@mixin phone {
  @media screen and (max-width: 576px) { @content; }
}
@mixin tablet {
  @media screen and (min-width: 768px) { @content; }
}

@mixin desktop {
  @media screen and (min-width: 1025px) { @content; }
}

@mixin l-desktop {
  @media screen and (min-width: 1440px) { @content; }
}