.new_articles .new_articles_list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  align-items: start;
  justify-items: center;
}

@media only screen and (max-width: 1180px) {
  .new_articles .new_articles_list {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 767px) {
  .new_articles .new_articles_list {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
  }
}

.new_articles .new_articles_list .news_li .news_img {
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
}

.new_articles .new_articles_list .news_li .news_img img {
  height: 169px;
  object-fit: cover;
}

.page-number-wapper{
  display: flex;
  justify-content: center;
}

.page-numbers {
  display: inline-block;
  padding: 14px;
  line-height: 0.5;
  border-radius: 40px;
  font-weight: 0;
  font-size: 14px

}
.page-numbers.current,
a.page-numbers:hover {
  background: #3c3c3c;
  color: white;
}