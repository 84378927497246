.l-header__gnav {
  @include desktop {
    // justify-content: space-between;
    // width: 600px;
    // transition: transform 1s ease-out;

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    width: calc(100% - 160px);
    height: calc(100% - 100px);
    min-height: 575px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    z-index: 1001;
    backface-visibility: hidden;
  }

  .header__gnav {
    @include desktop {
      position: relative;
      margin: 0;
      height: initial;
      width: 268px;
      left: -290px;
    }

    &__middle {
      @include desktop {
        position: absolute;
        width: fit-content;
        height: fit-content;
        margin: unset !important;
        top: unset;
        bottom: 40px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .item {
      @include desktop {
        margin-bottom: 14px;
        text-align: left;
        display: flex;
        align-items: center;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &-single {
        margin: 8px 0 !important;
      }

      .link {
        @include desktop {
          cursor: pointer;
        }

        .text {
          @include desktop {
            font-size: 25px;
            line-height: 1.5;
            font-weight: bold;
          }
        }

        .text-small {
          font-size: 14px;

          @include desktop {
            font-size: 18px !important;
            line-height: 1.2;
          }
        }
      }
    }

    .w-0 {
      width: 0;
    }

    .seperator {
      @include desktop {
        margin-top: 24px;
        margin-bottom: 14px;
      }

      margin-top: 32px;
      margin-bottom: 8px;
    }

    .ses {
      @include desktop {
        margin-top: 24px;
      }

      margin-top: 36px;
    }

    .inhouseservice {
      @include desktop {
        flex-direction: column;
        align-items: unset !important;
        margin-top: unset;
        width: unset;
      }

      margin-top: 36px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .link {
        text-align: center;
      }

      .service_logos {
        display: flex;
        column-gap: 20px;
        margin-top: 10px;
        margin-bottom: 60px;

        @include desktop {
          column-gap: 40px;
          margin-top: 24px;
          margin-bottom: unset;
        }

        .spin {
          height: 45px;
          width: 43px;
          object-fit: contain;

          @include desktop {
            width: auto;
            height: 57px;
          }
        }

        .tells {
          height: 45px;
          width: 50px;
          object-fit: contain;

          @include desktop {
            width: auto;
            height: 50px;
            transform: translateY(5px);
          }
        }

        .vibes {
          height: 45px;
          width: 59px;
          object-fit: contain;

          @include desktop {
            width: auto;
            height: 58px;
          }
        }

        .suspay {
          height: 45px;
          width: 68px;
          object-fit: contain;

          @include desktop {
            width: auto;
            height: 48px;
            margin-top: 6px;
          }
        }

        .filter_svg {
          filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(30deg) brightness(96%) contrast(114%);
        }
      }
    }
  }

  .header__secondNav {
    @include desktop {
      // position: relative;
      // display: block;
      // width: 174px;
      // height: 1px;
      // background-color: #000;
      // opacity: 0;
      // left: 2.5%;
      // transform: scaleX(0);
      // transform-origin: left;
      // transition: 0.7s ease-out;

      // &--overview,
      // &--business {
      //   transform: scale(1) !important;
      //   opacity: 1 !important;
      //   margin-left: 50px !important;

      position: absolute;
      display: block;
      overflow: hidden;
      opacity: 0;
      left: 350px;
      // transform: scaleY(0);
      transform-origin: top;

      &--overview {
        top: 0px;
        // transform: scale(1) !important;
        opacity: 1;
      }

      &--business {
        top: 0px;
        // transform: scale(1) !important;
        opacity: 1;
      }

      &--event {
        top: 2px;
        // transform: scale(1) !important;
        opacity: 1;
      }

      &--media {
        top: 0px;
        // transform: scale(1) !important;
        opacity: 1;
      }

      &--contact {
        top: 0px;
        // transform: scale(1) !important;
        opacity: 1;
      }
    }

    .second-level {
      @include desktop {
        position: relative;
        display: none;

        &.open {
          display: flex;
          flex-direction: column;
          animation-name: fadeUpAnime;
          animation-delay: 0.2;
          animation-duration: 0.5s;
          animation-fill-mode: forwards;
          opacity: 0;

          @keyframes fadeUpAnime {
            from {
              opacity: 0;
              transform: translateY(-100px);
            }

            to {
              opacity: 1;
              transform: translateY(0);
            }
          }
        }
      }

      .second_li {
        @include desktop {
          margin-bottom: 10px;
          padding: 0;
          white-space: nowrap;

          &:last-child {
            margin-bottom: 0;
          }

          &.second_li--event {
            margin-bottom: 12px;

            &:first-child {
              margin-bottom: 16px;

              .has_sub_a .text {
                font-size: 18px;
                color: #f00;
              }

              .event-location {
                font-weight: normal;
                font-size: 15px;
              }
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        &.second_li--suspay {
          .has_sub_a .text {
            text-transform: none;
          }
        }

        .link {
          padding: 0;
          transition: color 0.3s ease-in;

          @include desktop {
            &:hover {
              color: #f00;
            }
          }

          .text {
            @include desktop {
              font-size: 15px;
              font-weight: bold;
              line-height: 20px;
            }
          }
        }
      }

      .sub_li {
        flex-direction: column;
        align-items: unset;

        .sub_item {
          padding-left: 14px;
        }
      }
    }
  }

  .mt-5 {
    margin-top: 5px;
  }

  .pre-small {
    position: absolute;
    transform: translate(0px, -13px);
    font-size: 10px;
    font-weight: medium;

    &-mobile {
      position: absolute;
      transform: translate(0px, -10px);
      font-size: 10px;
      font-weight: medium;
    }
  }

  .event-location {
    font-weight: normal;
    font-size: 13px;
  }
}

.globalNav__line {
  @include desktop {
    position: absolute;
    display: block;
    width: 174px;
    height: 1px;
    background-color: #000;
    opacity: 0;
    left: 123px;
    transform: scaleX(0);
    transform-origin: left;
    transition: 0.1s ease-out;

    &--open {
      transform: scale(1) !important;
      opacity: 1 !important;
      margin-left: 50px !important;
      // transition: transform 0.7s ease-out;
    }
  }
}


// .globalNav__line2 {
//   @include desktop {
//     position: relative;
//     display: block;
//     width: 174px;
//     height: 1px;
//     background-color: #000;
//     opacity: 0;
//     left: 2.5%;
//     transform: scaleX(0);
//     transform-origin: left;
//     transition: 0.7s ease-out;

//     &--business {
//       transform: scale(1) !important;
//       opacity: 1 !important;
//       // transition: transform 0.7s ease-out;
//     }

//     &--business {
//         margin-left: 50px;
//     }
//   }
// }