.freewill-intro {
  z-index: 9000;
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: scroll;
  background-color: #fbfafb; // same as video
  transition: opacity 0.5s ease-in-out;

  &[showing] {
    opacity: 1;
  }

  &[hiding] {
    opacity: 0;
  }

  &[removed] {
    visibility: hidden;
  }

  &__content-wrapper {
    z-index: 9000;
    width: 100%;
    height: 200vw;
    min-height: calc(100vh * 2);
  }

  &__content {
    z-index: 9000;
    position: relative;
    width: 100%;
    height: 100vw;

    .freewill-intro-scroll-overlay {
      z-index: 9002;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
    }

    .freewill-intro-video {
      &-wrapper {
        z-index: 9001;
        position: fixed;
        top: 0;
        margin: 0;
        padding: 0;
        // width: 100%;
        width: 100vw;
        // height: calc(100vw * 0.52);
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
      }

      width: auto;
      min-width: 130vw;
      min-height: 130vh;
      height: 100vh;
      margin: 0;
      padding: 0;
      background-color: transparent;

      &[showing] {
        visibility: visible;
      }

      &[hiding] {
        visibility: hidden;
      }
    }

    .freewill-intro-clouds {
      z-index: 9004;
      position: absolute;
      width: 100%;
      bottom: 0;
      background-color: transparent;
      transition: opacity 1s ease-in-out;

      &[hiding] {
        opacity: 0;
        & + div {
          opacity: 0;
        }
      }

      &[peaking] {
        opacity: 0.65;
      }

      &[auto-animate] {
        animation-name: cloudsScrolling;
        animation-duration: 2s; // changed in Top.js
        animation-timing-function: cubic-bezier(0.12, 0.58, 1, 0.29);
      }

      &__background {
        z-index: 9003;
        position: absolute;
        width: 100%;
        height: 28vw;
        bottom: 0;
        background-color: #fbfafb; // same as video
      }
    }
  }
}

@keyframes cloudsScrolling {
  0% {
    /* opacity: 0; */
    bottom: 40vw;
  }
  /* 50%  { opacity: 0.7; } */
  100% {
    /* opacity: 0; */
    bottom: 65vw;
  }
}
.christmas-card-wrapper {
  margin-top: 120px;
  position: relative;

  @media screen and (max-width: 1024px) {
    margin-top: 280px;
  }

  .christmas-card__snowflakes__wrapper {
    @media screen and (min-width: 769px) {
      display: none;
    }
  }

  .top-snowfalls {
    position: absolute;
    width: 100%;
    height: 100%;

    .top-snow {
      &__wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;

        @media screen and (max-width: 768px) {
          display: none;
        }
      }

      &__content {
        position: absolute;
      }
    }
  }
}

.valentine-card-wrapper {
  margin-top: 120px;
  position: relative;

  @media screen and (max-width: 1024px) {
    margin-top: 280px;
  }
}

.l-page-wrapper-top {
  margin-top: 100vh;
}

.background_bird {
  background: url("../images/pc/top/background_bird.jpg");
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  top: 265px;
  position: absolute;
  z-index: 0;
  max-height: 2650px;
  background-size: cover;
  margin-left: auto;
  margin-right: auto;
  left: calc(50% - -68px);
  right: 0;
}

.top_header {
  display: flex;
  margin-top: 180px;
  height: 100%;
  border-top: 1px solid #e1e1e1;

  @media screen and (max-width: 1024px) {
    display: block;
    height: 100%;
    border-top: 0;
    width: 100%;
    margin-top: 70px;
  }
  @include l-desktop {
    margin: 180px 160px 0;
  }

  &__top {
    width: 45%;
    height: 170px;
    border-right: 1px solid #e1e1e1;
  }

  &__vline {
    display: none;
    @include tablet {
      position: absolute;
      left: 50%;
      // right: 700px;
      display: block;
      height: 600px;
      width: 1px;
      background-color: #e1e1e1;
    }
    @include desktop {
      height: 812px;
      z-index: 1;
    }
    @include l-desktop {
      left: 50%;
    }
  }

  &__content {
    position: absolute;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: center;
    // border-right: 1px solid #e1e1e1;
    padding: 0 80px;
    height: 890px;
    // mix-blend-mode: multiply;

    @media screen and (max-width: 1024px) {
      position: relative;
      display: block;
      height: 100%;
      // flex-basis: 100%;
      // max-width: 380px;
      border-right: 0;
      margin: 0 auto;
      padding: 0;
      // z-index: 50;
    }
    @include l-desktop {
      padding: 0 50px 0 8%;
    }
  }

  &__title {
    position: relative;
    color: #3333330d;
    font-size: 74px;
    font-weight: 700;
    line-height: 1.5;
    padding: 0 10px 48px;
    margin: 0;
    max-width: 100%;
    text-align: center;
    z-index: 20;

    @include tablet {
      display: flex;
      align-items: center;
      // min-height: 420px;
      padding: 60px 20px 48px;
      color: #242424;
      text-align: left;
    }
    @include desktop {
      position: absolute;
      top: 104px;
      display: block;
      overflow: hidden;
      max-width: 420px;
      max-height: 208px;
      margin-bottom: 45px;
      // margin-top: -300px;
      padding: 0;
      font-family: "Noto Sans JP", sans-serif;
      font-size: 70px;
      line-height: 104px;
      font-weight: 700;
    }
  }

  &__text-cover {
    @include desktop {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background-color: #fff;
      transform-origin: right;
    }
  }
  &__titleEn {
    position: relative;
    color: #3333330d;
    font-size: 65px;
    font-weight: 700;
    line-height: 1.5;
    padding: 0 10px 48px;
    margin: 0;
    max-width: 100%;
    text-align: center;
    z-index: 20;

    @include tablet {
      display: flex;
      align-items: center;
      // min-height: 420px;
      padding: 60px 20px 48px;
      color: #242424;
      text-align: left;
    }

    @include desktop {
      position: absolute;
      top: 104px;
      display: block;
      overflow: hidden;
      max-width: 420px;
      max-height: 208px;
      margin-bottom: 45px;
      // margin-top: -300px;
      padding: 0;
      font-family: "Noto Sans JP", sans-serif;
      font-size: 70px;
      line-height: 71px;
      font-weight: 700;
    }
  }
  // &__text-coverEn {
  //   @include desktop {
  //     position: absolute;
  //     top: 0;
  //     right: 0;
  //     left: 0;
  //     bottom: 0;
  //     background-color: #fff;
  //     transform-origin: right;
  //   }
  // }
  &__text {
    display: block;
    position: relative;
    padding: 0 20px;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.5;
    text-align: justify;
    &:after {
      display: block;
      content: "";
      height: 40px;
      margin-top: 20px;
      width: 1px;
      background: #242424;
      margin-left: auto;
      margin-right: auto;
      @include tablet {
        display: none;
      }
    }
    @include tablet {
      margin-top: 0;
    }
    @include desktop {
      position: relative;
      color: #242424;
      max-width: 420px;
      display: flex;
      flex-direction: column;
      padding: 0;
      margin-top: -100px;
      &:after {
        display: none;
      }
    }
    p {
      @include tablet {
        width: 50%;
        padding-right: 20px;
      }
      @include desktop {
        width: 100%;
        padding: 0;
      }
    }
  }
  &__subtitle {
    position: relative;
    display: block;
    color: #e1e1e1;
    margin: 0 0 48px 20px;
    font-size: 10px;
    line-height: 1.5;
    &:after {
      position: absolute;
      bottom: 4px;
      display: block;
      content: "";
      height: 1px;
      width: 100%;
      background-color: #e1e1e1;
      z-index: -1;
    }
    @include tablet {
      display: none;
    }
  }
  &__subtitle-text {
    display: inline-block;
    background-color: #fff;
    padding-right: 2px;
  }
}

.top_header__text--p {
  padding: 0;
}

// .top_contents {
//   @include desktop{
//     margin-top: -140px;
//    // z-index: 100;
//     z-index: 0;
//   }
// }

.kanata {
  position: absolute;
  top: 0;
  width: 420px;
  height: 420px;
  max-width: 100%;
  max-height: 100%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  z-index: 10;

  @include tablet {
    top: 90px;
    margin-right: 0;
  }
  @include desktop {
    position: relative;
    top: initial;
    width: 100%;
    height: initial;
  }
}

.kanata2 {
  position: relative;
  width: 420px;
  height: 420px;
  max-width: 100%;
  max-height: 100%;
  z-index: 11;

  @include l-desktop {
    margin-left: auto;
    height: 750px;
    width: 650px;
  }

  @include desktop {
    margin-left: auto;
    height: 750px;
    width: 600px;
  }

  @include tablet {
    margin-left: auto;
    height: 750px;
    width: 700px;
  }
}

/* --- 全体のBOX定義 ---------------------------------------- */

.kanata2 .bgImg {
  position: absolute;
  top: -190px;
  left: 0;
  right: 0;
  opacity: 0;
  width: 750px;
  height: 750px;
  animation: bgAnime 20s infinite;
  animation-duration: 20s;
  animation-iteration-count: infinite;

  @media screen and (max-width: 1024px) {
    width: 420px;
    height: 420px;
    max-width: 100%;
    max-height: 100%;
    top: 0;
  }
}

/* --- 段差で背景画像のアニメーションを実行 ----------------- */
.kanata2bg {
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

// .kanata2 .number::before {
//   position: absolute;
//   width: 70px;
//   height: 70px;
//   left: 0;
//   top: 0;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   opacity: 0;
//   animation: numberAnime 20s infinite;
//   animation-duration: 20s;
//   -webkit-animation-duration: 20s;
//   animation-iteration-count: infinite;
// }

// .kanata2 .number1::before {
//   content: "01";
// }

// .kanata2 .number2::before {
//   content: "02";
//   animation-delay: 5s;
// }

// .kanata2 .number3::before {
//   content: "03";
//   animation-delay: 10s;
// }

// .kanata2 .number4::before {
//   content: "04";
//   animation-delay: 15s;
// }

.kanata2 .src1 {
  background-image: url(../images/pc/top/BIRD1.png);
}

.kanata2 .src2 {
  background-image: url(../images/pc/top/BIRD2.png);
  animation-delay: 5s;
}

.kanata2 .src3 {
  background-image: url(../images/pc/top/BIRD3.png);
  animation-delay: 10s;
}

.kanata2 .src4 {
  background-image: url(../images/pc/top/BIRD4.png);
  animation-delay: 15s;
}

@keyframes bgAnime {
  0% {
    opacity: 0;
  }

  7.5% {
    opacity: 1;
    transform: translateY(10%);
  }

  17.5% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }
}

@keyframes numberAnime {
  0% {
    opacity: 0;
  }

  7.5% {
    opacity: 1;
    transform: translateY(10%);
  }

  17.5% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }
}

.right_top_image {
  position: absolute;
  @include desktop {
    position: relative;
    margin-right: 20px;
  }
  @include l-desktop {
    margin-right: 8%;
  }
}

// .image_number_block {
//   width: 70px;
//   height: 70px;
//   background: $red;
//   position: absolute;
//   right: 0;
//   bottom: 172px;
// }

// .image_number_wrapper {
//   position: absolute;
//   bottom: 95px;
//   right: 0;
//   height: 70%;

//   &::before {
//     content: "";
//     height: 80%;
//     width: 1px;
//     display: block;
//     background: $red;
//     position: absolute;
//     bottom: 85px;
//     right: 0;
//   }
// }

// .image_number {
//   position: absolute;
//   bottom: 178px;
//   right: 0;
//   width: 70px;
//   height: 70px;
//   font-family: 'Noto Sans JP', sans-serif;
//   color: white;
//   font-size: 30px;
//   line-height: 44px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   z-index: 5;
// }

.top_header_bottom {
  display: flex;
}

.top_header_bottom__inner {
  width: 100%;
  border-bottom: 1px solid #e1e1e1;
  position: relative;
  margin-bottom: 5px;

  @media screen and (max-width: 1024px) {
    display: none;
  }

  &::before {
    content: "OUR BUSINESS";
    font-size: 10px;
    line-height: 11px;
    color: #e1e1e1;
    font-family: "Noto Sans JP", sans-serif;
    display: block;
    position: absolute;
    bottom: -8px;
    background: #fcfcfc;
    padding-right: 7px;
  }

  &::after {
    content: "01";
    font-size: 10px;
    line-height: 11px;
    color: #e1e1e1;
    font-family: "Noto Sans JP", sans-serif;
    display: block;
    position: absolute;
    bottom: 4px;
    right: -15px;
  }
}

.top_contents{
  .business{
    position: relative;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    width: 80.55%;
    max-width: 1160px;
    min-width: 1025px;
    column-gap: 1px;
    row-gap: 1px;
    margin: 24px auto 140px;
    @media screen and (max-width: 1024px) {
      width: 80.55%;
      grid-template-columns: repeat(2,1fr);
      max-width: 770px;
      min-width: unset;
    }
    @media screen and (max-width:767px){
      width: 100%;
      grid-template-columns: repeat(1,1fr);
    }
    &__top{
      margin: 40px auto 0;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      width: 80.55%;
      max-width: 1160px;
      min-width: 1025px;
      @media screen and (max-width:1024px){
        min-width: unset;
      }
      @media screen and (max-width:767px){
        width: unset;
        margin: 0 2vw;
      }
      .title{
        font-size: 40px;
        font-weight: bold;
        @media screen and (max-width:767px){
          font-size: 24px;
        }
      }
      .link{
        font-size: 14px;
        white-space: nowrap;
        @media screen and (max-width:767px){
          display: block;
          font-size: 13px;
        }
        &_case{
          margin-right: 32px;
          @media screen and (max-width:767px){
            margin-right: unset;
            margin-bottom: 4px;
          }
        }
        &_page{
          font-weight: bold;
          color: #F21615;
        }
      }
    }
    .extra_height{
      @media screen and (max-width: 460px){
        height: 420px;
      }
    }

    &__card{
      position: relative;
      max-height: 590px;
      background-color: #000000;
      aspect-ratio: 193/295;
      @media screen and (max-width: 767px){
        aspect-ratio: auto;
        height: 310px;
      }
      &--bg{
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0.4;
      }
      .link{
        position: absolute;
        bottom: 23px;
        right: 24px;
        font-size: 14px;
        font-weight: bold;
        color: #FFFFFF;
        &:hover{
          text-decoration: underline !important;
        }
      }
    }
    .custom_position{
      top: 30%;
    }

    &__content{
      position: absolute;
      top: 14%;
      margin: 0 24px;
      color: #fff;
      pointer-events: none;
      @include desktop{
        // top: 40.5%;
        top: 12%;
      }
      .title{
        font-size: 64px;
        font-weight: bold;
        margin-bottom: 27px;
        @media screen and (max-width:1024px) {
          font-size: 40px;
        }
        &--small{
          font-size: 27px;
          white-space: nowrap;
          @media screen and (max-width:1024px) {
            font-size: 40px;
          }
        }
        &--big{
          font-size: 70px;
          @media screen and (max-width:1024px) {
            font-size: 40px;
          }
        }
        &--bigger{
          font-size: 100px;
          @media screen and (max-width:1024px) {
            font-size: 40px;
          }
        }
        &__design {
          position: relative;
          &::after {
            content: "UI/UX Design, Illustration, Movies, Photoshoots, etc.";
            white-space: nowrap;
            font-size: 12px;
            font-weight: normal;
            position: absolute;
            bottom: -14px;
            left: 0;
          }
        }
        &__solution {
          position: relative;
          &::after {
            content: "BISCKETS©";
            font-size: 12px;
            font-weight: normal;
            position: absolute;
            bottom: -14px;
            left: 0;
          }

        }
        &__inhouse {
          position: relative;
          &::after {
            content: "tells market©, サスPay©, SPIN©, Vibes Media©";
            font-size: 12px;
            font-weight: normal;
            position: absolute;
            bottom: -14px;
            left: 0;
          }
        }
      }
      // .subtitle{
      //   position: absolute;
      //   font-size: 12px;
      //   top: 35px;
      // }
      .description{
        font-size: 13px;
        line-height: 19px;
      }
    }
  }
}

.top_container {
  // padding: 0 20px;
  // margin-top: 64px;
  padding: 20px 20px;
  margin-bottom: 0;
  &--top {
    margin-top: 100px;
    @include tablet {
    }
  }
  @include desktop {
    max-width: 1100px;
    margin: 0 auto;
    z-index: 20;
    position: relative;
  }
}

.background_wrapper {
  position: relative;

  @media (min-width: 1025px) and (max-width: 1199px) {
    padding: 0 40px;
  }
}

.main_background {
  background: url("../images/pc/top/background.jpg");
  background-repeat: no-repeat;
  background-position: center top;
  overflow: hidden;
  position: absolute;
  margin-top: -35px;
  height: 100%;
  width: calc(100% + 200px);
  left: -200px;
}

.content_one {
  position: relative;
  display: flex;
  flex-direction: column;
  @include desktop {
    flex-direction: row;
    margin-bottom: 30px;
    margin-top: -5px;
    margin-left: -20px;
    padding-left: 20px;
  }
  &__right-vline {
    display: none;
    @include desktop {
      position: absolute;
      right: 0;
      bottom: 0;
      display: block;
      width: 1px;
      background-color: #e1e1e1;
      height: 108%;
    }
  }
}

.content_one__heading_wrapper {
  flex-basis: calc(40% + 15px);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 42px;
  @include desktop {
    margin: 0;
  }
}

.content_one__heading {
  font-size: 120px;
  line-height: 130px;
  color: #242424;
  font-weight: 600;
  max-width: 350px;
  text-transform: uppercase;

  @media screen and (max-width: 1024px) {
    font-size: 95px;
    line-height: 110px;
    max-width: 100%;
  }
}

.content_one__content {
  position: relative;
  color: #242424;
  @include desktop {
    // left: -180px;
    top: 180px;
    padding: 0px 68px 90px;
    flex-basis: calc(60% - 15px);
    // border-right: 1px solid #e1e1e1;
    max-width: 600px;
    margin-left: auto;
    background: white;
    // z-index: -1;
  }
}
.top_content_ai {
  position: relative;
  color: #242424;
  margin: 100px 0;
  @include desktop {
    left: -189px;
    padding: 10px 20px 0px;
    margin-top: 300px;
    margin-bottom: 100px;
    // border-right: 1px solid #e1e1e1;
    max-width: 600px;
    margin-left: auto;
    background: white;
  }
  &-vline {
    display: none;
    @include desktop {
      position: absolute;
      right: 0;
      top: -55%;
      display: block;
      width: 1px;
      background-color: #e1e1e1;
      height: 460px;
    }
  }
}
.top_content_blockchain {
  position: relative;
  color: #242424;
  @include desktop {
    padding: 10px 20px 0px;
    margin-top: 121px;
    margin-bottom: 100px;
    // border-right: 1px solid #e1e1e1;
    max-width: 600px;
    margin-left: auto;
    background: white;
  }
}

.content_subtitle {
  color: #242424;
  font-family: "Noto Sans JP", sans-serif;
  @include desktop {
    font-size: 15px;
    line-height: 15px;
    margin-top: 10px;
    margin-bottom: 5px;
  }
}

.content_heading {
  position: relative;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 25px;
  font-weight: 600;
  line-height: 1.5;
  color: #242424;
  margin-bottom: 30px;
  text-transform: uppercase;
  @media screen and (max-width: 1024px) {
    font-size: 25px;
    font-weight: 500;
    line-height: 37px;
  }
  &--smaller {
    @media screen and (max-width: 1024px) {
      font-size: 23px !important;
    }
  }
  &--bold {
    font-weight: 700;
    // font-size: 30px;
    // @media screen and (max-width: 1024px) {
    //   font-size: 22px !important;
    // }
  }
}

.content_headline {
  position: relative;
}

.content_text {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 15px;
  line-height: 25px;
  color: #242424;

  @media screen and (max-width: 1024px) {
    font-size: 12px;
    line-height: 18px;
  }
}

.content_button_wrapper {
  display: flex;
  justify-content: space-around;

  // .btn_white {
  //   background-color: #fff;
  //   color: #f21515;
  //   border: medium solid #f21515;
  // }

  .btn_black {
    background-color: #242424;
  }
}

.content_button {
  @include desktop {
    position: absolute;
    left: 28px;
    bottom: -28px;
  }
  &--space-top {
    position: relative;
    top: 32px;
    // margin-top: 82px;
    margin-right: 0;
    // margin-left: auto;
    z-index: 1;
    @include desktop {
      margin: 0;
    }
  }
}

.middle_content {
  display: block;
  position: relative;
  border-top: 1px solid #e1e1e1;
  border-right: 0;
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 20px;
  margin-bottom: 42px;
  // max-width: 100%;
  // width: 100%;
  width: inherit;
  // z-index: 10;
  @include desktop {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    border-top: none;
    border-right: 1px solid #e1e1e1;
    max-width: 482px;
    margin-right: 0;
    margin-top: -100px;
    margin-left: auto;
    margin-bottom: 0;
    padding-bottom: 50px;
  }
}

.middle_content_spacer {
  height: 190px;
  width: 100%;
  border-bottom: 1px solid #e1e1e1;
  position: relative;

  @media screen and (max-width: 1024px) {
    height: 130px;
    width: calc(100% - 200px);
    border-bottom: 0;
    border-right: 1px solid #e1e1e1;
  }

  @include desktop {
    &::after {
      content: "02";
      font-size: 10px;
      line-height: 11px;
      color: #e1e1e1;
      font-family: "Noto Sans JP", sans-serif;
      display: block;
      position: absolute;
      bottom: 4px;
      right: -17px;
    }
  }
}

.middle_content__title {
  padding-top: 50px;
  text-transform: uppercase;
  color: white;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  font-weight: 700;
  margin-right: 40px;

  @media screen and (max-width: 1024px) {
    background: url("../images/pc/top/background_bird.jpg");
    max-width: calc(100% + 40px);
    width: calc(100% + 40px);
    margin: 0 -20px;
    padding: 0 20px 40px;
  }
}

.middle_content__title_spacer {
  height: 170px;
  width: calc(100% - 200px);
  border-right: 1px solid #e1e1e1;
}

.middle_content__large {
  font-size: 130px;
  line-height: 120px;

  @media screen and (max-width: 1024px) {
    border-top: 1px solid #e1e1e1;
    margin-left: -20px;
    margin-right: -20px;
    padding: 30px 20px 0;
    width: calc(100% + 40px);
    position: relative;
    z-index: 20;
    font-size: 105px;
    line-height: 100px;

    &::after {
      content: "";
      border-right: 1px solid #e1e1e1;
      display: block;
      width: 78%;
      height: calc(100% + 130px);
      position: absolute;
      top: 0;
      z-index: -1;
    }
  }
}

.middle_content__small {
  font-size: 50px;
  // line-height: 90px;
  font-weight: 700;
  letter-spacing: 3px;

  @media screen and (max-width: 1024px) {
    font-size: 44px;
    z-index: 50;
  }
}

.content_two {
  margin-left: auto;
  border-right: 1px solid #e1e1e1;
  padding-right: 240px;
  border-bottom: 1px solid #e1e1e1;
  position: relative;
  max-width: 815px;

  @media screen and (max-width: 1024px) {
    max-width: calc(100% + 20px);
    padding: 0 0 100px 0;
    margin: 0;
    border-right: 0;
    border-bottom: 0;
  }

  @include desktop {
    &::after {
      content: "03";
      font-size: 10px;
      line-height: 11px;
      color: #e1e1e1;
      font-family: "Noto Sans JP", sans-serif;
      display: block;
      position: absolute;
      bottom: 4px;
      right: -17px;
    }
  }
}

.content_two__content {
  background: white;
  padding: 90px 70px 90px;
  flex-basis: calc(60% + 200px);
  max-width: 600px;
  position: relative;

  @media screen and (max-width: 1024px) {
    padding: 0;
    max-width: 100%;
  }
}

.content_heading--large {
  font-size: 50px;
  line-height: 55px;
  letter-spacing: 5px;
  font-weight: 700;

  @media screen and (max-width: 1024px) {
    font-size: 48px;
  }
}

.content_button--center {
  margin-left: -44px;
  left: 50%;

  @media screen and (max-width: 1024px) {
    left: initial;
    margin-top: 128px;
    margin-right: 0;
    margin-left: auto;
  }
}

.content_three {
  display: flex;
  margin: 0 -20px;
  padding: 0 20px;
  @include tablet {
    justify-content: center;
  }
  @include desktop {
    justify-content: flex-start;
    margin: 0;
    padding: 0;
  }
  &__heading {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;
    color: #242424;
    font-size: 130px;
    line-height: 130px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    @include tablet {
      width: inherit;
    }
    @include desktop {
      display: inline-flex;
      line-height: 140px;
      padding-left: 140px;
      padding-top: 140px;
    }

    .small {
      font-size: 50px;
      line-height: 59px;
      padding-left: 12px;

      @media screen and (max-width: 1024px) {
        z-index: 50;
        position: relative;
        font-size: 46px;
        line-height: 59px;
        padding-left: 6px;
      }
    }
  }
}

.index .s-csr .inner {
  @media screen and (max-width: 1024px) {
    padding: 0;
  }
}

.index .s-csr .inner .csr__intoro {
  padding: 0 20px;
}

.index .s-csr .inner .csr__body {
  margin-top: 0 !important;
  .item {
    width: 50% !important;
    @media screen and (max-width: 1024px) {
      width: 100% !important;
    }

    .link {
      .thumb {
        position: relative;
        display: block;
        max-height: 325px;
        overflow: hidden;
        text-align: center;
        .img {
          max-width: 360px;
          margin: 0 auto;
          @media screen and (max-width: 1024px) {
            max-width: 100%;
          }
        }
      }

      .article_title {
        padding: 30px 0 70px;
        max-width: 300px;
        margin: 0 auto;
        @media screen and (max-width: 1024px) {
          text-align: center;
        }
        &--right {
          // text-align: right;
          @media screen and (max-width: 1024px) {
            text-align: center;
          }
        }

        .top {
          text-transform: uppercase;
          color: #242424;
          font-size: 20px;
          font-weight: 700;
          line-height: 30px;
          text-align: justify;
          text-transform: uppercase;
        }

        .bottom {
          color: #242424;
          font-size: 20px;
          font-weight: 300;
          line-height: 30px;
          text-align: justify;
        }
      }
    }
  }
}

.members {
  &__body {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @include desktop {
      flex-direction: row;
    }
  }
  &__item {
    width: 50% !important;

    @media screen and (max-width: 1024px) {
      width: 100% !important;
    }

    .link {
      .thumb {
        position: relative;
        display: block;
        max-height: 325px;
        overflow: hidden;
        background: #1a1a1a;
        text-align: center;

        .img {
          max-width: 360px;
          margin: 0 auto;

          @media screen and (max-width: 1024px) {
            max-width: 100%;
          }
        }
      }

      .article_title {
        padding: 30px 0 70px;
        max-width: 300px;
        margin: 0 auto;

        @media screen and (max-width: 1024px) {
          text-align: center;
        }

        &--right {
          text-align: right;

          @media screen and (max-width: 1024px) {
            text-align: center;
          }
        }

        .top {
          text-transform: uppercase;
          color: #242424;
          font-size: 20px;
          font-weight: 700;
          line-height: 30px;
          text-align: justify;
          text-transform: uppercase;
        }

        .bottom {
          color: #242424;
          font-size: 20px;
          font-weight: 300;
          line-height: 30px;
          text-align: justify;
        }
      }
    }
  }
}

// news
.news_section {
  margin-top: 150px;

  .news_contents_latest{
    display: flex;
    width: 80.55%;
    max-width: 1160px;
    min-width: 1025px;
    margin: 0 auto;
    justify-content: space-between;
    align-items: baseline;
    @media only screen and (max-width: 1060px) {
      padding: 0 32px;
    }
    @media screen and (max-width: 1024px) {
      width: 100%;
      padding: 0 32px;
      max-width: unset;
      min-width: unset;
      margin: unset;
      justify-content: unset;
    }

  }

  .news_top_latest{
  /* UI Properties */
    text-align: right;
    font-size: 24px;
    font-weight: normal;
    letter-spacing: 0px;
    color: #242424;
    opacity: 1;

    @media screen and (max-width: 1024px) {
      display: block;
      left: 32px;

    }
  }

  .top_bottom_sentence_inner {
    width: 75%;
    border-bottom: 1px solid #E2E2E2;
    position: relative;
    margin-right: 15px;
    margin-left: 22.5px;
    @media screen and (max-width: 1024px) {
      display: block;
    }
  }

  .link_for_seeallnews{
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #F21615;
    opacity: 1;
    white-space: nowrap;

    @media screen and (max-width: 1024px) {
      display: block;
      bottom: -12px;
      height: 20px;
      left: 32px;
    }
  }

  .post_date {
    line-height: 1.5rem;
    margin-bottom: 5px;
    color: #969696;
    letter-spacing: 0.025em;
    font-size: 12px;
  }

  .news__headline {
    font-size: 40px;
    font-weight: 600;
    line-height: 1.5;
    padding: 50px 0 35px;
    text-align: center;
    text-transform: uppercase;
    color: #242424;
    z-index: 100;

    @include desktop {
      margin: 0 auto;
      padding: 50px 20px 50px;
      font-size: 60px;
      letter-spacing: 0.35px;
    }
  }

  .news_container{
    display: flex;
    width: 80.55%;
    max-width: 1160px;
    min-width: 1025px;
    margin: 16px auto;

    @media only screen and (max-width: 1060px) {
      padding: 0 32px;
    }
    @media only screen and (max-width: 1024px) {
      min-width: unset;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      padding: 16px 32px;
    }
    @media only screen and (max-width: 768px) {
      width: 100%;
    }

  }

  //ここから featured news
  .featured_contents {
    width: 100%;
    margin: 0 auto 56px auto;
    @include desktop {
      max-width: 474px;
    }

    @media only screen and (max-width: 820px) {
      margin: 0 auto 32px auto;
    }

    &:hover .featured_title{
      text-decoration: underline;
    }

    .featured_img_top {
      margin: 0 auto 12px auto;
      overflow: hidden;

      img {
        width: 474px;
        height: 277px;
        object-fit: cover;
        transition: all 0.2s ease-in-out;
        &:hover {
          transform: scale(1.05);
          transition: all 0.2s ease-in-out;
        }


        @media only screen and (max-width: 820px) {
          height: 300px;
        }
        @media only screen and (max-width: 575px) {
          height: 200px;
        }
      }
    }

    .featured_body {
      .featured_title {
        font-size: 18px;
        line-height: 1.5;
        font-weight: 500;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;

        @media only screen and (max-width: 820px) {
          font-size: 15px;
          font-weight: bold;
        }
      }

      .featured_paragraph_top {
        margin-top: 10px;

        @media only screen and (max-width: 820px) {
          font-size: 0.75rem;
          margin-top: 0;
        }
        p {
          line-height: 1.5;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
      }

    }
  }

  .news_contents {
    margin-left: 16px;
    @media only screen and (max-width: 1024px) {
      margin-left: unset;
    }
    .news_img_top {
      overflow: hidden;
      flex-shrink: 0;
      img {
        height: 114px;
        width: 196px;
        object-fit: cover;
        transition: all 0.2s ease-in-out;
        &:hover {
          transform: scale(1.05);
          transition: all 0.2s ease-in-out;
        }
        @media only screen and (max-width: 820px) {
          height: 63px;
          width: 108px;
          min-width: unset;

        }
      }

    }

    &_list_group {
      &_title {
        font-size: 1.5rem;
        font-weight: bold;
        line-height: 2.5rem;
        letter-spacing: 0;
        padding-left: 30px;
        margin-bottom: 20px;
        width: 100%;

        hr {
          border-top: 0.5px solid #707070;
          width: 100%;
        }

        @media only screen and (max-width: 820px) {
          padding: 0;
        }
      }

      &_wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .sub {
          width: 25%;
          min-width: 520px;
        }

        @media only screen and (max-width: 820px) {
          display: block;
          justify-content: unset;

          .main {
            width: 100%;
            min-width: unset;
            margin: auto;
          }

          .sub {
            width: 300px;
            min-width: unset;
            margin: auto;
          }
        }
      }
    }

    &_list {
      display: flex;
      margin-bottom: 12px;

      &:hover .news_title{
        text-decoration: underline;
      }

      .news_body {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        padding-left: 12px;

        @media only screen and (max-width: 820px) {
          padding-left: 8px;
        }

        .news_title {
          font-size: 14px;
          line-height: 1.5rem;
          font-weight: 700;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
        }

        .news_paragraph_top {
          margin-top: 10px;
          font-size: 0.875rem;

          @media only screen and (max-width: 820px) {
            margin-top: 0;
            font-size: 0.75rem;
          }

          p {
            line-height: 18px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            max-width: 458px;
          }
        }
      }
    }

    .viewall_btn {
      display: flex;
      justify-content: center;
      padding-top: 30px;

      @media only screen and (max-width: 820px) {
        padding-right: 20px;
      }

      &_p {
        left: 0;

        @media only screen and (max-width: 820px) {
          margin-top: 0;
        }
      }

      .viewall {
        text-decoration: none;
      }
    }
  }
}

@media only screen and (max-width: 780px) {
  .article_date {
    width: 100%;
  }
}

.press_section {
  margin-bottom: 105px;
  .press__headline {
    font-size: 40px;
    font-weight: 600;
    line-height: 1.5;
    padding: 122px 0 35px;
    text-align: center;
    text-transform: uppercase;
    color: #242424;
    z-index: 100;

    @include desktop {
      margin: 0 auto;
      padding: 100px 20px 80px;
      font-size: 60px;
      letter-spacing: 0.35px;
    }
  }

  .press_companies1 {
    display: flex;
    max-width: 920px;
    justify-content: space-between;
    margin: 35px auto;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
      align-items: center;
      max-width: 100%;
      padding: 0 20px;
      margin: 0px auto;
    }
    .company-forbes {
      @media screen and (max-width: 1024px) {
        margin-bottom: 0px;
      }
    }
    .company {
      width: 180px;
      height: 62px;
      position: relative;
      z-index: 4;
      @media screen and (max-width: 1024px) {
        &:last-child {
          margin-bottom: 0;
        }
      }
      .img {
        width: 100%;
      }
    }
    .company-eleminist {
      @media screen and (max-width: 1024px) {
        margin-bottom: 18px;
      }
      .img-eleminist {
        width: 100%;
        margin-top: -12px;
      }
    }
    .nikkei {
      transform: translate(10px, -16px) !important;
      @media screen and (max-width: 1024px) {
        transform: translate(2px, -16px) !important;

      }
    }
  }
  .press_companies2 {
    display: flex;
    max-width: 920px;
    justify-content: space-between;
    margin: 0 auto;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
      align-items: center;
      max-width: 100%;
      padding: 0 20px;
    }
    .company {
      width: 180px;
      height: 62px;
      position: relative;
      @media screen and (max-width: 1024px) {
        // margin-bottom: -68px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .img {
        width: 100%;
        // height: 100%;
      }
    }
    .kanji {
      transform: scale(2.0) translate(5px, -16px) !important;
      @media screen and (max-width: 1024px) {
        margin-bottom: 18px;
        transform: scale(2.0) translate(-1px, -16px) !important;
      }
    }
    .company-startuptimes {
      @media screen and (max-width: 1024px) {
        margin-bottom: 30px;
      }
      .img {
        width: 100%;
        transform: translate(10px, 20px);
        // height: 100%;
      }
    }
    .zenbird {
      transform: translate(-1px, -18px) !important;
      @media screen and (max-width: 1024px) {

      }
    }
  }
  .press_companies3 {
    display: flex;
    max-width: 920px;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
      align-items: center;
      max-width: 100%;
      padding: 0 20px;
      margin-top: -8px;
    }
    .company {
      width: 180px;
      height: 62px;
      @media screen and (max-width: 1024px) {
        margin-bottom: -4px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .img {
        width: 100%;
        // height: 100%;
      }
    }
    .heros {
      transform: translate(17px, 10px) !important;
      @media screen and (max-width: 1024px) {
        transform: translate(1px, 10px) !important;
      }
    }
    .life_burger {
      @media screen and (max-width: 1024px) {
        margin-top: 18px;
      }

    }
    .social {
      @media screen and (max-width: 1024px) {
        margin-top: 20px;
        margin-bottom: 30px;
      }

    }
    .company-startuptimes {
      @media screen and (max-width: 1024px) {
        margin-bottom: 30px;
      }
      .img {
        width: 100%;
        transform: translate(10px, 20px);
        // height: 100%;
      }
    }
  }

  .press_companies4 {
    display: flex;
    max-width: 920px;
    column-gap: 187px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    @media screen and (max-width: 1024px) {
      flex-direction: column;
      align-items: center;
      max-width: 100%;
      padding: 0 20px;
      margin-top: 27px;
    }
    .company {
      width: 180px;
      height: 62px;
      @media screen and (max-width: 1024px) {
        margin-bottom: -4px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .img {
        width: 100%;
        // height: 100%;
      }
    }
    .alterna {
      padding-top: 30px;
      @media screen and (max-width: 1024px) {
        padding-top: unset;;
      }
    }
    .company-startuptimes {
      @media screen and (max-width: 1024px) {
        margin-bottom: 30px;
      }
      .img {
        width: 100%;
        transform: translate(10px, 20px);
        // height: 100%;
      }
    }
  }
}
.first {
  display: flex;
}
.second {
  display: flex;
}

.content_four {
  display: flex;
  margin: 0 -20px 150px;
  padding: 0 20px;
  @include desktop {
    margin: -5px 0 0;
    padding: 0 0 325px;
  }
}

.content_four__heading_wrapper {
  flex-basis: calc(40% + 15px);
  display: flex;
  align-items: center;
}

.content_four__heading {
  font-size: 120px;
  line-height: 130px;
  color: #242424;
  font-weight: 600;
  max-width: 350px;
  text-transform: uppercase;
}

.content_four__content {
  background: white;
  padding: 100px 60px 90px;
  flex-basis: calc(60% - 15px);
  position: relative;
  min-height: 445px;
  max-width: 600px;
  margin-left: auto;

  @media screen and (max-width: 1024px) {
    min-height: 100%;
    max-width: 100%;
    // padding: 100px 0 0;
    padding: 40px 0 0;
    margin: 0;
    border-right: 0;
    flex-basis: 100%;
  }
}

.video_section {
  position: relative;
  overflow: hidden;
  min-width: 1305px;
  min-height: 625px;
  @media screen and (max-width: 1024px) {
    position: relative;
    min-width: unset;
    min-height: unset;
  }
  &__heading {
    color: #e1e1e1;
    font-size: 12px;
    line-height: 1.5;
    text-transform: capitalize;
    font-weight: bold;
  }
  &__paragraph {
    padding-top: 50px;
    color: #fff;
    font-size: 20px;
    font-weight: normal;
    line-height: 1.5;
    @include desktop {
      color: #242424;
      font-size: 15px;
    }
  }
  &__overlay_wrapper {
    display: inline-block;
    position: relative;
    overflow: hidden;
    width: calc(100% - 200px);
    box-sizing: border-box;
  }
  &__overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(36, 36, 36, 0.3);
    position: absolute;
    z-index: 50;
  }
  &__content {
    display: flex;
    z-index: 20;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 60;

    @media screen and (max-width: 1024px) {
      background: url("../images/sp/top/whoweare.png");
      height: 730px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      position: relative;
    }
  }
  &__video {
    position: relative;
    width: 100%;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(36, 36, 36, 0.3);
      z-index: 1;
    }
  }
  &__spacer {
    margin-top: 120px;
    flex-grow: 1;
    border-top: 1px solid #e1e1e1;
  }
  &__title {
    color: #fff;
    font-size: 120px;
    font-weight: 700;
    text-transform: uppercase;
    border-left: 1px solid #e1e1e1;
    border-top: 1px solid #e1e1e1;
    padding: 50px 170px 0 50px;
    margin: 120px 0 0 auto;
    width: 530px;
    position: relative;

    &::before {
      content: "04";
      font-size: 10px;
      line-height: 11px;
      color: #e1e1e1;
      display: block;
      position: absolute;
      top: -17px;
      left: -13px;
    }
  }
  &__text_wrapper {
    display: flex;
    flex-direction: column;
    flex-basis: 47%;

    @media screen and (max-width: 1024px) {
      flex-basis: 100%;
    }
  }
  &__text_spacer {
    height: 168px;
    margin-top: 120px;
    width: 70%;
    border-top: 1px solid #e1e1e1;
  }
  &__text {
    background-color: #fff;
    padding: 75px 70px 20px;
    min-height: 580px;

    @media screen and (max-width: 1024px) {
      min-height: 0;
      background-color: transparent;
      z-index: 50;
      position: relative;
      color: #fdfdfd;
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;
      padding: 0;
      width: 100%;
      justify-content: center;
      align-items: center;
      display: flex;
      text-align: center;
      height: 100%;
    }
  }
  &__text-container {
    padding: 0;
  }
  &__video-wrapper {
    position: relative;
    padding-bottom: 56.25%; // 16:9
    height: 0;
    overflow: hidden;
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.line_top {
  @include desktop {
    width: 1px;
    height: 1196px;
    position: absolute;
    background: #e1e1e1;
    bottom: calc(100% - 120px);
    z-index: 500;
    margin-left: auto;
    margin-right: auto;
    left: -43px;
    right: 0;
  }
}

//popup
.popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  transition: 0.6s;
}
.popup.is-show {
  opacity: 1;
  visibility: visible;
}
.popup-inner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 84%;
  max-width: 800px;
  padding: 50px;
  background-color: #fff;
  z-index: 2;
  text-align: center;

  @media only screen and (max-width: 780px) {
    padding: 15px;
  }

  &_title {
    font-size: 15px;
    line-height: 3;

    @media only screen and (max-width: 780px) {
      font-size: 11px;
    }
  }

  &_images {
    display: flex;
    justify-content: center;

    @media only screen and (max-width: 780px) {
      margin: 0;
    }

    &__onelove {
      width: 481px;
      height: auto;
      margin: 20px 0 20px 0;

      @media only screen and (max-width: 780px) {
        width: 256px;
      }
    }

    &__spin {
      margin-top: 25px;
      width: 97px;
      height: auto;
      transform: scale(1.1);
      cursor: pointer;
      filter: grayscale(100%);
      transition-duration: 0.5s;

      @media only screen and (max-width: 780px) {
        margin-top: 20px;
        width: 70px;
      }

      &:hover {
        filter: grayscale(0);
        transition-duration: 0.5s;
      }
    }

    &__fwfs {
      width: 319px;
      height: auto;
      transform: scale(1.1);
      cursor: pointer;
      filter: grayscale(100%);
      transition-duration: 0.5s;

      @media only screen and (max-width: 780px) {
        width: 250px;
      }
      &:hover {
        filter: grayscale(0);
        transition-duration: 0.5s;
      }
    }

    &__vibes {
      width: 106px;
      height: auto;
      margin-top: 25px;
      transform: scale(1.1);
      cursor: pointer;
      filter: grayscale(100%);
      transition-duration: 0.5s;

      @media only screen and (max-width: 780px) {
        width: 85px;
        margin-top: 20px;
      }

      &:hover {
        filter: grayscale(0);
        transition-duration: 0.5s;
      }
    }
  }

  &_anchor {
    float: right;
    margin-top: 30px;
    font-size: 15px;
    transition: ease-in 0.3s;

    &:hover {
      color: red;
    }
  }
}
.close-btn {
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  cursor: pointer;
}
.close-btn i {
  font-size: 20px;
  color: #333;
}
.black-background {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1;
  cursor: pointer;
}

.popup_arrows *,
.popup_arrows *:before,
.popup_arrows *:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.popup_arrows {
  position: relative;
  display: flex;
  height: 70px;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.popup_arrows .popup_arrow {
  position: relative;
  display: inline-block;
  padding-top: 1.8em;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  -webkit-animation: arrow-move06 2s 1s ease-in-out infinite;
  animation: arrow-move06 2s 1s ease-in-out infinite;
  text-decoration: none;
  color: #ff3c41;
  border-color: #ff3c41;
}
.popup_arrows .popup_arrow:before {
  line-height: 1.9em;
  position: absolute;
  top: 0;
  left: 50%;
  width: 2em;
  height: 2em;
  margin-left: -0.7em;
  content: "▼";
  -webkit-transition: transform 0.5s ease-in;
  transition: transform 0.5s ease-in;
  text-align: center;
  border: 0.13em solid #ffffff;
  border-color: inherit;
  border-radius: 10em;
}

@-webkit-keyframes arrow-move06 {
  0% {
    top: 1%;
    opacity: 0.1;
  }
  70% {
    top: 10%;
    opacity: 1;
  }
  100% {
    top: 1%;
    opacity: 0.3;
  }
}
@keyframes arrow-move06 {
  0% {
    top: 1%;
    opacity: 0.1;
  }
  70% {
    top: 20%;
    opacity: 1;
  }
  100% {
    top: 1%;
    opacity: 0.3;
  }
}

.pmark {
  // width: 120px;
  // height: 123.2px;
  width: 75px;
  height: 77px;
}
