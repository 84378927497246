@include desktop {
    .sft-header {
        width: 100%;
        height: 1000px;
        position: relative;

        &_title {
            font-size: 140px;
            font-weight: bold;
            position: absolute;
            top: 200px;
            left: calc(50% - 550px);
        }

        &_hr1 {
            background: transparent;
            width: 51%;
            height: 380px;
            border-bottom: .5px solid #e1e1e1;
            position: absolute;
        }

        &_vr1 {
            background: transparent;
            width: 49%;
            height: 970px;
            border-left: .5px solid #e1e1e1;
            border-bottom: .5px solid #e1e1e1;
            position: absolute;
            right: 0;
        }

        &_bgGray {
            background: #f8f8f8;
            width: 51%;
            height: 585px;
            /* margin-top: 480px; */
            position: absolute;
            top: 480px;
        }

        &_details {
            display: flex;
            position: relative;
            top: 457px;

            &__left {
                position: absolute;
                left: calc(50% - 555px);

                &--title1 {
                    font-size: 30px;
                    font-weight: bold;
                }

                &--p {
                    width: 500px;
                    padding-top: 75px;
                    font-size: 15px;
                    line-height: 1.5rem;
                }
            }
            
            &__right {
                position: absolute;
                right: calc(50% - 500px);

                &--title2 {
                    font-size: 30px;
                    font-weight: bold;
                }

                &--img {
                    padding-top: 84px;
                }

                &--detail {
                    font-size: 12px;
                    margin-top: 78px;
                    display: flex;
                    line-height: 1.5;

                    .sft_right__detail--left {

                    }

                    .sft_right__detail--right {
                        margin-left: 80px;
                    }
                }
            }
        }
    }

    .sft-border__body {
        background: transparent;
        border-left: .5px solid #e1e1e1;
        border-bottom: .5px solid #e1e1e1;
        width: calc(100% - 1225px);
        height: 1788px;
        position: absolute;
        right: 0;
        top: 970px;
    }

    .sft_title__whd {
        font-size: 60px;
        font-weight: bold;
        letter-spacing: inherit;
        position: relative;
        top: -40px
    }

    .sft_title__hawh {
        font-size: 60px;
        font-weight: bold;
        letter-spacing: inherit;
    }

    .sft-bgGray2 {
        width: 1226px;
        height: 722px;
        background: #f8f8f8;
        position: absolute;
        left: 0;
        top: 2035px;
        z-index: -1;
    }

    .sft_span {
        font-family: "Noto Sans JP";
        font-size: 12px;
        font-weight: 300;
    }
    .sft_bottom__spacer {
        margin-bottom: 100px
    }
}

@media screen and (max-width: 1024px) {
    .sft-header {
        position: relative;
        height: 850px;

        &_title {
            font-size: 100px;
            font-weight: bold;
            position: absolute;
            top: 120px;
            z-index: 1;
        }

        &_hr1 {
            width: 95%;
            height: 597px;
            background-color: #fff;
            position: absolute;
            top: 270px;
            right: 0;
            z-index: 0;
        }

        &_vr1 {
        }

        &_bgGray {
                width: 100%;
                height: 828px;
                background-color: #f8f8f8;
                position: absolute;
                top: 170px;
                z-index: -1;
        }

        &_details {
            &__left {
                &--title1 {
                    font-size: 20px;
                    font-weight: bold;
                    position: absolute;
                    top: 300px;
                    left: 6%;
                }

                &--p {
                    font-size: 12px;
                    line-height: 1.5;
                    width: 332px;
                    position: absolute;
                    top: 360px;
                    left: 6%;
                }
            }

            &__right {
                    position: absolute;
                    top: 580px;
                    left: 20%;

                &--title2 {
                    font-size: 20px;
                    font-weight: bold;
                    position: absolute;
                    top: -60px;
                    left: -27%;
                }

                &--img {
                    width: 197px;
                    height: 96px;
                }

                &--detail {
                    font-size: 12px;
                    position: absolute;
                    top: 150px;
                    display: flex;

                    .sft_right__detail--left {
                        width: 100px;
                    }
                }
            }
        }
    }

    .sft-border__body {
        // background: transparent;
        // border-left: .5px solid #e1e1e1;
        // border-bottom: .5px solid #e1e1e1;
        // width: 214px;
        // height: 1788px;
        // position: absolute;
        // right: 0;
        // top: 970px;
    }

    .sft_title__whd {
        font-size: 40px;
        font-weight: bold;
    }

    .sft_title__hawh {
        font-size: 40px;
        font-weight: bold;
        line-height: 1.6;
    }

    .sft-bgGray2 {
        width: 1226px;
        height: 722px;
        background: #f8f8f8;
        position: absolute;
        left: 0;
        top: 2035px;
        z-index: -1;
    }
    .sft_bottom__spacer {
        margin-bottom: 100px
    }
}