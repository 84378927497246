@keyframes snowflake-anim {
  from  { opacity: 0; transform: translate(var(--spawn-offset, calc(random() * 1vw)), var(--canvas-top, 1vh)) rotate(0deg); }
  50%   { opacity: 100; }
  to    { opacity: 0; transform: translate(var(--spawn-offset, calc(random() * 1vw)), var(--canvas-bottom, 100vh)) rotate(var(--fall-rotation, 360deg)); }
}

.effects-snowfall {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;

  .snowflake {
    position: absolute;
    animation: snowflake-anim var(--fall-duration, 5000ms) ease-out infinite;
  }
}
