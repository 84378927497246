#moff-2022 {
  //for header and sns logo
  @include desktop {
    overflow: unset !important;
  }
}
.moff_2022 {
  @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@900&display=swap');
  @import url("https://use.typekit.net/dym0kza.css");
  font-family: 'NotoSansCJKjp';
  @include desktop {
    overflow: unset !important;
    overflow-x: unset !important;
    overflow-y: unset !important;
    background-color:  #F7EFE0;
  }
  .moff_2022_container {
    @include desktop {
      position: relative;
      // top: -126px;
    }
  }
  .moff_phone_container{
    position: relative;
    background: #F7EFE0;
    display:none;
    @media only screen and (max-width: 1024px) {
      display: block;
    }
  }
  .moff_logo_phone{
    display:none;
    @media only screen and (max-width: 1024px) {
      display: block;
      width:66px;
      margin:auto;
      padding-top:90px;
    }
  }
  .gokan_letter_img_phone{
    display:none;
    @media only screen and (max-width: 1024px) {
      display: block;
      width:234px;
      margin:auto;
      padding-top:30px;
      padding-bottom:20px;
    }
  }

  .moff2019_video_container {
    position: relative;
    background: #F7EFE0;
    .video {
      width: 100%;
      height: 610px;
      object-fit: cover;
      @media only screen and (max-width: 1024px) {
        height:370px;
      }
      @media only screen and (max-width: 767px) {
        height:208px;
      }
    }
    .muted {
      position: absolute;
      left: 40px;
      bottom: 50px;
      width: 48px;
      height: 48px;
      display: grid;
      place-items: center;
      border-radius: 10px;
      background-color: #0c0c0c;
      opacity: 0.75;
      @media only screen and (max-width: 1024px) {
        width: 30px;
        height: 30px;
      }
      .mute_btn {
        cursor: pointer;
        width: 24px;
        height: 24px;
        @media only screen and (max-width: 1024px) {
          width: 15px;
          height: 15x;
        }
      }
    }
    .freewill_title_img_container{
      position:absolute;
      left:50%;
      top:80px;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      @media only screen and (max-width: 1024px) {
        display:none;
      }
    }
    .coin1{
      position:absolute;
      left: 7%;
      bottom:2px;
      @media only screen and (max-width: 1024px) {
        left: 5%;
        bottom: -6px;
        width: 37px;
        z-index: 1;
      }
    }
    .coin_shape1{
      z-index: 1;
      position: absolute;
      width: 66px;
      bottom: -66px;
      left: 7%;
      height: 24px;
      transform: rotateZ( 32deg );
      border-radius: 72% 80% 80% 80% / 80% 88% 65% 65%;
      background-color: #E5DCCF;
      @media only screen and (max-width: 1024px) {
        width: 35px;
        bottom: -27px;
        left: 5%;
        height: 14px;
      }
    }
    .coin2{
      position:absolute;
      right:14%;
      bottom:46px;
      @media only screen and (max-width: 1024px) {
        right: 12%;
        bottom: -8px;
        width: 19px;
        z-index: 1;
      }
    }
    .coin_shape2{
      z-index: 1;
      position: absolute;
      width: 53px;
      bottom: 21px;
      right: 14%;
      height: 13px;
      transform: rotateZ(-20deg);
      border-radius: 83% 80% 80% 80% / 80% 88% 81% 65%;
      background-color: #000000;
      opacity: 20%;
      @media only screen and (max-width: 1024px) {
       width: 21px;
        bottom: -22px;
        right: 11%;
        height: 5px;
        transform: rotateZ(-4deg);
      }
    }
  }
  .body_background_container{
    background-color:#F7EFE0;
    position:relative;
    // height:2000px;
    .moff_logo{
      width:158px;
      margin:auto;
      padding-top:24px;
      @media only screen and (max-width: 1024px) {
        display: none;
      }
    }
    .gokan_letter_img{
      position:absolute;
      width:556px;
      left:50%;
      top:250px;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      @media only screen and (max-width: 1024px) {
        display: none;
      }
    }
    .coin3{
      position:absolute;
      left:15%;
      top:273px;
      @media only screen and (max-width: 1024px) {
        display: none;
      }
    }
    .coin_shape3{
      z-index: 1;
      position: absolute;
      width: 61px;
      top: 379px;
      left: 15%;
      height: 13px;
      transform: rotateZ(15deg);
      border-radius: 83% 80% 80% 80% / 80% 88% 81% 65%;
      background-color: #F7EFE0;
      mix-blend-mode: multiply;
      @media only screen and (max-width: 1024px) {
        display: none;
      }
    }
    .coin4{
      position:absolute;
      right:15%;
      top:116px;
      @media only screen and (max-width: 1024px) {
        display: none;
      }
    }
    .coin_shape4{
      z-index: 1;
      position: absolute;
      width: 53px;
      top: 200px;
      right: 15%;
      height: 13px;
      transform: rotateZ(0deg);
      border-radius: 83% 80% 80% 80% / 80% 88% 81% 65%;
      background-color: #000000;
      opacity: 20%;
      @media only screen and (max-width: 1024px) {
        display: none;
      }
    }
    .moff_img_container{
      position: relative;
      display: flex;
      justify-content: center;
      top: 277px;
      @media only screen and (max-width: 1024px) {
        top:0;
        padding-top:9px;
      }
      .moff_date_img{
        margin-top: 170px;
        width:229px;
        @media only screen and (max-width: 1024px) {
          width:121px;
          margin-top: 0px;
        }
      }
      .date_img{
        margin-left:20px;
        @media only screen and (max-width: 1024px) {
          margin-left: 12%;
          margin-top: 38%;
        }
        @media only screen and (max-width: 600px) {
          margin-left: 29%;
          margin-top: 101%;
        }
        @media only screen and (max-width: 767px) {
          margin-left: 10%;
          margin-top: 75%;
        }
        @media only screen and (max-width: 455px) {
          margin-left: 53%;
          margin-top: 111%;
        }
      }
      .moff_tree_img{
        width:394px;
        z-index:10;
        @media only screen and (max-width: 1024px) {
          display:none;
        }
      }
      .moff_tree_img_phone{
        display: none;
        z-index:10;
        @media only screen and (max-width: 1024px) {
          display:block;
          width: 165px;
        }
      }
      .moff_hotel_img{
        margin-top: 170px;
        width:229px;
        z-index:5;
        @media only screen and (max-width: 1024px) {
          width: 132px;
          margin-top:0px;
        }
      }
      .hotel_img{
        margin-left:-7px;
        @media only screen and (max-width: 1024px) {
          margin-left: -4%;
          margin-top: 39%;
        }
        @media only screen and (max-width: 767px) {
          margin-left: -6%;
          margin-top: 75%;
        }
        @media only screen and (max-width: 600px) {
          margin-left: -4%;
          margin-top: 74%;
        }
        @media only screen and (max-width: 455px) {
          margin-left: -37%;
          margin-top: 102%;
        }
      }
      .hotel_address_img{
        padding-top:17px;
        width:242px;
        margin-left:-9px;
        @media only screen and (max-width: 1024px) {
          padding-top:6px;
          margin-left: -5%;
        }
        @media only screen and (max-width: 767px) {
          margin-left: -7%;
        }
        @media only screen and (max-width: 600px) {
          margin-left: -5%;
        }
        @media only screen and (max-width: 455px) {
          margin-left:-38%;
        }
      }
    }
  }
  .background_img_container{
    position:relative;
    @media only screen and (max-width: 1024px) {
      margin-top: -15%;
    }
    .background_img{
      position:absolute;
      top:70px;
      max-width: 100%;
      min-height: 1357px;
      object-fit: cover;
      @media only screen and (max-width: 1024px) {
        // min-height: 1056px;
        // top:-36px;
        display: none;
      }
      &_body{
        position: relative;
        max-height: 1357px;
        overflow-y: hidden;
        z-index:10;
        @media only screen and (max-width: 1024px) {
          position: relative;
          max-height: 1140px;
          overflow-y: hidden;
        }
      }
    }
    .background_img_phone{
      position:absolute;
      // top:70px;
      // max-width: 100%;
      // min-height: 1357px;
      // object-fit: cover;
      display:none;
      @media only screen and (max-width: 1024px) {
        min-height: 1056px;
        display: block;
        object-fit:cover;
      }
    }
  }
  .grid_container{
    position: relative;
    display: flex;
    padding-top: 340px;
    padding-bottom:50px;
    padding-left: 12%;
    @media only screen and (max-width: 1024px) {
      padding-right: 20px;
      padding-left: 20px;
      display: block;
      padding-top: 25%;
      text-align: center;
      padding-bottom: 65px;
    }
    .gokan_letter_img2_container{
      @media only screen and (max-width: 1024px) {
        padding-right: 41px;
      }
    }
    .gokan_letter_img2{
      width: 491px;
      /* margin-left: 40%; */
      padding-right: 40px;
      @media only screen and (max-width: 1024px) {
      margin-left: 0px;
      padding-right: 0px;
      width: 297px;
      }
    }
    .moff_about_body{
      font-weight: bold;
      color: #F7EFE0;
      font-size: 20px;
      line-height: 32px;
      font-family: 'NotoSansCJKjp';
      /* padding-left: 23%; */
      padding-right: 40px;
      padding-left: 12%;
      @media only screen and (max-width: 1024px) {
        text-align: left;
        font-size: 14px;
        line-height: 24px;
        padding-left: 0px;
        padding-right: 0px;
        margin-left: auto;
        margin-right: auto;
        padding-top: 34px;
        max-width: 341px;
        }
    }
  }

  .bottom {
    background-color: #F7EFE0;
    padding-top: 140px;
    padding-left: 12.5%;
    padding-right: 12.5%;
    // @media screen and (max-width: 1200px) {
    //   padding-left: 100px;
    //   padding-right: 100px;

    // }
    @media screen and (max-width: 1024px) {
      padding-left: 37px;
      padding-right: 37px;

    }
    &__ethical {
      &--text {
        margin-bottom: 40px;
        font-size: 40px;
        font-weight: 800;
        line-height: 54px;
        // @media screen and (max-width: 1200px) {
        //   font-size: 30px;
        //   margin-bottom: 20px;
        //  }
        @media screen and (max-width: 1024px) {
         font-size: 22px;
         margin-bottom: 32px;
         line-height: 34px;
        }
      }
      &--textone {
        font-size: 20px;
        line-height: 34px;
        font-weight: 500;
        @media screen and (max-width: 1024px) {
          font-size: 14px;
          line-height: 24px;
         }
      }

    }
    .style {
      color: #347F6C;
      font-weight: 700;
    }
    .underline {
      text-decoration: underline  #347F6C 5px;


    }
    .height {
      line-height: 34px;
    }

    &__event {
      margin-top: 200px;
      @media screen and (max-width: 1024px) {
        margin-top: 119px;
       }
      &__outline {
        margin-bottom: 50px;
        font-size: 40px;
        font-weight: 600;
        @media screen and (max-width: 1024px) {
          margin-bottom: 32px;

         }
        // @media screen and (max-width: 1200px) {
        //   font-size: 30px;

        //  }
        @media screen and (max-width: 1024px) {
          font-size: 22px;

         }
      }
      &__title {
        margin-bottom: 50px;
        @media screen and (max-width: 1024px) {
          margin-bottom: 32px;
         }
      }
      &__sub-title {
        margin-bottom: 16px;
        font-size: 20px;
        font-weight: 400;
        @media screen and (max-width: 1024px) {
          font-size: 14px;
          line-height: 28px;
          margin-bottom: 8px;

         }
      }
      &__name--text {
        font-size: 24px;
        font-weight: 600;
        line-height: 28px;
        @media screen and (max-width: 1024px) {
          font-size: 18px;
         }
      }
    }
    &__map {
      margin-top: 110px;
      @media screen and (max-width: 1024px) {
        margin-top: 120px;
       }
      &--title {
        font-size: 40px;
        margin-bottom: 103px;
        font-weight: 600;
        // @media screen and (max-width: 1200px) {
        //   font-size: 30px;
        //   margin-bottom: 40px;
        //  }
        @media screen and (max-width: 1024px) {
          font-size: 22px;
          margin-bottom: 40px;
         }

      }
      &--grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 84px;
        @media screen and (max-width: 1024px) {
          display: grid;
          grid-template-columns: repeat(1, 1fr);
         }
      }
      &--content {
        .image__wrapper {
          position: relative;
          max-width: 609px;
          max-height: 289px;
          @media screen and (max-width: 1024px) {
            margin-bottom: 47px;

          }
          .img {
            width: 100%;
            object-fit: cover;
            height: auto;
            mix-blend-mode: multiply;
          }
        }
        .image__wrapper::after {
          position: absolute;
          content: "";
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          // background-color: #F7EFE0;
          opacity: 30%;
          .img {
            width: 100%;
            object-fit: cover;
            height: auto;

          }
        }
        .title {
          font-size: 24px;
          font-weight: 600;
          color: #347F6C;
          // @media screen and (max-width: 1200px) {
          //   font-size: 30px;
          // }
          @media screen and (max-width: 1024px) {
            font-size: 20px;
            color: #347F6C;

          }
        }
        .below_one {
          margin-bottom: 24px !important;
          // @media screen and (max-width: 1200px) {
          //   margin-bottom: 30px !important;

          // }
          @media screen and (max-width: 1024px) {
            margin-bottom: 24px !important;

          }
        }
        .below_two {
          margin-bottom: 16px !important;
          // @media screen and (max-width: 1200px) {
          //   margin-bottom: 30px !important;

          // }
          @media screen and (max-width: 1024px) {
            margin-bottom: 18px !important;

          }
        }
        .sub-title {
          font-size: 32px;
          line-height: 46px;
          font-weight: 600;
          // @media screen and (max-width: 1200px) {
          //   font-size: 30px;
          // }
          @media screen and (max-width: 1024px) {
            font-size: 18px;
            line-height: 34px;
          }
        }
        .text {
          font-size: 16px;
          line-height: 26px;
          @media screen and (max-width: 1024px) {
            font-size: 14px;
          }
        }
      }
      .below {
        margin-bottom: 85px;
        @media screen and (max-width: 1024px) {
          margin-bottom: 18px;
        }

      }
      .lower {
        margin-bottom: 148px;
        @media screen and (max-width: 1024px) {
          margin-bottom: 60px;
         }

      }
    }

  }
  .bottomtwo {
    background-color: #347F6C;
    padding-top: 120px;
    @media screen and (max-width: 1024px) {
      padding-top: 21px
     }

    .hundred {
      display: flex;
      // display: grid;
      // grid-template-columns: repeat(2, 1fr);
      // grid-column-gap: 84px;
      margin-right: 12.5%;
      margin-left: 12.5%;
      padding-bottom: 82px;
      // @media screen and (max-width: 1200px) {
      //   display: flex;
      //   margin-right: 100px;
      //   margin-left: 100px;
      //   // display: grid;
      //   // grid-template-columns: repeat(1, 1fr);

      //  }
      @media screen and (max-width: 1024px) {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        margin-right: 37px;
        margin-left: 37px;

       }
      &__image {
        .image__wrapper {
          max-width: 225px;
          max-height: 111px;
          padding-right: 92px;
          @media screen and (max-width: 1024px) {
            margin-bottom: 32px;
            max-width: 164px;
            max-height: 72px;
            padding-right: unset;
           }

        }
      }
      &__content {
        &--list {
          color: #F7EFE0;
          .item {
            // white-space: nowrap;
            content: "";
            position: relative;
            margin-bottom: 40px;
            font-size: 32px;
            font-weight: 600;
            padding-left: 103px;
            // @media screen and (max-width: 1200px) {
            //   font-size: 30px;
            //  }
            @media screen and (max-width: 1024px) {
            font-size: 18px;
            padding-left: 14px;
            }
            &__content {
              font-size: 18px;
              margin-top: 12px;
              font-weight: 400 !important;
              padding-left: 39px;
              @media screen and (max-width: 1024px) {
                font-size: 12px;
                color: #94D1BA;
                padding-left: unset;
               }
            }
          }
          .item1 {
            content: "";
            position: relative;
            margin-bottom: 40px;
            font-size: 32px;
            font-weight: 600;
            padding-left: 103px;
            // @media screen and (max-width: 1200px) {
            //   font-size: 30px;
            //  }
            @media screen and (max-width: 1024px) {
            font-size: 18px;
            padding-left: 14px;
            }
            &__content {
              font-size: 18px;
              margin-top: 12px;
              font-weight: 400 !important;
              padding-left: 39px;
              @media screen and (max-width: 1024px) {
                font-size: 12px;
                color: #94D1BA;
                padding-left: unset;
               }
            }
          }
          .item::before {
            content: "";
            position: absolute;
            width: 36px;
            height: 36px;
            border-radius: 50%;
            border: 2px solid;
            border-radius: 50%;
            top: -17px;
            left: -38px;
            transform: translate(50%, 50%);
            @media screen and (max-width: 1024px) {
              width: 18px;
              height: 18px;
              left: -20px;
              top: -8px;
             }
            &__content {

              @media screen and (max-width: 1024px) {

               }
            }
          }
          .item1::before {
            content: "";
            position: absolute;
            width: 36px;
            height: 36px;
            border-radius: 50%;
            border: 2px solid;
            border-radius: 50%;
            top: -17px;
            left: -38px;
            transform: translate(50%, 50%);
            @media screen and (max-width: 1024px) {
              width: 18px;
              height: 18px;
              left: -20px;
              top: -8px;
             }
            &__content {

              @media screen and (max-width: 1024px) {

               }
            }
          }
          .item::after {
            content: "";
            position: absolute;
            height: 67px;
            border: 1px dotted;
            top: -100px;
            left: -4px;
            z-index: 1;
            transform: translate(50%, 50%);
            @media screen and (max-width: 1024px) {
              height: 66px;
              left: -4px;
              top: -98px;

             }
            &__content {

              @media screen and (max-width: 1024px) {

               }
            }
          }
          .item--small {
            font-size: 24px;
            @media screen and (max-width: 1024px) {
              font-size: 13px;

            }
          }

        }

      }
    }
  }
  .bottomthree {
    background-color: #F7EFE0;
    padding-top: 35px;
    display: grid;
    // justify-content: center;
    .thousand {
      display: grid;
      justify-content: center;
      @media screen and (max-width: 1024px) {
        margin-bottom: 70px;
       }
      .background__image {
        position: relative;
        background-color: #E3DAC9;
        border-radius: 29px;
        padding-bottom: 28px;
        @media screen and (max-width: 1024px) {
          padding-bottom: 15px;
         }
        .image__wrapper {
          max-width: 615px;
          max-height: 582px;
          @media screen and (max-width: 1024px) {
            max-width: 316px;
            max-height: 369px;

          }
          .text_one {
            position: absolute;
            top: 29%;
            font-weight: 500;
            font-size: 24px;
            right: 43%;
            color: #242424;
            @media screen and (max-width: 1024px) {
              top: 26%;
              font-size: 14px;
              right: 42%;


            }

          }
          .text_two {
            position: absolute;
            top: 38%;
            right: 41%;
            font-size: 40px;
            font-weight: 700;
            color: #242424;
            @media screen and (max-width: 1024px) {
              top: 31.5%;
              right: 33%;
              font-size: 40px;
            }

          }
          .text_three {
            position: absolute;
            top: 49.5%;
            font-size: 16px;
            text-align: center;
            line-height: 26px;
            padding-left: 15%;
            padding-right: 15%;
            color: #242424;
            @media screen and (max-width: 1024px) {
              top: 45.5%;
              font-size: 10px;
              text-align: unset;
              padding-left: 14%;
              line-height: 20px;
              padding-right: 7%;
             }

          }
          .text_four {
            position: absolute;
            top: 53%;
            right: 34%;
            font-size: 18px;
            color: #242424;
            @media screen and (max-width: 1024px) {
              top: 54.5%;
              font-size: 14px;
              right: 22%;

             }


          }
          .bold {
            font-weight: 600;
          }
          .text_five {
            position: absolute;
            top: 68%;
            right: 22%;
            font-size: 24px;
            font-weight: 700;
            color: #242424;
            @media screen and (max-width: 1024px) {
              position: absolute;
              top: 68%;
              right: 20%;
              color:#242424;
              font-size: 14px;

            }

          }
          .text_six {
            position: absolute;
            top: 76%;
            right: 35%;
            font-size: 14px;
            font-weight: 500;
            color: #999DA5;
            @media screen and (max-width: 1024px) {
              position: absolute;
              top: 77%;
              right: 31%;
              font-size: 10px;

            }

          }
        }
        .background__text {

        }
        .text {
          text-align: center;
          color: #347F6C;
          font-size: 20px;
          @media screen and (max-width: 1024px) {
            font-size: 14px;
           }
        }
      }
    }
    .behind {
      position: relative;
      display: block;
      background: url(https://assets.free-will.co/images/moff-2022/organizer_background.png);
      background-repeat: no-repeat;
      background-position: top right;
      background-size: 1930px 1594px;
      z-index: 1;
      @media screen and (max-width: 386px) {
        position: relative;
        display: block;
        background: url(https://assets.free-will.co/images/moff-2022/mobile_background.svg);
        background-repeat: no-repeat;
        background-position: top right;
        background-size: 386px 1504px;
        z-index: 1;

       }
      .organizer {
        position: relative;
        display: grid;
        padding-top: 227px;
        margin-top: 80px;
        justify-content: center;

        &__text {
          text-align: center;
          margin-bottom: 39px;
          color: #303030;
          font-size: 24px;
          font-weight: 600;
          @media screen and (max-width: 1024px){
            font-size: 20px;
            margin-bottom: 24px;

          }


        }
        &__texttwo {
          text-align: center;
          margin-bottom: 40px;
          color: #303030;
          font-size: 24px;
          font-weight: 600;
          margin-top: 100px;
          @media screen and (max-width: 1024px){
            font-size: 20px;
            margin-top: 60px;
            margin-bottom: 20px;

          }
        }
        &__logo {
          max-width: 140px;

          .img {

          }
        }
      }
      .co-sponsor  {
        display: grid;
        justify-items: center;
        margin-left: 12.5%;
        margin-right: 12.5%;
        // @media screen and (max-width: 1200px) {
        //   margin-left: 37px;
        //   margin-right: 37px;
        //  }
        @media screen and (max-width: 1024px) {
          margin-left: 37px;
          margin-right: 37px;
         }
        &__container {
          position: relative;
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          grid-column-gap: 30px;
          grid-row-gap: 40px;
          margin-bottom: 80px;
          @media screen and (max-width: 1024px) {
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 10px;
            grid-row-gap: 8px;
            margin-bottom: 40px;

           }
          .sponsor__logo {
            max-width: 249px;
            display: flex;
            align-items: center;
            @media screen and (max-width:1024px) {
              max-width: 146px;
              max-height: 146px;

            }
            .img {
              mix-blend-mode: multiply;
            }
          }
          .padding_top {
            padding-top: 38%;

            @media screen and (max-width:1024px) {
              padding-top: unset;
            }
            .img {

            }
          }
        }
        .city__text {
          margin-bottom: 41px;
          text-align: center;
          font-size: 24px;
          font-weight: 600;

        }
        .city__logo {
          margin-bottom: 200px;
          display: flex;
          column-gap: 60px;
          justify-content: center;

          @media screen and (max-width:1024px) {
            column-gap: 30px;
          }

          .logo-city{
            max-width: 274px;
            max-height: 98px;
            mix-blend-mode: multiply;
            @media screen and (max-width:1024px) {
              max-width: 113px;
              max-height: 40px;
            }
          }
          .logo-shouse{
            max-width: 360px;
            mix-blend-mode: multiply;
            margin-top: -126px;
            @media screen and (max-width:1024px) {
              max-width: 140px;
              margin-top: -45px;
            }
          }

        }
      }
    }

  }

  @media screen and (min-width: 1025px) {
    .u-sp-only_moff2022{
      display: none !important;
    }
  }

  @media screen and (max-width: 1024px) {
    .u-pc-only_moff2022 {
      display: none !important;
    }
  }

}
