#moff-2021 {
  //for header and sns logo
  @include desktop {
    overflow: unset !important;
  }
  .moff_hidden {
    display: none;
    @media screen and (max-width: 1024px) {
      display: block;
    }
  }
  .moff_block {
    display: block;
    @media screen and (max-width: 1024px) {
      display: none;
    }
  }
  .link {
    fill: #ffffff;
    &:hover {
      fill: $red;
    }
  }
}

.moff_2021 {
  @import url("https://fonts.googleapis.com/css2?family=PT+Sans+Narrow:wght@400;700&display=swap");
  @import url("https://use.typekit.net/dym0kza.css");
  font-family: "PT Sans Narrow", sans-serif;
  @include desktop {
    overflow: unset !important;
    overflow-x: unset !important;
    overflow-y: unset !important;
  }
  .moff_2021_container {
    @include desktop {
      position: relative;
      // top: -126px;
    }
  }
  .moff2019_video_container {
    background: #202641;
    .video {
      width: 100%;
      height: 100vh;
      object-fit: cover;
    }
  }
  .ses_video {
    margin: 40px auto 0;
    @include desktop {
      width: 640px;
    }
    .video__wrapper {
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      height: 0;
      .video__content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .moff_image {
    position: relative;
    text-align: center;
    display: flex;
    justify-content: center;
    height: 100vh;
    max-height: 980px;
    background: transparent
      radial-gradient(closest-side at 50% 50%, #353d5f 0%, #202641 100%) 0% 0%
      no-repeat padding-box;
    @media screen and (max-width: 768px) {
      height: calc(100vh - 60px);
      margin-top: 60px;
    }
    &_hand {
      // top: 169px;
      top: 8.8vw;
      max-width: 790px;
      height: 90%;
      // width: 41.15vw;
      animation: translate-y 5s, fadeinout 5s linear;
      @media screen and (max-width: 768px) {
        animation: unset;
      }
      // @media screen and (max-width: 425px) {
      //   height: 97vh;
      // }
    }
    &_logo {
      position: absolute;
      top: 58%;
      // width: 35vh; without gradient
      // max-width: 352px;
      width: 28vh;
      max-width: 313px;
      @media screen and (max-width: 768px) {
        width: 25vh;
      }
    }

    @keyframes translate-y {
      0% {
        transform: translateY(-40px);
      }
    }

    @keyframes fadeinout {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }
  }
  .container {
    position: absolute;
    bottom: 2%;
    .countdown_container {
      display: flex;
      justify-content: center;
      font-weight: bold;
      color: #a5e8b5;
      .text {
        display: inline-block;
        // font-size: 24px;
        font-size: calc(min(2vh, 24px));
        padding: calc(min(2vh, 20px));
      }
      .number {
        position: relative;
        display: block;
        // font-size: 60px;
        font-size: calc(min(6vh, 60px));
      }
      .seperator {
        &::after {
          position: absolute;
          top: -5%;
          left: 120%;
          content: ":";
        }
      }
    }
    .live {
      font-size: calc(min(6vh, 60px));
      font-weight: bold;
      padding: calc(min(2vh, 20px));
      color: #a5e8b5;
      display: none;
    }
  }

  .moff_content {
    position: relative;

    .btn_apply {
      position: relative;
      left: 50%;
      transform: translate(-50%, 30%);
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 60%;
      height: 60px;
      border-radius: 10px;
      background-color: #a5e8b5 !important;
      .text {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: normal;
        color: #202641;
      }
    }
    .btn_apply_faq {
      width: 40% !important;
      transform: translate(-50%, 0%) !important;
      margin-bottom: 20px;
    }
  }
  .moff_background {
    position: absolute;
    height: 100%;
    width: 100%;
    background: #202641;
    z-index: -2;
  }
  .moff_thankyou {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #ffffff;
    font-family: "PT Sans Narrow";
    margin: 0 5vw;
    font-size: 16px;
    line-height: 34px;
    @media screen and (max-width: 477px) {
      font-size: 14px;
      line-height: normal;
      text-align: left;
    }
    .url {
      color: #a5e8b5;
      text-decoration: underline !important;
      &:hover {
        opacity: 0.8;
      }
    }
    .btn_green {
      margin-top: 60px;
      width: 80%;
      background-color: #a5e8b5 !important;
      @include desktop {
        width: 400px;
      }
      .text {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: normal;
        color: #202641;
      }
    }
  }
  .moff_about {
    padding-top: 160px;
    text-align: center;
    color: #ffffff;
    font-family: "PT Sans Narrow";
    margin: 0 5vw;
    &_title {
      color: #a5e8b5;
      font-weight: bold;
      font-size: 32px;
    }
    &_subtitle {
      font-size: 80px;
      font-weight: bold;
      margin: 40px 0;
      @media screen and (max-width: 477px) {
        font-size: 36px;
      }
    }
    &_body {
      font-size: 16px;
      line-height: 34px;
      @media screen and (max-width: 477px) {
        font-size: 14px;
        line-height: normal;
        text-align: left;
      }
    }
  }
  .moff_schedule {
    margin-top: 164px;
    margin-bottom: 47px;
    color: #ffffff;
    @include desktop {
      overflow-x: hidden;
    }
    &_title {
      color: #a5e8b5;
      font-size: 32px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 80px;
    }
    &_premoff {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      width: 196px;
      margin-bottom: 40px;
    }
    &_body {
      display: grid;
      grid-template-columns: 60px 812px;
      column-gap: 70px;
      row-gap: 100px;
      justify-content: center;
      @media screen and (max-width: 1024px) {
        grid-template-columns: 60px 1fr;
        column-gap: 70px;
        row-gap: 100px;
        margin: 0 5vw;
      }
      @media screen and (max-width: 477px) {
        grid-template-columns: unset;
        display: flex;
        flex-direction: column;
        align-items: center;
        column-gap: unset;
        row-gap: unset;
        text-align: center;
      }
    }
    &_label {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #a5e8b5;
      background: transparent linear-gradient(180deg, #202641 0%, #3a405d 100%)
        0% 0% no-repeat padding-box;
      width: 60px;
      height: 106px;
      @media screen and (max-width: 477px) {
        margin-top: 40px;
      }
      &::before {
        position: absolute;
        bottom: 0;
        left: 0;
        content: "";
        background: #a5e8b5;
        width: 100%;
        height: 4px;
      }
      &::after {
        z-index: -1;
        position: absolute;
        left: 2px;
        content: "";
        width: 50%;
        height: 90%;
        box-shadow: -7px 0px 5px #848789;
        mix-blend-mode: multiply;
        transform: rotate(3deg);
      }
    }
    &_datetime {
      display: flex;
      color: #ffffff;
      font-family: "futura-pt-bold";
      margin-top: 35px;
      @media screen and (max-width: 477px) {
        margin-top: 20px;
        margin-bottom: 40px;
      }

      .schedule_date_container {
        position: relative;
        display: flex;
        flex-direction: column;
        .schedule {
          &_month {
            font-size: 40px;
            font-weight: bold;
            @media screen and (max-width: 768px) {
              font-size: 27px;
            }
          }
          &_date {
            font-size: 170px;
            font-weight: bold;
            @media screen and (max-width: 768px) {
              font-size: 117px;
            }
          }
          &_superscript {
            position: absolute;
            top: 22%;
            left: 83%;
            font-size: 40px;
            font-weight: bold;
            background: #202641;
            padding: 3px;
            @media screen and (max-width: 768px) {
              font-size: 27px;
            }
          }
          &_day {
            font-family: "futura-pt";
            font-weight: 500;
            font-size: 24px;
            @media screen and (max-width: 768px) {
              font-size: 16px;
            }
          }
        }
      }
      .schedule_time_container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: #ffffff;
        font-family: "futura-pt-bold";
        font-size: 50px;
        padding-left: 85px;
        padding-bottom: 30px;
        @media screen and (max-width: 768px) {
          font-size: 27px;
        }
        .schedule {
          &_start {
            position: relative;
            left: -6px;
          }
          &_end {
            position: relative;
            bottom: 0;
            &::before {
              content: "";
              position: absolute;
              top: -80px;
              left: 62px;
              background: #757c9f;
              width: 4px;
              height: 65px;
              @media screen and (max-width: 768px) {
                top: -69px;
                left: 31px;
              }
            }
          }
        }
      }
    }
    &_place {
      font-family: "PT Sans Narrow";
      margin-top: 35px;
      line-height: normal;
      @media screen and (max-width: 477px) {
        margin-top: 20px;
        margin-bottom: 40px;
      }
      &_online {
        font-size: 40px;
        font-weight: bold;
        @media screen and (max-width: 477px) {
          font-size: 32px;
        }
      }
      &_notice {
        font-size: 16px;
        margin-bottom: 60px;
        @media screen and (max-width: 477px) {
          font-size: 13px;
        }
      }
      &_or {
        font-size: 16px;
        position: relative;
        left: 138px;
        margin: 5px 0;
        @media screen and (max-width: 477px) {
          font-size: 13px;
          left: unset;
        }
      }
      &_hotel {
        font-size: 32px;
        font-weight: bold;
        @media screen and (max-width: 477px) {
          font-size: 24px;
        }
      }
      &_ballroom {
        font-size: 16px;
        font-weight: bold;
        @media screen and (max-width: 477px) {
          font-size: 13px;
        }
      }
      &_address {
        font-size: 18px;
        font-weight: bold;
        // margin-bottom: 80px;
        @media screen and (max-width: 477px) {
          font-size: 16px;
          // margin-bottom: 30x;
        }
      }
    }
    &_right {
      margin-top: 35px;
      line-height: 32px;
      font-family: "PT Sans Narrow";
      font-size: 18px;
      font-weight: bold;
      @media screen and (max-width: 477px) {
        font-size: 13px;
        margin-top: 20px;
      }
      &_notice {
        font-size: 16px;
        font-weight: normal;
        line-height: normal;
        @media screen and (max-width: 477px) {
          font-size: 13px;
        }
      }
    }
  }
  .moff_timetable {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 5vw;
    @include desktop {
      overflow-x: hidden;
    }

    &_title {
      color: #a5e8b5;
      font-family: "PT Sans Narrow";
      font-size: 32px;
      font-weight: bold;
      margin-bottom: 50px;
    }
    &_right {
      position: relative;
      display: flex;
      // justify-content: space-between;
      align-items: center;
      width: 785px;
      height: 250px;
      background: transparent linear-gradient(90deg, #202641 0%, #3a405d 100%)
        0% 0% no-repeat padding-box;
      margin-bottom: 9px;
      padding: 0 10px;
      @media screen and (max-width: 768px) {
        display: unset;
        background: unset;
        justify-content: space-between;
        width: 100%;
        height: auto;
        margin-bottom: unset;
        align-items: center;
        border-bottom: 1px solid #707070;
        padding: 30px 0;
      }
      &::after {
        z-index: -1;
        position: absolute;
        content: "";
        bottom: 15px;
        right: 10px;
        left: auto;
        width: 50%;
        top: 80%;
        background: #848789;
        box-shadow: 0 15px 10px #848789;
        transform: rotate(3deg);
        mix-blend-mode: multiply;
        @media screen and (max-width: 768px) {
          display: none;
        }
      }
    }
    &_left {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 785px;
      height: 250px;
      background: transparent linear-gradient(270deg, #202641 0%, #3a405d 100%)
        0% 0% no-repeat padding-box;
      margin-bottom: 9px;
      padding-left: 28px;
      padding-right: 80px;
      @media screen and (max-width: 768px) {
        display: unset;
        background: unset;
        width: 100%;
        height: auto;
        padding-left: unset;
        padding-right: unset;
        margin-bottom: unset;
        align-items: center;
        border-bottom: 1px solid #707070;
        padding: 30px 0;
      }
      &::after {
        z-index: -1;
        position: absolute;
        content: "";
        bottom: 15px;
        left: 10px;
        right: auto;
        width: 50%;
        top: 80%;
        background: #848789;
        box-shadow: 0 15px 10px #848789;
        transform: rotate(-3deg);
        mix-blend-mode: multiply;
        @media screen and (max-width: 768px) {
          display: none;
        }
      }
    }

    &_time {
      position: relative;
      color: #a5e8b5;
      font-weight: bold;
      @media screen and (max-width: 768px) {
        display: none;
      }
      &_hour {
        font-size: 140px;
        font-family: "futura-pt-bold";
        @media screen and (max-width: 768px) {
          font-size: 59px;
        }
      }
      &_min {
        position: absolute;
        top: 36%;
        left: 90%;
        font-family: "Real Head Pro";
        font-size: 50px;
        z-index: 1;
        @media screen and (max-width: 768px) {
          top: 12%;
          font-size: 21px;
        }
        &::after {
          position: absolute;
          top: 4px;
          left: 0;
          content: "";
          width: 40px;
          height: 44px;
          background: #232946;
          z-index: -1;
          @media screen and (max-width: 768px) {
            width: 17px;
            height: 18px;
          }
        }
      }
    }
    &_description {
      // margin-left: 100px;
      font-family: "PT Sans Narrow";
      @media screen and (max-width: 768px) {
        margin-top: unset;
      }
      &_time {
        font-size: 24px;
        font-weight: bold;
        color: #a5e8b5;
        margin-bottom: 10px;
      }
      &_title {
        font-size: 30px;
        font-weight: bold;
        color: #ffffff;
        @media screen and (max-width: 768px) {
          font-size: 18px;
        }
      }
      &_body {
        font-size: 14px;
        line-height: 20px;
        color: #9fa4bc;
        @media screen and (max-width: 768px) {
          margin-top: 15px;
          font-size: 14px;
        }
      }
      &_close {
        position: relative;
        right: -415px;
        color: #a5e8b5;
        font-size: 50px;
        font-weight: bold;
        @media screen and (max-width: 768px) {
          color: #ffffff;
          font-size: 40px;
          right: unset;
        }
      }
      &_mc {
        font-size: 14px;
        font-weight: bold;
        color: #9fa4bc;
        padding-top: 10px;
      }
      &_speakers_title {
        font-size: 14px;
        font-weight: bold;
        color: #a5e8b5;
        margin: 10px 0;
      }
      &_speakers {
        display: flex;
        flex-direction: row;
        @media screen and (max-width: 768px) {
          display: grid;
          grid-template-columns: 1fr auto;
          row-gap: 4px;
        }
        .speaker_container {
          margin-right: 25px;
          @media screen and (max-width: 768px) {
            display: flex;
            margin-right: unset;
          }
        }
        .speaker_container_small {
          margin-right: 20px !important;
        }
        .speaker_image {
          width: 45px;
          height: 45px;
          margin-right: 10px;
          border-radius: 5%;
          object-fit: cover;
        }
        .name {
          color: #ffffff;
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 5px;
          @media screen and (max-width: 768px) {
            font-size: 14px;
          }
        }
        .name-small {
          font-size: 14px !important;
        }
        .company {
          font-size: 14px;
          color: #a5e8b5;
          line-height: 17px;
          @media screen and (max-width: 768px) {
            font-size: 13px;
          }
        }
      }
      .margin_b15 {
        margin-bottom: 15px;
      }
      .padding_extra {
        @media screen and (min-width: 768px) {
          padding-top: 25px;
          padding-bottom: 10px;
        }
      }
    }
    .background_white {
      background: #ffffff;
      padding: 5px;
    }
    .right_margin {
      @media screen and (min-width: 769px) {
        margin-left: 100px;
      }
    }
    &_logo {
      position: absolute;
      top: 203px;
      left: calc(50% + 245px);
      height: 80%;
      z-index: -2;
    }
    &_note {
      font-family: "PT Sans Narrow";
      font-size: 14px;
      color: #7e85a5;
      line-height: normal;
      margin-top: 30px;
      @media only screen and (max-width: 768px) {
        padding: 0 10vw;
        font-size: 13px;
      }
    }
  }

  .moff_speakers {
    display: grid;
    // grid-template-columns: repeat(3, 400px);
    grid-template-columns: repeat(3, 1fr);
    max-width: 1320px;
    column-gap: 60px;
    row-gap: 90px;
    justify-content: center;
    font-family: "PT Sans Narrow";
    margin: 0 auto 90px auto;
    padding: 0 60px;
    @media only screen and (max-width: 1024px) {
      grid-template-columns: repeat(2, 1fr);
      padding: 0 30px;
      column-gap: 30px;
      row-gap: 60px;
    }
    @media only screen and (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
      padding: 0 30px;
      row-gap: 60px;
      margin: 0 auto 60px auto;
    }
  }
  .moff_speaker {
    &_title {
      text-align: center;
      color: #a5e8b5;
      font-family: "PT Sans Narrow";
      font-size: 32px;
      font-weight: bold;
      margin-bottom: 50px;
    }
    &_image {
      object-fit: cover;
      width: 400px;
      height: 330px;
      @media only screen and (max-width: 768px) {
        width: 100%;
      }
    }
    &_name {
      color: #ffffff;
      font-weight: bold;
      font-size: 40px;
      margin-top: 29px;
    }
    &_position {
      color: #a5e8b5;
      font-weight: bold;
      font-size: 24px;
      line-height: normal;
      margin-top: 11px;
      &_small {
        font-size: 18px;
      }
    }
    &_bio {
      color: #ffffff;
      font-size: 16px;
      line-height: 26px;
      margin-top: 23px;
      @media only screen and (max-width: 768px) {
        font-size: 14px;
        line-height: normal;
      }
      &_margin {
        margin-top: 66px;
        @media only screen and (max-width: 768px) {
          margin-top: 23px;
        }
      }
      &_margin2 {
        margin-top: 45px;
        @media only screen and (max-width: 768px) {
          margin-top: 23px;
        }
      }
      &_margin3 {
        margin-top: 36px;
        @media only screen and (max-width: 768px) {
          margin-top: 23px;
        }
      }
    }
    &_link {
      display: flex;
      margin-top: 24px;
      .website {
        width: 16px;
        margin-right: 30px;
        &:hover {
          opacity: 0.8;
        }
      }
      .facebook {
        width: 8px;
        margin-right: 30px;
        &:hover {
          opacity: 0.8;
        }
      }
      .twitter {
        width: 19.7px;
        margin-right: 30px;
        &:hover {
          opacity: 0.8;
        }
      }
      .instagram {
        width: 16px;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .toshi_session {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1320px;
    padding: 0 60px;
    margin: 0 auto;
    column-gap: 60px;
    margin-bottom: 150px;
    @media only screen and (max-width: 768px) {
      align-items: unset;
      padding: 0 30px;
    }
    .text_container {
      max-width: 604px;
      @media only screen and (max-width: 768px) {
        max-width: unset;
        width: 100%;
      }
      .text_name {
        color: #ffffff;
        font-weight: bold;
        font-size: 40px;
        @media only screen and (max-width: 768px) {
          margin-top: 11px;
        }
      }

      .bio_read_more {
        input {
          opacity: 0;
          position: absolute;
          pointer-events: none;
          &:checked + .toshi_bio {
            -webkit-line-clamp: unset;
          }
          &:checked ~ label {
            display: none;
          }
        }

        .toshi_bio {
          display: -webkit-box;
          -webkit-line-clamp: 8;
          -webkit-box-orient: vertical;
          overflow: hidden;
          margin-bottom: 8px;
          @media only screen and (max-width: 768px) {
            -webkit-line-clamp: 10;
          }
          &:not(.truncated) ~ label {
            display: none;
          }
        }
        .read_more_text {
          font-size: 14px;
          font-weight: bold;
          color: #a5e8b5;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .moff_artdirector {
    // margin: 0 auto 150px auto;
    // width: 400px;
    display: flex;
    flex-direction: column-reverse;
    margin: 0 5vw 150px 5vw;
    align-items: center;

    &_profile {
      width: 400px;
      @media only screen and (max-width: 1024px) {
        width: unset;
      }
    }
    &_title {
      text-align: center;
      color: #a5e8b5;
      font-size: 32px;
      font-weight: bold;
      margin-bottom: 50px;
    }
    &_image {
      object-fit: cover;
      width: 400px;
      height: 330px;
      @media only screen and (max-width: 768px) {
        width: 100%;
      }
    }
    &_name {
      color: #ffffff;
      font-weight: bold;
      font-size: 40px;
      margin-top: 29px;
    }
    &_position {
      color: #a5e8b5;
      font-weight: bold;
      font-size: 24px;
      line-height: normal;
      margin-top: 11px;
    }
    &_bio {
      color: #ffffff;
      font-size: 16px;
      line-height: 26px;
      margin-top: 23px;
      &_margin {
        margin-top: 66px;
        @media only screen and (max-width: 768px) {
          margin-top: unset;
        }
      }
    }
    &_link {
      display: flex;
      margin-top: 24px;
      .website {
        width: 16px;
        margin-right: 30px;
        &:hover {
          opacity: 0.8;
        }
      }
      .facebook {
        width: 8px;
        margin-right: 30px;
        &:hover {
          opacity: 0.8;
        }
      }
      .twitter {
        width: 19.7px;
        &:hover {
          opacity: 0.8;
        }
      }
    }
    &_right {
      color: #ffffff;
      font-size: 16px;
      line-height: 26px;
      max-width: 600px;
      margin-bottom: 40px;
      @media only screen and (max-width: 768px) {
        font-size: 14px;
        line-height: normal;
      }
    }
  }
  .moff_apply {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #ffffff;
    font-family: "PT Sans Narrow";
    margin: 0 7vw 200px 7vw;
    &_zoom {
      width: 526px;
    }
    &_title {
      font-size: 50px;
      font-weight: bold;
      transform: translateY(-50%);
      @media only screen and (max-width: 768px) {
        font-size: 23px;
      }
    }
    &_subtitle {
      font-size: 18px;
      font-weight: bold;
      color: #a5e8b5;
      margin: 36px 0;
    }
    &_body {
      font-size: 18px;
      line-height: 26px;
      width: 526px;

      @media only screen and (max-width: 768px) {
        font-size: 14px;
        line-height: normal;
        width: 100%;
        margin-bottom: unset;
      }
    }
    .btn_green {
      // width: 526px !important;
      background-color: #a5e8b5 !important;
      .text {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: normal;
        color: #202641;
      }
    }
  }
  .moff_past {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "PT Sans Narrow";
    color: #ffffff;
    margin-top: 200px;
    padding-bottom: 100px;
    &_title {
      color: #a5e8b5;
      font-size: 32px;
      font-weight: bold;
      padding-bottom: 50px;
    }
    &_image {
      width: 804px;
    }
    &_logo {
      transform: translateY(-26%);
      width: 415px;
      @media only screen and (max-width: 768px) {
        padding: 0 5vw;
        width: unset;
      }
    }
    &_text {
      font-size: 18px;
      line-height: 26px;
      text-align: center;
      width: 558px;
      @media only screen and (max-width: 768px) {
        font-size: 14px;
        line-height: normal;
        text-align: left;
        margin: 0 7vw 40px 7vw;
        width: unset;
      }
    }
    .btn_green {
      width: 418px !important;
      background: #a5e8b5 !important;
      margin-top: 70px;
      @media only screen and (max-width: 768px) {
        margin-top: 30px;
        width: 80% !important;
      }
      .text {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: normal;
        color: #202641;
      }
    }
  }

  .moff_2022 {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "PT Sans Narrow";
    margin: 100px 5vw;
    &_logo {
      width: 804px;
    }
    &_date {
      width: 200px;
      transform: translateY(-46%);
    }
    &_body {
      position: relative;
      top: -60px;
      text-align: center;
      line-height: normal;
      font-size: 16px;
      color: #ffffff;
    }
    &_detail {
      // top: -60px;
      font-size: 24px;
      color: #7e85a5;
    }
  }

  .moff_caution {
    text-align: center;
    font-family: "PT Sans Narrow";
    margin: 100px 5vw 0 5vw;
    line-height: normal;
    &_title {
      font-size: 32px;
      font-weight: bold;
      color: #a5e8b5;
      margin-bottom: 50px;
    }
    &_body {
      color: #ffffff;
      font-size: 16px;
      @media only screen and (max-width: 768px) {
        font-size: 14px;
        text-align: left;
      }
    }
    &_link {
      color: #ffffff;
    }
  }
  .pc_bg {
    @media only screen and (min-width: 769px) {
      background: url(../images/pc/moff/moff_2022_bg.svg);
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
  .mobile_bg {
    @media only screen and (max-width: 768px) {
      background: url(../images/pc/moff/ses_leaf.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: 50% 3%;
    }
    @media only screen and (max-width: 425px) {
      background-size: 600px;
    }
  }
  .comingsoon {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 100px;

    &_image {
      padding-top: 11vw;
      width: 352px;
      @media only screen and (max-width: 768px) {
        padding-top: 17.33vw;
        width: 162px;
      }
    }
    .comingsoon_date_container {
      position: relative;
      display: flex;
      flex-direction: column;
      color: #202641;
      font-family: "futura-pt-bold";
      margin: 80px 0 60px;
      .comingsoon {
        &_month {
          font-size: 40px;
          font-weight: bold;
          padding-left: 5%;
          @media screen and (max-width: 768px) {
            font-size: 27px;
          }
        }
        &_date {
          font-size: 170px;
          font-weight: bold;
          letter-spacing: -17px;
          @media screen and (max-width: 768px) {
            letter-spacing: -7px;
            font-size: 117px;
          }
        }
        &_superscript {
          position: absolute;
          top: 28%;
          left: 83%;
          font-size: 40px;
          font-weight: bold;
          padding: 3px;
          @media screen and (max-width: 768px) {
            font-size: 27px;
          }
        }
        &_day {
          font-family: "futura-pt";
          font-weight: 500;
          position: absolute;
          left: 84%;
          bottom: 10%;
          font-size: 24px;
          @media screen and (max-width: 768px) {
            font-size: 16px;
          }
        }
      }
    }
    &_text {
      text-align: center;
      color: #202641;
      line-height: normal;
      font-weight: bold;
      margin-top: 80px;
      @media only screen and (max-width: 768px) {
        padding: 0 9.6vw;
        text-align: left;
      }
    }
    &_title {
      font-size: 24px;
      padding-bottom: 40px;

      @media only screen and (max-width: 768px) {
        text-align: center;
        font-size: 18px;
        padding-bottom: 30px;
      }
    }
    &_body {
      font-size: 16px;
      padding-bottom: 60px;
      @media only screen and (max-width: 768px) {
        font-size: 14px;
      }
    }
    &_schedule {
      display: grid;
      grid-template-columns: 75px 581px;
      column-gap: 45px;
      row-gap: 30px;
      text-align: left;
      justify-content: center;
      @media screen and (max-width: 1024px) {
        grid-template-columns: 75px 1fr;
        margin: 0 5vw;
      }
      @media screen and (max-width: 768px) {
        grid-template-columns: unset;
        display: flex;
        flex-direction: column;
        column-gap: unset;
        row-gap: unset;
        margin: unset;
      }
    }
    &_label {
      font-size: 24px;
      color: #9d9faa;

      @media screen and (max-width: 768px) {
        font-size: 14px;
        padding-bottom: 9px;
      }
    }
    &_content {
      font-size: 18px;
      &_notice {
        font-size: 14px;
        font-weight: normal;
        @media screen and (max-width: 768px) {
          font-size: 12px;
        }
      }
      &_notice_padding {
        padding-top: 30px;
        @media screen and (max-width: 768px) {
          padding-bottom: unset;
        }
      }
      @media screen and (max-width: 768px) {
        font-size: 14px;
        padding-bottom: 26px;
      }
    }
  }

  .faq {
    position: relative;
    height: 100%;
    width: 100%;
    background: #202641;
    padding: 100px 100px 200px;
    @media screen and (max-width: 768px) {
      padding: 100px 4vw 200px;
    }
    &_text {
      position: relative;
      font-size: 32px;
      font-weight: bold;
      color: #a5e8b5;
      line-height: 42px;
      margin-bottom: 50px;
      @media screen and (max-width: 768px) {
        font-size: 24px;
      }
      &::before {
        position: absolute;
        content: "";
        bottom: -10px;
        left: 0;
        width: 230px;
        height: 1px;
        background: #a5e8b5;
      }
    }
    .accordion_container {
      margin: 0 auto;
      max-width: 1000px;
    }
    .accordion {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 30px 18px;
      width: 100%;
      text-align: left;
      transition: 0.4s;
      @media screen and (max-width: 768px) {
        padding: 30px 0;
      }
      .label {
        font-size: 32px;
        font-weight: bold;
        color: #a5e8b5;
        @media screen and (max-width: 768px) {
          font-size: 24px;
        }
      }
      .question {
        font-size: 24px;
        margin-left: 20px;
        font-weight: bold;
        color: #ffffff;
        @media screen and (max-width: 768px) {
          margin-left: 2vw;
          font-size: 14px;
        }
      }
      .sign {
        font-size: 60px;
        color: #a5e8b5;
        margin-left: auto;
        @media screen and (max-width: 768px) {
          font-size: 32px;
        }
      }
    }

    .active,
    .accordion:hover {
      // background-color: #ccc;
    }

    .panel {
      color: #ffffff;
      padding: 0 18px;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.2s ease-out;
      border-bottom: 1px solid #707070;
      @media screen and (max-width: 768px) {
        padding: unset;
      }
      .answer {
        font-size: 18px;
        line-height: 27px;
        padding-bottom: 80px;
        padding-left: 58px;
        width: 89%;
        @media screen and (max-width: 768px) {
          font-size: 14px;
          padding-left: unset;
          width: 100%;
        }
        .text {
          color: #ffffff;
        }
      }
    }
  }

  .partner {
    margin: 40px 0 200px;
    .title {
      font-size: 32px;
      font-weight: bold;
      color: #202641;
      text-align: center;
      padding-bottom: 80px;
    }
    .logos {
      display: grid;
      grid-template-columns: repeat(3, 350px);
      width: 1050px;
      row-gap: 100px;
      margin: 0 auto;
      @media screen and (max-width: 1024px) {
        grid-template-columns: repeat(2, 350px);
        width: 700px;
      }
      @media screen and (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
        width: unset;
        column-gap: 50px;
        padding: 0 5vw;
      }
      .logo-container {
        justify-self: center;
        align-self: center;
      }
      .logo {
        height: 60px;
        width: auto;
        justify-self: center;
        align-self: center;
        object-fit: contain;
      }
      .logo_sasasima {
        height: 100px;
      }
      .logo_hatsuna {
        width: 130px;
      }
      .logo_okts {
        height: 80px;
        width: auto;
        object-fit: contain;
      }
      .logo_osv {
        height: 80px;
        width: auto;
        object-fit: contain;
      }
    }
  }

  @media screen and (min-width: 769px) {
    .u-sp-only-moff {
      display: none !important;
    }
  }

  @media screen and (max-width: 768px) {
    .u-pc-only-moff {
      display: none !important;
    }
  }
  .pc_only_br {
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
  @include desktop {
    .apply_button_container {
      position: sticky;
      top: calc(100vh - 146px);
      margin-left: calc(100vw - 195px);
      margin-bottom: 20px;
      z-index: 9;
    }
    .apply_text {
      color: white;
      font-family: "PT Sans Narrow";
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      margin-top: 10px;
      margin-left: 20px;
    }
    .apply_button {
      width: 160px;
      height: 80px;
      border-radius: 35px;
      background: #a5e8b5;
      box-shadow: 0px 2px 7px rgb(0, 0, 0);
      transition: 0.3s ease-out;
      z-index: 1;
      &:hover {
        transform: scale(1.1);
        transition: 0.3s ease-out;
      }
      .text {
        font-family: "PT Sans Narrow";
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0;
        color: #202641;
      }
    }
  }
  .button {
    width: 100%;
  }
}
