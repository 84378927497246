@include desktop {
  .blockchain-header {
    width: 100%;
    height: 1000px;
    position: relative;

    &_page-title {
      font-size: 14px;
      position: absolute;
      top: 225px;
      left: calc(50% - 550px);
      width: calc(51% - (50% - 550px));
      height: 20px;
      text-align: left;
      letter-spacing: 0px;
      color: #2276fc;

      &::after {
        content: "";
        height: 1px;
        background-color: #e1e1e1;
        position: absolute;
        top: 6px;
        left: 170px;
        width: calc(100% - 170px);
      }
    }

    &_title {
      font-size: 40px;
      font-weight: bold;
      position: absolute;
      top: 281px;
      left: calc(50% - 550px);
      width: 848px;
      height: 118px;
      z-index: 1;
    }

    &_hr1 {
      background: transparent;
      width: 51%;
      height: 400px;
      border-bottom: 0.5px solid #e1e1e1;
      position: absolute;
    }

    &_vr1 {
      background: transparent;
      width: 49%;
      height: 1061px;
      // height: 1010px;
      border-left: 0.5px solid #e1e1e1;
      border-bottom: 0.5px solid #e1e1e1;
      position: absolute;
      right: 0;
    }

    &_bgGray {
      background: #f8f8f8;
      width: 51%;
      height: 521px;
      position: absolute;
      top: 540px;
    }

    &_details {
      display: flex;
      position: relative;
      top: 520px;

      &__left {
        position: absolute;
        left: calc(50% - 555px);

        &--title1 {
          position: absolute;
          top: -76px;
          width: 574px;
          font-size: 15px;
          font-weight: 500;
          line-height: 1.5;
          letter-spacing: 0.03em;
        }

        &--p {
          width: 554px;
          padding-top: 55px;
          font-size: 13px;
          line-height: 1.5rem;
          letter-spacing: 0.03em;
        }
      }

      &__right {
        position: absolute;
        right: calc(50% - 500px);
        top: -289px;

        &--title2 {
          font-size: 30px;
          font-weight: bold;
        }

        &--img {
          width: 400px;
          height: auto;
          margin-top: 45px;
        }

        &--detail {
          font-size: 12px;
          margin-top: 45px;
          display: flex;
          line-height: 2;

          .blockchain_right__detail--left {
          }

          .blockchain_right__detail--right {
            margin-left: 80px;
          }
        }
      }
    }
  }

  .blockchain-border__body {
    background: transparent;
    border-left: 0.5px solid #e1e1e1;
    border-bottom: 0.5px solid #e1e1e1;
    width: calc(100% - 1225px);
    height: 2691px;
    position: absolute;
    right: 0;
    top: 1059px;
  }

  .blockchain-bgGray2 {
    width: 1226px;
    height: 760px;
    background: #f8f8f8;
    position: absolute;
    left: 0;
    top: 2991px;
    z-index: -1;
  }

  .blockchain_bottom__spacer {
    margin-bottom: 100px;
  }

  .blockchain-section {
    padding-top: 60px;

    &-body {
      display: block;
      position: relative;
      width: 100%;
      padding-top: 25px;
      padding-left: 8.57633%;
      padding-right: 10.74443%;
    }

    &-title {
      font-size: 34px;
      font-weight: bold;
      font-family: NotoSansCJKjp, sans-serif;
      letter-spacing: inherit;
      -webkit-font-feature-settings: "palt" 1;
      font-feature-settings: "palt" 1;
      letter-spacing: 0.05em;
    }

    &-detail {
      margin-top: 60px;

      &__text {
        font-size: 14px;
        line-height: 2.1538;
        letter-spacing: 0;
      }
      &__text-link {
        text-decoration-line: underline !important;
      }
      &__img {
        margin-top: 60px;
        padding: 0 10%;
        text-align: center;
        max-width: 1048px;
        width: 100%;
        height: 100%;
        filter: brightness(100%);
        transform: scale(1, 1);
        transition: 1.6s transform, filter 3s, 1.6s -webkit-transform,
          -webkit-filter 3s;
        backface-visibility: hidden;
        overflow: hidden;
        will-change: filter, transform;
      }
    }
  }

  .blockchain-service {
    margin-top: 100px;

    &-body {
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      margin-top: 40px;

      &__img {
        width: 480px;
        height: auto;
        object-fit: cover;
      }

      &__description {
        width: 100%;
        padding-left: 40px;

        &-title {
          text-align: left;
          font-size: 24px;
          font-weight: bold;
          letter-spacing: 0px;
          margin-bottom: 20px;
        }
        &-text {
          text-align: left;
          font-size: 16px;
          letter-spacing: 0px;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .blockchain-header {
    position: relative;
    height: 1440px;

    &_page-title {
      font-size: 10px;
      position: absolute;
      top: 120px;
      left: 5%;
      width: 90%;
    }

    &_title {
      font-size: 25px;
      font-weight: bold;
      position: absolute;
      top: 157px;
      left: 7%;
      text-align: center;
      width: 90%;
      height: auto;
      line-height: 28px;
    }

    &_hr1 {
      width: 95%;
      height: 1344px;
      background-color: #fff;
      position: absolute;
      top: 270px;
      right: 0;
      z-index: 0;
    }

    &_bgGray {
      width: 100%;
      height: 10%;
      background-color: #f8f8f8;
      position: absolute;
      top: 1730px;
      z-index: -1;
    }

    &_details {
      position: absolute;
      top: 300px;
      left: 10%;
      width: 80%;
      height: auto;

      &__left {
        &--title1 {
          font-size: 17px;
          font-weight: bold;
          line-height: 1.2;
        }

        &--p {
          font-size: 12px;
          line-height: 2;
          letter-spacing: 0.03em;
          margin-top: 4em;
          padding-left: 2%;
        }
      }

      &__right {
        margin-top: 4em;

        &--title2 {
          font-size: 17px;
          font-weight: bold;
          line-height: 1.2;
        }

        &--img {
          width: 100%;
          height: auto;
          margin-top: 4em;
        }

        &--detail {
          font-size: 9px;
          line-height: 2;
          letter-spacing: 0.03em;
          margin-top: 4em;
          display: flex;

          .blockchain_right__detail--left {
            width: auto;
          }

          .blockchain_right__detail--right {
            margin-left: 2em;
          }
        }
      }
    }
  }

  .blockchain-bgGray2 {
    width: 1226px;
    height: 1268px;
    background: #f8f8f8;
    position: absolute;
    left: 0;
    top: 4142px;
    z-index: -1;
  }
  .blockchain_bottom__spacer {
    margin-bottom: 100px;
  }

  .blockchain-section {
    margin-top: 3.5em;

    .p-inner--m {
      padding: 0;
    }

    &-body {
    }

    &-title {
      font-size: 22px;
      font-weight: bold;
      padding: 294px 5%;
      line-height: 28px;
    }

    &-detail {
      margin-top: -228px;

      &__text {
        font-size: 12px;
        line-height: 2;
        letter-spacing: 0.03em;
        padding: 0 10%;
      }
      &__img {
        margin-top: 60px;
        text-align: center;
        max-width: 1048px;
        width: 100%;
        height: 100%;
        backface-visibility: hidden;
        overflow: hidden;
        will-change: filter, transform;
      }
    }
  }

  .blockchain-service {
    margin-top: 7em;

    .p-inner {
      padding: 0 10%;
    }

    &-body {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      margin-top: 3em;

      &__img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }

      &__description {
        width: 100%;
        padding: 8%;

        &-title {
          text-align: left;
          font-size: 24px;
          font-weight: bold;
          letter-spacing: 0px;
          margin-bottom: 20px;
        }
        &-text {
          text-align: left;
          font-size: 12px;
          line-height: 2;
          letter-spacing: 0.03em;
        }
      }
    }
  }
}
