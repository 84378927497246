@include desktop {
  /* Global engineering page */
  #page_title_sises {
    position: absolute;
    top: -687px;
    left: calc(50% - 505px);
    font-size: 70px;
    color: white;
    z-index: 3;
    font-weight: bold;
    width: 480px;

    &::after {
      content: "WHAT WE DO";
      width: 56px;
      height: 0px;
      background-color: #fff;
      position: absolute;
      top: -35px;
      font-size: 9px;
      font-weight: 100;
    }
  }

  .sises_header_description {
    position: absolute;
    left: calc(50% - 578px);
    top: -587px;
    width: 615px;
    padding: 82px 84px 50px 83px;
    background: #1167f2;
    z-index: 2;
    color: #fff;
    box-shadow: -2px 10px 4px 0px rgba(0, 0, 0, 0.1);
  }

  .ge_right__title {
    position: absolute;
    top: -230px;
    left: 770px;
    width: 550px;

    &--vline {
      width: 1px;
      height: 260px;
      background: #242424;
      display: block;
      position: absolute;
      top: -460px;
      right: 464px;
      z-index: 1;
    }
  }
  .ge_right_box {
    position: absolute;
    top: -230px;
    left: 600px;
    width: 740px;
    height: 303px;
    background: #fff;
    .title {
      font-size: 30px;
    }
    .body {
      font-size: 15px;
    }
  }

  .sises_text {
    font-size: 15px;
    line-height: 1.6;
    letter-spacing: 0em;
    max-width: 457px;
    max-height: 176px;
    font-family: "Noto Sans JP", sans-serif;
  }

  .sises_header {
    position: relative;
    display: block;
    background: url(../images/pc/sises/ge_sideline.png) top right no-repeat;
    background-size: 50% auto;
    z-index: 1;

    .ge_border__wwd {
      width: calc(50% - 504px);
      height: 1px;
      background-color: #fff;
      position: absolute;
      top: 172px;
      left: 0;
    }
  }

  .sises_header__background {
    // url(../images/pc/sises/ge_line.png);
    background: url(../images/pc/sises/ge_main.jpg) no-repeat;
    background-size: cover;
    background-blend-mode: hard-light;
    height: 900px;
    width: 51.6%;

    // &::before {
    //     background: url(../images/pc/sises/ge_sideline.png);
    //     background-size: cover;
    //     top: 0;
    //     left: 94%;
    //     width: 100%;
    //     height: 100%;
    //     content: '';
    //     position: absolute;
    // }
  }
  .sises_header__background_en {
    // url(../images/pc/sises/ge_line.png);
    background: url(../images/pc/sises/ge_main.jpg) no-repeat;
    background-size: cover;
    background-blend-mode: hard-light;
    height: 900px;
    width: 51.6%;
  }

  .sises_line_background {
    position: relative;
    background: linear-gradient(#3bb8ff, #0b4cb5);
  }

  .sises_circle_img {
    position: relative;
  }

  .sises_circles {
    position: absolute;
    top: -358px;
    left: 60px;
  }

  .sises-carousel__item {
    // &::before {
    //     content: "";
    //     position: absolute;
    //     top: -1240px;
    //     left: 21%;
    //     display: block;
    //     width: 1px;
    //     height: 260px;
    //     background-color: #242424;
    //     z-index: 1;
    // }

    .c_title {
      position: absolute;
      top: 0px;
      font-size: 30px;
      margin-left: 115px;
      margin-right: auto;
      z-index: 1;
      font-weight: bold;
      white-space: nowrap;
      @media screen and (max-width: 1439px) {
        margin-left: 45px;
      }
    }
    .c_title_en {
      position: absolute;
      top: -966px;
      font-size: 30px;
      margin-left: 70px;
      margin-right: auto;
      z-index: 1;
      font-weight: bold;
    }
  }

  .sises_carousel__nav {
    position: absolute;
    top: -600px;
    width: 650px;
    /* display: flex; */
    /* flex-direction: row; */
    /* justify-content: space-between; */
    /* flex-wrap: wrap; */
  }

  // .sises_carousel__nav {
  //     position: absolute;
  //     top: -600px;
  //     left: -25px;
  //     width: 6000px;
  //     text-align: center;
  // }

  .sises_carousel__info {
    left: 0;
    right: 0;
    position: absolute;
    background: #fff;
  }
  .sises_carousel__info_en {
    left: -15%;
    top: -990px;
    position: absolute;
    background: #fff;
  }
  // .sises-carousel__item--container {
  //     height: 548px;
  //     width: 698px;
  //     padding: 88px 117px 50px 123px;
  // }

  .sises-carousel__item--description {
    font-size: 15px;
    line-height: 1.5;
    letter-spacing: 0.03em;
    max-width: 458px;
  }

  .sises_circle_img {
    position: relative;

    .sises_circles {
      position: absolute;
      top: -358px;
      left: -155px;
    }
  }

  .ge-main {
    background: url(../images/pc/sises/ge-background.png);
    background-position: top;
    background-repeat: no-repeat;
    .sises-case {
      position: relative;
      top: -190px;
    }
    .sises-case__bgGray {
      background: #fcfcfc;
      width: 100%;
      height: 869px;
      position: relative;
      top: 200px;
      border-top: 0.5px solid #e1e1e1;
      border-bottom: 0.5px solid #e1e1e1;

      &::after {
        content: "導入事例";
        font-size: 9px;
        position: absolute;
        top: 5px;
        left: calc(50% - 34.7%);
        color: #e1e1e1;
      }

      .sises-case__bgWhite {
        background: #fff;
        width: calc(100% - 15%);
        height: 926px;
        position: absolute;
        top: 65px;
        right: 0;
      }

      .sises-case_bgGray__left {
        background: #fcfcfc;
        width: calc(100% - 85%);
        height: 869px;
        position: absolute;
        top: 0;
        left: 0;
        border-right: 0.5px solid #e1e1e1;
        border-bottom: 0.5px solid #e1e1e1;
      }

      .sises-case_bgWhite__bottom {
        background: transparent;
        width: calc(100% - 15%);
        height: 1px;
        position: absolute;
        top: 868px;
        right: 0;
        border-top: 0.5px solid #e1e1e1;
      }

      .sises-case__title {
        font-size: 60px;
        font-weight: bold;
        position: absolute;
        top: 40px;
        left: 150px;
      }

      .sises-case__content {
        position: relative;

        &__left {
          position: absolute;
          top: 178px;
          left: 145px;
          font-size: 12px;
          line-height: 20px;
          display: grid;

          &-title {
            font-weight: bold;
            margin-left: 20px;

            &::before {
              content: "";
              width: 5px;
              height: 5px;
              border-radius: 50px;
              background-color: $red;
              position: absolute;
              top: 18px;
              left: 3px;
            }
          }
        }

        &__middle {
          position: absolute;
          top: 220px;
          left: 345px;
          font-size: 15px;
          line-height: 50px;
          font-weight: bold;
        }

        &__right {
          position: absolute;
          top: 220px;
          left: 580px;
          font-size: 15px;

          &-lg {
            line-height: 50px;
          }

          &-sm {
            padding-top: 11px;
          }
        }

        &__details {
          position: absolute;
          top: 550px;
          left: 345px;
          font-size: 15px;
          line-height: 50px;

          &-title {
            font-weight: bold;
          }

          &-p {
            font-size: 15px;
            line-height: 1.6;
            letter-spacing: 0.03em;
            max-width: 768px;
            font-family: "Noto Sans JP", sans-serif;
          }
        }
      }
    }

    .sises-bk_section {
      position: relative;
      left: calc(50% - -100px);
      height: 500px;

      &__box {
        width: 352px;
        height: 400px;
        background: #f8f8f8;

        &--next {
          font-size: 9px;
          padding: 54px 48px 15px 59px;
        }

        &--title {
          font-size: 40px;
          font-weight: bold;
          padding-right: 97px;
          padding-bottom: 24px;
          padding-left: 58px;
        }

        &--content {
          font-size: 14px;
          line-height: 1.5;
          padding-right: 59px;
          padding-bottom: 60px;
          padding-left: 59px;
        }
      }

      &__btn {
        position: absolute;
        top: 364px;
        left: 153px;
      }

      .btn-open {
        display: inline-block;
        width: 199px;
        height: 67px;
        text-align: center;
        background-color: #f21515;
        font-size: 20px;
        line-height: 66px;
        color: #fff;
        // text-decoration: none;
        // font-weight: bold;
        // border: 2px solid #9ec34b;
        // position: relative;
        // overflow: hidden;
        // z-index: 1;
      }

      // .btn-open:after {
      //     width: 100%;
      //     height: 0;
      //     content: "";
      //     position: absolute;
      //     top: 50%;
      //     left: 50%;
      //     background: #FFF;
      //     opacity: 0;
      //     transform: translateX(-50%) translateY(-50%) rotate(45deg);
      //     transition: .2s;
      //     z-index: -1;
      // }

      // .btn-open:hover {
      //     color: #9ec34b;
      // }
    }
  }

  .ge-headline {
    font-size: 60px;
    letter-spacing: 0.01em;
    font-weight: bold;
  }

  .ge-case__content1 {
    visibility: hidden;
    opacity: 0;
    transition: 0.8s all ease-in-out;
  }

  .kanataactive {
    visibility: visible !important;
    z-index: 1;
    transform: translateY(0);
    opacity: 1;
    transition-delay: 0.4s;
  }

  .sises-case__content__left-li {
    font-size: 12px;
    top: 0%;
    left: 0;
    transition: 0.3s ease-in;
    text-align: left;
    align-items: center;
  }

  .ge_content__selected {
    transform: translateX(10px);
    position: relative;
    font-weight: bold;
  }

  .sises-case__content__left-li--line {
    display: inline-block;
    visibility: hidden;
    position: relative;
    top: -10px;
    left: 0px;
    width: 100%;
    height: 1px;
    background-color: red;
    transform: scaleX(0);
    opacity: 0;
    transition: 0.3s all ease-in-out;
    transform-origin: right;
  }

  .active_ge__line {
    visibility: visible;
    transform: scale(1);
    opacity: 1;
  }

  .carousel-item__description--ge {
    margin-top: -110px;
  }
}

.top {
  display: grid;
  position: relative;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(2, 1fr);

  &_background {
    background: url("../images/pc/top/background.jpg");
    background-repeat: no-repeat;
    background-position: center top;
    overflow: hidden;
    position: absolute;
    margin-top: -35px;
    height: 100%;
    width: calc(100% + 200px);
    z-index: -1;
  }
  @media screen and (max-width: 768px) {
    grid-template-rows: repeat(1, 1fr);
    grid-template-columns: repeat(1, 1fr);
  }
  @include desktop {
    min-width: 1223px;
  }
  .sises_quote {
    position: relative;
    z-index: 1;
    &_author {
      position: relative;
      left: 45%;
    }

    &_image {
      width: 93.12%;
      height: 100%;
      object-fit: cover;
      @media screen and (max-width: 1024px) {
        width: initial;
        object-fit: cover;
      }
      @media screen and (max-width: 768px) {
        width: initial;
        height: 100vh;
        object-fit: cover;
      }
    }
    &_container {
      position: absolute;
      top: 40%;
      right: 10px;
      padding: 60px 5.69% 40px 5.69%;
      color: #fff;
      background: #d12626;

      @media screen and (max-width: 1024px) {
        right: 0;
      }
    }
    &_title {
      font-size: 120px;
      font-weight: bold;
      position: absolute;
      top: -25%;
      left: -37%;
      @media screen and (max-width: 1024px) {
        font-size: 50px;
        top: -16%;
        left: -20%;
      }
    }
    &_body {
      line-height: 26px;
      font-size: 15px;
      @media screen and (max-width: 1024px) {
        font-size: 11px;
      }
    }
  }
  .sises_notIT {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    margin-top: 10%;
    @media screen and (max-width: 1024px) {
      margin-top: 30%;
    }
    @media screen and (max-width: 1024px) {
      margin-top: 15%;
    }

    &_vertical {
      background: #000;
      height: 290px;
      width: 1px;
    }
    &_box {
      display: flex;
      flex-direction: column;
      align-items: center;
      line-height: 26px;
      background: #fff;
      // width: 57%;
      margin-top: 60px;
      padding: 25px;
      @media screen and (max-width: 1024px) {
        width: 100%;
      }
    }
    &_title {
      font-size: 26px;
      font-weight: bold;
      text-align: center;
      line-height: 32px;
      @media screen and (max-width: 1024px) {
        font-size: 15px;
      }
    }
    &_body {
      margin-top: 25px;
      font-size: 15px;
      @media screen and (max-width: 1024px) {
        font-size: 13px;
      }
    }
  }
  .sises_ictdesign {
    margin-top: 10.4vw;
    margin-left: 12%;
    background: #fff;
    z-index: 1;
    padding: 0 5.2vw;
    @media screen and (max-width: 1024px) {
      margin-top: 5.4vw;
      margin-left: 0;
    }
    &_title {
      font-size: 50px;
      font-weight: bold;
      text-align: center;
      padding: 27px;

      @media screen and (max-width: 1024px) {
        font-size: 40px;
      }
    }
    &_body {
      line-height: 26px;
      font-size: 15px;
      @media screen and (max-width: 1024px) {
        font-size: 13px;
      }
    }
  }
  .sises_threepoint {
    margin-top: 10vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 26px;
    z-index: 1;
    @media screen and (max-width: 768px) {
      grid-row: 3;
    }
    &_title {
      font-size: 40px;
      font-weight: bold;
      padding-bottom: 50px;
      @media screen and (max-width: 1024px) {
        font-size: 20px;
      }
    }
    &_image {
      width: 45%;
      @media screen and (max-width: 1024px) {
        margin-left: 7%;
        width: 60%;
      }
    }
    &_text {
      font-size: 18px;
      text-align: center;
      margin-top: 50px;
      @media screen and (max-width: 1024px) {
        margin-top: 30px;
        font-size: 13px;
      }
    }
  }
}

.ict-solution-container {
  display: flex;
  margin-top: 5.2vw;
  @media screen and (max-width: 1024px) {
    display: initial;
  }
  .left-container {
    display: flex;
    flex-direction: column;
    width: 50%;
    @media screen and (max-width: 1024px) {
      margin-top: 100px;
      width: initial;
    }
    .image-container {
      margin-left: 7.44vw;
      max-width: 731px;
      max-height: 349px;
      @media screen and (max-width: 1024px) {
        margin-left: initial;
        max-width: initial;
        max-height: initial;
      }
      .image {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }
    .button {
      display: flex;
      flex-direction: column;
      margin-left: 50%;
      color: #ffffff;
      width: fit-content;
      // height: 300px;
      .box {
        margin-bottom: 24px;
        width: 250px;
        height: 60px;
        background: #191919;
      }
    }
  }
  .carousel-container {
    position: relative;
    margin-top: 7%;
    .carousel-item-sises {
      padding: 0 50px 0 30px;

      &-title {
        text-align: center;
        font-size: 40px;
      }
      &-body {
        top: 70px;
      }
      &-text {
        line-height: normal;
        font-size: 16px;
      }
    }
  }
}
.portfolio-container {
  @include desktop {
    margin-top: 100px;
  }

  .mandala-video{
    margin: 16px 0;
    width: 100%;
    height: auto;
    @include desktop{
      width: 90%;
    }
    &__title {
      font-size: 18px;
      margin-top: 40px;
      font-weight: bold;
    }
  }

  .more-videos{
    margin: 40px auto 0;
  }
  // .portfolio_background {
  //   background: url("../images/pc/top/background.jpg");
  //   background-repeat: no-repeat;
  //   background-position: center top;
  //   overflow: hidden;
  //   position: absolute;
  //   margin-top: -35px;
  //   height: 200%;
  //   width: calc(100% + 200px);
  //   z-index: -1;
  // }

  .portfolio-inner {
    min-width: 1350px;
    position: relative;
    display: grid;
    grid-template-areas:
      "title image"
      "body image"
      "box image";
    @media screen and (max-width: 768px) {
      grid-template-areas:
        "title image"
        "body body"
        "box box";
      grid-template-columns: 35% 1fr;
      min-width: initial;
    }

    .portfolio-title-container {
      margin-top: 77px;
      margin-left: 32%;
      grid-area: title;
      @media screen and (max-width: 768px) {
        margin-left: 18%;
      }
    }
    .portfolio {
      &-title {
        font-size: 95px;
        font-weight: bold;
        @media screen and (max-width: 768px) {
          font-size: 30px;
        }
      }
      &-subtitle1 {
        white-space: nowrap;
        font-size: 56px;
        font-weight: bold;
        margin-bottom: 24px;
        @media screen and (max-width: 768px) {
          margin-top: 10px;
          font-size: 24px;
        }
      }
      &-subtitle2 {
        white-space: nowrap;
        font-size: 27px;
        @media screen and (max-width: 768px) {
          font-size: 14px;
        }
      }
      &-body {
        grid-area: body;
        font-size: 16px;
        line-height: 26px;
        margin: 78px 0 65px 32%;
        width: 100%;
        max-width: 590px;
        @media screen and (max-width: 768px) {
          margin: auto;
          font-size: 15px;
          width: initial;
          padding: 20px;
          margin-bottom: 20px;
        }
      }
      &-redbox {
        grid-area: box;
        position: absolute;
        bottom: 0;
        left: 18vw;
        transform: translateY(50%);
        height: 205px;
        // color: #fff;
        // background: #d12626;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 1;
        @media screen and (max-width: 1024px) {
          height: 180px;
          left: 50%;
        }
        @media screen and (max-width: 768px) {
          position: relative;
          height: 60px;
          width: 195px;
          left: initial;
          justify-self: center;
          align-items: flex-start;
        }
        &-text1 {
          font-size: 40px;
          font-weight: bold;
          position: relative;
          left: -7.9%;
          color: black;
          bottom: -20px;
          @media screen and (max-width: 1024px) {
            font-size: 80px;
          }
          @media screen and (max-width: 768px) {
            font-size: 32px;
            left: -3px;
          }
        }
        &-text2 {
          font-size: 18px;
          align-self: flex-end;
          margin-right: 20px;
          @media screen and (max-width: 768px) {
            font-size: 11px;
            margin-top: 5px;
            margin-right: 15px;
          }
        }
      }
      &-image {
        grid-area: image;
        width: 800px;
        @media screen and (max-width: 768px) {
          width: unset;
        }
      }
      &-line {
        width: 98px;
        color: black;
        size: 3px;
        background-color: black;
        transform: translate(-83px, 33px);
      }
      &-UI {
        grid-area: box;
        position: absolute;
        left: 18vw;
        bottom: -199px;

        &-texta {
          font-size: 18px;
          font-weight: bold;
          position: relative;
        }
        &-textb {
          font-size: 18px;
          position: relative;
          font-weight: bold;
          bottom: -33px;
        }
        &-textc {
          position: relative;
          bottom: -50px;
          line-height: 22px;
        }
      }
      &-line1 {
        width: 98px;
        color: black;
        size: 3px;
        background-color: black;
        transform: translate(-102px, 12px);
      }
    }
    &-background {
      position: absolute;
      width: 100%;
      height: 100%;
      background: #303030;
      opacity: 0.07;
    }
  }
  .essen-container {
    max-width: 950px;
  }

  .portfolio {
    grid-area: box;
    position: relative;
    &-redbox {
      position: relative;
      &-text1 {
        position: relative;
        font-weight: bold;
        font-size: 40px;
        margin-top: -32px;
        padding-bottom: 17px;
        // line-height: 2em;
        margin-bottom: 12px;
        &::after {
          position: absolute;
          content: "";
          bottom: 0;
          left: 0;
          background: #242424;
          height: 8px;
          width: 100px;
        }
      }
      &-text2 {
        // margin-top: 16px;
        margin-bottom: 61px;
      }
    }
    // &-line {
    //   width: 138px;
    //   background-color: black;
    //   display: inline-block;
    //   height: 8px;
    // }
    &-UI {
      grid-area: box;
      position: relative;
      left: 18vw;
      margin-right: 30%;
      margin-top: -32px;
      &-texta {
        position: relative;
        font-size: 25px;
        // height: 38px;
        margin-bottom: 12px;
        padding-bottom: 13px;
        font-weight: bold;
        &::after {
          position: absolute;
          content: "";
          bottom: 0;
          left: 0;
          background: #242424;
          height: 4px;
          width: 80px;
        }
      }
      &-textb {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 7px;
      }
      &-textc {
        line-height: 26px;
        margin-bottom: 53px;
      }
      &-image {
        position: relative;
        width: 100%;
        &::before {
          content: "";
          background: url(../images/pc/sises/esse.svg);
          background-size: contain;
          background-repeat: no-repeat;
          position: absolute;
          width: 100%;
          height: 100%;
          max-width: 353px;
          max-height: 434px;
          left: 135px;
          bottom: -63%;
          z-index: -1;
        }
      }
      &-img {
        margin-left: 135px;
        transform: scale(1);
      }
      .paper-container {
        display: grid;
        left: 18vw;
        grid-template-columns: repeat(3, 1fr);
        transform: scale(0.9);
        column-gap: 43px;
        margin-top: 65px;
        margin-bottom: 192px;
        .broadcast-container {
          height: min-content;
          overflow: hidden;
          .broadcast {
            transition: 0.3s ease-in-out;
            &:hover {
              transform: scale(1.1);
              transition: 0.3s ease-out;
            }
          }
        }
        .csr-container {
          // margin-top: 85px;
          overflow: hidden;
          .csr {
            transition: 0.3s ease-in-out;
            &:hover {
              transform: scale(1.1);
              transition: 0.3s ease-out;
            }
          }
        }
        .toshi-story-container {
          height: min-content;
          overflow: hidden;
          .toshi-story {
            transition: 0.3s ease-in-out;
            &:hover {
              transform: scale(1.1);
              transition: 0.3s ease-out;
            }
          }
        }
      }
    }

    &-line1 {
      width: 80px;
      display: inline-block;
      background-color: black;
      // transform: translate(2px, -23px);
      height: 2.5px;
      // margin-top: 9px;
      margin-bottom: 12px;
    }
    &-essence {
      position: relative;
      left: 45%;
      padding-left: 37px;
      margin-top: 8%;
      margin-bottom: 200px;
      max-width: 541px;
      width: 72%;
      // &-img {
      //   transform: translate(-153px, 20px) scale(1.3) rotateY(45deg);
      // }
      &-title {
        font-weight: bold;
        font-size: 16px;
      }
      &-text {
        font-size: 16px;
        line-height: 2em;
      }
    }
    &-essen {
      max-width: 950px;
      position: relative;
      &-image1 {
        position: relative;
        max-width: 900px;
        // margin-left: 100px;
        margin-left: 61px;
        .image {
          // transform: scale(1.03);
          position: relative;
        }
      }
      &-title {
        font-weight: bold;
        font-size: 20px;
        margin-left: 22%;
        // margin-left: 26%;
        margin-top: 93px;
        line-height: 26px;
      }
      &-text {
        // font-size: 16px;
        // line-height: 26px;
        // margin-left: 26%;
        margin-left: 24%;
        // margin-top: -262px;
        margin-top: calc(min(-27%, -100px));
        position: relative;
      }
      &-body {
        font-size: 15px;
        line-height: 26px;
        // margin-left: 26%;
        margin-left: 22%;
        margin-top: 12px;
        // margin-top: 25px;
      }
    }
    &-donuts {
      max-width: 950px;
      position: relative;
      margin-top: 116px;
      margin-bottom: 34px;
      &-image1 {
        max-width: 900px;
        margin-left: 61px;
        position: relative;
        .image {
          // width: 94%;
          position: relative;
        }
      }
      &-title {
        font-weight: bold;
        font-size: 20px;
        margin-left: 25%;
        margin-top: 45px;
        line-height: 26px;
      }
      &-text {
        // font-size: 16px;
        // line-height: 26px;
        margin-left: 24%;
        // margin-top: -187px;
        margin-top: calc(max(-9%, -100px));
        position: relative;
      }
      &-text1 {
        margin-left: 25%;
        margin-bottom: 12px;
        font-size: 13px;
        font-weight: bold;
      }
      &-text2 {
        margin-left: 25%;
        margin-bottom: 24px;
        font-size: 16px;
      }
      &-text3 {
        margin-left: 25%;
        margin-bottom: 12px;
        font-size: 13px;
        font-weight: bold;
      }
      &-logo {
        position: relative;
        margin-left: 21%;
        margin-bottom: 12px;
        .logoimage {
          width: 50%;
          transform: scale(0.7);
          // height: 4vh;
        }
      }
      &-text4 {
        margin-left: 25%;
        font-size: 13px;
        // font-weight: bold;
      }
      &-body {
        font-size: 15px;
        line-height: 26px;
        margin-left: 25%;
        margin-top: 12px;
        // margin-top: 25px;
      }
    }
    &-agriuber {
      max-width: 950px;
      &-image1 {
        max-width: 900px;
        margin-left: 61px;
      }
      &-title {
        font-weight: bold;
        font-size: 20px;
        margin-left: 26%;
        margin-top: 93px;
        line-height: 26px;
      }
      &-text {
        // font-size: 16px;
        // line-height: 26px;
        margin-left: 24%;
        margin-top: -168px;
        position: relative;
      }
      &-body {
        font-size: 15px;
        line-height: 26px;
        margin-left: 26%;
        margin-top: 12px;
        // margin-top: 25px;
      }
    }
    &-donut {
      margin-top: 58px;
      margin-bottom: 58px;
      &-image1 {
        max-width: 900px;
        margin-left: 100px;
      }
      &-title {
        font-weight: bold;
        font-size: 16px;
        // margin-left: 26%;
        margin-top: -1%;
        margin-bottom: 25px;
      }
      &-text {
        margin-left: calc(min(61%, 450px));
        // font-size:16px;
        // line-height: 2em;
        // margin-left: 26%;
      }
      &-body {
        font-size: 16px;
        line-height: 26px;
      }
    }
    &-agriube {
      &-image1 {
        max-width: 900px;
        margin-left: 100px;
      }
      &-title {
        font-weight: bold;
        font-size: 16px;
        // margin-left: 26%;
        margin-top: -1%;
        margin-bottom: 25px;
        line-height: 26px;
      }
      &-text {
        margin-left: calc(min(61%, 450px));
        // font-size: 16px;
        // line-height: 2em;
        // margin-left: 26%;
      }
      &-body {
        font-size: 16px;
        line-height: 26px;
      }

    }
    &-alterna {
      max-width: 950px;
      position: relative;
      margin-top: 116px;
      margin-bottom: 34px;
      &-image1 {
        max-width: 900px;
        margin-left: 61px;
        position: relative;
        .image {
          position: relative;
        }
      }
      &-text {
        margin-left: 24%;
        margin-top: -133px;
        position: relative;
      }
      &-title {
        font-weight: bold;
        font-size: 20px;
        margin-left: 25%;
        margin-top: 45px;
        line-height: 26px;
      }
      &-body {
        font-size: 15px;
        line-height: 26px;
        margin-left: 25%;
        margin-top: 12px;
      }
    }
    &-kyotoUni {
      max-width: 950px;
      position: relative;
      margin-top: 190px;
      margin-bottom: 34px;
      &-image1 {
        max-width: 900px;
        margin-left: 61px;
        position: relative;
        .image {
          position: relative;
        }
      }
      &-text {
        margin-left: 24%;
        margin-top: -133px;
        position: relative;
      }
      &-title {
        font-weight: bold;
        font-size: 20px;
        margin-left: 25%;
        margin-top: 45px;
        line-height: 26px;
        transform:translateX(-12px);
      }
      &-title2{
        font-weight: bold;
        font-size: 20px;
        margin-left: 25%;
        line-height: 26px;
      }
      &-body {
        font-size: 15px;
        line-height: 26px;
        margin-left: 25%;
        margin-top: 12px;
      }
    }


    // &-donuts {
    //   padding-left: 302px;
    //   margin-bottom: 150px;
    //   &-img1 {
    //     transform: translate(92px, 51px) scale(1.5);
    //   }
    //   &-img2 {
    //     transform: translate(67px, 182px);
    //   }
    //   &-title {
    //     padding-left: 57px;
    //     font-weight: bold;
    //     font-size: 16px;
    //   }
    //   &-text {
    //     font-size: 16px;
    //     padding-left: 57px;
    //     line-height: 2em;
    //   }
    // }
    // &-agriuber {
    //   padding-left: 302px;
    //   margin-bottom: 189px;
    //   &-img {
    //     transform: translate(165px, 115px) scale(1.5);
    //   }
    //   &-title {
    //     font-weight: bold;
    //     font-size: 16px;
    //     padding-left: 57px;
    //     padding-top: 78px;
    //   }
    //   &-text {
    //     padding-left: 57px;
    //     font-size: 16px;
    //     line-height: 2em;
    //     padding-top: 11px;
    //   }
    // }
    // &-agriimage {
    //   padding-left: 302px;
    //   &-img {
    //     transform: translate(-163px, 212px) scale(1.3);
    //   }
    // }
    &-content{
      margin-top: 65px;
      .title{
        &--number{
          position: relative;
          margin-bottom: 12px;
          padding-bottom: 13px;
          font-size: 25px;
          font-weight: bold;
          &::after {
            position: absolute;
            content: "";
            bottom: 0;
            left: 0;
            background: #242424;
            height: 4px;
            width: 80px;
          }
        }
        &--text{
          font-size: 24px;
          font-weight: bold;
          margin-top: 12px;
        }
      }
      .description{
        font-size: 15px;
        line-height: 26px;
        margin-top: 7px;
        @include desktop{
          font-size: 13px;
          line-height: normal;
          min-width: 500px;
        }
      }
      .list{
        padding-left: 18px;
        .item{
          list-style: decimal;
        }
      }
      .image-container{
        margin-top: 65px;
        display: grid;
        grid-template-columns: repeat(3,1fr);
        width: 100%;
        .image{
          object-fit: cover;
          &:last-child{
            margin-left: 14px;
          }
        }
      }
      &--video{
        display: flex;
        flex-direction: column;
        @include desktop{
          flex-direction: row;
        }

        .portfolio-video {
          position: relative;
          @include desktop{
            top: -24px;
          }
          &--branding{
            margin: 16px 0;
            width: 100%;
            height: auto;
            @include desktop{
              width:40%;
            }
          }
          &--promo{
            margin: 16px 0;
            width: 60%;
            height: auto;
            @include desktop{
              width: 20%;
            }
          }
        }
      }
    }
    &-illustration {
      margin-bottom: 65px;
      &-texta {
        position: relative;
        font-size: 25px;
        // height: 38px;
        margin-bottom: 12px;
        padding-bottom: 13px;
        font-weight: bold;
        margin-top: 116px;
        &::after {
          position: absolute;
          content: "";
          bottom: 0;
          left: 0;
          background: #242424;
          height: 4px;
          width: 80px;
        }
      }
      &-textb {
        font-size: 24px;
        font-weight: bold;
        margin-top: 12px;
        // padding-top: 10px;
      }
      &-textc {
        line-height: 26px;
        margin-top: 7px;
        width: 90%;
      }
      &-head {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        justify-items: center;
      }
      &-image {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        justify-items: center;
        margin-bottom: 116px;
        grid-column-gap: 138px;
        grid-row-gap: 57px;
        transform: scale(0.9);
        .insect {
          .portfolio-img {
            width: 236px;
            height: 400px;
            object-fit: contain;
          }
        }
        .rat {
          .portfolio-img {
            position: relative;
            object-fit: contain;
            height: 348px;
            width: 239px;
            top: 51px;
          }
        }
        .helmet {
          .portfolio-img {
            position: relative;
            object-fit: contain;
            height: 369px;
            width: 236px;
            top: 30px;
          }
        }
        .frog {
          .portfolio-img {
            position: relative;
            object-fit: contain;
            width: 240px;
            height: 468px;
          }
        }
        .tool {
          .portfolio-img {
            position: relative;
            object-fit: contain;
            top: 50px;
            width: 236px;
            height: 417px;
          }
        }
        .cap {
          .portfolio-img {
            position: relative;
            object-fit: contain;
            width: 236px;
            height: 463px;
            top: 4px;
          }
        }
      }
      &-head {
        margin-bottom: 20px;
        transform: scale(0.7);
        .portfolio-img {
          width: 72px;
          height: 169px;
        }
      }
    }
    &-line2 {
      width: 80px;
      display: inline-block;
      background-color: black;
      transform: translate(2px, -2px);
      height: 2.5px;
    }
    &-pampflets {
      &-texta {
        position: relative;
        font-size: 25px;
        // height: 38px;
        margin-bottom: 12px;
        padding-bottom: 13px;
        font-weight: bold;
        &::after {
          position: absolute;
          content: "";
          bottom: 0;
          left: 0;
          background: #242424;
          height: 4px;
          width: 80px;
        }
      }
      &-textb {
        font-size: 24px;
        font-weight: bold;
        margin-top: 12px;
        margin-bottom: 7px;
        // padding-top: 10px;
      }
      &-textc {
        line-height: 26px;
      }
      &-image {
        display: grid;
        left: 18vw;
        grid-template-columns: repeat(3, 1fr);
        transform: scale(0.9) translate(34px, 10px);
        margin-top: 100px;
        margin-bottom: 192px;
        grid-column-gap: 43px;
      }
    }
    &-line3 {
      width: 80px;
      display: inline-block;
      background-color: black;
      height: 2.5px;
    }
  }
  // .pamphbackground {
  //   background: url("../images/pc/top/background.jpg");
  //   background-repeat: no-repeat;
  //   background-position: center top;
  //   overflow: hidden;
  //   position: absolute;
  //   margin-top: -523px;
  //   margin-left: -341px;
  //   height: 100%;
  //   width: calc(100% + 641px);
  //   z-index: -1;
  // }

  .essence-one {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      font-size: 100px;
      background: url(../images/pc/sises/img.png);
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      width: 100%;
      height: 100%;
      max-width: 353px;
      max-height: 434px;
      top: 68%;
      left: 0%;
      z-index: -2;
    }
  }
  .donuts-two {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      font-size: 100px;
      background: url(../images/pc/sises/img2.png);
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      width: 100%;
      height: 100%;
      max-width: 353px;
      max-height: 434px;
      top: 51%;
      left: 0%;
      z-index: -2;
      // z-index: -1;
      // left: 10px;
      // top: 1719px;
      // left: 61px;
    }
  }
  .agriuber-three {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      font-size: 100px;
      background: url(../images/pc/sises/img3.png);
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      width: 100%;
      height: 100%;
      max-width: 353px;
      max-height: 434px;
      left: 0%;
      top: 50%;
      z-index: -2;
      // top: 51%;
      // left: 0%;
      // z-index: -2;
    }
  }

  .portfoliomobile {
    display: grid;
    justify-content: center;
    padding: 0px 24px 0px 20px;
    &-top {
      &-text1 {
        position: relative;
        font-size: 30px;
        font-weight: bold;
        margin-top: 20px;
        margin-bottom: 12px;
        padding-bottom: 17px;
        &::after {
          position: absolute;
          content: "";
          bottom: 0;
          left: 0;
          background: #242424;
          height: 6px;
          width: 100px;
        }
      }
      // &-line {
      //   display: inline-block;
      //   width: 100px;
      //   background-color: black;
      //   height: 8px;
      // }
      &-text2 {
        font-weight: bold;
        font-size: 12px;
        margin-top: 12px;
      }
    }
    &-UI {
      position: relative;
      margin-top: 61px;
      &-text1 {
        position: relative;
        font-weight: bold;
        margin-bottom: 12px;
        padding-bottom: 13px;
        font-size: 25px;
        &::after {
          position: absolute;
          content: "";
          bottom: 0;
          left: 0;
          background: #242424;
          height: 4px;
          width: 80px;
        }
      }
      &-line {
        width: 50px;
        display: inline-block;
        background-color: black;
        height: 2.5px;
      }
      &-text2 {
        font-weight: bold;
        margin-top: 12px;
        font-size: 30px;
      }
      &-text3 {
        margin-top: 9px;
        line-height: normal;
        margin-bottom: 53px;
        font-size: 13px;
      }
    }
    &-essence {
      &-image1 {
        transform: scale(1.04);
      }
      &-image2 {
        &::after {
          content: "01";
          position: absolute;
          font-weight: bold;
          font-size: 50px;
          color: #f2f2f2;
          left: 310px;
          top: -5px;
          @media screen and (max-width: 1024px) {
            left: 372px;
          }
          @media screen and (max-width: 375px) {
            left: 272px;
          }
          @media screen and (max-width: 280px) {
            left: 171px;
          }
        }
        transform: translateX(8px);
        margin-top: 8px;
      }
      &-title {
        font-size: 16px;
        line-height: normal;
        font-weight: bold;
        margin-bottom: 12px;
      }
      &-text {
        // margin-top: 25px;
        line-height: normal;
        margin-bottom: 65px;
        font-size: 13px;
      }
    }
    &-donuts {
      &-image1 {
        // transform: scale(1.065) translate(-2px, 0px);
        margin-bottom: 8px;
      }
      &-image2 {
        &::after {
          content: "02";
          position: absolute;
          font-size: 59px;
          font-weight: bold;
          color: #f2f2f2;
          left: 368px;
          top: -18px;
          @media screen and (max-width: 1024px) {
            left: 372px;
          }
          @media screen and (max-width: 375px) {
            left: 266px;
            top: -18px;
          }
          @media screen and (max-width: 280px) {
            left: 189px;
            top: -18px;
          }
        }
        margin-bottom: 8px;
        transform: translate(-1px, -2px) scale(0.9);
      }
      &-text1 {
        font-size: 11px;
        line-height: normal;
        font-weight: bold;
      }
      &-text2 {
        font-size: 13px;
        line-height: normal;
        margin-bottom: 8px;
      }
      &-text3 {
        font-size: 11px;
        line-height: normal;
        font-weight: bold;
        margin-bottom: 4px;
      }
      &-logo {
        .logoimage {
          @media screen and (max-width: 1024px) {
            width: 30%;
          }
          @media screen and (max-width: 768px) {
            width: 50%;
          }
        }
      }
      &-text4 {
        font-size: 13px;
        line-height: normal;
        margin-top: 8px;
      }

      &-title {
        font-size: 16px;
        font-weight: bold;
        line-height: normal;
        margin: 20px 0 12px 0;
      }
      &-body {
        line-height: normal;
        margin-bottom: 58px;
        font-size: 13px;
      }
    }
    &-agriuber {
      &-image1 {
        transform: scale(1.13);
      }
      &-image2 {
        &::after {
          content: "03";
          position: absolute;
          font-size: 50px;
          font-weight: bold;
          color: #f2f2f2;
          left: 303px;
          top: -32px;
          @media screen and (max-width: 1024px) {
            left: 352px;
            top: -19px;
          }
          @media screen and (max-width: 375px) {
            left: 230px;
          }
          @media screen and (max-width: 280px) {
            left: 158px;
          }
        }
        margin-bottom: 20px;
        transform: translate(24px, -23px) scale(1.1);
        @media screen and (max-width: 375px) {
          transform: translate(14px, -18px) scale(1.1);
          margin-top: 10px;
        }
      }
      &-title {
        margin: 20px 0 12px 0;
        font-size: 16px;
        font-weight: bold;
        line-height: normal;
      }
      &-text {
        line-height: normal;
        // margin-top: 20px;
        font-size: 13px;
      }
    }
    &-kyotoUni {
      &-image1 {
        transform: scale(1.05);
        margin-left:10px;
        margin-top:35px;
      }
      &-image2 {
        &::after {
          content: "03";
          position: absolute;
          font-size: 50px;
          font-weight: bold;
          color: #f2f2f2;
          left: 303px;
          top: -32px;
          @media screen and (max-width: 1024px) {
            left: 352px;
            top: -19px;
          }
          @media screen and (max-width: 375px) {
            left: 230px;
          }
          @media screen and (max-width: 280px) {
            left: 158px;
          }
        }
        margin-bottom: 20px;
        transform: translate(24px, -23px) scale(1.1);
        @media screen and (max-width: 375px) {
          transform: translate(14px, -18px) scale(1.1);
          margin-top: 10px;
        }
      }
      &-title {
        margin: 20px 0 0px 0;
        font-size: 16px;
        font-weight: bold;
        line-height: normal;
        transform:translateX(-10px);
      }
      &-title2{
        margin: 0px 0 12px 0;
        font-size: 16px;
        font-weight: bold;
        line-height: normal;
      }
      &-text {
        line-height: normal;
        // margin-top: 20px;
        font-size: 13px;
      }
    }
    &-alterna {
      margin-top: 50px;
      margin-bottom: 65px;
      &-image1 {
        transform: translatex(11px) scale(1.1);
      }

      &-title {
        margin: 0 0 12px 0;
        font-size: 16px;
        font-weight: bold;
        line-height: normal;
      }
      &-text {
        line-height: normal;
        // margin-top: 20px;
        font-size: 13px;
      }
    }
    &-illustration {
      margin-top: 100px;
      &-text1 {
        position: relative;
        margin-bottom: 12px;
        padding-bottom: 13px;
        font-size: 25px;
        font-weight: bold;
        &::after {
          position: absolute;
          content: "";
          bottom: 0;
          left: 0;
          background: #242424;
          height: 4px;
          width: 80px;
        }
      }
      &-line {
        width: 50px;
        display: inline-block;
        background-color: black;
        height: 2.5px;
        // @media screen and (max-width: 375px) {
        //   width: 16%;
        // }
      }
      &-text2 {
        margin-top: 10px;
        font-size: 30px;
        font-weight: bold;
      }
      &-text3 {
        margin-top: 10px;
        margin-bottom: 65px;
        line-height: normal;
        font-size: 13px;
      }
      &-text4 {
        margin-top: 10px;
        line-height: normal;
        font-size: 13px;
      }
    }
    &-illuheadimage {
      display: grid;
      justify-content: center;
      margin-bottom: 14px;
    }
    // &-illuimages {
    //   display: grid;
    //   // justify-content: center;
    //   justify-items: center;
    //   grid-template-columns: repeat(5, 1fr);
    //   column-gap: 7.6%;
    //   margin-bottom: 100px;
    //   &-column-1 {
    //     &-insect {
    //       padding-top: 19vw;
    //     }
    //   }
    //   &-column-2 {
    //     &-helmet {
    //       padding-top: 65vw;
    //     }
    //   }
    //   &-column-3 {
    //     &-frog {
    //       padding-top: 14vw;
    //     }
    //     &-head {
    //       width: 70%;
    //       padding-top: 8vw;
    //     }
    //   }
    //   &-column-4 {
    //     &-tool {
    //       padding-top: 70vw;
    //     }
    //   }
    //   &-column-5 {
    //     &-mouse {
    //       padding-top: 25vw;
    //     }
    //   }

    // }

    .sketch-container {
      margin-bottom: 100px;
      position: relative;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 25%;
      .column-1 {
      }
      .insect {
      }
      .column-2 {
      }
      .helmet {
        padding-top: 27vw;
      }
      .column-3 {
      }
      .frog {
        position: relative;
        top: -52px;
      }
      .head {
        padding-top: 28vw;
      }
      .column-4 {
      }
      .tool {
        padding-top: 11vw;
      }
      .column-5 {
      }
      .mouse {
        position: relative;
        top: -91px;
      }
      .column-6 {
      }
      .head {
        padding-top: 10vw;
      }
    }

    .paper-container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 10%;
      margin-top: 9vw;
    }
    .broadcast-container {
      height: min-content;
      overflow: hidden;
    }
    .broadcast {
      transition: 0.3s ease-in-out;
      &:hover {
        transform: scale(1.1);
        transition: 0.3s ease-out;
      }
    }
    .csr-container {
      margin-top: 85px;
      overflow: hidden;
    }
    .csr {
      transition: 0.3s ease-in-out;
      &:hover {
        transform: scale(1.1);
        transition: 0.3s ease-out;
      }
    }
    .toshi-story-container {
      height: min-content;
      overflow: hidden;
    }
    .toshi-story {
      transition: 0.3s ease-in-out;
      &:hover {
        transform: scale(1.1);
        transition: 0.3s ease-out;
      }
    }
    &-pamphlet {
      &-text1 {
        position: relative;
        margin-bottom: 12px;
        padding-bottom: 13px;
        font-size: 25px;
        font-weight: bold;
        &::after {
          position: absolute;
          content: "";
          bottom: 0;
          left: 0;
          background: #242424;
          height: 4px;
          width: 80px;
        }
      }
      &-line {
        width: 50px;
        display: inline-block;
        background-color: black;
        height: 2.5px;
        // @media screen and (max-width: 375px) {
        //   width: 16%;
        // }
      }
      &-text2 {
        margin-top: 10px;
        font-size: 30px;
        font-weight: bold;
      }
      &-text3 {
        margin-top: 10px;
        margin-bottom: 25px;
        line-height: 26px;
        font-size: 13px;
      }
    }
    &-pamphimages {
      display: grid;
      justify-content: center;
      grid-template-columns: repeat(3, 1fr);
    }
  }

  // .portfolio-gallery {
  //   position: relative;
  //   .sketch-container {
  //     display: grid;
  //     grid-template-columns: repeat(5, 1fr);
  //     column-gap: 7.29%;
  //     justify-items: center;
  //     @media screen and (max-width: 1024px) {
  //       column-gap: initial;
  //     }
  //     .column-3 {
  //       display: flex;
  //       flex-direction: column;
  //       align-items: center;
  //     }
  //     .column-4 {
  //       display: flex;
  //       flex-direction: column;
  //       align-items: center;
  //     }
  //     .column-5 {
  //       display: flex;
  //       flex-direction: column;
  //       align-items: flex-end;
  //     }

  //     .insect {
  //       padding-top: 272px;
  //       @media screen and (max-width: 1024px) {
  //         padding-top: 19.15vw;
  //       }
  //     }
  //     .helmet {
  //       padding-top: 605px;
  //       @media screen and (max-width: 1024px) {
  //         padding-top: 65.15vw;
  //       }
  //     }
  //     .frog {
  //       padding-top: 169px;
  //       @media screen and (max-width: 1024px) {
  //         padding-top: 14vw;
  //       }
  //     }
  //     .head {
  //       padding-top: 203px;
  //       @media screen and (max-width: 1024px) {
  //         width: 30%;
  //         padding-top: 8.17vw;
  //       }
  //     }
  //     .tool {
  //       padding-top: 635px;
  //       width: 60%;

  //       @media screen and (max-width: 1024px) {
  //         width: 39%;
  //         padding-top: 70.15vw;
  //       }
  //       @media screen and (max-width: 768px) {
  //         padding-top: 66.15vw;
  //         width: 39%;
  //       }
  //     }
  //     .mouse {
  //       padding-top: 333px;
  //       width: 80%;
  //       @media screen and (max-width: 1024px) {
  //         padding-top: 25.15vw;
  //       }
  //       // @media screen and (max-width: 768px) {
  //       //   padding-top: 25.15vw;
  //       //   width: 80%;
  //       // }
  //     }
  //   }
  //   .paper-container {
  //     display: grid;
  //     grid-template-columns: repeat(3, 1fr);
  //     column-gap: 10%;
  //     margin-top: 9vw;

  //     .broadcast-container {
  //       height: min-content;
  //       overflow: hidden;
  //       .broadcast {
  //         transition: 0.3s ease-in-out;
  //         &:hover {
  //           transform: scale(1.1);
  //           transition: 0.3s ease-out;
  //         }
  //       }
  //     }
  //     .csr-container {
  //       margin-top: 85px;
  //       overflow: hidden;
  //       .csr {
  //         transition: 0.3s ease-in-out;
  //         &:hover {
  //           transform: scale(1.1);
  //           transition: 0.3s ease-out;
  //         }
  //       }
  //     }
  //     .toshi-story-container {
  //       height: min-content;
  //       overflow: hidden;
  //       .toshi-story {
  //         transition: 0.3s ease-in-out;
  //         &:hover {
  //           transform: scale(1.1);
  //           transition: 0.3s ease-out;
  //         }
  //       }
  //     }
  //   }
  // }
}

.why-how {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1000px;
  // margin-top: 2724px;
  @media screen and (max-width: 1024px) {
    margin-top: 100px;
  }
  .why-container {
    color: #303030;
    padding-top: 20%;
  }
  .how-container {
    padding-top: 20%;
    color: #fff;
    background-color: #000;
  }
  .title {
    text-align: center;
    font-size: 120px;
    font-weight: bold;
    @media screen and (max-width: 786px) {
      font-size: 39px;
    }
  }
  .text {
    margin-top: 24px;
    text-align: center;
    font-size: 30px;
    @media screen and (max-width: 1024px) {
      font-size: 9px;
    }
  }
  .middle-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    @media screen and (max-width: 768px) {
      top: 25%;
      left: 0;
      transform: initial;
    }

    &-container {
      padding: 6.45%;
      line-height: 26px;
      background-color: #fff;
      @media screen and (max-width: 768px) {
        padding: 6.45% 6.45% 0 6.45%;
      }
    }
    &-title {
      font-size: 30px;
      font-weight: bold;
      @media screen and (max-width: 768px) {
        font-size: 15px;
      }
    }
    &-body {
      margin: 20px 0 50px 0;
      font-size: 15px;
    }
  }
}
.collab {
  margin-top: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 1024px) {
    margin-top: 600px;
  }
  @media screen and (max-width: 768px) {
    margin-top: 150px;
  }
  &-globe {
    width: 609px;
    height: 465px;
    @media screen and (max-width: 1024px) {
      width: initial;
      height: initial;
    }
  }
  &-title {
    margin: 50px 0 30px 0;
    font-size: 60px;
    font-weight: bold;
    @media screen and (max-width: 1024px) {
      font-size: 25px;
    }
  }
  &-text {
    max-width: 733px;
    line-height: 26px;
    font-size: 15px;
    @media screen and (max-width: 1024px) {
      padding: 0 6.45%;
    }
  }
  &-map {
    width: 80%;
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }
}
.ai {
  min-width: 1255px;
  line-height: 26px;
  @media screen and (max-width: 1024px) {
    min-width: initial;
  }
  .ai-top {
    display: flex;
    align-items: center;
    @media screen and (max-width: 1024px) {
      color: #fff;
      align-items: initial;
    }

    &-image {
      width: 50%;
      height: auto;
      @media screen and (max-width: 1024px) {
        width: 100%;
        height: 400px;
        object-fit: cover;
      }
      @media screen and (max-width: 768px) {
        height: auto;
        object-fit: initial;
      }
    }
    &-text-container {
      // width: 539px;
      // height: 334px;
      position: relative;
      left: -100px;
      background: #fff;
      @media screen and (max-width: 1024px) {
        position: absolute;
        left: initial;
        background: initial;
      }
      @media screen and (max-width: 768px) {
        position: absolute;
        display: flex;
        left: initial;
        background: initial;
      }
    }
    &-title-container {
      padding: 50px 0 0 20px;
      @media screen and (max-width: 1024px) {
        padding: 20px 0 0 6.25%;
      }
    }
    &-title {
      font-size: 54px;
      font-weight: bold;
      line-height: normal;
    }
    &-subtitle {
      font-size: 19px;
      font-weight: bold;
    }
    &-text {
      width: 60%;
      padding: 50px 20px;
      font-size: 15px;
      @media screen and (max-width: 1024px) {
        width: initial;
        padding: 20px 10px;
        font-size: 13px;
      }
    }
  }
  .ai-bottom {
    padding: 10vw 3vw 0;
    @include desktop {
      position: relative;
      background: #fff;
      top: -50px;
      left: 14.47%;
      padding: 60px 0 0 60px;
      width: 50%;
      padding: 10vw 3vw;
    }
    .ai-text {
      &-title {
        font-size: 30px;
        font-weight: bold;
        @media screen and (max-width: 1024px) {
          font-size: 20px;
        }
      }
      &-subtitle {
        padding-top: 10px;
        font-size: 20px;
        font-weight: bold;
        @media screen and (max-width: 1024px) {
          font-size: 15px;
        }
      }
      &-body {
        padding: 20px 0 50px 0;
        font-size: 15px;
        @media screen and (max-width: 1024px) {
          font-size: 12px;
        }
      }
    }
  }
  .ai-case {
    display: grid;
    grid-template-areas:
      "left right"
      "left right"
      "detail detail";
    grid-template-columns: 35% 1fr;

    &-title {
      font-size: 20px;
      font-weight: bold;
      padding-bottom: 20px;
      &-sp {
        font-weight: bold;
        font-size: 20px;
        padding-left: 15px;
      }
    }
    &-left {
      grid-area: left;
      &-label {
        font-size: 15px;
        font-weight: bold;
        @media screen and (max-width: 1024px) {
          font-size: 12px;
        }
      }
    }
    &-right {
      grid-area: right;
      &-label {
        white-space: nowrap;
        font-size: 15px;
        @media screen and (max-width: 1024px) {
          font-size: 12px;
        }
      }
    }
    &-detail {
      grid-area: detail;
      margin-top: 40px;
      &-title {
        font-size: 15px;
        font-weight: bold;
        @media screen and (max-width: 1024px) {
          font-size: 12px;
        }
      }
      &-body {
        font-size: 15px;
        @media screen and (max-width: 1024px) {
          font-size: 12px;
        }
      }
    }
  }
}
.blockchain {
  margin: 100px 0;
  @media screen and (max-width: 1024px) {
    margin-bottom: 50px;
  }
  .blockchain-top {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    min-width: 1327px;
    @media screen and (max-width: 1024px) {
      margin-bottom: 40px;
      grid-template-columns: repeat(1, 1fr);
      min-width: initial;
    }
    &-title {
      font-size: 90px;
      font-weight: bold;
      justify-self: end;
      padding: 50px 56px 0 0;
      @media screen and (max-width: 1024px) {
        position: absolute;
        padding: 50px 0 0 20px;
        font-size: 45px;
        color: #fff;
        z-index: 1;
      }
      @media screen and (max-width: 768px) {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        font-size: 45px;
        color: #fff;
        z-index: 1;
        padding: 40px 0 0 0;
      }
    }
    &-right {
      position: relative;
    }
    &-image {
      width: 100%;
      @media screen and (max-width: 1024px) {
        object-fit: cover;
        height: 400px;
      }
      @media screen and (max-width: 768px) {
        object-fit: initial;
        height: auto;
      }
    }
    &-text-container {
      position: absolute;
      top: 46%;
      left: -22%;
      width: 64.7%;
      padding: 6.25% 3.125% 6.25% 0;
      background: #fff;
      line-height: 26px;
      @media screen and (max-width: 1024px) {
        top: 34%;
        left: initial;
        width: 60%;
        padding: 50px 20px;
        font-size: 15px;
        color: #fff;
        background: initial;
        z-index: 1;
      }
      @media screen and (max-width: 768px) {
        top: 35%;
        left: 0;
        width: fit-content;
        padding: 6.25%;
        color: #fff;
        background: initial;
        z-index: 1;
      }
    }
    &-text {
      font-size: 15px;
      @media screen and (max-width: 1024px) {
        font-size: 13px;
      }
    }
    &-button {
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translateY(50%);
      @media screen and (max-width: 1024px) {
        top: 100%;
        left: 50%;
        bottom: initial;
        transform: translate(-50%, -50%);
      }
      .btn-open {
        display: inline-block;
        width: 199px;
        height: 67px;
        text-align: center;
        background-color: #f21515;
        font-size: 20px;
        line-height: 66px;
        color: #fff;
      }
    }
  }

  .blockchain-bottom {
    line-height: 26px;
    padding: 5vw 3vw 0 3vw;
    @include desktop {
      position: relative;
      top: -20px;
      background: #fff;
      width: 47%;
      margin-left: 22.29%;
      padding: 66px 0 0 0;
      line-height: 26px;
    }
    &-title {
      font-size: 30px;
      font-weight: bold;
      @media screen and (max-width: 1024px) {
        font-size: 20px;
      }
    }
    &-subtitle {
      font-size: 20px;
      font-weight: bold;
      padding-top: 12px;
      @media screen and (max-width: 1024px) {
        font-size: 15px;
      }
    }
    &-body {
      padding: 25px 0 40px 0;
      font-size: 15px;
      @media screen and (max-width: 1024px) {
        font-size: 12px;
      }
    }
  }
}
.it-design {
  .it-design-top {
    position: relative;
    &-image {
      width: 71.2%;
      @media screen and (max-width: 1024px) {
        width: 100%;
        height: 400px;
        object-fit: cover;
      }
      @media screen and (max-width: 768px) {
        width: 100%;
        height: auto;
        object-fit: initial;
      }
    }
    &-text-container {
      position: relative;
      top: -60px;
      background: #fff;
      margin-left: 14.74%;
      padding: 40px 20px 0 40px;
      width: 36.56%;
      // width: 702px;
      line-height: 26px;
      @media screen and (max-width: 1024px) {
        top: 0;
        margin-left: initial;
        width: 100%;
        background: initial;
      }
      @media screen and (max-width: 768px) {
        // position: absolute;
        // top: 0;
        // margin-left: initial;
        // color: #fff;
        width: 100%;
        background: initial;
      }
    }
    &-maintitle {
      line-height: 45px;
      font-size: 45px;
      font-weight: bold;
      position: absolute;
      top: 0;
      color: #fff;
      width: 100%;
      padding: 40px 20px 0 40px;
    }
    &-title {
      font-size: 30px;
      font-weight: bold;
      padding: 50px 0 25px;
    }
    &-subtitle {
      font-size: 15px;
      font-weight: bold;
    }
    &-body {
      padding-bottom: 20px;
      font-size: 15px;
      @media screen and (max-width: 768px) {
        font-size: 13px;
      }
      &-sp {
        line-height: 26px;
        font-size: 15px;
        position: absolute;
        top: 12%;
        color: #fff;
        width: 60%;
        padding: 40px 20px 0 40px;
        @media screen and (max-width: 768px) {
          font-size: 13px;
          width: 100%;
        }
      }
    }
  }
}

.sises-carousel__item--container {
  @include desktop {
    height: 548px;
    width: 698px;
    padding: 88px 220px 50px 45px;
  }
  @include l-desktop {
    height: 548px;
    width: 698px;
    padding: 88px 99px 50px 115px;
  }
}

@media screen and (max-width: 1024px) {
  .sises_header {
    padding-top: 60px;
    height: 100vh;
    position: relative;
    display: block;

    &__background {
      position: absolute !important;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: url(../images/sp/sises/ge_mobile.jpg);
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  #page_title_sises {
    position: relative;
    overflow: visible; // Use this when the before/after subtitle message looks weird
    margin-top: auto;
    width: 100%;
    height: initial;
    padding: 20px 20px 20px 10px;
    color: white;
    font-size: 40px;
    line-height: 1.5;
    font-weight: bold;

    @media screen and (max-width: 350px) {
      font-size: 30px;
    }

    &::before {
      content: "";
      width: 1px;
      height: 190px;
      background-color: #fff;
      position: absolute;
      top: -140px;
      right: 30px;

      @media screen and (max-width: 350px) {
        top: -104px;
        height: 150px;
      }
    }

    &::after {
      position: absolute;
      top: 120px;
      right: -5px;
      transform: rotate(90deg);
      width: 74px;
      color: #fff;
      font-size: 6px;
      font-weight: 100;
      content: "WHAT WE DO";

      @media screen and (max-width: 350px) {
        top: 95px;
      }
    }
  }

  .sises_header_description {
    display: flex;
    color: #fff;

    .sises_text {
      font-size: 12px;
      line-height: 1.6;
      letter-spacing: 0.03em;
      max-width: 650px;
      padding: 20px 20px 68px 20px;
    }
  }

  .sises_header__text-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    // align-items: center;
    height: calc(100vh - 60px);
  }

  .ge_section {
  }

  .ge_section a {
    text-decoration: none;
  }

  .ge_section h1 {
    text-align: center;
    color: white;
    margin-bottom: 1em;
  }

  .ge_section .container {
    margin: auto;
    width: 100%;
    background: #fff;
  }

  .ge_section ul > li {
    list-style: none;
  }

  .ge_section ul > li > label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ge_section ul > li > a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ge_section ul {
    padding: 0;
  }

  .ge_section .menu > .menu-item.label {
    padding-left: 15px;
    padding-right: 15px;
  }

  .ge_section .menu > .menu-item > label {
    padding-left: 2.5em;
  }

  .ge_section .accordion-menu input[type="checkbox"] {
    position: absolute;
    opacity: 0;
  }

  .ge_section .accordion-menu label::after {
    background: #ff3c41;
    left: 0.5em;
    background-position: -12px 0;
  }

  .ge_section .menu > .menu-item > label::after {
    left: 1.5em;
    background-position: -12px 0;
  }

  .ge_section .menu-item .actions > button {
    display: inline-block;
  }

  .ge_section button {
    color: #ff3c41;
    background: transparent;
    border-radius: 4px;
    border: transparent;
    /* font-size: 3em; */
    width: 1.2em;
    height: 1.2em;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .ge_section button:hover {
    background: black;
    color: white;
  }

  .ge_section .accordion-menu label {
    position: relative;
    justify-content: space-between;
    display: flex;
    padding: 1.2em 1.2em 0.2em 1.2em;
    color: #000;
    cursor: pointer;
    border-bottom: 0.5px solid #f8f8f8;
  }

  .ge_section label span {
    position: relative;
    justify-content: space-between;
    display: flex;
    color: #242424;
    cursor: pointer;
    /* font-size: 45px; */
  }

  .ge_section .accordion-menu a {
    position: relative;
    justify-content: space-between;
    display: flex;
    padding: 0.5em 0.5em 0.5em 1.5em;
    color: #bbb;
    font-size: 1.2em;
    cursor: pointer;
  }

  .ge_section .accordion-menu ul {
    display: none;
    background-color: #f8f8f8;
  }

  .ge_section .accordion-menu input[type="checkbox"]:checked + label + ul,
  .ge_section
    .accordion-menu
    input[type="checkbox"]:checked
    + label:nth-of-type(n)
    + ul {
    display: block;
  }

  .ge_section .accordion-menu input[type="checkbox"]:checked + label::after {
    background: #0ebeff;
  }

  .sises_section .menu > .label {
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 50px;
    background-color: #fff;
    line-height: normal;
  }

  .ge_section .menu-item > label {
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding: 10px 10px;
    background-color: #fff;
    /* font-size: 45px; */
    height: 5vh;
    line-height: normal;
  }

  // .ge_section .menu>.label:hover,
  // .ge_section .menu-item>label:hover {
  //     background: #222;
  //     box-shadow: inset 4px 0 0 0 #0ebeff;
  //     color: #fff;
  // }

  .ge_section .by {
    position: fixed;
    bottom: 0.4em;
    right: 0.6em;
  }

  .ge .accordion_section .accordion-menu input[type="checkbox"] {
    position: absolute;
    opacity: 0;
  }

  .ge .accordion_section .accordion-menu label::after {
    background: #ff3c41;
    left: 0.5em;
    background-position: -12px 0;
  }

  .ge .accordion_section .menu > .menu-item > label::after {
    left: 1.5em;
    background-position: -12px 0;
  }

  .ge .accordion_section .menu-item .actions > button {
    display: inline-block;
  }

  .ge .accordion_section button {
    color: $red;
    background: transparent;
    border-radius: 4px;
    border: transparent;
    font-size: 1em;
    width: 1em;
    height: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    /* cursor: pointer; */
  }

  .ge .accordion_section button:hover {
    background: black;
    color: white;
  }

  .ge .accordion_section .accordion-menu label {
    position: relative;
    justify-content: space-between;
    display: flex;
    padding: 1.2em 1.2em 1.2em 1.5em;
    color: #000;
    cursor: pointer;
    border-bottom: 0.5px solid #ccc;
    width: 345px;
  }

  .ge .accordion_section label span {
    position: relative;
    justify-content: space-between;
    display: flex;
    color: #bbb;
    /* cursor: pointer; */
    font-size: 15px;
  }

  .ge .accordion_section .accordion-menu a {
    position: relative;
    justify-content: space-between;
    display: flex;
    padding: 0.5em 0.5em 0.5em 1.5em;
    color: #bbb;
    font-size: 1.2em;
    /* cursor: pointer; */
  }

  .ge .accordion_section .accordion-menu ul {
    /* display: none; */
    display: block;
    height: 0;
    opacity: 0;
    /* padding: 0 10px; */
    transition: 0.5s;
    visibility: hidden;
  }

  .ge
    .accordion_section
    .accordion-menu
    input[type="checkbox"]:checked
    + label
    + ul,
  .ge
    .accordion_section
    .accordion-menu
    input[type="checkbox"]:checked
    + label:nth-of-type(n)
    + ul {
    display: block;

    height: 28%;
    opacity: 1;
    /* padding: 10px; */
    visibility: visible;
    overflow: hidden;
  }

  .ge
    .accordion_section
    .accordion-menu
    input[type="checkbox"]:checked
    + label::after {
    background: #0ebeff;
    transition: 0.5s;
    visibility: visible;
  }

  .ge .accordion_section .menu > .label {
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding: 10px 10px;
    background-color: #fff;
    /* font-size: 40px; */
  }

  .ge .accordion_section .menu-item > label {
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding: 10px 10px;
    background-color: #fff;
    font-size: 45px;
    height: 5vh;
  }

  // .ge .accordion_section .menu>.label:hover,
  // .ge .accordion_section .menu-item>label:hover {
  //     background: #222;
  //     box-shadow: inset 4px 0 0 0 #0ebeff;
  //     color: #fff;
  // }

  .ge .accordion_section .by {
    position: fixed;
    bottom: 0.4em;
    right: 0.6em;
  }

  .ge-case__contet--flex {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;

    .ge-case__content--flex-left {
      font-size: 12px;
      font-weight: bold;
      padding: 10px;
      width: 300px;

      &--li {
        margin-bottom: 10px;
      }
    }

    .ge-case__content--flex-right {
      font-size: 12px;
      padding: 10px;

      &--li {
        margin-bottom: 10px;
      }
    }
  }
  .ge-case__content {
    color: #000;
  }
  .sises-case__content__details-title {
    font-size: 17px;
    font-weight: bold;
    padding-bottom: 15px;
    margin-top: 50px;
    color: #000;
  }

  .sises-case__content__details-p {
    font-size: 12px;
    line-height: 1.8;
    color: #000;
  }

  .ge-headline {
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 0;
  }

  .ge-case__content--table {
    font-size: 12px;
    line-height: 2;
    color: #000;
  }

  .ge-case__content--tr {
    height: 50px;
  }

  .ge-case__content--td {
    width: 150px;
    font-weight: 600;
  }

  .ge_client__title {
    margin-bottom: 70px;
  }

  .ge_case__block {
    position: relative;
    margin-bottom: 50px;

    &--grayBg {
      background-color: #f8f8f8;
      width: 320px;
      height: 130px;
      opacity: 0;
    }

    &--title {
      font-size: 40px;
      font-weight: bold;
      position: absolute;
      top: 115px;
      left: 25px;
    }
  }
}
