$christmas-card-scale: var(--christmas-card-scale, 0.5);
$christmas-card-rectangle-offset: 65px;
$christmas-card-rectangle-height: 700px;

.christmas-card-absolute-default {
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: center;
}

.christmas-card {
  @media screen and (max-width: 891px) {
    --christmas-card-scale: 0.4;
  }

  // @media screen and (max-width: 581px) {
  //   --christmas-card-scale: 0.4;
  // }

  @media screen and (max-width: 445px) {
    --christmas-card-scale: 0.3;
    margin-bottom: 90px;
  }

  position: relative;
  width: 100vw;
  height: calc(1100px * #{$christmas-card-scale});
  margin-bottom: 140px;

  &__feather-1 {
    &__wrapper {
      @extend .christmas-card-absolute-default;

      top: 0;
    }

    &__image {
      height: calc(160px * #{$christmas-card-scale});
      transform: rotate(33deg);
    }
  }

  &__feather-2 {
    &__wrapper {
      @extend .christmas-card-absolute-default;

      top: calc(220px * #{$christmas-card-scale});
    }

    &__image {
      height: calc(80px * #{$christmas-card-scale});
      transform: translateX(calc(-220px * #{$christmas-card-scale}))
        rotate(68deg);
    }
  }

  &__feather-3 {
    &__wrapper {
      @extend .christmas-card-absolute-default;

      top: calc(460px * #{$christmas-card-scale});
    }

    &__image {
      height: calc(50px * #{$christmas-card-scale});
      transform: translateX(calc(-310px * #{$christmas-card-scale}))
        rotate(162deg);
    }
  }

  &__seagull {
    &__wrapper {
      @extend .christmas-card-absolute-default;

      top: calc(120px * #{$christmas-card-scale});
    }

    &__image {
      height: calc(840px * #{$christmas-card-scale});
      transform: translateX(calc(80px * #{$christmas-card-scale}));
    }
  }

  &__snowman {
    &__wrapper {
      @extend .christmas-card-absolute-default;

      top: calc(600px * #{$christmas-card-scale});

      @media screen and (max-width: calc(1140px * #{$christmas-card-scale})) {
        justify-content: left;
        height: auto;
      }
    }

    &__image {
      height: calc(166px * #{$christmas-card-scale});
      transform: translateX(calc(-346px * #{$christmas-card-scale}));

      @media screen and (max-width: calc(1140px * #{$christmas-card-scale})) {
        transform: translateX(calc(50px * #{$christmas-card-scale}));
      }
    }
  }

  &__merry-christmas {
    &__wrapper {
      @extend .christmas-card-absolute-default;

      top: calc(800px * #{$christmas-card-scale});
      height: calc(60px * #{$christmas-card-scale});
    }

    &__text {
      color: #ff190e;
      font: 100 calc(40px * #{$christmas-card-scale}) "Noto Sans JP";
      letter-spacing: calc(9.6px * #{$christmas-card-scale});
      text-align: center;
    }
  }

  &__freewill-logo {
    &__wrapper {
      @extend .christmas-card-absolute-default;

      top: calc(1040px * #{$christmas-card-scale});
    }

    &__image {
      height: calc(26px * #{$christmas-card-scale});
    }
  }

  &__snowflakes {
    &__wrapper {
      @extend .christmas-card-absolute-default;

      top: calc(0px * #{$christmas-card-scale});
    }

    &__image-1 {
      position: absolute;
      height: calc(600px * #{$christmas-card-scale});
      transform: translateY(calc(-160px * #{$christmas-card-scale}));
    }

    &__image-2 {
      position: absolute;
      height: calc(600px * #{$christmas-card-scale});
      transform: translateY(calc(-160px * #{$christmas-card-scale}));
    }

    &__image-3 {
      position: absolute;
      height: calc(600px * #{$christmas-card-scale});
      transform: translate(
        calc(-40px * #{$christmas-card-scale}),
        calc(-40px * #{$christmas-card-scale})
      );
    }

    &__image-4 {
      position: absolute;
      height: calc(600px * #{$christmas-card-scale});
      transform: translate(
          calc(-320px * #{$christmas-card-scale}),
          calc(60px * #{$christmas-card-scale})
        )
        scale(-1, -1);
    }

    &__image-5 {
      position: absolute;
      height: calc(600px * #{$christmas-card-scale});
      transform: translate(
        calc(-220px * #{$christmas-card-scale}),
        calc(60px * #{$christmas-card-scale})
      );
    }

    &__image-6 {
      position: absolute;
      height: calc(480px * #{$christmas-card-scale});
      transform: translate(
        calc(300px * #{$christmas-card-scale}),
        calc(220px * #{$christmas-card-scale})
      );
    }
  }

  .static-background {
    display: flex;
    justify-content: center;
    align-items: center;

    .rectangles {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: calc(45px * #{$christmas-card-scale});
      height: calc(
        calc(
            #{$christmas-card-rectangle-height} + calc(#{$christmas-card-rectangle-offset} *
                  2)
          ) * #{$christmas-card-scale}
      );

      .rectangle {
        background-color: #f8f8f8;
        height: calc(
          #{$christmas-card-rectangle-height} * #{$christmas-card-scale}
        );

        &__center {
          width: calc(475px * #{$christmas-card-scale});
          align-self: center;
          display: flex;
          justify-content: center;
          align-items: flex-end;

          .v-line {
            background-color: #f02a2a;
            width: 1px;
            height: calc(550px * #{$christmas-card-scale});
            transform: translateY(calc(-40px * #{$christmas-card-scale}));
          }
        }

        &__left {
          justify-self: right;
          align-self: start;
        }

        &__right {
          justify-self: left;
          align-self: end;
        }

        // left & right
        &:not(:nth-child(2)) {
          width: calc(125px * #{$christmas-card-scale});
        }
      }
    }
  }
}
