// Contact
.l-contact-area {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 200px;
  z-index: 0;
  @include desktop {
      position: relative;
      min-height: 300px;
      z-index: 0;
  }
  .contact-area__bg {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      @include desktop {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: -1;
          overflow: hidden;
      }
      .bg {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-image: url(../images/pc/common/contact_area.png);
          background-size: cover;
          background-repeat: no-repeat;
          background-position: 50% 50%;
          @include desktop {
              position: absolute;
              top: 0;
              right: 0;
              bottom: -1px;
              left: 0;
              background-image: url(../images/pc/common/contact_area.png);
              background-size: cover;
              background-repeat: no-repeat;
              background-position: 50% 50%;
          }
      }
  }

  .contact_btn {
      @include desktop {
          margin-left: 100%;
      }
      @include l-desktop {
          margin-left: 165%;
      }
  }
}