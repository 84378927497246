.company_header {
  &__title {
    @include tablet {
      position: absolute;
      top: 144px;
      left: 50%;
      transform: translateX(-50%);
      color: $white;
      font-size: 10px;
      font-weight: bold;
      &.page_title {
        height: initial;
      }
    }
    @include desktop {
      top: 50%;
      font-size: 90px;
    }
  }
  @include tablet {
    &__sub {
      display: flex;
      margin-left: 144px;
      margin-bottom: 40px;
      padding-top: 120px;
    }

    .company_circle_img {
      position: relative;

      &::before {
        content: "";
        width: 54px;
        height: 54px;
        background-color: #242424;
        display: block;
        position: absolute;
        top: -427px;
        left: 181px;
        opacity: 0.5;
        mix-blend-mode: overlay;
      }
    }

    .company_circles {
      position: absolute;
      top: -400px;
      left: 145px;
    }
  }
  &__background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url(../images/sp/company/co_mobile.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100vh;
    padding-top: 60px;
    @include tablet {
      background: url(../images/pc/company/cp_wavy.png);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-color: #0c357a;
      height: 70vh;
      width: 100%;
      padding-top: 0;
      position: relative;
      overflow: hidden;
    }
    @include desktop {
      height: 1024px;
    }
    &--image {
      @include tablet {
        position: absolute;
        background: url(../images/pc/company/co_main.png);
        background-size: cover;
        width: 100%;
        height: 70vh;
        opacity: 0.8;
        z-index: 0;
      }
      @include desktop {
        height: 1024px;
      }
    }
  }
}
.company_sec {
  display: flex;
  font-size: 12px;
  line-height: 1.5;
  color: $white;
  margin-bottom: 20px;
  &--subject {
    width: 30%;
  }
  &--description {
    width: 70%;
    font-weight: 100;
  }
  &--black {
    color: #222;
  }
}
.company_sp {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-top: 60px;
  height: 100vh;
  @include tablet {
    display: none;
  }
  &__title {
    display: flex;
    justify-content: flex-start;
    padding: 0 20px 20px;
    &--div {
      font-size: 60px;
      color: $white;
      font-weight: bold;
    }
  }

  &__content1 {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0 20px;
  }
}

.company-kanren__logo1 {
  display: flex;

  &--li {
    padding-top: 30px;
  }
}

.company-kanren__logo2 {
  display: flex;
  justify-content: center;
  align-items: center;

  &--li {
    display: flex;
    justify-content: center;

    .company-kanren__img {
      width: 100px;
    }
  }

  &--li2 {
    //  display: flex;
    //  justify-content: center;

    .company-kanren__img {
      width: 100px;
    }
  }
}

.company_sp__content {
  display: flex !important;
  margin-bottom: -60px;

  &--body {
    margin-top: 50px !important;
  }
}

.company_sp__blkBox {
  width: 100%;
  height: 379px;
  background-color: #242424;
  color: $white;

  &--title {
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }

  &--sub1 {
    margin-left: 37px;
    margin-top: 40px;
    padding-bottom: 10px;
  }

  &--sub2 {
    font-size: 12px;
    line-height: 1.4;
    margin-left: 37px;
  }
}

.company_sp__map {
  margin-top: 355px;
  //padding-top: 255px;
  //margin-top: -224px;
}

.company-affiliate__sec {
  margin-top: 100px;
  margin-bottom: 100px;

  .company_sp__bluekBox {
    width: 100%;
    height: 213px;
    background-color: #1167f2;
  }

  .company-affiliate__secSub {
    &--title {
      font-size: 25px;
      color: $white;
      position: absolute;
      top: -185px;
      left: 15%;
    }

    &--content {
      &_subs {
        &__1 {
          color: $white;
          position: absolute;
          top: -123px;
          left: 5%;
        }

        &__2 {
          color: $white;
          font-size: 12px;
          position: absolute;
          top: -94px;
          left: 10%;

          &::before {
            content: "";
            width: 14px;
            height: 3px;
            background-color: $red;
            position: absolute;
            top: 5px;
            left: -20px;
          }
        }
      }
    }
  }

  .company-img_map1 {
    width: 90%;
    position: absolute;
    top: -21px;
    left: 21px;
  }

  .company-affiliate__secSub--content2 {
    padding-top: 200px;

    &_subs2 {
      margin-top: -50px;
      &__11 {
        font-size: 15px;
        margin-left: 10px;
        margin-bottom: 10px;
      }

      &__22 {
        font-size: 12px;
        margin-left: 33px;

        &::before {
          content: "";
          width: 14px;
          height: 3px;
          background-color: $red;
          position: absolute;
          top: 180px;
          left: 20px;
        }
      }
    }
  }

  .company-img_map2 {
    margin-top: 40px;
    width: 90%;
    margin-left: 18px;
  }
}

.index .s-news .news__content .news__body .news__list .item .link,
.index .s-news .news__content .news__body .news__list .item .text {
  display: block;
  letter-spacing: 0.03em;
  font-size: 12px;
  line-height: 1.6666;
  margin-bottom: -4px;
  margin-top: 0px;
  font-weight: 500;
  color: $black;
}

.company_sp__wework {
  padding-top: 80px;
  margin-left: 15px;

  &--title {
    font-size: 14px;
  }

  &--address_num {
    font-size: 12px;
    padding-top: 10px;
  }

  &--address {
    font-size: 12px;
    padding-top: 5px;
  }
}

.company {
  @include tablet {
    display: flex;
    flex-direction: column;
    &__subtitle {
      display: flex;
      font-size: 40px;
      font-weight: bold;
      margin-left: 88px;
    }
  }
  &__container {
    @include tablet {
      position: relative;
      display: block;
      margin-right: 0;
      margin-left: auto;
      width: calc(100% - 80px);
      background-color: $white;
      &:before {
        position: absolute;
        top: 0;
        left: 144px;
        bottom: 0;
        width: 1px;
        background-color: $gray-100;
        content: "";
      }
    }
    @include desktop {
      width: calc(100% - 230px);
      // padding-right: 200px;
    }
  }
}

.company-overview {
  display: none;
  @include tablet {
    position: relative;
    top: -128px;
    display: block;
    margin-bottom: -128px;
    &:after {
      position: absolute;
      left: -230px;
      bottom: 0;
      height: 1px;
      width: calc(100% + 230px);
      background-color: $gray-100;
      content: "";
    }
  }
  @include desktop {
    top: -350px;
    margin-bottom: -350px;
  }
  &__sp-title {
    @include tablet {
      display: none;
    }
  }
  &__heading {
    font-size: 40px;
    font-weight: bold;
  }
  &__header {
    display: none;
    @include tablet {
      position: relative;
      display: block;
      margin-left: 144px;
      padding: 128px 0 40px 88px;
      &:before {
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 1px;
        background-color: $gray-100;
        content: "";
      }
      &:after {
        position: absolute;
        top: 100%;
        left: 0;
        display: block;
        width: 100%;
        padding-left: 4px;
        color: $gray-200;
        font-size: 9px;
        line-height: 2;
        content: "01 WHO WE ARE";
      }
    }
  }
  &__list {
    @include tablet {
      display: table;
      margin: 80px 20px 40px;
      width: 100%;
    }
  }
  &__item {
    @include tablet {
      display: table-row;
      width: 100%;
      .news__body {
        display: table-cell;
      }
      .news__list .item {
        line-height: 1.5;
        margin-bottom: 8px;
      }
      .news__list .empty {
        height: 24px;
        margin-bottom: 8px;
      }
    }
  }
  &__subject {
    font-size: 16px;
    font-weight: 500;
    @include tablet {
      display: table-cell;
      width: 212px;
      height: 120px;
    }
  }
}

.company_header {
  &__title {
    display: none;
    @include tablet {
      display: block;
    }
  }
}

.company-content {
  &__description {
    width: 224px;
  }
  @include tablet {
    &__list {
      display: table;
      margin-left: 232px;
    }
    &__item {
      display: flex;
      width: 100%;
    }
    &__subject {
      h2 {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
}

.company-partners {
  padding: 20px;
  @include tablet {
    display: flex;
    padding: 0 20px;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  &__list {
    @include tablet {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      padding-top: 148px;
      > li {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    @include desktop {
      flex-direction: row;
      padding-right: 230px;
    }
  }
  &__secondlist {
    @include tablet {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      > li {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    @include desktop {
      flex-direction: row;
      padding-right: 230px;
    }
  }
  &__item {
    display: flex;
    justify-content: center;
  }
  &__header {
    position: relative;
    @include tablet {
      &:before {
        position: relative;
        bottom: 0;
        display: block;
        width: 124px;
        padding-right: 4px;
        color: $gray-200;
        font-size: 9px;
        line-height: 2;
        text-align: right;
        content: "02 WHAT WE DO";
      }
    }
  }
  &__heading {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    @include tablet {
      width: 212px;
      padding-top: 128px;
    }
  }
  &__secondheading {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    @include tablet {
      width: 212px;
    }
  }
  &__imgbox {
    display: flex;
    margin: 8px 0;
    max-height: 64px;
    max-width: 160px;
    text-align: center;
    @include tablet {
      margin: 0;
    }
  }
  &__img {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
  }
}

.company-members {
  padding: 20px;
  &__table {
    border-collapse: collapse;
    border-spacing: 8px;
  }
  &__subject,
  &__description {
    text-align: left;
    vertical-align: top;
  }
  &__subject {
    margin-right: 16px;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 500;
    @include tablet {
      width: 212px;
      height: 120px;
    }
  }
  &__description {
    font-size: 16px;
    line-height: 2;
  }
}

.company-location {
  &:after {
    position: absolute;
    top: 0;
    left: -230px;
    display: block;
    width: calc(100% + 230px);
    height: 1px;
    background-color: $gray-100;
    content: "";
  }
  &__main {
    position: relative;
    padding: 20px;
    font-size: 14px;
    line-height: 1.5;
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      background-color: #242424;
      height: 240px;
      width: 100%;
      content: "";
      z-index: -1;
      @include tablet {
        display: none;
      }
    }
    @include tablet {
      display: flex;
      background-color: initial;
      color: $black;
      padding: 120px 20px 20px;
      &:before {
        position: absolute;
        top: 0;
        left: 144px;
        display: block;
        padding-left: 4px;
        color: $gray-200;
        font-size: 9px;
        line-height: 2;
        content: "03 LOCATIONS";
      }
      .company-location__header {
        width: 212px;
      }
    }
  }
  &__heading {
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 1.5;
    text-align: center;
    @include tablet {
      display: none;
    }
    br {
      display: none;
      @include tablet {
        display: initial;
      }
    }
    &--white {
      color: $white;
    }
  }
  &__details {
    position: relative;
    margin-bottom: 40px;
    @include tablet {
      display: flex;
      justify-content: space-between;
    }
    @include desktop {
      justify-content: flex-start;
      padding-right: 200px;
    }
    &--reverse {
      @include desktop {
        flex-direction: row-reverse;
        justify-content: space-between;
        > div {
          margin: 0;
        }
        .company-location__description {
          width: 300px;
        }
        .company-location-maps {
          &__link {
            display: inline-flex;
            justify-content: flex-start;
          }
          &__imgbox {
            margin-right: 20px;
          }
        }
      }
    }
    p {
      color: $black;
    }
    &:first-child {
      p {
        color: $white;
        @include tablet {
          color: $black;
        }
      }
    }
  }
  &__description {
    @include tablet {
      padding-right: 20px;
    }
    @include desktop {
      min-width: 216px;
    }
  }
  &__subject {
    display: none;
    width: 212px;
    font-size: 16px;
    font-weight: 500;
    @include tablet {
      display: block;
    }
  }
  &__office {
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 1.5;
  }
  &__contents {
    margin-bottom: 20px;
    > div {
      margin-bottom: 128px;
    }
    @include tablet {
      width: 100%;
    }
  }
}

.company-location-maps {
  position: relative;
  margin: 20px;
  @include tablet {
    top: initial;
    margin: 0;
    margin-left: auto;
  }
  &__imgbox {
    @include tablet {
      width: 240px;
      margin-left: auto;
      a {
        display: block;
      }
    }
    @include desktop {
      width: 300px;
    }
  }
  &__link {
    // position: relative;
    position: absolute;
    right: 0;
    bottom: -48px;
    display: flex;
    justify-content: flex-end;
    margin: 20px 0;
    padding-right: 48px;
    color: $red;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0.03em;
    @include tablet {
      position: relative;
      right: initial;
      bottom: initial;
      display: inline-flex;
      margin-bottom: 0;
      padding-right: 24px;
    }
    // @include desktop {
    //     position: relative;
    // }
    .arrow {
      position: absolute;
      top: 50%;
      right: 0;
      width: 40px;
      height: 1px;
      background-color: $red;
      @include tablet {
        right: 0;
        width: 12px;
        height: 12px;
        margin-top: -3.5px;
        background-color: initial;
        &:before {
          top: 0;
          transform: rotate(45deg);
        }
        &:after {
          top: 4px;
          transform: rotate(-45deg);
        }
        &:before,
        &:after {
          position: absolute;
          right: 0;
          display: block;
          width: 7px;
          height: 2px;
          background-color: $red;
          content: "";
        }
      }
    }
  }
}

.company-affiliates {
  position: relative;
  &:before {
    position: absolute;
    display: block;
    width: 100%;
    // height: 196px;
    height: 256px;
    background-color: #1167f2;
    content: "";
    z-index: -1; // shame
    @include tablet {
      display: none;
    }
  }
  &__container {
    padding: 20px;
    @include tablet {
      display: inline-flex;
      width: 100%;
      // padding-left: 232px;
    }
    @include desktop {
      padding-right: 220px;
    }
    .company-location__heading {
      @include tablet {
        display: block;
        width: 212px;
        font-size: 16px;
        font-weight: 500;
        text-align: left;
      }
    }
    > div {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__companies {
    display: flex;
    flex-direction: column;
    flex: 1 auto;
  }
  &__contents {
    margin-bottom: 20px;
    font-size: 16px;
    @include tablet {
      flex: 1;
      margin-right: 20px;
      min-width: 300px;
    }
  }
  &__address {
    position: relative;
    display: block;
    margin-left: 20px;
    font-size: 12px;
    line-height: 1.5;
    @include tablet {
      margin-left: 0;
    }
    &:before {
      content: "";
      width: 14px;
      height: 3px;
      background-color: $red;
      position: absolute;
      top: 8px;
      left: -20px;
      @include tablet {
        display: none;
      }
    }
  }
  &__map {
    @include tablet {
      width: 320px;
      img {
        object-fit: cover;
      }
    }
  }
  .company-location {
    &__heading {
      color: $white;
      @include tablet {
        color: $black;
      }
    }
    &__company {
      margin-bottom: 40px;
      color: $black;
      &:first-child {
        color: $white;
      }
      @include tablet {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        &:first-child {
          color: $black;
        }
      }
      @include desktop {
        flex-direction: row;
      }
    }
  }
}

@include tablet {
  .company__content-small {
    padding: 40px 110px 5px 94px;
  }

  .company__content-large {
    padding: 151px 110px 5px 94px;
  }

  .company-borderBox1 {
    background: transparent;
    width: calc(50% - -180px);
    height: 220px;
    border-left: 0.5px solid $gray-200;
    position: absolute;
    top: 691px;
    right: 0;
    z-index: 11;
  }

  .company-borderBox2 {
    background: transparent;
    width: calc(50% - -180px);
    height: 780px;
    border-left: 0.5px solid $gray-200;
    border-top: 0.5px solid $gray-200;
    border-bottom: 0.5px solid $gray-200;
    position: absolute;
    top: 891px;
    right: 0;
    z-index: 11;

    &::before {
      content: "01 WHO WE ARE";
      position: absolute;
      top: 7px;
      left: 5px;
      color: $gray-200;
      font-size: 9px;
    }
  }
  .company-borderBox3 {
    background: transparent;
    width: calc(50% - 179px);
    height: 690px;
    border-right: 0.5px solid $gray-200;
    border-top: 0.5px solid $gray-200;
    border-bottom: 0.5px solid $gray-200;
    position: absolute;
    top: 1670px;
    left: 0;
    z-index: 11;

    &::before {
      content: "02 WHAT WE DO";
      position: absolute;
      top: 7px;
      right: 5px;
      color: $gray-200;
      font-size: 9px;
    }
  }
  .company-borderBox4 {
    background: transparent;
    width: calc(50% - -180px);
    height: 1849px;
    border-left: 0.5px solid $gray-200;
    border-top: 0.5px solid $gray-200;
    position: absolute;
    right: 0;
    top: 2359px;
    right: 0;
    z-index: 11;

    &::before {
      content: "03 LOCATIONS";
      position: absolute;
      top: 7px;
      left: 5px;
      color: $gray-200;
      font-size: 9px;
    }
  }

  .company-kanren__logo1 {
    width: 500px;
    position: absolute;
    left: calc(50% - 65px);
    top: -43px;
  }

  .company-kanren__logo2 {
    width: 310px;
    position: absolute;
    left: calc(50% - 95px);

    &--li {
      position: absolute;
      left: 38%;
      margin-left: 90px;
    }

    &--li2 {
      //  position: absolute;
      //  left: 38%;
      margin-left: 20px;
    }
  }

  .company-kanren__img {
    background: none;
  }

  .company-affiliate__sec {
    width: 100%;
    height: 330px;
    margin-top: 100px;
  }

  .company-affiliate__secSub {
    display: flex;
    position: relative;

    &--title {
      width: 99px;
      font-size: 15px;
      position: absolute;
      left: 14%;
    }

    &--content {
      display: flex;
      position: absolute;
      left: calc(50% - 150px);

      &_subs {
        width: 300px;
        height: 101px;
        padding-right: 48px;

        &__1 {
          font-size: 15px;
          width: 213px;
        }

        &__2 {
          font-size: 10px;
          margin-top: 10px;
        }
      }

      .company-img_map1 {
        width: 312px;
        height: 106px;
      }
    }

    &--content2 {
      display: flex;
      position: absolute;
      top: 150px;
      left: calc(50% - 150px);

      &_subs {
        width: 300px;
        height: 101px;
        padding-right: 48px;

        &__1 {
          font-size: 15px;
          width: 192px;
        }

        &__2 {
          font-size: 10px;
          margin-top: 10px;
        }
      }

      .company-img_map1 {
        width: 312px;
        height: 106px;
      }

      .company-img_map2 {
        width: 312px;
        height: 106px;
      }
    }
  }

  .company_wework__ul {
    padding-top: 50px;
    clear: right;
  }
}
