.icon-dummy:before,
button,
select {
  text-transform: none;
}

[data$=".svg"],
[src$=".svg"],
table {
  width: 100%;
}

img,
select {
  vertical-align: middle;
}

pre,
textarea {
  overflow: auto;
}

@-webkit-keyframes neonBox {
  0% {
    border: 3px solid red;
    -webkit-box-shadow: 0 0 15px red, 0 0 15px red inset;
    box-shadow: 0 0 15px red, 0 0 15px red inset;
  }

  100%,
  98% {
    border: 3px solid red;
    -webkit-box-shadow: 0 0 40px red, 0 0 40px red inset;
    box-shadow: 0 0 40px red, 0 0 40px red inset;
  }
}

@keyframes neonBox {
  0% {
    border: 3px solid red;
    -webkit-box-shadow: 0 0 15px red, 0 0 15px red inset;
    box-shadow: 0 0 15px red, 0 0 15px red inset;
  }

  100%,
  98% {
    border: 3px solid red;
    -webkit-box-shadow: 0 0 40px red, 0 0 40px red inset;
    box-shadow: 0 0 40px red, 0 0 40px red inset;
  }
}

@-webkit-keyframes neonText {
  0% {
    color: red;
    text-shadow: 0 0 15px red;
  }

  100%,
  98% {
    color: red;
    text-shadow: 0 0 40px rgba(220, 0, 0, 0.5), 0 0 120px #dc0000,
      0 0 120px #dc0000, 0 0 120px #dc0000;
  }
}

@keyframes neonText {
  0% {
    color: red;
    text-shadow: 0 0 15px red;
  }

  100%,
  98% {
    color: red;
    text-shadow: 0 0 40px rgba(220, 0, 0, 0.5), 0 0 120px #dc0000,
      0 0 120px #dc0000, 0 0 120px #dc0000;
  }
}

@-webkit-keyframes scrollLine {
  0% {
    -webkit-transform-origin: top;
    transform-origin: top;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }

  50% {
    -webkit-transform-origin: top;
    transform-origin: top;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }

  51% {
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }

  100% {
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }
}

@keyframes scrollLine {
  0% {
    -webkit-transform-origin: top;
    transform-origin: top;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }

  50% {
    -webkit-transform-origin: top;
    transform-origin: top;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }

  51% {
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }

  100% {
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }
}

@-webkit-keyframes arrrowRight {
  0% {
    right: 0;
  }

  100% {
    right: -3px;
  }
}

@-webkit-keyframes arrrowLeft {
  0% {
    left: 3px;
  }

  100% {
    left: 6px;
  }
}

@font-face {
  font-family: NotoSansCJKjp;
  font-weight: 700;
  src: url(../fonts/NotoSans_bold.woff) format("woff"),
    url(../fonts/NotoSans_bold.otf) format("opentype");
}

@font-face {
  font-family: NotoSansCJKjp;
  font-weight: 500;
  src: url(../fonts/NotoSans_medium.woff) format("woff"),
    url(../fonts/NotoSans_medium.otf) format("opentype");
}

@font-face {
  font-family: NotoSansCJKjp;
  font-weight: 400;
  src: url(../fonts/NotoSans_regular.woff) format("woff"),
    url(../fonts/NotoSans_regular.otf) format("opentype");
}

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden],
template {
  display: none;
}

a {
  background-color: transparent;
  text-decoration: none !important;
  color: #333;
}

a:active,
a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
optgroup,
strong {
  font-weight: 700;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

mark {
  background: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

.l-bg,
.l-page-wrapper {
  top: 0;
  bottom: 0;
  left: 0;
}

img {
  border: 0;
  max-width: 100%;
  height: auto;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 1em 40px;
}

hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

input[type="checkbox"],
input[type="radio"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

li,
ul {
  list-style: none;
}

html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1;
}

*,
:after,
:before {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

@include desktop {
  html {
    font-size: 16px;
  }
}

body {
  margin: 0;
  color: #303030;
  font-family: NotoSansCJKjp, sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
  -webkit-font-kerning: normal;
  font-kerning: normal;
  background-color: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit;
  font-weight: 400;
  line-height: 1;
  color: inherit;
}

li>ol,
li>ul {
  margin-bottom: 0;
}

dd {
  margin-left: 0;
}

th {
  text-align: left;
}

a:active,
a:focus,
a:hover {
  text-decoration: none;
  color: #595959;
}

address,
cite,
dfn,
em,
i {
  font-style: normal;
}

code,
pre {
  font-family: Consolas, Menlo, Courier, monospace;
}

code {
  padding: 0.2em;
  background-color: rgba(0, 0, 0, 0.06);
}

pre code {
  padding: 0;
  background-color: inherit;
}

address,
blockquote,
dl,
fieldset,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
ol,
p,
pre,
table,
ul {
  margin-top: 0;
  margin-bottom: 0;
}

dd,
dl,
dt,
li,
ul {
  padding: 0;
}

:focus {
  outline: 0;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #fff inset;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0;
  border: 0;
  margin: 0;
  padding: 0;
  background: none;
  font-size: inherit;
  color: inherit;
}

textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  resize: none;
  padding: 0;
  border: 0;
  outline: 0;
  background: 0 0;
  border-radius: 0;
}

input[type="text"],
input[type="tel"],
input[type="email"] {
  padding: 0;
  border: none;
  border-radius: 0;
  outline: 0;
  background: 0 0;
}

button,
input[type="button"],
input[type="submit"] {
  padding: 0;
  border: none;
  outline: 0;
  background: 0 0;
  border-radius: 0;
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
  cursor: pointer;
}

.l-page-wrapper {
  position: absolute;
  right: 0;
  z-index: 0;
}

.l-page {
  position: relative;
  overflow: hidden;
}

.is-fixed {
  position: fixed;
}

.l-bg {
  position: absolute;
  right: 0;
  z-index: -2;
  /* background-color: #fcfcfc */
}

@include desktop {

  .l-header,
  .l-header .l-gnav-menu-icon>*,
  .l-header__gnav,
  .l-header__gnav .gnav__bg {
    -webkit-backface-visibility: hidden;
  }

  .l-header,
  .l-header .header__bg--main {
    top: 0;
    right: 0;
    pointer-events: none;
    bottom: 0;
    left: 0;
    opacity: 0;
  }

  .l-header {
    position: fixed;
    z-index: 1000;
    width: 100%;
    will-change: background;
    backface-visibility: hidden;
  }

  .l-header .header__bg--main {
    position: absolute;
    background-color: #fff;
  }

  .l-header .l-header__logo {
    position: absolute;
    top: 18px;
    right: 40px;
    /* left: 15px; */
    pointer-events: auto;

    .moff_hidden {
      display: block;
    }

    .moff_block {
      display: none;
    }
  }

  .l-header .l-header__logo .header__logo,
  .l-header .l-header__logo .header__logo .link {
    position: relative;
    display: block;
  }

  .l-header .l-header__logo .header__logo .link .img {
    max-width: 150px;

    &__newyear {
      max-width: 85px !important;
      transform: translateY(-10px);
    }
  }

  .l-header .l-header__sns {
    position: absolute;
    top: 0;
    right: 24px;
    bottom: 0;
    margin: auto 0;
    height: 190px;
    pointer-events: auto;
  }

  .l-header .l-header__sns .header__sns {
    position: relative;
    display: block;
  }

  .l-header .l-header__sns .header__sns .item {
    margin-top: 14px;
  }

  .l-header .l-header__sns .header__sns .item:first-child {
    margin-top: 0;
  }

  // .l-header .l-header__sns .header__sns .item:last-child {
  //   padding-left: 2px;
  // }

  .l-header .l-header__sns .header__sns .item.preparation {
    opacity: 0.4;
    pointer-events: none;
  }

  .l-header .l-header__sns .header__sns .item .link {
    text-align: center;
    position: relative;
    display: block;
    -webkit-transition-property: fill;
    transition-property: fill;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }

  .l-header .l-header__sns .header__sns .item .link:hover {
    fill: $red;
  }

  .l-header .l-header__language {
    position: absolute;
    bottom: 20px;
    right: 40px;
    pointer-events: auto;
  }

  .l-header .l-header__language .header__language {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .l-header .l-header__language .header__language .jp {
    position: relative;
    cursor: pointer;
    font-size: 14px;
    font-family: NotoSansCJKjp, sans-serif;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    font-weight: 700;
    padding-right: 10px;
    margin-right: 3px;
    -webkit-transition-property: color letter-spacing;
    transition-property: color letter-spacing;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    letter-spacing: 0.05em;
    color: #333;
  }

  .l-header .l-header__language .header__language .en,
  .l-header .l-header__scroll .scroll .text {
    font-family: NotoSansCJKjp, sans-serif;
    -webkit-font-feature-settings: "palt" 1;
    letter-spacing: 0.05em;
  }

  .l-header .l-header__language .header__language .jp.current,
  .l-header .l-header__language .header__language .jp:hover {
    color: $red;
  }

  .l-header .l-header__language .header__language .jp.current:hover {
    color: #333;
  }

  .l-header .l-header__language .header__language .jp::after {
    position: absolute;
    top: 1px;
    right: 3px;
    width: 2px;
    height: 12px;
    content: "";
    background-color: #333;
    -webkit-transform: rotate(20deg);
    transform: rotate(20deg);
  }

  .l-header .l-header__language .header__language .jp .link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
  }

  .l-header .l-header__language .header__language .en {
    cursor: pointer;
    position: relative;
    font-size: 14px;
    font-feature-settings: "palt" 1;
    font-weight: 700;
    -webkit-transition-property: color letter-spacing;
    transition-property: color letter-spacing;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    color: #333;
  }

  .l-header .l-header__scroll,
  .l-header__gnav .policy {
    overflow: hidden;
    -webkit-transition-property: opacity;
  }

  .l-header .l-header__language .header__language .en.current,
  .l-header .l-header__language .header__language .en:hover {
    color: $red;
  }

  .l-header .l-header__language .header__language .en.current:hover {
    color: #333;
  }

  .l-header .l-header__language .header__language .en .link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
  }

  .l-header .l-header__scroll {
    position: absolute;
    bottom: 46px;
    left: -70px;
    width: 160px;
    height: 70px;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    transition-property: opacity;
    -webkit-transition-duration: 0.6s;
    transition-duration: 0.6s;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    pointer-events: auto;
  }

  .l-header .l-header__scroll.is-change .scroll--more,
  .l-header .l-header__scroll.is-change .scroll--top {
    -webkit-transition-property: -webkit-transform;
    -webkit-transition-duration: 0.6s;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }

  .l-header .l-header__scroll.is-change .scroll--more {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    transition-duration: 0.6s;
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }

  .l-header .l-header__scroll.is-change .scroll--top {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    transition-duration: 0.6s;
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }

  .l-header .l-header__scroll .scroll,
  .l-header .l-header__scroll .scroll--more {
    -webkit-transition-property: -webkit-transform;
    -webkit-transition-duration: 0.6s;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }

  .l-header .l-header__scroll .scroll {
    position: absolute;
    top: 0;
    right: 16px;
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    transition-duration: 0.6s;
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }

  .l-header .l-header__scroll .scroll .link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .l-header .l-header__scroll .scroll .text {
    display: block;
    font-weight: 500;
    font-feature-settings: "palt" 1;
    font-size: 12px;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }

  .l-header .l-header__scroll .scroll:hover .text {
    color: $red;
  }

  .l-header .l-header__scroll .scroll:hover .arrow::after,
  .l-header .l-header__scroll .scroll:hover .arrow::before {
    background-color: $red;
  }

  .l-header .l-header__scroll .scroll--more {
    width: 88%;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    transition-duration: 0.6s;
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }

  .l-header .l-header__scroll .scroll--more .arrow {
    margin-top: -32px;
  }

  .l-header .l-header__scroll .scroll--top {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 0.6s;
    transition-duration: 0.6s;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }

  .l-header .l-header__scroll .scroll--top>.text {
    padding-left: 48px;
  }

  .l-header .l-header__scroll .scroll--top>.arrow {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
    right: initial;
    top: 0;
    left: 0;
    margin-left: 12px;
    margin-top: -2px;
    -webkit-animation: arrrowLeft 0.6s infinite alternate;
    animation: arrrowLeft 0.6s infinite alternate;
  }

  .l-header .l-header__scroll .scroll--top>.arrow::before {
    position: absolute;
    top: 0;
    left: 3px;
  }

  .l-header .l-header__scroll .scroll--top>.arrow::after {
    left: 3px;
  }

  .l-header .l-header__scroll .arrow {
    position: absolute;
    top: 50%;
    right: 0;
    width: 20px;
    height: 12px;
    margin-top: -17px;
    display: block;
    -webkit-animation: arrrowRight 0.6s infinite alternate;
    animation: arrrowRight 0.6s infinite alternate;
  }

  .l-header .l-header__scroll .arrow::after,
  .l-header .l-header__scroll .arrow::before {
    right: 4px;
    width: 8px;
    height: 2px;
    position: absolute;
    display: block;
    content: "";
  }

  .l-header .l-header__scroll .arrow::before {
    top: 0;
    background-color: #333;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition-property: background-color;
    transition-property: background-color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }

  .l-header .l-header__scroll .arrow::after {
    top: 5px;
    background-color: #333;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transition-property: background-color;
    transition-property: background-color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }

  // menu button stuff moved to header.scss

  // .l-header__gnav {
  //   position: fixed;
  //   top: 0;
  //   right: 0;
  //   bottom: 0;
  //   left: 0;
  //   pointer-events: none;
  //   width: calc(100% - 160px);
  //   height: calc(100% - 100px);
  //   display: -webkit-box;
  //   display: -ms-flexbox;
  //   display: flex;
  //   -webkit-box-pack: center;
  //   -ms-flex-pack: center;
  //   justify-content: center;
  //   -webkit-box-align: center;
  //   -ms-flex-align: center;
  //   align-items: center;
  //   margin: auto;
  //   z-index: 1001;
  //   backface-visibility: hidden;
  // }

  .l-header__gnav.is-open {
    pointer-events: auto;
  }

  .l-header__gnav .gnav__bg {
    pointer-events: none;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    top: 22px;
    background: url(../images/pc/header/nav_bg_lines.png);
    background-size: 212%;
    background-position-x: 42%;
    background-position-y: center;
    z-index: -1;
    opacity: 0;
    backface-visibility: hidden;
    will-change: opacity, transform;
  }

  .l-header__gnav .policy {
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    bottom: -30px;
    text-align: center;
    z-index: 3;
    transition-property: opacity;
    transition-duration: 0.2s;
    width: 200px;
    margin: 0 auto;
  }

  .l-header__gnav .policy:hover {
    opacity: 0.7;
  }

  .l-header__gnav .policy .text {
    position: relative;
    display: block;
    transform: translateY(20px);
    color: #242424;
    font-size: 11px;
    letter-spacing: 0.1em;
    will-change: transform;
    backface-visibility: hidden;
  }

  .l-header__gnav .header__gnav {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    /* width: 275px; */
    // width: 375px;
    height: 220px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 2;
  }

  .l-header__gnav .header_gnav__pc {
    transition: 0.2s all ease-out;
    top: -110px;
  }

  .l-header__gnav .header__gnav .item {
    position: relative;
    text-align: center;
    opacity: 0;
    will-change: opacity, transform;
    backface-visibility: hidden;
  }

  .l-header__gnav .header__gnav .item .link {
    position: relative;
    display: block;
    z-index: 999;
  }

  .l-header__gnav .header__gnav .item .link:hover {
    color: red;
  }

  // .l-header__gnav .header__gnav .item .link::after {
  //     content: "";
  //     background-color: #fcfcfc;
  //     display: block;
  //     position: absolute;
  //     bottom: 10px;
  //     left: 20%;
  //     width: 30%;
  //     height: 1px;
  //     -webkit-transform: scaleX(0);
  //     transform: scaleX(0);
  //     -webkit-transform-origin: left;
  //     transform-origin: left;
  //     -webkit-transition-duration: .3s;
  //     transition-duration: .3s;
  //     -webkit-transition-property: -webkit-transform;
  //     transition-property: -webkit-transform;
  //     transition-property: transform;
  //     transition-property: transform, -webkit-transform;
  //     z-index: -1;
  //     -webkit-transition-timing-function: cubic-bezier(.19, 1, .22, 1);
  //     transition-timing-function: cubic-bezier(.19, 1, .22, 1);
  //     -webkit-backface-visibility: hidden;
  //     backface-visibility: hidden;
  //     will-change: transform
  // }

  // .l-header__gnav .header__gnav .item .link:hover::after {
  //     -webkit-transform: scaleX(1);
  //     transform: scaleX(1);
  //     -webkit-transition-property: -webkit-transform;
  //     transition-property: -webkit-transform;
  //     transition-property: transform;
  //     transition-property: transform, -webkit-transform;
  //     -webkit-transition-timing-function: cubic-bezier(.19, 1, .22, 1);
  //     transition-timing-function: cubic-bezier(.19, 1, .22, 1)
  // }

  .l-header__gnav .header__gnav .item .sub_a::after {
    content: "";
    background-color: #242424;
    display: block;
    position: absolute;
    bottom: 10px;
    left: 30%;
    width: 30%;
    /* width: 50%; */
    height: 1px;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: left;
    transform-origin: left;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    z-index: -1;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    will-change: transform;
  }

  .l-header__gnav .header__gnav .item .link .text {
    position: relative;
    font-size: 18px;
    color: #242424;
    letter-spacing: 2px;
    /* font-weight: 700 */
  }

  .l-header__gnav .header__gnav .item .link .text:hover {
    color: red;
    transition: 0.3s;
  }

  .l-header__gnav .header__gnav .item .link .text .bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #fff;
    content: "";
    -webkit-transform-origin: right;
    transform-origin: right;
  }
}

@media screen and (max-width: 1024px) {
  .l-header {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 60px;
    opacity: 0;
  }

  .l-header {
    .header__logo {
      position: absolute;
      top: 21px;
      left: 50%;
      transform: translateX(-50%);

      .moff_hidden {
        display: block;
      }

      .moff_block {
        display: none;
      }
    }

    .header__servicelogo {
      position: absolute;
      display: flex;
      top: 23px;
      left: 16px;

      .spin {
        position: relative;
        height: 20px;
      }

      .tells {
        height: 20px;
        width: auto;
      }

      .vibes {
        height: 20px;
      }

      .seperator {
        width: 1px;
        height: 15px;
        background: #E5E5E5;
        margin: 0 8px;
        transform: translateY(2.5px);
      }
    }
  }

  .l-header .l-header__logo .header__logo .link .img {
    max-width: 90px;

    &__newyear {
      max-width: 60px !important;
      transform: translateY(-8px);
    }
  }

  .l-header .l-gnav-menu-icon {
    cursor: pointer;
    position: absolute;
    top: 26px;
    right: 20px;
    width: 24px;
    height: 24px;
    overflow: hidden;
  }

  .l-header .l-gnav-menu-icon>* {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    will-change: transform;
  }

  .l-header .l-gnav-menu-icon.is-open .close .line--right {
    -webkit-transform: rotate(-45deg) scaleX(1);
    transform: rotate(-45deg) scaleX(1);
    -webkit-transition-delay: 0.6s;
    transition-delay: 0.6s;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
  }

  .l-header .l-gnav-menu-icon.is-open .close .line--left {
    -webkit-transform: rotate(45deg) scaleX(1);
    transform: rotate(45deg) scaleX(1);
    -webkit-transition-delay: 0.7s;
    transition-delay: 0.7s;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
  }

  .l-header .l-gnav-menu-icon.is-open .open .line--bottom,
  .l-header .l-gnav-menu-icon.is-open .open .line--middle,
  .l-header .l-gnav-menu-icon.is-open .open .line--top {
    -webkit-transition: 0.6s -webkit-transform;
    transition: 0.6s -webkit-transform;
    transition: 0.6s transform;
    transition: 0.6s transform, 0.6s -webkit-transform;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: right;
    transform-origin: right;
  }

  .l-header .l-gnav-menu-icon .open {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .l-header .l-gnav-menu-icon .open .line {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #333;
  }

  .l-header .l-gnav-menu-icon .open .line .line__cover {
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    content: "";
    display: block;
    background-color: #fff;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 1s;
    transition-duration: 1s;
    -webkit-transform: translateX(-110%);
    transform: translateX(-110%);
  }

  .l-header .l-gnav-menu-icon .open .line--top {
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    top: 0;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transition-duration: 0.6s;
    transition-duration: 0.6s;
    -webkit-transition-delay: 0.6s;
    transition-delay: 0.6s;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transform-origin: left;
    transform-origin: left;
  }

  .l-header .l-gnav-menu-icon .open .line--middle {
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    top: 10px;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transition-duration: 0.6s;
    transition-duration: 0.6s;
    -webkit-transition-delay: 0.7s;
    transition-delay: 0.7s;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transform-origin: left;
    transform-origin: left;
  }

  .l-header .l-gnav-menu-icon .open .line--middle .line__cover {
    -webkit-transition-delay: 80ms;
    transition-delay: 80ms;
  }

  .l-header .l-gnav-menu-icon .open .line--bottom {
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    top: 20px;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transition-duration: 0.6s;
    transition-duration: 0.6s;
    -webkit-transition-delay: 0.8s;
    transition-delay: 0.8s;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transform-origin: left;
    transform-origin: left;
  }

  .l-header .l-gnav-menu-icon .open .line--bottom .line__cover {
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
  }

  .l-header .l-gnav-menu-icon .close {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .l-header .l-gnav-menu-icon .close .line {
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #333;
  }

  .l-header .l-gnav-menu-icon .close .line--right {
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    display: block;
    right: 2px;
    top: 2px;
    background-color: #333;
    -webkit-transform: rotate(-45deg) scaleX(0);
    transform: rotate(-45deg) scaleX(0);
    -webkit-transform-origin: right;
    transform-origin: right;
    -webkit-transition-duration: 0.6s;
    transition-duration: 0.6s;
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
  }

  .l-header .l-gnav-menu-icon .close .line--right .line__cover {
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    content: "";
    position: absolute;
    z-index: 1;
    left: 0;
    top: 50%;
    display: block;
    width: 100%;
    height: 2px;
    background-color: #fff;
    margin-top: -1px;
    margin-left: -1px;
    -webkit-transform: translateX(-150%);
    transform: translateX(-150%);
    -webkit-transition-duration: 1.4s;
    transition-duration: 1.4s;
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
  }

  .l-header .l-gnav-menu-icon .close .line--left {
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    position: absolute;
    top: 19px;
    right: 2px;
    display: block;
    background-color: #333;
    -webkit-transform: rotate(45deg) scaleX(0);
    transform: rotate(45deg) scaleX(0);
    -webkit-transform-origin: right;
    transform-origin: right;
    -webkit-transition-duration: 0.6s;
    transition-duration: 0.6s;
    -webkit-transition-delay: 0.3s;
    transition-delay: 0.3s;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
  }

  .l-header .l-gnav-menu-icon .close .line--left .line__cover {
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    content: "";
    position: absolute;
    z-index: 1;
    left: 0;
    top: 50%;
    display: block;
    width: 23px;
    height: 2px;
    background-color: #fff;
    margin-top: -1px;
    margin-left: -1px;
    -webkit-transform: translateX(-150%);
    transform: translateX(-150%);
    -webkit-transition-duration: 1.4s;
    transition-duration: 1.4s;
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
  }

  .l-header__gnav,
  .l-header__gnav .gnav__bg {
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    right: 0;
    left: 0;
    bottom: 0;
  }

  .l-header__gnav {
    position: fixed;
    top: 0;
    pointer-events: none;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 999;
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-duration: 1s;
    transition-duration: 1s;
    -webkit-transition-property: background-color;
    transition-property: background-color;
    will-change: background-color;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-font-smoothing: antialiased;
    overflow: auto;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .l-header__gnav .policy {
    position: absolute;
    display: block;
    right: 0;
    bottom: 23px;
    left: 0;
    text-align: center;
    z-index: 3;
    overflow: hidden;
  }

  .l-header__gnav .policy .text {
    position: relative;
    display: block;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    color: #dcdcdc;
    font-size: 12px;
    letter-spacing: 0.1em;
  }

  .l-header__gnav .gnav__bg {
    pointer-events: none;
    position: fixed;
    top: 60px;
    width: calc(100% - 40px);
    height: calc(100% - 60px);
    margin: auto;
    z-index: -1;
    background-color: #151515;
    opacity: 0;
    transform: scale(0.8);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-duration: 1s;
    transition-property: background-color;
    backface-visibility: hidden;
    -webkit-font-smoothing: antialiased;

    @include tablet {
      pointer-events: none;
      position: fixed;
      top: 60px;
      width: calc(100% - 40px);
      height: calc(100% - 60px);
      margin: auto;
      z-index: -1;
      background-color: #151515;
      opacity: 0;
      transform: scale(0.8);
      transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      transition-duration: 1s;
      transition-property: background-color;
      backface-visibility: hidden;
      -webkit-font-smoothing: antialiased;
    }
  }

  .l-header__gnav .header__gnav {
    position: absolute;
    top: 24px;
    right: 0;
    bottom: 0;
    left: 0;
    // margin: auto;
    margin: 80px auto 20px;
    width: fit-content;
    // height: 235px;
    height: 440px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .l-header__gnav .header__gnav .item {
    text-align: center;
    opacity: 0;
  }

  .l-header__gnav .header__gnav .item .link {
    position: relative;
    display: block;
    z-index: 999;
  }

  .l-header__gnav .header__gnav .item .link .text {
    font-size: 18px;
    color: #fcfcfc;
    letter-spacing: 0.1em;
    font-weight: 700;
    white-space: nowrap;
  }

  .l-header__gnav .header__gnav .item .link .text .bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #fff;
    content: "";
    -webkit-transform-origin: right;
    transform-origin: right;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .l-header__gnav.is-open {
    pointer-events: auto;
    background-color: #fff;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .l-header__gnav.is-open .header__gnav,
  .l-header__gnav.is-open .policy {
    opacity: 1;
  }

  .l-header__gnav.is-open .gnav__bg {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  .l-header__gnav .l-header__sns {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 40px;
    height: 220px;
    margin: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    opacity: 0;
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }

  .l-header__gnav .l-header__sns .header__sns {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .l-header__gnav .l-header__sns .header__sns .item {
    margin-top: 33px;
    text-align: center;
  }

  .l-header__gnav .l-header__sns .header__sns .item.preparation {
    opacity: 0.4;
  }

  .l-header__gnav .l-header__sns .header__sns .item:first-child {
    margin-top: 0;
  }

  .l-header__gnav .l-header__sns .header__sns .item .link {
    position: relative;
  }

  .l-header__gnav .l-header__sns .header__sns .item .c-facebook,
  .l-header__gnav .l-header__sns .header__sns .item .c-instagram,
  .l-header__gnav .l-header__sns .header__sns .item .c-wantedly {
    fill: #fcfcfc;
  }

  .l-header__gnav .l-header__language {
    position: absolute;
    // top: 88px;
    top: 74px;
    left: 50%;
    width: 104px;
    margin-left: -52px;
    overflow: hidden;
  }

  .l-header__gnav .l-header__language .header__language {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
    z-index: 1000;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
    will-change: opacity, transform;
  }

  .l-header__gnav .l-header__language .header__language .item {
    position: relative;
  }

  .l-header__gnav .l-header__language .header__language .en .link,
  .l-header__gnav .l-header__language .header__language .jp .link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
  }

  .l-header__gnav .l-header__language .header__language .jp {
    color: #dcdcdc;
    font-size: 12px;
    letter-spacing: 0.1em;
    font-weight: 700;
  }

  .l-header__gnav .l-header__language .header__language .jp.current {
    color: $red;
  }

  .l-header__gnav .l-header__language .header__language .en {
    color: #dcdcdc;
    font-size: 12px;
    letter-spacing: 0.1em;
    font-weight: 700;
  }

  .l-header__gnav .l-header__language .header__language .en.current {
    color: $red;
  }
}

@include desktop {
  .l-footer {
    position: relative;
    z-index: 99;
    padding: 56px 0 96px;
    background-color: #151515;
  }

  .l-footer .inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .l-footer .inner .l-footer__logo,
  .l-footer .inner .l-footer__logo .footer__logo {
    position: relative;
    display: block;
    padding-top: 12px;
    width: 285px;
  }

  .l-footer .inner .l-footer__logo .footer__logo .text {
    font-size: 12px;
    line-height: 1.6;
    margin-bottom: -3.6px;
    margin-top: 14.4px;
    letter-spacing: 0.05em;
    color: #f7f7f7;
  }

  // .text-one {
  //   color: #999999 !important;
  //   font-weight: 500;
  //   margin-bottom: 12px !important;
  //   margin-top: 40px !important;
  //   line-height: unset !important;
  // }
  // .text-two {
  //   color: #FFFFFF !important;
  //   opacity: 1;
  //   margin-bottom: 8px !important;
  //   margin-top: unset !important;
  // }
  // .text-three {
  //   color: #FFFFFF !important;
  //   opacity: 1;
  //   letter-spacing: 0px !important;
  //   margin-top: unset !important;
  // }

  .l-footer .inner .l-footer__logo .footer__logo .text:first-child {
    font-size: 12px;
    letter-spacing: 0.05em;
    color: #999;
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
  }

  .l-footer .inner .l-footer__copyright .footer__copyright,
  .l-footer .inner .l-footer__nav .footer__nav .item {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-size: 12px;
    letter-spacing: 0.05em;
  }

  .l-footer .inner .l-footer__nav {
    padding-top: 40px;
    // margin-right: 48px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 12px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 850px;

    .service_logos {
      display: flex;
      justify-content: flex-start;
      gap: 8px;
      margin-top: 20px;
      width: 150px;

      .spin {
        height: 26px;
        object-fit: contain;
      }

      .tells {
        height: 21px;
        object-fit: contain;
      }

      .vibes {
        height: 20px;
        object-fit: contain;
        margin-top: 2px;
      }

      &.service_logos--suspay {
        .suspay {
          height: 20px;
          object-fit: contain;
        }
      }
    }
  }

  .l-footer .inner .l-footer__nav .footer__nav .item {
    position: relative;
    margin-top: 20px;
    font-feature-settings: "palt" 1;
  }

  .l-footer .inner .l-footer__nav .footer__nav .item:first-child {
    margin-top: 0;
  }

  .l-footer .inner .l-footer__nav .footer__nav .item.gray {
    color: #999;
  }

  .l-footer .inner .l-footer__nav .footer__nav .item.gray.second_sub_title {
    margin-top: 25px;
  }

  .l-footer .inner .l-footer__nav .footer__nav li:not(.sub_title) {
    padding-left: 15px;
  }

  .l-footer .inner .l-footer__nav .footer__nav .link {
    position: relative;
    display: block;
    color: #f7f7f7;
  }

  .l-footer .inner .l-footer__nav .footer__nav .link::after {
    content: "";
    background-color: #fcfcfc;
    display: block;
    position: absolute;
    bottom: -8px;
    width: 100%;
    height: 2px;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: left;
    transform-origin: left;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    z-index: -1;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }

  .l-footer .inner .l-footer__nav .footer__nav .link:hover::after {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }

  .l-footer .inner .footer__fw_address {
    display: none;
  }

  .l-footer .inner .l-footer__copyright {
    position: absolute;
    left: 43.5%;
    bottom: -72px;
  }

  .l-footer .inner .l-footer__copyright .footer__copyright {
    font-feature-settings: "palt" 1;
    color: #999;
  }
}

@media screen and (max-width: 1024px) {
  .l-footer {
    position: relative;
    z-index: 99;
    padding: 30px 0 104px;
    background-color: #151515;
  }

  .l-footer .inner .l-footer__logo {
    position: relative;
    display: none;
  }

  .l-footer .inner .l-footer__logo .footer__logo {
    position: relative;
    display: block;
    margin: 0 auto;
  }

  .l-footer .inner .l-footer__logo .footer__logo .text {
    text-align: center;
    font-size: 12px;
    line-height: 1.666;
    margin-bottom: -4px;
    margin-top: 14px;
    letter-spacing: 0.05em;
    color: #f7f7f7;
  }

  // .text-one {
  //   font-weight: 500;
  //   margin-top: 28px !important;
  //   color: #999999 !important;
  //   margin-bottom: unset !important;
  // }
  // .text-two {
  //   margin-top: 12px !important;
  //   margin-bottom: unset !important;
  // }
  // .text-three {
  //   margin-top: 8px !important;
  // }

  .l-footer .inner .l-footer__logo .footer__logo .text:first-child {
    font-size: 12px;
    letter-spacing: 0.05em;
    color: #999;
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
  }

  .l-footer .inner .l-footer__nav {
    position: relative;
    margin: 28px auto 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: center;

    .service_logos {
      display: flex;
      justify-content: flex-start;
      gap: 8px;
      width: 150px;
      margin-top: 18px;
      margin-bottom: 12px;

      .spin {
        height: 26px;
        object-fit: contain;
      }

      .tells {
        height: 22px;
        object-fit: contain;
      }

      .vibes {
        height: 20px;
        object-fit: contain;
      }

      &.service_logos--suspay {
        justify-content: center;

        .suspay {
          height: 20px;
          object-fit: contain;
        }
      }
    }
  }

  .l-footer .inner .l-footer__nav .footer__nav {
    position: relative;
    display: block;
    width: 240px;
    float: left;

    @media screen and (max-width: 350px) {
      margin-top: 15px;
    }
  }

  //.l-footer .inner .l-footer__nav .footer__nav:first-child {
  //  margin: 0 56px 0 0;
  //}

  //.l-footer .inner .l-footer__nav .footer__nav:last-child {
  //  clear: both;
  //  float: none;
  //  margin-right: auto;
  //  padding-top: 18px;
  //  max-width: 200px;
  //}

  .l-footer .inner .l-footer__nav .footer__nav .item {
    margin-top: 18px;
    font-size: 12px;
    letter-spacing: 0.05em;
  }

  .l-footer .inner .l-footer__nav .footer__nav .item.gray,
  .l-footer .inner .footer__fw_address .item.gray {
    color: #999;
  }

  .l-footer .inner .l-footer__nav .footer__nav .item.gray.second_sub_title {
    margin-top: 25px;
  }

  .l-footer .inner .l-footer__nav .footer__nav .item:first-child {
    margin-top: 0;
  }

  .l-footer .inner .l-footer__nav .footer__nav li:not(.sub_title) {
    padding-left: 15px;
  }

  .l-footer .inner .footer__fw_address {
    display: block;
    margin: 46px auto 12px auto;
    width: 274px;
  }

  .l-footer .inner .footer__fw_address p {
    font-size: 12px;
    line-height: 1.666;
    letter-spacing: 0.05em;
    color: #f7f7f7;

  }

  .l-footer .inner .l-footer__nav .footer__nav .link {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    display: block;
    position: relative;
    color: #f7f7f7;
  }

  .l-footer .inner .l-footer__copyright {
    position: absolute;
    bottom: -72px;
    left: calc((100vw / 2) - 105px);
  }

  .l-footer .inner .l-footer__copyright .footer__copyright {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    font-size: 11px;
    letter-spacing: 0.05em;
    color: #999;
  }
}

@media screen and (max-width: 533px) {
  .l-footer .inner .l-footer__nav {
    text-align: center;
  }

  .l-footer .inner .l-footer__nav .footer__nav li:not(.sub_title) {
    padding-left: unset;
  }

  .l-footer .inner .l-footer__nav .footer__nav .item.sub_title {
    margin-top: 12px;
  }

  .l-footer .inner .l-footer__nav .footer__nav .item.gray.second_sub_title {
    margin-top: 37px;
  }

  .service_logos {
    margin: 18px auto 12px auto;
  }

  .l-footer .inner .footer__fw_address {
    text-align: center;
  }

  .l-footer .inner .l-footer__copyright {
    left: calc((100vw / 2) - 80px);
  }

}


@media screen and (max-width: 350px) {
  .l-footer .inner .l-footer__nav .footer__nav .item.gray.second_sub_title {
    margin-top: 40px;
  }
}


.l-contents {
  position: relative;
  z-index: 100;
  overflow-x: hidden;
  overflow-y: hidden;
}

@include desktop {
  .l-index-kv {
    position: relative;
    z-index: 500;
    height: 100vh;
    max-height: 900px;
  }

  .l-index-kv .l-index-kv__read .copy {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 0;
    display: inline-block;
    width: 451px;
    height: auto;
  }

  .l-index-kv .l-index-kv__read .copy img {
    width: 100%;
  }

  .l-index-kv .l-index-kv__read .copy .cover {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #fcfcfc;
    -webkit-transition-duration: 2.4s;
    transition-duration: 2.4s;
    -webkit-transition-timing-function: cubic-bezier(0.3, 0.4, 0.1, 1);
    transition-timing-function: cubic-bezier(0.3, 0.4, 0.1, 1);
  }

  .l-index-kv .l-index-kv__read.is-show .copy .cover {
    -webkit-transform: matrix(1, 0, 0.57734, 1, 2127, 0);
    transform: matrix(1, 0, 0.57734, 1, 2127, 0);
  }

  .l-index-kv .l-index-kv__thumb {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 675px;
    margin: auto;
  }

  .l-index-kv .l-index-kv__thumb .c-bird1 {
    position: absolute;
    z-index: 3;
    fill: none;
    stroke-width: 1px;
    stroke: #333;
    stroke-dasharray: 2163;
    stroke-dashoffset: 2163;
  }

  .l-index-kv .l-index-kv__thumb.is-show .bird img {
    -webkit-animation: bird 1.2s cubic-bezier(0.3, 0.4, 0.1, 1);
    animation: bird 1.2s cubic-bezier(0.3, 0.4, 0.1, 1);
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }

  .l-index-kv .l-index-kv__thumb .bird {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  .l-index-kv .l-index-kv__thumb .bird img {
    opacity: 0;
    -webkit-filter: blur(10px);
    filter: blur(10px);
    -webkit-transition-duration: 1.2s;
    transition-duration: 1.2s;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-transition-timing-function: cubic-bezier(0.3, 0.4, 0.1, 1);
    transition-timing-function: cubic-bezier(0.3, 0.4, 0.1, 1);
    will-change: filter, transform;
  }

  .l-index-kv .l-index-kv__thumb .thumb--bird-blue {
    position: absolute;
    top: -50px;
    right: 0;
    left: -60px;
    margin: auto;
    width: 853px;
    height: 565px;
    z-index: 3;
    mix-blend-mode: multiply;
  }

  .l-index-kv .l-index-kv__thumb .thumb--bird-blue .bird-blue-1 {
    position: absolute !important;
    left: 658px !important;
    top: 378px !important;
    z-index: 3;
    display: inline-block;
    width: 306px;
    height: auto;
    /* font-size: 50px; */
    line-height: 1.3;
  }

  .l-index-kv .l-index-kv__thumb .thumb--bird-blue .bird-blue-1 img {
    width: 100%;
  }

  .l-index-kv .l-index-kv__thumb .thumb--bird-blue .bird-blue-2 {
    position: absolute !important;
    top: 380px !important;
    left: 580px !important;
    display: inline-block;
    width: 204px;
    height: auto;
    z-index: 2;
    /* font-size: 20px; */
  }

  /*
    .l-index-kv .l-index-kv__thumb .thumb--bird-blue .bird-blue-2::after {
        z-index: 1;
        background-color: #1167F2;
        content: "";
        height: 2px;
        width: 40%;
        position: absolute;
        top: 50%;
        left: -50%;
        bottom: -20%;
    } */

  .l-index-kv .l-index-kv__thumb .thumb--bird-blue .bird-blue-2 img {
    width: 100%;
  }

  .l-index-kv .l-index-kv__thumb .thumb--bird-blue .bird-blue-3 {
    position: absolute !important;
    top: 500px !important;
    left: -10px !important;
    z-index: 1;
    display: inline-block;
    width: 450px;
    height: auto;
    font-size: 15px;
    line-height: 1.7;
  }

  .l-index-kv .l-index-kv__thumb .thumb--bird-blue .bird-blue-3 img {
    width: 100%;
  }

  .l-index-kv .l-index-kv__thumb .thumb--bird-blue .bird-blue-4 {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    z-index: 4;
    display: inline-block;
    width: 378px;
    height: auto;
  }

  .l-index-kv .l-index-kv__thumb .thumb--bird-blue .bird-blue-4 img {
    width: 100%;
  }

  .l-index-kv .l-index-kv__thumb .thumb--bird-red {
    position: absolute;
    top: 0;
    left: 0;
    margin: auto;
    width: 852px;
    height: 565px;
    z-index: 2;
    right: -60px;
  }

  .l-index-kv .l-index-kv__thumb .thumb--bird-red .bird-red-1 {
    position: absolute !important;
    top: 50px !important;
    left: -250px !important;
    z-index: 3;
    display: inline-block;
    width: 1200px;
    height: auto;
  }

  .l-index-kv .l-index-kv__thumb .thumb--bird-red .bird-red-1 img {
    width: 100%;
  }

  .l-index-kv .l-index-kv__thumb .thumb--bird-red .bird-red-2 {
    position: absolute !important;
    top: 340px !important;
    left: 112px !important;
    display: inline-block;
    width: 544px;
    height: auto;
    z-index: 2;
  }

  .l-index-kv .l-index-kv__thumb .thumb--bird-red .bird-red-2 img {
    width: 100%;
  }

  .l-index-kv .l-index-kv__thumb .thumb--bird-red .bird-red-3 {
    position: absolute !important;
    top: 86px !important;
    left: 0 !important;
    z-index: 1;
    display: inline-block;
    width: 514px;
    height: auto;
  }

  .l-index-kv .l-index-kv__thumb .thumb--bird-red .bird-red-3 img {
    width: 100%;
  }

  .l-index-kv .l-index-kv__thumb .thumb--bird-red .bird-red-4 {
    position: absolute !important;
    top: 0 !important;
    left: 474px !important;
    z-index: 4;
    display: inline-block;
    width: 378px;
    height: auto;
  }

  .l-index-kv .l-index-kv__thumb .thumb--bird-red .bird-red-4 img {
    width: 100%;
  }

  .l-index-kv .l-index-kv__bg {
    position: absolute;
    width: 100vw;
    height: 100vh;
    max-height: 900px;
    pointer-events: none;
    z-index: -1;
    opacity: 0;
    -webkit-transition: 1.2s 2.5s cubic-bezier(0.3, 0.4, 0.1, 1);
    transition: 1.2s 2.5s cubic-bezier(0.3, 0.4, 0.1, 1);
  }
}

@media screen and (max-width: 1024px) {
  .l-index-kv {
    position: relative;
    z-index: 500;
    width: 100%;
    min-height: 608px;
    height: 100vh;
    background: #fcfcfc;
  }

  .l-index-kv .l-index-kv__thumb {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .l-index-kv .l-index-kv__thumb.is-show .bird {
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }

  .l-index-kv .l-index-kv__thumb .bird {
    opacity: 0;
    -webkit-filter: blur(10px);
    filter: blur(10px);
    -webkit-transition-duration: 1.2s;
    transition-duration: 1.2s;
    -webkit-transition-timing-function: cubic-bezier(0.3, 0.4, 0.1, 1);
    transition-timing-function: cubic-bezier(0.3, 0.4, 0.1, 1);
  }

  .l-index-kv .l-index-kv__thumb .thumb--bird {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    margin-top: -10.66667%;
    width: 100%;
    height: auto;
    z-index: 1;
  }

  .l-index-kv .l-index-kv__read {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    margin-top: 37.33333%;
    width: 100%;
    z-index: 2;
  }

  .l-index-kv .l-index-kv__read .copy {
    position: relative;
    width: 100%;
    display: block;
    margin: 0 auto;
  }

  .l-index-kv .l-index-kv__read .copy .cover {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    background: #fcfcfc;
    -webkit-transition-duration: 2.4s;
    transition-duration: 2.4s;
    -webkit-transition-timing-function: cubic-bezier(0.3, 0.4, 0.1, 1);
    transition-timing-function: cubic-bezier(0.3, 0.4, 0.1, 1);
  }

  .l-index-kv .l-index-kv__read .copy img {
    margin: 0 auto;
    display: block;
    max-width: 287px;
    height: auto;
  }

  .l-index-kv .l-index-kv__read.is-show .copy .cover {
    -webkit-transform: matrix(1, 0, 0.57734, 1, 2127, 0);
    transform: matrix(1, 0, 0.57734, 1, 2127, 0);
  }

  .l-index-kv .l-index-kv__bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    opacity: 0;
    -webkit-transition: 1.2s 2.5s cubic-bezier(0.3, 0.4, 0.1, 1);
    transition: 1.2s 2.5s cubic-bezier(0.3, 0.4, 0.1, 1);
    z-index: -1;
  }
}

@include desktop {
  .l-kv {
    position: relative;
    z-index: 500;
    width: 100%;
    // height: 386px;
    // height: 500px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    /* overflow: hidden */
  }

  .l-kv .l-kv__read {
    position: relative;
    z-index: 2;
    margin: auto;
  }

  .l-kv .l-kv__read .copy {
    position: relative;
    display: block;
    max-width: 236px;
  }

  .l-kv .l-kv__bg,
  .l-kv .l-kv__bg .bg,
  .l-kv .l-kv__read .copy .cover {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .l-kv .l-kv__read .copy .img {
    display: block;
  }

  .l-kv .l-kv__read .copy .cover {
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    background-color: #fcfcfc;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: right;
    transform-origin: right;
    content: "";
    will-change: transform;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .l-kv .l-kv__bg {
    z-index: -1;
    /* overflow: hidden */
  }

  .l-kv .l-kv__bg .bg {
    opacity: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    will-change: transform, opacity;
    -webkit-transition-property: opacity, -webkit-transform, -webkit-filter;
    transition-property: opacity, -webkit-transform, -webkit-filter;
    transition-property: opacity, transform, filter;
    transition-property: opacity, transform, filter, -webkit-transform,
      -webkit-filter;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-filter: blur(30px);
    filter: blur(30px);
  }
}

@media screen and (max-width: 1024px) {
  .l-kv {
    // position: relative;
    // z-index: 500;
    // width: 100%;
    // height: 273px;
    // display: -webkit-box;
    // display: -ms-flexbox;
    // display: flex;
    // -webkit-box-align: center;
    // -ms-flex-align: center;
    // align-items: center;
    // margin-top: 60px;
    // overflow: hidden
  }

  .l-kv .l-kv__read {
    position: relative;
    z-index: 2;
    margin: auto;
  }

  .l-kv .l-kv__read .copy {
    position: relative;
    max-width: 165px;
  }

  .l-kv .l-kv__bg,
  .l-kv .l-kv__bg .bg,
  .l-kv .l-kv__read .copy .cover {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .l-kv .l-kv__read .copy .img {
    display: block;
  }

  .l-kv .l-kv__read .copy .cover {
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    background-color: #fcfcfc;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: right;
    transform-origin: right;
    content: "";
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .l-kv .l-kv__bg {
    z-index: -1;
    overflow: hidden;
  }

  .l-kv .l-kv__bg .bg {
    opacity: 0;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    will-change: transform, opacity, filter;
    -webkit-filter: blur(30px);
    filter: blur(30px);
  }

  .l-cursor {
    display: none;
  }
}

.l-section {
  position: relative;
}

@include desktop {
  .l-cursor {
    position: fixed;
    top: 0;
    left: 0;
    width: 12px;
    height: 12px;
    content: "";
    border-radius: 50%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    z-index: 10000;
    background-color: #fff;
    mix-blend-mode: exclusion;
    pointer-events: none;
    display: none;
  }

  .l-cursor.over {
    -webkit-transition: 0.3s top, 0.3s left;
    transition: 0.3s top, 0.3s left;
    top: -4px;
    left: -4px;
  }
}

.l-screen-transition {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10002;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.l-screen-transition .curtain {
  width: 100vw;
  height: 100vh;
  position: absolute;
  display: block;
}

.l-screen-transition .curtain .curtain-item {
  width: 100%;
  height: calc(100vh / 1);
  /* background: #111; */
  background: #fff;
  /* background: $red;
    background: -moz-linear-gradient(-45deg, $red 0%, #1167f2 100%);
    background: -webkit-linear-gradient(-45deg, $red 0%, #1167f2 100%);
    background: linear-gradient(135deg, $red 0%, #1167f2 100%); */

  /* background: #ffe2e2;
    background: -moz-linear-gradient(-45deg, #ffe2e2 1%, #b1b8f9 100%);
    background: -webkit-linear-gradient(-45deg, #ffe2e2 1%, #b1b8f9 100%);
    background: linear-gradient(135deg, #ffe2e2 1%, #b1b8f9 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffe2e2', endColorstr='#b1b8f9', GradientType=1); */

  position: relative;
  left: -150%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: transform;
  /* border: 1px solid #000; */
}

.l-screen-transition .bar {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 50px;
  margin: auto;
  z-index: -1;
  opacity: 0;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-duration: 0.1s;
  transition-duration: 0.1s;
}

.l-screen-transition .bar.is-show {
  z-index: 100;
  opacity: 1;
}

@-webkit-keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }

  100%,
  60% {
    left: 100%;
    right: -90%;
  }
}

@keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }

  100%,
  60% {
    left: 100%;
    right: -90%;
  }
}

@-webkit-keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }

  100%,
  60% {
    left: 107%;
    right: -8%;
  }
}

@keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }

  100%,
  60% {
    left: 107%;
    right: -8%;
  }
}

.l-load {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10003;
  width: 100%;
  height: 100%;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  pointer-events: none;
}

.l-load .line {
  /* position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    background: #fff;
    -webkit-transition: -webkit-transform .8s ease-in;
    transition: -webkit-transform .8s ease-in;
    transition: transform .8s ease-in;
    transition: transform .8s ease-in, -webkit-transform .8s ease-in;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: top;
    transform-origin: top;
    position: absolute;
    top: 0;
    bottom: 0; */

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 50px;
  margin: auto;
}

.l-load .curtain {
  width: 100vw;
  height: 50vh;
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

/* .l-load .bar {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 240px;
    margin: auto;
    z-index: -1;
    opacity: 0;
    -webkit-transition-property: opacity;
    transition-property: opacity;
    -webkit-transition-duration: .1s;
    transition-duration: .1s
}

.l-load .bar .is-show {
    z-index: 100;
    opacity: 1
} */

/* .l-load .curtain.curtain-top {
    top: 0;
} */

/* .l-load .curtain.curtain-bottom {
    bottom: 0;
} */

.l-load .curtain .curtain-item {
  width: calc(100vw / 1);
  height: 100vh;
  /* background: #111; */
  background: #fff;
  /* background: $red;
    background: -moz-linear-gradient(-45deg, $red 0%, #1167f2 100%);
    background: -webkit-linear-gradient(-45deg, $red 0%, #1167f2 100%);
    background: linear-gradient(135deg, $red 0%, #1167f2 100%); */
  -webkit-transition: 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition: 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
}

.l-load .curtain.is-open.curtain-top .curtain-item {
  -webkit-transform-origin: top;
  transform-origin: top;
  /* -webkit-transform: translateY(-50vh); */
  transform: translateX(100%);
}

.l-load .curtain.is-open.curtain-bottom .curtain-item {
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
  /* -webkit-transform: translateY(50vh); */
  transform: translateX(100%);
}

@media all and (-ms-high-contrast: none) {

  .l-header .l-header__scroll .scroll--more .text,
  .l-header .l-header__scroll .scroll--top .text {
    font-size: 11px !important;
    font-family: "メイリオ", Meiryo, "游ゴシック", YuGothic,
      "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "ＭＳ Ｐゴシック",
      "MS PGothic", sans-serif !important;
  }

  .bold,
  .en,
  .footer__logo .text,
  .item,
  .l-footer__copyright .footer__copyright,
  .l-header .l-header__scroll .scroll--more .text,
  .l-header .l-header__scroll .scroll--top .text,
  .news__list .item .head .date,
  .news__list .item .head .tag,
  .num,
  .p-btn .link .text,
  .p-btn--b .link .text,
  .p-headline,
  .p-headline .text,
  .s-title,
  .text,
  .text-link .text,
  .wpcf7-list-item-label,
  body {
    font-family: "メイリオ", Meiryo, "游ゴシック", YuGothic,
      "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "ＭＳ Ｐゴシック",
      "MS PGothic", sans-serif !important;
  }

  .service--first .inner .body .text__content,
  .service--last .inner .body .text__content {
    margin-left: auto !important;
  }

  .img,
  .l-kv__bg .bg {
    -webkit-filter: none !important;
    filter: none !important;
  }

  .l-header .l-header__scroll .scroll--more .arrow {
    margin-top: -34px !important;
  }

  .l-header .l-header__scroll .scroll--top .arrow {
    margin-top: -3px !important;
  }

  .text-link .text {
    font-size: 12px !important;
  }

  .service--second .inner .body .text__content {
    margin-right: auto !important;
  }
}

@-moz-document url-prefix() {

  .img,
  .l-kv__bg .bg {
    filter: none !important;
  }
}

@include desktop {
  .p-headline {
    position: relative;
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    font-size: 24px;
    letter-spacing: 0.1em;
  }

  .p-headline.is-shown .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .p-headline .cover {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 3s;
    transition-duration: 3s;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: right;
    transform-origin: right;
    content: "";
  }
}

@media screen and (max-width: 1024px) {
  .p-headline {
    position: relative;
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    font-size: 18px;
    letter-spacing: 0.1em;
  }

  .p-headline.is-shown .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .p-headline .cover {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 3s;
    transition-duration: 3s;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: right;
    transform-origin: right;
  }
}

@include desktop {
  .text-link {
    position: relative;
    display: block;
    width: 110px;
  }

  .text-link:hover .arrow {
    -webkit-animation: arrrowRight 0.6s infinite alternate;
    animation: arrrowRight 0.6s infinite alternate;
  }

  .text-link:hover .text {
    color: #151515;
  }

  .text-link .text {
    font-size: 16px;
    letter-spacing: 0.05em;
    color: $red;
    /* font-weight: 700; */
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }

  .text-link .arrow {
    position: absolute;
    top: 50%;
    right: 0;
    width: 12px;
    height: 12px;
    margin-top: -3.5px;
  }

  .text-link .arrow::after,
  .text-link .arrow::before {
    width: 7px;
    height: 2px;
    display: block;
    background-color: $red;
    position: absolute;
    right: 0;
    content: "";
  }

  .text-link .arrow::before {
    top: 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .text-link .arrow::after {
    top: 4px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  .p-btn {
    position: relative;
    display: block;
    width: 200px;
    background-color: #f21515;
    overflow: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transform-origin: left;
    transform-origin: left;
  }

  .p-btn .link .text,
  .p-btn::after {
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }

  .p-btn--b,
  .p-btn::after {
    -webkit-transition-property: -webkit-transform;
  }

  .p-btn.is-shown {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  .p-btn.is-shown .link .text {
    -webkit-transition-delay: 0.5s;
    transition-delay: 0.5s;
    opacity: 1;
  }

  .p-btn::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    content: "";
    background-color: #151515;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: left;
    transform-origin: left;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    z-index: 0;
  }

  .p-btn .link,
  .p-btn--b {
    position: relative;
    display: block;
    // left: 130%;
  }

  .p-btn.is-over::after {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  .p-btn.is-leave::after {
    -webkit-transform-origin: right;
    transform-origin: right;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .p-btn .link {
    line-height: 67px;
    text-align: center;
    z-index: 1;
  }

  .p-btn .link .text {
    display: block;
    font-family: NotoSansCJKjp, sans-serif;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    font-weight: 400;
    font-size: 18px;
    letter-spacing: 0.1em;
    color: #fff;
    -webkit-transition-duration: 0.7s;
    transition-duration: 0.7s;
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: opacity;
    transition-property: opacity;
    opacity: 0;
  }

  .p-btn--b {
    width: 266px;
    background-color: #f21515;
    overflow: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transform-origin: left;
    transform-origin: left;
  }

  .p-btn--b.is-shown {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  .p-btn--b.is-shown .link .text {
    -webkit-transition-delay: 0.5s;
    transition-delay: 0.5s;
    opacity: 1;
  }

  .p-btn--b::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    content: "";
    background-color: #151515;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: left;
    transform-origin: left;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    -webkit-transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    z-index: 0;
  }

  .p-btn--b.is-over::after {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  .p-btn--b.is-leave::after {
    -webkit-transform-origin: right;
    transform-origin: right;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .p-btn--b .link {
    position: relative;
    display: block;
    line-height: 74px;
    text-align: center;
    z-index: 1;
  }

  .p-btn--b .link .text {
    font-family: NotoSansCJKjp, sans-serif;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0.075em;
    color: #fff;
    -webkit-transition-duration: 0.7s;
    transition-duration: 0.7s;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: opacity;
    transition-property: opacity;
    opacity: 0;
  }
}

@media screen and (max-width: 1024px) {

  .p-btn,
  .p-btn--b,
  .text-link .arrow {
    // background-color: $red
    background-color: #f21515;
  }

  .p-btn .link,
  .p-btn--b .link {
    display: block;
    text-align: center;
  }

  .text-link {
    position: relative;
    display: block;
    width: 104px;
  }

  .text-link .text {
    font-size: 12px;
    letter-spacing: 0.05em;
    color: $red;
    font-weight: 700;
  }

  .p-btn .link .text,
  .p-btn--b .link .text {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 700;
    color: #fff;
  }

  .text-link .arrow {
    position: absolute;
    top: 50%;
    right: -12px;
    width: 40px;
    height: 1px;
    content: "";
  }

  .p-btn,
  .p-btn .link,
  .p-btn--b,
  .p-btn--b .link {
    position: relative;
  }

  .p-btn {
    display: block;
    width: 200px;
    background-color: #f21515;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transform-origin: left;
    transform-origin: left;
  }

  .p-btn .link .text,
  .p-btn--b {
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }

  .p-btn.is-shown {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  .p-btn.is-shown .link .text {
    -webkit-transition-delay: 0.5s;
    transition-delay: 0.5s;
    opacity: 1;
  }

  .p-btn .link {
    line-height: 64px;
  }

  .p-btn .link .text {
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    font-weight: 400;
    font-size: 18px;
    letter-spacing: 0.1em;
    color: #fff;
    -webkit-transition-duration: 0.7s;
    transition-duration: 0.7s;
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: opacity;
    transition-property: opacity;
    opacity: 0;
  }

  .p-btn--b {
    display: block;
    width: 180px;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transform-origin: left;
    transform-origin: left;
  }

  .p-btn--b.is-shown {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    float: right;
  }

  .p-btn--b.is-shown .link .text {
    -webkit-transition-delay: 0.5s;
    transition-delay: 0.5s;
    opacity: 1;
  }

  .p-btn--b .link {
    line-height: 60px;
  }

  .p-btn--b .link .text {
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    font-size: 14px;
    letter-spacing: 0.075em;
    -webkit-transition-duration: 0.7s;
    transition-duration: 0.7s;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: opacity;
    transition-property: opacity;
    opacity: 0;
  }
}

@include desktop {

  .p-inner,
  .p-inner--m {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding: 0 24px;
  }

  .p-inner {
    max-width: 1012px;
  }

  .p-inner--m {
    max-width: 1214px;
  }
}

@media screen and (max-width: 1024px) {

  .p-inner,
  .p-inner--m {
    position: relative;
    width: 100%;
    /* padding: 0 20px */
    padding: 0 8px;
  }
}

@font-face {
  font-family: iconfont;
  src: url(/assets/fonts/iconfont.eot);
  src: url(/assets/fonts/iconfont.eot?#iefix) format("eot"),
    url(/assets/fonts/iconfont.woff2) format("woff2"),
    url(/assets/fonts/iconfont.woff) format("woff"),
    url(/assets/fonts/iconfont.ttf) format("truetype"),
    url(/assets/fonts/iconfont.svg#iconfont) format("svg");
}

.icon-dummy:before {
  font-family: iconfont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  text-decoration: none;
  content: "";
}

@include desktop {
  .p-contact-form {
    position: relative;
    display: block;
    margin-top: 20px;
    padding: 0 32px;
    background-color: #fff;
  }

  .p-contact-form .contact-form__inner,
  .p-contact-form .contact-form__inner .p-contact-form__wrap,
  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table {
    position: relative;
    display: block;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table.is-lastCheck tbody tr td input[type="text"],
  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table.is-lastCheck tbody tr td input[type="tel"],
  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table.is-lastCheck tbody tr td input[type="email"],
  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table.is-lastCheck tbody tr td select,
  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table.is-lastCheck tbody tr td textarea {
    border: 0;
    border-bottom: 1px solid #ccc;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table.is-lastCheck tbody tr td label:before {
    border: 0 !important;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody {
    position: relative;
    display: block;
    padding: 70px 0 124px;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    margin-top: 32px;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr:first-child {
    margin-top: 0;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr:last-child th {
    line-height: 26px;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr.is-error td input[type="text"],
  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr.is-error td input[type="tel"],
  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr.is-error td input[type="email"],
  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr.is-error td select,
  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr.is-error td textarea {
    border: 1px solid red;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr.is-error td label:before {
    border: 1px solid red !important;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr th {
    display: block;
    position: relative;
    font-size: 15px;
    letter-spacing: 0.1em;
    width: 160px;
    font-weight: 500;
    line-height: 40px;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td {
    display: block;
    position: relative;
    width: calc(100% - 210px);
    margin-left: auto;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .privacy-inner {
    width: 100%;
    height: 160px;
    overflow-y: scroll;
    background-color: #fff;
    border: 1px solid #ccc;
    line-height: 40px;
    padding: 16px 20px;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .privacy-inner .privacy__text {
    font-size: 15px;
    line-height: 2.1333;
    margin-bottom: -8.5px;
    margin-top: 7.5px;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree {
    position: relative;
    margin-top: 8px;
    width: 216px;
    height: 36px;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label,
  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox,
  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox .wpcf7-form-control {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox .wpcf7-form-control .wpcf7-list-item {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    margin: 0;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox .wpcf7-form-control .wpcf7-list-item label {
    position: relative;
    display: block;
    line-height: 1;
    vertical-align: middle;
    cursor: pointer;
    padding-top: 10px;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox .wpcf7-form-control .wpcf7-list-item label:before {
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -7px;
    content: "";
    width: 24px;
    height: 24px;
    display: inline-block;
    vertical-align: middle;
    border: 1px solid #ccc;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox .wpcf7-form-control .wpcf7-list-item label input[type="checkbox"] {
    display: none;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox .wpcf7-form-control .wpcf7-list-item label input[type="checkbox"]:checked+.wpcf7-list-item-label::before,
  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox .wpcf7-form-control .wpcf7-list-item label input[type="checkbox"]:checked+input[type="hidden"]+.wpcf7-list-item-label::before {
    opacity: 1;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox .wpcf7-form-control .wpcf7-list-item label .wpcf7-list-item-label {
    position: relative;
    padding-left: 32px;
    letter-spacing: 0.1em;
    font-size: 15px;
    font-weight: 600;
    white-space: nowrap;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox .wpcf7-form-control .wpcf7-list-item label .wpcf7-list-item-label::before {
    position: absolute;
    top: 1px;
    left: 9px;
    display: block;
    content: "";
    width: 6px;
    height: 10px;
    border-right: 2px solid #303030;
    border-bottom: 2px solid #303030;
    opacity: 0;
    z-index: 1;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: opacity 0.15s cubic-bezier(0.25, 0.25, 0.75, 0.75);
    transition: opacity 0.15s cubic-bezier(0.25, 0.25, 0.75, 0.75);
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td input[type="text"] {
    width: 100%;
    background-color: #fff;
    border: 1px solid #ccc;
    line-height: 40px;
    height: 40px;
    padding: 0 20px;
    font-size: 14px;
    font-weight: 500;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td ::input-placeholder {
    font-size: 14px;
    color: #999;
    letter-spacing: 0.1em;
    font-weight: 500;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td ::-webkit-input-placeholder {
    font-size: 15px;
    color: #999;
    letter-spacing: 0.1em;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td :-ms-input-placeholder {
    font-size: 15px;
    color: #999;
    letter-spacing: 0.1em;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td ::-ms-input-placeholder {
    font-size: 15px;
    color: #999;
    letter-spacing: 0.1em;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td ::placeholder {
    font-size: 15px;
    color: #999;
    letter-spacing: 0.1em;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td input[type="tel"],
  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td input[type="email"] {
    ime-mode: disabled !important;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ccc;
    line-height: 40px;
    height: 40px;
    padding: 0 20px;
    font-size: 14px;
    font-weight: 500;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td select {
    width: 100%;
    background-color: #fff;
    border: 1px solid #ccc;
    line-height: 40px;
    height: 40px;
    padding: 0 20px;
    letter-spacing: 0.1em;
    font-size: 14px;
    font-weight: 500;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td textarea {
    width: 100%;
    height: 224px;
    background-color: #fff;
    border: 1px solid #ccc;
    line-height: 40px;
    padding: 0 20px;
    font-size: 14px;
    font-weight: 500;
  }
}

@media screen and (max-width: 1024px) {
  .p-contact-form {
    position: relative;
    display: block;
    margin-top: 15px;
    background-color: #fff;
  }

  .p-contact-form .contact-form__inner,
  .p-contact-form .contact-form__inner .p-contact-form__wrap,
  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table {
    position: relative;
    display: block;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table.is-lastCheck tbody tr td input[type="text"],
  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table.is-lastCheck tbody tr td input[type="tel"],
  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table.is-lastCheck tbody tr td input[type="email"],
  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table.is-lastCheck tbody tr td select,
  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table.is-lastCheck tbody tr td textarea {
    border: 0;
    border-bottom: 1px solid #ccc;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table.is-lastCheck tbody tr td label:before {
    border: 0 !important;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody {
    position: relative;
    display: block;
    padding: 42px 16px 77px;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr {
    display: block;
    margin-top: 18px;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr:first-child {
    margin-top: 0;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr.is-error td input[type="text"],
  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr.is-error td input[type="tel"],
  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr.is-error td input[type="email"],
  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr.is-error td select,
  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr.is-error td textarea {
    border: 1px solid red;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr.is-error td label:before {
    border: 1px solid red !important;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr th {
    display: block;
    position: relative;
    font-size: 12px;
    letter-spacing: 0.03em;
    font-weight: 500;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td {
    margin-top: 14px;
    display: block;
    position: relative;
    width: 100%;
    font-size: 12px;
    letter-spacing: 0.03em;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .privacy-inner {
    width: 100%;
    height: 160px;
    overflow-y: scroll;
    background-color: #fff;
    border: 1px solid #ccc;
    line-height: 54px;
    padding: 16px;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .privacy-inner .privacy__text {
    font-size: 12px;
    line-height: 2;
    margin-bottom: -6px;
    margin-top: 10px;
    letter-spacing: 0.03em;
    color: #585858;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree {
    position: relative;
    margin: 8px auto 0;
    width: 216px;
    height: 36px;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label,
  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox,
  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox .wpcf7-form-control {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox .wpcf7-form-control .wpcf7-list-item {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    margin: 0;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox .wpcf7-form-control .wpcf7-list-item label {
    position: relative;
    display: block;
    line-height: 1;
    vertical-align: middle;
    cursor: pointer;
    padding-top: 10px;
    margin: 0 auto;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox .wpcf7-form-control .wpcf7-list-item label:before {
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -4px;
    content: "";
    width: 18px;
    height: 18px;
    display: inline-block;
    vertical-align: middle;
    border: 1px solid #ccc;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox .wpcf7-form-control .wpcf7-list-item label input[type="checkbox"] {
    display: none;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox .wpcf7-form-control .wpcf7-list-item label input[type="checkbox"]:checked+.wpcf7-list-item-label::before,
  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox .wpcf7-form-control .wpcf7-list-item label input[type="checkbox"]:checked+input[type="hidden"]+.wpcf7-list-item-label::before {
    opacity: 1;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox .wpcf7-form-control .wpcf7-list-item label .wpcf7-list-item-label {
    position: relative;
    padding-left: 26px;
    letter-spacing: 0.03em;
    font-size: 12px;
    font-weight: 600;
    white-space: nowrap;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox .wpcf7-form-control .wpcf7-list-item label .wpcf7-list-item-label::before {
    position: absolute;
    top: 0;
    left: 6px;
    display: block;
    content: "";
    width: 6px;
    height: 10px;
    border-right: 2px solid #303030;
    border-bottom: 2px solid #303030;
    opacity: 0;
    z-index: 1;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: opacity 0.15s cubic-bezier(0.25, 0.25, 0.75, 0.75);
    transition: opacity 0.15s cubic-bezier(0.25, 0.25, 0.75, 0.75);
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td input[type="text"] {
    width: 100%;
    background-color: #fff;
    border: 1px solid #ccc;
    line-height: 54px;
    height: 54px;
    padding: 0 16px;
    font-size: 12px;
    font-weight: 500;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td ::input-placeholder {
    font-size: 12px;
    letter-spacing: 0.03em;
    color: #999;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td ::-webkit-input-placeholder {
    font-size: 12px;
    letter-spacing: 0.03em;
    color: #999;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td :-ms-input-placeholder {
    font-size: 12px;
    letter-spacing: 0.03em;
    color: #999;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td ::-ms-input-placeholder {
    font-size: 12px;
    letter-spacing: 0.03em;
    color: #999;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td ::placeholder {
    font-size: 12px;
    letter-spacing: 0.03em;
    color: #999;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td input[type="tel"],
  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td input[type="email"] {
    ime-mode: disabled !important;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ccc;
    line-height: 54px;
    height: 54px;
    padding: 0 16px;
    font-size: 12px;
    font-weight: 500;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td select {
    width: 100%;
    background-color: #fff;
    border: 1px solid #ccc;
    line-height: 54px;
    height: 54px;
    padding: 0 16px;
    font-size: 12px;
    letter-spacing: 0.03em;
    font-weight: 500;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td textarea {
    width: 100%;
    background-color: #fff;
    border: 1px solid #ccc;
    line-height: 54px;
    height: 54px;
    padding: 0 16px;
  }
}

@include desktop {
  .index .s-news {
    position: relative;
    display: block;
    // padding-top: 104px;
    margin-top: 100px;
    z-index: 10;
  }

  .index .s-news .news__content {
    position: relative;
    /* background-color: #fff; */
    margin-left: auto;
    margin-right: auto;
    max-width: 764px;
    // padding: 54px 110px 84px 94px;
    // display: -webkit-box;
    // display: -ms-flexbox;
    // display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;
    opacity: 0;
  }

  .index .s-news .news__content.is-shown {
    opacity: 1;
  }

  .index .s-news .news__content.is-shown .news__head .p-headline .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .index .s-news .news__content.is-shown .news__body {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  .index .s-news .news__content .news__head {
    // position: relative;
    // width: 24.58333%;
    // padding-left: 3.33333%
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #d2d2d2;
  }

  .index .s-news .news__content .news__head .p-headline {
    opacity: 1;
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
    margin-bottom: 10px;
  }

  .index .s-news .news__content .news__head .p-headline .cover {
    background-color: #fff;
  }

  .index .s-about,
  .index .s-about .inner .about__body .p-headline .cover {
    background-color: #fcfcfc;
  }

  .index .s-news .news__content .news__body {
    position: relative;
    width: calc(100% - 236px);
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    -webkit-transition-duration: 1s;
    transition-duration: 1s;
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
    opacity: 0;
  }

  .index .s-news .news__content .news__body .news__list {
    position: relative;
    padding-left: 36px;
  }

  .index .s-news .news__content .news__body .news__list .item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding: 20px 24px 20px 0;
    // border-bottom: 1px solid #d2d2d2
  }

  .index .s-news .news__content .news__body .news__list .item:first-child {
    padding-top: 10px;
  }

  .index .s-news .news__content .news__body .news__list .item .head {
    width: 174px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-right: 16px;
    line-height: 1.4;
  }

  .index .s-news .news__content .news__body .news__list .item .head .date {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    font-size: 13px;
    letter-spacing: 0.03em;
    margin-right: 24px;
    color: #d2d2d2;
  }

  .index .s-news .news__content .news__body .news__list .item .head .tag {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    width: 50px;
    font-size: 13px;
    letter-spacing: 0.03em;
  }

  .index .s-news .news__content .news__body .news__list .item .link,
  .index .s-news .news__content .news__body .news__list .item .text {
    display: block;
    font-size: 13px;
    letter-spacing: 0.03em;
    line-height: 1.4;
    font-weight: 500;
    color: #303030;
    -webkit-transition-property: opacity;
    transition-property: opacity;
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;
  }

  .index .s-news .news__content .news__body .news__list .item .link:hover {
    opacity: 0.7;
  }

  .index .s-news .news__content .news__body .p-btn {
    position: absolute;
    bottom: -112px;
    left: 36px;
  }

  .index .s-about {
    position: relative;
    padding: 185px 0 92px;
    margin-top: -1px;
  }

  .index .s-about .inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .index .s-about .inner .about__head {
    position: relative;
    display: block;
    width: 466px;
    min-width: 446px;
    margin-right: 3.33333%;
    -webkit-transition-property: opacity;
    transition-property: opacity;
    -webkit-transition-duration: 0.8s;
    transition-duration: 0.8s;
    opacity: 0;
  }

  .index .s-about .inner .about__head.is-shown,
  .index .s-service .inner .service__body .service__list .item.is-shown .head {
    opacity: 1;
  }

  .index .s-about .inner .about__head .thumb__men {
    position: absolute;
    top: 0;
    right: 0;
    width: 272px;
    height: 100%;
    z-index: 1;
  }

  .index .s-about .inner .about__head .thumb__men .img {
    display: block;
    max-width: 210px;
  }

  .index .s-about .inner .about__head .thumb__men .men-blue {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }

  .index .s-about .inner .about__head .thumb__men .men-red {
    position: absolute;
    top: 95px;
    left: 0;
    z-index: 2;
    mix-blend-mode: multiply;
  }

  .index .s-about .inner .about__head .thumb__men .men-red2 {
    position: absolute;
    top: 250px;
    left: -165px;
    z-index: 3;
    mix-blend-mode: multiply;
  }

  .index .s-about .inner .about__head .thumb__women {
    position: absolute;
    top: 24px;
    left: 0;
    width: 294px;
    height: 100%;
    z-index: 2;
  }

  .index .s-about .inner .about__head .thumb__women .img {
    display: block;
    max-width: 263px;
  }

  .index .s-about .inner .about__head .thumb__women .women-blue {
    position: absolute;
    top: 32px;
    right: 0;
    z-index: 2;
    mix-blend-mode: multiply;
  }

  .index .s-about .inner .about__head .thumb__women .women-red {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .index .s-about .inner .about__body {
    position: relative;
    padding: 40px 0 60px;
    max-width: 438px;
  }

  .index .s-about .inner .about__body .p-headline.is-shown .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .index .s-about .inner .about__body .s-title {
    font-size: 42px;
    font-weight: 500;
    letter-spacing: 0.025em;
    line-height: 1.2;
    margin-top: 80px;
  }

  .index .s-about .inner .about__body>.text {
    position: relative;
    font-size: 15px;
    line-height: 2.1333;
    margin-bottom: 51.5px;
    margin-top: 23.5px;
    letter-spacing: 0.03em;
  }

  .index .s-about .inner .about__body .p-btn {
    margin-left: auto;
  }

  .index .s-solution {
    position: relative;
    padding: 100px 0 80px;
    background-color: #fff;
  }

  .index .s-solution .inner .solution__content {
    position: relative;
    width: 100%;
  }

  .index .s-solution .inner .solution__content .thumb-wrapper {
    /* overflow: hidden */
  }

  .index .s-solution .inner .solution__content .thumb-wrapper.is-shown .thumb .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .index .s-solution .inner .solution__content .thumb-wrapper.is-shown .thumb .img {
    -webkit-filter: brightness(100%);
    filter: brightness(100%);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  .index .s-solution .inner .solution__content .thumb-wrapper .thumb {
    max-width: 872px;
    margin-left: auto;
    padding-left: 32px;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .index .s-solution .inner .solution__content .thumb-wrapper .thumb .cover {
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    background-color: #fff;
    z-index: 1;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 2s;
    transition-duration: 2s;
    -webkit-transform-origin: right center;
    transform-origin: right center;
    overflow: hidden;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
  }

  .index .s-solution .inner .solution__content .thumb-wrapper .thumb .img {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    -webkit-transition: 1.6s -webkit-transform, -webkit-filter 3s;
    transition: 1.6s -webkit-transform, -webkit-filter 3s;
    transition: 1.6s transform, filter 3s;
    transition: 1.6s transform, filter 3s, 1.6s -webkit-transform,
      -webkit-filter 3s;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow: hidden;
    -webkit-filter: brightness(200%);
    filter: brightness(200%);
    will-change: filter, transform;
  }

  .index .s-solution .inner .solution__content .solution__body {
    position: absolute;
    top: 56px;
    left: 0;
    background-color: #fff;
    width: 424px;
    padding: 46px 20px 92px 48px;
  }

  .index .s-solution .inner .solution__content .solution__body.is-shown .p-headline .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .index .s-solution .inner .solution__content .solution__body .p-headline {
    text-align: left;
  }

  .index .s-solution .inner .solution__content .solution__body .p-headline .cover {
    background-color: #fff;
  }

  .index .s-solution .inner .solution__content .solution__body>.text {
    font-size: 15px;
    line-height: 2.1333;
    margin-bottom: -8.5px;
    margin-top: 51.5px;
    letter-spacing: 0.03em;
  }

  .index .s-solution .inner .solution__content .solution__body .p-btn {
    position: absolute;
    left: 46px;
    bottom: -26px;
  }

  /* add new layout */

  .index .s-solution .inner .solution__content .thumb-wrapper.is-shown .thumb1 .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .index .s-solution .inner .solution__content .thumb-wrapper.is-shown .thumb1 .img {
    -webkit-filter: brightness(100%);
    filter: brightness(100%);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  .index .s-solution .inner .solution__content .thumb-wrapper .thumb1 {
    max-width: 872px;
    /* margin-left: auto; */
    /* margin-left: 10px; */
    padding-right: 32px;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .index .s-solution .inner .solution__content .thumb-wrapper .thumb1 .cover {
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    background-color: #fff;
    z-index: 1;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 2s;
    transition-duration: 2s;
    -webkit-transform-origin: right center;
    transform-origin: right center;
    overflow: hidden;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
  }

  .index .s-solution .inner .solution__content .thumb-wrapper .thumb1 .black_box {
    position: relative;
    display: block;
    width: 55vw;
    height: 55vh;
    background-color: #000;
    /* width: 100%;
        height: 100%; */
    -webkit-transition: 1.6s -webkit-transform, -webkit-filter 3s;
    transition: 1.6s -webkit-transform, -webkit-filter 3s;
    transition: 1.6s transform, filter 3s;
    transition: 1.6s transform, filter 3s, 1.6s -webkit-transform,
      -webkit-filter 3s;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow: hidden;
    -webkit-filter: brightness(200%);
    filter: brightness(200%);
    will-change: filter, transform;
  }

  .index .s-solution .inner .solution__content .solution__body1 {
    position: absolute;
    top: 56px;
    right: -30px;
    background-color: #f8f8f8;
    width: 424px;
    padding: 46px 20px 92px 48px;
  }

  .index .s-solution .inner .solution__content .solution__body1.is-shown .p-headline .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .index .s-solution .inner .solution__content .solution__body1 .p-headline {
    text-align: left;
  }

  .index .s-solution .inner .solution__content .solution__body1 .p-headline .cover {
    background-color: #f8f8f8;
  }

  .index .s-solution .inner .solution__content .solution__body1>.text {
    font-size: 15px;
    line-height: 2.1333;
    margin-bottom: -8.5px;
    margin-top: 51.5px;
    letter-spacing: 0.03em;
  }

  .index .s-solution .inner .solution__content .solution__body1 .p-btn {
    position: absolute;
    left: 196px;
    bottom: -26px;
  }

  .index .s-service {
    background-color: #fcfcfc;
    padding: 80px 0 158px;
  }

  .index .s-service .inner .service__head {
    width: 486px;
    margin-left: auto;
    margin-right: auto;
  }

  .index .s-service .inner .service__head .p-headline {
    text-align: center;
  }

  .index .s-service .inner .service__head .p-headline.is-shown .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .index .s-service .inner .service__head .p-headline .cover {
    background-color: #fcfcfc;
  }

  .index .s-service .inner .service__head .text {
    font-size: 15px;
    line-height: 2.1333;
    margin-bottom: 61.5px;
    margin-top: 71.5px;
    letter-spacing: 0.03em;
  }

  .index .s-service .inner .service__body {
    position: relative;
    display: block;
    width: 100%;
  }

  .index .s-service .inner .service__body .service__list {
    position: relative;
    /* display: block */
    display: flex;
  }

  .index .s-service .inner .service__body .service__list .item {
    /* display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between; */
    width: 100%;
    /* margin-top: 64px; */
    padding-top: 10px;
    padding-left: 30px;
  }

  .index .s-service .inner .service__body .service__list .item:first-child {
    margin-top: 0;
  }

  .index .s-service .inner .service__body .service__list .item.is-shown .head .service__headline .num {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .index .s-service .inner .service__body .service__list .item.is-shown .head .service__headline .line {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition-delay: 0.6s;
    transition-delay: 0.6s;
  }

  .index .s-service .inner .service__body .service__list .item.is-shown .head .service__headline .en {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .index .s-service .inner .service__body .service__list .item.is-shown .head .s-title .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .index .s-service .inner .service__body .service__list .item .head {
    position: relative;
    width: 356px;
    padding-top: 8px;
  }

  .index .s-service .inner .service__body .service__list .item .head .service__headline {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    overflow: hidden;
  }

  .index .s-service .inner .service__body .service__list .item .head .service__headline .num {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    position: relative;
    display: inline-block;
    font-size: 12px;
    color: #959595;
    letter-spacing: 0.1em;
    width: 20px;
    z-index: 2;
    overflow: hidden;
    background-color: #fcfcfc;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }

  .index .s-service .inner .service__body .service__list .item .head .service__headline .line {
    z-index: 1;
    background-color: #959595;
    content: "";
    height: 1px;
    width: 100%;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -1px;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition-duration: 1.8s;
    transition-duration: 1.8s;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }

  .index .s-service .inner .service__body .service__list .item .head .service__headline .en {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 12px;
    color: #959595;
    letter-spacing: 0.1em;
    z-index: 2;
    overflow: hidden;
    background-color: #fcfcfc;
    text-align: right;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }

  .index .s-service .inner .service__body .service__list .item .head .service__headline .en.media {
    width: 50px;
  }

  .index .s-service .inner .service__body .service__list .item .head .service__headline .en.service {
    width: 64px;
  }

  .index .s-service .inner .service__body .service__list .item .head .s-title {
    position: relative;
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    font-size: 28px;
    letter-spacing: 0.05em;
    margin-top: 28px;
  }

  .index .s-service .inner .service__body .service__list .item .head .s-title .cover {
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    background-color: #fcfcfc;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 3s;
    transition-duration: 3s;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: right;
    transform-origin: right;
  }

  .index .s-service .inner .service__body .service__list .item .head .text {
    font-size: 13px;
    line-height: 2.1538;
    margin-bottom: 12.5px;
    margin-top: 52.5px;
    letter-spacing: 0.03em;
  }

  .index .s-service .inner .service__body .service__list .item .head .text-link {
    -webkit-transition: 0.6s opacity;
    transition: 0.6s opacity;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
    -webkit-transition-delay: 0.6s;
    transition-delay: 0.6s;
  }

  .index .s-service .inner .service__body .service__list .item .head .text-link.is-shown,
  .index .s-service .inner .service__body .service__list .item.is-shown .body {
    opacity: 1;
  }

  .index .s-service .inner .service__body .service__list .item .head .text-link .text {
    font-size: 16px;
  }

  .index .s-service .inner .service__body .service__list .item .body {
    position: relative;
    max-width: 512px;
    /* padding-top: 58px; */
    /* margin-left: 3.33333%; */
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    overflow: hidden;
  }

  .index .s-service .inner .service__body .service__list .item .body .thumb {
    position: relative;
    display: block;
    overflow: hidden;
    /* width: 100%;
        height: auto; */
    width: 335px;
    height: 300px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .index .s-service .inner .service__body .service__list .item .body .thumb .cover {
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    background-color: #fcfcfc;
    z-index: 1;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 2s;
    transition-duration: 2s;
    -webkit-transform-origin: right;
    transform-origin: right;
    overflow: hidden;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  .index .s-service .inner .service__body .service__list .item .body .thumb .img {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    -webkit-transition: 1.6s -webkit-transform, -webkit-filter 3s;
    transition: 1.6s -webkit-transform, -webkit-filter 3s;
    transition: 1.6s transform, filter 3s;
    transition: 1.6s transform, filter 3s, 1.6s -webkit-transform,
      -webkit-filter 3s;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow: hidden;
    -webkit-filter: brightness(200%);
    filter: brightness(200%);
    will-change: filter, transform;
  }

  .index .s-service .inner .service__body .service__list .item.is-shown .body .thumb .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .index .s-service .inner .service__body .service__list .item.is-shown .body .thumb .img {
    -webkit-filter: brightness(100%);
    filter: brightness(100%);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  .index .s-csr {
    position: relative;
    display: block;
    padding: 0 0 154px;
    z-index: 1;
    background-color: #fff;
  }

  .index .s-csr .inner .csr__intoro {
    position: relative;
    display: block;
    max-width: 486px;
    margin-left: auto;
    margin-right: auto;
  }

  .index .s-csr .inner .csr__intoro .p-headline {
    text-align: center;
  }

  .index .s-csr .inner .csr__body {
    margin-top: 80px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .index .s-csr .inner .csr__body .item {
    width: 30.625%;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-property: opacity transform;
    transition-property: opacity transform;
  }

  .index .s-csr .inner .csr__body .item.is-shown {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .index .s-csr .inner .csr__body .item .link,
  .index .s-csr .inner .csr__body .item .link .thumb {
    position: relative;
    display: block;
  }

  .index .s-csr .inner .csr__body .item .link .thumb .img {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
  }

  .index .s-csr .inner .csr__body .item .link .csr__text {
    font-size: 13px;
    line-height: 2.307;
    margin-bottom: 11.5px;
    margin-top: 15.5px;
    letter-spacing: 0.03em;
  }

  .index .s-csr .inner .csr__body .item .text-link {
    width: 148px;
    -webkit-transition: 0.6s opacity;
    transition: 0.6s opacity;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
  }

  .index .s-csr .inner .csr__body .item .text-link.is-shown {
    opacity: 1;
  }

  .index .s-csr .inner .csr__body .item .text-link:hover .text {
    color: #151515;
  }

  .index .s-csr .inner .csr__body .item .text-link .text {
    font-size: 16px;
    letter-spacing: 0.05em;
    color: $red;
    font-weight: 700;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }

  .index .article-title {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    padding-top: 60%;
    text-align: center;
    color: #fff;
    background-color: #2424249c;
  }

  .index .board_article-title {
    position: absolute;
    bottom: 161px;
    width: 100%;
    padding: 10px;
    color: #fff;
    background-color: rgba(255, 255, 255, 0.2);
  }

  .index .inner .solution__content .thumb-wrapper.is-shown .thumb_red .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .index .inner .solution__content .thumb-wrapper.is-shown .thumb_red .img {
    -webkit-filter: brightness(100%);
    filter: brightness(100%);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  .index .inner .solution__content .thumb-wrapper .thumb_red {
    max-width: 872px;
    margin-left: auto;
    /* margin-left: 10px; */
    padding-right: 32px;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    z-index: -1000;
    position: absolute;
    top: 500px;
    left: -62px;
  }

  .index .inner .solution__content .thumb-wrapper .thumb_red .cover {
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    background-color: #fff;
    z-index: 1;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 2s;
    transition-duration: 2s;
    -webkit-transform-origin: right center;
    transform-origin: right center;
    overflow: hidden;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
  }

  .index .inner .solution__content .thumb-wrapper .thumb_red .black_box {
    position: relative;
    display: block;
    width: 27.5vw;
    height: 45vh;
    background-color: red;
    /* width: 100%;
        height: 100%; */
    -webkit-transition: 1.6s -webkit-transform, -webkit-filter 3s;
    transition: 1.6s -webkit-transform, -webkit-filter 3s;
    transition: 1.6s transform, filter 3s;
    transition: 1.6s transform, filter 3s, 1.6s -webkit-transform,
      -webkit-filter 3s;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow: hidden;
    -webkit-filter: brightness(200%);
    filter: brightness(200%);
    will-change: filter, transform;
  }
}

@media screen and (max-width: 1024px) {
  .index .s-news {
    position: relative;
    display: block;
    background-color: #fcfcfc;
    z-index: 1;
  }

  .index .s-news .news__content {
    position: relative;
    display: block;
    width: 100%;
    padding: 0 20px;
  }

  .index .s-news .news__content.is-shown .news__head .p-headline .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .index .s-news .news__content.is-shown .news__body .news__list {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }

  .index .s-news .news__content .news__head {
    position: relative;
    text-align: center;
    width: 100px;
  }

  .index .s-news .news__content .news__head .p-headline {
    position: relative;
    /* background-color: #fff; */
    padding: 30px 0 0;
  }

  .index .s-news .news__content .news__head .p-headline .text {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    font-size: 18px;
    letter-spacing: 0.1em;
  }

  .index .s-news .news__content .news__head .p-headline .cover {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    /* background-color: #fff; */
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 3s;
    transition-duration: 3s;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: right;
    transform-origin: right;
  }

  .index .s-news .news__content .news__body {
    position: relative;
    padding: 36px 18px 44px;
    /* background-color: #fff */
  }

  .index .s-news .news__content .news__body .news__list {
    position: relative;
    display: block;
    opacity: 0;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-duration: 1.4s;
    transition-duration: 1.4s;
    -webkit-transition-property: opacity transform;
    transition-property: opacity transform;
    -webkit-transition-delay: 0.6s;
    transition-delay: 0.6s;
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }

  .index .s-news .news__content .news__body .news__list .item {
    position: relative;
    display: block;
    border-bottom: 1px solid #000;
    margin-top: 21px;
    // padding-bottom: 13px
  }

  .index .s-news .news__content .news__body .news__list .item .head {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    text-align: left;
    line-height: 1.4;
  }

  .index .s-news .news__content .news__body .news__list .item .head .date {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    width: 68px;
    font-size: 11px;
    letter-spacing: 0.05em;
  }

  .index .s-news .news__content .news__body .news__list .item .head .tag {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    font-size: 11px;
    letter-spacing: 0.05em;
  }

  .index .s-news .news__content .news__body .news__list .item .link,
  .index .s-news .news__content .news__body .news__list .item .text {
    display: block;
    letter-spacing: 0.03em;
    font-size: 12px;
    line-height: 1.6666;
    margin-bottom: -4px;
    margin-top: 13px;
    font-weight: 500;
    color: #303030;
  }

  .index .s-news .news__content .news__body .p-btn {
    margin: 40px auto 0;
  }

  .index .s-about {
    position: relative;
    padding: 45px 0 0;
    background-color: #fcfcfc;
  }

  .index .s-about .inner {
    position: relative;
    display: block;
    padding: 0;
  }

  .index .s-about .inner .about__head {
    position: relative;
    display: block;
    width: 100%;
    max-width: 375px;
    height: 246px;
    margin: 0 auto;
    -webkit-transition-property: opacity;
    transition-property: opacity;
    -webkit-transition-duration: 0.8s;
    transition-duration: 0.8s;
    opacity: 0;
  }

  .index .s-about .inner .about__head.is-shown {
    opacity: 1;
  }

  .index .s-about .inner .about__head .thumb__men {
    position: absolute;
    top: 0;
    right: 0;
    width: 188px;
    height: 100%;
    z-index: 1;
  }

  .index .s-about .inner .about__head .thumb__men .img {
    display: block;
  }

  .index .s-about .inner .about__head .thumb__men .men-blue {
    position: absolute;
    top: 0;
    right: 40px;
    z-index: 1;
    max-width: 120px;
  }

  .index .s-about .inner .about__head .thumb__men .men-red {
    position: absolute;
    top: 26px;
    left: -30px;
    z-index: 2;
    mix-blend-mode: multiply;
    max-width: 148px;
  }

  .index .s-about .inner .about__head .thumb__men .men-red2 {
    position: absolute;
    top: 110px;
    left: -150px;
    z-index: 2;
    mix-blend-mode: multiply;
    max-width: 148px;
  }

  .index .s-about .inner .about__head .thumb__women {
    position: absolute;
    top: 14px;
    left: 0;
    width: 206px;
    height: 100%;
    z-index: 2;
  }

  .index .s-about .inner .about__head .thumb__women .img {
    display: block;
  }

  .index .s-about .inner .about__head .thumb__women .women-blue {
    position: absolute;
    top: 18px;
    right: 0;
    z-index: 2;
    mix-blend-mode: multiply;
    max-width: 165px;
  }

  .index .s-about .inner .about__head .thumb__women .women-red {
    position: absolute;
    top: 2px;
    left: 0;
    z-index: 1;
    max-width: 159px;
  }

  .index .s-about .inner .about__body {
    position: relative;
    width: 100%;
    padding: 28px 38px 0;
  }

  .index .s-about .inner .about__body .p-headline {
    position: relative;
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    font-size: 18px;
    letter-spacing: 0.1em;
  }

  .index .s-about .inner .about__body .p-headline.is-shown .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .index .s-about .inner .about__body .p-headline .cover {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #fcfcfc;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 3s;
    transition-duration: 3s;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: right;
    transform-origin: right;
  }

  .index .s-about .inner .about__body .s-title {
    font-size: 28px;
    line-height: 1.75;
    margin-bottom: -10.5px;
    margin-top: 29.5px;
    font-weight: 500;
    letter-spacing: 0.025em;
  }

  .index .s-about .inner .about__body .text {
    font-size: 12px;
    line-height: 2;
    margin-bottom: 34px;
    margin-top: 16px;
    letter-spacing: 0.03em;
  }

  .index .s-solution {
    position: relative;
    padding: 90px 0 0;
    background-color: #fcfcfc;
  }

  .index .s-solution .inner {
    padding: 0;
  }

  .index .s-solution .inner .solution__content {
    position: relative;
    width: 100%;
  }

  .index .s-solution .inner .solution__content .thumb-wrapper {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
  }

  .index .s-solution .inner .solution__content .thumb-wrapper .thumb,
  .index .s-solution .inner .solution__content .thumb-wrapper .thumb .img {
    display: block;
    width: 100%;
    height: 100%;
  }

  .index .s-solution .inner .solution__content .solution__body {
    position: relative;
    background-color: #fcfcfc;
    padding: 32px 18px 0;
    width: calc(100% - 40px);
    margin: -40px auto 0;
  }

  .index .s-solution .inner .solution__content .solution__body .p-headline {
    text-align: center;
  }

  .index .s-solution .inner .solution__content .solution__body.is-shown .p-headline .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .index .s-solution .inner .solution__content .solution__body .cover {
    background-color: #fff;
  }

  .index .s-solution .inner .solution__content .solution__body>.text {
    font-size: 12px;
    line-height: 2;
    margin-bottom: 34px;
    margin-top: 29px;
    letter-spacing: 0.03em;
  }

  .index .s-solution .inner .solution__content .solution__body .p-btn {
    margin: 0 auto;
  }

  /* add new layout */
  .index .s-solution .inner .solution__content .thumb-wrapper .thumb1,
  .index .s-solution .inner .solution__content .thumb-wrapper .thumb1 .black_box {
    display: block;
    width: 100%;
    height: 25vh;
    background-color: #000;
  }

  .index .s-solution .inner .solution__content .solution__body1 {
    position: relative;
    background-color: #f8f8f8;
    padding: 32px 18px 0;
    width: calc(100% - 40px);
    margin: -40px auto 0;
  }

  .index .s-solution .inner .solution__content .solution__body1 .p-headline {
    text-align: center;
  }

  .index .s-solution .inner .solution__content .solution__body1.is-shown .p-headline .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .index .s-solution .inner .solution__content .solution__body1 .cover {
    background-color: #f8f8f8;
  }

  .index .s-solution .inner .solution__content .solution__body1>.text {
    font-size: 12px;
    line-height: 2;
    margin-bottom: 34px;
    margin-top: 29px;
    letter-spacing: 0.03em;
  }

  .index .s-solution .inner .solution__content .solution__body1 .p-btn {
    margin: 0 auto;
  }

  .index .s-service {
    background-color: #fcfcfc;
    padding: 0 0 56px;
  }

  .index .s-service .inner .service__head {
    position: relative;
    width: 100%;
    padding: 90px 18px 0;
    /* background-color: #fcfcfc */
  }

  .index .s-service .inner .service__head .p-headline {
    text-align: center;
  }

  .index .s-service .inner .service__head .p-headline .cover {
    background-color: #fff;
  }

  .index .s-service .inner .service__head .text {
    font-size: 12px;
    line-height: 2;
    margin-bottom: -6px;
    margin-top: -6px;
    letter-spacing: 0.03em;
    padding: 28px 0;
  }

  .index .s-service .inner .service__body {
    padding: 1px 0 60px;
    position: relative;
    display: block;
    width: 100%;
    background-color: #fff;
  }

  .index .s-service .inner .service__body .service__list {
    position: relative;
    display: block;
  }

  .index .s-service .inner .service__body .service__list .item {
    position: relative;
    width: 100%;
    margin-top: 46px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-property: opacity transform;
    transition-property: opacity transform;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }

  .index .s-service .inner .service__body .service__list .item>.link {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  .index .s-service .inner .service__body .service__list .item:first-child {
    margin-top: 0;
  }

  .index .s-service .inner .service__body .service__list .item:last-child .body .thumb .service__headline .line,
  .index .s-service .inner .service__body .service__list .item:nth-child(2) .body .thumb .service__headline .line,
  .index .s-service .inner .service__body .service__list .item:nth-child(3) .body .thumb .service__headline .line {
    height: 110px;
  }

  .index .s-service .inner .service__body .service__list .item.is-shown {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .index .s-service .inner .service__body .service__list .item .head {
    position: relative;
    padding: 0 18px;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .index .s-service .inner .service__body .service__list .item .head .s-title {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    font-size: 18px;
    letter-spacing: 0.05em;
    margin-top: 20px;
  }

  .index .s-service .inner .service__body .service__list .item .head .text {
    font-size: 12px;
    line-height: 2;
    margin-bottom: 16px;
    margin-top: 17px;
    letter-spacing: 0.03em;
  }

  .index .s-service .inner .service__body .service__list .item .head .text-link {
    position: absolute;
    right: -10px;
    bottom: -18px;
  }

  .index .s-service .inner .service__body .service__list .item .body {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    position: relative;
    padding: 0 18px;
  }

  .index .s-service .inner .service__body .service__list .item .body .thumb {
    position: relative;
    display: block;
  }

  .index .s-service .inner .service__body .service__list .item .body .thumb .service__headline {
    position: absolute;
    top: 0;
    left: -25px;
    height: 168px;
    width: 20px;
  }

  .index .s-service .inner .service__body .service__list .item .body .thumb .service__headline .num {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    position: absolute;
    top: 0;
    left: -2px;
    font-size: 11px;
    color: #959595;
    letter-spacing: 0.1em;
    z-index: 2;
    height: 16px;
  }

  .index .s-service .inner .service__body .service__list .item .body .thumb .service__headline .line {
    position: absolute;
    top: 16px;
    left: 25%;
    margin-left: -1px;
    z-index: 1;
    background-color: #000;
    content: "";
    height: 124px;
    width: 1px;
  }

  .index .s-service .inner .service__body .service__list .item .body .thumb .service__headline .en {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    position: absolute;
    bottom: 0;
    left: -16px;
    font-size: 11px;
    color: #959595;
    letter-spacing: 0.1em;
    z-index: 2;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  .index .s-service .inner .service__body .service__list .item .body .thumb .service__headline .en.media {
    width: 44px;
    text-align: right;
  }

  .index .s-service .inner .service__body .service__list .item .body .thumb .service__headline .en.service {
    width: 55px;
    left: -22px;
    bottom: 8px;
    text-align: right;
  }

  .index .s-service .inner .service__body .service__list .item .body .thumb .img {
    display: block;
    width: 100%;
    height: 100%;
  }

  .index .s-csr {
    padding-bottom: 116px;
    padding-top: 100px;
  }

  .index .s-csr .inner {
    padding: 0 38px;
  }

  .index .s-csr .csr__body {
    width: 100%;
  }

  .index .s-csr .csr__body .csr__list .item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    margin-top: 116px;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-property: opacity transform;
    transition-property: opacity transform;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }

  .index .s-csr .csr__body .csr__list .item.is-shown {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .index .s-csr .csr__body .csr__list .item .head {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    position: relative;
    width: 100%;
  }

  .index .s-csr .csr__body .csr__list .item:first-child {
    margin-top: 0;
  }

  .index .s-csr .csr__body .csr__list .item .head .s-title {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    margin-top: 10px;
  }

  .index .s-csr .csr__body .csr__list .item .head .s-title .read {
    font-size: 18px;
    line-height: 1;
    margin-bottom: 0;
    margin-top: 12px;
    letter-spacing: 0.1em;
    display: block;
    text-align: center;
  }

  .index .s-csr .csr__body .csr__list .item .head .s-title .num {
    color: #b2b2b2;
    letter-spacing: 0.1em;
    font-size: 10px;
    display: block;
    text-align: center;
  }

  .index .s-csr .csr__body .csr__list .item .head .s-title>.text {
    font-size: 28px;
    line-height: 1.6;
    margin-bottom: -8.4px;
    margin-top: 29.6px;
    letter-spacing: 0.025em;
    display: block;
  }

  .index .s-csr .csr__body .csr__list .item .head>.text {
    font-size: 12px;
    line-height: 2;
    margin-bottom: 9px;
    margin-top: 14px;
    letter-spacing: 0.03em;
  }

  .index .s-csr .csr__body .csr__list .item .head .text-link {
    position: absolute;
    right: -32px;
    bottom: -18px;
  }

  .index .s-csr .csr__body .csr__list .item .head .text-link .text {
    color: $red;
  }

  .index .s-csr .csr__body .csr__list .item .body {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    position: relative;
    width: 100%;
  }

  .index .s-csr .csr__body .csr__list .item .body .thumb {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
  }

  .index .s-csr .csr__body .csr__list .item .body .thumb .img {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    line-height: 0;
    margin: 0;
    border: 0;
  }

  .index .s-csr .csr__body .csr__list .item.is-shown .body {
    opacity: 1;
  }

  .index .csr__intoro {
    text-align: center;
    padding: 30px;
  }

  .index .article-title {
    font-size: 18px;
    padding: 20px;
  }
}

@include desktop {

  .about .s-mission,
  .about .s-vision {
    z-index: 1;
    position: relative;
    display: block;
  }

  .about .s-vision,
  .about .s-vision .vision__head .p-headline .cover {
    background-color: #fcfcfc;
  }

  .about .l-kv__bg .bg {
    background-image: url(/wp-content/themes/GDI/assets/images/pc/about/About_kv.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  .about .js-section {
    opacity: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    will-change: opacity;
  }

  .about .s-vision {
    padding: 116px 0 76px;
  }

  .about .s-vision .vision__head {
    position: relative;
    display: block;
    max-width: 486px;
    margin-left: auto;
    margin-right: auto;
  }

  .about .s-vision .vision__head .p-headline {
    text-align: center;
    opacity: 1;
  }

  .about .s-vision .vision__body {
    position: relative;
    display: block;
    margin-top: 76px;
    text-align: center;
  }

  .about .s-vision .vision__body .text {
    font-size: 15px;
    line-height: 2.1333;
    margin-bottom: -8.5px;
    margin-top: 37.5px;
    letter-spacing: 0.03em;
  }

  .about .s-vision .vision__body .text:first-child {
    margin-top: 0;
  }

  .about .s-explores .inner .explores__body .text,
  .about .s-mission .inner .mission__body .text {
    margin-bottom: -8.5px;
    margin-top: 45.5px;
    font-size: 15px;
    line-height: 2.1333;
    letter-spacing: 0.03em;
  }

  .about .s-mission {
    padding: 80px 0 284px;
    background-color: #fff;
  }

  .about .s-mission .inner {
    position: relative;
    overflow: visible;
  }

  .about .s-mission .inner .mission__body {
    position: relative;
    width: 46.89%;
    margin-left: auto;
    padding-top: 36px;
  }

  .about .s-mission .inner .mission__body .p-headline .cover {
    background-color: #fff;
  }

  .about .s-mission .inner .mission__body .text:first-of-type {
    margin-top: 70px;
  }

  .about .s-mission .inner .mission__thumb {
    position: absolute;
    display: block;
    top: 0;
    left: -180px;
    width: 64.49%;
    height: auto;
    overflow: hidden;
  }

  .about .s-mission .inner .mission__thumb.is-shown .cover {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }

  .about .s-mission .inner .mission__thumb.is-shown .thumb .img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: brightness(100%);
    filter: brightness(100%);
  }

  .about .s-mission .inner .mission__thumb::before {
    display: block;
    content: "";
    padding-top: 100%;
  }

  .about .s-mission .inner .mission__thumb .thumb {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .about .s-mission .inner .mission__thumb .thumb .img {
    display: block;
    width: 100%;
    height: auto;
    -webkit-transition: 1.6s -webkit-transform, -webkit-filter 3s;
    transition: 1.6s -webkit-transform, -webkit-filter 3s;
    transition: 1.6s transform, filter 3s;
    transition: 1.6s transform, filter 3s, 1.6s -webkit-transform,
      -webkit-filter 3s;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-filter: brightness(200%);
    filter: brightness(200%);
  }

  .about .s-mission .inner .mission__thumb .cover {
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    background-color: #fff;
    z-index: 1;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 3s;
    transition-duration: 3s;
    -webkit-transform-origin: left top;
    transform-origin: left top;
    overflow: hidden;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }

  .about .s-explores {
    position: relative;
    display: block;
    padding: 104px 0;
    background-color: #fcfcfc;
    z-index: 1;
  }

  .about .s-explores .inner {
    position: relative;
    overflow: visible;
  }

  .about .s-explores .inner .explores__body {
    padding-top: 22px;
    position: relative;
    width: 46.89%;
    margin-right: auto;
  }

  .about .s-explores .inner .explores__body .p-headline .cover {
    background-color: #fcfcfc;
  }

  .about .s-explores .inner .explores__body .text:first-of-type {
    margin-top: 70px;
  }

  .about .s-explores .inner .explores__thumb {
    position: absolute;
    top: 0;
    right: -180px;
    display: block;
    width: 64.49%;
    height: auto;
    overflow: hidden;
  }

  .about .s-explores .inner .explores__thumb.is-shown .cover {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }

  .about .s-explores .inner .explores__thumb.is-shown .thumb .img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: brightness(100%);
    filter: brightness(100%);
  }

  .about .s-explores .inner .explores__thumb::before {
    content: "";
    display: block;
    padding-top: 100%;
  }

  .about .s-explores .inner .explores__thumb .cover {
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    background-color: #fcfcfc;
    z-index: 1;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 2s;
    transition-duration: 2s;
    -webkit-transform-origin: left top;
    transform-origin: left top;
    overflow: hidden;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }

  .about .s-explores .inner .explores__thumb .thumb {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow: hidden;
  }

  .about .s-explores .inner .explores__thumb .thumb .img {
    display: block;
    width: 100%;
    height: auto;
    -webkit-transition: 1.6s -webkit-transform, -webkit-filter 3s;
    transition: 1.6s -webkit-transform, -webkit-filter 3s;
    transition: 1.6s transform, filter 3s;
    transition: 1.6s transform, filter 3s, 1.6s -webkit-transform,
      -webkit-filter 3s;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-filter: brightness(200%);
    filter: brightness(200%);
  }

  .about .s-media,
  .about .s-service {
    position: relative;
    display: block;
    background-color: #fff;
  }

  .about .s-media .bg,
  .about .s-service .bg {
    position: absolute;
    top: 420px;
    width: 91.21523%;
    height: 640px;
    content: "";
    background-color: #fff;
    pointer-events: none;
  }

  .about .s-csr,
  .about .s-csr .inner .csr__intoro .p-headline .cover,
  .about .s-media .inner .intro .p-headline .cover,
  .about .s-service .inner .intro .p-headline .cover {
    background-color: #fcfcfc;
  }

  .about .s-media .inner,
  .about .s-service .inner {
    position: relative;
  }

  .about .s-media .inner .intro,
  .about .s-service .inner .intro {
    position: relative;
    display: block;
    max-width: 486px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 300px;
  }

  .about .s-media .inner .intro .p-headline,
  .about .s-service .inner .intro .p-headline {
    text-align: center;
  }

  .about .s-media .inner .body,
  .about .s-service .inner .body {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-top: 86px;
    /* padding-top: 286px */
  }

  .about .s-media .inner .body.is-shown .thumb__content .thumb,
  .about .s-service .inner .body.is-shown .thumb__content .thumb {
    opacity: 1;
  }

  .about .s-media .inner .body.is-shown .thumb__content .thumb .cover,
  .about .s-service .inner .body.is-shown .thumb__content .thumb .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .about .s-media .inner .body.is-shown .thumb__content .thumb .img,
  .about .s-service .inner .body.is-shown .thumb__content .thumb .img {
    -webkit-filter: brightness(100%);
    filter: brightness(100%);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  .about .s-media .inner .body .thumb__content,
  .about .s-service .inner .body .thumb__content {
    position: absolute;
    top: -225px;
    width: 75.88358%;
    height: 100%;
    z-index: 1;
    overflow: hidden;
  }

  .about .s-media .inner .body .thumb__content .thumb,
  .about .s-service .inner .body .thumb__content .thumb {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    max-width: 730px;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    opacity: 0;
    -webkit-transition-duration: 1.2s;
    transition-duration: 1.2s;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: opacity;
    transition-property: opacity;
  }

  .about .s-media .inner .body .thumb__content .thumb .cover,
  .about .s-service .inner .body .thumb__content .thumb .cover {
    display: none;
  }

  .about .s-media .inner .body .thumb__content .thumb .img,
  .about .s-service .inner .body .thumb__content .thumb .img {
    display: block;
    width: 100%;
    height: auto;
    -webkit-transition: 1.2s -webkit-transform, -webkit-filter 2s;
    transition: 1.2s -webkit-transform, -webkit-filter 2s;
    transition: 1.2s transform, filter 2s;
    transition: 1.2s transform, filter 2s, 1.2s -webkit-transform,
      -webkit-filter 2s;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow: hidden;
    -webkit-filter: brightness(200%);
    filter: brightness(200%);
    will-change: filter, transform;
  }

  .about .s-media .inner .body .text__content,
  .about .s-service .inner .body .text__content {
    /* width: 49.875%; */
    width: 100%;
    z-index: 2;
  }

  .about .s-media .inner .body .text__content>.text,
  .about .s-service .inner .body .text__content>.text {
    font-size: 15px;
    line-height: 2.1333;
    margin-bottom: -8.5px;
    margin-top: 45.5px;
    letter-spacing: 0.03em;
    color: #333;
  }

  .about .s-media .inner .body .text__content>.text:first-child,
  .about .s-service .inner .body .text__content>.text:first-child {
    margin-top: 0;
  }

  .about .s-media .inner .p-btn--b,
  .about .s-service .inner .p-btn--b {
    position: absolute;
    right: 0;
    left: 0;
    bottom: -120px;
    margin: 0 auto;
    z-index: 3;
  }

  .about .s-media {
    padding: 88px 0;
  }

  .about .s-media .bg {
    right: 0;
  }

  .about .s-media .thumb__content {
    left: 0;
  }

  .about .service--first {
    margin-top: 0;
    padding: 188px 0 90px;
  }

  .about .service--first .bg {
    top: 494px;
    left: 0;
  }

  .about .service--first .body .thumb__content {
    right: 0;
  }

  .about .service--first .inner .p-btn--b {
    bottom: -126px;
  }

  .about .service--second {
    padding: 386px 0 96px;
  }

  .about .service--second .bg {
    right: 0;
  }

  .about .service--second .inner .p-btn--b {
    bottom: -112px;
  }

  .about .service--second .inner .body .thumb__content {
    top: -216px;
    left: 0;
  }

  .about .service--last {
    padding: 360px 0 96px;
  }

  .about .service--last .bg {
    left: 0;
  }

  .about .service--last .inner .body .thumb__content {
    top: -216px;
    right: 0;
  }

  .about .service--last .inner .p-btn--b {
    bottom: -144px;
  }

  .about .s-csr {
    position: relative;
    display: block;
    padding: 130px 0 154px;
    z-index: 1;
  }

  .about .s-csr .inner .csr__intoro {
    position: relative;
    display: block;
    max-width: 486px;
    margin-left: auto;
    margin-right: auto;
  }

  .about .s-csr .inner .csr__intoro .p-headline {
    text-align: center;
  }

  .about .s-csr .inner .csr__body {
    margin-top: 80px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .about .s-csr .inner .csr__body .item {
    width: 30.625%;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-property: opacity transform;
    transition-property: opacity transform;
  }

  .about .s-csr .inner .csr__body .item.is-shown {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .about .s-csr .inner .csr__body .item .link,
  .about .s-csr .inner .csr__body .item .link .thumb {
    position: relative;
    display: block;
  }

  .about .s-csr .inner .csr__body .item .link .thumb .img {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
  }

  .about .s-csr .inner .csr__body .item .link .csr__text {
    font-size: 13px;
    line-height: 2.307;
    margin-bottom: 11.5px;
    margin-top: 15.5px;
    letter-spacing: 0.03em;
  }

  .about .s-csr .inner .csr__body .item .text-link {
    width: 148px;
    -webkit-transition: 0.6s opacity;
    transition: 0.6s opacity;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
  }

  .about .s-csr .inner .csr__body .item .text-link.is-shown {
    opacity: 1;
  }

  .about .s-csr .inner .csr__body .item .text-link:hover .text {
    color: #151515;
  }

  .about .s-csr .inner .csr__body .item .text-link .text {
    font-size: 16px;
    letter-spacing: 0.05em;
    color: $red;
    font-weight: 700;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }
}

@media screen and (max-width: 1024px) {

  .about .s-mission,
  .about .s-vision {
    z-index: 1;
    display: block;
    background-color: #fcfcfc;
  }

  .about .s-explores .inner,
  .about .s-mission .inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    position: relative;
  }

  .about .s-explores,
  .about .s-explores .inner .explores__body .p-headline .cover,
  .about .s-media,
  .about .s-media .inner .body,
  .about .s-media .inner .intro,
  .about .s-media .inner .intro .p-headline .cover,
  .about .s-mission,
  .about .s-mission .inner .mission__body,
  .about .s-mission .inner .mission__body .p-headline .cover,
  .about .s-service,
  .about .s-service .bg,
  .about .s-service .inner .intro .p-headline .cover,
  .about .s-vision,
  .about .s-vision .vision__head .p-headline .cover {
    background-color: #fcfcfc;
  }

  .about .l-kv__bg .bg {
    background-image: url(/wp-content/themes/GDI/assets/images/pc/about/About_kv.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  .about .js-section {
    opacity: 0;
    will-change: opacity;
  }

  .about .s-vision {
    position: relative;
    padding: 88px 0 0;
  }

  .about .s-vision .vision__head {
    position: relative;
    display: block;
  }

  .about .s-vision .vision__head .p-headline {
    text-align: center;
    opacity: 1;
  }

  .about .s-vision .vision__body {
    position: relative;
    display: block;
    margin-top: 32px;
    text-align: center;
  }

  .about .s-vision .vision__body .text {
    font-size: 12px;
    line-height: 2;
    margin-bottom: -6px;
    margin-top: 30px;
    letter-spacing: 0.03em;
  }

  .about .s-vision .vision__body .text:first-child {
    margin-top: 0;
  }

  .about .s-explores .inner .explores__body .text,
  .about .s-media .inner .body .text__content>.text,
  .about .s-mission .inner .mission__body .text {
    line-height: 2;
    margin-bottom: -6px;
    margin-top: 30px;
    font-size: 12px;
    letter-spacing: 0.03em;
  }

  .about .s-mission {
    position: relative;
  }

  .about .s-mission .inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0 20px;
  }

  .about .s-mission .inner .mission__body {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    position: relative;
    width: 100%;
    padding: 24px 18px 40px;
  }

  .about .s-mission .inner .mission__body .p-headline {
    text-align: center;
  }

  .about .s-mission .inner .mission__thumb {
    display: block;
    width: 100%;
    height: 100%;
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    padding: 64px 18px 0;
    overflow: visible;
  }

  .about .s-mission .inner .mission__thumb .thumb {
    position: relative;
    display: block;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

  .about .s-mission .inner .mission__thumb .thumb .img {
    display: block;
    width: 100%;
    height: auto;
  }

  .about .s-explores {
    position: relative;
    display: block;
    padding: 0 0 44px;
    z-index: 1;
  }

  .about .s-explores .inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .about .s-explores .inner .explores__body {
    position: relative;
    width: 100%;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    padding: 24px 18px 0;
  }

  .about .s-explores .inner .explores__body .p-headline {
    text-align: center;
  }

  .about .s-explores .inner .explores__thumb {
    display: block;
    width: 100%;
    height: 100%;
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    padding: 64px 18px 0;
    overflow: visible;
  }

  .about .s-explores .inner .explores__thumb .thumb {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
  }

  .about .s-explores .inner .explores__thumb .thumb .img {
    display: block;
    width: 100%;
    height: auto;
  }

  .about .s-media {
    position: relative;
    display: block;
    z-index: 1;
  }

  .about .s-media .bg {
    display: none;
  }

  .about .s-media .inner {
    position: relative;
    padding-bottom: 21px;
  }

  .about .s-media .inner .intro {
    padding: 44px 0 36px;
    position: relative;
    display: block;
  }

  .about .s-media .inner .intro .p-headline {
    text-align: center;
  }

  .about .s-media .inner .body {
    position: relative;
    display: block;
    padding: 0 18px 62px;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: opacity, transform;
    transition-property: opacity, transform, -webkit-transform;
  }

  .about .s-media .inner .body.is-shown {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .about .s-media .inner .body .text__content {
    position: relative;
    width: 100%;
  }

  .about .s-media .inner .body .text__content>.text {
    color: #333;
  }

  .about .s-media .inner .body .thumb__content {
    position: relative;
    display: block;
    width: 100%;
  }

  .about .s-media .inner .body .thumb__content .thumb {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
  }

  .about .s-media .inner .body .thumb__content .thumb .img {
    display: block;
    width: 100%;
    height: auto;
  }

  .about .s-media .inner .body .p-btn--b {
    position: absolute;
    bottom: -20px;
    right: 0;
    left: 0;
    margin: 0 auto;
    width: 147px;
  }

  .about .s-media .inner .body .p-btn--b .link {
    line-height: 46px;
  }

  .about .s-media .inner .body .p-btn--b .text {
    line-height: 46px;
    font-size: 12px;
  }

  .about .s-service {
    position: relative;
    display: block;
    padding: 42px 0 0;
    z-index: 1;
  }

  .about .s-service.service--first {
    padding: 88px 0 31px;
  }

  .about .s-service.service--second {
    padding: 42px 0 31px;
  }

  .about .s-service.service--last {
    padding: 0;
  }

  .about .s-service .bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    display: block;
    z-index: -1;
  }

  .about .s-service .inner .intro {
    position: relative;
    display: block;
  }

  .about .s-service .inner .intro .p-headline {
    position: relative;
    text-align: center;
  }

  .about .s-service .inner .body {
    position: relative;
    display: block;
    margin-top: 126px;
    padding: 133px 18px 62px;
    background-color: #fff;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-property: opacity transform;
    transition-property: opacity transform;
  }

  .about .s-csr,
  .about .s-csr .inner .csr__intoro .p-headline .cover {
    background-color: #fcfcfc;
  }

  .about .s-service .inner .body.is-shown {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .about .s-service .inner .body .text__content {
    position: relative;
    width: 100%;
  }

  .about .s-service .inner .body .text__content>.text {
    font-size: 12px;
    line-height: 2;
    margin-bottom: -6px;
    margin-top: 30px;
    letter-spacing: 0.03em;
    color: #333;
  }

  .about .s-service .inner .body .text__content>.text:first-child {
    margin-top: 0;
  }

  .about .s-service .inner .body .thumb__content {
    position: absolute;
    top: -88px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    margin: 0 auto;
    display: block;
    width: 297px;
  }

  .about .s-service .inner .body .thumb__content .thumb {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
  }

  .about .s-service .inner .body .thumb__content .thumb .img {
    display: block;
    width: 100%;
    height: auto;
  }

  .about .s-service .inner .body .p-btn--b {
    position: absolute;
    bottom: -20px;
    right: 0;
    left: 0;
    margin: 0 auto;
    width: 147px;
  }

  .about .s-service .inner .body .p-btn--b .link {
    line-height: 46px;
  }

  .about .s-service .inner .body .p-btn--b .text {
    line-height: 46px;
    font-size: 12px;
  }

  .about .s-csr {
    position: relative;
    display: block;
    padding: 80px 0 112px;
    z-index: 1;
  }

  .about .s-csr .inner .csr__intoro {
    position: relative;
    display: block;
  }

  .about .s-csr .inner .csr__intoro .p-headline {
    text-align: center;
  }

  .about .s-csr .inner .csr__body {
    margin-top: 34px;
    padding: 0 18px;
  }

  .about .s-csr .inner .csr__body .item {
    position: relative;
    width: 100%;
    margin-top: 84px;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: opacity, transform;
    transition-property: opacity, transform, -webkit-transform;
  }

  .about .s-csr .inner .csr__body .item.is-shown {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .about .s-csr .inner .csr__body .item:first-child {
    margin-top: 0;
  }

  .about .s-csr .inner .csr__body .item>.link,
  .about .s-csr .inner .csr__body .item>.link .thumb {
    position: relative;
    display: block;
  }

  .about .s-csr .inner .csr__body .item>.link .thumb .img {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
  }

  .about .s-csr .inner .csr__body .item>.link .csr__text {
    font-size: 12px;
    line-height: 2;
    margin-bottom: -6px;
    margin-top: 30px;
    letter-spacing: 0.03em;
  }

  .about .s-csr .inner .csr__body .item .text-link {
    position: absolute;
    right: -8px;
    bottom: -26px;
  }

  .about .s-csr .inner .csr__body .item .text-link .arrow {
    right: -36px;
    margin-top: 1px;
  }

  .about .p-inner .head {
    padding: 0 18px;
  }
}

@include desktop {
  /* .solution .s-intoro,
    .solution .s-intoro .body,
    .solution .s-intoro .head,
    .solution .s-solution .inner .solution__body,
    .solution .s-solution .inner .solution__body .solution__list {
        display: block;
        position: relative
    }

    .solution .l-kv .l-kv__read .copy {
        max-width: 296px
    }

    .solution .l-kv__bg .bg {
        background-image: url(/wp-content/themes/GDI/assets/images/pc/solution/Solutions_kv.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%
    }

    .solution .js-section {
        opacity: 0;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        will-change: opacity
    }

    .solution .s-intoro {
        padding: 116px 0 180px;
        background-color: #fcfcfc;
        z-index: 1
    }

    .solution .s-intoro .head {
        max-width: 486px;
        margin-left: auto;
        margin-right: auto
    }

    .solution .s-intoro .head .p-headline {
        text-align: center;
        opacity: 1
    }

    .solution .s-intoro .head .p-headline .cover {
        background-color: #fcfcfc
    }

    .solution .s-intoro .body {
        max-width: 724px;
        margin: 76px auto 0
    }

    .solution .s-intoro .body .text {
        font-size: 15px;
        line-height: 2.1333;
        margin-bottom: -8.5px;
        margin-top: 37.5px;
        letter-spacing: .03em
    }

    .solution .s-intoro .body .text:first-child {
        margin-top: 0
    }

    .solution .s-solution {
        background-color: #fcfcfc;
        padding-bottom: 180px
    }

    .solution .s-solution .inner .solution__body {
        width: 100%
    }

    .solution .s-solution .inner .solution__body .solution__list .item {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        width: 100%;
        margin-top: 176px
    }

    .solution .s-solution .inner .solution__body .solution__list .item.is-shown .head {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }

    .solution .s-solution .inner .solution__body .solution__list .item.is-shown .head .s-title .num {
        -webkit-transition-delay: .2s;
        transition-delay: .2s;
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }

    .solution .s-solution .inner .solution__body .solution__list .item.is-shown .body .thumb .cover,
    .solution .s-solution .inner .solution__body .solution__list .item.is-shown .head .s-title .read .cover {
        -webkit-transform: scaleX(0);
        transform: scaleX(0)
    }

    .solution .s-solution .inner .solution__body .solution__list .item.is-shown .body .thumb .img {
        -webkit-filter: brightness(100%);
        filter: brightness(100%);
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1)
    }

    .solution .s-solution .inner .solution__body .solution__list .item:first-child {
        margin-top: 0
    }

    .solution .s-solution .inner .solution__body .solution__list .item:nth-child(odd) .head {
        -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
        order: 0;
        padding-left: 8.57633%;
        margin-right: 2.74443%
    }

    .solution .s-solution .inner .solution__body .solution__list .item:nth-child(odd) .body {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
        margin-left: 2.74443%
    }

    .solution .s-solution .inner .solution__body .solution__list .item:nth-child(even) .head {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
        padding-right: 8.57633%;
        margin-left: 2.74443%
    }

    .solution .s-solution .inner .solution__body .solution__list .item:nth-child(even) .body {
        -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
        order: 0;
        margin-right: 2.74443%
    }

    .solution .s-solution .inner .solution__body .solution__list .item .head {
        position: relative;
        width: 550px
    }

    .solution .s-solution .inner .solution__body .solution__list .item .head .s-title {
        font-family: NotoSansCJKjp, sans-serif;
        font-weight: 500;
        -webkit-font-feature-settings: "palt"1;
        font-feature-settings: "palt"1;
        margin-top: 20px;
        overflow: hidden
    }

    .solution .s-solution .inner .solution__body .solution__list .item .head .s-title .read {
        position: relative;
        font-size: 24px;
        line-height: 1;
        margin-bottom: 0;
        margin-top: 10px;
        letter-spacing: .1em;
        display: block
    }

    .solution .s-solution .inner .solution__body .solution__list .item .head .s-title .read .cover {
        position: absolute;
        top: -5px;
        right: -5px;
        bottom: -5px;
        left: -5px;
        background-color: #fcfcfc;
        -webkit-transition-timing-function: cubic-bezier(.19, 1, .22, 1);
        transition-timing-function: cubic-bezier(.19, 1, .22, 1);
        -webkit-transition-property: -webkit-transform;
        transition-property: -webkit-transform;
        transition-property: transform;
        transition-property: transform, -webkit-transform;
        -webkit-transition-duration: 3s;
        transition-duration: 3s;
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        -webkit-transform-origin: right;
        transform-origin: right;
        content: ""
    }

    .solution .s-solution .inner .solution__body .solution__list .item .head .s-title .num {
        color: #b2b2b2;
        letter-spacing: .1em;
        font-size: 12px;
        display: block;
        overflow: hidden;
        -webkit-transform: translateY(-40px);
        transform: translateY(-40px);
        -webkit-transition-duration: 1.6s;
        transition-duration: 1.6s;
        -webkit-transition-timing-function: cubic-bezier(.19, 1, .22, 1);
        transition-timing-function: cubic-bezier(.19, 1, .22, 1)
    }

    .solution .s-solution .inner .solution__body .solution__list .item .head .s-title>.text {
        font-size: 30px;
        line-height: 1.6;
        margin-bottom: -9px;
        margin-top: 43px;
        letter-spacing: .025em;
        display: block
    }

    .solution .s-solution .inner .solution__body .solution__list .item .head>.text {
        font-size: 15px;
        line-height: 2.1538;
        margin-bottom: 21.35px;
        margin-top: 31.35px;
        letter-spacing: .03em
    }

    .solution .s-solution .inner .solution__body .solution__list .item .head .text-link {
        -webkit-transition: .6s opacity;
        transition: .6s opacity;
        -webkit-transition-timing-function: cubic-bezier(.19, 1, .22, 1);
        transition-timing-function: cubic-bezier(.19, 1, .22, 1);
        opacity: 0
    }

    .solution .s-solution .inner .solution__body .solution__list .item .head .text-link.is-shown {
        opacity: 1
    }

    .solution .s-solution .inner .solution__body .solution__list .item .head .text-link .text {
        color: $red
    }

    .solution .s-solution .inner .solution__body .solution__list .item .head .text-link .arrow {
        margin-top: -3px
    }

    .solution .s-solution .inner .solution__body .solution__list .item .head .text-link:hover .text {
        color: #151515
    }

    .solution .s-solution .inner .solution__body .solution__list .item .body {
        position: relative;
        max-width: 550px;
        -webkit-transition-timing-function: cubic-bezier(.19, 1, .22, 1);
        transition-timing-function: cubic-bezier(.19, 1, .22, 1);
        overflow: hidden
    }

    .solution .s-solution .inner .solution__body .solution__list .item .body .thumb {
        position: relative;
        display: block;
        overflow: hidden;
        width: 100%;
        height: auto
    }

    .solution .s-solution .inner .solution__body .solution__list .item .body .thumb .cover {
        position: absolute;
        top: -5px;
        right: -5px;
        bottom: -5px;
        left: -5px;
        background-color: #fcfcfc;
        z-index: 1;
        -webkit-transition-timing-function: cubic-bezier(.19, 1, .22, 1);
        transition-timing-function: cubic-bezier(.19, 1, .22, 1);
        -webkit-transition-property: -webkit-transform;
        transition-property: -webkit-transform;
        transition-property: transform;
        transition-property: transform, -webkit-transform;
        -webkit-transition-duration: 2s;
        transition-duration: 2s;
        -webkit-transform-origin: right;
        transform-origin: right;
        overflow: hidden;
        -webkit-transform: scaleX(1);
        transform: scaleX(1)
    }

    .solution .s-solution .inner .solution__body .solution__list .item .body .thumb .img {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        -webkit-transition: 1.6s -webkit-transform, -webkit-filter 3s;
        transition: 1.6s -webkit-transform, -webkit-filter 3s;
        transition: 1.6s transform, filter 3s;
        transition: 1.6s transform, filter 3s, 1.6s -webkit-transform, -webkit-filter 3s;
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
        overflow: hidden;
        -webkit-filter: brightness(200%);
        filter: brightness(200%);
        will-change: filter, transform
    } */

  /* biskets */
  .biskets .s-intoro,
  .biskets .s-intoro .body,
  .biskets .s-intoro .head,
  .biskets .s-biskets .inner .biskets__body,
  .biskets .s-biskets .inner .biskets__body .biskets__list {
    display: block;
    position: relative;
  }

  .biskets .l-kv .l-kv__read .copy {
    max-width: 296px;
  }

  .biskets .l-kv__bg .bg {
    background-image: url(/wp-content/themes/GDI/assets/images/pc/biskets/bisketss_kv.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  .biskets .js-section {
    opacity: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    will-change: opacity;
  }

  .biskets .s-intoro {
    padding: 116px 0 180px;
    background-color: #fcfcfc;
    z-index: 1;
  }

  .biskets .s-intoro .head {
    max-width: 486px;
    margin-left: auto;
    margin-right: auto;
  }

  .biskets .s-intoro .head .p-headline {
    text-align: center;
    opacity: 1;
  }

  .biskets .s-intoro .head .p-headline .cover {
    background-color: #fcfcfc;
  }

  .biskets .s-intoro .body {
    max-width: 724px;
    margin: 76px auto 0;
  }

  .biskets .s-intoro .body .text {
    font-size: 15px;
    line-height: 2.1333;
    margin-bottom: -8.5px;
    margin-top: 37.5px;
    letter-spacing: 0.03em;
  }

  .biskets .s-intoro .body .text:first-child {
    margin-top: 0;
  }

  .biskets .s-biskets {
    // background-color: #fcfcfc;
    padding-bottom: 180px;
  }

  .biskets .s-biskets .inner .biskets__body {
    width: 100%;
  }

  .biskets .s-biskets .inner .biskets__body .biskets__list .item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
    margin-top: 176px;
  }

  .biskets .s-biskets .inner .biskets__body .biskets__list .item.is-shown .head {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .biskets .s-biskets .inner .biskets__body .biskets__list .item.is-shown .head .s-title .num {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .biskets .s-biskets .inner .biskets__body .biskets__list .item.is-shown .body .thumb .cover,
  .biskets .s-biskets .inner .biskets__body .biskets__list .item.is-shown .head .s-title .read .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .biskets .s-biskets .inner .biskets__body .biskets__list .item.is-shown .body .thumb .img {
    -webkit-filter: brightness(100%);
    filter: brightness(100%);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  .biskets .s-biskets .inner .biskets__body .biskets__list .item:first-child {
    margin-top: 0;
  }

  .biskets .s-biskets .inner .biskets__body .biskets__list .item:nth-child(odd) .head {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    padding-left: 8.57633%;
    margin-right: 2.74443%;
  }

  .biskets .s-biskets .inner .biskets__body .biskets__list .item:nth-child(odd) .body {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    margin-left: 2.74443%;
  }

  .biskets .s-biskets .inner .biskets__body .biskets__list .item:nth-child(even) .head {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    padding-right: 8.57633%;
    margin-left: 2.74443%;
  }

  .biskets .s-biskets .inner .biskets__body .biskets__list .item:nth-child(even) .body {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    margin-right: 2.74443%;
  }

  .biskets .s-biskets .inner .biskets__body .biskets__list .item .head {
    position: relative;
    width: 550px;
  }

  .biskets .s-biskets .inner .biskets__body .biskets__list .item .head .s-title {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    margin-top: 20px;
    overflow: hidden;
  }

  .biskets .s-biskets .inner .biskets__body .biskets__list .item .head .s-title .read {
    position: relative;
    font-size: 24px;
    line-height: 1;
    margin-bottom: 0;
    margin-top: 10px;
    letter-spacing: 0.1em;
    display: block;
  }

  .biskets .s-biskets .inner .biskets__body .biskets__list .item .head .s-title .read .cover {
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    // background-color: #fcfcfc;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 3s;
    transition-duration: 3s;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: right;
    transform-origin: right;
    content: "";
  }

  .biskets .s-biskets .inner .biskets__body .biskets__list .item .head .s-title .num {
    color: #b2b2b2;
    letter-spacing: 0.1em;
    font-size: 12px;
    display: block;
    overflow: hidden;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }

  .biskets .s-biskets .inner .biskets__body .biskets__list .item .head .s-title>.text {
    font-size: 30px;
    line-height: 1.6;
    margin-bottom: -9px;
    margin-top: 43px;
    letter-spacing: 0.025em;
    display: block;
  }

  .biskets .s-biskets .inner .biskets__body .biskets__list .item .head>.text {
    font-size: 15px;
    line-height: 2.1538;
    margin-bottom: 21.35px;
    margin-top: 31.35px;
    letter-spacing: 0.03em;
  }

  .biskets .s-biskets .inner .biskets__body .biskets__list .item .head .text-link {
    -webkit-transition: 0.6s opacity;
    transition: 0.6s opacity;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
  }

  .biskets .s-biskets .inner .biskets__body .biskets__list .item .head .text-link.is-shown {
    opacity: 1;
  }

  .biskets .s-biskets .inner .biskets__body .biskets__list .item .head .text-link .text {
    color: $red;
  }

  .biskets .s-biskets .inner .biskets__body .biskets__list .item .head .text-link .arrow {
    margin-top: -3px;
  }

  .biskets .s-biskets .inner .biskets__body .biskets__list .item .head .text-link:hover .text {
    color: #151515;
  }

  .biskets .s-biskets .inner .biskets__body .biskets__list .item .body {
    position: relative;
    max-width: 850px;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    overflow: hidden;
  }

  .biskets .s-biskets .inner .biskets__body .biskets__list .item .body .thumb {
    position: relative;
    display: block;
    overflow: hidden;
    width: 100%;
    height: auto;
  }

  .biskets .s-biskets .inner .biskets__body .biskets__list .item .body .thumb .cover {
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    background-color: #fcfcfc;
    z-index: 1;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 2s;
    transition-duration: 2s;
    -webkit-transform-origin: right;
    transform-origin: right;
    overflow: hidden;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  .biskets .s-biskets .inner .biskets__body .biskets__list .item .body .thumb .img {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    -webkit-transition: 1.6s -webkit-transform, -webkit-filter 3s;
    transition: 1.6s -webkit-transform, -webkit-filter 3s;
    transition: 1.6s transform, filter 3s;
    transition: 1.6s transform, filter 3s, 1.6s -webkit-transform,
      -webkit-filter 3s;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    overflow: hidden;
    -webkit-filter: brightness(200%);
    filter: brightness(200%);
    will-change: filter, transform;
  }

  .biskets .s-media,
  .biskets .s-service {
    position: relative;
    display: block;
    // background-color: #fcfcfc;
    padding-bottom: 200px;
  }

  .biskets .s-media .bg,
  .biskets .s-service .bg {
    position: absolute;
    top: 420px;
    /* width: 91.21523%; */
    height: 640px;
    content: "";
    background-color: #fff;
    pointer-events: none;
  }

  .biskets .s-csr,
  .biskets .s-csr .inner .csr__intoro .p-headline .cover,
  .biskets .s-media .inner .intro .p-headline .cover,
  .biskets .s-service .inner .intro .p-headline .cover {
    // background-color: #fcfcfc
  }

  .biskets .s-media .inner,
  .biskets .s-service .inner {
    position: relative;
  }

  .biskets .s-media .inner .intro,
  .biskets .s-service .inner .intro {
    position: relative;
    display: block;
    max-width: 486px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 300px;
  }

  .biskets .s-media .inner .intro .p-headline,
  .biskets .s-service .inner .intro .p-headline {
    text-align: center;
  }

  .biskets .s-media .inner .body,
  .biskets .s-service .inner .body {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-top: 286px;
  }

  .biskets .s-media .inner .body.is-shown .thumb__content .thumb,
  .biskets .s-service .inner .body.is-shown .thumb__content .thumb {
    opacity: 1;
  }

  .biskets .s-media .inner .body.is-shown .thumb__content .thumb .cover,
  .biskets .s-service .inner .body.is-shown .thumb__content .thumb .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .biskets .s-media .inner .body.is-shown .thumb__content .thumb .img,
  .biskets .s-service .inner .body.is-shown .thumb__content .thumb .img {
    -webkit-filter: brightness(100%);
    filter: brightness(100%);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  .biskets .s-media .inner .body .thumb__content,
  .biskets .s-service .inner .body .thumb__content {
    position: absolute;
    top: -115px;
    width: 100%;
    height: 100%;
    z-index: 1;
    /* overflow: hidden */
  }

  .biskets .s-media .inner .body .thumb__content .thumb,
  .biskets .s-service .inner .body .thumb__content .thumb {
    position: absolute;
    top: 0;
    left: 125px;
    width: 100%;
    height: auto;
    max-width: 750px;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    opacity: 0;
    -webkit-transition-duration: 1.2s;
    transition-duration: 1.2s;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: opacity;
    transition-property: opacity;
    margin-top: 100px;
  }

  .biskets .s-media .inner .body .thumb__content .thumb .cover,
  .biskets .s-service .inner .body .thumb__content .thumb .cover {
    display: none;
  }

  .biskets .s-media .inner .body .thumb__content .thumb .img,
  .biskets .s-service .inner .body .thumb__content .thumb .img {
    display: block;
    width: 100%;
    height: auto;
    -webkit-transition: 1.2s -webkit-transform, -webkit-filter 2s;
    transition: 1.2s -webkit-transform, -webkit-filter 2s;
    transition: 1.2s transform, filter 2s;
    transition: 1.2s transform, filter 2s, 1.2s -webkit-transform,
      -webkit-filter 2s;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow: hidden;
    -webkit-filter: brightness(200%);
    filter: brightness(200%);
    will-change: filter, transform;
  }

  .biskets .s-service {
    padding: 10px 0;
    z-index: 1;
  }

  .biskets .s-service .inner .service__head {
    width: 550px;
    margin-left: 40px;
    margin-right: auto;
  }

  .biskets .s-service .inner .service__head .p-headline .head_line {
    background-color: red;
    content: "";
    height: 3px;
    width: 8%;
    position: absolute;
    top: 50%;
    /* right: 0; */
    left: -10%;
    bottom: -20%;
    /* margin-top: -1px;
        -webkit-transform: scale(0);
        transform: scale(0);
        -webkit-transition-duration: 1.8s;
        transition-duration: 1.8s;
        -webkit-transition-timing-function: cubic-bezier(.19, 1, .22, 1);
        transition-timing-function: cubic-bezier(.19, 1, .22, 1); */
  }

  .biskets .s-biskets .inner .biskets__body {
    width: 100%;
  }

  .biskets .s-biskets .inner .biskets__body .biskets__list .item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
    margin-top: 176px;
    /* background-color: #f8f8f8; */
  }

  .biskets .s-biskets .inner .biskets__body .biskets__list .item.is-shown .head {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .biskets .s-biskets .inner .biskets__body .biskets__list .item.is-shown .head .s-title .num {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .biskets .s-biskets .inner .biskets__body .biskets__list .item.is-shown .body .thumb .cover,
  .biskets .s-biskets .inner .biskets__body .biskets__list .item.is-shown .head .s-title .read .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .biskets .s-biskets .inner .biskets__body .biskets__list .item.is-shown .body .thumb .img {
    -webkit-filter: brightness(100%);
    filter: brightness(100%);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  .biskets .s-biskets .inner .biskets__body .biskets__list .item:first-child {
    margin-top: 0;
    margin-bottom: 10vh;
  }

  .biskets .s-biskets .inner .biskets__body .biskets__list .item:nth-child(odd) .head {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    padding-left: 8.57633%;
    margin-right: 2.74443%;
  }

  .biskets .s-biskets .inner .biskets__body .biskets__list .item:nth-child(odd) .body {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    margin-left: 2.74443%;
    padding-top: 60px;
  }

  .biskets .s-biskets .inner .biskets__body .biskets__list .item:nth-child(even) .head {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    padding-right: 10%;
    margin-left: 2.74443%;
  }

  .biskets .s-biskets .inner .biskets__body .biskets__list .item:nth-child(even) .body {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    margin-right: 2.74443%;
    padding-left: 10%;
    padding-top: 60px;
  }

  .biskets .s-biskets .inner .biskets__body .biskets__list .item .head {
    position: relative;
    width: 1050px;
  }

  .biskets .s-biskets .inner .biskets__body .biskets__list .item .head .s-title {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    margin-top: 20px;
    overflow: hidden;
  }

  .biskets .s-biskets .inner .biskets__body .biskets__list .item .head .s-title .read {
    position: relative;
    font-size: 24px;
    line-height: 1;
    margin-bottom: 0;
    margin-top: 10px;
    letter-spacing: 0.1em;
    display: block;
  }

  .biskets .s-biskets .inner .biskets__body .biskets__list .item .head .s-title .read .cover {
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    background-color: #f8f8f8;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 3s;
    transition-duration: 3s;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: right;
    transform-origin: right;
    content: "";
  }

  .biskets .s-biskets .inner .biskets__body .biskets__list .item .head .s-title .num {
    color: #b2b2b2;
    letter-spacing: 0.1em;
    font-size: 12px;
    display: block;
    overflow: hidden;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }

  .biskets .s-biskets .inner .biskets__body .biskets__list .item .head .s-title>.text {
    font-size: 30px;
    line-height: 1.6;
    margin-bottom: -9px;
    margin-top: 43px;
    letter-spacing: 0.025em;
    display: block;
  }

  .biskets .s-biskets .inner .biskets__body .biskets__list .item .head>.text {
    font-size: 15px;
    line-height: 2.1538;
    margin-bottom: 21.35px;
    margin-top: 31.35px;
    letter-spacing: 0.03em;
  }

  .biskets .s-about {
    position: relative;
    padding: 80px 0 508px;
    margin-top: -1px;
    // background-color: #fcfcfc;
  }

  .biskets .s-about .inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .biskets .s-about .inner .about__head {
    position: relative;
    display: block;
    width: 360px;
    min-width: 360px;
    margin-right: 3.33333%;
    -webkit-transition-property: opacity;
    transition-property: opacity;
    -webkit-transition-duration: 0.8s;
    transition-duration: 0.8s;
    opacity: 0;
  }

  .biskets .s-about .inner .about__head.is-shown,
  .biskets .s-service .inner .service__body .service__list .item.is-shown .head {
    opacity: 1;
  }

  .biskets .s-about .inner .about__head .thumb__men {
    position: absolute;
    top: 0;
    right: 0;
    width: 272px;
    height: 100%;
    z-index: 1;
  }

  .biskets .s-about .inner .about__head .thumb__men .img {
    display: block;
    max-width: 800px;
  }

  .biskets .s-about .inner .about__head .thumb__men .men-blue {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }

  .biskets .s-about .inner .about__head .thumb__men .men-red {
    position: absolute;
    top: -180px;
    left: 0;
    z-index: 2;
    /* mix-blend-mode: multiply */
  }

  .biskets .s-about .inner .about__head .thumb__men .men-red2 {
    position: absolute;
    top: -95px;
    left: -15px;
    z-index: 3;
    /* mix-blend-mode: multiply */
  }

  .kanata_line {
    z-index: 1;
    background-color: red;
    content: "";
    height: 3px;
    width: 15%;
    position: absolute;
    top: 50%;
    /* right: 0; */
    left: -20%;
    bottom: -20%;
  }
}

@media screen and (max-width: 1024px) {
  /* .solution .s-intoro,
    .solution .s-intoro .head .p-headline .cover {
        background-color: #fcfcfc
    }

    .solution .s-intoro,
    .solution .s-intoro .body,
    .solution .s-intoro .head,
    .solution .s-solution .solution__body,
    .solution .s-solution .solution__body .solution__list {
        display: block;
        position: relative
    }

    .solution .l-kv .l-kv__read .copy {
        max-width: 210px
    }

    .solution .l-kv__bg .bg {
        background-image: url(/wp-content/themes/GDI/assets/images/pc/solution/Solutions_kv.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%
    }

    .solution .js-section {
        opacity: 0;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        will-change: opacity
    }

    .solution .s-intoro {
        padding: 86px 18px;
        z-index: 1
    }

    .solution .s-intoro .head .p-headline {
        text-align: center;
        opacity: 1
    }

    .solution .s-intoro .body {
        margin-top: 34px
    }

    .solution .s-intoro .body .text {
        font-size: 12px;
        line-height: 2;
        margin-bottom: -6px;
        margin-top: 20px;
        letter-spacing: .03em
    }

    .solution .s-intoro .body .text:first-child {
        margin-top: 0
    }

    .solution .s-solution {
        padding-bottom: 116px
    }

    .solution .s-solution .inner {
        padding: 0 38px
    }

    .solution .s-solution .solution__body {
        width: 100%
    }

    .solution .s-solution .solution__body .solution__list .item {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        width: 100%;
        margin-top: 116px;
        -webkit-transition-timing-function: cubic-bezier(.19, 1, .22, 1);
        transition-timing-function: cubic-bezier(.19, 1, .22, 1);
        opacity: 0;
        -webkit-transition-duration: 1.6s;
        transition-duration: 1.6s;
        -webkit-transition-property: opacity transform;
        transition-property: opacity transform;
        -webkit-transform: translateY(40px);
        transform: translateY(40px)
    }

    .solution .s-solution .solution__body .solution__list .item.is-shown {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }

    .solution .s-solution .solution__body .solution__list .item .head {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
        position: relative;
        width: 100%
    }

    .solution .s-solution .solution__body .solution__list .item:first-child {
        margin-top: 0
    }

    .solution .s-solution .solution__body .solution__list .item .head .s-title {
        font-family: NotoSansCJKjp, sans-serif;
        font-weight: 500;
        -webkit-font-feature-settings: "palt"1;
        font-feature-settings: "palt"1;
        margin-top: 10px
    }

    .solution .s-solution .solution__body .solution__list .item .head .s-title .read {
        font-size: 18px;
        line-height: 1;
        margin-bottom: 0;
        margin-top: 12px;
        letter-spacing: .1em;
        display: block;
        text-align: center
    }

    .solution .s-solution .solution__body .solution__list .item .head .s-title .num {
        color: #b2b2b2;
        letter-spacing: .1em;
        font-size: 10px;
        display: block;
        text-align: center
    }

    .solution .s-solution .solution__body .solution__list .item .head .s-title>.text {
        font-size: 28px;
        line-height: 1.6;
        margin-bottom: -8.4px;
        margin-top: 29.6px;
        letter-spacing: .025em;
        display: block
    }

    .solution .s-solution .solution__body .solution__list .item .head>.text {
        font-size: 12px;
        line-height: 2;
        margin-bottom: 9px;
        margin-top: 14px;
        letter-spacing: .03em
    }

    .solution .s-solution .solution__body .solution__list .item .head .text-link {
        position: absolute;
        right: -32px;
        bottom: -18px
    }

    .solution .s-solution .solution__body .solution__list .item .head .text-link .text {
        color: $red
    }

    .solution .s-solution .solution__body .solution__list .item .body {
        -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
        order: 0;
        position: relative;
        width: 100%
    }

    .solution .s-solution .solution__body .solution__list .item .body .thumb {
        position: relative;
        display: block;
        width: 100%;
        height: auto
    }

    .solution .s-solution .solution__body .solution__list .item .body .thumb .img {
        position: relative;
        display: block;
        width: 100%;
        height: auto;
        line-height: 0;
        margin: 0;
        border: 0
    }

    .solution .s-solution .solution__body .solution__list .item.is-shown .body {
        opacity: 1
    } */

  .biskets .s-intoro,
  .biskets .s-intoro .head .p-headline .cover {
    background-color: #fcfcfc;
  }

  .biskets .s-intoro,
  .biskets .s-intoro .body,
  .biskets .s-intoro .head,
  .biskets .s-biskets .biskets__body,
  .biskets .s-biskets .biskets__body .biskets__list {
    display: block;
    position: relative;
  }

  .biskets .l-kv .l-kv__read .copy {
    max-width: 210px;
  }

  .biskets .l-kv__bg .bg {
    background-image: url(/wp-content/themes/GDI/assets/images/pc/biskets/bisketss_kv.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  .biskets .js-section {
    opacity: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    will-change: opacity;
  }

  .biskets .s-intoro {
    padding: 86px 18px;
    z-index: 1;
  }

  .biskets .s-intoro .head .p-headline {
    text-align: center;
    opacity: 1;
  }

  .biskets .s-intoro .body {
    margin-top: 34px;
  }

  .biskets .s-intoro .body .text {
    font-size: 12px;
    line-height: 2;
    margin-bottom: -6px;
    margin-top: 20px;
    letter-spacing: 0.03em;
  }

  .biskets .s-intoro .body .text:first-child {
    margin-top: 0;
  }

  .biskets .s-biskets {
    padding-bottom: 40px;
    margin-top: 35px;
  }

  .biskets .s-biskets .inner {
    padding: 0 38px;
  }

  .biskets .s-biskets .biskets__body {
    width: 100%;
  }

  .biskets .s-biskets .biskets__body .biskets__list .item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    margin-top: 116px;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-property: opacity transform;
    transition-property: opacity transform;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }

  .biskets .s-biskets .biskets__body .biskets__list .item.is-shown {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .biskets .s-biskets .biskets__body .biskets__list .item .head {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    position: relative;
    width: 100%;
  }

  .biskets .s-biskets .biskets__body .biskets__list .item:first-child {
    margin-top: 0;
  }

  .biskets .s-biskets .biskets__body .biskets__list .item .head .s-title {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    margin-top: 10px;
  }

  .biskets .s-biskets .biskets__body .biskets__list .item .head .s-title .read {
    font-size: 18px;
    line-height: 1;
    margin-bottom: 0;
    margin-top: 12px;
    letter-spacing: 0.1em;
    display: block;
    text-align: center;
  }

  .biskets .s-biskets .biskets__body .biskets__list .item .head .s-title .num {
    color: #b2b2b2;
    letter-spacing: 0.1em;
    font-size: 10px;
    display: block;
    text-align: center;
  }

  .biskets .s-biskets .biskets__body .biskets__list .item .head .s-title>.text {
    font-size: 28px;
    line-height: 1.6;
    margin-bottom: -8.4px;
    margin-top: 29.6px;
    letter-spacing: 0.025em;
    display: block;
  }

  .biskets .s-biskets .biskets__body .biskets__list .item .head>.text {
    font-size: 12px;
    line-height: 2;
    margin-bottom: 9px;
    margin-top: 14px;
    letter-spacing: 0.03em;
  }

  .biskets .s-biskets .biskets__body .biskets__list .item .head .text-link {
    position: absolute;
    right: -32px;
    bottom: -18px;
  }

  .biskets .s-biskets .biskets__body .biskets__list .item .head .text-link .text {
    color: $red;
  }

  .biskets .s-biskets .biskets__body .biskets__list .item .body {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    position: relative;
    width: 100%;
  }

  .biskets .s-biskets .biskets__body .biskets__list .item .body .thumb {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
  }

  .biskets .s-biskets .biskets__body .biskets__list .item .body .thumb .img {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    line-height: 0;
    margin: 0;
    border: 0;
  }

  .biskets .s-biskets .biskets__body .biskets__list .item.is-shown .body {
    opacity: 1;
  }

  .kanta-mobile {
    width: 100%;
    height: 100%;
    background-color: #000;
    color: #fff;
    padding-top: 35px;
    padding-left: 50px;
  }

  .biskets .s-service .inner .service__head .p-headline .head_line {
    z-index: 1;
    background-color: red;
    content: "";
    height: 3px;
    width: 15%;
    position: absolute;
    top: 50%;
    left: -20%;
    bottom: -20%;
  }

  /* .biskets .s-csr {
        position: relative;
        display: block;
        padding: 0 0 154px;
        z-index: 1
    } */

  .biskets .s-csr .inner .csr__intoro {
    position: relative;
    display: block;
    max-width: 486px;
    margin-left: auto;
    margin-right: auto;
  }

  .biskets .s-csr .inner .csr__intoro .p-headline {
    text-align: center;
  }

  .biskets .s-csr .inner .csr__body {
    /* margin-top: 80px; */
    /* display: -webkit-box;
        display: -ms-flexbox;
        display: flex; */
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .biskets .s-csr .inner .csr__body .item {
    /* width: 100%; */
    height: 180px;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-property: opacity transform;
    transition-property: opacity transform;
    /* border-left: 1px solid #1167f2; */
    -webkit-box-shadow: 10px 10px 5px 1px rgba(0, 0, 0, 0.26);
    -moz-box-shadow: 10px 10px 5px 1px rgba(0, 0, 0, 0.26);
    box-shadow: 10px 10px 5px 1px rgba(0, 0, 0, 0.26);
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 25px;
  }

  /* .biskets .s-csr .inner .csr__body .item_hidden {
        visibility: hidden;
    } */

  .biskets .s-csr .inner .csr__body .item.is-shown {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .biskets .s-csr .inner .csr__body .item .link,
  .biskets .s-csr .inner .csr__body .item .link .thumb {
    position: relative;
    display: block;
  }

  .biskets .s-csr .inner .csr__body .item .link .thumb .img {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
  }

  .biskets .s-csr .inner .csr__body .item .link .csr__text {
    font-size: 13px;
    line-height: 2.307;
    margin-bottom: 11.5px;
    margin-top: 15.5px;
    letter-spacing: 0.03em;
    padding-left: 40px;
  }

  .biskets .s-csr .inner .csr__body .item .text-link {
    width: 148px;
    -webkit-transition: 0.6s opacity;
    transition: 0.6s opacity;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
  }

  .biskets .s-csr .inner .csr__body .item .text-link.is-shown {
    opacity: 1;
  }

  .biskets .s-csr .inner .csr__body .item .text-link:hover .text {
    color: #151515;
  }

  .biskets .s-csr .inner .csr__body .item .text-link .text {
    font-size: 16px;
    letter-spacing: 0.05em;
    color: $red;
    font-weight: 700;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }

  .biskets .article-title {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 10px;
    color: #000;
    background-color: rgba(255, 255, 255, 0.2);
  }

  .biskets .board_article-title {
    /* position: absolute;
        bottom: 161px;
        left: -15px; */
    width: 100%;
    padding: 10px;
    /* color: #1167f2; */
    background-color: rgba(255, 255, 255, 0.2);
  }

  .biskets .biskets_relation {
    width: 100%;
    height: 150px;
    /* background-color: blue; */
    padding-top: 25px;
    margin-top: 40px;
  }

  .biskets .biskets_relation_title {
    color: #fff;
    text-align: center;
    padding: 30px;
    font-size: 22px;
  }

  .biskets .biskets_relation_list {
    /* position: absolute;
        top: -51px;
        left: 18px;
        z-index: 10;
        background-color: #fff; */
    /* padding: 20px 20px; */
    /* width: 340px; */
  }
}

@include desktop {
  .contact .js-section {
    opacity: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    will-change: opacity;
  }

  .contact .wpcf7-not-valid-tip,
  .contact .wpcf7-response-output,
  .contact div.wpcf7-acceptance-missing,
  .contact div.wpcf7-validation-errors {
    position: relative;
    display: inline-block;
    border: 0;
    margin: 5px 0;
    font-size: 13px;
    letter-spacing: 0.1em;
    color: red;
  }

  .contact .contact__body+.wpcf7-response-output {
    position: absolute;
    left: 0;
    bottom: 26px;
  }

  .contact .step--1 {
    position: absolute;
    bottom: -32px;
    right: 0;
    left: 0;
    margin: 0 auto;
    display: block;
  }

  .contact .step--2 {
    position: absolute;
    bottom: -32px;
    left: 200px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 556px;
  }

  .contact input[type="button"],
  .contact input[type="submit"] {
    width: 260px;
    padding: 0;
    border: 0;
    display: block;
    line-height: 70px;
    height: 70px;
    text-align: center;
    margin: auto;
    z-index: 1;
    font-weight: 700;
    background-color: $red;
    color: #fff;
    font-size: 15px;
    letter-spacing: 0.1em;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
  }

  .contact input[type="button"]:hover,
  .contact input[type="submit"]:hover {
    background-color: #151515;
    -webkit-transition-duration: 1s;
    transition-duration: 1s;
    -webkit-transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  .contact .l-kv__bg .bg {
    background-image: url(/wp-content/themes/GDI/assets/images/pc/contact/Contact_kv.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  .contact .s-contact {
    padding: 114px 0 214px;
    position: relative;
    display: block;
    background-color: #fcfcfc;
  }

  .contact .s-contact .s-inner {
    position: relative;
    max-width: 964px;
    margin-right: auto;
    margin-left: auto;
  }

  .contact .s-contact .s-inner .contact__head {
    position: relative;
    display: block;
    margin-right: auto;
    margin-left: auto;
    max-width: 664px;
  }

  .contact .s-contact .s-inner .contact__head .text {
    font-size: 15px;
    line-height: 2.1333;
    margin-bottom: 7.5px;
    margin-top: -8.5px;
    letter-spacing: 0.1em;
    padding-bottom: 16px;
  }

  .contact .s-contact .s-inner .contact__head .note {
    position: absolute;
    left: 0;
    bottom: 0;
    font-size: 12px;
    letter-spacing: 0.1em;
    opacity: 0;
  }
}

@media screen and (max-width: 1024px) {
  .contact .js-section {
    opacity: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    will-change: opacity;
  }

  .contact .wpcf7-not-valid-tip,
  .contact .wpcf7-response-output,
  .contact div.wpcf7-acceptance-missing,
  .contact div.wpcf7-validation-errors {
    position: relative;
    display: inline-block;
    border: 0;
    margin: 5px 0;
    font-size: 12px;
    letter-spacing: 0.1em;
    color: red;
  }

  .contact .contact__body+.wpcf7-response-output {
    position: absolute;
    // left: 0;
    bottom: 36px;
  }

  .contact .step--1,
  .contact .step--2 {
    bottom: -24px;
    right: 0;
    margin: 0 auto;
    left: 0;
  }

  .contact .step--1 {
    position: absolute;
    display: block;
  }

  .contact .step--2 {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 260px;
  }

  .contact .step--2 input[type="button"],
  .contact .step--2>input[type="submit"] {
    width: 110px;
    height: 40px;
    line-height: 40px;
    font-size: 10px;
  }

  .contact input[type="button"],
  .contact input[type="submit"] {
    width: 180px;
    padding: 0;
    border: 0;
    display: block;
    line-height: 45px;
    height: 45px;
    text-align: center;
    margin: 0 auto;
    z-index: 1;
    font-weight: 700;
    background-color: $red;
    font-size: 12px;
    letter-spacing: 0.1em;
    color: #fff;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
  }

  .contact .l-kv__bg .bg {
    background-image: url(/wp-content/themes/GDI/assets/images/pc/contact/Contact_kv.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  .contact .s-contact {
    padding: 78px 0 100px;
    position: relative;
    display: block;
    background-color: #fcfcfc;
  }

  .contact .s-contact .s-inner {
    position: relative;
    width: 100%;
    padding: 0 38px;
  }

  .contact .s-contact .s-inner .contact__head {
    position: relative;
    display: block;
  }

  .contact .s-contact .s-inner .contact__head .text {
    font-size: 12px;
    line-height: 2;
    margin-bottom: 12px;
    margin-top: -6px;
    letter-spacing: 0.03em;
    padding-bottom: 14px;
  }

  .contact .s-contact .s-inner .contact__head .note {
    position: absolute;
    left: 0;
    bottom: 0;
    font-size: 11px;
    letter-spacing: 0.03em;
    opacity: 0;
  }
}

@include desktop {

  .policy .s-policy .body .inner,
  .policy .s-policy .head .inner {
    padding-right: 5.52697%;
    padding-left: 5.10788%;
  }

  .policy .s-policy,
  .policy .s-policy .body,
  .policy .s-policy .body .policy__list .item,
  .policy .s-policy .head {
    position: relative;
    display: block;
  }

  .policy .l-kv .l-kv__read .copy {
    max-width: 429px;
  }

  .policy .l-kv__bg .bg {
    background-image: url(/wp-content/themes/GDI/assets/images/pc/policy/Policy.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  .policy .js-section {
    opacity: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    will-change: opacity;
  }

  .policy .s-policy {
    padding-top: 116px;
    padding-bottom: 158px;
    background-color: #fcfcfc;
  }

  .policy .s-policy .head .inner>.text {
    font-size: 15px;
    line-height: 1.8666;
    margin-bottom: -6.5px;
    margin-top: -6.5px;
    letter-spacing: 0.03em;
  }

  .policy .s-policy .body {
    margin-top: 52px;
  }

  .policy .s-policy .body .policy__list {
    z-index: 1;
  }

  .policy .s-policy .body .policy__list .item {
    padding-top: 52px;
    padding-bottom: 58px;
  }

  .policy .s-policy .body .policy__list .item:nth-child(odd)::before {
    position: absolute;
    right: 0;
    top: 0;
    content: "";
    display: block;
    background-color: #fff;
    height: 100%;
    width: 82.86969%;
    z-index: 0;
  }

  .policy .s-policy .body .policy__list .item .s-title {
    font-size: 18px;
    letter-spacing: 0.03em;
    font-weight: 500;
  }

  .policy .s-policy .body .policy__list .item .bold {
    display: block;
    line-height: 1.8666;
    font-weight: 700;
  }

  .policy .s-policy .body .policy__list .item .text {
    font-size: 15px;
    line-height: 1.8666;
    margin-bottom: -6.5px;
    margin-top: 23.5px;
  }

  .policy .s-policy .body .policy__list .item .policy__list--s .item {
    padding: 0;
    font-size: 15px;
    line-height: 1.8666;
    margin-bottom: -6.5px;
    margin-top: 3.5px;
  }

  .policy .s-policy .body .policy__list .item .policy__list--s .item::before {
    display: none;
  }

  .policy .s-policy .date {
    position: relative;
    display: block;
    margin-top: 110px;
  }

  .policy .s-policy .date>.text {
    font-size: 15px;
    letter-spacing: 0.03em;
  }

  .policy .pipl_title {
    font-size: 30px;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .policy .pipl_title2 {
    font-size: 30px;
    text-align: center;
    margin-bottom: 50px;
    margin-top: 50px;
  }

  .policy .text_content {
    text-align: right;
  }
}

@media screen and (max-width: 1024px) {

  .policy .s-policy,
  .policy .s-policy .body,
  .policy .s-policy .body .policy__list,
  .policy .s-policy .body .policy__list .item,
  .policy .s-policy .head {
    position: relative;
    display: block;
  }

  .policy .l-kv .l-kv__read .copy {
    max-width: 296px;
  }

  .policy .l-kv__bg .bg {
    background-image: url(/wp-content/themes/GDI/assets/images/sp/policy/Policy.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  .policy .js-section {
    opacity: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    will-change: opacity;
  }

  .policy .s-policy {
    padding-top: 76px 80px;
    padding-bottom: 88px;
    background-color: #fcfcfc;
  }

  .policy .s-policy .inner {
    padding: 0 36px;
  }

  .policy .s-policy .head .inner>.text {
    font-size: 12px;
    line-height: 2;
    margin-bottom: -6px;
    margin-top: 74px;
    letter-spacing: 0.03em;
  }

  .policy .s-policy .body .date {
    font-size: 12px;
    letter-spacing: 0.03em;
    margin-top: 40px;
  }

  .policy .s-policy .body .policy__list .item {
    margin-top: 45px;
    padding: 0 18px;
  }

  .policy .s-policy .body .policy__list .item:nth-child(odd) .inner {
    background-color: #fff;
  }

  .policy .s-policy .body .policy__list .item .inner {
    padding: 37px 18px;
  }

  .policy .s-policy .body .policy__list .item .s-title {
    font-size: 18px;
    line-height: 1.6666;
    letter-spacing: 0.03em;
    font-weight: 500;
  }

  .policy .s-policy .body .policy__list .item .bold {
    display: block;
    line-height: 2;
    font-weight: 700;
  }

  .policy .s-policy .body .policy__list .item .text {
    font-size: 12px;
    line-height: 2;
    margin-bottom: -6px;
    margin-top: 24px;
  }

  .policy .s-policy .body .policy__list .item .policy__list--s .item {
    font-size: 12px;
    line-height: 2;
    margin-bottom: -6px;
    margin-top: 4px;
  }

  .policy .sp_head {
    margin-bottom: 80px;
  }

  .policy .pipl_title {
    padding-top: 125px;
    font-size: 30px;
    text-align: center;
  }

  .policy .pipl_title2 {
    font-size: 30px;
    text-align: center;
    margin-bottom: 50px;
    margin-top: 50px;
  }

  .policy .text_content {
    text-align: right;
  }
}

@include desktop {
  .thanks .l-kv__bg .bg {
    background-image: url(/wp-content/themes/GDI/assets/images/pc/contact/Contact_kv.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  .thanks .js-section {
    opacity: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    will-change: opacity;
  }

  .thanks .js-section.is-show {
    opacity: 1;
  }

  .thanks .s-thanks {
    padding: 114px 0 214px;
    position: relative;
    display: block;
    background-color: #fcfcfc;
  }

  .thanks .s-thanks .inner {
    text-align: center;
  }

  .thanks .s-thanks .inner .text {
    font-size: 15px;
    line-height: 2.1333;
    margin-bottom: 7.5px;
    margin-top: -8.5px;
    letter-spacing: 0.1em;
  }

  .thanks .s-thanks .inner .p-btn--b {
    margin: 80px auto 0;
  }
}

@media screen and (max-width: 1024px) {
  .thanks .l-kv__bg .bg {
    background-image: url(/wp-content/themes/GDI/assets/images/sp/contact/Contact_kv.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  .thanks .js-section {
    opacity: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    will-change: opacity;
  }

  .thanks .js-section.is-show {
    opacity: 1;
  }

  .thanks .s-thanks {
    padding: 78px 0 100px;
    position: relative;
    display: block;
    background-color: #fcfcfc;
  }

  .thanks .s-thanks .inner .text {
    font-size: 12px;
    line-height: 2;
    margin-bottom: 12px;
    margin-top: -6px;
    letter-spacing: 0.03em;
  }

  .thanks .s-thanks .inner .p-btn--b {
    margin: 48px auto 0;
  }
}

.u-text-indent {
  text-indent: 1em !important;
}

.u-text-note {
  margin-left: 1em !important;
  text-indent: -1em !important;
}

.u-align-left,
.u-ml-zero {
  margin-left: 0 !important;
}

.u-text-nowrap {
  white-space: nowrap !important;
}

.u-text-breakword {
  word-wrap: break-word !important;
  overflow-wrap: break-word !important;
}

.u-text-justify {
  text-align: justify !important;
  text-justify: inter-ideograph !important;
}

.u-text-none {
  text-transform: none !important;
}

.u-text-lowercase {
  text-transform: lowercase !important;
}

.u-text-uppercase {
  text-transform: uppercase !important;
}

.u-text-capitalize {
  text-transform: capitalize !important;
}

.u-text-smallcaps {
  font-variant: small-caps !important;
}

.u-text-vertical {
  -webkit-writing-mode: vertical-rl !important;
  -ms-writing-mode: tb-rl !important;
  writing-mode: vertical-rl !important;
  text-orientation: mixed !important;
}

.u-text-vertical--upright {
  text-orientation: upright !important;
}

.u-text-right {
  text-align: right !important;
}

.u-text-center {
  text-align: center !important;
}

.u-text-left {
  text-align: left !important;
}

.u-text-top {
  vertical-align: top !important;
}

.u-text-middle {
  vertical-align: middle !important;
}

.u-text-bottom {
  vertical-align: bottom !important;
}

.u-mt-zero {
  margin-top: 0 !important;
}

.u-mb-zero {
  margin-bottom: 0 !important;
}

.u-mr-zero {
  margin-right: 0 !important;
}

.u-align-center,
.u-align-left {
  margin-right: auto !important;
}

.u-align-left {
  display: block !important;
}

.u-align-center,
.u-align-right {
  display: block !important;
  margin-left: auto !important;
}

.u-align-right {
  margin-right: 0 !important;
}

@include desktop {
  .u-sp-only {
    display: none !important;
  }
}

@media screen and (max-width: 1024px) {
  .u-pc-only {
    display: none !important;
  }
}

.u-sr-only {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  border: 0 !important;
  overflow: hidden !important;
  padding: 0 !important;
  clip: rect(0, 0, 0, 0) !important;
}

@include desktop {

  .recruit .s-intoro,
  .recruit .s-intoro .body,
  .recruit .s-intoro .head,
  .recruit .s-service .inner .service__body .service__list {
    display: block;
    position: relative;
  }

  .recruit .s-intoro,
  .recruit .s-intoro .head .p-headline .cover,
  .recruit .s-service,
  .recruit .s-service .inner .service__head .p-headline .cover {
    background-color: #fff;
  }

  .recruit .l-kv .l-kv__read .copy {
    max-width: 429px;
  }

  .recruit .l-kv__bg .bg {
    background-image: url(/wp-content/themes/GDI/assets/images/pc/recruit/Recruit_kv.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  .recruit .js-section {
    opacity: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    will-change: opacity;
  }

  .recruit .s-intoro {
    padding: 116px 0 180px;
    z-index: 1;
  }

  .recruit .s-intoro .head {
    max-width: 486px;
    margin-left: auto;
    margin-right: auto;
  }

  .recruit .s-intoro .head .p-headline {
    text-align: center;
    opacity: 1;
  }

  .recruit .s-intoro .body {
    max-width: 724px;
    margin: 76px auto 0;
  }

  .recruit .s-intoro .body .text {
    font-size: 15px;
    line-height: 2.1333;
    margin-bottom: -8.5px;
    margin-top: 37.5px;
    letter-spacing: 0.03em;
  }

  .recruit .s-intoro .body .text:first-child {
    margin-top: 0;
  }

  .recruit .s-service {
    padding: 84px 0 158px;
  }

  .recruit .s-service .inner .service__head {
    width: 486px;
    margin-left: auto;
    margin-right: auto;
  }

  .recruit .s-service .inner .service__head .p-headline {
    /* text-align: center */
  }

  .recruit .s-service .inner .service__head .p-headline.is-shown .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .recruit .s-service .inner .service__head .text {
    font-size: 15px;
    line-height: 2.1333;
    margin-bottom: 61.5px;
    margin-top: 71.5px;
    letter-spacing: 0.03em;
  }

  .recruit .s-service .inner .service__body {
    position: relative;
    display: block;
    width: 100%;
  }

  .recruit .s-service .inner .service__body .service__list .item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
    margin-top: 64px;
    padding-top: 10px;
    padding-left: 30px;
  }

  .recruit .s-service .inner .service__body .service__list .item:first-child {
    margin-top: 0;
  }

  .recruit .s-service .inner .service__body .service__list .item.is-shown .head {
    opacity: 1;
  }

  .recruit .s-service .inner .service__body .service__list .item.is-shown .head .service__headline .num {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .recruit .s-service .inner .service__body .service__list .item.is-shown .head .service__headline .line {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition-delay: 0.6s;
    transition-delay: 0.6s;
  }

  .recruit .s-service .inner .service__body .service__list .item.is-shown .head .service__headline .en {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .recruit .s-service .inner .service__body .service__list .item.is-shown .head .s-title .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .recruit .s-service .inner .service__body .service__list .item.is-shown .head .s-link {
    text-decoration: underline;
    font-size: 13px;
  }

  .recruit .s-service .inner .service__body .service__list .item .head {
    position: relative;
    width: 400px;
    padding-top: 8px;
  }

  .recruit .s-service .inner .service__body .service__list .item .head .service__headline {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    overflow: hidden;
    margin-bottom: -20px;
  }

  .recruit .s-service .inner .service__body .service__list .item .head .service__headline .num {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    position: relative;
    display: inline-block;
    font-size: 12px;
    color: #959595;
    letter-spacing: 0.1em;
    width: 20px;
    z-index: 2;
    overflow: hidden;
    background-color: #fcfcfc;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }

  .recruit .s-service .inner .service__body .service__list .item .head .service__headline .line {
    z-index: 1;
    background-color: #959595;
    content: "";
    height: 1px;
    width: 100%;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -1px;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition-duration: 1.8s;
    transition-duration: 1.8s;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }

  .recruit .s-service .inner .service__body .service__list .item .head .s-title {
    position: relative;
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    font-size: 28px;
    letter-spacing: 0.05em;
    margin-top: 28px;
  }

  .recruit .s-service .inner .service__body .service__list .item .head .s-title .cover {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: -10px;
    background-color: #fcfcfc;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 3s;
    transition-duration: 3s;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: right;
    transform-origin: right;
  }

  .recruit .s-service .inner .service__body .service__list .item .head .text {
    font-size: 13px;
    line-height: 2.1538;
    margin-bottom: 12.5px;
    margin-top: 52.5px;
    letter-spacing: 0.03em;
  }

  .recruit .s-end .vision__body .text,
  .recruit .s-vision .vision__body .text {
    font-size: 15px;
    line-height: 2.1333;
    margin-bottom: -8.5px;
    letter-spacing: 0.03em;
  }

  .recruit .s-service .inner .service__body .service__list .item .head .text-link {
    width: 92px;
    -webkit-transition: 0.6s opacity;
    transition: 0.6s opacity;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
    -webkit-transition-delay: 0.6s;
    transition-delay: 0.6s;
  }

  .recruit .s-service .inner .service__body .service__list .item .head .text-link.is-shown,
  .recruit .s-service .inner .service__body .service__list .item.is-shown .body {
    opacity: 1;
  }

  .recruit .s-service .inner .service__body .service__list .item .body {
    position: relative;
    max-width: 512px;
    padding-top: 58px;
    margin-left: 3.33333%;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    overflow: hidden;
  }

  .recruit .s-service .inner .service__body .service__list .item .body .thumb {
    position: relative;
    display: block;
    overflow: hidden;
    width: 100%;
    height: auto;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .recruit .s-service .inner .service__body .service__list .item .body .thumb .cover {
    position: absolute;
    right: 0;
    left: 0;
    top: -5px;
    bottom: -5px;
    background-color: #fcfcfc;
    z-index: 1;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 2s;
    transition-duration: 2s;
    -webkit-transform-origin: right;
    transform-origin: right;
    overflow: hidden;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  .recruit .s-service .inner .service__body .service__list .item .body .thumb .img {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    -webkit-transition: 1.6s -webkit-transform, -webkit-filter 3s;
    transition: 1.6s -webkit-transform, -webkit-filter 3s;
    transition: 1.6s transform, filter 3s;
    transition: 1.6s transform, filter 3s, 1.6s -webkit-transform,
      -webkit-filter 3s;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow: hidden;
    -webkit-filter: brightness(200%);
    filter: brightness(200%);
    will-change: filter, transform;
  }

  .recruit .s-service .inner .service__body .service__list .item.is-shown .body .thumb .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .recruit .s-service .inner .service__body .service__list .item.is-shown .body .thumb .img {
    -webkit-filter: brightness(100%);
    filter: brightness(100%);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  .recruit .s-vision {
    position: relative;
    display: block;
    padding: 116px 0 76px;
    background-color: #fcfcfc;
    z-index: 1;
  }

  .recruit .s-vision .vision__head {
    position: relative;
    display: block;
    max-width: 486px;
    margin-left: auto;
    margin-right: auto;
  }

  .recruit .s-vision .vision__head .p-headline {
    text-align: center;
    opacity: 1;
  }

  .recruit .s-vision .vision__head .p-headline .cover {
    background-color: #fcfcfc;
  }

  .recruit .s-vision .vision__body {
    position: relative;
    display: block;
    margin-top: 76px;
    text-align: center;
  }

  .recruit .s-vision .vision__body .text {
    margin-top: 37.5px;
  }

  .recruit .s-vision .vision__body .text:first-child {
    margin-top: 0;
  }

  .recruit .s-end {
    position: relative;
    display: block;
    padding: 0 0 176px;
    background-color: #fcfcfc;
    z-index: 1;
  }

  .recruit .s-end .vision__body {
    position: relative;
    display: block;
    margin-top: 0;
    text-align: center;
  }

  .recruit .s-end .vision__body .text {
    margin-top: 37.5px;
  }

  .recruit .s-end .vision__body .text:first-child {
    margin-top: 0;
  }

  .recruit .head .text .frontend-link {
    position: absolute;
    right: -10px;
    top: 260px;
  }

  .recruit .head .text .frontend-link .frontend-arrow {
    position: absolute;
    top: 50%;
    right: -12px;
    width: 40px;
    height: 1px;
    content: "";
  }

  .recruit .head .text .backend-link {
    position: absolute;
    right: -10px;
    top: 290px;
  }

  .recruit .head .text .backend-link .backend-arrow {
    position: absolute;
    top: 50%;
    right: -12px;
    width: 40px;
    height: 1px;
    content: "";
  }

  .recruit .head .text .writer-link {
    position: absolute;
    right: -10px;
    top: 320px;
  }

  .recruit .head .text .writer-link .writer-arrow {
    position: absolute;
    top: 50%;
    right: -12px;
    width: 40px;
    height: 1px;
    content: "";
  }

  .recruit .s-csr {
    position: relative;
    display: block;
    padding: 0 0 154px;
    z-index: 1;
  }

  .recruit .s-csr .inner .csr__intoro {
    position: relative;
    display: block;
    max-width: 486px;
    margin-left: auto;
    margin-right: auto;
  }

  .recruit .s-csr .inner .csr__intoro .p-headline {
    text-align: center;
  }

  .recruit .s-csr .inner .csr__body {
    margin-top: 80px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .recruit .s-csr .inner .csr__body .item {
    width: 30.625%;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-property: opacity transform;
    transition-property: opacity transform;
  }

  .recruit .s-csr .inner .csr__body .item.is-shown {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .recruit .s-csr .inner .csr__body .item .link,
  .recruit .s-csr .inner .csr__body .item .link .thumb {
    position: relative;
    display: block;
  }

  .recruit .s-csr .inner .csr__body .item .link .thumb .img {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
  }

  .recruit .s-csr .inner .csr__body .item .link .csr__text {
    font-size: 13px;
    line-height: 2.307;
    margin-bottom: 11.5px;
    margin-top: 15.5px;
    letter-spacing: 0.03em;
  }

  .recruit .s-csr .inner .csr__body .item .text-link {
    width: 148px;
    -webkit-transition: 0.6s opacity;
    transition: 0.6s opacity;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
  }

  .recruit .s-csr .inner .csr__body .item .text-link.is-shown {
    opacity: 1;
  }

  .recruit .s-csr .inner .csr__body .item .text-link:hover .text {
    color: #151515;
  }

  .recruit .s-csr .inner .csr__body .item .text-link .text {
    font-size: 16px;
    letter-spacing: 0.05em;
    color: $red;
    font-weight: 700;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }

  .recruit .article-title {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    padding-top: 60%;
    text-align: center;
    color: #fff;
    background-color: #2424249c;
  }

  .recruit .board_article-title {
    position: absolute;
    bottom: 161px;
    width: 100%;
    padding: 10px;
    color: #fff;
    background-color: rgba(255, 255, 255, 0.2);
  }

  .recruit .inner .solution__content .thumb-wrapper.is-shown .thumb_red .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .recruit .inner .solution__content .thumb-wrapper.is-shown .thumb_red .img {
    -webkit-filter: brightness(100%);
    filter: brightness(100%);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  .recruit .inner .solution__content .thumb-wrapper .thumb_red {
    max-width: 872px;
    margin-left: auto;
    /* margin-left: 10px; */
    padding-right: 32px;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    z-index: -1000;
    position: absolute;
    top: 245px;
    left: -62px;
  }

  .recruit .inner .solution__content .thumb-wrapper .thumb_red .cover {
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    background-color: #fff;
    z-index: 1;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 2s;
    transition-duration: 2s;
    -webkit-transform-origin: right center;
    transform-origin: right center;
    overflow: hidden;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
  }

  .recruit .inner .solution__content .thumb-wrapper .thumb_red .black_box {
    position: relative;
    display: block;
    width: 27.5vw;
    height: 45vh;
    background-color: red;
    /* width: 100%;
        height: 100%; */
    -webkit-transition: 1.6s -webkit-transform, -webkit-filter 3s;
    transition: 1.6s -webkit-transform, -webkit-filter 3s;
    transition: 1.6s transform, filter 3s;
    transition: 1.6s transform, filter 3s, 1.6s -webkit-transform,
      -webkit-filter 3s;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow: hidden;
    -webkit-filter: brightness(200%);
    filter: brightness(200%);
    will-change: filter, transform;
  }

  .recruit .s-recruit2 .inner .recruit__body .recruit__list .item.is-shown .body .thumb .img {
    -webkit-filter: brightness(100%);
    filter: brightness(100%);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  .recruit .s-recruit2 .inner .recruit__body .recruit__list .item:first-child {
    margin-top: 0;
  }

  .recruit .s-recruit2 .inner .recruit__body .recruit__list .item:nth-child(odd) .head {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    padding-left: 8.57633%;
    margin-right: 2.74443%;
  }

  .recruit .s-recruit2 .inner .recruit__body .recruit__list .item:nth-child(odd) .body {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    margin-left: 2.74443%;
  }

  .recruit .s-recruit2 .inner .recruit__body .recruit__list .item:nth-child(even) .head {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    padding-right: 10%;
    margin-left: 2.74443%;
  }

  .recruit .s-recruit2 .inner .recruit__body .recruit__list .item:nth-child(even) .body {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    margin-right: 2.74443%;
    padding-left: 10%;
  }

  .recruit .s-recruit2 .inner .recruit__body .recruit__list .item .head {
    position: relative;
    width: 1050px;
  }

  .recruit .s-recruit2 .inner .recruit__body .recruit__list .item .head .s-title {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    margin-top: 20px;
    overflow: hidden;
  }

  .recruit .s-recruit2 .inner .recruit__body .recruit__list .item .head .s-title .read {
    position: relative;
    font-size: 24px;
    line-height: 1;
    margin-bottom: 0;
    margin-top: 10px;
    letter-spacing: 0.1em;
    display: block;
  }

  .recruit .s-recruit2 .inner .recruit__body .recruit__list .item .head .s-title .read .cover {
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    background-color: #f8f8f8;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 3s;
    transition-duration: 3s;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: right;
    transform-origin: right;
    content: "";
  }

  .recruit .s-recruit2 .inner .recruit__body .recruit__list .item .head .s-title .num {
    color: #b2b2b2;
    letter-spacing: 0.1em;
    font-size: 12px;
    display: block;
    overflow: hidden;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }

  .recruit .s-recruit2 .inner .recruit__body .recruit__list .item .head .s-title>.text {
    font-size: 30px;
    line-height: 1.6;
    margin-bottom: -9px;
    margin-top: 43px;
    letter-spacing: 0.025em;
    display: block;
  }

  .recruit .s-recruit2 .inner .recruit__body .recruit__list .item .head>.text {
    font-size: 15px;
    line-height: 2.1538;
    margin-bottom: 21.35px;
    margin-top: 31.35px;
    letter-spacing: 0.03em;
  }

  .recruit .s-service {
    padding: 84px 0 158px;
  }

  .recruit .s-service .inner .service__head {
    width: 281px;
    margin-left: 40px;
    margin-right: auto;
  }

  .recruit .s-service .inner .service__head .p-headline .head_line {
    z-index: 1;
    background-color: red;
    content: "";
    height: 3px;
    width: 15%;
    position: absolute;
    top: 50%;
    /* right: 0; */
    left: -20%;
    bottom: -20%;
    /* margin-top: -1px;
        -webkit-transform: scale(0);
        transform: scale(0);
        -webkit-transition-duration: 1.8s;
        transition-duration: 1.8s;
        -webkit-transition-timing-function: cubic-bezier(.19, 1, .22, 1);
        transition-timing-function: cubic-bezier(.19, 1, .22, 1); */
  }

  .recruit .s-recruit .inner .recruit__body {
    width: 100%;
  }

  .recruit .s-recruit .inner .recruit__body .recruit__list .item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
    margin-top: 176px;
    /* background-color: #f8f8f8; */
  }

  .recruit .s-recruit .inner .recruit__body .recruit__list .item.is-shown .head {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .recruit .s-recruit .inner .recruit__body .recruit__list .item.is-shown .head .s-title .num {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .recruit .s-recruit .inner .recruit__body .recruit__list .item.is-shown .body .thumb .cover,
  .recruit .s-recruit .inner .recruit__body .recruit__list .item.is-shown .head .s-title .read .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .recruit .s-recruit .inner .recruit__body .recruit__list .item.is-shown .body .thumb .img {
    -webkit-filter: brightness(100%);
    filter: brightness(100%);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  .recruit .s-recruit .inner .recruit__body .recruit__list .item:first-child {
    margin-top: 0;
  }

  .recruit .s-recruit .inner .recruit__body .recruit__list .item:nth-child(odd) .head {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    padding-left: 8.57633%;
    margin-right: 2.74443%;
  }

  .recruit .s-recruit .inner .recruit__body .recruit__list .item:nth-child(odd) .body {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    margin-left: 2.74443%;
  }

  .recruit .s-recruit .inner .recruit__body .recruit__list .item:nth-child(even) .head {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    padding-right: 10%;
    margin-left: 2.74443%;
  }

  .recruit .s-recruit .inner .recruit__body .recruit__list .item:nth-child(even) .body {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    margin-right: 2.74443%;
    padding-left: 10%;
  }

  .recruit .s-recruit .inner .recruit__body .recruit__list .item .head {
    position: relative;
    width: 1050px;
  }

  .recruit .s-recruit .inner .recruit__body .recruit__list .item .head .s-title {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    margin-top: 20px;
    overflow: hidden;
  }

  .recruit .s-recruit .inner .recruit__body .recruit__list .item .head .s-title .read {
    position: relative;
    font-size: 24px;
    line-height: 1;
    margin-bottom: 0;
    margin-top: 10px;
    letter-spacing: 0.1em;
    display: block;
  }

  .recruit .s-recruit .inner .recruit__body .recruit__list .item .head .s-title .read .cover {
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    background-color: #f8f8f8;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 3s;
    transition-duration: 3s;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: right;
    transform-origin: right;
    content: "";
  }

  .recruit .s-recruit .inner .recruit__body .recruit__list .item .head .s-title .num {
    color: #b2b2b2;
    letter-spacing: 0.1em;
    font-size: 12px;
    display: block;
    overflow: hidden;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }

  .recruit .s-recruit .inner .recruit__body .recruit__list .item .head .s-title>.text {
    font-size: 30px;
    line-height: 1.6;
    margin-bottom: -9px;
    margin-top: 43px;
    letter-spacing: 0.025em;
    display: block;
  }

  .recruit .s-recruit .inner .recruit__body .recruit__list .item .head>.text {
    font-size: 15px;
    line-height: 2.1538;
    margin-bottom: 21.35px;
    margin-top: 31.35px;
    letter-spacing: 0.03em;
  }

  .recruit .s-about {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    -webkit-justify-content: center;
    align-items: center;
    -webkit-align-items: center;
  }

  .recruit .carousel_recruit {
    width: 50%;
    height: 100%;
    display: flex;
    max-width: 900px;
    max-height: 550px;
    overflow: hidden;
    /* position: relative; */
  }

  .recruit .carousel_recruit-item {
    visibility: visible;
    display: flex;
    width: 100%;
    height: 40vh;
    align-items: center;
    justify-content: flex-end;
    -webkit-align-items: center;
    -webkit-justify-content: flex-end;
    position: relative;
    background-color: #fcfcfc;
    flex-shrink: 0;
    -webkit-flex-shrink: 0;
    position: absolute;
    z-index: 0;
    transition: 0.6s all linear;
  }

  .recruit .carousel_recruit-item__info {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    width: 100%;
  }

  .recruit .carousel_recruit-item__image {
    width: 60%;
    height: 100%;
    order: 2;
    align-self: flex-end;
    flex-basis: 60%;

    -webkit-order: 2;
    -webkit-align-self: flex-end;
    -webkit-flex-basis: 60%;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    transform: translateX(100%);
    transition: 0.6s all ease-in-out;
  }

  .recruit .carousel_recruit-item__subtitle {
    font-family: "Open Sans", sans-serif;
    letter-spacing: 3px;
    font-size: 10px;
    text-transform: uppercase;
    margin: 0;
    color: #7e7e7e;
    font-weight: 700;
    transform: translateY(25%);
    opacity: 0;
    visibility: hidden;
    transition: 0.4s all ease-in-out;
  }

  .recruit .carousel_recruit-item__title {
    margin: 15px 0 0 0;
    /* font-family: 'Playfair Display', serif; */
    font-family: "NotoSansCJKjp", sans-serif;
    font-size: 30px;
    line-height: 45px;
    letter-spacing: 1px;
    font-weight: 700;
    color: #2c2c2c;
    transform: translateY(25%);
    opacity: 0;
    visibility: hidden;
    transition: 0.6s all ease-in-out;
  }

  .recruit .carousel_recruit-item__description {
    transform: translateY(25%);
    opacity: 0;
    visibility: hidden;
    transition: 0.6s all ease-in-out;
    margin-top: 35px;
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    color: #7e7e7e;
    line-height: 22px;
    margin-bottom: 35px;
  }

  .carousel_recruit-item__container {
    padding-left: 30px;
    padding-top: 100px;
  }

  .recruit .carousel_recruit-item__num {
    position: absolute;
    font-size: 35px;
    top: 0;
  }

  .recruit .carousel_recruit-item__btn {
    width: 35%;
    color: #2c2c2c;
    font-family: "Open Sans", sans-serif;
    letter-spacing: 3px;
    font-size: 11px;
    text-transform: uppercase;
    margin: 0;
    width: 35%;
    font-weight: 700;
    text-decoration: none;
    transform: translateY(25%);
    opacity: 0;
    visibility: hidden;
    transition: 0.6s all ease-in-out;
  }

  .recruit .carousel_recruit__nav {
    position: absolute;
    top: 265px;
    left: -24px;
    transform: rotate(90deg);
    z-index: 2;
    background-color: transparent;
  }

  .recruit .carousel_recruit__icon {
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    fill: #fff;
  }

  .recruit .carousel_recruit__arrow {
    cursor: pointer;
    display: inline-block;
    padding: 11px 15px;
    position: relative;
    background-color: $red;
  }

  .recruit .carousel_recruit__arrow:nth-child(1):before {
    content: "";
    right: 50px;
    position: absolute;
    width: 150px;
    background-color: $red;
    height: 1px;
    top: 50%;
    margin-top: -1px;
  }

  .recruit .carousel_recruit__arrow:nth-child(2):before {
    content: "";
    /* right: -3px; */
    position: absolute;
    width: 150px;
    background-color: $red;
    height: 1px;
    top: 50%;
    left: 50px;
    margin-top: -1px;
  }

  .recruit .active {
    z-index: 1;
    display: flex;
    visibility: visible;
  }

  .recruit .active .carousel_recruit-item__subtitle,
  .active .carousel_recruit-item__title,
  .active .carousel_recruit-item__description,
  .active .carousel_recruit-item__btn {
    transform: translateY(0);
    opacity: 1;
    transition: 0.6s all ease-in-out;
    visibility: visible;
  }

  .recruit .active .carousel_recruit-item__image {
    transition: 0.6s all ease-in-out;
    transform: translateX(0);
  }
}

@media screen and (max-width: 1024px) {

  .recruit .s-intoro,
  .recruit .s-intoro .head .p-headline .cover,
  .recruit .s-service {
    // background-color: #fcfcfc
  }

  .recruit .s-intoro,
  .recruit .s-intoro .body,
  .recruit .s-intoro .head,
  .recruit .s-service .inner .service__body .service__list {
    display: block;
    position: relative;
  }

  .recruit .l-kv .l-kv__read .copy {
    max-width: 296px;
  }

  .recruit .l-kv__bg .bg {
    background-image: url(/wp-content/themes/GDI/assets/images/sp/recruit/Recruit_kv.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  .recruit .js-section {
    opacity: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    will-change: opacity;
  }

  .recruit .s-intoro {
    padding: 86px 18px;
    z-index: 1;
  }

  .recruit .s-intoro .head .p-headline {
    text-align: center;
    opacity: 1;
  }

  .recruit .s-intoro .body {
    margin-top: 34px;
  }

  .recruit .s-intoro .body .text {
    font-size: 12px;
    line-height: 2;
    margin-bottom: -6px;
    margin-top: 20px;
    letter-spacing: 0.03em;
  }

  .recruit .s-intoro .body .text:first-child {
    margin-top: 0;
  }

  .recruit .s-service {
    padding: 20px 0 56px;
  }

  .recruit .s-service .inner .service__head {
    position: relative;
    width: 100%;
    padding: 90px 18px 0;
    background-color: #fff;
  }

  .recruit .s-service .inner .service__head .p-headline {
    text-align: center;
  }

  .recruit .s-service .inner .service__head .p-headline .cover {
    background-color: #fff;
  }

  .recruit .s-service .inner .service__head .text {
    font-size: 12px;
    line-height: 2;
    margin-bottom: -6px;
    margin-top: -6px;
    letter-spacing: 0.03em;
    padding: 28px 0;
  }

  .recruit .s-service .inner .service__body {
    padding: 28px 0 60px;
    position: relative;
    display: block;
    width: 100%;
    background-color: #fff;
  }

  .recruit .s-service .inner .service__body .service__list .item {
    position: relative;
    width: 100%;
    /* margin-top: 70px; */
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-property: opacity transform;
    transition-property: opacity transform;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }

  .recruit .s-service .inner .service__body .service__list .item>.link {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  .recruit .s-service .inner .service__body .service__list .item:first-child {
    margin-top: 0;
  }

  .recruit .s-service .inner .service__body .service__list .item:last-child .body .thumb .service__headline .line,
  .recruit .s-service .inner .service__body .service__list .item:nth-child(2) .body .thumb .service__headline .line,
  .recruit .s-service .inner .service__body .service__list .item:nth-child(3) .body .thumb .service__headline .line {
    height: 110px;
  }

  .recruit .s-service .inner .service__body .service__list .item.is-shown {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .recruit .s-service .inner .service__body .service__list .item .head {
    position: relative;
    padding: 0 18px;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .recruit .s-service .inner .service__body .service__list .item .head .s-title {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    font-size: 18px;
    letter-spacing: 0.05em;
    margin-top: 20px;
  }

  .recruit .s-service .inner .service__body .service__list .item .head .text {
    font-size: 12px;
    line-height: 2;
    margin-bottom: 16px;
    margin-top: 17px;
    letter-spacing: 0.03em;
  }

  .recruit .s-service .inner .service__body .service__list .item .head .text-link {
    position: absolute;
    right: -10px;
    bottom: -18px;
  }

  .recruit .s-service .inner .service__body .service__list .item .body {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    position: relative;
    padding: 0 18px;
  }

  .recruit .s-service .inner .service__body .service__list .item .body .thumb {
    position: relative;
    display: block;
  }

  .recruit .s-service .inner .service__body .service__list .item .body .thumb .service__headline {
    position: absolute;
    top: 0;
    left: -25px;
    height: 168px;
    width: 20px;
  }

  .recruit .s-service .inner .service__body .service__list .item .body .thumb .service__headline .num {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    position: absolute;
    top: 0;
    left: -2px;
    font-size: 11px;
    color: #959595;
    letter-spacing: 0.1em;
    z-index: 2;
    height: 16px;
  }

  .recruit .s-service .inner .service__body .service__list .item .body .thumb .service__headline .line {
    position: absolute;
    top: 16px;
    left: 25%;
    margin-left: -1px;
    z-index: 1;
    background-color: #000;
    content: "";
    height: 124px;
    width: 1px;
  }

  .recruit .s-end,
  .recruit .s-end .vision__body,
  .recruit .s-vision,
  .recruit .s-vision .vision__head {
    position: relative;
    display: block;
  }

  .recruit .s-end,
  .recruit .s-vision,
  .recruit .s-vision .vision__head .p-headline .cover {
    background-color: #fcfcfc;
  }

  .recruit .s-service .inner .service__body .service__list .item .body .thumb .img {
    display: block;
    width: 100%;
    height: 100%;
  }

  .recruit .s-vision {
    padding: 20px 0 0;
    z-index: 1;
  }

  .recruit .s-vision .vision__head .p-headline {
    text-align: center;
    opacity: 1;
  }

  .recruit .s-vision .vision__body {
    position: relative;
    display: block;
    margin-top: 32px;
    text-align: center;
  }

  .recruit .s-vision .vision__body .text {
    font-size: 12px;
    line-height: 2;
    margin-bottom: -6px;
    margin-top: 30px;
    letter-spacing: 0.03em;
  }

  .recruit .s-vision .vision__body .text:first-child {
    margin-top: 0;
  }

  .recruit .s-end {
    padding: 0 0 156px;
    z-index: 1;
  }

  .recruit .s-end .vision__body {
    text-align: center;
  }

  .recruit .s-end .vision__body .text {
    font-size: 12px;
    line-height: 2;
    margin-bottom: -6px;
    margin-top: 30px;
    letter-spacing: 0.03em;
  }

  .recruit .s-end .vision__body .text:first-child {
    margin-top: 0;
  }

  .recruit .p-inner .head {
    padding: 0 18px;
  }

  .recruit .head .frontend-link {
    position: absolute;
    right: -10px;
    top: 360px;
  }

  .recruit .head .frontend-link .frontend-arrow {
    position: absolute;
    top: 18%;
    right: -12px;
    width: 40px;
    height: 1px;
    content: "";
  }

  .recruit .head .backend-link {
    position: absolute;
    right: -10px;
    top: 390px;
  }

  .recruit .head .backend-link .backend-arrow {
    position: absolute;
    top: 30%;
    right: -12px;
    width: 40px;
    height: 1px;
    content: "";
  }

  .recruit .head .writer-link {
    position: absolute;
    right: -10px;
    top: 420px;
  }

  .recruit .head .writer-link .writer-arrow {
    position: absolute;
    top: 12px;
    right: -12px;
    width: 40px;
    height: 1px;
    content: "";
  }

  .recruit .csr__intoro {
    text-align: center;
    padding: 30px;
  }

  .recruit .article-title {
    font-size: 18px;
    padding: 20px;
  }
}

.index .s-company,
.index .s-company .inner .company__body .p-headline .cover {
  background-color: #fcfcfc;
}

.index .s-company {
  position: relative;
  padding: 185px 0 92px;
  margin-top: -1px;
}

.index .s-company .inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.index .s-company .inner .company__head {
  position: relative;
  display: block;
  width: 466px;
  min-width: 446px;
  margin-right: 3.33333%;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-duration: 0.8s;
  transition-duration: 0.8s;
  opacity: 0;
}

.index .s-company .inner .company__head.is-shown,
.index .s-service .inner .service__body .service__list .item.is-shown .head {
  opacity: 1;
}

.index .s-company .inner .company__head .thumb__men {
  position: absolute;
  top: 0;
  right: 0;
  width: 272px;
  height: 100%;
  z-index: 1;
}

.index .s-company .inner .company__head .thumb__men .img {
  display: block;
  max-width: 210px;
}

.index .s-company .inner .company__head .thumb__men .men-blue {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.index .s-company .inner .company__head .thumb__men .men-red {
  position: absolute;
  top: 95px;
  left: 0;
  z-index: 2;
  mix-blend-mode: multiply;
}

.index .s-company .inner .company__head .thumb__men .men-red2 {
  position: absolute;
  top: 250px;
  left: -165px;
  z-index: 3;
  mix-blend-mode: multiply;
}

.index .s-company .inner .company__head .thumb__women {
  position: absolute;
  top: 24px;
  left: 0;
  width: 294px;
  height: 100%;
  z-index: 2;
}

.index .s-company .inner .company__head .thumb__women .img {
  display: block;
  max-width: 263px;
}

.index .s-company .inner .company__head .thumb__women .women-blue {
  position: absolute;
  top: 32px;
  right: 0;
  z-index: 2;
  mix-blend-mode: multiply;
}

.index .s-company .inner .company__head .thumb__women .women-red {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.index .s-company .inner .company__body {
  position: relative;
  padding: 40px 0 60px;
  max-width: 438px;
}

.index .s-company .inner .company__body .p-headline.is-shown .cover {
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}

.index .s-company .inner .company__body .s-title {
  font-size: 42px;
  font-weight: 500;
  letter-spacing: 0.025em;
  line-height: 1.2;
  margin-top: 80px;
}

.index .s-company .inner .company__body>.text {
  position: relative;
  font-size: 15px;
  line-height: 2.1333;
  margin-bottom: 51.5px;
  margin-top: 23.5px;
  letter-spacing: 0.03em;
}

.index .s-company .inner .company__body .p-btn {
  margin-left: auto;
}

.index .s-company {
  position: relative;
  padding: 45px 0 0;
  background-color: #fcfcfc;
}

.index .s-company .inner {
  position: relative;
  display: block;
  padding: 0;
}

.index .s-company .inner .company__head {
  position: relative;
  display: block;
  width: 100%;
  max-width: 375px;
  height: 246px;
  margin: 0 auto;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-duration: 0.8s;
  transition-duration: 0.8s;
  opacity: 0;
}

.index .s-company .inner .company__head.is-shown {
  opacity: 1;
}

.index .s-company .inner .company__head .thumb__men {
  position: absolute;
  top: 0;
  right: 0;
  width: 188px;
  height: 100%;
  z-index: 1;
}

.index .s-company .inner .company__head .thumb__men .img {
  display: block;
}

.index .s-company .inner .company__head .thumb__men .men-blue {
  position: absolute;
  top: 0;
  right: 40px;
  z-index: 1;
  max-width: 120px;
}

.index .s-company .inner .company__head .thumb__men .men-red {
  position: absolute;
  top: 26px;
  left: -30px;
  z-index: 2;
  mix-blend-mode: multiply;
  max-width: 148px;
}

.index .s-company .inner .company__head .thumb__men .men-red2 {
  position: absolute;
  top: 110px;
  left: -150px;
  z-index: 2;
  mix-blend-mode: multiply;
  max-width: 148px;
}

.index .s-company .inner .company__head .thumb__women {
  position: absolute;
  top: 14px;
  left: 0;
  width: 206px;
  height: 100%;
  z-index: 2;
}

.index .s-company .inner .company__head .thumb__women .img {
  display: block;
}

.index .s-company .inner .company__head .thumb__women .women-blue {
  position: absolute;
  top: 18px;
  right: 0;
  z-index: 2;
  mix-blend-mode: multiply;
  max-width: 165px;
}

.index .s-company .inner .company__head .thumb__women .women-red {
  position: absolute;
  top: 2px;
  left: 0;
  z-index: 1;
  max-width: 159px;
}

.index .s-company .inner .company__body {
  position: relative;
  width: 100%;
  padding: 28px 38px 0;
}

.index .s-company .inner .company__body .p-headline {
  position: relative;
  font-family: NotoSansCJKjp, sans-serif;
  font-weight: 500;
  -webkit-font-feature-settings: "palt" 1;
  font-feature-settings: "palt" 1;
  font-size: 18px;
  letter-spacing: 0.1em;
}

.index .s-company .inner .company__body .p-headline.is-shown .cover {
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}

.index .s-company .inner .company__body .p-headline .cover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fcfcfc;
  -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 3s;
  transition-duration: 3s;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: right;
  transform-origin: right;
}

.index .s-company .inner .company__body .s-title {
  font-size: 28px;
  line-height: 1.75;
  margin-bottom: -10.5px;
  margin-top: 29.5px;
  font-weight: 500;
  letter-spacing: 0.025em;
}

.index .s-company .inner .company__body .text {
  font-size: 12px;
  line-height: 2;
  margin-bottom: 34px;
  margin-top: 16px;
  letter-spacing: 0.03em;
}

@include desktop {

  .company .s-mission,
  .company .s-vision {
    z-index: 1;
    position: relative;
    display: block;
  }

  .company .s-vision,
  .company .s-vision .vision__head .p-headline .cover {
    background-color: #fcfcfc;
  }

  .company .l-kv__bg .bg {
    background-image: url(/wp-content/themes/GDI/assets/images/pc/company/company_kv.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  .company .js-section {
    opacity: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    will-change: opacity;
  }

  .company .s-vision {
    padding: 116px 0 76px;
  }

  .company .s-vision .vision__head {
    position: relative;
    display: block;
    max-width: 486px;
    margin-left: auto;
    margin-right: auto;
  }

  .company .s-vision .vision__head .p-headline {
    text-align: center;
    opacity: 1;
  }

  .company .s-vision .vision__body {
    position: relative;
    display: block;
    margin-top: 76px;
    text-align: center;
  }

  .company .s-vision .vision__body .text {
    font-size: 15px;
    line-height: 2.1333;
    margin-bottom: -8.5px;
    margin-top: 37.5px;
    letter-spacing: 0.03em;
  }

  .company .s-vision .vision__body .text:first-child {
    margin-top: 0;
  }

  .company .s-explores .inner .explores__body .text,
  .company .s-mission .inner .mission__body .text {
    margin-bottom: -8.5px;
    margin-top: 45.5px;
    font-size: 15px;
    line-height: 2.1333;
    letter-spacing: 0.03em;
  }

  .company .s-mission {
    padding: 80px 0 284px;
    background-color: #fff;
  }

  .company .s-mission .inner {
    position: relative;
    overflow: visible;
  }

  .company .s-mission .inner .mission__body {
    position: relative;
    width: 46.89%;
    margin-left: auto;
    padding-top: 36px;
  }

  .company .s-mission .inner .mission__body .p-headline .cover {
    background-color: #fff;
  }

  .company .s-mission .inner .mission__body .text:first-of-type {
    margin-top: 70px;
  }

  .company .s-mission .inner .mission__thumb {
    position: absolute;
    display: block;
    top: 0;
    left: -180px;
    width: 64.49%;
    height: auto;
    overflow: hidden;
  }

  .company .s-mission .inner .mission__thumb.is-shown .cover {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }

  .company .s-mission .inner .mission__thumb.is-shown .thumb .img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: brightness(100%);
    filter: brightness(100%);
  }

  .company .s-mission .inner .mission__thumb::before {
    display: block;
    content: "";
    padding-top: 100%;
  }

  .company .s-mission .inner .mission__thumb .thumb {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .company .s-mission .inner .mission__thumb .thumb .img {
    display: block;
    width: 100%;
    height: auto;
    -webkit-transition: 1.6s -webkit-transform, -webkit-filter 3s;
    transition: 1.6s -webkit-transform, -webkit-filter 3s;
    transition: 1.6s transform, filter 3s;
    transition: 1.6s transform, filter 3s, 1.6s -webkit-transform,
      -webkit-filter 3s;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-filter: brightness(200%);
    filter: brightness(200%);
  }

  .company .s-mission .inner .mission__thumb .cover {
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    background-color: #fff;
    z-index: 1;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 3s;
    transition-duration: 3s;
    -webkit-transform-origin: left top;
    transform-origin: left top;
    overflow: hidden;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }

  .company .s-explores {
    position: relative;
    display: block;
    padding: 104px 0;
    background-color: #fcfcfc;
    z-index: 1;
  }

  .company .s-explores .inner {
    position: relative;
    overflow: visible;
  }

  .company .s-explores .inner .explores__body {
    padding-top: 22px;
    position: relative;
    width: 46.89%;
    margin-right: auto;
  }

  .company .s-explores .inner .explores__body .p-headline .cover {
    background-color: #fcfcfc;
  }

  .company .s-explores .inner .explores__body .text:first-of-type {
    margin-top: 70px;
  }

  .company .s-explores .inner .explores__thumb {
    position: absolute;
    top: 0;
    right: -180px;
    display: block;
    width: 64.49%;
    height: auto;
    overflow: hidden;
  }

  .company .s-explores .inner .explores__thumb.is-shown .cover {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }

  .company .s-explores .inner .explores__thumb.is-shown .thumb .img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: brightness(100%);
    filter: brightness(100%);
  }

  .company .s-explores .inner .explores__thumb::before {
    content: "";
    display: block;
    padding-top: 100%;
  }

  .company .s-explores .inner .explores__thumb .cover {
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    background-color: #fcfcfc;
    z-index: 1;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 2s;
    transition-duration: 2s;
    -webkit-transform-origin: left top;
    transform-origin: left top;
    overflow: hidden;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }

  .company .s-explores .inner .explores__thumb .thumb {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow: hidden;
  }

  .company .s-explores .inner .explores__thumb .thumb .img {
    display: block;
    width: 100%;
    height: auto;
    -webkit-transition: 1.6s -webkit-transform, -webkit-filter 3s;
    transition: 1.6s -webkit-transform, -webkit-filter 3s;
    transition: 1.6s transform, filter 3s;
    transition: 1.6s transform, filter 3s, 1.6s -webkit-transform,
      -webkit-filter 3s;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-filter: brightness(200%);
    filter: brightness(200%);
  }

  .company .s-media,
  .company .s-service {
    position: relative;
    display: block;
    /* background-color: #fcfcfc */
    padding: 10px 0 158px;
  }

  .company .s-service {
    background-color: none;
  }

  .company .s-media .bg,
  .company .s-service .bg {
    position: absolute;
    top: 420px;
    width: 91.21523%;
    height: 640px;
    content: "";
    background-color: #fff;
    pointer-events: none;
  }

  .company .s-csr,
  .company .s-csr .inner .csr__intoro .p-headline .cover,
  .company .s-media .inner .intro .p-headline .cover,
  .company .s-service .inner .intro .p-headline .cover {
    /* background-color: #fcfcfc */
  }

  .company .s-media .inner,
  .company .s-service .inner {
    position: relative;
  }

  .company .s-service .inner .service__body .service__list .item {
    width: 100%;
    /* margin-top: 64px; */
    padding-top: 10px;
    padding-left: 100px;
  }

  .company .s-media .inner .intro,
  .company .s-service .inner .intro {
    position: relative;
    display: block;
    max-width: 486px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 300px;
  }

  .company .s-media .inner .intro .p-headline,
  .company .s-service .inner .intro .p-headline {
    text-align: center;
  }

  .company .s-media .inner .body,
  .company .s-service .inner .body {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    /* padding-top: 286px */
  }

  .company .s-media .inner .body.is-shown .thumb__content .thumb,
  .company .s-service .inner .body.is-shown .thumb__content .thumb {
    opacity: 1;
  }

  .company .s-media .inner .body.is-shown .thumb__content .thumb .cover,
  .company .s-service .inner .body.is-shown .thumb__content .thumb .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .company .s-media .inner .body.is-shown .thumb__content .thumb .img,
  .company .s-service .inner .body.is-shown .thumb__content .thumb .img {
    -webkit-filter: brightness(100%);
    filter: brightness(100%);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  .company .s-media .inner .body .thumb__content,
  .company .s-service .inner .body .thumb__content {
    position: absolute;
    top: -225px;
    width: 75.88358%;
    height: 100%;
    z-index: 1;
    overflow: hidden;
  }

  .company .s-media .inner .body .thumb__content .thumb,
  .company .s-service .inner .body .thumb__content .thumb {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    max-width: 730px;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    opacity: 0;
    -webkit-transition-duration: 1.2s;
    transition-duration: 1.2s;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: opacity;
    transition-property: opacity;
  }

  .company .s-media .inner .body .thumb__content .thumb .cover,
  .company .s-service .inner .body .thumb__content .thumb .cover {
    display: none;
  }

  .company .s-media .inner .body .thumb__content .thumb .img,
  .company .s-service .inner .body .thumb__content .thumb .img {
    display: block;
    width: 100%;
    height: auto;
    -webkit-transition: 1.2s -webkit-transform, -webkit-filter 2s;
    transition: 1.2s -webkit-transform, -webkit-filter 2s;
    transition: 1.2s transform, filter 2s;
    transition: 1.2s transform, filter 2s, 1.2s -webkit-transform,
      -webkit-filter 2s;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow: hidden;
    -webkit-filter: brightness(200%);
    filter: brightness(200%);
    will-change: filter, transform;
  }

  .company .s-media .inner .body .text__content,
  .company .s-service .inner .body .text__content {
    width: 46.875%;
    z-index: 2;
  }

  .company .s-media .inner .body .text__content>.text,
  .company .s-service .inner .body .text__content>.text {
    font-size: 15px;
    line-height: 2.1333;
    margin-bottom: -8.5px;
    margin-top: 45.5px;
    letter-spacing: 0.03em;
    color: #333;
  }

  .company .s-media .inner .body .text__content>.text:first-child,
  .company .s-service .inner .body .text__content>.text:first-child {
    margin-top: 0;
  }

  .company .s-media .inner .p-btn--b,
  .company .s-service .inner .p-btn--b {
    position: absolute;
    right: 0;
    left: 0;
    bottom: -120px;
    margin: 0 auto;
    z-index: 3;
  }

  .company .s-media {
    padding: 88px 0;
  }

  .company .s-media .bg {
    right: 0;
  }

  .company .s-media .thumb__content {
    left: 0;
  }

  .company .service--first {
    margin-top: 0;
    padding: 188px 0 90px;
  }

  .company .service--first .bg {
    top: 494px;
    left: 0;
  }

  .company .service--first .body .thumb__content {
    right: 0;
  }

  .company .service--first .inner .p-btn--b {
    bottom: -126px;
  }

  .company .service--second {
    padding: 386px 0 96px;
  }

  .company .service--second .bg {
    right: 0;
  }

  .company .service--second .inner .p-btn--b {
    bottom: -112px;
  }

  .company .service--second .inner .body .thumb__content {
    top: -216px;
    left: 0;
  }

  .company .service--last {
    padding: 360px 0 96px;
  }

  .company .service--last .bg {
    left: 0;
  }

  .company .service--last .inner .body .thumb__content {
    top: -216px;
    right: 0;
  }

  .company .service--last .inner .p-btn--b {
    bottom: -144px;
  }

  .company .s-csr {
    position: relative;
    display: block;
    padding: 0 0 10px;
    z-index: 1;
  }

  .company .s-csr .inner .csr__intoro {
    position: relative;
    display: block;
    max-width: 486px;
    margin-left: auto;
    margin-right: auto;
  }

  .company .s-csr .inner .csr__intoro .p-headline {
    text-align: center;
  }

  .company .s-csr .inner .csr__body {
    margin-top: 80px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .company .s-csr .inner .csr__body .item {
    width: 30.625%;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-property: opacity transform;
    transition-property: opacity transform;
  }

  .company .s-csr .inner .csr__body .item.is-shown {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    padding: 10px;
  }

  .company .s-csr .inner .csr__body .item .link,
  .company .s-csr .inner .csr__body .item .link .thumb {
    position: relative;
    display: block;
  }

  .company .s-csr .inner .csr__body .item .link .thumb .img {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
  }

  .company .s-csr .inner .csr__body .item .link .csr__text {
    font-size: 13px;
    line-height: 2.307;
    margin-bottom: 11.5px;
    margin-top: 15.5px;
    letter-spacing: 0.03em;
  }

  .company .s-csr .inner .csr__body .item .text-link {
    width: 148px;
    -webkit-transition: 0.6s opacity;
    transition: 0.6s opacity;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
  }

  .company .s-csr .inner .csr__body .item .text-link.is-shown {
    opacity: 1;
  }

  .company .s-csr .inner .csr__body .item .text-link:hover .text {
    color: #151515;
  }

  .company .s-csr .inner .csr__body .item .text-link .text {
    font-size: 16px;
    letter-spacing: 0.05em;
    color: $red;
    font-weight: 700;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }

  .company .s-service .inner .service__body .service__list .item.is-shown .body {
    opacity: 1;
    padding-top: 60px;
  }
}

@media screen and (max-width: 1024px) {

  .company .s-mission,
  .company .s-vision {
    z-index: 1;
    display: block;
    background-color: #fcfcfc;
  }

  .company .s-explores .inner,
  .company .s-mission .inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    position: relative;
  }

  .company .s-explores,
  .company .s-explores .inner .explores__body .p-headline .cover,
  .company .s-media,
  .company .s-media .inner .body,
  .company .s-media .inner .intro,
  .company .s-media .inner .intro .p-headline .cover,
  .company .s-mission,
  .company .s-mission .inner .mission__body,
  .company .s-mission .inner .mission__body .p-headline .cover,
  .company .s-service,
  .company .s-service .bg,
  .company .s-service .inner .intro .p-headline .cover,
  .company .s-vision,
  .company .s-vision .vision__head .p-headline .cover {
    background-color: #fcfcfc;
  }

  .company .l-kv__bg .bg {
    background-image: url(/wp-content/themes/GDI/assets/images/pc/company/company_kv.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  .company .js-section {
    opacity: 0;
    will-change: opacity;
  }

  .company .s-vision {
    position: relative;
    padding: 88px 0 0;
  }

  .company .s-vision .vision__head {
    position: relative;
    display: block;
  }

  .company .s-vision .vision__head .p-headline {
    text-align: center;
    opacity: 1;
  }

  .company .s-vision .vision__body {
    position: relative;
    display: block;
    margin-top: 32px;
    text-align: center;
  }

  .company .s-vision .vision__body .text {
    font-size: 12px;
    line-height: 2;
    margin-bottom: -6px;
    margin-top: 30px;
    letter-spacing: 0.03em;
  }

  .company .s-vision .vision__body .text:first-child {
    margin-top: 0;
  }

  .company .s-explores .inner .explores__body .text,
  .company .s-media .inner .body .text__content>.text,
  .company .s-mission .inner .mission__body .text {
    line-height: 2;
    margin-bottom: -6px;
    margin-top: 30px;
    font-size: 12px;
    letter-spacing: 0.03em;
  }

  .company .s-mission {
    position: relative;
  }

  .company .s-mission .inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0 20px;
  }

  .company .s-mission .inner .mission__body {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    position: relative;
    width: 100%;
    padding: 24px 18px 0;
  }

  .company .s-mission .inner .mission__body .p-headline {
    text-align: center;
  }

  .company .s-mission .inner .mission__thumb {
    display: block;
    width: 100%;
    height: 100%;
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    padding: 64px 18px 0;
    overflow: visible;
  }

  .company .s-mission .inner .mission__thumb .thumb {
    position: relative;
    display: block;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

  .company .s-mission .inner .mission__thumb .thumb .img {
    display: block;
    width: 100%;
    height: auto;
  }

  .company .s-explores {
    position: relative;
    display: block;
    padding: 0 0 44px;
    z-index: 1;
  }

  .company .s-explores .inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .company .s-explores .inner .explores__body {
    position: relative;
    width: 100%;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    padding: 24px 18px 0;
  }

  .company .s-explores .inner .explores__body .p-headline {
    text-align: center;
  }

  .company .s-explores .inner .explores__thumb {
    display: block;
    width: 100%;
    height: 100%;
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    padding: 64px 18px 0;
    overflow: visible;
  }

  .company .s-explores .inner .explores__thumb .thumb {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
  }

  .company .s-explores .inner .explores__thumb .thumb .img {
    display: block;
    width: 100%;
    height: auto;
  }

  .company .s-media {
    position: relative;
    display: block;
    z-index: 1;
  }

  .company .s-media .bg {
    display: none;
  }

  .company .s-media .inner {
    position: relative;
    padding-bottom: 21px;
  }

  .company .s-media .inner .intro {
    padding: 44px 0 36px;
    position: relative;
    display: block;
  }

  .company .s-media .inner .intro .p-headline {
    text-align: center;
  }

  .company .s-media .inner .body {
    position: relative;
    display: block;
    padding: 0 18px 62px;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: opacity, transform;
    transition-property: opacity, transform, -webkit-transform;
  }

  .company .s-media .inner .body.is-shown {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .company .s-media .inner .body .text__content {
    position: relative;
    width: 100%;
  }

  .company .s-media .inner .body .text__content>.text {
    color: #333;
  }

  .company .s-media .inner .body .thumb__content {
    position: relative;
    display: block;
    width: 100%;
  }

  .company .s-media .inner .body .thumb__content .thumb {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
  }

  .company .s-media .inner .body .thumb__content .thumb .img {
    display: block;
    width: 100%;
    height: auto;
  }

  .company .s-media .inner .body .p-btn--b {
    position: absolute;
    bottom: -20px;
    right: 0;
    left: 0;
    margin: 0 auto;
    width: 147px;
  }

  .company .s-media .inner .body .p-btn--b .link {
    line-height: 46px;
  }

  .company .s-media .inner .body .p-btn--b .text {
    line-height: 46px;
    font-size: 12px;
  }

  .company .s-service {
    position: relative;
    display: block;
    /* padding: 42px 0 0; */
    z-index: 1;
  }

  .company .s-service.service--first {
    padding: 88px 0 31px;
  }

  .company .s-service.service--second {
    padding: 42px 0 31px;
  }

  .company .s-service.service--last {
    padding: 0;
  }

  .company .s-service .bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    display: block;
    z-index: -1;
  }

  .company .s-service .inner .intro {
    position: relative;
    display: block;
  }

  .company .s-service .inner .intro .p-headline {
    position: relative;
    text-align: center;
  }

  .company .s-service .inner .body {
    position: relative;
    display: block;
    margin-top: 145px;
    padding: 133px 18px 62px;
    background-color: #fff;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    /* opacity: 0; */
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-property: opacity transform;
    transition-property: opacity transform;
  }

  .company .s-csr,
  .company .s-csr .inner .csr__intoro .p-headline .cover {
    /* background-color: #fcfcfc; */
  }

  .company .s-service .inner .body.is-shown {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .company .s-service .inner .body .text__content {
    position: relative;
    width: 100%;
  }

  .company .s-service .inner .body .text__content>.text {
    font-size: 12px;
    line-height: 2;
    margin-bottom: -6px;
    margin-top: 30px;
    letter-spacing: 0.03em;
    color: #333;
  }

  .company .s-service .inner .body .text__content>.text:first-child {
    margin-top: 0;
  }

  .company .s-service .inner .body .thumb__content {
    position: absolute;
    top: -88px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    margin: 0 auto;
    display: block;
    width: 297px;
  }

  .company .s-service .inner .body .thumb__content .thumb {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
  }

  .company .s-service .inner .body .thumb__content .thumb .img {
    display: block;
    width: 100%;
    height: auto;
  }

  .company .s-service .inner .body .p-btn--b {
    position: absolute;
    bottom: -20px;
    right: 0;
    left: 0;
    margin: 0 auto;
    width: 147px;
  }

  .company .s-service .inner .body .p-btn--b .link {
    line-height: 46px;
  }

  .company .s-service .inner .body .p-btn--b .text {
    line-height: 46px;
    font-size: 12px;
  }

  .company .s-csr {
    position: relative;
    display: block;
    padding: 80px 0 0;
    z-index: 1;
  }

  .company .s-csr .inner .csr__intoro {
    position: relative;
    display: block;
  }

  .company .s-csr .inner .csr__intoro .p-headline {
    text-align: center;
  }

  .company .s-csr .inner .csr__body {
    margin-top: 34px;
    padding: 5px 18px;
  }

  .company .s-csr .inner .csr__body .item {
    position: relative;
    width: 100%;
    // margin-top: 84px;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: opacity, transform;
    transition-property: opacity, transform, -webkit-transform;
  }

  .company .s-csr .inner .csr__body .item.is-shown {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .company .s-csr .inner .csr__body .item:first-child {
    margin-top: 0;
  }

  .company .s-csr .inner .csr__body .item>.link,
  .company .s-csr .inner .csr__body .item>.link .thumb {
    position: relative;
    display: block;
  }

  .company .s-csr .inner .csr__body .item>.link .thumb .img {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
  }

  .company .s-csr .inner .csr__body .item>.link .csr__text {
    font-size: 12px;
    line-height: 2;
    margin-bottom: -6px;
    margin-top: 30px;
    letter-spacing: 0.03em;
  }

  .company .s-csr .inner .csr__body .item .text-link {
    position: absolute;
    right: -8px;
    bottom: -26px;
  }

  .company .s-csr .inner .csr__body .item .text-link .arrow {
    right: -36px;
    margin-top: 1px;
  }

  .company .news__content {
    padding: 0 20px 0 20px !important;
  }

  .company .news__head {
    padding-left: 3.33333% !important;
    padding-right: 9px;
    text-align: unset !important;
  }

  .company .company_profile {
    margin-left: -400px !important;
  }

  .company .pc {
    display: none !important;
  }

  .company .sp {
    display: block !important;
  }

  .company .vision__head {
    margin-left: 300px !important;
    padding-bottom: 50px !important;
  }

  /* .company .kanren {
        margin-left: -200px !important;
    }

    .company .p-headline {
        margin-left: -250px;
    } */

  .company .arrow {
    position: absolute;
    top: 50%;
    right: -23px;
    width: 40px;
    height: 1px;
    content: "";
  }

  .sp-kanata {
    display: block !important;
  }

  .company .s-service .inner .service__body .service__list .item {
    position: relative;
    width: 100%;
    margin-top: -150px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-property: opacity transform;
    transition-property: opacity transform;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }

  .company .s-service .inner .service__body .service__list .item .body {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    position: relative;
    padding: 0 0;
  }

  .company .s-service .inner .service__body .service__list {
    position: relative;
    display: block;
    margin-top: -125px;
  }

  .company .s-news .news__content .news__head .p-headline {
    position: relative;
    /* background-color: #fff; */
    padding: 85px 0 0;
  }
}

@include desktop {

  .explorers .s-intoro,
  .explorers .s-intoro .body,
  .explorers .s-intoro .head,
  .explorers .s-service .inner .service__body .service__list {
    /* display: block; */
    position: relative;
    display: flex;
  }

  .explorers .s-intoro,
  .explorers .s-intoro .head .p-headline .cover,
  .explorers .s-service,
  .explorers .s-service .inner .service__head .p-headline .cover {
    /* background-color: #f8f8f8;
        background-color: #fff; */
    background-color: #fcfcfc;
  }

  .explorers .l-kv .l-kv__read .copy {
    max-width: 429px;
  }

  .explorers .l-kv__bg .bg {
    background-image: url(/wp-content/themes/GDI/assets/images/pc/explorers/Explorers_kv.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  .explorers .js-section {
    opacity: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    will-change: opacity;
  }

  .explorers .s-intoro {
    padding: 116px 0 180px;
    z-index: 1;
  }

  .explorers .s-intoro .head {
    max-width: 486px;
    margin-left: auto;
    margin-right: auto;
  }

  .explorers .s-intoro .head .p-headline {
    text-align: center;
    opacity: 1;
  }

  .explorers .s-intoro .body {
    max-width: 724px;
    margin: 76px auto 0;
  }

  .explorers .s-intoro .body .text {
    font-size: 15px;
    line-height: 2.1333;
    margin-bottom: -8.5px;
    margin-top: 37.5px;
    letter-spacing: 0.03em;
  }

  .explorers .s-intoro .body .text:first-child {
    margin-top: 0;
  }

  .explorers .s-service {
    padding: 54px 0 158px;
  }

  .explorers .s-service .inner .service__head {
    width: 486px;
    margin-left: auto;
    margin-right: auto;
  }

  .explorers .s-service .inner .service__head .p-headline {
    text-align: center;
  }

  .explorers .s-service .inner .service__head .p-headline.is-shown .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .explorers .s-service .inner .service__head .text {
    font-size: 15px;
    line-height: 2.1333;
    margin-bottom: 61.5px;
    margin-top: 71.5px;
    letter-spacing: 0.03em;
  }

  .explorers .s-service .inner .service__body {
    position: relative;
    display: block;
    width: 100%;
    background-color: #fcfcfc;
  }

  .explorers .s-service .inner .service__body .service__list .item {
    /* display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between; */
    width: 100%;
    /* margin-top: 64px; */
    padding-top: 10px;
    padding-left: 80px;
  }

  .explorers .s-service .inner .service__body .service__list .item:first-child {
    margin-top: 0;
    /* -webkit-box-shadow: 10px 10px 5px 1px rgba(0, 0, 0, 0.26);
        -moz-box-shadow: 10px 10px 5px 1px rgba(0, 0, 0, 0.26);
        box-shadow: 10px 10px 5px 1px rgba(0, 0, 0, 0.26) */
  }

  .explorers .s-service .inner .service__body .service__list .item.is-shown .head {
    opacity: 1;
  }

  .explorers .s-service .inner .service__body .service__list .item.is-shown .head .service__headline .num {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .explorers .s-service .inner .service__body .service__list .item.is-shown .head .service__headline .line {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition-delay: 0.6s;
    transition-delay: 0.6s;
  }

  .explorers .s-service .inner .service__body .service__list .item.is-shown .head .service__headline .en {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .explorers .s-service .inner .service__body .service__list .item.is-shown .head .s-title .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .explorers .s-service .inner .service__body .service__list .item.is-shown .head .s-link {
    text-decoration: underline;
    font-size: 13px;
  }

  .explorers .s-service .inner .service__body .service__list .item .head {
    position: relative;
    width: 335px;
    padding-top: 8px;
  }

  .explorers .s-service .inner .service__body .service__list .item .head .service__headline {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    overflow: hidden;
  }

  .explorers .s-service .inner .service__body .service__list .item .head .service__headline .num {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    position: relative;
    display: inline-block;
    font-size: 12px;
    color: #959595;
    letter-spacing: 0.1em;
    width: 35px;
    z-index: 2;
    overflow: hidden;
    background-color: #fcfcfc;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }

  .explorers .s-service .inner .service__body .service__list .item .head .service__headline .line {
    z-index: 1;
    background-color: #959595;
    content: "";
    height: 1px;
    width: 100%;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -1px;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition-duration: 1.8s;
    transition-duration: 1.8s;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }

  .explorers .s-service .inner .service__body .service__list .item .head .s-title {
    position: relative;
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    font-size: 20px;
    letter-spacing: 0.05em;
    margin-top: 28px;
  }

  .explorers .s-service .inner .service__body .service__list .item .head .s-title .cover {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: -10px;
    background-color: #fcfcfc;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 3s;
    transition-duration: 3s;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: right;
    transform-origin: right;
  }

  .explorers .s-service .inner .service__body .service__list .item .head .text {
    font-size: 13px;
    line-height: 2.1538;
    margin-bottom: 12.5px;
    margin-top: 52.5px;
    letter-spacing: 0.03em;
  }

  .explorers .s-end .vision__body .text,
  .explorers .s-vision .vision__body .text {
    font-size: 15px;
    line-height: 2.1333;
    margin-bottom: -8.5px;
    letter-spacing: 0.03em;
  }

  .explorers .s-service .inner .service__body .service__list .item .head .text-link {
    width: 92px;
    -webkit-transition: 0.6s opacity;
    transition: 0.6s opacity;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
    -webkit-transition-delay: 0.6s;
    transition-delay: 0.6s;
  }

  .explorers .s-service .inner .service__body .service__list .item .head .text-link.is-shown,
  .explorers .s-service .inner .service__body .service__list .item.is-shown .body {
    opacity: 1;
  }

  .explorers .s-service .inner .service__body .service__list .item .body {
    position: relative;
    max-width: 512px;
    padding-top: 58px;
    /* margin-left: 3.33333%; */
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    overflow: hidden;
  }

  .explorers .s-service .inner .service__body .service__list .item .body .thumb {
    position: relative;
    display: block;
    overflow: hidden;
    /* width: 100%;
        height: auto; */
    width: 335px;
    height: 300px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .explorers .s-service .inner .service__body .service__list .item .body .thumb .cover {
    position: absolute;
    right: 0;
    left: 0;
    top: -5px;
    bottom: -5px;
    background-color: #fcfcfc;
    z-index: 1;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 2s;
    transition-duration: 2s;
    -webkit-transform-origin: right;
    transform-origin: right;
    overflow: hidden;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  .explorers .s-service .inner .service__body .service__list .item .body .thumb .img {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    -webkit-transition: 1.6s -webkit-transform, -webkit-filter 3s;
    transition: 1.6s -webkit-transform, -webkit-filter 3s;
    transition: 1.6s transform, filter 3s;
    transition: 1.6s transform, filter 3s, 1.6s -webkit-transform,
      -webkit-filter 3s;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow: hidden;
    -webkit-filter: brightness(200%);
    filter: brightness(200%);
    will-change: filter, transform;
  }

  .explorers .s-service .inner .service__body .service__list .item.is-shown .body .thumb .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .explorers .s-service .inner .service__body .service__list .item.is-shown .body .thumb .img {
    -webkit-filter: brightness(100%);
    filter: brightness(100%);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  .explorers .s-vision {
    position: relative;
    display: block;
    padding: 116px 0 76px;
    background-color: #fcfcfc;
    z-index: 1;
  }

  .explorers .s-vision2 {
    position: relative;
    display: block;
    padding: 116px 0 76px;
    background-color: #f8f8f8;
    z-index: 1;
  }

  .explorers .s-vision .vision__head {
    position: relative;
    display: block;
    max-width: 486px;
    margin-left: auto;
    margin-right: auto;
  }

  .explorers .s-vision .vision__head .p-headline {
    text-align: center;
    opacity: 1;
  }

  .explorers .s-vision .vision__head .p-headline .cover {
    /* background-color: #fcfcfc; */
  }

  .explorers .s-vision .vision__body {
    position: relative;
    display: block;
    margin-top: 76px;
    text-align: center;
  }

  .explorers .s-vision .vision__body .text {
    margin-top: 37.5px;
  }

  .explorers .s-vision .vision__body .text:first-child {
    margin-top: 0;
  }

  .explorers .s-end {
    position: relative;
    display: block;
    padding: 0 0 176px;
    background-color: #fcfcfc;
    z-index: 1;
  }

  .explorers .s-end .vision__body {
    position: relative;
    display: block;
    margin-top: 0;
    text-align: center;
  }

  .explorers .s-end .vision__body .text {
    margin-top: 37.5px;
  }

  .explorers .s-end .vision__body .text:first-child {
    margin-top: 0;
  }

  .explorers .head .text .frontend-link {
    position: absolute;
    right: -10px;
    top: 260px;
  }

  .explorers .head .text .frontend-link .frontend-arrow {
    position: absolute;
    top: 50%;
    right: -12px;
    width: 40px;
    height: 1px;
    content: "";
  }

  .explorers .head .text .backend-link {
    position: absolute;
    right: -10px;
    top: 290px;
  }

  .explorers .head .text .backend-link .backend-arrow {
    position: absolute;
    top: 50%;
    right: -12px;
    width: 40px;
    height: 1px;
    content: "";
  }

  .explorers .head .text .writer-link {
    position: absolute;
    right: -10px;
    top: 320px;
  }

  .explorers .head .text .writer-link .writer-arrow {
    position: absolute;
    top: 50%;
    right: -12px;
    width: 40px;
    height: 1px;
    content: "";
  }

  .explorers {
    position: relative;
    display: block;
    // padding-top: 104px;
    // background-color: #fcfcfc;
    z-index: 1;
  }

  .explorers .s-news {
    padding-top: 20px;
    background-color: #f8f8f8;
  }

  .explorers .news__content {
    position: relative;
    background-color: #fff;
    margin-left: auto;
    margin-right: auto;
    max-width: 864px;
    padding: 54px 110px 84px 94px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;
    opacity: 0;
  }

  .explorers .news__content.is-shown {
    opacity: 1;
  }

  .explorers .news__content.is-shown .news__head .p-headline .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .explorers .news__content.is-shown .news__body {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  .explorers .news__content .news__head {
    position: relative;
    /* width: 24.58333%; */
    padding-left: 3.33333%;
    left: 245px;
    top: -25px;
  }

  .explorers .news__content .news__head .p-headline {
    opacity: 1;
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
  }

  .explorers .news__content .news__head .p-headline .cover {
    background-color: #fff;
  }

  /* .s-about,
    .index .s-about .inner .about__body .p-headline .cover {
        background-color: #fcfcfc
    } */

  .explorers .news__content .news__body {
    position: relative;
    /* width: calc(100% - 236px); */
    width: 100%;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    -webkit-transition-duration: 1s;
    transition-duration: 1s;
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
    opacity: 0;
  }

  .explorers .news__content .news__body .news__list {
    position: relative;
    /* padding-left: 36px */
    margin-left: -125px;
    width: 100%;
  }

  .explorers .news__content .news__body .news__list .item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding: 20px 24px 20px 0;
  }

  .explorers .news__content .news__body .news__list .item:first-child {
    padding-top: 50px;
  }

  .explorers .news__content .news__body .news__list .item .head {
    /* width: 174px; */
    width: 1000px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-right: 16px;
    line-height: 1.4;
  }

  .explorers .news__content .news__body .news__list .item .head .date {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    font-size: 13px;
    letter-spacing: 0.03em;
    margin-right: 24px;
  }

  .explorers .news__content .news__body .news__list .item .head .tag {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    width: 50px;
    font-size: 13px;
    letter-spacing: 0.03em;
  }

  .explorers .news__content .news__body .news__list .item .link,
  .explorers .news__content .news__body .news__list .item .text {
    display: block;
    font-size: 13px;
    letter-spacing: 0.03em;
    line-height: 1.4;
    font-weight: 500;
    color: #000;
    -webkit-transition-property: opacity;
    transition-property: opacity;
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;
  }

  .explorers .news__content .news__body .news__list .item .link:hover {
    opacity: 0.7;
  }

  .explorers .news__content .news__body .p-btn {
    position: absolute;
    bottom: -112px;
    left: 36px;
  }

  .explorers .s-vision2 .vision__head .p-headline .cover {}

  .explorers .s-mission,
  .explorers .s-vision {
    z-index: 1;
    position: relative;
    display: block;
  }

  .explorers .s-explores .inner .explores__body .text,
  .explorers .s-mission .inner .mission__body .text {
    margin-bottom: -8.5px;
    margin-top: 45.5px;
    font-size: 15px;
    line-height: 2.1333;
    letter-spacing: 0.03em;
  }

  .explorers .s-mission {
    padding: 80px 0 284px;
    background-color: #fff;
  }

  .explorers .s-mission .inner {
    position: relative;
    overflow: visible;
  }

  .explorers .s-mission .inner .mission__body {
    /* position: relative;
        width: 46.89%;
        margin-left: auto;
        padding-top: 36px */
  }

  .explorers .s-mission .inner .mission__body .p-headline .cover {
    background-color: #fff;
  }

  .explorers .s-mission .inner .mission__body .text:first-of-type {
    margin-top: 70px;
  }

  .explorers .s-mission .inner .mission__thumb {
    /* position: absolute;
        display: block;
        top: 0;
        left: -180px;
        width: 64.49%;
        height: auto;
        overflow: hidden */
  }

  .explorers .s-mission .inner .mission__thumb.is-shown .cover {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }

  .explorers .s-mission .inner .mission__thumb.is-shown .thumb .img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: brightness(100%);
    filter: brightness(100%);
  }

  .explorers .s-mission .inner .mission__thumb::before {
    display: block;
    content: "";
    padding-top: 100%;
  }

  .explorers .s-mission .inner .mission__thumb .thumb {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .explorers .s-mission .inner .mission__thumb .thumb .img {
    display: block;
    width: 100%;
    height: auto;
    -webkit-transition: 1.6s -webkit-transform, -webkit-filter 3s;
    transition: 1.6s -webkit-transform, -webkit-filter 3s;
    transition: 1.6s transform, filter 3s;
    transition: 1.6s transform, filter 3s, 1.6s -webkit-transform,
      -webkit-filter 3s;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-filter: brightness(200%);
    filter: brightness(200%);
  }

  .explorers .s-mission .inner .mission__thumb .cover {
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    background-color: #fff;
    z-index: 1;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 3s;
    transition-duration: 3s;
    -webkit-transform-origin: left top;
    transform-origin: left top;
    overflow: hidden;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }

  .explorers .s-service .p-inner .service__body .service__list .item .head .p-btn--b {
    width: 200px;
    background-color: $red;
    overflow: hidden;
  }

  .explorers .s-media .inner .body,
  .explorers .s-service .inner .body {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-top: 86px;
    /* padding-top: 286px */
  }

  .explorers .s-media .inner .body.is-shown .thumb__content .thumb,
  .explorers .s-service .inner .body.is-shown .thumb__content .thumb {
    opacity: 1;
  }

  .explorers .s-media .inner .body.is-shown .thumb__content .thumb .cover,
  .explorers .s-service .inner .body.is-shown .thumb__content .thumb .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .explorers .s-media .inner .body.is-shown .thumb__content .thumb .img,
  .explorers .s-service .inner .body.is-shown .thumb__content .thumb .img {
    -webkit-filter: brightness(100%);
    filter: brightness(100%);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  .explorers .s-media .inner .body .thumb__content,
  .explorers .s-service .inner .body .thumb__content {
    position: absolute;
    top: -225px;
    width: 75.88358%;
    height: 100%;
    z-index: 1;
    overflow: hidden;
  }

  .explorers .s-media .inner .body .thumb__content .thumb,
  .explorers .s-service .inner .body .thumb__content .thumb {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    max-width: 730px;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    opacity: 0;
    -webkit-transition-duration: 1.2s;
    transition-duration: 1.2s;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: opacity;
    transition-property: opacity;
  }

  .explorers .s-media .inner .body .thumb__content .thumb .cover,
  .explorers .s-service .inner .body .thumb__content .thumb .cover {
    display: none;
  }

  .explorers .s-media .inner .body .thumb__content .thumb .img,
  .explorers .s-service .inner .body .thumb__content .thumb .img {
    display: block;
    width: 100%;
    height: auto;
    -webkit-transition: 1.2s -webkit-transform, -webkit-filter 2s;
    transition: 1.2s -webkit-transform, -webkit-filter 2s;
    transition: 1.2s transform, filter 2s;
    transition: 1.2s transform, filter 2s, 1.2s -webkit-transform,
      -webkit-filter 2s;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow: hidden;
    -webkit-filter: brightness(200%);
    filter: brightness(200%);
    will-change: filter, transform;
  }

  .explorers .s-media .inner .body .text__content,
  .explorers .s-service .inner .body .text__content {
    /* width: 46.875%; */
    width: 100%;
    z-index: 2;
  }

  .explorers .s-media .inner .body .text__content>.text,
  .explorers .s-service .inner .body .text__content>.text {
    font-size: 15px;
    line-height: 2.1333;
    margin-bottom: -8.5px;
    margin-top: 45.5px;
    letter-spacing: 0.03em;
    color: #333;
  }

  .explorers .s-media .inner .body .text__content>.text:first-child,
  .explorers .s-service .inner .body .text__content>.text:first-child {
    margin-top: 0;
  }

  .explorers .s-media .inner .p-btn--b,
  .explorers .s-service .inner .p-btn--b {
    position: absolute;
    right: 0;
    left: 0;
    bottom: -120px;
    margin: 0 auto;
    z-index: 3;
  }

  .explorers .s-service_last {
    background-color: #fcfcfc;
    padding: 80px 0 158px;
  }

  .explorers .s-service_last .inner .service__head {
    width: 486px;
    margin-left: auto;
    margin-right: auto;
  }

  .explorers .s-service_last .inner .service__head .p-headline {
    text-align: center;
  }

  .explorers .s-service_last .inner .service__head .p-headline.is-shown .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .explorers .s-service_last .inner .service__head .p-headline .cover {
    background-color: #fcfcfc;
  }

  .explorers .s-service_last .inner .service__head .text {
    font-size: 15px;
    line-height: 2.1333;
    margin-bottom: 61.5px;
    margin-top: 71.5px;
    letter-spacing: 0.03em;
  }

  .explorers .s-service_last .inner .service__body {
    position: relative;
    display: block;
    width: 100%;
  }

  .explorers .s-service_last .inner .service__body .service__list {
    position: relative;

    display: flex;
  }

  .explorers .s-service_last .inner .service__body .service__list .item {
    /* width: 100%; */
    /* width: 30vw; */
    padding-top: 10px;
    padding-left: 30px;
    background-color: #f8f8f8;
    height: 50vh;
    margin-left: 50px;
  }

  .explorers .s-service_last .inner .service__body .service__list .item:first-child {
    margin-top: 0;
    visibility: hidden;
  }

  .explorers .s-service_last .inner .service__body .service__list .item.is-shown .head .service__headline .num {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .explorers .s-service_last .inner .service__body .service__list .item.is-shown .head .service__headline .line {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition-delay: 0.6s;
    transition-delay: 0.6s;
  }

  .explorers .s-service_last .inner .service__body .service__list .item.is-shown .head .service__headline .en {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .explorers .s-service_last .inner .service__body .service__list .item.is-shown .head .s-title .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .explorers .s-service_last .inner .service__body .service__list .item .head {
    position: relative;
    width: 356px;
    padding-top: 8px;
    padding-right: 15px;
  }

  .explorers .s-service_last .inner .service__body .service__list .item .head .service__headline {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    overflow: hidden;
  }

  .explorers .s-service_last .inner .service__body .service__list .item .head .service__headline .num {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    position: relative;
    display: inline-block;
    font-size: 12px;
    color: #959595;
    letter-spacing: 0.1em;
    /* width: 20px; */
    z-index: 2;
    overflow: hidden;
    background-color: #fcfcfc;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }

  .explorers .s-service_last .inner .service__body .service__list .item .head .service__headline .line {
    z-index: 1;
    background-color: #959595;
    content: "";
    height: 1px;
    width: 100%;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -1px;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition-duration: 1.8s;
    transition-duration: 1.8s;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }

  .explorers .s-service_last .inner .service__body .service__list .item .head .service__headline .en {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 12px;
    color: #959595;
    letter-spacing: 0.1em;
    z-index: 2;
    overflow: hidden;
    background-color: #fcfcfc;
    text-align: right;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }

  .explorers .s-service_last .inner .service__body .service__list .item .head .service__headline .en.media {
    width: 50px;
  }

  .explorers .s-service_last .inner .service__body .service__list .item .head .service__headline .en.service {
    width: 64px;
  }

  .explorers .s-service_last .inner .service__body .service__list .item .head .s-title {
    position: relative;
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    font-size: 28px;
    letter-spacing: 0.05em;
    margin-top: 28px;
  }

  .explorers .s-service_last .inner .service__body .service__list .item .head .s-title .cover {
    position: absolute;
    top: -5px;
    right: 0;
    bottom: -5px;
    left: -5px;
    background-color: #f8f8f8;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 3s;
    transition-duration: 3s;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: right;
    transform-origin: right;
  }

  .explorers .s-service_last .inner .service__body .service__list .item .head .text {
    font-size: 13px;
    line-height: 2.1538;
    margin-bottom: 12.5px;
    margin-top: 52.5px;
    letter-spacing: 0.03em;
  }

  .explorers .s-service_last .inner .service__body .service__list .item .head .text-link {
    -webkit-transition: 0.6s opacity;
    transition: 0.6s opacity;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
    -webkit-transition-delay: 0.6s;
    transition-delay: 0.6s;
  }

  .explorers .s-service_last .inner .service__body .service__list .item .head .text-link.is-shown,
  .explorers .s-service_last .inner .service__body .service__list .item.is-shown .body {
    opacity: 1;
  }

  .explorers .s-service_last .inner .service__body .service__list .item .head .text-link .text {
    font-size: 16px;
  }

  .explorers .s-service_last .inner .service__body .service__list .item .body {
    position: relative;
    max-width: 512px;

    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    overflow: hidden;
  }

  .explorers .s-service_last .inner .service__body .service__list .item .body .thumb {
    position: relative;
    display: block;
    overflow: hidden;

    width: 335px;
    height: 300px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .explorers .s-service_last .inner .service__body .service__list .item .body .thumb .cover {
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    background-color: #fcfcfc;
    z-index: 1;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 2s;
    transition-duration: 2s;
    -webkit-transform-origin: right;
    transform-origin: right;
    overflow: hidden;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  .explorers .s-explorers {
    background-color: #fcfcfc;
    padding-bottom: 180px;
    margin-top: 1300px;
  }

  .explorers .s-explorers .inner .explorers__body {
    width: 100%;
  }

  .explorers .s-explorers .inner .explorers__body .explorers__list .item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
    margin-top: 176px;
    // background-color: #f8f8f8;
  }

  .explorers .s-explorers .inner .explorers__body .explorers__list .item.is-shown .head {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .explorers .s-explorers .inner .explorers__body .explorers__list .item.is-shown .head .s-title .num {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .explorers .s-explorers .inner .explorers__body .explorers__list .item.is-shown .body .thumb .cover,
  .explorers .s-explorers .inner .explorers__body .explorers__list .item.is-shown .head .s-title .read .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .explorers .s-explorers .inner .explorers__body .explorers__list .item.is-shown .body .thumb .img {
    -webkit-filter: brightness(100%);
    filter: brightness(100%);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  .explorers .s-explorers .inner .explorers__body .explorers__list .item:first-child {
    margin-top: 0;
  }

  .explorers .s-explorers .inner .explorers__body .explorers__list .item:nth-child(odd) .head {
    /* -webkit-box-ordinal-group: 1;
        -ms-flex-order: 1;
        order: 1;
        padding-left: 8.57633%;
        margin-right: 2.74443% */

    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    padding-right: 8.57633%;
    margin-left: 4.74443%;
  }

  .explorers .s-explorers .inner .explorers__body .explorers__list .item:nth-child(odd) .body {
    /* -webkit-box-ordinal-group: 2;
        -ms-flex-order: 0;
        order: 0;
        margin-left: 2.74443% */

    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    margin-right: 2.74443%;
  }

  .explorers .s-explorers .inner .explorers__body .explorers__list .item:nth-child(even) .head {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    padding-right: 8.57633%;
    margin-left: 2.74443%;
  }

  .explorers .s-explorers .inner .explorers__body .explorers__list .item:nth-child(even) .body {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    margin-right: 2.74443%;
  }

  .explorers .s-explorers .inner .explorers__body .explorers__list .item .head {
    position: relative;
    width: 700px;
    white-space: nowrap;
  }

  .explorers .s-explorers .inner .explorers__body .explorers__list .item .head .s-title {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    margin-top: 20px;
    overflow: hidden;
  }

  .explorers .s-explorers .inner .explorers__body .explorers__list .item .head .s-title .read {
    position: relative;
    font-size: 24px;
    line-height: 1;
    margin-bottom: 0;
    margin-top: 10px;
    letter-spacing: 0.1em;
    display: block;
  }

  .explorers .s-explorers .inner .explorers__body .explorers__list .item .head .s-title .read .cover {
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    background-color: #fcfcfc;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 3s;
    transition-duration: 3s;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: right;
    transform-origin: right;
    content: "";
  }

  .explorers .s-explorers .inner .explorers__body .explorers__list .item .head .s-title .num {
    color: #b2b2b2;
    letter-spacing: 0.1em;
    font-size: 12px;
    display: block;
    overflow: hidden;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }

  .explorers .s-explorers .inner .explorers__body .explorers__list .item .head .s-title>.text {
    font-size: 30px;
    line-height: 1.6;
    margin-bottom: -9px;
    margin-top: 43px;
    letter-spacing: 0.025em;
    display: block;
  }

  .explorers .s-explorers .inner .explorers__body .explorers__list .item .head>.text {
    font-size: 14px;
    line-height: 2.1538;
    margin-bottom: 21.35px;
    margin-top: 31.35px;
    letter-spacing: 0.03em;
  }

  .explorers .s-explorers .inner .explorers__body .explorers__list .item .head .text-link {
    -webkit-transition: 0.6s opacity;
    transition: 0.6s opacity;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
  }

  .explorers .s-explorers .inner .explorers__body .explorers__list .item .head .text-link.is-shown {
    opacity: 1;
    /* margin-left: 580px; */
  }

  .explorers .s-explorers .inner .explorers__body .explorers__list .item .head .text-link .text {
    color: $red;
  }

  .explorers .s-explorers .inner .explorers__body .explorers__list .item .head .text-link .arrow {
    margin-top: -3px;
  }

  .explorers .s-explorers .inner .explorers__body .explorers__list .item .head .text-link:hover .text {
    color: #151515;
  }

  .explorers .s-explorers .inner .explorers__body .explorers__list .item .body {
    position: relative;
    max-width: 550px;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    overflow: hidden;
    top: 40px;
    left: 70px;
  }

  .explorers .s-explorers .inner .explorers__body .explorers__list .item .body .thumb {
    position: relative;
    display: block;
    overflow: hidden;
    width: 100%;
    height: auto;
  }

  .explorers .s-explorers .inner .explorers__body .explorers__list .item .body .thumb .cover {
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    background-color: #fcfcfc;
    z-index: 1;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 2s;
    transition-duration: 2s;
    -webkit-transform-origin: right;
    transform-origin: right;
    overflow: hidden;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  .explorers .s-explorers .inner .explorers__body .explorers__list .item .body .thumb .img {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    -webkit-transition: 1.6s -webkit-transform, -webkit-filter 3s;
    transition: 1.6s -webkit-transform, -webkit-filter 3s;
    transition: 1.6s transform, filter 3s;
    transition: 1.6s transform, filter 3s, 1.6s -webkit-transform,
      -webkit-filter 3s;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    overflow: hidden;
    -webkit-filter: brightness(200%);
    filter: brightness(200%);
    will-change: filter, transform;
  }

  .explorers .s-service_last .p-inner .service__body .service__list .item .gotocsr {
    padding-top: 60px;
    padding-left: 210px;
  }

  .explorers .s-service_last .p-inner .service__body .service__list .item .gotocsr .p-btn {
    height: 7vh;
  }

  .explorers .s-service_last .p-inner .service__body .service__list .item .gotocsr .p-btn .link {
    line-height: 50px;
    text-align: center;
    z-index: 1;
    top: -35px;
  }

  .explorers .s-service_last .p-inner .service__body .service__list .item .gotocsr .p-btn .link .text {
    font-size: 13px;
    line-height: 2.1538;
    /* margin-bottom: 12.5px;
        margin-top: 52.5px;
        letter-spacing: .03em */
  }
}

@media screen and (max-width: 1024px) {

  .explorers .s-intoro,
  .explorers .s-intoro .head .p-headline .cover,
  .explorers .s-service {
    background-color: #fcfcfc;
  }

  .explorers .s-intoro,
  .explorers .s-intoro .body,
  .explorers .s-intoro .head,
  .explorers .s-service .inner .service__body .service__list {
    display: block;
    position: relative;
  }

  .explorers .l-kv .l-kv__read .copy {
    max-width: 296px;
  }

  .explorers .l-kv__bg .bg {
    background-image: url(/wp-content/themes/GDI/assets/images/pc/explorers/Explorers_kv.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  .explorers .js-section {
    opacity: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    will-change: opacity;
  }

  .explorers .s-intoro {
    padding: 86px 18px;
    z-index: 1;
  }

  .explorers .s-intoro .head .p-headline {
    text-align: center;
    opacity: 1;
  }

  .explorers .s-intoro .body {
    margin-top: 34px;
  }

  .explorers .s-intoro .body .text {
    font-size: 12px;
    line-height: 2;
    margin-bottom: -6px;
    margin-top: 20px;
    letter-spacing: 0.03em;
  }

  .explorers .s-intoro .body .text:first-child {
    margin-top: 0;
  }

  .explorers .s-service {
    padding: 0 0 56px;
  }

  .explorers .s-service .inner .service__head {
    position: relative;
    width: 100%;
    padding: 90px 18px 0;
    background-color: #fff;
  }

  .explorers .s-service .inner .service__head .p-headline {
    text-align: center;
  }

  .explorers .s-service .inner .service__head .p-headline .cover {
    background-color: #fff;
  }

  .explorers .s-service .inner .service__head .text {
    font-size: 12px;
    line-height: 2;
    margin-bottom: -6px;
    margin-top: -6px;
    letter-spacing: 0.03em;
    padding: 28px 0;
  }

  .explorers .s-service .inner .service__body {
    padding: 28px 0 60px;
    position: relative;
    display: block;
    width: 100%;
    background-color: #fff;
  }

  .explorers .s-service .inner .service__body .service__list .item {
    position: relative;
    width: 100%;
    margin-top: 46px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-property: opacity transform;
    transition-property: opacity transform;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }

  .explorers .s-service .inner .service__body .service__list .item>.link {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  .explorers .s-service .inner .service__body .service__list .item:first-child {
    /* margin-top: 0; */
  }

  .explorers .s-service .inner .service__body .service__list .item:last-child .body .thumb .service__headline .line,
  .explorers .s-service .inner .service__body .service__list .item:nth-child(2) .body .thumb .service__headline .line,
  .explorers .s-service .inner .service__body .service__list .item:nth-child(3) .body .thumb .service__headline .line {
    height: 110px;
  }

  .explorers .s-service .inner .service__body .service__list .item.is-shown {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .explorers .s-service .inner .service__body .service__list .item .head {
    position: relative;
    padding: 0 18px;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .explorers .s-service .inner .service__body .service__list .item .head .s-title {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    font-size: 18px;
    letter-spacing: 0.05em;
    margin-top: 20px;
  }

  .explorers .s-service .inner .service__body .service__list .item .head .text {
    font-size: 12px;
    line-height: 2;
    margin-bottom: 16px;
    margin-top: 17px;
    letter-spacing: 0.03em;
  }

  .explorers .s-service .inner .service__body .service__list .item .head .text-link {
    position: absolute;
    right: -10px;
    bottom: -18px;
  }

  .explorers .s-service .inner .service__body .service__list .item .body {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    position: relative;
    padding: 0 18px;
  }

  .explorers .s-service .inner .service__body .service__list .item .body .thumb {
    position: relative;
    display: block;
  }

  .explorers .s-service .inner .service__body .service__list .item .body .thumb .service__headline {
    position: absolute;
    top: 0;
    left: -25px;
    height: 168px;
    width: 20px;
  }

  .explorers .s-service .inner .service__body .service__list .item .body .thumb .service__headline .num {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    position: absolute;
    top: 0;
    left: -2px;
    font-size: 11px;
    color: #959595;
    letter-spacing: 0.1em;
    z-index: 2;
    height: 16px;
  }

  .explorers .s-service .inner .service__body .service__list .item .body .thumb .service__headline .line {
    position: absolute;
    top: 16px;
    left: 25%;
    margin-left: -1px;
    z-index: 1;
    background-color: #000;
    content: "";
    height: 124px;
    width: 1px;
  }

  .explorers .s-end,
  .explorers .s-end .vision__body,
  .explorers .s-vision,
  .explorers .s-vision .vision__head {
    position: relative;
    display: block;
  }

  .explorers .s-end,
  .explorers .s-vision,
  .explorers .s-vision .vision__head .p-headline .cover {
    background-color: #fcfcfc;
  }

  .explorers .s-service .inner .service__body .service__list .item .body .thumb .img {
    display: block;
    width: 100%;
    height: 100%;
  }

  .explorers .s-vision {
    padding: 20px 0 0;
    z-index: 1;
  }

  .explorers .s-vision .vision__head .p-headline {
    text-align: center;
    opacity: 1;
  }

  .explorers .s-vision .vision__body {
    position: relative;
    display: block;
    margin-top: 32px;
    text-align: center;
  }

  .explorers .s-vision .vision__body .text {
    font-size: 12px;
    line-height: 2;
    margin-bottom: -6px;
    margin-top: 30px;
    letter-spacing: 0.03em;
  }

  .explorers .s-vision .vision__body .text:first-child {
    margin-top: 0;
  }

  .explorers .s-end {
    padding: 0 0 156px;
    z-index: 1;
  }

  .explorers .s-end .vision__body {
    text-align: center;
  }

  .explorers .s-end .vision__body .text {
    font-size: 12px;
    line-height: 2;
    margin-bottom: -6px;
    margin-top: 30px;
    letter-spacing: 0.03em;
  }

  .explorers .s-end .vision__body .text:first-child {
    margin-top: 0;
  }

  .explorers .p-inner .head {
    padding: 0 18px;
  }

  .explorers .head .frontend-link {
    position: absolute;
    right: -10px;
    top: 360px;
  }

  .explorers .head .frontend-link .frontend-arrow {
    position: absolute;
    top: 18%;
    right: -12px;
    width: 40px;
    height: 1px;
    content: "";
  }

  .explorers .head .backend-link {
    position: absolute;
    right: -10px;
    top: 390px;
  }

  .explorers .head .backend-link .backend-arrow {
    position: absolute;
    top: 30%;
    right: -12px;
    width: 40px;
    height: 1px;
    content: "";
  }

  .explorers .head .writer-link {
    position: absolute;
    right: -10px;
    top: 420px;
  }

  .explorers .head .writer-link .writer-arrow {
    position: absolute;
    top: 12px;
    right: -12px;
    width: 40px;
    height: 1px;
    content: "";
  }

  .explorers {
    position: relative;
    display: block;
    background-color: #fcfcfc;
    z-index: 1;
  }

  .explorers .news__content {
    position: relative;
    display: block;
    width: 100%;
    padding: 0 20px;
  }

  .explorers .news__content.is-shown .news__head .p-headline .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .explorers .news__content.is-shown .news__body .news__list {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }

  .explorers .news__content .news__head {
    position: relative;
    text-align: center;
  }

  .explorers .news__content .news__head .p-headline {
    position: relative;
    background-color: #000;
    padding: 30px 0 0;
    margin-top: 30px;
  }

  .explorers .news__content .news__head .p-headline .text {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    font-size: 18px;
    letter-spacing: 0.1em;
    color: #fff;
  }

  .explorers .news__content .news__head .p-headline .cover {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #fff;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 3s;
    transition-duration: 3s;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: right;
    transform-origin: right;
  }

  .explorers .news__content .news__body {
    position: relative;
    padding: 36px 18px 44px;
    background-color: #fff;
  }

  .explorers .news__content .news__body .news__list {
    position: relative;
    display: block;
    opacity: 0;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-duration: 1.4s;
    transition-duration: 1.4s;
    -webkit-transition-property: opacity transform;
    transition-property: opacity transform;
    -webkit-transition-delay: 0.6s;
    transition-delay: 0.6s;
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }

  .explorers .news__content .news__body .news__list .item {
    position: relative;
    display: block;
    /* border-bottom: 1px solid #000; */
    margin-top: 21px;
    padding-bottom: 13px;
  }

  .explorers .news__content .news__body .news__list .item .head {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    /* text-align: left; */
    line-height: 1.4;
    text-align: center;
  }

  .explorers .news__content .news__body .news__list .item .head .date {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    width: 68px;
    font-size: 11px;
    letter-spacing: 0.05em;
  }

  .explorers .news__content .news__body .news__list .item .head .tag {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    font-size: 11px;
    letter-spacing: 0.05em;
  }

  .explorers .news__content .news__body .news__list .item .link,
  .explorers .news__content .news__body .news__list .item .text {
    display: block;
    letter-spacing: 0.03em;
    font-size: 12px;
    line-height: 1.6666;
    margin-bottom: -4px;
    margin-top: 13px;
    font-weight: 500;
    color: #303030;
  }

  .explorers .news__content .news__body .p-btn {
    margin: 40px auto 0;
  }

  .explorers .s-mission,
  .explorers .s-vision {
    z-index: 1;
    display: block;
    background-color: #fcfcfc;
  }

  .explorers .s-explores .inner,
  .explorers .s-mission .inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    position: relative;
  }

  .explorers .s-explores,
  .explorers .s-explores .inner .explores__body .p-headline .cover,
  .explorers .s-media,
  .explorers .s-media .inner .body,
  .explorers .s-media .inner .intro,
  .explorers .s-media .inner .intro .p-headline .cover,
  .explorers .s-mission,
  .explorers .s-mission .inner .mission__body,
  .explorers .s-mission .inner .mission__body .p-headline .cover,
  .explorers .s-service,
  .explorers .s-service .bg,
  .explorers .s-service .inner .intro .p-headline .cover,
  .explorers .s-vision,
  .explorers .s-vision .vision__head .p-headline .cover {
    background-color: #fcfcfc;
  }

  .explorers .s-explores .inner .explores__body .text,
  .explorers .s-media .inner .body .text__content>.text,
  .explorers .s-mission .inner .mission__body .text {
    line-height: 2;
    margin-bottom: -6px;
    margin-top: 30px;
    font-size: 12px;
    letter-spacing: 0.03em;
  }

  .explorers .s-mission {
    position: relative;
  }

  .explorers .s-mission .inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0 20px;
  }

  .explorers .s-mission .inner .mission__body {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    position: relative;
    width: 100%;
    padding: 24px 18px 0;
  }

  .explorers .s-mission .inner .mission__body .p-headline {
    text-align: center;
  }

  .explorers .s-mission .inner .mission__thumb {
    display: block;
    width: 100%;
    height: 100%;
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    padding: 64px 18px 0;
    overflow: visible;
  }

  .explorers .s-mission .inner .mission__thumb .thumb {
    position: relative;
    display: block;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

  .explorers .s-mission .inner .mission__thumb .thumb .img {
    display: block;
    width: 100%;
    height: auto;
  }

  .explorers .s-explorers .inner {
    padding: 0 38px;
  }

  .explorers .s-explorers .explorers__body {
    width: 100%;
  }

  .explorers .s-explorers .explorers__body .explorers__list .item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    margin-top: 116px;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-property: opacity transform;
    transition-property: opacity transform;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }

  .explorers .s-explorers .explorers__body .explorers__list .item.is-shown {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .explorers .s-explorers .explorers__body .explorers__list .item .head {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    position: relative;
    width: 100%;
  }

  .explorers .s-explorers .explorers__body .explorers__list .item:first-child {
    margin-top: 0;
  }

  .explorers .s-explorers .explorers__body .explorers__list .item .head .s-title {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    margin-top: 10px;
  }

  .explorers .s-explorers .explorers__body .explorers__list .item .head .s-title .read {
    font-size: 18px;
    line-height: 1;
    margin-bottom: 0;
    margin-top: 12px;
    letter-spacing: 0.1em;
    display: block;
    text-align: center;
  }

  .explorers .s-explorers .explorers__body .explorers__list .item .head .s-title .num {
    color: #b2b2b2;
    letter-spacing: 0.1em;
    font-size: 10px;
    display: block;
    text-align: center;
  }

  .explorers .s-explorers .explorers__body .explorers__list .item .head .s-title>.text {
    font-size: 28px;
    line-height: 1.6;
    margin-bottom: -8.4px;
    margin-top: 29.6px;
    letter-spacing: 0.025em;
    display: block;
  }

  .explorers .s-explorers .explorers__body .explorers__list .item .head>.text {
    font-size: 12px;
    line-height: 2;
    margin-bottom: 9px;
    margin-top: 14px;
    letter-spacing: 0.03em;
  }

  .explorers .s-explorers .explorers__body .explorers__list .item .head .text-link {
    position: absolute;
    right: -32px;
    bottom: -18px;
  }

  .explorers .s-explorers .explorers__body .explorers__list .item .head .text-link .text {
    color: $red;
  }

  .explorers .s-explorers .explorers__body .explorers__list .item .body {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    position: relative;
    width: 100%;
  }

  .explorers .s-explorers .explorers__body .explorers__list .item .body .thumb {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
  }

  .explorers .s-explorers .explorers__body .explorers__list .item .body .thumb .img {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    line-height: 0;
    margin: 0;
    border: 0;
  }

  .explorers .s-explorers .explorers__body .explorers__list .item.is-shown .body {
    opacity: 1;
  }

  .explorers .s-service_last {
    padding: 0 0 56px;
  }

  .explorers .s-service_last .inner .service__head {
    position: relative;
    width: 100%;
    padding: 90px 18px 0;
    background-color: #fff;
  }

  .explorers .s-service_last .inner .service__head .p-headline {
    text-align: center;
  }

  .explorers .s-service_last .inner .service__head .p-headline .cover {
    background-color: #fff;
  }

  .explorers .s-service_last .inner .service__head .text {
    font-size: 12px;
    line-height: 2;
    margin-bottom: -6px;
    margin-top: -6px;
    letter-spacing: 0.03em;
    padding: 28px 0;
  }

  .explorers .s-service_last .inner .service__body {
    padding: 28px 0 60px;
    position: relative;
    display: block;
    width: 100%;
    background-color: #fff;
  }

  .explorers .s-service_last .inner .service__body .service__list .item {
    position: relative;
    width: 100%;
    margin-top: 46px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-property: opacity transform;
    transition-property: opacity transform;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }

  .explorers .s-service_last .inner .service__body .service__list .item>.link {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  .explorers .s-service_last .inner .service__body .service__list .item:first-child {
    /* margin-top: 0; */
  }

  .explorers .s-service_last .inner .service__body .service__list .item:last-child .body .thumb .service__headline .line,
  .explorers .s-service_last .inner .service__body .service__list .item:nth-child(2) .body .thumb .service__headline .line,
  .explorers .s-service_last .inner .service__body .service__list .item:nth-child(3) .body .thumb .service__headline .line {
    height: 110px;
  }

  .explorers .s-service_last .inner .service__body .service__list .item.is-shown {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .explorers .s-service_last .inner .service__body .service__list .item_hidden {
    display: none;
  }

  .explorers .s-service_last .inner .service__body .service__list .item .head {
    position: relative;
    padding: 0 18px;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .explorers .s-service_last .inner .service__body .service__list .item .head .s-title {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    font-size: 18px;
    letter-spacing: 0.05em;
    margin-top: 20px;
  }

  .explorers .s-service_last .inner .service__body .service__list .item .head .text {
    font-size: 12px;
    line-height: 2;
    margin-bottom: 16px;
    margin-top: 17px;
    letter-spacing: 0.03em;
  }

  .explorers .s-service_last .inner .service__body .service__list .item .head .text-link {
    position: absolute;
    right: -10px;
    bottom: -18px;
  }

  .explorers .s-service_last .inner .service__body .service__list .item .body {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    position: relative;
    padding: 0 18px;
  }

  .explorers .s-service_last .inner .service__body .service__list .item .body .thumb {
    position: relative;
    display: block;
  }

  .explorers .s-service_last .inner .service__body .service__list .item .body .thumb .service__headline {
    position: absolute;
    top: 0;
    left: -25px;
    height: 168px;
    width: 20px;
  }

  .explorers .s-service_last .inner .service__body .service__list .item .body .thumb .service__headline .num {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    position: absolute;
    top: 0;
    left: -2px;
    font-size: 11px;
    color: #959595;
    letter-spacing: 0.1em;
    z-index: 2;
    height: 16px;
  }

  .explorers .s-service_last .inner .service__body .service__list .item .body .thumb .service__headline .line {
    position: absolute;
    top: 16px;
    left: 25%;
    margin-left: -1px;
    z-index: 1;
    background-color: #000;
    content: "";
    height: 124px;
    width: 1px;
  }
}

@include desktop {

  .csr .s-intoro,
  .csr .s-intoro .body,
  .csr .s-intoro .head,
  .csr .s-csr .inner .csr__body,
  .csr .s-csr .inner .csr__body .csr__list {
    display: block;
    position: relative;
  }

  .csr .l-kv .l-kv__read .copy {
    max-width: 296px;
  }

  .csr .l-kv__bg .bg {
    background-image: url(/wp-content/themes/GDI/assets/images/pc/csr/CSR_kv.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  .csr .js-section {
    opacity: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    will-change: opacity;
  }

  .csr .s-intoro {
    padding: 116px 0 180px;
    background-color: #fcfcfc;
    z-index: 1;
  }

  .csr .s-intoro .head {
    max-width: 486px;
    margin-left: auto;
    margin-right: auto;
  }

  .csr .s-intoro .head .p-headline {
    text-align: center;
    opacity: 1;
  }

  .csr .s-intoro .head .p-headline .cover {
    background-color: #fcfcfc;
  }

  .csr .s-intoro .body {
    max-width: 724px;
    margin: 76px auto 0;
  }

  .csr .s-intoro .body .text {
    font-size: 15px;
    line-height: 2.1333;
    margin-bottom: -8.5px;
    margin-top: 37.5px;
    letter-spacing: 0.03em;
  }

  .csr .s-intoro .body .text:first-child {
    margin-top: 0;
  }

  .csr .s-csr {
    // background-color: #fcfcfc;
    padding-bottom: 180px;
  }

  .csr .s-csr .inner .csr__body {
    width: 100%;
    position: relative;
    top: -100px;
    z-index: 10;
  }

  .csr .s-csr .inner .csr__body .csr__list .item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 90% !important;
    margin-top: 176px;
    // background-color: #f8f8f8;
  }

  .csr .s-csr .inner .csr__body .csr__list .item.is-shown .head {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .csr .s-csr .inner .csr__body .csr__list .item.is-shown .head .s-title .num {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .csr .s-csr .inner .csr__body .csr__list .item.is-shown .body .thumb .cover,
  .csr .s-csr .inner .csr__body .csr__list .item.is-shown .head .s-title .read .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .csr .s-csr .inner .csr__body .csr__list .item.is-shown .body .thumb .img {
    -webkit-filter: brightness(100%);
    filter: brightness(100%);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  .csr .s-csr .inner .csr__body .csr__list .item:first-child {
    margin-top: 0;
  }

  .csr .s-csr .inner .csr__body .csr__list .item:nth-child(odd) .head {
    /* -webkit-box-ordinal-group: 1;
        -ms-flex-order: 1;
        order: 1;
        padding-left: 8.57633%;
        margin-right: 2.74443% */

    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    padding-right: 8.57633%;
    margin-left: 2.74443%;
  }

  .csr .s-csr .inner .csr__body .csr__list .item:nth-child(odd) .body {
    /* -webkit-box-ordinal-group: 2;
        -ms-flex-order: 0;
        order: 0;
        margin-left: 2.74443% */

    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    margin-right: 2.74443%;
  }

  .csr .s-csr .inner .csr__body .csr__list .item:nth-child(even) .head {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    padding-right: 8.57633%;
    margin-left: 2.74443%;
  }

  .csr .s-csr .inner .csr__body .csr__list .item:nth-child(even) .body {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    margin-right: 2.74443%;
  }

  .csr .s-csr .inner .csr__body .csr__list .item .head {
    position: relative;
    width: 526px;
  }

  .csr .s-csr .inner .csr__body .csr__list .item .head .s-title {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    margin-top: 20px;
    overflow: hidden;
  }

  .csr .s-csr .inner .csr__body .csr__list .item .head .s-title .read {
    position: relative;
    font-size: 24px;
    line-height: 1;
    margin-bottom: 0;
    margin-top: 10px;
    letter-spacing: 0.1em;
    display: block;
  }

  .csr .s-csr .inner .csr__body .csr__list .item .head .s-title .read .cover {
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    background-color: #fcfcfc;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 3s;
    transition-duration: 3s;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: right;
    transform-origin: right;
    content: "";
  }

  .csr .s-csr .inner .csr__body .csr__list .item .head .s-title .num {
    color: #b2b2b2;
    letter-spacing: 0.1em;
    font-size: 12px;
    display: block;
    overflow: hidden;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }

  .csr .s-csr .inner .csr__body .csr__list .item .head .s-title>.text {
    font-size: 30px;
    line-height: 1.6;
    margin-bottom: -9px;
    margin-top: 43px;
    letter-spacing: 0.025em;
    display: block;
  }

  .csr .s-csr .inner .csr__body .csr__list .item .head>.text {
    font-size: 15px;
    line-height: 2.1538;
    margin-bottom: 21.35px;
    margin-top: 31.35px;
    letter-spacing: 0.03em;
  }

  .csr .s-csr .inner .csr__body .csr__list .item .head .text-link {
    -webkit-transition: 0.6s opacity;
    transition: 0.6s opacity;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
  }

  .csr .s-csr .inner .csr__body .csr__list .item .head .text-link.is-shown {
    opacity: 1;
    /* margin-left: 580px; */
  }

  .csr .s-csr .inner .csr__body .csr__list .item .head .text-link .text {
    color: $red;
  }

  .csr .s-csr .inner .csr__body .csr__list .item .head .text-link .arrow {
    margin-top: -3px;
  }

  .csr .s-csr .inner .csr__body .csr__list .item .head .text-link:hover .text {
    color: #151515;
  }

  .csr .s-csr .inner .csr__body .csr__list .item .body {
    position: relative;
    max-width: 350px;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    overflow: hidden;
    top: 40px;
    left: 70px;
  }

  .csr .s-csr .inner .csr__body .csr__list .item .body .thumb {
    position: relative;
    display: block;
    overflow: hidden;
    width: 100%;
    height: auto;
  }

  .csr .s-csr .inner .csr__body .csr__list .item .body .thumb .cover {
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    background-color: #fcfcfc;
    z-index: 1;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 2s;
    transition-duration: 2s;
    -webkit-transform-origin: right;
    transform-origin: right;
    overflow: hidden;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  .csr .s-csr .inner .csr__body .csr__list .item .body .thumb .img {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    -webkit-transition: 1.6s -webkit-transform, -webkit-filter 3s;
    transition: 1.6s -webkit-transform, -webkit-filter 3s;
    transition: 1.6s transform, filter 3s;
    transition: 1.6s transform, filter 3s, 1.6s -webkit-transform,
      -webkit-filter 3s;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    overflow: hidden;
    -webkit-filter: brightness(200%);
    filter: brightness(200%);
    will-change: filter, transform;
  }
}

@media screen and (max-width: 1024px) {

  .csr .s-intoro,
  .csr .s-intoro .head .p-headline .cover {
    background-color: #fcfcfc;
  }

  .csr .s-intoro,
  .csr .s-intoro .body,
  .csr .s-intoro .head,
  .csr .s-csr .csr__body,
  .csr .s-csr .csr__body .csr__list {
    display: block;
    position: relative;
  }

  .csr .l-kv .l-kv__read .copy {
    max-width: 210px;
  }

  .csr .l-kv__bg .bg {
    background-image: url(/wp-content/themes/GDI/assets/images/pc/csr/CSR_kv.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  .csr .js-section {
    opacity: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    will-change: opacity;
  }

  .csr .s-intoro {
    padding: 86px 18px;
    z-index: 1;
  }

  .csr .s-intoro .head .p-headline {
    text-align: center;
    opacity: 1;
  }

  .csr .s-intoro .body {
    margin-top: 34px;
  }

  .csr .s-intoro .body .text {
    font-size: 12px;
    line-height: 2;
    margin-bottom: -6px;
    margin-top: 20px;
    letter-spacing: 0.03em;
  }

  .csr .s-intoro .body .text:first-child {
    margin-top: 0;
  }

  .csr .s-csr {
    padding-bottom: 116px;
    padding-top: 50px;
  }

  .csr .s-csr .inner {
    padding: 0 38px;
  }

  .csr .s-csr .csr__body {
    width: 100%;
  }

  .csr .s-csr .csr__body .csr__list .item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    margin-top: 116px;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-property: opacity transform;
    transition-property: opacity transform;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }

  .csr .s-csr .csr__body .csr__list .item.is-shown {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .csr .s-csr .csr__body .csr__list .item .head {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    position: relative;
    width: 100%;
  }

  .csr .s-csr .csr__body .csr__list .item:first-child {
    margin-top: 0;
  }

  .csr .s-csr .csr__body .csr__list .item .head .s-title {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    margin-top: 10px;
  }

  .csr .s-csr .csr__body .csr__list .item .head .s-title .read {
    font-size: 18px;
    line-height: 1;
    margin-bottom: 0;
    margin-top: 12px;
    letter-spacing: 0.1em;
    display: block;
    text-align: center;
  }

  .csr .s-csr .csr__body .csr__list .item .head .s-title .num {
    color: #b2b2b2;
    letter-spacing: 0.1em;
    font-size: 10px;
    display: block;
    text-align: center;
  }

  .csr .s-csr .csr__body .csr__list .item .head .s-title>.text {
    font-size: 28px;
    line-height: 1.6;
    margin-bottom: -8.4px;
    margin-top: 29.6px;
    letter-spacing: 0.025em;
    display: block;
  }

  .csr .s-csr .csr__body .csr__list .item .head>.text {
    font-size: 12px;
    line-height: 2;
    margin-bottom: 9px;
    margin-top: 14px;
    letter-spacing: 0.03em;
  }

  .csr .s-csr .csr__body .csr__list .item .head .text-link {
    position: absolute;
    right: -32px;
    bottom: -18px;
  }

  .csr .s-csr .csr__body .csr__list .item .head .text-link .text {
    color: $red;
  }

  .csr .s-csr .csr__body .csr__list .item .body {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    position: relative;
    width: 100%;
  }

  .csr .s-csr .csr__body .csr__list .item .body .thumb {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
  }

  .csr .s-csr .csr__body .csr__list .item .body .thumb .img {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    line-height: 0;
    margin: 0;
    border: 0;
  }

  .csr .s-csr .csr__body .csr__list .item.is-shown .body {
    opacity: 1;
  }
}

@include desktop {

  .sft .s-intoro,
  .sft .s-intoro .body,
  .sft .s-intoro .head,
  .sft .s-sft .inner .sft__body,
  .sft .s-sft .inner .sft__body .sft__list {
    display: block;
    position: relative;
  }

  .sft .l-kv .l-kv__read .copy {
    max-width: 296px;
  }

  .sft .l-kv__bg .bg {
    background-image: url(/wp-content/themes/GDI/assets/images/pc/csr/CSR_kv.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  .sft .js-section {
    opacity: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    will-change: opacity;
  }

  .sft .s-intoro {
    padding: 116px 0 180px;
    background-color: #fcfcfc;
    z-index: 1;
  }

  .sft .s-intoro .head {
    max-width: 486px;
    margin-left: auto;
    margin-right: auto;
  }

  .sft .s-intoro .head .p-headline {
    text-align: center;
    opacity: 1;
  }

  .sft .s-intoro .head .p-headline .cover {
    background-color: #fcfcfc;
  }

  .sft .s-intoro .p-inner--m {
    max-width: 900px;
  }

  .sft .s-intoro {
    background-color: #fcfcfc;
    padding-bottom: 180px;
  }

  .sft .s-intoro .inner .sft__body {
    width: 100%;
  }

  .sft .s-intoro .inner .sft__body .sft__list .item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
    margin-top: 176px;
  }

  .sft .s-intoro .inner .sft__body .sft__list .item.is-shown .head {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .sft .s-intoro .inner .sft__body .sft__list .item.is-shown .head .s-title .num {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .sft .s-intoro .inner .sft__body .sft__list .item.is-shown .body .thumb .cover,
  .sft .s-intoro .inner .sft__body .sft__list .item.is-shown .head .s-title .read .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .sft .s-intoro .inner .sft__body .sft__list .item.is-shown .body .thumb .img {
    -webkit-filter: brightness(100%);
    filter: brightness(100%);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  .sft .s-intoro .inner .sft__body .sft__list .item:first-child {
    margin-top: 0;
  }

  .sft .s-intoro .inner .sft__body .sft__list .item:nth-child(odd) .head {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    padding-left: 8.57633%;
    margin-right: 2.74443%;
  }

  .sft .s-intoro .inner .sft__body .sft__list .item:nth-child(odd) .body {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    margin-left: 2.74443%;
  }

  .sft .s-intoro .inner .sft__body .sft__list .item:nth-child(even) .head {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    padding-right: 8.57633%;
    margin-left: 2.74443%;
  }

  .sft .s-intoro .inner .sft__body .sft__list .item:nth-child(even) .body {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    margin-right: 2.74443%;
  }

  .sft .s-intoro .inner .sft__body .sft__list .item .head {
    position: relative;
    width: 550px;
  }

  .sft .s-intoro .inner .sft__body .sft__list .item .head .s-title {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    margin-top: 20px;
    overflow: hidden;
  }

  .sft .s-intoro .inner .sft__body .sft__list .item .head .s-title .read {
    position: relative;
    font-size: 24px;
    line-height: 1;
    margin-bottom: 0;
    margin-top: 10px;
    letter-spacing: 0.1em;
    display: block;
  }

  .sft .s-intoro .inner .sft__body .sft__list .item .head .s-title .read .cover {
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    background-color: #fcfcfc;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 3s;
    transition-duration: 3s;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: right;
    transform-origin: right;
    content: "";
  }

  .sft .s-intoro .inner .sft__body .sft__list .item .head .s-title .num {
    color: #b2b2b2;
    letter-spacing: 0.1em;
    font-size: 12px;
    display: block;
    overflow: hidden;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }

  .sft .s-intoro .inner .sft__body .sft__list .item .head .s-title>.text {
    font-size: 30px;
    line-height: 1.6;
    margin-bottom: -9px;
    margin-top: 43px;
    letter-spacing: 0.025em;
    display: block;
  }

  .sft .s-intoro .inner .sft__body .sft__list .item .head>.text {
    font-size: 15px;
    line-height: 2.1538;
    margin-bottom: 21.35px;
    margin-top: 31.35px;
    letter-spacing: 0.03em;
  }

  .sft .s-intoro .inner .sft__body .sft__list .item .head .text-link {
    -webkit-transition: 0.6s opacity;
    transition: 0.6s opacity;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
  }

  .sft .s-intoro .inner .sft__body .sft__list .item .head .text-link.is-shown {
    opacity: 1;
  }

  .sft .s-intoro .inner .sft__body .sft__list .item .head .text-link .text {
    color: $red;
  }

  .sft .s-intoro .inner .sft__body .sft__list .item .head .text-link .arrow {
    margin-top: -3px;
  }

  .sft .s-intoro .inner .sft__body .sft__list .item .head .text-link:hover .text {
    color: #151515;
  }

  .sft .s-intoro .inner .sft__body .sft__list .item .body {
    position: relative;
    max-width: 550px;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    overflow: hidden;
  }

  .sft .s-intoro .inner .sft__body .sft__list .item .body .thumb {
    position: relative;
    display: block;
    overflow: hidden;
    width: 100%;
    height: auto;
  }

  .sft .s-intoro .inner .sft__body .sft__list .item .body .thumb .cover {
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    background-color: #fcfcfc;
    z-index: 1;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 2s;
    transition-duration: 2s;
    -webkit-transform-origin: right;
    transform-origin: right;
    overflow: hidden;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  .sft .s-intoro .inner .sft__body .sft__list .item .body .thumb .img {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    -webkit-transition: 1.6s -webkit-transform, -webkit-filter 3s;
    transition: 1.6s -webkit-transform, -webkit-filter 3s;
    transition: 1.6s transform, filter 3s;
    transition: 1.6s transform, filter 3s, 1.6s -webkit-transform,
      -webkit-filter 3s;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    overflow: hidden;
    -webkit-filter: brightness(200%);
    filter: brightness(200%);
    will-change: filter, transform;
  }

  .sft .s-intoro .body {
    max-width: 724px;
    margin: 76px auto 0;
  }

  .sft .s-intoro .body .text {
    font-size: 15px;
    line-height: 2.1333;
    margin-bottom: -8.5px;
    margin-top: 37.5px;
    letter-spacing: 0.03em;
  }

  .sft .s-intoro .body .text:first-child {
    margin-top: 0;
  }

  .sft .s-sft {
    // background-color: #fcfcfc;
    /* padding-bottom: 180px; */
    padding-top: 50px;
  }

  .sft .s-sft .inner .sft__body {
    width: 100%;
  }

  .sft .s-sft .inner .sft__body .sft__list .item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
    margin-top: 176px;
    // background-color: #f8f8f8;
  }

  .sft .s-sft .inner .sft__body .sft__list .item.is-shown .head {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .sft .s-sft .inner .sft__body .sft__list .item.is-shown .head .s-title .num {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .sft .s-sft .inner .sft__body .sft__list .item.is-shown .body .thumb .cover,
  .sft .s-sft .inner .sft__body .sft__list .item.is-shown .head .s-title .read .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .sft .s-sft .inner .sft__body .sft__list .item.is-shown .body .thumb .img {
    -webkit-filter: brightness(100%);
    filter: brightness(100%);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  .sft .s-sft .inner .sft__body .sft__list .item:first-child {
    margin-top: 0;
  }

  .sft .s-sft .inner .sft__body .sft__list .item:nth-child(odd) .head {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    padding-left: 8.57633%;
    margin-right: 2.74443%;
  }

  .sft .s-sft .inner .sft__body .sft__list .item:nth-child(odd) .body {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    margin-left: 2.74443%;
  }

  .sft .s-sft .inner .sft__body .sft__list .item:nth-child(even) .head {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    padding-right: 10%;
    margin-left: 2.74443%;
  }

  .sft .s-sft .inner .sft__body .sft__list .item:nth-child(even) .body {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    margin-right: 2.74443%;
    padding-left: 10%;
  }

  .sft .s-sft .inner .sft__body .sft__list .item .head {
    position: relative;
    width: 1050px;
  }

  .sft .s-sft .inner .sft__body .sft__list .item .head .s-title {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    margin-top: 20px;
    // overflow: hidden;
  }

  .sft .s-sft .inner .sft__body .sft__list .item .head .s-title .read {
    position: relative;
    font-size: 24px;
    line-height: 1;
    margin-bottom: 0;
    margin-top: 10px;
    letter-spacing: 0.1em;
    display: block;
  }

  .sft .s-sft .inner .sft__body .sft__list .item .head .s-title .read .cover {
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    background-color: #f8f8f8;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 3s;
    transition-duration: 3s;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: right;
    transform-origin: right;
    content: "";
  }

  .sft .s-sft .inner .sft__body .sft__list .item .head .s-title .num {
    color: #b2b2b2;
    letter-spacing: 0.1em;
    font-size: 12px;
    display: block;
    overflow: hidden;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }

  .sft .s-sft .inner .sft__body .sft__list .item .head .s-title>.text {
    font-size: 30px;
    line-height: 1.6;
    margin-bottom: -9px;
    margin-top: 43px;
    letter-spacing: 0.025em;
    display: block;
  }

  .sft .s-sft .inner .sft__body .sft__list .item .head>.text {
    font-size: 15px;
    line-height: 2.1538;
    margin-bottom: 21.35px;
    margin-top: 31.35px;
    letter-spacing: 0.03em;
  }

  .sft .s-sft .inner .sft__body .sft__list .item .head .text-link {
    -webkit-transition: 0.6s opacity;
    transition: 0.6s opacity;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
  }

  .sft .s-sft .inner .sft__body .sft__list .item .head .text-link.is-shown {
    opacity: 1;
  }

  .sft .s-sft .inner .sft__body .sft__list .item .head .text-link .text {
    color: $red;
  }

  .sft .s-sft .inner .sft__body .sft__list .item .head .text-link .arrow {
    margin-top: -3px;
  }

  .sft .s-sft .inner .sft__body .sft__list .item .head .text-link:hover .text {
    color: #151515;
  }

  .sft .s-sft .inner .sft__body .sft__list .item .body {
    position: relative;
    max-width: 1050px;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    overflow: hidden;
  }

  .sft .s-sft .inner .sft__body .sft__list .item .body .thumb {
    position: relative;
    display: block;
    overflow: hidden;
    width: 100%;
    height: auto;
  }

  .sft .s-sft .inner .sft__body .sft__list .item .body .thumb .cover {
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    background-color: #fcfcfc;
    z-index: 1;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 2s;
    transition-duration: 2s;
    -webkit-transform-origin: right;
    transform-origin: right;
    overflow: hidden;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  .sft .s-sft .inner .sft__body .sft__list .item .body .thumb .img {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    -webkit-transition: 1.6s -webkit-transform, -webkit-filter 3s;
    transition: 1.6s -webkit-transform, -webkit-filter 3s;
    transition: 1.6s transform, filter 3s;
    transition: 1.6s transform, filter 3s, 1.6s -webkit-transform,
      -webkit-filter 3s;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    overflow: hidden;
    -webkit-filter: brightness(200%);
    filter: brightness(200%);
    will-change: filter, transform;
  }

  .sft .s-service {
    padding: 0 0 70px;
  }

  .sft .s-service .inner .service__head {
    width: 486px;
    margin-left: auto;
    margin-right: auto;
  }

  .sft .s-service .inner .service__head .p-headline {
    text-align: center;
  }

  .sft .s-service .inner .service__head .p-headline.is-shown .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .sft .s-service .inner .service__head .text {
    font-size: 15px;
    line-height: 2.1333;
    margin-bottom: 61.5px;
    margin-top: 71.5px;
    letter-spacing: 0.03em;
  }

  .sft .s-service .inner .service__body {
    position: relative;
    display: block;
    width: 100%;
    /* background-color: #fff; */
  }

  .sft .s-service .inner .service__body .service__list .item {
    /* display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between; */
    width: 100%;
    /* margin-top: 64px; */
    padding-top: 10px;
    padding-left: 80px;
  }

  .sft .s-service .inner .service__body .service__list .item:first-child {
    margin-top: 0;
  }

  .sft .s-service .inner .service__body .service__list .item.is-shown .head {
    opacity: 1;
  }

  .sft .s-service .inner .service__body .service__list .item.is-shown .head .service__headline .num {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .sft .s-service .inner .service__body .service__list .item.is-shown .head .service__headline .line {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition-delay: 0.6s;
    transition-delay: 0.6s;
  }

  .sft .s-service .inner .service__body .service__list .item.is-shown .head .service__headline .en {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .sft .s-service .inner .service__body .service__list .item.is-shown .head .s-title .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .sft .s-service .inner .service__body .service__list .item.is-shown .head .s-link {
    text-decoration: underline;
    font-size: 13px;
  }

  .sft .s-service .inner .service__body .service__list .item .head {
    position: relative;
    width: 335px;
    padding-top: 8px;
  }

  .sft .s-service .inner .service__body .service__list .item .head .service__headline {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    overflow: hidden;
  }

  .sft .s-service .inner .service__body .service__list .item .head .service__headline .num {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    position: relative;
    display: inline-block;
    font-size: 12px;
    color: #959595;
    letter-spacing: 0.1em;
    width: 35px;
    z-index: 2;
    overflow: hidden;
    background-color: #fcfcfc;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }

  .sft .s-service .inner .service__body .service__list .item .head .service__headline .line {
    z-index: 1;
    background-color: #959595;
    content: "";
    height: 1px;
    width: 100%;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -1px;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition-duration: 1.8s;
    transition-duration: 1.8s;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }

  .sft .s-service .inner .service__body .service__list .item .head .s-title {
    position: relative;
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    font-size: 20px;
    letter-spacing: 0.05em;
    margin-top: 28px;
  }

  .sft .s-service .inner .service__body .service__list .item .head .s-title .cover {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: -10px;
    background-color: #fcfcfc;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 3s;
    transition-duration: 3s;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: right;
    transform-origin: right;
  }

  .sft .s-service .inner .service__body .service__list .item .head .text {
    font-size: 13px;
    line-height: 2.1538;
    margin-bottom: 12.5px;
    margin-top: 52.5px;
    letter-spacing: 0.03em;
  }

  .sft .s-end .vision__body .text,
  .sft .s-vision .vision__body .text {
    font-size: 15px;
    line-height: 2.1333;
    margin-bottom: -8.5px;
    letter-spacing: 0.03em;
  }

  .sft .s-service .inner .service__body .service__list .item .head .text-link {
    width: 92px;
    -webkit-transition: 0.6s opacity;
    transition: 0.6s opacity;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
    -webkit-transition-delay: 0.6s;
    transition-delay: 0.6s;
  }

  .sft .s-service .inner .service__body .service__list .item .head .text-link.is-shown,
  .sft .s-service .inner .service__body .service__list .item.is-shown .body {
    opacity: 1;
  }

  .sft .s-service .inner .service__body .service__list .item .body {
    position: relative;
    max-width: 512px;
    padding-top: 58px;
    /* margin-left: 3.33333%; */
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    overflow: hidden;
  }

  .sft .s-service .inner .service__body .service__list .item .body .thumb {
    position: relative;
    display: block;
    overflow: hidden;
    /* width: 100%;
        height: auto; */
    width: 300px;
    height: 300px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .sft .s-service .inner .service__body .service__list .item .body .thumb .cover {
    position: absolute;
    right: 0;
    left: 0;
    top: -5px;
    bottom: -5px;
    background-color: #fcfcfc;
    z-index: 1;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 2s;
    transition-duration: 2s;
    -webkit-transform-origin: right;
    transform-origin: right;
    overflow: hidden;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  .sft .s-service .inner .service__body .service__list .item .body .thumb .img {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    -webkit-transition: 1.6s -webkit-transform, -webkit-filter 3s;
    transition: 1.6s -webkit-transform, -webkit-filter 3s;
    transition: 1.6s transform, filter 3s;
    transition: 1.6s transform, filter 3s, 1.6s -webkit-transform,
      -webkit-filter 3s;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow: hidden;
    -webkit-filter: brightness(200%);
    filter: brightness(200%);
    will-change: filter, transform;
  }

  .sft .s-service .inner .service__body .service__list .item.is-shown .body .thumb .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .sft .s-service .inner .service__body .service__list .item.is-shown .body .thumb .img {
    -webkit-filter: brightness(100%);
    filter: brightness(100%);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  .sft .s-service .inner .service__body .service__list {
    display: flex;
  }
}

@media screen and (max-width: 1024px) {

  .sft .s-intoro,
  .sft .s-intoro .head .p-headline .cover {
    background-color: #fcfcfc;
  }

  .sft .s-intoro,
  .sft .s-intoro .body,
  .sft .s-intoro .head,
  .sft .s-sft .sft__body,
  .sft .s-sft .sft__body .sft__list {
    display: block;
    position: relative;
  }

  .sft .l-kv .l-kv__read .copy {
    max-width: 210px;
  }

  .sft .l-kv__bg .bg {
    background-image: url(/wp-content/themes/GDI/assets/images/pc/sft/sfts_kv.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  .sft .js-section {
    opacity: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    will-change: opacity;
  }

  .sft .s-intoro {
    padding: 86px 18px;
    z-index: 1;
  }

  .sft .s-intoro .head .p-headline {
    text-align: center;
    opacity: 1;
  }

  /* .sft .s-intoro .p-inner--m {
        max-width: 900px;
    } */

  .sft .s-intoro {
    background-color: #fcfcfc;
    /* padding-bottom: 180px; */
  }

  .sft .s-intoro .inner .sft__body {
    width: 100%;
  }

  .sft .s-intoro .inner .sft__body .sft__list .item {
    display: -webkit-box;
    display: -ms-flexbox;
    /* display: flex; */
    display: block;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
    margin-top: 176px;
  }

  .sft .s-intoro .inner .sft__body .sft__list .item.is-shown .head {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .sft .s-intoro .inner .sft__body .sft__list .item.is-shown .head .s-title .num {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .sft .s-intoro .inner .sft__body .sft__list .item.is-shown .body .thumb .cover,
  .sft .s-intoro .inner .sft__body .sft__list .item.is-shown .head .s-title .read .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .sft .s-intoro .inner .sft__body .sft__list .item.is-shown .body .thumb .img {
    -webkit-filter: brightness(100%);
    filter: brightness(100%);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  .sft .s-intoro .inner .sft__body .sft__list .item:first-child {
    margin-top: 0;
  }

  .sft .s-intoro .inner .sft__body .sft__list .item:nth-child(odd) .head {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    padding-left: 8.57633%;
    margin-right: 2.74443%;
  }

  .sft .s-intoro .inner .sft__body .sft__list .item:nth-child(odd) .body {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    margin-left: 2.74443%;
  }

  .sft .s-intoro .inner .sft__body .sft__list .item:nth-child(even) .head {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    padding-right: 8.57633%;
    margin-left: 2.74443%;
  }

  .sft .s-intoro .inner .sft__body .sft__list .item:nth-child(even) .body {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    margin-right: 2.74443%;
  }

  .sft .s-intoro .inner .sft__body .sft__list .item .head {
    position: relative;
    /* width: 550px; */
  }

  .sft .s-intoro .inner .sft__body .sft__list .item .head .s-title {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    margin-top: 20px;
    overflow: hidden;
  }

  .sft .s-intoro .inner .sft__body .sft__list .item .head .s-title .read {
    position: relative;
    font-size: 24px;
    line-height: 1;
    margin-bottom: 0;
    margin-top: 10px;
    letter-spacing: 0.1em;
    display: block;
  }

  .sft .s-intoro .inner .sft__body .sft__list .item .head .s-title .read .cover {
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    background-color: #fcfcfc;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 3s;
    transition-duration: 3s;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: right;
    transform-origin: right;
    content: "";
  }

  .sft .s-intoro .inner .sft__body .sft__list .item .head .s-title .num {
    color: #b2b2b2;
    letter-spacing: 0.1em;
    font-size: 12px;
    display: block;
    overflow: hidden;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }

  .sft .s-intoro .inner .sft__body .sft__list .item .head .s-title>.text {
    font-size: 30px;
    line-height: 1.6;
    margin-bottom: -9px;
    margin-top: 43px;
    letter-spacing: 0.025em;
    display: block;
  }

  .sft .s-intoro .inner .sft__body .sft__list .item .head>.text {
    font-size: 15px;
    line-height: 2.1538;
    margin-bottom: 21.35px;
    margin-top: 31.35px;
    letter-spacing: 0.03em;
  }

  .sft .s-intoro .inner .sft__body .sft__list .item .head .text-link {
    -webkit-transition: 0.6s opacity;
    transition: 0.6s opacity;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
  }

  .sft .s-intoro .inner .sft__body .sft__list .item .head .text-link.is-shown {
    opacity: 1;
  }

  .sft .s-intoro .inner .sft__body .sft__list .item .head .text-link .text {
    color: $red;
  }

  .sft .s-intoro .inner .sft__body .sft__list .item .head .text-link .arrow {
    margin-top: -3px;
  }

  .sft .s-intoro .inner .sft__body .sft__list .item .head .text-link:hover .text {
    color: #151515;
  }

  .sft .s-intoro .inner .sft__body .sft__list .item .body {
    position: relative;
    max-width: 550px;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    overflow: hidden;
  }

  .sft .s-intoro .inner .sft__body .sft__list .item .body .thumb {
    position: relative;
    display: block;
    overflow: hidden;
    width: 100%;
    height: auto;
  }

  .sft .s-intoro .inner .sft__body .sft__list .item .body .thumb .cover {
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    background-color: #fcfcfc;
    z-index: 1;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 2s;
    transition-duration: 2s;
    -webkit-transform-origin: right;
    transform-origin: right;
    overflow: hidden;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  .sft .s-intoro .inner .sft__body .sft__list .item .body .thumb .img {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    -webkit-transition: 1.6s -webkit-transform, -webkit-filter 3s;
    transition: 1.6s -webkit-transform, -webkit-filter 3s;
    transition: 1.6s transform, filter 3s;
    transition: 1.6s transform, filter 3s, 1.6s -webkit-transform,
      -webkit-filter 3s;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    overflow: hidden;
    -webkit-filter: brightness(200%);
    filter: brightness(200%);
    will-change: filter, transform;
  }

  .sft .s-intoro .body {
    margin-top: 34px;
  }

  .sft .s-intoro .body .text {
    font-size: 12px;
    line-height: 2;
    margin-bottom: -6px;
    margin-top: 20px;
    letter-spacing: 0.03em;
  }

  .sft .s-intoro .body .text:first-child {
    margin-top: 0;
  }

  .sft .s-sft {
    padding-top: 100px;
    padding-bottom: 116px;
  }

  .sft .s-sft .inner {
    padding: 0 38px;
    // background-color: #f8f8f8;
  }

  .sft .s-sft .sft__body {
    width: 100%;
  }

  .sft .s-sft .sft__body .sft__list .item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    margin-top: 116px;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-property: opacity transform;
    transition-property: opacity transform;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }

  .sft .s-sft .sft__body .sft__list .item.is-shown {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .sft .s-sft .sft__body .sft__list .item .head {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    position: relative;
    top: 100px;
    width: 100%;
  }

  .sft .s-sft .sft__body .sft__list .item:first-child {
    margin-top: 0;
  }

  .sft .s-sft .sft__body .sft__list .item .head .s-title {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    margin-top: 10px;
    position: absolute;
    top: -120px;
    left: -10px;
  }

  .sft .s-sft .sft__body .sft__list .item .head .s-title .read {
    font-size: 18px;
    line-height: 1;
    margin-bottom: 0;
    margin-top: 12px;
    letter-spacing: 0.1em;
    display: block;
    text-align: center;
  }

  .sft .s-sft .sft__body .sft__list .item .head .s-title .num {
    color: #b2b2b2;
    letter-spacing: 0.1em;
    font-size: 10px;
    display: block;
    text-align: center;
  }

  .sft .s-sft .sft__body .sft__list .item .head .s-title>.text {
    font-size: 28px;
    line-height: 1.6;
    margin-bottom: -8.4px;
    margin-top: 29.6px;
    letter-spacing: 0.025em;
    display: block;
  }

  .sft .s-sft .sft__body .sft__list .item .head>.text {
    font-size: 12px;
    line-height: 2;
    margin-bottom: 9px;
    margin-top: 14px;
    letter-spacing: 0.03em;
  }

  .sft .s-sft .sft__body .sft__list .item .head .text-link {
    position: absolute;
    right: -32px;
    bottom: -18px;
  }

  .sft .s-sft .sft__body .sft__list .item .head .text-link .text {
    color: $red;
  }

  .sft .s-sft .sft__body .sft__list .item .body {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    position: relative;
    width: 100%;
  }

  .sft .s-sft .sft__body .sft__list .item .body .thumb {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
  }

  .sft .s-sft .sft__body .sft__list .item .body .thumb .img {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    line-height: 0;
    margin: 0;
    border: 0;
  }

  .sft .s-sft .sft__body .sft__list .item.is-shown .body {
    opacity: 1;
  }

  .sft .s-service {
    /* padding: 54px 0 158px; */
  }

  .sft .s-service .inner .service__head {
    width: 486px;
    margin-left: auto;
    margin-right: auto;
  }

  .sft .s-service .inner .service__head .p-headline {
    text-align: center;
  }

  .sft .s-service .inner .service__head .p-headline.is-shown .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .sft .s-service .inner .service__head .text {
    font-size: 15px;
    line-height: 2.1333;
    margin-bottom: 61.5px;
    margin-top: 71.5px;
    letter-spacing: 0.03em;
  }

  .sft .s-service .inner .service__body {
    position: relative;
    display: block;
    width: 100%;
    /* background-color: #fff; */
  }

  .sft .s-service .inner .service__body .service__list .item {
    /* display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between; */
    width: 100%;
    /* margin-top: 64px; */
    padding-top: 10px;
    /* padding-left: 80px; */
  }

  .sft .s-service .inner .service__body .service__list .item:first-child {
    margin-top: 0;
  }

  .sft .s-service .inner .service__body .service__list .item.is-shown .head {
    opacity: 1;
  }

  .sft .s-service .inner .service__body .service__list .item.is-shown .head .service__headline .num {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .sft .s-service .inner .service__body .service__list .item.is-shown .head .service__headline .line {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition-delay: 0.6s;
    transition-delay: 0.6s;
  }

  .sft .s-service .inner .service__body .service__list .item.is-shown .head .service__headline .en {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .sft .s-service .inner .service__body .service__list .item.is-shown .head .s-title .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .sft .s-service .inner .service__body .service__list .item.is-shown .head .s-link {
    text-decoration: underline;
    font-size: 13px;
  }

  .sft .s-service .inner .service__body .service__list .item .head {
    position: relative;
    width: 335px;
    padding-top: 8px;
  }

  .sft .s-service .inner .service__body .service__list .item .head .service__headline {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    overflow: hidden;
  }

  .sft .s-service .inner .service__body .service__list .item .head .service__headline .num {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    position: relative;
    display: inline-block;
    font-size: 12px;
    color: #959595;
    letter-spacing: 0.1em;
    width: 35px;
    z-index: 2;
    overflow: hidden;
    background-color: #fcfcfc;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }

  .sft .s-service .inner .service__body .service__list .item .head .service__headline .line {
    z-index: 1;
    background-color: #959595;
    content: "";
    height: 1px;
    width: 100%;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -1px;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition-duration: 1.8s;
    transition-duration: 1.8s;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }

  .sft .s-service .inner .service__body .service__list .item .head .s-title {
    position: relative;
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    font-size: 20px;
    letter-spacing: 0.05em;
    margin-top: 28px;
  }

  .sft .s-service .inner .service__body .service__list .item .head .s-title .cover {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: -10px;
    background-color: #fcfcfc;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 3s;
    transition-duration: 3s;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: right;
    transform-origin: right;
  }

  .sft .s-service .inner .service__body .service__list .item .head .text {
    font-size: 13px;
    line-height: 2.1538;
    margin-bottom: 12.5px;
    margin-top: 52.5px;
    letter-spacing: 0.03em;
  }

  .sft .s-end .vision__body .text,
  .sft .s-vision .vision__body .text {
    font-size: 15px;
    line-height: 2.1333;
    margin-bottom: -8.5px;
    letter-spacing: 0.03em;
  }

  .sft .s-service .inner .service__body .service__list .item .head .text-link {
    width: 92px;
    -webkit-transition: 0.6s opacity;
    transition: 0.6s opacity;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
    -webkit-transition-delay: 0.6s;
    transition-delay: 0.6s;
  }

  .sft .s-service .inner .service__body .service__list .item .head .text-link.is-shown,
  .sft .s-service .inner .service__body .service__list .item.is-shown .body {
    opacity: 1;
    left: calc(50% - 166px);
  }

  .sft .s-service .inner .service__body .service__list .item .body {
    position: relative;
    max-width: 512px;
    padding-top: 40px;
    /* margin-left: 3.33333%; */
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    overflow: hidden;
  }

  .sft .s-service .inner .service__body .service__list .item .body .thumb {
    position: relative;
    display: block;
    overflow: hidden;
    /* width: 100%;
        height: auto; */
    width: 335px;
    height: 300px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .sft .s-service .inner .service__body .service__list .item .body .thumb .cover {
    position: absolute;
    right: 0;
    left: 0;
    top: -5px;
    bottom: -5px;
    background-color: #fcfcfc;
    z-index: 1;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 2s;
    transition-duration: 2s;
    -webkit-transform-origin: right;
    transform-origin: right;
    overflow: hidden;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  .sft .s-service .inner .service__body .service__list .item .body .thumb .img {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    -webkit-transition: 1.6s -webkit-transform, -webkit-filter 3s;
    transition: 1.6s -webkit-transform, -webkit-filter 3s;
    transition: 1.6s transform, filter 3s;
    transition: 1.6s transform, filter 3s, 1.6s -webkit-transform,
      -webkit-filter 3s;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow: hidden;
    -webkit-filter: brightness(200%);
    filter: brightness(200%);
    will-change: filter, transform;
  }

  .sft .s-service .inner .service__body .service__list .item.is-shown .body .thumb .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .sft .s-service .inner .service__body .service__list .item.is-shown .body .thumb .img {
    -webkit-filter: brightness(100%);
    filter: brightness(100%);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

@include desktop {
  /* .kanata {
        background-image: url("/wp-content/themes/GDI/assets/images/pc/top/Top_visual.gif");
        background-position: center;
        background-size: cover;
        width: 92%;
        height: 105%;

    } */

  /* .red_box {
        width: 500px;
        height: 500px;
        background-color: red;
        position: absolute;
        z-index: -10000;
    } */

  /* Grid styles -------------------------------------------------*/
  .cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 30px;
  }

  .cards {
    max-width: 960px;
    margin: 0 auto 30px;
  }

  article {
    position: relative;
  }

  .article-img {
    height: 200px;
    width: 100%;
    object-fit: cover;
  }

  .article-title {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 10px;
    color: #fff;
    background-color: rgba(255, 255, 255, 0.2);
  }
}

@include desktop {
  .members .s-vision {
    z-index: 1;
    position: relative;
    display: block;
  }

  .members .s-vision,
  .members .s-vision .vision__head .p-headline .cover {
    background-color: #fcfcfc;
  }

  .members .l-kv__bg .bg {
    background-image: url(/wp-content/themes/GDI/assets/images/pc/members/members_kv.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  .members .js-section {
    opacity: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    will-change: opacity;
  }

  .members .s-vision {
    padding: 116px 0 0;
  }

  .members .s-vision .vision__head {
    position: relative;
    display: block;
    max-width: 486px;
    margin-left: auto;
    margin-right: auto;
  }

  .members .s-vision .vision__head .p-headline {
    text-align: center;
    opacity: 1;
  }

  .members .s-vision .vision__body {
    position: relative;
    display: block;
    margin-top: 76px;
    text-align: center;
  }

  .members .s-vision .vision__body .text {
    font-size: 15px;
    line-height: 2.1333;
    margin-bottom: -8.5px;
    margin-top: 37.5px;
    letter-spacing: 0.03em;
  }

  .members .s-vision .vision__body .text:first-child {
    margin-top: 0;
  }

  .members .s-csr {
    position: relative;
    display: block;
    padding: 150px 0 154px;
    z-index: 1;
  }

  .members .s-csr .inner .csr__intoro {
    position: relative;
    display: block;
    // max-width: 486px;
    // margin-left: auto;
    // margin-right: auto;
  }

  .members .s-csr .inner .csr__intoro .p-headline {
    text-align: center;
  }

  .members .s-csr .inner .csr__body {
    margin-top: 80px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .members .s-csr .inner .csr__body .item {
    width: 30.625%;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-property: opacity transform;
    transition-property: opacity transform;
  }

  .members .s-csr .inner .csr__body .item.is-shown {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .members .s-csr .inner .csr__body .item .link,
  .members .s-csr .inner .csr__body .item .link .thumb {
    position: relative;
    display: block;
  }

  .members .s-csr .inner .csr__body .item .link .thumb .img {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
  }

  .members .s-csr .inner .csr__body .item .link .csr__text {
    font-size: 13px;
    line-height: 2.307;
    margin-bottom: 11.5px;
    margin-top: 15.5px;
    letter-spacing: 0.03em;
  }

  .members .s-csr .inner .csr__body .item .text-link {
    width: 148px;
    -webkit-transition: 0.6s opacity;
    transition: 0.6s opacity;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
  }

  .members .s-csr .inner .csr__body .item .text-link.is-shown {
    opacity: 1;
  }

  .members .s-csr .inner .csr__body .item .text-link:hover .text {
    color: #151515;
  }

  .members .s-csr .inner .csr__body .item .text-link .text {
    font-size: 16px;
    letter-spacing: 0.05em;
    color: $red;
    font-weight: 700;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }

  .members .article-title {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    padding-top: 60%;
    text-align: center;
    color: #fff;
    background-color: #2424249c;
  }

  .members .board_article-title {
    position: absolute;
    bottom: 161px;
    width: 100%;
    padding: 10px;
    color: #fff;
    background-color: rgba(255, 255, 255, 0.2);
  }

  .members_gotorecruit {
    padding: 10px 20px 100px;
    margin-left: 80vw;
  }

  .members .inner .solution__content .thumb-wrapper.is-shown .thumb_red .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .members .inner .solution__content .thumb-wrapper.is-shown .thumb_red .img {
    -webkit-filter: brightness(100%);
    filter: brightness(100%);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  .members .inner .solution__content .thumb-wrapper .thumb_red {
    max-width: 872px;
    margin-left: auto;
    /* margin-left: 10px; */
    padding-right: 32px;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    z-index: -1000;
    position: absolute;
    top: 500px;
    left: -62px;
  }

  .members .inner .solution__content .thumb-wrapper .thumb_red .cover {
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    background-color: #fff;
    z-index: 1;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 2s;
    transition-duration: 2s;
    -webkit-transform-origin: right center;
    transform-origin: right center;
    overflow: hidden;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
  }

  .members .inner .solution__content .thumb-wrapper .thumb_red .black_box {
    position: relative;
    display: block;
    width: 27.5vw;
    height: 45vh;
    background-color: red;
    /* width: 100%;
        height: 100%; */
    -webkit-transition: 1.6s -webkit-transform, -webkit-filter 3s;
    transition: 1.6s -webkit-transform, -webkit-filter 3s;
    transition: 1.6s transform, filter 3s;
    transition: 1.6s transform, filter 3s, 1.6s -webkit-transform,
      -webkit-filter 3s;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow: hidden;
    -webkit-filter: brightness(200%);
    filter: brightness(200%);
    will-change: filter, transform;
  }

  .members .inner .solution__content .solution__body1 {
    position: absolute;
    top: 56px;
    right: -30px;
    background-color: #f8f8f8;
    width: 550px;
    padding: 46px 20px 92px 48px;
  }

  .members .inner .solution__content .solution__body1.is-shown .p-headline .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .members .inner .solution__content .solution__body1 .p-headline {
    text-align: left;
  }

  .members .inner .solution__content .solution__body1 .p-headline .cover {
    background-color: #f8f8f8;
  }

  .members .inner .solution__content .solution__body1>.text {
    font-size: 15px;
    line-height: 2.1333;
    margin-bottom: -8.5px;
    margin-top: 51.5px;
    letter-spacing: 0.03em;
  }

  .members .inner .solution__content .thumb-wrapper.is-shown .thumb_blue .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .members .inner .solution__content .thumb-wrapper.is-shown .thumb_blue .img {
    -webkit-filter: brightness(100%);
    filter: brightness(100%);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  .members .inner .solution__content .thumb-wrapper .thumb_blue {
    max-width: 872px;
    margin-left: auto;
    /* margin-left: 10px; */
    padding-right: 32px;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    z-index: -1000;
    position: absolute;
    top: 1050px;
    left: 672px;
  }

  .members .inner .solution__content .thumb-wrapper .thumb_blue .cover {
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    background-color: #fff;
    z-index: 1;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 2s;
    transition-duration: 2s;
    -webkit-transform-origin: right center;
    transform-origin: right center;
    overflow: hidden;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
  }

  .members .inner .solution__content .thumb-wrapper .thumb_blue .black_box {
    position: relative;
    display: block;
    width: 27.5vw;
    height: 42vh;
    background-color: #1167f2;
    /* width: 100%;
        height: 100%; */
    -webkit-transition: 1.6s -webkit-transform, -webkit-filter 3s;
    transition: 1.6s -webkit-transform, -webkit-filter 3s;
    transition: 1.6s transform, filter 3s;
    transition: 1.6s transform, filter 3s, 1.6s -webkit-transform,
      -webkit-filter 3s;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow: hidden;
    /* -webkit-filter: brightness(200%);
        filter: brightness(200%); */
    will-change: filter, transform;
  }

  .members .inner .solution__content .solution__body1 {
    position: absolute;
    top: 56px;
    right: -30px;
    background-color: #f8f8f8;
    width: 550px;
    padding: 46px 20px 92px 48px;
  }

  .members .inner .solution__content .solution__body1.is-shown .p-headline .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .members .inner .solution__content .solution__body1 .p-headline {
    text-align: left;
  }

  .members .inner .solution__content .solution__body1 .p-headline .cover {
    background-color: #f8f8f8;
  }

  .members .inner .solution__content .solution__body1>.text {
    font-size: 15px;
    line-height: 2.1333;
    margin-bottom: -8.5px;
    margin-top: 51.5px;
    letter-spacing: 0.03em;
  }

  /* .hover-mask {
        　width: 250px;
        　height: 175px;
        　position: absolute;
        　top: 0;
        　bottom: 0;
        　left: 0;
        　right: 0;
        　background: rgba(0,0,0,0.5);
        　opacity: 0;
        　color: #fff;
        　display: flex;
        　align-items: center;
        　text-align: center;
        　padding: 30px;
        }

.hover-mask1 {
            　opacity: 1;
            　transition: all 0.6s ease;
            　padding-bottom: 0;
        }

.hover-parent:hover  {
        　padding-bottom: 30px;
        } */

  .article-title {
    background: rgba(0, 0, 0, 0.75);
    /* text-align: center; */
    padding: 45px 0 66px 0;
    opacity: 0;
    -webkit-transition: opacity 0.5s ease;
    -moz-transition: opacity 0.5s ease;
  }

  .thumb:hover .article-title {
    opacity: 1;
  }

  #plus {
    color: #fff;
    font-size: 16px;
  }

  .culture .s-vision {
    z-index: 1;
    position: relative;
    display: block;
  }

  .culture .s-vision,
  .culture .s-vision .vision__head .p-headline .cover {
    background-color: #fcfcfc;
  }

  .culture .l-kv__bg .bg {
    background-image: url(/wp-content/themes/GDI/assets/images/pc/culture/culture_kv.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  .culture .js-section {
    opacity: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    will-change: opacity;
  }

  .culture .s-vision {
    padding: 116px 0 0;
  }

  .culture .s-vision .vision__head {
    position: relative;
    display: block;
    max-width: 486px;
    margin-left: auto;
    margin-right: auto;
  }

  .culture .s-vision .vision__head .p-headline {
    text-align: center;
    opacity: 1;
  }

  .culture .s-vision .vision__body {
    position: relative;
    display: block;
    margin-top: 76px;
    text-align: center;
  }

  .culture .s-vision .vision__body .text {
    font-size: 15px;
    line-height: 2.1333;
    margin-bottom: -8.5px;
    margin-top: 37.5px;
    letter-spacing: 0.03em;
  }

  .culture .s-vision .vision__body .text:first-child {
    margin-top: 0;
  }

  .culture .s-csr {
    position: relative;
    display: block;
    padding: 0 0 154px;
    z-index: 1;
  }

  .culture .s-csr .inner .csr__intoro {
    position: relative;
    display: block;
    max-width: 486px;
    margin-left: auto;
    margin-right: auto;
  }

  .culture .s-csr .inner .csr__intoro .p-headline {
    text-align: center;
  }

  .culture .s-csr .inner .csr__body {
    margin-top: 80px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    position: relative;
    left: calc(100% - 1000px);
  }

  .culture .s-csr .inner .csr__body .item {
    height: 300px;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-property: opacity transform;
    transition-property: opacity transform;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 95px;
    margin-bottom: 50px;
  }

  /* .culture .s-csr .inner .csr__body .item_hidden {
        visibility: hidden;
    } */

  .culture .s-csr .inner .csr__body .item.is-shown {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .culture .s-csr .inner .csr__body .item .link,
  .culture .s-csr .inner .csr__body .item .link .thumb {
    position: relative;
    display: block;
  }

  .culture .s-csr .inner .csr__body .item .link .thumb .img {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
  }

  .culture .s-csr .inner .csr__body .item .link .csr__text {
    font-size: 13px;
    line-height: 2.307;
    margin-bottom: 11.5px;
    margin-top: 15.5px;
    letter-spacing: 0.03em;
    padding-left: 10px;
    width: calc(min(340px, 27vw));
  }

  .culture .s-csr .inner .csr__body .item .text-link {
    width: 148px;
    -webkit-transition: 0.6s opacity;
    transition: 0.6s opacity;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
  }

  .culture .s-csr .inner .csr__body .item .text-link.is-shown {
    opacity: 1;
  }

  .culture .s-csr .inner .csr__body .item .text-link:hover .text {
    color: #151515;
  }

  .culture .s-csr .inner .csr__body .item .text-link .text {
    font-size: 16px;
    letter-spacing: 0.05em;
    color: $red;
    font-weight: 700;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }

  .culture .article-title {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 10px;
    color: #000;
    background-color: rgba(255, 255, 255, 0.2);
  }

  .culture .board_article-title {
    width: 100%;
    padding: 10px;
    font-size: 25px;
    font-weight: 500;
    color: #1167f2;
    line-height: 1.2;
  }

  .culture .s-service {
    padding: 300px 0 300px;
  }

  .culture .s-service .inner .service__head {
    width: 281px;
    margin-left: 40px;
    margin-right: auto;
  }

  .culture .s-service .inner .service__head .p-headline .head_line {
    z-index: 1;
    background-color: red;
    content: "";
    height: 3px;
    width: 15%;
    position: absolute;
    top: 50%;
    /* right: 0; */
    left: -20%;
    bottom: -20%;
    /* margin-top: -1px;
        -webkit-transform: scale(0);
        transform: scale(0);
        -webkit-transition-duration: 1.8s;
        transition-duration: 1.8s;
        -webkit-transition-timing-function: cubic-bezier(.19, 1, .22, 1);
        transition-timing-function: cubic-bezier(.19, 1, .22, 1); */
  }

  .culture .s-service .inner .service__head .p-headline.is-shown .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .culture .s-service .inner .service__head .text {
    font-size: 15px;
    line-height: 2.1333;
    margin-bottom: 61.5px;
    margin-top: 71.5px;
    letter-spacing: 0.03em;
  }

  .culture .s-service .inner .service__body {
    position: relative;
    display: block;
    width: 100%;
  }

  .culture .s-service .inner .service__body .service__list .item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
    margin-top: 64px;
    padding-top: 10px;
    padding-left: 30px;
  }

  .culture .s-service .inner .service__body .service__list .item:first-child {
    margin-top: 0;
  }

  .culture .s-service .inner .service__body .service__list .item.is-shown .head {
    opacity: 1;
  }

  .culture .s-service .inner .service__body .service__list .item.is-shown .head .service__headline .num {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .culture .s-service .inner .service__body .service__list .item.is-shown .head .service__headline .line {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition-delay: 0.6s;
    transition-delay: 0.6s;
  }

  .culture .s-service .inner .service__body .service__list .item.is-shown .head .service__headline .en {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .culture .s-service .inner .service__body .service__list .item.is-shown .head .s-title .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .culture .s-service .inner .service__body .service__list .item.is-shown .head .s-link {
    text-decoration: underline;
    font-size: 13px;
  }

  .culture .s-service .inner .service__body .service__list .item .head {
    position: relative;
    width: 356px;
    padding-top: 8px;
  }

  .culture .s-service .inner .service__body .service__list .item .head .service__headline {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    overflow: hidden;
  }

  .culture .s-service .inner .service__body .service__list .item .head .service__headline .num {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    position: relative;
    display: inline-block;
    font-size: 12px;
    color: #959595;
    letter-spacing: 0.1em;
    width: 20px;
    z-index: 2;
    overflow: hidden;
    background-color: #fcfcfc;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }

  .culture .s-service .inner .service__body .service__list .item .head .service__headline .line {
    z-index: 1;
    background-color: #959595;
    content: "";
    height: 1px;
    width: 100%;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -1px;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition-duration: 1.8s;
    transition-duration: 1.8s;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }

  .culture .s-service .inner .service__body .service__list .item .head .s-title {
    position: relative;
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    font-size: 28px;
    letter-spacing: 0.05em;
    margin-top: 28px;
  }

  .culture .s-service .inner .service__body .service__list .item .head .s-title .cover {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: -10px;
    background-color: #fcfcfc;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 3s;
    transition-duration: 3s;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: right;
    transform-origin: right;
  }

  .culture .s-service .inner .service__body .service__list .item .head .text {
    font-size: 13px;
    line-height: 2.1538;
    margin-bottom: 12.5px;
    margin-top: 52.5px;
    letter-spacing: 0.03em;
  }

  .culture .s-end .vision__body .text,
  .culture .s-vision .vision__body .text {
    font-size: 15px;
    line-height: 2.1333;
    margin-bottom: -8.5px;
    letter-spacing: 0.03em;
  }

  .culture .s-service .inner .service__body .service__list .item .head .text-link {
    width: 92px;
    -webkit-transition: 0.6s opacity;
    transition: 0.6s opacity;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
    -webkit-transition-delay: 0.6s;
    transition-delay: 0.6s;
  }

  .culture .s-service .inner .service__body .service__list .item .head .text-link.is-shown,
  .culture .s-service .inner .service__body .service__list .item.is-shown .body {
    opacity: 1;
  }

  .culture .s-service .inner .service__body .service__list .item .body {
    position: relative;
    max-width: 512px;
    padding-top: 58px;
    margin-left: 3.33333%;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    overflow: hidden;
  }

  .culture .s-service .inner .service__body .service__list .item .body .thumb {
    position: relative;
    display: block;
    overflow: hidden;
    width: 100%;
    height: auto;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .culture .s-service .inner .service__body .service__list .item .body .thumb .cover {
    position: absolute;
    right: 0;
    left: 0;
    top: -5px;
    bottom: -5px;
    background-color: #fcfcfc;
    z-index: 1;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 2s;
    transition-duration: 2s;
    -webkit-transform-origin: right;
    transform-origin: right;
    overflow: hidden;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  .culture .s-service .inner .service__body .service__list .item .body .thumb .img {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    -webkit-transition: 1.6s -webkit-transform, -webkit-filter 3s;
    transition: 1.6s -webkit-transform, -webkit-filter 3s;
    transition: 1.6s transform, filter 3s;
    transition: 1.6s transform, filter 3s, 1.6s -webkit-transform,
      -webkit-filter 3s;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow: hidden;
    -webkit-filter: brightness(200%);
    filter: brightness(200%);
    will-change: filter, transform;
  }

  .culture .s-service .inner .service__body .service__list .item.is-shown .body .thumb .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .culture .s-service .inner .service__body .service__list .item.is-shown .body .thumb .img {
    -webkit-filter: brightness(100%);
    filter: brightness(100%);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  .culture .s-about {
    position: relative;
    padding: 185px 0 250px;
    margin-top: -1px;
  }

  .culture .s-about .inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .culture .s-about .inner .about__head {
    position: relative;
    top: 25px;
    display: block;
    width: 466px;
    /* min-width: 446px; */
    margin-right: 3.33333%;
    -webkit-transition-property: opacity;
    transition-property: opacity;
    -webkit-transition-duration: 0.8s;
    transition-duration: 0.8s;
    opacity: 0;
  }

  .culture .s-about .inner .about__head.is-shown,
  .culture .s-service .inner .service__body .service__list .item.is-shown .head {
    opacity: 1;
  }

  .culture .s-about .inner .about__head .thumb__men {
    position: absolute;
    top: 0;
    right: 0;
    width: 272px;
    height: 100%;
    z-index: 1;
  }

  .culture .s-about .inner .about__head .thumb__men .img {
    display: block;
    max-width: 700px;
  }

  .culture .s-about .inner .about__head .thumb__men .men-blue {
    position: absolute;
    top: -145px;
    right: -55px;
    z-index: 1;
    mix-blend-mode: multiply;
  }

  .culture .s-about .inner .about__head .thumb__men .men-red {
    position: absolute;
    // top: -21px;
    // left: -200px;
    top: -126px;
    left: -387px;
    z-index: 2;
    mix-blend-mode: screen;
  }

  .culture .s-about .inner .about__head .thumb__men .men-red2 {
    position: absolute;
    // top: -30px;
    // left: -203px;
    top: -101px;
    left: -368px;
    z-index: 3;
    mix-blend-mode: screen;
  }

  .culture .s-about .inner .about__head .thumb__men .men-red3 {
    position: absolute;
    // top: -30px;
    // left: -175px;
    top: -130px;
    left: -380px;
    z-index: 4;
  }

  .culture .s-about .inner .about__head .thumb__women {
    position: absolute;
    top: 24px;
    left: 0;
    width: 294px;
    height: 100%;
    z-index: 2;
  }

  .culture .s-about .inner .about__head .thumb__women .img {
    display: block;
    max-width: 263px;
  }

  .culture .s-about .inner .about__head .thumb__women .women-blue {
    position: absolute;
    top: 32px;
    right: 0;
    z-index: 2;
    mix-blend-mode: multiply;
  }

  .culture .s-about .inner .about__head .thumb__women .women-red {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .culture .s-about .inner .about__body {
    position: relative;
    padding: 60px 0 660px;
    /* max-width: 500px; */
    width: 70vw;
    top: -216px;
  }

  .culture .s-about .inner .about__body .p-headline.is-shown .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .culture .s-about .inner .about__body .s-title {
    font-size: 42px;
    font-weight: 500;
    letter-spacing: 0.025em;
    line-height: 1.2;
    margin-top: 80px;
  }

  .culture .s-about .inner .about__body>.text {
    position: relative;
    font-size: 15px;
    line-height: 2.1333;
    margin-bottom: 51.5px;
    margin-top: 23.5px;
    letter-spacing: 0.03em;
  }

  .culture .s-culture {
    /* background-color: #f8f8f8; */
    /* padding-bottom: 180px; */
  }

  .culture .s-culture .inner .culture__body {
    width: 100%;
  }

  .culture .s-culture .inner .culture__body .culture__list .item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
    margin-top: 176px;
    /* background-color: #f8f8f8; */
  }

  .culture .s-culture .inner .culture__body .culture__list .item.is-shown .head {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .culture .s-culture .inner .culture__body .culture__list .item.is-shown .head .s-title .num {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .culture .s-culture .inner .culture__body .culture__list .item.is-shown .body .thumb .cover,
  .culture .s-culture .inner .culture__body .culture__list .item.is-shown .head .s-title .read .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .culture .s-culture .inner .culture__body .culture__list .item.is-shown .body .thumb .img {
    -webkit-filter: brightness(100%);
    filter: brightness(100%);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  .culture .s-culture .inner .culture__body .culture__list .item:first-child {
    margin-top: 0;
  }

  .culture .s-culture .inner .culture__body .culture__list .item:nth-child(odd) .head {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    padding-left: 8.57633%;
    margin-right: 2.74443%;
  }

  .culture .s-culture .inner .culture__body .culture__list .item:nth-child(odd) .body {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    margin-left: 2.74443%;
  }

  .culture .s-culture .inner .culture__body .culture__list .item:nth-child(even) .head {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    padding-right: 10%;
    margin-left: 2.74443%;
  }

  .culture .s-culture .inner .culture__body .culture__list .item:nth-child(even) .body {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    margin-right: 2.74443%;
    padding-left: 10%;
  }

  .culture .s-culture .inner .culture__body .culture__list .item .head {
    position: relative;
    width: 1050px;
  }

  .culture .s-culture .inner .culture__body .culture__list .item .head .s-title {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    margin-top: 20px;
    overflow: hidden;
  }

  .culture .s-culture .inner .culture__body .culture__list .item .head .s-title .read {
    position: relative;
    font-size: 24px;
    line-height: 1;
    margin-bottom: 0;
    margin-top: 10px;
    letter-spacing: 0.1em;
    display: block;
  }

  .culture .s-culture .inner .culture__body .culture__list .item .head .s-title .read .cover {
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    background-color: #f8f8f8;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 3s;
    transition-duration: 3s;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: right;
    transform-origin: right;
    content: "";
  }

  .culture .s-culture .inner .culture__body .culture__list .item .head .s-title .num {
    color: #b2b2b2;
    letter-spacing: 0.1em;
    font-size: 12px;
    display: block;
    overflow: hidden;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }

  .culture .s-culture .inner .culture__body .culture__list .item .head .s-title>.text {
    font-size: 30px;
    line-height: 1.6;
    margin-bottom: -9px;
    margin-top: 43px;
    letter-spacing: 0.025em;
    display: block;
  }

  .culture .s-culture .inner .culture__body .culture__list .item .head>.text {
    font-size: 15px;
    line-height: 2.1538;
    margin-bottom: 21.35px;
    margin-top: 31.35px;
    letter-spacing: 0.03em;
  }

  .culture .s-culture2 {
    background-color: #fcfcfc;
    /* padding-bottom: 180px; */
  }

  .culture .s-culture2 .inner .culture__body {
    width: 100%;
  }

  .culture .s-culture2 .inner .culture__body .culture__list .item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
    margin-top: 176px;
    /* background-color: #f8f8f8; */
  }

  .culture .s-culture2 .inner .culture__body .culture__list .item.is-shown .head {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .culture .s-culture2 .inner .culture__body .culture__list .item.is-shown .head .s-title .num {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .culture .s-culture2 .inner .culture__body .culture__list .item.is-shown .body .thumb .cover,
  .culture .s-culture2 .inner .culture__body .culture__list .item.is-shown .head .s-title .read .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .culture .s-culture2 .inner .culture__body .culture__list .item.is-shown .body .thumb .img {
    -webkit-filter: brightness(100%);
    filter: brightness(100%);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  .culture .s-culture2 .inner .culture__body .culture__list .item:first-child {
    margin-top: 0;
  }

  .culture .s-culture2 .inner .culture__body .culture__list .item:nth-child(odd) .head {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    padding-left: 8.57633%;
    margin-right: 2.74443%;
  }

  .culture .s-culture2 .inner .culture__body .culture__list .item:nth-child(odd) .body {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    margin-left: 2.74443%;
  }

  .culture .s-culture2 .inner .culture__body .culture__list .item:nth-child(even) .head {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    padding-right: 10%;
    margin-left: 2.74443%;
  }

  .culture .s-culture2 .inner .culture__body .culture__list .item:nth-child(even) .body {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    margin-right: 2.74443%;
    padding-left: 10%;
  }

  .culture .s-culture2 .inner .culture__body .culture__list .item .head {
    position: relative;
    width: 1050px;
  }

  .culture .s-culture2 .inner .culture__body .culture__list .item .head .s-title {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    margin-top: 20px;
    overflow: hidden;
  }

  .culture .s-culture2 .inner .culture__body .culture__list .item .head .s-title .read {
    position: relative;
    font-size: 24px;
    line-height: 1;
    margin-bottom: 0;
    margin-top: 10px;
    letter-spacing: 0.1em;
    display: block;
  }

  .culture .s-culture2 .inner .culture__body .culture__list .item .head .s-title .read .cover {
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    background-color: #f8f8f8;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 3s;
    transition-duration: 3s;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: right;
    transform-origin: right;
    content: "";
  }

  .culture .s-culture2 .inner .culture__body .culture__list .item .head .s-title .num {
    color: #b2b2b2;
    letter-spacing: 0.1em;
    font-size: 12px;
    display: block;
    overflow: hidden;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }

  .culture .s-culture2 .inner .culture__body .culture__list .item .head .s-title>.text {
    font-size: 30px;
    line-height: 1.6;
    margin-bottom: -9px;
    margin-top: 43px;
    letter-spacing: 0.025em;
    display: block;
  }

  .culture .s-culture2 .inner .culture__body .culture__list .item .head>.text {
    font-size: 15px;
    line-height: 2.1538;
    margin-bottom: 21.35px;
    margin-top: 31.35px;
    letter-spacing: 0.03em;
  }

  .culture .s-service {
    padding: 300px 0 300px;
  }

  .culture .s-service .inner .service__head {
    width: 281px;
    margin-left: 40px;
    margin-right: auto;
  }

  .culture .s-service .inner .service__head .p-headline .head_line {
    z-index: 1;
    background-color: red;
    content: "";
    height: 3px;
    width: 15%;
    position: absolute;
    top: 50%;
    /* right: 0; */
    left: -20%;
    bottom: -20%;
    /* margin-top: -1px;
        -webkit-transform: scale(0);
        transform: scale(0);
        -webkit-transition-duration: 1.8s;
        transition-duration: 1.8s;
        -webkit-transition-timing-function: cubic-bezier(.19, 1, .22, 1);
        transition-timing-function: cubic-bezier(.19, 1, .22, 1); */
  }

  .culture .s-culture .inner .culture__body {
    width: 100%;
  }

  .culture .s-culture .inner .culture__body .culture__list .item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
    margin-top: 176px;
    /* background-color: #f8f8f8; */
  }

  .culture .s-culture .inner .culture__body .culture__list .item.is-shown .head {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .culture .s-culture .inner .culture__body .culture__list .item.is-shown .head .s-title .num {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .culture .s-culture .inner .culture__body .culture__list .item.is-shown .body .thumb .cover,
  .culture .s-culture .inner .culture__body .culture__list .item.is-shown .head .s-title .read .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .culture .s-culture .inner .culture__body .culture__list .item.is-shown .body .thumb .img {
    -webkit-filter: brightness(100%);
    filter: brightness(100%);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  .culture .s-culture .inner .culture__body .culture__list .item:first-child {
    margin-top: 0;
  }

  .culture .s-culture .inner .culture__body .culture__list .item:nth-child(odd) .head {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    padding-left: 8.57633%;
    margin-right: 2.74443%;
  }

  .culture .s-culture .inner .culture__body .culture__list .item:nth-child(odd) .body {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    margin-left: 2.74443%;
  }

  .culture .s-culture .inner .culture__body .culture__list .item:nth-child(even) .head {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    padding-right: 10%;
    margin-left: 2.74443%;
  }

  .culture .s-culture .inner .culture__body .culture__list .item:nth-child(even) .body {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    margin-right: 2.74443%;
    padding-left: 10%;
  }

  .culture .s-culture .inner .culture__body .culture__list .item .head {
    position: relative;
    width: 1050px;
  }

  .culture .s-culture .inner .culture__body .culture__list .item .head .s-title {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    margin-top: 20px;
    overflow: hidden;
  }

  .culture .s-culture .inner .culture__body .culture__list .item .head .s-title .read {
    position: relative;
    font-size: 24px;
    line-height: 1;
    margin-bottom: 0;
    margin-top: 10px;
    letter-spacing: 0.1em;
    display: block;
  }

  .culture .s-culture .inner .culture__body .culture__list .item .head .s-title .read .cover {
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    background-color: #f8f8f8;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 3s;
    transition-duration: 3s;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: right;
    transform-origin: right;
    content: "";
  }

  .culture .s-culture .inner .culture__body .culture__list .item .head .s-title .num {
    color: #b2b2b2;
    letter-spacing: 0.1em;
    font-size: 12px;
    display: block;
    overflow: hidden;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }

  .culture .s-culture .inner .culture__body .culture__list .item .head .s-title>.text {
    font-size: 30px;
    line-height: 1.6;
    margin-bottom: -9px;
    margin-top: 43px;
    letter-spacing: 0.025em;
    display: block;
  }

  .culture .s-culture .inner .culture__body .culture__list .item .head>.text {
    font-size: 15px;
    line-height: 2.1538;
    margin-bottom: 21.35px;
    margin-top: 31.35px;
    letter-spacing: 0.03em;
  }

  .culture .s-about {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 480px;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    -webkit-justify-content: center;
    align-items: center;
    -webkit-align-items: center;
    margin-top: 420px;
  }

  .culture .carousel_culture {
    width: 50%;
    height: 100%;
    display: flex;
    max-width: 900px;
    max-height: 550px;
    // overflow: hidden;
    /* position: relative; */
  }

  .culture .carousel_culture-item {
    visibility: visible;
    display: flex;
    width: 100%;
    height: max-content;
    align-items: center;
    justify-content: flex-end;
    -webkit-align-items: center;
    -webkit-justify-content: flex-end;
    position: relative;
    /* background-color: #fcfcfc; */
    background-color: #fff;
    flex-shrink: 0;
    -webkit-flex-shrink: 0;
    position: absolute;
    z-index: 0;
    transition: 0.6s all linear;
  }

  .culture .carousel_culture-item__info {
    // height: 121%;
    // display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    width: 100%;
    border-left: 0.5px solid #e1e1e1;
  }

  .culture .carousel_culture-item__image {
    width: 60%;
    height: 100%;
    order: 2;
    align-self: flex-end;
    flex-basis: 60%;

    -webkit-order: 2;
    -webkit-align-self: flex-end;
    -webkit-flex-basis: 60%;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    transform: translateX(100%);
    transition: 0.6s all ease-in-out;
  }

  .culture .carousel_culture-item__subtitle {
    font-family: "Open Sans", sans-serif;
    letter-spacing: 3px;
    font-size: 10px;
    text-transform: uppercase;
    margin: 0;
    color: #7e7e7e;
    font-weight: 700;
    transform: translateY(25%);
    opacity: 0;
    visibility: hidden;
    transition: 0.4s all ease-in-out;
  }

  .culture .carousel_culture-item__title {
    margin: 15px 0 0 0;
    /* font-family: 'Playfair Display', serif; */
    font-family: "NotoSansCJKjp", sans-serif;
    font-size: 30px;
    line-height: 45px;
    letter-spacing: 1px;
    font-weight: 700;
    color: #2c2c2c;
    transform: translateY(25%);
    opacity: 0;
    visibility: hidden;
    transition: 0.6s all ease-in-out;
    position: relative;
    top: 0;
  }

  .culture .carousel_culture-item__description {
    transform: translateY(25%);
    opacity: 0;
    visibility: hidden;
    transition: 0.6s all ease-in-out;
    margin-top: 35px;
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    color: #7e7e7e;
    line-height: 22px;
    margin-bottom: 35px;
    max-height: 100px;
  }

  .carousel_culture-item__container {
    padding-left: 30px;
    padding-top: 165px;
  }

  .culture .carousel_culture-item__num {
    position: absolute;
    font-size: 15px;
    top: 178px;
    left: 39px;
  }

  .culture .carousel_culture-item__btn {
    width: 35%;
    color: #2c2c2c;
    font-family: "Open Sans", sans-serif;
    letter-spacing: 3px;
    font-size: 11px;
    text-transform: uppercase;
    margin: 0;
    width: 35%;
    font-weight: 700;
    text-decoration: none;
    transform: translateY(25%);
    opacity: 0;
    visibility: hidden;
    transition: 0.6s all ease-in-out;
    position: relative;
    left: -50px;
    top: 100px;
  }

  .culture .carousel_culture__nav {
    position: absolute;
    // top: 265px;
    // left: -24px;
    top: 450px;
    left: -91px;
    transform: rotate(90deg);
    z-index: 2;
    background-color: transparent;
  }

  .culture .carousel_culture__icon {
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    fill: #fff;
  }

  .culture .carousel_culture__arrow {
    cursor: pointer;
    display: inline-block;
    padding: 15px 18px;
    position: relative;
    background-color: $red;
  }

  .culture .carousel_culture__arrow:nth-child(1):before {
    content: "";
    right: 50px;
    position: absolute;
    width: 150px;
    background-color: $red;
    height: 1px;
    top: 50%;
    margin-top: -1px;
  }

  .culture .carousel_culture__arrow:nth-child(2):before {
    content: "";
    /* right: -3px; */
    position: absolute;
    width: 150px;
    background-color: $red;
    height: 1px;
    top: 50%;
    left: 50px;
    margin-top: -1px;
  }

  .culture .active {
    z-index: 1;
    display: flex;
    visibility: visible;
  }

  .culture .active .carousel_culture-item__subtitle,
  .active .carousel_culture-item__title,
  .active .carousel_culture-item__description,
  .active .carousel_culture-item__btn {
    transform: translateY(0);
    opacity: 1;
    transition: 0.6s all ease-in-out;
    visibility: visible;
  }

  .culture .active .carousel_culture-item__image {
    transition: 0.6s all ease-in-out;
    transform: translateX(0);
  }

  .culture .inner .solution__content .thumb-wrapper .thumb_red {
    max-width: 872px;
    margin-left: auto;
    /* margin-left: 10px; */
    padding-right: 32px;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    z-index: -1000;
    position: absolute;
    top: 500px;
    left: -62px;
  }

  .culture .inner .solution__content .thumb-wrapper .thumb_red .cover {
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    background-color: #fff;
    z-index: 1;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 2s;
    transition-duration: 2s;
    -webkit-transform-origin: right center;
    transform-origin: right center;
    overflow: hidden;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
  }

  .culture .inner .solution__content .thumb-wrapper .thumb_red .black_box {
    position: relative;
    display: block;
    width: 27.5vw;
    height: 45vh;
    background-color: red;
    /* width: 100%;
        height: 100%; */
    -webkit-transition: 1.6s -webkit-transform, -webkit-filter 3s;
    transition: 1.6s -webkit-transform, -webkit-filter 3s;
    transition: 1.6s transform, filter 3s;
    transition: 1.6s transform, filter 3s, 1.6s -webkit-transform,
      -webkit-filter 3s;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow: hidden;
    -webkit-filter: brightness(200%);
    filter: brightness(200%);
    will-change: filter, transform;
  }

  .culture .inner .solution__content .solution__body1 {
    position: absolute;
    top: 56px;
    right: -30px;
    background-color: #f8f8f8;
    width: 550px;
    padding: 46px 20px 92px 48px;
  }

  .culture .inner .solution__content .solution__body1.is-shown .p-headline .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .culture .inner .solution__content .solution__body1 .p-headline {
    text-align: left;
  }

  .culture .inner .solution__content .solution__body1 .p-headline .cover {
    background-color: #f8f8f8;
  }

  .culture .inner .solution__content .solution__body1>.text {
    font-size: 15px;
    line-height: 2.1333;
    margin-bottom: -8.5px;
    margin-top: 51.5px;
    letter-spacing: 0.03em;
  }

  .culture .inner .solution__content .thumb-wrapper.is-shown .thumb_blue .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .culture .inner .solution__content .thumb-wrapper.is-shown .thumb_blue .img {
    -webkit-filter: brightness(100%);
    filter: brightness(100%);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  .culture .inner .solution__content .thumb-wrapper .thumb_blue {
    max-width: 872px;
    margin-left: auto;
    /* margin-left: 10px; */
    padding-right: 32px;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    z-index: -1000;
    position: absolute;
    top: 450px;
    left: 672px;
  }

  .culture .inner .solution__content .thumb-wrapper .thumb_blue .cover {
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    background-color: #fff;
    z-index: 1;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 2s;
    transition-duration: 2s;
    -webkit-transform-origin: right center;
    transform-origin: right center;
    overflow: hidden;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
  }

  .culture .inner .solution__content .thumb-wrapper .thumb_blue .black_box {
    position: relative;
    display: block;
    width: 27.5vw;
    height: 42vh;
    background-color: #1167f2;
    /* width: 100%;
        height: 100%; */
    -webkit-transition: 1.6s -webkit-transform, -webkit-filter 3s;
    transition: 1.6s -webkit-transform, -webkit-filter 3s;
    transition: 1.6s transform, filter 3s;
    transition: 1.6s transform, filter 3s, 1.6s -webkit-transform,
      -webkit-filter 3s;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow: hidden;
    /* -webkit-filter: brightness(200%);
        filter: brightness(200%); */
    will-change: filter, transform;
  }

  /* second level menu */
  .slide-in-menu ul.first-level li ul.second-level {
    padding-left: 0px;
    background-color: rgba(188, 87, 87, 0.52);
  }

  .slide-in-menu ul.first-level li ul.second-level li a {
    padding: 5px 40px;
  }

  /* plus sign */
  li.has-sub {
    position: relative;
  }

  li.has-sub span.kanataicon {
    position: absolute;
    width: 50px;
    height: 50px;
    top: -30px;
    /* right: 25px; */
    z-index: 100;
  }

  li.has-sub span.kanataicon:after {
    position: absolute;
    content: "";
    display: block;
    width: 12px;
    height: 1px;
    background-color: #fff;
    right: 20px;
    top: 38px;
    z-index: 9999;
  }

  li.has-sub span.kanataicon:hover:after {
    background-color: red;
  }

  li.has-sub span.kanataicon:before {
    position: absolute;
    content: "";
    display: block;
    width: 12px;
    height: 1px;
    background-color: #fff;
    right: 20px;
    top: 38px;
    z-index: 9999;
    transform: rotate(90deg);
  }

  li.has-sub span.kanataicon:hover:before {
    background-color: red;
  }

  .header__gnav .has-sub {
    a {
      color: #fff;
      text-transform: uppercase;
    }

    .second_li--event {
      a {
        text-transform: none;
      }
    }
  }

  ul.second-level {
    display: none;
    padding: 10px;
  }

  ul.second-level .second_li {
    padding-top: 10px;
    padding-bottom: 10px;
    white-space: nowrap;
  }

  ul.second-level .has_sub_a {
    padding-left: 180px;
    text-align: left;
  }

  .fine {
    display: block !important;
  }

  .nn {
    display: none !important;
  }

  //   ::-webkit-scrollbar {
  //       width: 0px;
  //       /* remove scrollbar space */
  //       background: transparent;
  //       /* optional: just make scrollbar invisible */
  //   }

  /* sises */

  .ceo .s-service {
    padding: 54px 0 158px;
  }

  .ceo .s-service .inner .service__head {
    width: 110px;
    margin-left: 40px;
    margin-right: auto;
  }

  .ceo .s-service .inner .service__head .head_line {
    z-index: 1;
    background-color: #1167f2;
    content: "";
    height: 3px;
    width: calc(90% - 250px);
    position: absolute;
    left: 200px;
    margin-top: 10px;
  }

  .ceo .s-service .inner .service__head .p-headline.is-shown .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .sises .s-service {
    padding: 54px 0 158px;
  }

  .sises .s-service .inner .service__head {
    width: 110px;
    margin-left: 40px;
    margin-right: auto;
  }

  .sises .s-service .inner .service__head .p-headline .head_line {
    z-index: 1;
    background-color: red;
    content: "";
    height: 3px;
    width: 30%;
    position: absolute;
    top: 50%;
    /* right: 0; */
    left: -40%;
    bottom: -20%;
    /* margin-top: -1px;
        -webkit-transform: scale(0);
        transform: scale(0);
        -webkit-transition-duration: 1.8s;
        transition-duration: 1.8s;
        -webkit-transition-timing-function: cubic-bezier(.19, 1, .22, 1);
        transition-timing-function: cubic-bezier(.19, 1, .22, 1); */
  }

  .sises .s-service .inner .service__head .p-headline.is-shown .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .sises .s-sises {
    // background-color: #f8f8f8;
    /* padding-bottom: 180px; */
  }

  .sises .s-sises .inner .sises__body {
    width: 100%;
    background-color: #fff;
    margin-top: 25px;
  }

  .sises .s-sises .inner .sises__body .sises__list .item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
    /* margin-top: 176px; */
    /* background-color: #f8f8f8; */
  }

  .sises .s-sises .inner .sises__body .sises__list .item.is-shown .head {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .sises .s-sises .inner .sises__body .sises__list .item.is-shown .head .s-title .num {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .sises .s-sises .inner .sises__body .sises__list .item.is-shown .body .thumb .cover,
  .sises .s-sises .inner .sises__body .sises__list .item.is-shown .head .s-title .read .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .sises .s-sises .inner .sises__body .sises__list .item.is-shown .body .thumb .img {
    -webkit-filter: brightness(100%);
    filter: brightness(100%);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  .sises .s-sises .inner .sises__body .sises__list .item:first-child {
    margin-top: 0;
  }

  .sises .s-sises .inner .sises__body .sises__list .item:nth-child(odd) .head {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    padding-left: 8.57633%;
    margin-right: 2.74443%;
  }

  .sises .s-sises .inner .sises__body .sises__list .item:nth-child(odd) .body {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    margin-left: 2.74443%;
  }

  .sises .s-sises .inner .sises__body .sises__list .item:nth-child(even) .head {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    padding-left: 8.57633%;
    margin-right: 2.74443%;
  }

  .sises .s-sises .inner .sises__body .sises__list .item:nth-child(even) .body {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    margin-right: 2.74443%;
    padding-left: 10%;
  }

  .sises .s-sises .inner .sises__body .sises__list .item .head {
    position: relative;
    width: 1050px;
  }

  .sises .s-sises .inner .sises__body .sises__list .item .head .s-title {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    margin-top: 20px;
    overflow: hidden;
  }

  .sises .s-sises .inner .sises__body .sises__list .item .head .s-title .read {
    position: relative;
    font-size: 24px;
    line-height: 1;
    margin-bottom: 0;
    margin-top: 10px;
    letter-spacing: 0.1em;
    display: block;
  }

  .sises .s-sises .inner .sises__body .sises__list .item .head .s-title .read .cover {
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    /* background-color: #f8f8f8; */
    background-color: #fff;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 3s;
    transition-duration: 3s;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: right;
    transform-origin: right;
    content: "";
  }

  .sises .s-sises .inner .sises__body .sises__list .item .head .s-title .num {
    color: #b2b2b2;
    letter-spacing: 0.1em;
    font-size: 12px;
    display: block;
    overflow: hidden;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }

  .sises .s-sises .inner .sises__body .sises__list .item .head .s-title>.text {
    font-size: 30px;
    line-height: 1.6;
    margin-bottom: -9px;
    margin-top: 43px;
    letter-spacing: 0.025em;
    display: block;
  }

  .sises .s-sises .inner .sises__body .sises__list .item .head>.text {
    font-size: 15px;
    line-height: 2.1538;
    margin-bottom: 21.35px;
    margin-top: 31.35px;
    letter-spacing: 0.03em;
  }

  .s-sises .p-inner--m .sises__body .sises__list .item .head .text-link {
    position: relative;
    display: block;
    width: 110px;
    /* left: 70vw; */
    top: 10vh;
    margin-left: 100%;
  }

  .sises .s-csr {
    position: relative;
    display: block;
    padding: 200px 0 250px;
    z-index: 1;
  }

  .sises .s-csr .inner .csr__intoro {
    position: relative;
    display: block;
    max-width: 486px;
    // margin-left: auto;
    left: calc(50% - 600px);
    margin-right: auto;
  }

  .sises .s-csr .inner .csr__intoro .p-headline {
    text-align: center;
  }

  .sises .s-csr .inner .csr__body {
    margin-top: 80px;
    margin-left: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .sises .s-csr .inner .csr__body .item {
    width: 30.625%;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-property: opacity transform;
    transition-property: opacity transform;
  }

  .sises .s-csr .inner .csr__body .item.is-shown {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    padding: 80px 100px 10px 10px;
  }

  .sises .s-csr .inner .csr__body .item .link,
  .sises .s-csr .inner .csr__body .item .link .thumb {
    position: relative;
    display: block;
  }

  .sises .s-csr .inner .csr__body .item .link .thumb .img {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
  }

  .sises .s-service_last {
    background-color: #fff;
    padding: 80px 0 158px;
  }

  .sises .s-service_last .inner .service__head {
    width: 486px;
    margin-left: auto;
    margin-right: auto;
  }

  .sises .s-service_last .inner .service__head .p-headline {
    text-align: center;
  }

  .sises .s-service_last .inner .service__head .p-headline.is-shown .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .sises .s-service_last .inner .service__head .p-headline .cover {
    background-color: #fcfcfc;
  }

  .sises .s-service_last .inner .service__head .text {
    font-size: 15px;
    line-height: 2.1333;
    margin-bottom: 61.5px;
    margin-top: 71.5px;
    letter-spacing: 0.03em;
  }

  .sises .s-service_last .inner .service__body {
    position: relative;
    display: block;
    width: 100%;
  }

  .sises .s-service_last .inner .service__body .service__list {
    position: relative;

    display: flex;
  }

  .sises .s-service_last .inner .service__body .service__list .item {
    /* width: 100%; */
    /* width: 30vw; */
    padding-top: 10px;
    padding-left: 30px;
    background-color: #f8f8f8;
    height: 50vh;
    margin-left: 50%;
  }

  .sises .s-service_last .inner .service__body .service__list .item:first-child {
    margin-top: 0;
    /* visibility: hidden; */
  }

  .sises .s-service_last .inner .service__body .service__list .item.is-shown .head .service__headline .num {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .sises .s-service_last .inner .service__body .service__list .item.is-shown .head .service__headline .line {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition-delay: 0.6s;
    transition-delay: 0.6s;
  }

  .sises .s-service_last .inner .service__body .service__list .item.is-shown .head .service__headline .en {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .sises .s-service_last .inner .service__body .service__list .item.is-shown .head .s-title .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .sises .s-service_last .inner .service__body .service__list .item .head {
    position: relative;
    width: 356px;
    padding-top: 8px;
    padding-right: 15px;
  }

  .sises .s-service_last .inner .service__body .service__list .item .head .service__headline {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    overflow: hidden;
  }

  .sises .s-service_last .inner .service__body .service__list .item .head .service__headline .num {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    position: relative;
    display: inline-block;
    font-size: 12px;
    color: #959595;
    letter-spacing: 0.1em;
    /* width: 20px; */
    z-index: 2;
    overflow: hidden;
    background-color: #fcfcfc;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }

  .sises .s-service_last .inner .service__body .service__list .item .head .service__headline .line {
    z-index: 1;
    background-color: #959595;
    content: "";
    height: 1px;
    width: 100%;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -1px;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition-duration: 1.8s;
    transition-duration: 1.8s;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }

  .sises .s-service_last .inner .service__body .service__list .item .head .service__headline .en {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 12px;
    color: #959595;
    letter-spacing: 0.1em;
    z-index: 2;
    overflow: hidden;
    background-color: #fcfcfc;
    text-align: right;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }

  .sises .s-service_last .inner .service__body .service__list .item .head .service__headline .en.media {
    width: 50px;
  }

  .sises .s-service_last .inner .service__body .service__list .item .head .service__headline .en.service {
    width: 64px;
  }

  .sises .s-service_last .inner .service__body .service__list .item .head .s-title {
    position: relative;
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    font-size: 28px;
    letter-spacing: 0.05em;
    margin-top: 28px;
  }

  .sises .s-service_last .inner .service__body .service__list .item .head .s-title .cover {
    position: absolute;
    top: -5px;
    right: 0;
    bottom: -5px;
    left: -5px;
    background-color: #f8f8f8;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 3s;
    transition-duration: 3s;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: right;
    transform-origin: right;
  }

  .sises .s-service_last .inner .service__body .service__list .item .head .text {
    font-size: 13px;
    line-height: 2.1538;
    margin-bottom: 12.5px;
    margin-top: 52.5px;
    letter-spacing: 0.03em;
  }

  .sises .s-service_last .inner .service__body .service__list .item .head .text-link {
    -webkit-transition: 0.6s opacity;
    transition: 0.6s opacity;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
    -webkit-transition-delay: 0.6s;
    transition-delay: 0.6s;
  }

  .sises .s-service_last .inner .service__body .service__list .item .head .text-link.is-shown,
  .sises .s-service_last .inner .service__body .service__list .item.is-shown .body {
    opacity: 1;
  }

  .sises .s-service_last .inner .service__body .service__list .item .head .text-link .text {
    font-size: 16px;
  }

  .sises .s-service_last .inner .service__body .service__list .item .body {
    position: relative;
    max-width: 512px;

    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    overflow: hidden;
  }

  .sises .s-service_last .inner .service__body .service__list .item .body .thumb {
    position: relative;
    display: block;
    overflow: hidden;

    width: 335px;
    height: 300px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .sises .s-service_last .inner .service__body .service__list .item .body .thumb .cover {
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    background-color: #fcfcfc;
    z-index: 1;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 2s;
    transition-duration: 2s;
    -webkit-transform-origin: right;
    transform-origin: right;
    overflow: hidden;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  .sises .s-service_last .p-inner .service__body .service__list .item .gotobiskets {
    padding-top: 60px;
    padding-left: 210px;
  }

  .sises .s-service_last .p-inner .service__body .service__list .item .gotobiskets .p-btn {
    height: 7vh;
  }

  .sises .s-service_last .p-inner .service__body .service__list .item .gotobiskets .p-btn .link {
    line-height: 50px;
    text-align: center;
    z-index: 1;
    top: -35px;
  }

  .sises .s-service_last .p-inner .service__body .service__list .item .gotobiskets .p-btn .link .text {
    font-size: 13px;
    line-height: 2.1538;
    /* margin-bottom: 12.5px;
        margin-top: 52.5px;
        letter-spacing: .03em */
  }

  .kanata {
    width: 100%;
    height: 100%;
  }

  .ellipse-container {
    width: 30rem;
    height: 30rem;
    border-radius: 50%;
    margin: 0 auto;
    position: relative;
    top: 10rem;
  }

  .ellipse-container .greeting {
    position: relative;
    top: 14rem;
    left: -6rem;
    right: 0;
    margin: 0 auto;
    text-transform: uppercase;
    letter-spacing: 4rem;
    font-size: 8rem;
    font-weight: 400;
    opacity: 0.5;
  }

  .ellipse-container .greeting::after {
    content: "";
    width: 0.3rem;
    height: 0.3rem;
    border-radius: 50%;
    display: inline-block;
    background-color: #0c383e;
    position: relative;
    top: -0.65rem;
    left: -5.05rem;
  }

  .ellipse {
    border-radius: 50%;
    position: absolute;
    top: 0;
    border-style: solid;
  }

  .ellipse__outer--thin {
    width: 100%;
    height: 100%;
    border-width: 1px;
    border-color: rgba(9, 56, 62, 0.3);
    animation: ellipseOrbit 15s ease-in-out infinite;
  }

  .ellipse__outer--thin::after {
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    color: red;
    position: absolute;
    opacity: 0.3;
  }

  .ellipse__outer--thick {
    width: 99.5%;
    height: 99.5%;
    border-color: #fcfcfc transparent;
    border-width: 2px;
    transform: rotate(-45deg);
    animation: ellipseRotate 15s ease-in-out infinite;
  }

  .ellipse__orbit {
    width: 2.5rem;
    height: 2.5rem;
    border-width: 2px;
    border-color: #000;
    top: 5.5rem;
    /* right: 6.75rem;  */
  }

  .ellipse__orbit::before {
    content: "";
    width: 0.7rem;
    height: 0.7rem;
    border-radius: 50%;
    display: inline-block;
    background-color: #09383e;
    margin: 0 auto;
    left: 0;
    right: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  @keyframes ellipseRotate {
    0% {
      transform: rotate(-45deg);
    }

    100% {
      transform: rotate(-405deg);
    }
  }

  @keyframes ellipseOrbit {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .ceo .s-ceo {
    /* background-color: #f8f8f8; */
    /* padding-bottom: 180px; */
  }

  .ceo .s-ceo .inner .ceo__body {
    width: 100%;
    /* background-color: #fff; */
    margin-top: 25px;
  }

  .ceo .s-ceo .inner .ceo__body .ceo__list .item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
    /* margin-top: 176px; */
    /* background-color: #f8f8f8; */
  }

  .ceo .s-ceo .inner .ceo__body .ceo__list .item.is-shown .head {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .ceo .s-ceo .inner .ceo__body .ceo__list .item.is-shown .head .s-title .num {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .ceo .s-ceo .inner .ceo__body .ceo__list .item.is-shown .body .thumb .cover,
  .ceo .s-ceo .inner .ceo__body .ceo__list .item.is-shown .head .s-title .read .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .ceo .s-ceo .inner .ceo__body .ceo__list .item.is-shown .body .thumb .img {
    -webkit-filter: brightness(100%);
    filter: brightness(100%);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  .ceo .s-ceo .inner .ceo__body .ceo__list .item:first-child {
    margin-top: 0;
  }

  .ceo .s-ceo .inner .ceo__body .ceo__list .item:nth-child(odd) .head {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    padding-left: 8.57633%;
    margin-right: 2.74443%;
  }

  .ceo .s-ceo .inner .ceo__body .ceo__list .item:nth-child(odd) .body {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    margin-left: 2.74443%;
  }

  .ceo .s-ceo .inner .ceo__body .ceo__list .item:nth-child(even) .head {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    padding-left: 8.57633%;
    margin-right: 2.74443%;
  }

  .ceo .s-ceo .inner .ceo__body .ceo__list .item:nth-child(even) .body {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    margin-right: 2.74443%;
    padding-left: 10%;
  }

  .ceo .s-ceo .inner .ceo__body .ceo__list .item .head {
    position: relative;
    width: 1050px;
  }

  .ceo .s-ceo .inner .ceo__body .ceo__list .item .head .s-title {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    margin-top: 20px;
    overflow: hidden;
  }

  .ceo .s-ceo .inner .ceo__body .ceo__list .item .head .s-title .read {
    position: relative;
    font-size: 24px;
    line-height: 1;
    margin-bottom: 0;
    margin-top: 10px;
    letter-spacing: 0.1em;
    display: block;
  }

  .ceo .s-ceo .inner .ceo__body .ceo__list .item .head .s-title .read .cover {
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    background-color: #f8f8f8;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 3s;
    transition-duration: 3s;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: right;
    transform-origin: right;
    content: "";
  }

  .ceo .s-ceo .inner .ceo__body .ceo__list .item .head .s-title .num {
    color: #b2b2b2;
    letter-spacing: 0.1em;
    font-size: 12px;
    display: block;
    overflow: hidden;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }

  .ceo .s-ceo .inner .ceo__body .ceo__list .item .head .s-title>.text {
    font-size: 30px;
    line-height: 1.6;
    margin-bottom: -9px;
    margin-top: 43px;
    letter-spacing: 0.025em;
    display: block;
  }

  .ceo .s-ceo .inner .ceo__body .ceo__list .item .head>.text {
    font-size: 15px;
    line-height: 2.1538;
    margin-bottom: 21.35px;
    margin-top: 31.35px;
    letter-spacing: 0.03em;
  }

  .ceo .s-service_last {
    background-color: #fcfcfc;
    padding: 80px 0 158px;
  }

  .ceo .s-service_last .inner .service__head {
    width: 486px;
    margin-left: auto;
    margin-right: auto;
  }

  .ceo .s-service_last .inner .service__head .p-headline {
    text-align: center;
  }

  .ceo .s-service_last .inner .service__head .p-headline.is-shown .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .ceo .s-service_last .inner .service__head .p-headline .cover {
    background-color: #fcfcfc;
  }

  .ceo .s-service_last .inner .service__head .text {
    font-size: 15px;
    line-height: 2.1333;
    margin-bottom: 61.5px;
    margin-top: 71.5px;
    letter-spacing: 0.03em;
  }

  .ceo .s-service_last .inner .service__body {
    position: relative;
    display: block;
    width: 100%;
  }

  .ceo .s-service_last .inner .service__body .service__list {
    position: relative;

    display: flex;
  }

  .ceo .s-service_last .inner .service__body .service__list .item {
    /* width: 100%; */
    /* width: 30vw; */
    padding-top: 10px;
    padding-left: 30px;
    background-color: #f8f8f8;
    height: 50vh;
    margin-left: 50%;
  }

  .ceo .s-service_last .inner .service__body .service__list .item:first-child {
    margin-top: 0;
    /* visibility: hidden; */
  }

  .ceo .s-service_last .inner .service__body .service__list .item.is-shown .head .service__headline .num {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .ceo .s-service_last .inner .service__body .service__list .item.is-shown .head .service__headline .line {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition-delay: 0.6s;
    transition-delay: 0.6s;
  }

  .ceo .s-service_last .inner .service__body .service__list .item.is-shown .head .service__headline .en {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .ceo .s-service_last .inner .service__body .service__list .item.is-shown .head .s-title .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .ceo .s-service_last .inner .service__body .service__list .item .head {
    position: relative;
    width: 356px;
    padding-top: 8px;
    padding-right: 15px;
  }

  .ceo .s-service_last .inner .service__body .service__list .item .head .service__headline {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    overflow: hidden;
  }

  .ceo .s-service_last .inner .service__body .service__list .item .head .service__headline .num {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    position: relative;
    display: inline-block;
    font-size: 12px;
    color: #959595;
    letter-spacing: 0.1em;
    /* width: 20px; */
    z-index: 2;
    overflow: hidden;
    background-color: #fcfcfc;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }

  .ceo .s-service_last .inner .service__body .service__list .item .head .service__headline .line {
    z-index: 1;
    background-color: #959595;
    content: "";
    height: 1px;
    width: 100%;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -1px;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition-duration: 1.8s;
    transition-duration: 1.8s;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }

  .ceo .s-service_last .inner .service__body .service__list .item .head .service__headline .en {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 12px;
    color: #959595;
    letter-spacing: 0.1em;
    z-index: 2;
    overflow: hidden;
    background-color: #fcfcfc;
    text-align: right;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }

  .ceo .s-service_last .inner .service__body .service__list .item .head .service__headline .en.media {
    width: 50px;
  }

  .ceo .s-service_last .inner .service__body .service__list .item .head .service__headline .en.service {
    width: 64px;
  }

  .ceo .s-service_last .inner .service__body .service__list .item .head .s-title {
    position: relative;
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    font-size: 28px;
    letter-spacing: 0.05em;
    margin-top: 28px;
  }

  .ceo .s-service_last .inner .service__body .service__list .item .head .s-title .cover {
    position: absolute;
    top: -5px;
    right: 0;
    bottom: -5px;
    left: -5px;
    background-color: #f8f8f8;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 3s;
    transition-duration: 3s;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: right;
    transform-origin: right;
  }

  .ceo .s-service_last .inner .service__body .service__list .item .head .text {
    font-size: 13px;
    line-height: 2.1538;
    margin-bottom: 12.5px;
    margin-top: 52.5px;
    letter-spacing: 0.03em;
  }

  .ceo .s-service_last .inner .service__body .service__list .item .head .text-link {
    -webkit-transition: 0.6s opacity;
    transition: 0.6s opacity;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
    -webkit-transition-delay: 0.6s;
    transition-delay: 0.6s;
  }

  .ceo .s-service_last .inner .service__body .service__list .item .head .text-link.is-shown,
  .ceo .s-service_last .inner .service__body .service__list .item.is-shown .body {
    opacity: 1;
  }

  .ceo .s-service_last .inner .service__body .service__list .item .head .text-link .text {
    font-size: 16px;
  }

  .ceo .s-service_last .inner .service__body .service__list .item .body {
    position: relative;
    max-width: 512px;

    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    overflow: hidden;
  }

  .ceo .s-service_last .inner .service__body .service__list .item .body .thumb {
    position: relative;
    display: block;
    overflow: hidden;

    width: 335px;
    height: 300px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .ceo .s-service_last .inner .service__body .service__list .item .body .thumb .cover {
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    background-color: #fcfcfc;
    z-index: 1;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 2s;
    transition-duration: 2s;
    -webkit-transform-origin: right;
    transform-origin: right;
    overflow: hidden;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  .ceo .s-service_last .p-inner .service__body .service__list .item .gotobiskets {
    padding-top: 60px;
    padding-left: 210px;
  }

  .ceo .s-service_last .p-inner .service__body .service__list .item .gotobiskets .p-btn {
    height: 7vh;
  }

  .ceo .s-service_last .p-inner .service__body .service__list .item .gotobiskets .p-btn .link {
    line-height: 50px;
    text-align: center;
    z-index: 1;
    top: -35px;
  }

  .ceo .s-service_last .p-inner .service__body .service__list .item .gotobiskets .p-btn .link .text {
    font-size: 13px;
    line-height: 2.1538;
    /* margin-bottom: 12.5px;
        margin-top: 52.5px;
        letter-spacing: .03em */
  }
}

@media screen and (max-width: 1024px) {
  /* culture */

  .culture_section {
    // padding-top: 45px;
  }

  .culture_section a {
    text-decoration: none;
  }

  .culture_section h1 {
    text-align: center;
    color: white;
    margin-bottom: 1em;
  }

  .culture_section .container {
    margin: auto;
    width: 100%;
    background: #000;
  }

  .culture_section ul>li {
    list-style: none;
  }

  .culture_section ul>li>label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .culture_section ul>li>a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .culture_section ul {
    padding: 0;
  }

  .culture_section .menu>.menu-item.label {
    padding-left: 2.5em;
  }

  .culture_section .menu>.menu-item>label {
    padding-left: 2.5em;
  }

  /*
.sub-menu {
  padding-left: 2.5em;
} */

  .culture_section .accordion-menu input[type="checkbox"] {
    position: absolute;
    opacity: 0;
  }

  .culture_section .accordion-menu label::after {
    background: #ff3c41;
    left: 0.5em;
    background-position: -12px 0;
  }

  .culture_section .menu>.menu-item>label::after {
    left: 1.5em;
    background-position: -12px 0;
  }

  .culture_section .menu-item .actions>button {
    display: inline-block;
  }

  .culture_section button {
    color: #ff3c41;
    background: transparent;
    border-radius: 4px;
    border: transparent;
    /* font-size: 3em; */
    width: 1.2em;
    height: 1.2em;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  // .culture_section button:hover {
  //     background: black;
  //     color: white;
  // }

  .culture_section .accordion-menu label {
    position: relative;
    justify-content: space-between;
    display: flex;
    padding: 1.5em 1.5em 1.5em 1.5em;
    color: #bbb;
    cursor: pointer;
  }

  .culture_section label span {
    position: relative;
    justify-content: space-between;
    display: flex;
    color: #bbb;
    cursor: pointer;
    /* font-size: 45px; */
  }

  .culture_section .accordion-menu a {
    position: relative;
    justify-content: space-between;
    // display: flex;
    // padding: 0.5em 0.5em 0.5em 1.5em;
    color: #bbb;
    font-size: 1.2em;
    cursor: pointer;
  }

  .culture_section .accordion-menu .sub-menu {
    display: none;
  }

  .culture_section .accordion-menu input[type="checkbox"]:checked+label+ul,
  .culture_section .accordion-menu input[type="checkbox"]:checked+label:nth-of-type(n)+ul {
    display: block;
  }

  .culture_section .accordion-menu input[type="checkbox"]:checked+label::after {
    background: #0ebeff;
  }

  .culture_section .menu>.label {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 15px;
    background-color: #fff;
    /* font-size: 40px; */
    line-height: normal;
  }

  .culture_section .menu-item>label {
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding: 34px 10px;
    background-color: #fff;
    /* font-size: 45px; */
    height: 5vh;
    line-height: normal;
  }

  // .culture_section .menu>.label:hover,
  // .culture_section .menu-item>label:hover {
  //     background: #222;
  //     box-shadow: inset 4px 0 0 0 #0ebeff;
  //     color: #fff;
  // }

  .culture_section .by {
    position: fixed;
    bottom: 0.4em;
    right: 0.6em;
  }

  .s-csr {
    position: relative;
    display: block;
    padding: 80px 0 112px;
    z-index: 1;
  }

  .s-csr .inner .csr__intoro {
    position: relative;
    display: block;
  }

  .s-csr .inner .csr__intoro .p-headline {
    text-align: center;
  }

  .s-csr .inner .csr__body {
    margin-top: 34px;
    padding: 0 18px;
  }

  .s-csr .inner .csr__body .item {
    position: relative;
    width: 100%;
    margin-top: 84px;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: opacity, transform;
    transition-property: opacity, transform, -webkit-transform;
  }

  .s-csr .inner .csr__body .item_hidden {
    display: none;
  }

  .s-csr .inner .csr__body .item.is-shown {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  // .s-csr .inner .csr__body .item:first-child {
  //   margin-top: 0;
  // }

  .s-csr .inner .csr__body .item>.link,
  .s-csr .inner .csr__body .item>.link .thumb {
    position: relative;
    display: block;
  }

  .s-csr .inner .csr__body .item>.link .thumb .img {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
  }

  .s-csr .inner .csr__body .item>.link .csr__text {
    font-size: 12px;
    line-height: 2;
    margin-bottom: -6px;
    margin-top: 30px;
    letter-spacing: 0.03em;
  }

  .s-csr .inner .csr__body .item .text-link {
    position: absolute;
    right: -8px;
    bottom: -26px;
  }

  .s-csr .inner .csr__body .item .text-link .arrow {
    right: -36px;
    margin-top: 1px;
  }

  .board_article-title {
    color: #1167f2;
  }

  .culture .p-inner .head {
    padding: 0 18px;
  }

  .s-service {
    background-color: #fcfcfc;
    padding: 0 0 56px;
  }

  .s-service .inner .service__head {
    position: relative;
    width: 100%;
    padding: 90px 18px 0;
    /* background-color: #fcfcfc */
  }

  .s-service .inner .service__head .p-headline {
    text-align: center;
  }

  .s-service .inner .service__head .p-headline .cover {
    background-color: #fff;
  }

  .s-service .inner .service__head .text {
    font-size: 12px;
    line-height: 2;
    margin-bottom: -6px;
    margin-top: -6px;
    letter-spacing: 0.03em;
    padding: 28px 0;
  }

  .s-service .inner .service__body {
    padding: 28px 0 60px;
    position: relative;
    display: block;
    width: 100%;
    background-color: #fff;
  }

  .s-service .inner .service__body .service__list {
    position: relative;
    display: block;
  }

  .s-service .inner .service__body .service__list .item {
    position: relative;
    width: 100%;
    margin-top: 46px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-property: opacity transform;
    transition-property: opacity transform;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }

  .s-service .inner .service__body .service__list .item>.link {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  .s-service .inner .service__body .service__list .item:first-child {
    margin-top: 0;
  }

  .s-service .inner .service__body .service__list .item:last-child .body .thumb .service__headline .line,
  .s-service .inner .service__body .service__list .item:nth-child(2) .body .thumb .service__headline .line,
  .s-service .inner .service__body .service__list .item:nth-child(3) .body .thumb .service__headline .line {
    height: 110px;
  }

  .s-service .inner .service__body .service__list .item.is-shown {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .s-service .inner .service__body .service__list .item .head {
    position: relative;
    padding: 0 18px;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .s-service .inner .service__body .service__list .item .head .s-title {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    font-size: 18px;
    letter-spacing: 0.05em;
    margin-top: 20px;
  }

  .s-service .inner .service__body .service__list .item .head .text {
    font-size: 12px;
    line-height: 2;
    margin-bottom: 16px;
    margin-top: 17px;
    letter-spacing: 0.03em;
  }

  .s-service .inner .service__body .service__list .item .head .text-link {
    position: absolute;
    right: -10px;
    bottom: -18px;
  }

  .s-service .inner .service__body .service__list .item .body {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    position: relative;
    padding: 0 18px;
  }

  .s-service .inner .service__body .service__list .item .body .thumb {
    position: relative;
    display: block;
  }

  .s-service .inner .service__body .service__list .item .body .thumb .service__headline {
    position: absolute;
    top: 0;
    left: -25px;
    height: 168px;
    width: 20px;
  }

  .s-service .inner .service__body .service__list .item .body .thumb .service__headline .num {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    position: absolute;
    top: 0;
    left: -2px;
    font-size: 11px;
    color: #959595;
    letter-spacing: 0.1em;
    z-index: 2;
    height: 16px;
  }

  .s-service .inner .service__body .service__list .item .body .thumb .service__headline .line {
    position: absolute;
    top: 16px;
    left: 25%;
    margin-left: -1px;
    z-index: 1;
    background-color: #000;
    content: "";
    height: 124px;
    width: 1px;
  }

  .s-service .inner .service__body .service__list .item .body .thumb .service__headline .en {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    position: absolute;
    bottom: 0;
    left: -16px;
    font-size: 11px;
    color: #959595;
    letter-spacing: 0.1em;
    z-index: 2;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  .s-service .inner .service__body .service__list .item .body .thumb .service__headline .en.media {
    width: 44px;
    text-align: right;
  }

  .s-service .inner .service__body .service__list .item .body .thumb .service__headline .en.service {
    width: 55px;
    left: -22px;
    bottom: 8px;
    text-align: right;
  }

  .s-service .inner .service__body .service__list .item .body .thumb .img {
    display: block;
    width: 100%;
    height: 100%;
  }

  .s-culture {
    padding-bottom: 116px;
  }

  .s-culture .inner {
    padding: 0 38px;
    background-color: #f8f8f8;
  }

  .s-culture .culture__body {
    width: 100%;
  }

  .s-culture .culture__body .culture__list .item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    margin-top: 116px;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-property: opacity transform;
    transition-property: opacity transform;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }

  .s-culture .culture__body .culture__list .item.is-shown {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .s-culture .culture__body .culture__list .item .head {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    position: relative;
    width: 100%;
  }

  .s-culture .culture__body .culture__list .item:first-child {
    margin-top: 0;
  }

  .s-culture .culture__body .culture__list .item .head .s-title {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    margin-top: 10px;
  }

  .s-culture .culture__body .culture__list .item .head .s-title .read {
    font-size: 18px;
    line-height: 1;
    margin-bottom: 0;
    margin-top: 12px;
    letter-spacing: 0.1em;
    display: block;
    text-align: center;
  }

  .s-culture .culture__body .culture__list .item .head .s-title .num {
    color: #b2b2b2;
    letter-spacing: 0.1em;
    font-size: 10px;
    display: block;
    text-align: center;
  }

  .s-culture .culture__body .culture__list .item .head .s-title>.text {
    font-size: 28px;
    line-height: 1.6;
    margin-bottom: -8.4px;
    margin-top: 29.6px;
    letter-spacing: 0.025em;
    display: block;
  }

  .s-culture .culture__body .culture__list .item .head>.text {
    font-size: 12px;
    line-height: 2;
    margin-bottom: 9px;
    margin-top: 14px;
    letter-spacing: 0.03em;
  }

  .s-culture .culture__body .culture__list .item .head .text-link {
    position: absolute;
    right: -32px;
    bottom: -18px;
  }

  .s-culture .culture__body .culture__list .item .head .text-link .text {
    color: $red;
  }

  .s-culture .culture__body .culture__list .item .body {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    position: relative;
    width: 100%;
  }

  .s-culture .culture__body .culture__list .item .body .thumb {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
  }

  .s-culture .culture__body .culture__list .item .body .thumb .img {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    line-height: 0;
    margin: 0;
    border: 0;
  }

  .s-culture .culture__body .culture__list .item.is-shown .body {
    opacity: 1;
  }

  .s-culture2 {
    padding-bottom: 116px;
  }

  .s-culture2 .inner {
    padding: 0 38px;
    background-color: #f8f8f8;
  }

  .s-culture2 .culture__body {
    width: 100%;
  }

  .s-culture2 .culture__body .culture__list .item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    margin-top: 116px;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-property: opacity transform;
    transition-property: opacity transform;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }

  .s-culture2 .culture__body .culture__list .item.is-shown {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .s-culture2 .culture__body .culture__list .item .head {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    position: relative;
    width: 100%;
  }

  .s-culture2 .culture__body .culture__list .item:first-child {
    margin-top: 0;
  }

  .s-culture2 .culture__body .culture__list .item .head .s-title {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    margin-top: 10px;
  }

  .s-culture2 .culture__body .culture__list .item .head .s-title .read {
    font-size: 18px;
    line-height: 1;
    margin-bottom: 0;
    margin-top: 12px;
    letter-spacing: 0.1em;
    display: block;
    text-align: center;
  }

  .s-culture2 .culture__body .culture__list .item .head .s-title .num {
    color: #b2b2b2;
    letter-spacing: 0.1em;
    font-size: 10px;
    display: block;
    text-align: center;
  }

  .s-culture2 .culture__body .culture__list .item .head .s-title>.text {
    font-size: 28px;
    line-height: 1.6;
    margin-bottom: -8.4px;
    margin-top: 29.6px;
    letter-spacing: 0.025em;
    display: block;
  }

  .s-culture2 .culture__body .culture__list .item .head>.text {
    font-size: 12px;
    line-height: 2;
    margin-bottom: 9px;
    margin-top: 14px;
    letter-spacing: 0.03em;
  }

  .s-culture2 .culture__body .culture__list .item .head .text-link {
    position: absolute;
    right: -32px;
    bottom: -18px;
  }

  .s-culture2 .culture__body .culture__list .item .head .text-link .text {
    color: $red;
  }

  .s-culture2 .culture__body .culture__list .item .body {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    position: relative;
    width: 100%;
  }

  .s-culture2 .culture__body .culture__list .item .body .thumb {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
  }

  .s-culture2 .culture__body .culture__list .item .body .thumb .img {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    line-height: 0;
    margin: 0;
    border: 0;
  }

  .s-culture2 .culture__body .culture__list .item.is-shown .body {
    opacity: 1;
  }

  .s-culture {
    padding-bottom: 116px;
  }

  .s-culture .inner {
    padding: 0 38px;
    background-color: #f8f8f8;
  }

  .s-culture .culture__body {
    width: 100%;
  }

  .s-culture .culture__body .culture__list .item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    margin-top: 116px;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-property: opacity transform;
    transition-property: opacity transform;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }

  .s-culture .culture__body .culture__list .item.is-shown {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .s-culture .culture__body .culture__list .item .head {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    position: relative;
    width: 100%;
  }

  .s-culture .culture__body .culture__list .item:first-child {
    margin-top: 0;
  }

  .s-culture .culture__body .culture__list .item .head .s-title {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    margin-top: 10px;
  }

  .s-culture .culture__body .culture__list .item .head .s-title .read {
    font-size: 18px;
    line-height: 1;
    margin-bottom: 0;
    margin-top: 12px;
    letter-spacing: 0.1em;
    display: block;
    text-align: center;
  }

  .s-culture .culture__body .culture__list .item .head .s-title .num {
    color: #b2b2b2;
    letter-spacing: 0.1em;
    font-size: 10px;
    display: block;
    text-align: center;
  }

  .s-culture .culture__body .culture__list .item .head .s-title>.text {
    font-size: 28px;
    line-height: 1.6;
    margin-bottom: -8.4px;
    margin-top: 29.6px;
    letter-spacing: 0.025em;
    display: block;
  }

  .s-culture .culture__body .culture__list .item .head>.text {
    font-size: 12px;
    line-height: 2;
    margin-bottom: 9px;
    margin-top: 14px;
    letter-spacing: 0.03em;
  }

  /* recruit */
  .recruit .s-csr {
    position: relative;
    display: block;
    padding: 75px 0 112px;
    z-index: 1;
  }

  .recruit .s-csr .inner .csr__intoro {
    position: relative;
    display: block;
  }

  .recruit .s-csr .inner .csr__intoro .p-headline {
    text-align: center;
  }

  .recruit .s-csr .inner .csr__body {
    margin-top: 34px;
    // padding: 0 18px
  }

  .recruit .s-csr .inner .csr__body .item {
    position: relative;
    width: 100%;
    // margin-top: 84px;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: opacity, transform;
    transition-property: opacity, transform, -webkit-transform;
  }

  .recruit .s-csr .inner .csr__body .item_hidden {
    display: none;
  }

  .recruit .s-csr .inner .csr__body .item.is-shown {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .recruit .s-csr .inner .csr__body .item:first-child {
    margin-top: 0;
  }

  .recruit .s-csr .inner .csr__body .item>.link,
  .recruit .s-csr .inner .csr__body .item>.link .thumb {
    position: relative;
    display: block;
  }

  .recruit .s-csr .inner .csr__body .item>.link .thumb .img {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
  }

  .recruit .s-csr .inner .csr__body .item>.link .csr__text {
    font-size: 12px;
    line-height: 2;
    margin-bottom: -6px;
    margin-top: 30px;
    letter-spacing: 0.03em;
  }

  .recruit .s-csr .inner .csr__body .item .text-link {
    position: absolute;
    right: -8px;
    bottom: -26px;
  }

  .recruit .s-csr .inner .csr__body .item .text-link .arrow {
    right: -36px;
    margin-top: 1px;
  }

  .recruit .board_article-title {
    color: #1167f2;
  }

  .recruit .p-inner .head {
    padding: 0 18px;
  }

  .recruit .s-service {
    // background-color: #fcfcfc;
    padding: 0 0 56px;
  }

  .recruit .s-service .inner .service__head {
    position: relative;
    width: 100%;
    padding: 90px 18px 0;
    /* background-color: #fcfcfc */
  }

  .recruit .s-service .inner .service__head .p-headline {
    text-align: center;
  }

  .recruit .s-service .inner .service__head .p-headline .cover {
    background-color: #fff;
  }

  .recruit .s-service .inner .service__head .text {
    font-size: 12px;
    line-height: 2;
    margin-bottom: -6px;
    margin-top: -6px;
    letter-spacing: 0.03em;
    padding: 28px 0;
  }

  .recruit .s-service .inner .service__body {
    padding: 28px 0 60px;
    position: relative;
    display: block;
    width: 100%;
    background-color: #fff;
  }

  .recruit .s-service .inner .service__body .service__list {
    position: relative;
    display: block;
  }

  .recruit .s-service .inner .service__body .service__list .item {
    position: relative;
    width: 100%;
    /* margin-top: 46px; */
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-property: opacity transform;
    transition-property: opacity transform;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }

  .recruit .s-service .inner .service__body .service__list .item>.link {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  .recruit .s-service .inner .service__body .service__list .item:first-child {
    margin-top: 0;
  }

  .recruit .s-service .inner .service__body .service__list .item:last-child .body .thumb .service__headline .line,
  .recruit .s-service .inner .service__body .service__list .item:nth-child(2) .body .thumb .service__headline .line,
  .recruit .s-service .inner .service__body .service__list .item:nth-child(3) .body .thumb .service__headline .line {
    height: 110px;
  }

  .recruit .s-service .inner .service__body .service__list .item.is-shown {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .recruit .s-service .inner .service__body .service__list .item .head {
    position: relative;
    padding: 0 18px;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .recruit .s-service .inner .service__body .service__list .item .head .s-title {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    font-size: 18px;
    letter-spacing: 0.05em;
    margin-top: 20px;
  }

  .recruit .s-service .inner .service__body .service__list .item .head .text {
    font-size: 12px;
    line-height: 2;
    margin-bottom: 16px;
    margin-top: 17px;
    letter-spacing: 0.03em;
  }

  .recruit .s-service .inner .service__body .service__list .item .head .text-link {
    position: absolute;
    right: -10px;
    bottom: -18px;
  }

  .recruit .s-service .inner .service__body .service__list .item .body {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    position: relative;
    padding: 0 18px;
  }

  .recruit .s-service .inner .service__body .service__list .item .body .thumb {
    position: relative;
    display: block;
  }

  .recruit .s-service .inner .service__body .service__list .item .body .thumb .service__headline {
    position: absolute;
    top: 0;
    left: -25px;
    height: 168px;
    width: 20px;
  }

  .recruit .s-service .inner .service__body .service__list .item .body .thumb .service__headline .num {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    position: absolute;
    top: 0;
    left: -2px;
    font-size: 11px;
    color: #959595;
    letter-spacing: 0.1em;
    z-index: 2;
    height: 16px;
  }

  .recruit .s-service .inner .service__body .service__list .item .body .thumb .service__headline .line {
    position: absolute;
    top: 16px;
    left: 25%;
    margin-left: -1px;
    z-index: 1;
    background-color: #000;
    content: "";
    height: 124px;
    width: 1px;
  }

  .recruit .s-service .inner .service__body .service__list .item .body .thumb .service__headline .en {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    position: absolute;
    bottom: 0;
    left: -16px;
    font-size: 11px;
    color: #959595;
    letter-spacing: 0.1em;
    z-index: 2;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  .recruit .s-service .inner .service__body .service__list .item .body .thumb .service__headline .en.media {
    width: 44px;
    text-align: right;
  }

  .recruit .s-service .inner .service__body .service__list .item .body .thumb .service__headline .en.service {
    width: 55px;
    left: -22px;
    bottom: 8px;
    text-align: right;
  }

  .recruit .s-service .inner .service__body .service__list .item .body .thumb .img {
    display: block;
    width: 100%;
    height: 100%;
  }

  .recruit .s-recruit {
    padding-bottom: 116px;
  }

  .recruit .s-recruit .inner {
    padding: 0 38px;
    background-color: #f8f8f8;
  }

  .recruit .s-recruit .recruit__body {
    width: 100%;
  }

  .recruit .s-recruit .recruit__body .recruit__list .item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    margin-top: 116px;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-property: opacity transform;
    transition-property: opacity transform;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }

  .recruit .s-recruit .recruit__body .recruit__list .item.is-shown {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .recruit .s-recruit .recruit__body .recruit__list .item .head {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    position: relative;
    width: 100%;
  }

  .recruit .s-recruit .recruit__body .recruit__list .item:first-child {
    margin-top: 0;
  }

  .recruit .s-recruit .recruit__body .recruit__list .item .head .s-title {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    margin-top: 10px;
  }

  .recruit .s-recruit .recruit__body .recruit__list .item .head .s-title .read {
    font-size: 18px;
    line-height: 1;
    margin-bottom: 0;
    margin-top: 12px;
    letter-spacing: 0.1em;
    display: block;
    text-align: center;
  }

  .recruit .s-recruit .recruit__body .recruit__list .item .head .s-title .num {
    color: #b2b2b2;
    letter-spacing: 0.1em;
    font-size: 10px;
    display: block;
    text-align: center;
  }

  .recruit .s-recruit .recruit__body .recruit__list .item .head .s-title>.text {
    font-size: 28px;
    line-height: 1.6;
    margin-bottom: -8.4px;
    margin-top: 29.6px;
    letter-spacing: 0.025em;
    display: block;
  }

  .recruit .s-recruit .recruit__body .recruit__list .item .head>.text {
    font-size: 12px;
    line-height: 2;
    margin-bottom: 9px;
    margin-top: 14px;
    letter-spacing: 0.03em;
  }

  .recruit .s-recruit .recruit__body .recruit__list .item .head .text-link {
    position: absolute;
    right: -32px;
    bottom: -18px;
  }

  .recruit .s-recruit .recruit__body .recruit__list .item .head .text-link .text {
    color: $red;
  }

  .recruit .s-recruit .recruit__body .recruit__list .item .body {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    position: relative;
    width: 100%;
  }

  .recruit .s-recruit .recruit__body .recruit__list .item .body .thumb {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
  }

  .recruit .s-recruit .recruit__body .recruit__list .item .body .thumb .img {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    line-height: 0;
    margin: 0;
    border: 0;
  }

  .recruit .s-recruit .recruit__body .recruit__list .item.is-shown .body {
    opacity: 1;
  }

  .recruit .s-recruit2 {
    padding-bottom: 116px;
  }

  .recruit .s-recruit2 .inner {
    padding: 0 38px;
    background-color: #f8f8f8;
  }

  .recruit .s-recruit2 .recruit__body {
    width: 100%;
  }

  .recruit .s-recruit2 .recruit__body .recruit__list .item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    margin-top: 116px;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-property: opacity transform;
    transition-property: opacity transform;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }

  .recruit .s-recruit2 .recruit__body .recruit__list .item.is-shown {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .recruit .s-recruit2 .recruit__body .recruit__list .item .head {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    position: relative;
    width: 100%;
  }

  .recruit .s-recruit2 .recruit__body .recruit__list .item:first-child {
    margin-top: 0;
  }

  .recruit .s-recruit2 .recruit__body .recruit__list .item .head .s-title {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    margin-top: 10px;
  }

  .recruit .s-recruit2 .recruit__body .recruit__list .item .head .s-title .read {
    font-size: 18px;
    line-height: 1;
    margin-bottom: 0;
    margin-top: 12px;
    letter-spacing: 0.1em;
    display: block;
    text-align: center;
  }

  .recruit .s-recruit2 .recruit__body .recruit__list .item .head .s-title .num {
    color: #b2b2b2;
    letter-spacing: 0.1em;
    font-size: 10px;
    display: block;
    text-align: center;
  }

  .recruit .s-recruit2 .recruit__body .recruit__list .item .head .s-title>.text {
    font-size: 28px;
    line-height: 1.6;
    margin-bottom: -8.4px;
    margin-top: 29.6px;
    letter-spacing: 0.025em;
    display: block;
  }

  .recruit .s-recruit2 .recruit__body .recruit__list .item .head>.text {
    font-size: 12px;
    line-height: 2;
    margin-bottom: 9px;
    margin-top: 14px;
    letter-spacing: 0.03em;
  }

  .recruit .s-recruit2 .recruit__body .recruit__list .item .head .text-link {
    position: absolute;
    right: -32px;
    bottom: -18px;
  }

  .recruit .s-recruit2 .recruit__body .recruit__list .item .head .text-link .text {
    color: $red;
  }

  .recruit .s-recruit2 .recruit__body .recruit__list .item .body {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    position: relative;
    width: 100%;
  }

  .recruit .s-recruit2 .recruit__body .recruit__list .item .body .thumb {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
  }

  .recruit .s-recruit2 .recruit__body .recruit__list .item .body .thumb .img {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    line-height: 0;
    margin: 0;
    border: 0;
  }

  .recruit .s-recruit2 .recruit__body .recruit__list .item.is-shown .body {
    opacity: 1;
  }

  .recruit .s-recruit {
    padding-bottom: 116px;
  }

  .recruit .s-recruit .inner {
    padding: 0 38px;
    background-color: #f8f8f8;
  }

  .recruit .s-recruit .recruit__body {
    width: 100%;
  }

  .recruit .s-recruit .recruit__body .recruit__list .item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    margin-top: 116px;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-property: opacity transform;
    transition-property: opacity transform;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }

  .recruit .s-recruit .recruit__body .recruit__list .item.is-shown {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .recruit .s-recruit .recruit__body .recruit__list .item .head {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    position: relative;
    width: 100%;
  }

  .recruit .s-recruit .recruit__body .recruit__list .item:first-child {
    margin-top: 0;
  }

  .recruit .s-recruit .recruit__body .recruit__list .item .head .s-title {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    margin-top: 10px;
  }

  .recruit .s-recruit .recruit__body .recruit__list .item .head .s-title .read {
    font-size: 18px;
    line-height: 1;
    margin-bottom: 0;
    margin-top: 12px;
    letter-spacing: 0.1em;
    display: block;
    text-align: center;
  }

  .recruit .s-recruit .recruit__body .recruit__list .item .head .s-title .num {
    color: #b2b2b2;
    letter-spacing: 0.1em;
    font-size: 10px;
    display: block;
    text-align: center;
  }

  .recruit .s-recruit .recruit__body .recruit__list .item .head .s-title>.text {
    font-size: 28px;
    line-height: 1.6;
    margin-bottom: -8.4px;
    margin-top: 29.6px;
    letter-spacing: 0.025em;
    display: block;
  }

  .recruit .s-recruit .recruit__body .recruit__list .item .head>.text {
    font-size: 12px;
    line-height: 2;
    margin-bottom: 9px;
    margin-top: 14px;
    letter-spacing: 0.03em;
  }

  .recruit_section {
    // padding-top: 45px;
  }

  .recruit_section a {
    text-decoration: none;
  }

  .recruit_section h1 {
    text-align: center;
    color: white;
    margin-bottom: 1em;
  }

  .recruit_section .container {
    margin: auto;
    width: 100%;
    background: #000;
  }

  .recruit_section ul>li {
    list-style: none;
  }

  .recruit_section ul>li>label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .recruit_section ul>li>a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .recruit_section ul {
    padding: 0;
  }

  .recruit_section .menu>.menu-item.label {
    padding-left: 1em;
  }

  .recruit_section .menu>.menu-item>label {
    padding-left: 2.5em;
  }

  /*
    .sub-menu {
    padding-left: 2.5em;
    } */

  .recruit_section .accordion-menu input[type="checkbox"] {
    position: absolute;
    opacity: 0;
  }

  .recruit_section .accordion-menu label::after {
    background: #ff3c41;
    left: 0.5em;
    background-position: -12px 0;
  }

  .recruit_section .menu>.menu-item>label::after {
    left: 1.5em;
    background-position: -12px 0;
  }

  .recruit_section .menu-item .actions>button {
    display: inline-block;
  }

  .recruit_section button {
    color: #ff3c41;
    background: transparent;
    border-radius: 4px;
    border: transparent;
    /* font-size: 3em; */
    width: 1.2em;
    height: 1.2em;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  // .recruit_section button:hover {
  //     background: black;
  //     color: white;
  // }

  .recruit_section .accordion-menu label {
    position: relative;
    justify-content: space-between;
    display: flex;
    padding: 1.5em 1.5em 1.5em 1.5em;
    color: #bbb;
    cursor: pointer;
  }

  .recruit_section label span {
    position: relative;
    justify-content: space-between;
    display: flex;
    color: #bbb;
    cursor: pointer;
    /* font-size: 45px; */
  }

  .recruit_section .accordion-menu a {
    position: relative;
    justify-content: space-between;
    display: flex;
    padding: 0.5em 0.5em 0.5em 1.5em;
    color: #bbb;
    font-size: 1.2em;
    cursor: pointer;
  }

  .recruit_section .accordion-menu ul {
    display: none;
  }

  .recruit_section .accordion-menu input[type="checkbox"]:checked+label+ul,
  .recruit_section .accordion-menu input[type="checkbox"]:checked+label:nth-of-type(n)+ul {
    display: block;
  }

  .recruit_section .accordion-menu input[type="checkbox"]:checked+label::after {
    background: #0ebeff;
  }

  .recruit_section .menu>.label {
    justify-content: space-between;
    /* display: flex; */
    align-items: center;
    padding: 15px 15px;
    background-color: #707070;
    /* font-size: 40px; */
    line-height: normal;
  }

  .recruit_section .menu-item>label {
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding: 10px 10px;
    background-color: #fff;
    /* font-size: 45px; */
    height: 5vh;
    line-height: normal;
  }

  // .recruit_section .menu>.label:hover,
  // .recruit_section .menu-item>label:hover {
  //     background: #222;
  //     box-shadow: inset 4px 0 0 0 #0ebeff;
  //     color: #fff;
  // }

  .recruit_section .by {
    position: fixed;
    bottom: 0.4em;
    right: 0.6em;
  }

  /* members */

  .members .js-section {
    opacity: 0;
    will-change: opacity;
  }

  .members .s-vision {
    position: relative;
    padding: 88px 0 0;
  }

  .members .s-vision .vision__head {
    position: relative;
    display: block;
  }

  .members .s-vision .vision__head .p-headline {
    text-align: center;
    opacity: 1;
  }

  .members .s-vision .vision__body {
    position: relative;
    display: block;
    margin-top: 32px;
    text-align: center;
  }

  .members .s-vision .vision__body .text {
    font-size: 12px;
    line-height: 2;
    margin-bottom: -6px;
    margin-top: 30px;
    letter-spacing: 0.03em;
  }

  .members .s-vision .vision__body .text:first-child {
    margin-top: 0;
  }

  .members .inner .solution__content .thumb-wrapper.is-shown .thumb_red .cover {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
  }

  .members .inner .solution__content .thumb-wrapper.is-shown .thumb_red .img {
    -webkit-filter: brightness(100%);
    filter: brightness(100%);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  .members .inner .solution__content .thumb-wrapper .thumb_red {
    max-width: 872px;
    margin-left: auto;
    /* margin-left: 10px; */
    padding-right: 32px;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    z-index: -1000;
    position: absolute;
    top: 500px;
  }

  .members .inner .solution__content .thumb-wrapper .thumb_red .cover {
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    background-color: #fff;
    z-index: 1;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 2s;
    transition-duration: 2s;
    -webkit-transform-origin: right center;
    transform-origin: right center;
    overflow: hidden;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
  }

  .members .inner .solution__content .thumb-wrapper .thumb_red .black_box {
    position: relative;
    display: block;
    width: 76.5vw;
    height: 36vh;
    background-color: red;
    /* width: 100%;
        height: 100%; */
    -webkit-transition: 1.6s -webkit-transform, -webkit-filter 3s;
    transition: 1.6s -webkit-transform, -webkit-filter 3s;
    transition: 1.6s transform, filter 3s;
    transition: 1.6s transform, filter 3s, 1.6s -webkit-transform,
      -webkit-filter 3s;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow: hidden;
    -webkit-filter: brightness(200%);
    filter: brightness(200%);
    will-change: filter, transform;
  }

  .members .s-csr {
    position: relative;
    display: block;
    padding: 150px 0 112px;
    z-index: 1;
  }

  .members .s-csr .inner .csr__intoro {
    position: relative;
    display: block;
  }

  .members .s-csr .inner .csr__intoro .p-headline {
    text-align: center;
  }

  .members .s-csr .inner .csr__body {
    margin-top: 34px;
    /* padding: 0 18px; */
    // display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .members .s-csr .inner .csr__body .item {
    position: relative;
    width: 47%;
    /* margin-top: 84px; */
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: opacity, transform;
    transition-property: opacity, transform, -webkit-transform;
  }

  .members .s-csr .inner .csr__body .item.is-shown {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .members .s-csr .inner .csr__body .item:first-child {
    margin-top: 0;
  }

  .members .s-csr .inner .csr__body .item>.link,
  .members .s-csr .inner .csr__body .item>.link .thumb {
    position: relative;
    display: block;
  }

  .members .s-csr .inner .csr__body .item>.link .thumb .img {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
  }

  .members .s-csr .inner .csr__body .item>.link .csr__text {
    font-size: 12px;
    line-height: 2;
    margin-bottom: -6px;
    margin-top: 30px;
    letter-spacing: 0.03em;
  }

  .members .s-csr .inner .csr__body .item .text-link {
    position: absolute;
    right: -8px;
    bottom: -26px;
  }

  .members .s-csr .inner .csr__body .item .text-link .arrow {
    right: -36px;
    margin-top: 1px;
  }

  .members .p-inner .head {
    padding: 0 18px;
  }

  .members .members_gotorecruit {
    padding-left: 55vw;
    padding-bottom: 5vh;
  }

  .members .csr__intoro {
    text-align: center;
    padding: 30px;
  }

  .members .article-title {
    font-size: 14px;
    padding: 8px;
  }

  /* sises */

  .sises .s-sises {
    padding-bottom: 116px;
  }

  .sises .s-sises .inner {
    padding: 0 38px;
    /* background-color: #f8f8f8; */
  }

  .sises .s-service .inner .service__head .p-headline .head_line {
    z-index: 1;
    background-color: red;
    content: "";
    height: 3px;
    width: 8%;
    position: absolute;
    top: 50%;
    /* right: 0; */
    left: -10%;
    bottom: -20%;
    /* margin-top: -1px;
        -webkit-transform: scale(0);
        transform: scale(0);
        -webkit-transition-duration: 1.8s;
        transition-duration: 1.8s;
        -webkit-transition-timing-function: cubic-bezier(.19, 1, .22, 1);
        transition-timing-function: cubic-bezier(.19, 1, .22, 1); */
  }

  .sises .s-sises .sises__body {
    width: 100%;
  }

  .sises .s-sises .sises__body .sises__list .item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    margin-top: 25px;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-property: opacity transform;
    transition-property: opacity transform;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }

  .sises .s-sises .sises__body .sises__list .item.is-shown {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .sises .s-sises .sises__body .sises__list .item .head {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    position: relative;
    width: 100%;
  }

  .sises .s-sises .sises__body .sises__list .item:first-child {
    margin-top: 0;
  }

  .sises .s-sises .sises__body .sises__list .item .head .s-title {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    margin-top: 10px;
  }

  .sises .s-sises .sises__body .sises__list .item .head .s-title .read {
    font-size: 14px;
    line-height: 1;
    margin-bottom: 0;
    margin-top: 12px;
    letter-spacing: 0.1em;
    display: block;
    text-align: center;
  }

  .sises .s-sises .sises__body .sises__list .item .head .s-title .num {
    color: #b2b2b2;
    letter-spacing: 0.1em;
    font-size: 10px;
    display: block;
    text-align: center;
  }

  .sises .s-sises .sises__body .sises__list .item .head .s-title>.text {
    font-size: 28px;
    line-height: 1.6;
    margin-bottom: -8.4px;
    margin-top: 29.6px;
    letter-spacing: 0.025em;
    display: block;
  }

  .sises .s-sises .sises__body .sises__list .item .head>.text {
    font-size: 12px;
    line-height: 2;
    margin-bottom: 9px;
    margin-top: 14px;
    letter-spacing: 0.03em;
  }

  .sises .s-csr {
    position: relative;
    display: block;
    padding: 100px 0 0;
    z-index: 1;
  }

  .sises .s-csr .inner .csr__intoro {
    position: relative;
    display: block;
  }

  .sises .s-csr .inner .csr__intoro .p-headline {
    text-align: center;
  }

  .sises .s-csr .inner .csr__body {
    margin-top: 65px;
    padding: 20px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sises .s-csr .inner .csr__body .item {
    position: relative;
    width: 30%;
    /* margin-top: 84px; */
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: opacity, transform;
    transition-property: opacity, transform, -webkit-transform;
  }

  .sises .s-csr .inner .csr__body .item.is-shown {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  // .sises .s-csr .inner .csr__body .item:first-child {
  //   margin-top: 0;
  //   padding: 10px 10px 10px 10px;
  // }

  .sises .s-csr .inner .csr__body .item>.link,
  .sises .s-csr .inner .csr__body .item>.link .thumb {
    position: relative;
    display: block;
  }

  .sises .s-csr .inner .csr__body .item>.link .thumb .img {
    position: relative;
    display: block;
    width: 50%;
    margin-left: 24%;
    height: auto;
  }

  .sises .s-service_last {
    padding: 0 0 56px;
  }

  .sises .s-service_last .inner .service__head {
    position: relative;
    width: 100%;
    padding: 90px 18px 0;
    background-color: #fff;
  }

  .sises .s-service_last .inner .service__head .p-headline {
    text-align: center;
  }

  .sises .s-service_last .inner .service__head .p-headline .cover {
    background-color: #fff;
  }

  .sises .s-service_last .inner .service__head .text {
    font-size: 12px;
    line-height: 2;
    margin-bottom: -6px;
    margin-top: -6px;
    letter-spacing: 0.03em;
    padding: 28px 0;
  }

  .sises .s-service_last .inner .service__body {
    padding: 28px 0 60px;
    position: relative;
    display: block;
    width: 100%;
    background-color: #fff;
  }

  .sises .s-service_last .inner .service__body .service__list .item {
    position: relative;
    width: 100%;
    margin-top: 46px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-property: opacity transform;
    transition-property: opacity transform;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }

  .sises .s-service_last .inner .service__body .service__list .item>.link {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  .sises .s-service_last .inner .service__body .service__list .item:first-child {
    /* margin-top: 0; */
  }

  .sises .s-service_last .inner .service__body .service__list .item:last-child .body .thumb .service__headline .line,
  .sises .s-service_last .inner .service__body .service__list .item:nth-child(2) .body .thumb .service__headline .line,
  .sises .s-service_last .inner .service__body .service__list .item:nth-child(3) .body .thumb .service__headline .line {
    height: 110px;
  }

  .sises .s-service_last .inner .service__body .service__list .item.is-shown {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .sises .s-service_last .inner .service__body .service__list .item_hidden {
    display: none;
  }

  .sises .s-service_last .inner .service__body .service__list .item .head {
    position: relative;
    padding: 0 18px;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .sises .s-service_last .inner .service__body .service__list .item .head .s-title {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    font-size: 18px;
    letter-spacing: 0.05em;
    margin-top: 20px;
  }

  .sises .s-service_last .inner .service__body .service__list .item .head .text {
    font-size: 12px;
    line-height: 2;
    margin-bottom: 16px;
    margin-top: 17px;
    letter-spacing: 0.03em;
  }

  .sises .s-service_last .inner .service__body .service__list .item .head .text-link {
    position: absolute;
    right: -10px;
    bottom: -18px;
  }

  .sises .s-service_last .inner .service__body .service__list .item .body {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    position: relative;
    padding: 0 18px;
  }

  .sises .s-service_last .inner .service__body .service__list .item .body .thumb {
    position: relative;
    display: block;
  }

  .sises .s-service_last .inner .service__body .service__list .item .body .thumb .service__headline {
    position: absolute;
    top: 0;
    left: -25px;
    height: 168px;
    width: 20px;
  }

  .sises .s-service_last .inner .service__body .service__list .item .body .thumb .service__headline .num {
    font-family: NotoSansCJKjp, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    position: absolute;
    top: 0;
    left: -2px;
    font-size: 11px;
    color: #959595;
    letter-spacing: 0.1em;
    z-index: 2;
    height: 16px;
  }

  .sises .s-service_last .inner .service__body .service__list .item .body .thumb .service__headline .line {
    position: absolute;
    top: 16px;
    left: 25%;
    margin-left: -1px;
    z-index: 1;
    background-color: #000;
    content: "";
    height: 124px;
    width: 1px;
  }

  .sises_section {
    // padding-top: 45px;
  }

  .sises_section a {
    text-decoration: none;
  }

  .sises_section h1 {
    text-align: center;
    color: white;
    margin-bottom: 1em;
  }

  .sises_section .container {
    margin: auto;
    width: 100%;
    background: #000;
  }

  .sises_section ul>li {
    list-style: none;
  }

  .sises_section ul>li>label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .sises_section ul>li>a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .sises_section ul {
    padding: 0;
  }

  .sises_section .menu>.menu-item.label {
    padding-left: 1em;
  }

  .sises_section .menu>.menu-item>label {
    padding-left: 2.5em;
  }

  /*
.sub-menu {
  padding-left: 2.5em;
} */

  .sises_section .accordion-menu input[type="checkbox"] {
    position: absolute;
    opacity: 0;
  }

  .sises_section .accordion-menu label::after {
    background: #ff3c41;
    left: 0.5em;
    background-position: -12px 0;
  }

  .sises_section .menu>.menu-item>label::after {
    left: 1.5em;
    background-position: -12px 0;
  }

  .sises_section .menu-item .actions>button {
    display: inline-block;
  }

  .sises_section button {
    color: #ff3c41;
    background: transparent;
    border-radius: 4px;
    border: transparent;
    /* font-size: 3em; */
    width: 1.2em;
    height: 1.2em;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  // .sises_section button:hover {
  //     background: black;
  //     color: white;
  // }

  .sises_section .accordion-menu label {
    position: relative;
    justify-content: space-between;
    display: flex;
    padding: 1.5em 1.5em 1.5em 1.5em;
    color: #bbb;
    cursor: pointer;
  }

  .sises_section label span {
    position: relative;
    justify-content: space-between;
    display: flex;
    color: #bbb;
    cursor: pointer;
    /* font-size: 45px; */
  }

  .sises_section .accordion-menu a {
    position: relative;
    justify-content: space-between;
    display: flex;
    padding: 0.5em 0.5em 0.5em 1.5em;
    color: #bbb;
    font-size: 1.2em;
    cursor: pointer;
  }

  .sises_section .accordion-menu ul {
    display: none;
  }

  .sises_section .accordion-menu input[type="checkbox"]:checked+label+ul,
  .sises_section .accordion-menu input[type="checkbox"]:checked+label:nth-of-type(n)+ul {
    display: block;
  }

  .sises_section .accordion-menu input[type="checkbox"]:checked+label::after {
    background: #0ebeff;
  }

  .sises_section .menu>.label {
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding: 15px 15px;
    background-color: #fff;
    /* font-size: 40px; */
    line-height: normal;
  }

  .sises_section .menu-item>label {
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding: 10px 10px;
    background-color: #fff;
    /* font-size: 45px; */
    height: 5vh;
    line-height: normal;
  }

  // .sises_section .menu>.label:hover,
  // .sises_section .menu-item>label:hover {
  //     background: #222;
  //     box-shadow: inset 4px 0 0 0 #0ebeff;
  //     color: #fff;
  // }

  .sises_section .by {
    position: fixed;
    bottom: 0.4em;
    right: 0.6em;
  }

  .sises .accordion_section .accordion-menu input[type="checkbox"] {
    position: absolute;
    opacity: 0;
  }

  .sises .accordion_section .accordion-menu label::after {
    background: #ff3c41;
    left: 0.5em;
    background-position: -12px 0;
  }

  .sises .accordion_section .menu>.menu-item>label::after {
    left: 1.5em;
    background-position: -12px 0;
  }

  .sises .accordion_section .menu-item .actions>button {
    display: inline-block;
  }

  .sises .accordion_section button {
    color: $red;
    background: transparent;
    border-radius: 4px;
    border: transparent;
    font-size: 1em;
    width: 1em;
    height: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    /* cursor: pointer; */
  }

  // .sises .accordion_section button:hover {
  //     background: black;
  //     color: white;
  // }

  .sises .accordion_section .accordion-menu label {
    position: relative;
    justify-content: space-between;
    display: flex;
    padding: 1.5em 1.5em 1.5em 1.5em;
    color: #bbb;
    /* cursor: pointer; */
    border-bottom: 2px solid #fff;
  }

  .sises .accordion_section label span {
    position: relative;
    justify-content: space-between;
    display: flex;
    color: #bbb;
    /* cursor: pointer; */
    font-size: 15px;
  }

  .sises .accordion_section .accordion-menu a {
    position: relative;
    justify-content: space-between;
    display: flex;
    padding: 0.5em 0.5em 0.5em 1.5em;
    color: #bbb;
    font-size: 1.2em;
    /* cursor: pointer; */
  }

  .sises .accordion_section .accordion-menu ul {
    /* display: none; */
    display: block;
    height: 0;
    opacity: 0;
    /* padding: 0 10px; */
    transition: 0.5s;
    visibility: hidden;
  }

  .sises .accordion_section .accordion-menu input[type="checkbox"]:checked+label+ul,
  .sises .accordion_section .accordion-menu input[type="checkbox"]:checked+label:nth-of-type(n)+ul {
    display: block;

    height: 28%;
    opacity: 1;
    /* padding: 10px; */
    visibility: visible;
    overflow: hidden;
  }

  .sises .accordion_section .accordion-menu input[type="checkbox"]:checked+label::after {
    background: #0ebeff;
    transition: 0.5s;
    visibility: visible;
  }

  .sises .accordion_section .menu>.label {
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding: 10px 10px;
    background-color: #fff;
    /* font-size: 40px; */
  }

  .sises .accordion_section .menu-item>label {
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding: 10px 10px;
    background-color: #fff;
    font-size: 45px;
    height: 5vh;
  }

  // .sises .accordion_section .menu>.label:hover,
  // .sises .accordion_section .menu-item>label:hover {
  //     background: #222;
  //     box-shadow: inset 4px 0 0 0 #0ebeff;
  //     color: #fff;
  // }

  .sises .accordion_section .by {
    position: fixed;
    bottom: 0.4em;
    right: 0.6em;
  }

  .inhouseservices .s-media {
    position: relative;
    display: block;
    z-index: 1;
    margin-top: 24px;
  }

  .inhouseservices .s-media .bg {
    display: none;
  }

  .inhouseservices .s-media .inner {
    position: relative;
    padding-bottom: 21px;
  }

  .inhouseservices .s-media .inner .intro {
    padding: 44px 0 36px;
    position: relative;
    display: block;
  }

  .inhouseservices .s-media .inner .intro .p-headline {
    text-align: center;
  }

  .inhouseservices .s-media .inner .body {
    position: relative;
    display: block;
    padding: 0 18px 62px;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: opacity, transform;
    transition-property: opacity, transform, -webkit-transform;
  }

  .inhouseservices .s-media .inner .body.is-shown {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .inhouseservices .s-media .inner .body .text__content {
    position: relative;
    width: 100%;
  }

  .inhouseservices .s-media .inner .body .text__content>.text {
    font-size: 12px;
    line-height: 2;
    margin-bottom: -6px;
    margin-top: 30px;
    letter-spacing: 0.03em;
    color: #333;
  }

  .inhouseservices .s-media .inner .body .thumb__content {
    position: relative;
    display: block;
    width: 100%;
  }

  .inhouseservices .s-media .inner .body .thumb__content .thumb {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
  }

  .inhouseservices .s-media .inner .body .thumb__content .thumb .img {
    display: block;
    width: 100%;
    height: auto;
  }

  .inhouseservices .s-media .inner .body .p-btn--b {
    position: absolute;
    bottom: -20px;
    right: 0;
    left: 0;
    margin: 0 auto;
    width: 147px;
  }

  .inhouseservices .s-media .inner .body .p-btn--b .link {
    line-height: 46px;
  }

  .inhouseservices .s-media .inner .body .p-btn--b .text {
    line-height: 46px;
    font-size: 12px;
  }

  // .inhouseservices .s-service {
  //     position: relative;
  //     display: block;
  //     padding: 42px 0 0;
  //     z-index: 1
  // }

  // .inhouseservices .s-service.service--first {
  //     padding: 88px 0 31px
  // }

  // .inhouseservices .s-service.service--second {
  //     padding: 42px 0 31px
  // }

  // .inhouseservices .s-service.service--last {
  //     padding: 0
  // }

  .inhouseservices .s-service .bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    display: block;
    z-index: -1;
  }

  .inhouseservices .s-service .inner .intro {
    position: relative;
    display: block;
  }

  .inhouseservices .s-service .inner .intro .p-headline {
    position: relative;
    text-align: center;
  }

  .inhouseservices .s-service .inner .body {
    position: relative;
    display: block;
    margin-top: 126px;
    padding: 133px 18px 62px;
    background-color: #fff;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0;
    -webkit-transition-duration: 1.6s;
    transition-duration: 1.6s;
    -webkit-transition-property: opacity transform;
    transition-property: opacity transform;
  }

  .inhouseservices .s-csr,
  .inhouseservices .s-csr .inner .csr__intoro .p-headline .cover {
    background-color: #fcfcfc;
  }

  .inhouseservices .s-service .inner .body.is-shown {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .inhouseservices .s-service .inner .body .text__content {
    position: relative;
    width: 100%;
  }

  .inhouseservices .s-service .inner .body .text__content>.text {
    font-size: 12px;
    line-height: 2;
    margin-bottom: -6px;
    margin-top: 30px;
    letter-spacing: 0.03em;
    color: #333;
  }

  .inhouseservices .s-service .inner .body .text__content>.text:first-child {
    margin-top: 0;
  }

  .inhouseservices .s-service .inner .body .thumb__content {
    position: absolute;
    top: -88px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    margin: 0 auto;
    display: block;
    width: 297px;
  }

  .inhouseservices .s-service .inner .body .thumb__content .thumb {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
  }

  .inhouseservices .s-service .inner .body .thumb__content .thumb .img {
    display: block;
    width: 100%;
    height: auto;
  }

  .inhouseservices .s-service .inner .body .p-btn--b {
    position: absolute;
    bottom: -20px;
    right: 0;
    left: 0;
    margin: 0 auto;
    width: 147px;
  }

  .inhouseservices .s-service .inner .body .p-btn--b .link {
    line-height: 46px;
  }

  .inhouseservices .s-service .inner .body .p-btn--b .text {
    line-height: 46px;
    font-size: 12px;
  }

  /* second level menu */
  .slide-in-menu ul.first-level li ul.second-level {
    padding-left: 0px;
    background-color: rgba(188, 87, 87, 0.52);
  }

  .slide-in-menu ul.first-level li ul.second-level li a {
    padding: 5px 40px;
  }

  /* plus sign */
  li.has-sub {
    position: relative;
  }

  li.has-sub span.kanataicon {
    position: absolute;
    width: 50px;
    height: 50px;
    top: -30px;
    /* right: 25px; */
    z-index: 100;
  }

  li.has-sub span.kanataicon:after {
    position: absolute;
    content: "";
    display: block;
    width: 12px;
    height: 1px;
    background-color: #fff;
    right: 20px;
    top: 38px;
    z-index: 9999;
  }

  li.has-sub span.kanataicon:hover:after {
    background-color: red;
  }

  li.has-sub span.kanataicon:before {
    position: absolute;
    content: "";
    display: block;
    width: 12px;
    height: 1px;
    background-color: #fff;
    right: 20px;
    top: 38px;
    z-index: 9999;
    transform: rotate(90deg);
  }

  li.has-sub span.kanataicon:hover:before {
    background-color: red;
  }

  .header__gnav .has-sub a {
    color: #fff;
    cursor: pointer;
  }

  .l-header__gnav .header__gnav .item .link .sub_span {
    font-size: 12px;
  }

  .fine {
    display: block !important;
  }

  .nn {
    display: none !important;
  }

  //   ::-webkit-scrollbar {
  //       width: 0px;
  //       /* remove scrollbar space */
  //       background: transparent;
  //       /* optional: just make scrollbar invisible */
  //   }
}

/* Nisei code */

@media screen and (max-width: 1024px) {
  #bg_met_design {
    /* left: 10%;
        width: 80%; */
    background-size: cover;
    background-repeat: no-repeat;
  }

  .pc_only {
    visibility: hidden;
    height: 0;
    position: absolute;
  }

  .carousel-item {
    clear: both;
    position: relative;
    margin-bottom: 100px;
  }

  .carousel-item__title {
    margin: 15px 0 0 0;
    font-family: "Playfair Display", serif;
    font-size: 44px;
    line-height: 45px;
    letter-spacing: 3px;
    font-weight: 700;
    color: #2c2c2c;
  }

  .page_description_title {
    font-weight: 500;
    font-feature-settings: "palt" 1;
    font-size: 16px;
    letter-spacing: 0.1em;
    text-align: center;
  }

  .met_text {
    font-size: 12px;
    line-height: 2;
    margin-bottom: -6px;
    // letter-spacing: 0.03em;
  }

  .page_description {
    margin: 30px 38px 0 38px;
    font-size: 13px;
    letter-spacing: 0.05em;
    line-height: 1.4;
  }

  .carousel__nav {
    visibility: hidden;
  }

  // .carousel-item__info {
  //     margin-left: 15%;
  //     width: 70%;
  // }

  .carousel-item__selected_num {
    margin-left: 40px;
    float: left;
  }

  .carousel-item__selected_title {
    writing-mode: vertical-lr;
    position: absolute;
    left: 25px;
    top: 200px;
    height: 150px;
  }

  .carousel-item__line {
    height: 100px;
    position: absolute;
    top: 80px;
    /* margin-top: 90px; */
    margin-left: 50px;
    border-left: solid 1px;
  }

  .member_certifications {}

  .member_certification {
    width: 70%;
    margin: 5px 5px 30px 30px;
  }

  .member_2box {
    width: 70%;
    margin: 5px 5px 30px 30px;
  }

  .member_2boxs {}

  .background_blk {
    background: black;
    opacity: 0.8;
    color: white;
    padding: 50px 0 20px 0;
  }

  .background_red {
    background: $red;
    opacity: 0.9;
    color: white;
    padding: 50px 0 70px 0;
  }

  .nations-img {
    background-image: url(/wp-content/themes/GDI/assets/images/pc/members/nations-sp.png) !important;
  }

  .header_left_csr {
    height: auto;
  }

  .header_left {
    // margin-top: 45px;
  }

  .page_description_long {
    margin: 0 50px 0 50px;
  }

  .page_description_short {
    margin: 0 50px 0 50px;
  }
}

@include desktop {
  .nations-img {
    background-image: url(/wp-content/themes/GDI/assets/images/pc/members/nations.png) !important;
  }

  .member_2boxs {
    display: flex;
  }

  // .after_origin_header {
  //     margin-top: 400px;
  // }

  .carousel-item__selected_title {
    // transform: translateY(25%);
    opacity: 0;
    visibility: hidden;
    // transition: 1s all ease-in-out;
    // transition-delay: 0.4s;
  }

  #bg_met_design {
    left: calc(50% - 60px);
    width: 574px;
    background-size: 574px 434px;
    top: 47px;
    background-image: url("/wp-content/themes/GDI/assets/images/pc/SI/header_siArtboard-2.png");
    background-repeat: no-repeat;
  }

  #bg_ceo {
    left: calc(50% - 280px);
    width: 455px;
    top: 236px;
  }

  .met_text {
    font-size: 15px;
    line-height: 1.6;
    letter-spacing: 0.03em;
    max-width: 457px;
    max-height: 176px;
  }

  .met_text_animation {
    font-size: 15px;
    line-height: 2;
    margin-top: 20px;
    margin-bottom: -6px;
    /* margin-top: 20px; */
    letter-spacing: 0.03em;
    // transform: translateY(25%);
    opacity: 0;
    visibility: hidden;
    transition: 1s all ease-in-out;
    transition-delay: 0.4s;
  }

  #vision_header {
    left: calc(50% - 130px);
    width: 574px;
    background-size: 417px 467px;
    top: 47px;
    background-repeat: no-repeat;
  }

  .carousel {
    /* width: 900px;
        margin: 0 auto;
        height: 500px;
        overflow: hidden; */
  }

  .header_carousel {
    position: relative;
    top: -100px;
    // opacity: 0;
    visibility: hidden;
    transform: translateY(-10%);
    // transition: 1s all ease-in-out;
  }

  .header_carousel.is-show {
    // opacity: 0;
    visibility: visible;
    transform: translateY(0);
    position: absolute;
    top: 330px;
    // z-index: 500;
    left: calc(50% - 590px);
  }

  .carousel-item {
    visibility: visible;
  }

  .carousel-item-copy {
    visibility: visible;
    display: flex;
    width: 900px;
    height: 100%;
    top: 220px;
    left: 750px;
    position: relative;
    flex-shrink: 0;
    -webkit-flex-shrink: 0;
    position: absolute;
    /* z-index: 0; */
    // t
  }

  .carousel-item__title {
    margin: 15px 0 0 0;
    font-family: "Playfair Display", serif;
    font-size: 44px;
    line-height: 45px;
    letter-spacing: 3px;
    font-weight: 700;
    color: #2c2c2c;
  }

  .header_left {
    /* width: 0px;
        left: calc(50% - 500px);
        overflow: hidden;
        position: absolute;
        top: 0; */
    /* transition: 500ms linear; */
    /* transition: 500ms ease-in;
        transition-delay: 2s;
        height: 600px; */
  }

  .header_left.is-show {
    width: 1000px;
    background: transparent;
  }

  .page_title {
    /* position: absolute;
        top: 100px;
        width: 435px;
        height: 240px;
        font-size: 36px;
        padding-top: 25px;
        padding-left: 50px;
        background: #1167f2;
        color: white; */
    /* background: #ef342b;
        background: -moz-linear-gradient(-45deg, #ef342b 0%, #c78fea 100%);
        background: -webkit-linear-gradient(-45deg, #ef342b 0%, #c78fea 100%);
        background: linear-gradient(135deg, #ef342b 0%, #c78fea 100%); */
  }

  .page_title:before {
    /* content: '';
        position: absolute;
        width: 27px;
        background-color: #fff;
        height: 3px;
        top: 17%;
        left: 13px; */
  }

  .page_title_ceo {
    position: absolute;
    top: 100px;
    width: 980px;
    height: 540px;
    font-size: 36px;
    padding-top: 25px;
    padding-left: 70px;
    background: #1167f2;
    color: white;
  }

  .page_title_ceo:before {
    content: "";
    position: absolute;
    width: 27px;
    background-color: #fff;
    height: 3px;
    top: 17%;
    left: 13px;
  }

  .page_description_ceo {
    position: absolute;
    left: 12%;
    top: 190px;
    width: 100%;
    font-size: 13px;
    padding: 15px 20px 15px 20px;
    color: #fff;
  }

  .page_description_title_ceo {
    position: absolute;
    top: 390px;
    left: -80px;
    font-size: 15px;
  }

  .ceo_about {
    position: absolute;
    left: 620px;
    top: -77px;
    font-size: 13px;
  }

  .page_description {
    position: absolute;
    left: 12%;
    top: 190px;
    width: 412px;
    font-size: 13px;
    padding: 15px 20px 15px 20px;
    -webkit-box-shadow: 10px 10px 15px -8px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 10px 10px 15px -8px rgba(0, 0, 0, 0.08);
    box-shadow: 10px 10px 15px -8px rgba(0, 0, 0, 0.08);
    z-index: 999;
    background-color: #fff;
  }

  .page_description_title {
    font-size: 25px;
  }

  .page_description_long {
    position: absolute;
    left: 10%;
    top: 319px;
    width: 43vw;
    /* height: 308px; */
    padding: 82px 84px 50px 83px;
    background: #1167f2;
    z-index: 2;
    color: #fff;
    -webkit-box-shadow: -2px 10px 4px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: -2px 10px 4px 0px rgba(0, 0, 0, 0.1);
    box-shadow: -2px 10px 4px 0px rgba(0, 0, 0, 0.1);
  }

  .page_description_short {
    position: absolute;
    left: 12%;
    top: 190px;
    width: 465px;
    /* width topadding kaeta */
    padding: 45px 50px 45px 50px;
    font-size: 13px;
    /* padding: 15px 20px 15px 20px; */
    background: #fff;
    z-index: 999;
    -webkit-box-shadow: 10px 10px 15px -8px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 10px 10px 15px -8px rgba(0, 0, 0, 0.08);
    box-shadow: 10px 10px 15px -8px rgba(0, 0, 0, 0.08);
  }

  .carousel__nav {
    position: absolute;
    top: 330px;
    width: 550px;
    display: inline-block;
    color: white;
    // display: flex;
    // flex-direction: row;
    // justify-content: space-between;
    // flex-wrap: wrap;
  }

  .carousel__nav__title {
    /* font-weight: 700; */
    font-size: 12px;
    margin: 60px 0 0 60px;
    top: 0%;
    left: 0;
    transition: 0.3s ease-in;
    //    transition-delay: 0.2s;
    text-align: left;
    align-items: center;
  }

  .carousel__nav__title__selected {
    transform: translateY(-20px);
    position: relative;
    // padding-bottom: 5px;
    // border-bottom: 2px solid #fff;
    // align-items: center;
  }

  .carousel__nav__num {
    font-size: 12px;
    position: relative;
    // left: 21px;
    left: 16px;
    top: -15px;
    /* visibility: visible; */
  }

  .carousel__nav__line {
    display: inline-block;
    visibility: hidden;
    position: relative;
    top: 0px;
    left: 18px;
    width: 85%;
    height: 2px;
    background-color: #fff;
    transform: scaleX(0);
    opacity: 0;
    transition: 0.3s all ease-in-out;
    transform-origin: right;
  }

  .active {
    z-index: 1;
    display: flex;
    visibility: visible;
  }

  .active_line {
    visibility: visible;
    transform: scale(1);
    opacity: 1;
  }

  .active {
    visibility: visible;
  }

  .carousel_item_description_info {
    // transform: translateY(25%);
    opacity: 0;
    visibility: hidden;
    // transition: 1s all ease-in-out;
    // transition-delay: 0.4s;
  }

  .active .carousel-item__subtitle,
  .active .carousel-item__title,
  .active .carousel-item__description,
  .active .carousel-item__btn,
  .active .carousel-item__description2,
  .active .carousel-item__selected_num,
  .active .carousel-item__selected_title,
  .active .carousel-item__line,
  .active .carousel_item_description_info,
  .active .num_data_parcentage,
  .active .num_data,
  .active .nationl_img,
  .active .met_text_animation {
    // transform: translateY(0);
    opacity: 1;
    transition: 0.4s all ease-in-out;
    visibility: visible;
  }

  .carousel-item__info {
    /* height: 100%; */
    display: flex;
    justify-content: center;
    flex-direction: column;
    display: -webkit-flex;
    -webkit-justify-content: center;
    -webkit-flex-direction: column;
    // left: -5%;
    // top: 81px;
    // position: absolute;
    // width: 480px;
    /* height:400px; */
    /* background-color: #fcfcfc; */
  }

  .carousel-item__description {
    transform: translateY(25%);
    opacity: 0;
    visibility: hidden;
    // transition: 1s all ease-in-out;
    // transition-delay: 0.4s;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: -0.5px;
  }

  .carousel-item__selected_num {
    transform: translateY(25%);
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 173px;
    left: -40px;
    transition: 1s all ease-in-out;
    transition-delay: 0.4s;
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    color: red;
  }

  .carousel-item__line {
    transform: translateY(25%);
    opacity: 0;
    position: absolute;
    top: 175px;
    left: 0;
    visibility: hidden;
    transition: 1s all ease-in-out;
    transition-delay: 0.4s;
    margin-top: 7px;
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    line-height: 22px;
    content: "";
    height: 1px;
    width: 430px;
    z-index: 999999999;
    background: red;
  }

  .header_left_csr {
    height: 700px;
  }

  .header_left_bisckets {
    width: 450px;
  }

  .member_certifications {
    display: flex;
    margin-top: 35px;
  }

  .member_certification {
    width: 33%;
    margin: 5px 5px 5px 5px;
  }

  .member_2box {
    width: 50%;
    margin: 5px 10px 5px 10px;
  }

  .header_left_csr {
    height: 700px;
  }

  .nationl_img {
    transform: translateY(10%);
    opacity: 0;
    visibility: hidden;
    transition: 1s all ease-in-out;
  }

  .num_data {
    font-size: 64px;
    transform: translateY(25%);
    opacity: 0;
    visibility: hidden;
    transition: 1s all ease-in-out;
  }

  .num_data_parcentage {
    font-size: 26px;
    margin-top: 30px;
    visibility: hidden;
    transition: 1s all ease-in-out;
    transform: translateY(25%);
    opacity: 0;
    visibility: hidden;
  }
}

/* culture bottom */

@include desktop {
  .culture_bottom_nav {
    // padding-left: 80px;
  }

  .carousel-bottom-item__selected_title {
    visibility: hidden;
  }

  .header_carousel-bottom {
    position: relative;
    top: -100px;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10%);
    transition: 1s all ease-in-out;
    transition-delay: 1.8s;
  }

  .header_carousel-bottom.is-show {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    position: absolute;
    top: 330px;
    // z-index: 999;
    left: calc(50% - 340px);
  }

  .carousel-bottom {
    width: 100%;
    margin: 0 auto;
    height: 500px;
    max-width: 900px;
    max-height: 550px;
    overflow: hidden;
  }

  .carousel-bottom-item {
    visibility: visible;
    display: flex;
    width: 700px;
    height: 100%;
    position: relative;
    flex-shrink: 0;
    -webkit-flex-shrink: 0;
    position: absolute;
  }

  .carousel-bottom-item__title {
    margin: 15px 0 0 0;
    font-family: "Playfair Display", serif;
    font-size: 44px;
    line-height: 45px;
    letter-spacing: 3px;
    font-weight: 700;
    color: #2c2c2c;
  }

  .carousel-bottom__nav {
    position: absolute;
    left: calc(50% - 500px);
    width: 380px;
    height: 380px;
    display: flex;
    flex-direction: column;
    z-index: 9999;
    color: #242424;
    padding-top: 200px;
  }

  .carousel-bottom__nav__title {
    // font-size: 15px;
    // margin: 50px 0 0 50px;
    // top: 0%;
    // left: 0;
    transition: 0.3s ease;
    text-align: left;
    padding: 30px;
  }

  .carousel-bottom__nav__title__selected {
    // font-size: 18px;
    // left: 20px;
    // margin: 30px 0 0 30px;
    font-weight: bold;
    transform: translateY(-10px);
  }

  .carousel-bottom__nav__num {
    font-size: 15px;
    position: relative;
    left: 21px;
    top: -20px;
  }

  .active {
    z-index: 1;
    display: flex;
    visibility: visible;
  }

  // .deactive {
  //     visibility: hidden;
  // }

  .active_line {
    visibility: visible;
  }

  .active .carousel-bottom-item__subtitle,
  .active .carousel-bottom-item__title,
  .active .carousel-bottom-item__info,
  .active .carousel-bottom-item__btn,
  .active .carousel-bottom-item__description2,
  .active .carousel-bottom-item__selected_num,
  .active {
    // transform: translateY(0);
    opacity: 1;
    visibility: visible;
    transition: 0.4s all ease-in-out;
  }

  .active .carousel-bottom-item__line {
    // transform: translateY(0);
    opacity: 1;
    visibility: visible;
    transition: 0.4s all ease-in-out;
  }

  .carousel-bottom-item__info {
    display: flex;
    justify-content: center;
    flex-direction: column;
    display: -webkit-flex;
    -webkit-justify-content: center;
    -webkit-flex-direction: column;
    /* background-color: #fcfcfc; */
    left: 420px;
    top: 240px;
    position: absolute;
    width: 70%;
    // transform: translateY(25%);
    opacity: 0;
    visibility: hidden;
    // transition: .4s all ease-in-out;
    // transition-delay: 0.4s;
    margin-top: 90px;
  }

  .carousel-bottom-item__description {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    line-height: 22px;
  }

  .carousel-bottom-item__selected_num {
    transform: translateY(25%);
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 140px;
    left: 30px;
    transition: 1s all ease-in-out;
    // transition-delay: 0.4s;
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    color: red;
  }

  .carousel-bottom-item__line {
    width: 100%;
    position: absolute;
    top: 220px;
    left: 420px;
    visibility: hidden;
    margin-top: 7px;
    font-family: "Open Sans", sans-serif;
    font-size: 25px;
    font-weight: 600;
    // transform: translateY(25%);
    opacity: 0;
    // transition: .4s all ease-in-out;
    // transition-delay: 0.4s;
  }

  .carousel-bottom-item__linetwo {
    width: 100%;
    position: absolute;
    top: 252px;
    left: 421px;
    // visibility: hidden;
    margin-top: 7px;
    font-family: "Open Sans", sans-serif;
    font-size: 20px;
    font-weight: 600;
    // transform: translateY(25%);
    // opacity: 0;
    // transition: .4s all ease-in-out;
    // transition-delay: 0.4s;
  }

  .carousel-bottom-item__linethree {
    width: 100%;
    position: absolute;
    top: 252px;
    left: 421px;
    // visibility: hidden;
    margin-top: 7px;
    font-family: "Open Sans", sans-serif;
    font-size: 20px;
    font-weight: 600;
    // transform: translateY(25%);
    // opacity: 0;
    // transition: .4s all ease-in-out;
    // transition-delay: 0.4s;
  }
}

@media screen and (max-width: 1024px) {
  .carousel_bisckets {
    width: 100%;
    position: relative;
  }

  .carousel_bisckets-item {
    /* margin-top: 100px */
  }

  .carousel_bisckets__nav__title {
    width: 80px;
  }

  .carousel_bisckets__nav__title1 {
    font-size: 13px;
    width: 40px;
    height: 40px;
    // background: #1167f2;
    /* transition: 0.6s ease-in-out;
        transition-delay: 0.2s; */
    position: absolute;
    top: 14px;
    left: calc(50% - 83px);
    color: #242424;
    z-index: 2;

    &::before {
      content: "";
      width: 6px;
      height: 6px;
      border: 0px;
      border-top: solid 2px #242424;
      border-right: solid 2px #242424;
      -ms-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      transform: rotate(-135deg);
      position: absolute;
      top: 54%;
      left: 0;
      margin-top: -4px;
    }
  }

  .carousel_bisckets__nav__title2 {
    font-size: 13px;
    width: 40px;
    height: 40px;
    // background: #1167f2;
    /* transition: 0.6s ease-in-out; */
    /* transition-delay: 0.2s; */
    position: absolute;
    top: 15px;
    left: calc(50% - -25px);
    color: #242424;
    z-index: 2;

    &::before {
      content: "";
      width: 6px;
      height: 6px;
      border: 0px;
      border-top: solid 2px #242424;
      border-right: solid 2px #242424;
      -ms-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      position: absolute;
      top: 54%;
      left: 35px;
      margin-top: -4px;
    }
  }

  .carousel_bisckets__nav__title__selected {
    font-size: 18px;
    text-align: center;
    position: absolute;
    width: calc(100% - 79px);
    margin: 24px 30px 0 30px;
    font-weight: 600;
  }

  .active {
    z-index: 1;
    display: flex;
    visibility: visible;
  }

  // .deactive {
  //     visibility: hidden;
  // }

  .active_line {
    visibility: visible;
  }

  .active .carousel_bisckets-item,
  .active .carousel_bisckets-item__info {
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
  }

  .carousel_bisckets-item__info {
    transform: translateX(10px);
    opacity: 0;
    visibility: hidden;
    transition: 0.7s all ease-in-out;
    transition-delay: 0.4s;
    width: 90%;
    position: ABSOLUTE;
    // width: calc(45% - 60px);
    margin: 73px 30px 0 30px;
    // background: #fcfcfc;
    // -webkit-box-shadow: 11px 6px 12px 0px rgba(224, 217, 224, 1);
    // -moz-box-shadow: 11px 6px 12px 0px rgba(224, 217, 224, 1);
    // box-shadow: 11px 6px 12px 0px rgba(224, 217, 224, 1);
    line-height: 1.4;
  }
}

/* @media only screen and (min-width : 768px) and (max-width : 1024px) {



    .header_left {
        top: 5%;
    }




    .mission_title {
        left: -25%;
    }

    .about .s-mission .inner .mission__thumb {
        position: absolute;
        display: block;
        top: 145px;
        left: 25px;
        width: 45.49%;
        height: auto;
        overflow: hidden;
    }
} */

@media screen and (max-width: 749px) {
  .csr_subtitle {
    font-size: 9px;
  }
}

@media screen and (min-width: 750px) {
  .csr_subtitle {
    font-size: 13px;
  }
}

.line-h {
  position: absolute;
  width: 200%;
  height: 1px;
  background-color: rgba(225, 225, 225, 0.5);
  z-index: -10;

  @include desktop {
    margin: 0 -160px;
  }
}

.desktop-only {
  display: none;

  @include desktop {
    display: inherit;
  }
}

@include desktop {

  /* Global engineering page */
  #page_title_sises {
    position: absolute;
    top: 6.3%;
    right: 56.5%;
    font-size: 70px;
    color: white;
    z-index: 3;
    font-weight: bold;
  }

  .sises_header_description {
    position: absolute;
    left: 10%;
    top: 319px;
    width: 43vw;
    /* height: 308px; */
    padding: 82px 84px 50px 83px;
    background: #1167f2;
    z-index: 2;
    color: #fff;
    -webkit-box-shadow: -2px 10px 4px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: -2px 10px 4px 0px rgba(0, 0, 0, 0.1);
    box-shadow: -2px 10px 4px 0px rgba(0, 0, 0, 0.1);
  }

  .sises_text {
    font-size: 15px;
    line-height: 1.6;
    letter-spacing: 0.03em;
    max-width: 457px;
    max-height: 176px;
  }

  .sises_header {
    position: relative;
    display: block;
    /* padding: 100px 0 154px; */
    z-index: 1;
    /* background-image: url(../images/pc/sises/ge_spiral.png); */
  }

  .sises_header__background {
    background: linear-gradient(rgba(59, 184, 255, 1), rgba(11, 76, 181, 1)),
      url(../images/pc/sises/ge_main.jpg), url(../images/pc/sises/ge_spiral.png);
    background-repeat: round;
    background-blend-mode: multiply;
    height: 900px;
    width: 51.6%;
  }

  .sises_line_background {
    position: relative;
    background: linear-gradient(#3bb8ff, #0b4cb5);
  }

  .sises_horizontal_line1 {
    position: absolute;
    height: 0.5px;
    width: 527px;
    top: -792px;
    left: 216px;
    background-color: #e1e1e1;
    mix-blend-mode: overlay;
    opacity: 0.5;
  }

  .sises_horizontal_line1::before {
    content: "01 clouds";
    font-size: 9px;
  }

  .sises_horizontal_line2 {
    position: absolute;
    height: 0.5px;
    width: 216px;
    top: -715px;
    left: 0;
    background-color: #ffffff;
  }

  .sises_horizontal_line3 {
    position: absolute;
    height: 0.5px;
    width: 743px;
    top: -266px;
    left: 0;
    background-color: #e1e1e1;
    mix-blend-mode: overlay;
    opacity: 0.5;
  }

  .sises_vertical_line1 {
    position: absolute;
    height: 0.5px;
    width: 108px;
    top: -845px;
    left: 470px;
    transform: rotate(90deg);
    background-color: #e1e1e1;
    mix-blend-mode: overlay;
    opacity: 0.5;
  }

  .sises_vertical_line2 {
    position: absolute;
    height: 900px;
    width: 0.5px;
    top: -900px;
    left: 215px;
    background-color: #e1e1e1;
    mix-blend-mode: overlay;
    opacity: 0.5;
  }

  .circle_img {
    position: relative;
  }

  // .circles {
  //     position: absolute;
  //     top: -358px;
  //     left: 60px;
  // }

  /* Members page */
  .members_header_description {
    position: absolute;
    left: 10%;
    top: 299px;
    width: 43vw;
    height: 342px;
    padding: 50px 32px 50px 65px;
    background: $red;
    z-index: 2;
    color: #fff;
    -webkit-box-shadow: -2px 10px 4px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: -2px 10px 4px 0px rgba(0, 0, 0, 0.1);
    box-shadow: -2px 10px 4px 0px rgba(0, 0, 0, 0.1);
  }

  .members_text {
    font-size: 15px;
    line-height: 1.6;
    letter-spacing: 0.03em;
    max-width: 518px;
    /* max-height: 176px; */
  }

  .members_header {
    position: relative;
    display: block;
    /* padding: 100px 0 154px; */
    z-index: 1;
    /* background-image: url(../images/pc/sises/ge_spiral.png); */
  }

  .members_header__background {
    background: linear-gradient(rgba(244, 70, 56, 1), rgba(51, 9, 6, 0.1)),
      url(../images/pc/header/ms_main.jpg), url(../images/pc/header/ms_wavy.png);
    background-repeat: round;
    background-blend-mode: multiply;
    height: 900px;
    width: 60%;
  }
}

.grecaptcha-badge {
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  left: 0px !important;

  @media screen and (max-width: 991px) {
    transform: scale(0.6);
    left: -14px !important;
    pointer-events: none;
  }

  &:hover {
    width: 256px !important;
  }
}

// .l-header__gnav,
// .l-header__gnav .gnav__bg {
//     @include tablet {
//         -webkit-transition-timing-function: cubic-bezier(.19, 1, .22, 1);
//         right: 0;
//         left: 0;
//         bottom: 0

//     }
//     @include desktop {
//         -webkit-transition-timing-function: cubic-bezier(.19, 1, .22, 1);
//         right: 0;
//         left: 0;
//         bottom: 0
//     }
//     @include l-desktop {
//         -webkit-transition-timing-function: cubic-bezier(.19, 1, .22, 1);
//         right: 0;
//         left: 0;
//         bottom: 0
//     }
// }

// .l-header__gnav {
//     @include tablet {
//         position: fixed;
//         top: 0;
//         pointer-events: none;
//         width: 100%;
//         height: 100%;
//         display: -webkit-box;
//         display: -ms-flexbox;
//         display: flex;
//         -webkit-box-pack: center;
//         -ms-flex-pack: center;
//         justify-content: center;
//         -webkit-box-align: center;
//         -ms-flex-align: center;
//         align-items: center;
//         z-index: 999;
//         transition-timing-function: cubic-bezier(.19, 1, .22, 1);
//         -webkit-transition-duration: 1s;
//         transition-duration: 1s;
//         -webkit-transition-property: background-color;
//         transition-property: background-color;
//         will-change: background-color;
//         -webkit-backface-visibility: hidden;
//         backface-visibility: hidden;
//         -webkit-font-smoothing: antialiased
//     }
//     @include desktop {
//         position: fixed;
//         top: 0;
//         pointer-events: none;
//         width: 100%;
//         height: 100%;
//         display: -webkit-box;
//         display: -ms-flexbox;
//         display: flex;
//         -webkit-box-pack: center;
//         -ms-flex-pack: center;
//         justify-content: center;
//         -webkit-box-align: center;
//         -ms-flex-align: center;
//         align-items: center;
//         z-index: 999;
//         transition-timing-function: cubic-bezier(.19, 1, .22, 1);
//         -webkit-transition-duration: 1s;
//         transition-duration: 1s;
//         -webkit-transition-property: background-color;
//         transition-property: background-color;
//         will-change: background-color;
//         -webkit-backface-visibility: hidden;
//         backface-visibility: hidden;
//         -webkit-font-smoothing: antialiased
//     }
//     @include l-desktop {
//         position: fixed;
//         top: 0;
//         pointer-events: none;
//         width: 100%;
//         height: 100%;
//         display: -webkit-box;
//         display: -ms-flexbox;
//         display: flex;
//         -webkit-box-pack: center;
//         -ms-flex-pack: center;
//         justify-content: center;
//         -webkit-box-align: center;
//         -ms-flex-align: center;
//         align-items: center;
//         z-index: 999;
//         transition-timing-function: cubic-bezier(.19, 1, .22, 1);
//         -webkit-transition-duration: 1s;
//         transition-duration: 1s;
//         -webkit-transition-property: background-color;
//         transition-property: background-color;
//         will-change: background-color;
//         -webkit-backface-visibility: hidden;
//         backface-visibility: hidden;
//         -webkit-font-smoothing: antialiased

//     }

// }

// .l-header__gnav .gnav__bg {
//     @include tablet {
//         // pointer-events: none;
//         // position: fixed;
//         // top: 60px;
//         // width: calc(100% - 40px);
//         // height: calc(100% - 60px);
//         // margin: auto;
//         // z-index: -1;
//         // background-color: #151515;
//         // opacity: 0;
//         // -webkit-transform: scale(.8);
//         // transform: scale(.8);
//         // transition-timing-function: cubic-bezier(.19, 1, .22, 1);
//         // -webkit-transition-duration: 1s;
//         // transition-duration: 1s;
//         // -webkit-transition-property: background-color;
//         // transition-property: background-color;
//         // -webkit-backface-visibility: hidden;
//         // backface-visibility: hidden;
//         // -webkit-font-smoothing: antialiased
//     }
//     // @include desktop {
//     //     pointer-events: none;
//     //     position: fixed;
//     //     top: 60px;
//     //     width: calc(100% - 40px);
//     //     height: calc(100% - 60px);
//     //     margin: auto;
//     //     z-index: -1;
//     //     background-color: #151515;
//     //     opacity: 0;
//     //     -webkit-transform: scale(.8);
//     //     transform: scale(.8);
//     //     transition-timing-function: cubic-bezier(.19, 1, .22, 1);
//     //     -webkit-transition-duration: 1s;
//     //     transition-duration: 1s;
//     //     -webkit-transition-property: background-color;
//     //     transition-property: background-color;
//     //     -webkit-backface-visibility: hidden;
//     //     backface-visibility: hidden;
//     //     -webkit-font-smoothing: antialiased
//     // }
//     // @include l-desktop {
//     // pointer-events: none;
//     // position: fixed;
//     // top: 60px;
//     // width: calc(100% - 40px);
//     // height: calc(100% - 60px);
//     // margin: auto;
//     // z-index: -1;
//     // background-color: #151515;
//     // opacity: 0;
//     // -webkit-transform: scale(.8);
//     // transform: scale(.8);
//     // transition-timing-function: cubic-bezier(.19, 1, .22, 1);
//     // -webkit-transition-duration: 1s;
//     // transition-duration: 1s;
//     // -webkit-transition-property: background-color;
//     // transition-property: background-color;
//     // -webkit-backface-visibility: hidden;
//     // backface-visibility: hidden;
//     // -webkit-font-smoothing: antialiased
//     // }
// }

// .l-header__gnav .header__gnav {
//     @include tablet {
//         position: absolute;
//         top: 24px;
//         right: 0;
//         bottom: 0;
//         left: 0;
//         margin: auto;
//         width: 180px;
//         height: 235px;
//         display: -webkit-box;
//         display: -ms-flexbox;
//         display: flex;
//         -webkit-box-orient: vertical;
//         -webkit-box-direction: normal;
//         -ms-flex-direction: column;
//         flex-direction: column;
//         -webkit-box-pack: justify;
//         -ms-flex-pack: justify;
//         justify-content: space-between
//     }
//     @include desktop {
//         position: absolute;
//         top: 24px;
//         right: 0;
//         bottom: 0;
//         left: 0;
//         margin: auto;
//         width: 180px;
//         height: 235px;
//         display: -webkit-box;
//         display: -ms-flexbox;
//         display: flex;
//         -webkit-box-orient: vertical;
//         -webkit-box-direction: normal;
//         -ms-flex-direction: column;
//         flex-direction: column;
//         -webkit-box-pack: justify;
//         -ms-flex-pack: justify;
//         justify-content: space-between
//     }
//     @include l-desktop {
//         position: absolute;
//         top: 24px;
//         right: 0;
//         bottom: 0;
//         left: 0;
//         margin: auto;
//         width: 180px;
//         height: 235px;
//         display: -webkit-box;
//         display: -ms-flexbox;
//         display: flex;
//         -webkit-box-orient: vertical;
//         -webkit-box-direction: normal;
//         -ms-flex-direction: column;
//         flex-direction: column;
//         -webkit-box-pack: justify;
//         -ms-flex-pack: justify;
//         justify-content: space-between
//     }

// }