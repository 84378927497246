.banner_container {
    max-width: 254px;
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 300;
    bottom: 8px;
    margin-left: 4px;
    @media only screen and (max-width: 1440px) {
      margin-left: 1.66vw;
      max-width: 170px;
    }
  .present_freewill {
    max-width: 100px;
    align-self: center;
    position: relative;
    margin-bottom: 12px;

  }
  .moff_freewill {
    margin-bottom: 10px;
    .image {
      max-width: 100%;
    }

  }
  .trunk_hotel {
    max-width: 228px;
    align-self: center;
  }
  .event_button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60px;
    border-radius: 30px;
    background-color: #205FED;
    margin-top: 15px;
    @media only screen and (max-width: 1440px) {
      height: 50px;
    }
    &--text {
      color: #FFFFFF;
      font-weight: bold;
    }

  }
  @media screen and (min-width: 1025px) {
    .u-sp-only_moff2022{
      display: none !important;
    }
  }

  @media screen and (max-width: 1024px) {
    .u-pc-only_moff2022 {
      display: none !important;
    }
  }
}
.moffbanner {
  position: fixed;
  padding-left: 10px;
  padding-right: 10px;
  z-index: 300;
  bottom: 7px;
  @media screen and (max-width: 1024px) {
    max-width: 400px;
    transform: translate(calc(50vw - 50%));
  }
  .moff__close {
    position: absolute;
    top: -36px;
    right: 11px;
    background-color: #7e7e7e;
    width: 32px;
    height: 32px;
    padding: 8px;
    border-radius: 100%;
    opacity: .3;
    .img {
      transform: translate(1px, -1px);
    }
  }
  .moff__close:before, .moff__close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 12px;
    width: 2px;
    top: 10px;
    background-color:#FFFFFF;

  }
  .moff__close:before {
    transform: rotate(45deg);
  }
  .moff__close:after {
    transform: rotate(-45deg);
  }
}
.moff--hidden {
  display: none;
}
