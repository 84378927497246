$sakura-card-scale: var(--sakura-card-scale, 0.8);
$sakura-card-rectangle-offset: 65px;
$sakura-card-rectangle-height: 700px;

.sakura-card-absolute-default {
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: center;
}

.sakura-card {
  display: flex;
  justify-content: center;

  // @media screen and (max-width: 891px) {
  //   --sakura-card-scale: 0.7;
  // }

  @media screen and (max-width: 581px) {
    --sakura-card-scale: 0.6;
  }

  @media screen and (max-width: 445px) {
    --sakura-card-scale: 0.45;
  }

  position: relative;
  width: 100vw;
  height: calc(1350px * #{$sakura-card-scale});
  transform: translate(
    calc(-11px * #{$sakura-card-scale}),
    calc(-150px * #{$sakura-card-scale})
  );

  // @media screen and (min-width: 375px) {
  //   top: -3px;
  //   height: calc(1429px * #{$sakura-card-scale});
  //   transform: translate(
  //     calc(-11px * #{$sakura-card-scale}),
  //     calc(-150px * #{$sakura-card-scale})
  //   );
  // }

  &__feather-1 {
    &__wrapper {
      @extend .sakura-card-absolute-default;

      top: calc(905px * #{$sakura-card-scale});
    }

    &__image {
      height: calc(686px * #{$sakura-card-scale});
      z-index: 2;
      transform: translate(
          calc(-66px * #{$sakura-card-scale}),
          calc(-552px * #{$sakura-card-scale})
        )
        rotate(359deg);
    }
  }

  &__feather-2 {
    &__wrapper {
      @extend .sakura-card-absolute-default;

      top: calc(132px * #{$sakura-card-scale});
    }

    &__image {
      height: calc(801px * #{$sakura-card-scale});
      transform: translate(
          calc(2px * #{$sakura-card-scale}),
          calc(-132px * #{$sakura-card-scale})
        )
        rotate(1deg);
    }
  }

  &__feather-3 {
    &__wrapper {
      @extend .sakura-card-absolute-default;

      top: calc(317px * #{$sakura-card-scale});
    }

    &__image {
      height: calc(737px * #{$sakura-card-scale});
      z-index: 2;
      transform: translate(
          calc(-67px * #{$sakura-card-scale}),
          calc(-10px * #{$sakura-card-scale})
        )
        rotate(2deg);
    }
  }

  &__seagull {
    &__wrapper {
      @extend .sakura-card-absolute-default;

      top: calc(1px * #{$sakura-card-scale});
    }

    &__image {
      height: calc(806px * #{$sakura-card-scale});
      transform: translateX(calc(0px * #{$sakura-card-scale}));
    }
  }

  &__snowman {
    &__wrapper {
      @extend .sakura-card-absolute-default;

      top: calc(600px * #{$sakura-card-scale});

      @media screen and (max-width: calc(1140px * #{$sakura-card-scale})) {
        justify-content: left;
        height: auto;
      }
    }

    &__image {
      height: calc(166px * #{$sakura-card-scale});
      transform: translateX(calc(-346px * #{$sakura-card-scale}));

      @media screen and (max-width: calc(1140px * #{$sakura-card-scale})) {
        transform: translateX(calc(50px * #{$sakura-card-scale}));
      }
    }
  }

  &__merry-sakura {
    &__wrapper {
      @extend .sakura-card-absolute-default;

      top: calc(672px * #{$sakura-card-scale});
    }

    &__image {
      position: absolute;
      z-index: 5;
      height: calc(657px * #{$sakura-card-scale});
      transform: translate(
        calc(-58px * #{$sakura-card-scale}),
        calc(-304px * #{$sakura-card-scale})
      );
    }
  }
  &__merry-sakura {
    &__wrapper {
      @extend .sakura-card-absolute-default;
      top: calc(672px * #{$sakura-card-scale});
    }

    &__handwritten {
      position: absolute;
      height: calc(492px * #{$sakura-card-scale});
      z-index: -2;
      transform: translate(
          calc(19px * #{$sakura-card-scale}),
          calc(80px * #{$sakura-card-scale})
        )
        rotate(358deg);
    }
  }

  &__freewill-logo {
    &__wrapper {
      @extend .sakura-card-absolute-default;

      top: calc(830px * #{$sakura-card-scale});
    }

    &__image {
      height: calc(45px * #{$sakura-card-scale});
      transform: translate(
        calc(15px * #{$sakura-card-scale}),
        calc(320px * #{$sakura-card-scale})
      );
    }
  }

  &__snowflakes {
    &__wrapper {
      @extend .sakura-card-absolute-default;

      top: calc(0px * #{$sakura-card-scale});
    }

    &__image-1 {
      position: absolute;
      height: calc(600px * #{$sakura-card-scale});
      transform: translateY(calc(-160px * #{$sakura-card-scale}));
    }

    &__image-2 {
      position: absolute;
      height: calc(600px * #{$sakura-card-scale});
      transform: translateY(calc(-160px * #{$sakura-card-scale}));
    }

    &__image-3 {
      position: absolute;
      height: calc(600px * #{$sakura-card-scale});
      transform: translate(
        calc(-40px * #{$sakura-card-scale}),
        calc(-40px * #{$sakura-card-scale})
      );
    }

    &__image-4 {
      position: absolute;
      height: calc(600px * #{$sakura-card-scale});
      transform: translate(
          calc(-320px * #{$sakura-card-scale}),
          calc(60px * #{$sakura-card-scale})
        )
        scale(-1, -1);
    }

    &__image-5 {
      position: absolute;
      height: calc(600px * #{$sakura-card-scale});
      transform: translate(
        calc(-220px * #{$sakura-card-scale}),
        calc(60px * #{$sakura-card-scale})
      );
    }

    &__image-6 {
      position: absolute;
      height: calc(480px * #{$sakura-card-scale});
      transform: translate(
        calc(300px * #{$sakura-card-scale}),
        calc(220px * #{$sakura-card-scale})
      );
    }
  }

  .static-background {
    display: flex;
    justify-content: center;
    align-items: center;

    .rectangles {
      height: calc(80px * #{$sakura-card-scale});
      transform: translate(
        cal(300px * #{$sakura-card-scale}),
        cal(220px * #{$sakura-card-scale})
      );
    }
  }
  .v-line {
    position: absolute;
    background-color: #f4d5f3;
    width: 1px;
    z-index: -3;
    height: calc(1146px * var(--sakura-card-scale, 0.8));
    transform: translate(
        calc(11.5px * #{$sakura-card-scale}),
        calc(-62px * #{$sakura-card-scale})
      )
      rotate(0deg);
    @media screen and (max-width: 766px) {
      height: calc(1000px * var(--sakura-card-scale, 0.8));
      transform: translate(
        calc(11px * #{$sakura-card-scale}),
        calc(150px * #{$sakura-card-scale})
      );
    }
  }
}
