.sustainableecosociety {
  .link {
    fill: #333333;
    &:hover {
      fill: $red;
    }
    .filter {
      filter: invert(3%) sepia(0%) saturate(2605%) hue-rotate(134deg)
        brightness(0%) contrast(64%);
    }
  }
  .page_header {
    padding-top: 60px;
    @include desktop {
      padding: unset;
    }

    .ses_video_container {
      position: relative;
      background: #ffffff;
      .video {
        width: 100%;
        height: 100vh;
        object-fit: cover;
        @media only screen and (max-width: 1024px) {
          max-height: 750px;
          height: unset;
        }
      }

      .muted {
        position: absolute;
        right: 40px;
        bottom: 50px;
        width: 48px;
        height: 48px;
        display: grid;
        place-items: center;
        border-radius: 10px;
        background-color: #0c0c0c;
        opacity: 0.75;
        @media only screen and (max-width: 1024px) {
          right: 1.9vw;
          bottom: 8px;
          transform: scale(0.75);
        }
        .mute_btn {
          cursor: pointer;
          width: 24px;
          height: 24px;
          // @media only screen and (max-width: 1024px) {
          //   width: 60px;
          //   height: 60px;
          // }
        }
      }
    }

    &__title {
      font-size: 32px;
      font-weight: 500;
      line-height: 1.3;
      letter-spacing: -2.21px;
      margin-top: 80px;
      @include desktop {
        font-size: 48px;
        line-height: 58px;
      }
    }

    &__sub_title {
      font-size: 18px;
      font-weight: bold;
      line-height: 31px;
      letter-spacing: 0px;
      margin-top: 18px;
      @include desktop {
        font-size: 26px;
      }
    }

    .p-inner {
      max-width: 812px;
      padding: 0 20px;
      @include desktop {
        padding: unset;
      }
    }
  }

  .page_content {
    max-width: 812px;
    margin-top: 80px;
    margin-bottom: 150px;
    padding: 0 20px;
    @include desktop {
      padding: unset;
    }

    &__title {
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0px;
      line-height: 1.3;
      margin-top: 60px;
      margin-bottom: 32px;
      @include desktop {
        font-size: 25px;
        margin-bottom: 32px;
      }
    }

    &__text {
      font-size: 14px;
      line-height: 28px;
      letter-spacing: 0px;
      margin-bottom: 28px;
      @include desktop {
        font-size: 17px;
      }
    }

    &__img {
      margin: 60px 0;
    }

    .ses {
      position: relative;
      padding-left: 50px;
      margin-top: 80px;
      margin-bottom: 80px;
      @include desktop {
        padding-left: 150px;
      }

      .ses-one {
        &::before {
          content: "1";
          position: absolute;
          top: -60px;
          left: -15px;
          font-size: 200px;
          font-weight: bold;
          color: #f8f8f8;
          z-index: -1;
          @include desktop {
            left: 55px;
          }
        }
      }
      .ses-two {
        &::before {
          content: "2";
          position: absolute;
          top: -60px;
          left: -15px;
          font-size: 200px;
          font-weight: bold;
          color: #f8f8f8;
          z-index: -1;
          @include desktop {
            left: 55px;
          }
        }
      }
      .ses-three {
        &::before {
          content: "3";
          position: absolute;
          top: -60px;
          left: -15px;
          font-size: 200px;
          font-weight: bold;
          color: #f8f8f8;
          z-index: -1;
          @include desktop {
            left: 55px;
          }
        }
      }
    }
  }
}
