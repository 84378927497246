$moff-white: #fefefe;

.moff {
  @import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:300,400,700&display=swap&subset=japanese");
  font-family: "Noto Sans JP", sans-serif;

  &-btn {
    position: fixed;
    top: 35%;
    right: 76px;
    z-index: 1;
    &__text {
      line-height: 18px;
      text-align: center;
      margin-bottom: 5px;
    }
    &__buy {
      background-color: #f21515;
      color: white;
      border-radius: 4px;
      cursor: pointer;
      padding: 30px 12px;
      writing-mode: vertical-rl;
      text-orientation: upright;
      font-size: 15px;
      font-weight: 400;
    }
  }
  &_btnsp {
    position: fixed;
    top: 100px;
    right: 32px;
    z-index: 1;

    &__buy {
      background-color: #f21515;
      color: white;
      padding: 15px 20px;
      border-radius: 4px;
      border-color: #46b8da;
      cursor: pointer;

      .a_textsp {
        color: #fff;
      }
    }
  }
  &__main {
    margin-top: 60px;
    background-color: #171314;
    color: $moff-white;
    font-size: 12px;
    font-weight: 300;
    line-height: 3;
    @include desktop {
      margin-top: 0;
    }
  }
  &__section {
    padding: 0 32px;
    text-align: center;
    @include desktop {
      max-width: 896px;
      margin: 0 auto 64px;
    }
    p {
      margin-bottom: 2rem;
      font-size: 14px;
      line-height: 2;
      @include desktop {
        font-size: 1.125rem;
      }
    }
    .video__wrapper {
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      height: 0;
      margin-bottom: 30vh;
    }
    .video__content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  &__main-imgbox {
    position: relative;
    display: flex;
    justify-content: center;
    height: 100vh;
    overflow: hidden;
    @include desktop {
      height: initial;
    }
  }
  &__main-img {
    object-fit: cover;
    &--pc {
      display: none;
      @include desktop {
        display: block;
      }
    }
    &--sp {
      position: absolute;
      bottom: -56px;
      display: block;
      @include desktop {
        display: none;
      }
    }
  }
  &__main-img-text {
    position: relative;
    top: -15px;
    @include desktop {
      top: -30px;
    }
  }
  &__main-video-text {
    position: relative;
    margin-top: 50px;
    margin-bottom: 30px;
    @include desktop {
      margin-top: 100px;
      margin-bottom: 73px;
    }
  }
  &__heading {
    position: relative;
    margin-top: 80px;
    margin-bottom: 40px;
    &:before {
      position: absolute;
      top: 11px;
      width: 22%;
      left: 16%;
      height: 0.5px;
      background-color: #707070;
      content: "";
    }
    &:after {
      position: absolute;
      top: 11px;
      width: 22%;
      right: 16%;
      height: 0.5px;
      background-color: #707070;
      content: "";
    }
  }
  &__text-large {
    font-size: 1.125rem;
    line-height: 1.5;
    margin-bottom: 0.5rem;
    @include desktop {
      font-size: 40px;
    }
  }
  &__text-semilarge {
    font-size: 15px;
    line-height: 1.5;
    @include desktop {
      font-size: 28px;
    }
  }
  &__text-subtopic {
    font-size: 1.125rem;
    font-weight: bold;
    line-height: 1.5;
    margin-bottom: 0.5rem;
  }
  &__text-bold {
    font-weight: bold;
  }
  &__text-center {
    text-align: center;
  }
  &__bullet-list {
    font-size: 1.125rem;
    list-style-type: disc;
    list-style-position: inside;
    li {
      list-style: inherit;
    }
  }
  &__service-list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 50px 0;
    @include desktop {
      justify-content: space-between;
      flex-direction: row;
    }
  }
  &__service-item {
    @include desktop {
      padding: 1rem;
    }
    &:not(:last-child) {
      margin-bottom: 60px;
      @include desktop {
        margin-bottom: initial;
      }
    }
  }
  &__service-image {
    display: block;
    width: 100px;
    height: auto;
    @include desktop {
      width: 142px;
    }
  }
}

.moff2019 {
  @import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:300,400,700&display=swap&subset=japanese");
  font-family: "Noto Sans JP", sans-serif;

  &-btn {
    position: fixed;
    top: 35%;
    right: 76px;
    z-index: 1;
    &__text {
      line-height: 18px;
      text-align: center;
      margin-bottom: 5px;
    }
    &__buy {
      background-color: #f21515;
      color: white;
      border-radius: 4px;
      cursor: pointer;
      padding: 30px 12px;
      writing-mode: vertical-rl;
      text-orientation: upright;
      font-size: 15px;
      font-weight: 400;
    }
  }
  &_btnsp {
    position: fixed;
    top: 100px;
    right: 32px;
    z-index: 1;

    &__buy {
      background-color: #f21515;
      color: white;
      padding: 15px 20px;
      border-radius: 4px;
      border-color: #46b8da;
      cursor: pointer;

      .a_textsp {
        color: #fff;
      }
    }
  }
  &__main {
    margin-top: 60px;
    background-color: #171314;
    color: $moff-white;
    font-size: 12px;
    font-weight: 300;
    line-height: 3;
    @include desktop {
      margin-top: 0;
    }
  }
  &__section {
    padding: 0 32px;
    @include desktop {
      max-width: 896px;
      margin: 0 auto 64px;
    }
    .video__wrapper {
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      height: 0;
    }
    .video__content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    p {
      margin-bottom: 1rem;
      font-size: 15px;
      line-height: 2;
      @include desktop {
        font-size: 1.125rem;
      }
    }
  }
  &__main-imgbox {
    position: relative;
    display: flex;
    height: 100vh;
    overflow: hidden;
    @include desktop {
      height: initial;
    }
  }
  &__main-img {
    object-fit: cover;
    &--pc {
      display: none;
      @include desktop {
        display: block;
      }
    }
    &--sp {
      position: absolute;
      bottom: -56px;
      display: block;
      @include desktop {
        display: none;
      }
    }
  }
  &__main-img-text {
    position: relative;
    top: -96px;
  }
  &__heading {
    position: relative;
    margin-top: 80px;
    margin-bottom: 56px;
    font-size: 25px;
    font-weight: 600;
    text-align: center;
    @include desktop {
      text-align: left;
    }
    &:before {
      position: absolute;
      bottom: 12px;
      margin-left: auto;
      margin-right: auto;
      display: block;
      width: 100%;
      opacity: 0.2;
      font-size: 50px;
      color: $moff-white;
      content: attr(data-number);
      @include desktop {
        right: 44px;
        bottom: -10px;
      }
    }
  }
  &__text-large {
    font-size: 1.125rem;
    line-height: 1.5;
    margin-bottom: 0.5rem;
    @include desktop {
      font-size: 40px;
    }
  }
  &__text-semilarge {
    font-size: 15px;
    line-height: 1.5;
    @include desktop {
      font-size: 28px;
    }
  }
  &__text-subtopic {
    font-size: 1.125rem;
    font-weight: bold;
    line-height: 1.5;
    margin-bottom: 0.5rem;
  }
  &__text-bold {
    font-weight: bold;
  }
  &__text-center {
    text-align: center;
  }
  &__bullet-list {
    font-size: 1.125rem;
    list-style-type: disc;
    list-style-position: inside;
    li {
      list-style: inherit;
    }
  }
  &__service-list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 50px 0;
    @include desktop {
      justify-content: space-between;
      flex-direction: row;
    }
  }
  &__service-item {
    @include desktop {
      padding: 1rem;
    }
    &:not(:last-child) {
      margin-bottom: 60px;
      @include desktop {
        margin-bottom: initial;
      }
    }
  }
  &__service-image {
    display: block;
    width: 100px;
    height: auto;
    @include desktop {
      width: 142px;
    }
  }
  &__btn {
    margin: 40px auto;
    width: 250px;
    @media only screen and (max-width: 768px) {
      width: 80% !important;
      margin-bottom: 100px !important;
    }
    .text {
      font-size: 14px !important;
    }
  }
}
