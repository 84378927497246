@include desktop {
  .csr_header {
    &__background {
      // background: url(../images/pc/csr/csr_lines.png);
      background-size: 100%;
      background-repeat: no-repeat;
      background-blend-mode: hard-light;
      height: 1040px;
      width: 100%;
      position: relative;

      &--image {
        @include desktop {
          position: absolute;
          background: url(../images/pc/csr/csr-top-image.png);
          background-size: cover;
          width: 100%;
          height: 1040px;
          z-index: -1;
        }
      }

      .csr_header__box {
        width: 100%;

        .csr_header__title {
          font-size: 80px;
          font-weight: bold;
          color: #fff;
          position: absolute;
          top: 408px;
          left: 704px;
          transform: translateY(-50%) translateX(-50%);

          &::after {
            content: "FOR OUR SOCIETY";
            width: 160px;
            /* height: 54px; */
            color: #fff;
            font-size: 9px;
            position: absolute;
            top: -28px;
            left: 11px;
            font-weight: 100;
          }
        }

        .csr_header__sub {
          font-size: 30px;
          color: #fff;
          position: absolute;
          top: 478px;
          left: 1133px;
          width: 100%;
          transform: translateY(-50%) translateX(-50%);
        }

        .csr_header__content {
          position: absolute;
          top: 655px;
          left: 860px;
          transform: translateY(-50%) translateX(-50%);
          width: 474px;
          overflow: hidden;

          .csr_text {
            position: relative;
            color: #fff;
            font-size: 15px;
            line-height: 1.6;
            letter-spacing: 0.03em;
            font-family: "Noto Sans JP", sans-serif;
          }
        }
      }

      .csr_activity__border {
        width: calc(50% - -390px);
        height: 1px;
        background: transparent;
        border-top: 0.5px solid #e1e1e1;
        position: absolute;
        top: 900px;
        left: calc(50% - 390px);
        z-index: 11;

        &::before {
          content: "SOME OF OUR ACTIVITIES";
          position: absolute;
          top: -4px;
          left: -125px;
          color: #e1e1e1;
          font-size: 9px;
        }
      }

      .csr_header__whiteBox {
        width: calc(100% - 262px);
        height: 1200px;
        background-color: #fff;
        position: absolute;
        top: 821px;
        left: 0;
        z-index: 10;
        border-bottom: 0.5px solid #ccc;
        border-right: 0.5px solid #ccc;

        .explores_text__content {
          padding-top: 80px;
          padding-left: 200px;

          // .vision_hr1 {
          //     width: 710px;
          //     height: .5px;
          //     background-color: #242424;
          //     position: absolute;
          //     top: 55px;
          //     right: 25px;
          // }

          .explores_text {
            font-size: 15px;
            line-height: 1.6;
            letter-spacing: 0.03em;
            max-width: 773px;
            max-height: 475px;

            &:first-child {
              border-top: 0.5px solid #242424;
              padding-top: 50px;
            }
          }
        }
      }
    }

    .explores_circle_img {
      position: relative;

      &::before {
        content: "";
        width: 52px;
        height: 148px;
        background-color: #242424;
        display: block;
        position: absolute;
        top: -563px;
        left: 196px;
        opacity: 0.5;
        mix-blend-mode: overlay;
      }
    }

    .explores_circles {
      position: absolute;
      top: -609px;
      left: 145px;
    }
  }

  .company__content-small {
    padding: 40px 110px 5px 94px;
  }

  .company__content-large {
    padding: 151px 110px 5px 94px;
  }

  .csr_border1 {
    width: calc(100% - 263px);
    left: 0;
    top: 1444px;
    border-bottom: 0.5px solid #e1e1e1;
    position: absolute;
    height: 10px;
    z-index: 11;
  }

  .csr_border2 {
    width: 100%;
    top: 2011px;
    border-bottom: 0.5px solid #e1e1e1;
    position: absolute;
    height: 10px;
    z-index: 11;
  }
}

@media screen and (max-width: 1024px) {
  .csr_header {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 60px;

    &__background {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      background: url(../images/sp/csr/csr-topmobile-image.png);
      background-repeat: no-repeat;
      background-size: cover;
      height: calc(100vh - 60px);

      .csr_header__title {
        font-size: 60px;
        color: white;
        font-weight: bold;
        overflow: visible;

        &::before {
          content: "";
          width: 1px;
          height: 190px;
          background-color: #fff;
          position: absolute;
          top: -205px;
          right: 30px;

          @media screen and (max-width: 350px) {
            top: -6px;
            height: 100px;
          }
        }

        &::after {
          content: "FOR OUR SOCIETY";
          width: 160px;
          /* height: 54px; */
          color: #fff;
          font-size: 6px;
          position: absolute;
          top: 79px;
          right: -50px;
          font-weight: 100;
          transform: rotate(90deg);

          @media screen and (max-width: 350px) {
            top: 180px;
          }
        }
      }

      .csr_header__sub {
        color: #fff;
        font-size: 20px;
        padding: 20px 10px 10px 10px;
        // position: absolute;
        // top: 475px;
        // left: 20%;
      }

      .csr_header__content {
        color: #fff;
        font-size: 12px;
        line-height: 1.5;
        // position: absolute;
        // left: 17%;
        // top: 540px;
        max-width: 500px;
        // width: 70%;
        padding: 20px 10px 68px 10px;
      }

      .explores_header__whiteBox {
        .explores_text__content {
          top: 845px;
          height: 307px;
          padding: 20px;
          text-align: center;

          .explores_text {
            font-size: 12px;
            line-height: 2.2;
            letter-spacing: 0.03em;
          }
        }
      }

      .explores_header__sub {
        color: #fff;
        font-size: 20px;
        position: absolute;
        top: 550px;
        left: 40%;
      }

      .explores_header__content {
        color: #fff;
        font-size: 12px;
        line-height: 1.5;
        width: 300px;
        position: absolute;
        top: 635px;
        left: 15%;
      }
    }
  }
}

.mobile_br {
  display: none;

  @media only screen and (max-width: 768px) {
    display: block;
  }
}
