@include desktop {
  .vision_header {
    &__background {
      background: url(../images/pc/about/vs_lines.png);
      background-size: cover;
      background-position: center;
      background-blend-mode: hard-light;
      background-color: #000;
      height: 1040px;
      width: 100%;
      position: relative;

      &--image {
        @include desktop {
          position: absolute;
          background: url(../images/pc/about/vs_main.jpg);
          background-size: cover;
          background-position: center;
          width: 100%;
          height: 1040px;
          opacity: 0.8;
          z-index: 0;
        }
      }

      .vision_header__title {
        font-size: 90px;
        font-weight: bold;
        color: #fff;
        position: absolute;
        top: 59%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);

        // &::before {
        //     content: '';
        //     position: absolute;
        //     left: 50%;
        //     top: -290px;
        //     display: block;
        //     width: 1px;
        //     height: 200px;
        //     background-color: #fff;
        // }

        // &::after {
        //     content: 'OUR BELIEF';
        //     font-size: 9px;
        //     font-weight: 100;
        //     position: absolute;
        //     top: -250px;
        //     display: block;
        //     width: 100%;
        //     transform: rotate(90deg);
        // }
      }
    }
    &__whiteBox {
      width: calc(100% - 262px);
      height: auto;
      background-color: #fff;
      position: absolute;
      top: 800px;
      left: 0;
      z-index: 10;
      border-right: 0.5px solid #e1e1e1;
      border-bottom: 0.5px solid #e1e1e1;
      padding: 10px 10px 150px;

      .csr_activities__border {
        width: calc(50% - -241px);
        height: 1px;
        background: transparent;
        border-top: 0.5px solid #e1e1e1;
        position: absolute;
        top: 80px;
        left: calc(50% - 241px);
        z-index: 11;

        &::before {
          content: "OUR BELIEF";
          position: absolute;
          top: -4px;
          left: -67px;
          color: #e1e1e1;
          font-size: 9px;
        }
      }

      .vision_text__content {
        padding-top: 140px;
        padding-left: 110px;

        // .vision_hr1 {
        //     width: 710px;
        //     height: .5px;
        //     background-color: #242424;
        //     position: absolute;
        //     top: 55px;
        //     right: 25px;
        // }

        .vision_text {
          font-size: 15px;
          line-height: 2.5;
          letter-spacing: 0.03em;
          max-width: 773px;
          max-height: 475px;

          // &:first-child {
          //     border-top: .5px solid #242424;
          //     padding-top: 50px;
          // }
        }
      }
    }

    .vision_circle_img {
      position: relative;

      &::before {
        content: "";
        width: 54px;
        height: 54px;
        background-color: #242424;
        display: block;
        position: absolute;
        top: -427px;
        left: 181px;
        opacity: 0.5;
        mix-blend-mode: overlay;
      }
    }

    .vision_circles {
      position: absolute;
      top: -290px;
      right: 207px;
    }
  }

  .company__content-small {
    padding: 40px 110px 5px 94px;
  }

  .company__content-large {
    padding: 151px 110px 5px 94px;
  }

  .mission_sec {
    height: 1700px;
    background: url(../images/pc/about/vs_spiral.png);
    background-position: 15% 90%;
    position: relative;

    .mission_title {
      position: absolute;
      bottom: -1250px;
      left: 70px;
      font-size: 70px;
      font-weight: bold;
      z-index: 10;

      &::after {
        content: "";
        width: 455px;
        height: 1px;
        background-color: #242424;
        position: absolute;
        top: 7px;
        left: 368px;
      }

      &::before {
        content: "WHY WE DO";
        position: absolute;
        top: 5px;
        left: 838px;
        font-size: 9px;
        font-weight: 100;
        width: 100%;
      }
    }

    .mission_titleEn {
      bottom: -1040px;
    }

    @media only screen and (max-width: 1180px) {
      .mission_title {
        bottom: -1400px;
      }
      .mission_titleEn {
        bottom: -1140px;
      }
    }

    .mission_text__content {
      position: absolute;
      bottom: -1475px;
      left: 440px;
      width: 578px;
      z-index: 10;

      @media only screen and (max-width: 1180px) {
        bottom: -1620px;
      }

      .vision_text {
        font-size: 15px;
        line-height: 1.6;
        letter-spacing: 0.03em;
        max-width: 773px;
        max-height: 475px;
      }
    }
    .mission_text__contentEn {
      bottom: -1425px;

      @media only screen and (max-width: 1180px) {
        bottom: -1530px;
      }
    }
  }
  .credo_sec {
    height: 551px;
    background: url(../images/pc/about/vs_spiral.png);
    background-position: 15% 90%;
    position: relative;
    .credo_title {
      position: absolute;
      bottom: 91px;
      left: 70px;
      font-size: 70px;
      font-weight: bold;
      z-index: 10;

      &::after {
        content: "";
        width: 455px;
        height: 1px;
        background-color: #242424;
        position: absolute;
        top: 7px;
        left: 368px;
      }

      &::before {
        content: "OUR VALUES";
        position: absolute;
        top: 5px;
        left: 838px;
        font-size: 9px;
        font-weight: 100;
        width: 100%;
      }
    }
    @media only screen and (max-width: 1180px) {
      bottom: -145px;
    }
  }

  .credo_titleEn {
    bottom: -1040px;
  }

  @media only screen and (max-width: 1180px) {
    .credo_title {
      bottom: -1400px;
    }
    .credo_titleEn {
      bottom: -1140px;
    }
  }

  .credo_text__content {
    position: absolute;
    bottom: -352px;
    left: 440px;
    width: 578px;
    z-index: 10;

    .vision_text {
      font-size: 15px;
      line-height: 1.6;
      letter-spacing: 0.03em;
      max-width: 773px;
      max-height: 475px;
    }
  }
  .credo_text__contentEn {
    bottom: -1425px;

    @media only screen and (max-width: 1180px) {
      bottom: -1530px;
    }
  }

  .credo_button {
    display: flex;
    position: relative;
    justify-content: space-around;
    font-size: 18px;
    bottom: -400px;
  }

  .founder_sec {
    position: relative;
    height: 1569px;

    @media only screen and (max-width: 1180px) {
      position: relative;
      top: 100px;
    }

    .founder_title {
      max-width: 100px;
      font-size: 50px;
      font-weight: bold;
      position: relative;
      top: 312px;
      left: 711px;
      z-index: 10;
    }

    .founder_title:before {
      content: "";
      width: 291px;
      height: 1px;
      background-color: #242424;
      position: absolute;
      left: 0px;
      top: -234px;
    }

    .founder_text__content {
      position: absolute;
      left: 0px;
      top: 444px;

      .founder_text {
        font-size: 15px;
        line-height: 1.6;
        letter-spacing: 0.03em;
        max-width: 1173px;
        max-height: 475px;
      }

      .founder_text_quote {
        font-size: 15px;
        line-height: 1.6;
        font-weight: bold;
        letter-spacing: 0.03em;
        max-width: 950px;
        max-height: 475px;
        position: relative;
        left: 30px;
      }

      .verticalLine {
        border-left: thick solid #e1e1e1;
        left: 40px;
        position: relative;
      }
    }

    .founder_img {
      left: 0px;
      top: 47px;
      width: 674px;
      height: 365px;
      position: absolute;
    }
  }
}

@media screen and (max-width: 1024px) {
  .vision_header {
    // height: 2100px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 60px;
    &__background {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      background: url(../images/sp/vision/vs_mobile.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      // height: 800px;
      height: calc(100vh - 60px);
      .page_title {
        position: relative;
        overflow: visible;
        &::before {
          content: "";
          height: 183px;
          width: 1px;
          background-color: #fff;
          position: absolute;
          top: -244px;
          left: 50%;
          @include tablet {
            top: -312px;
          }
        }

        &::after {
          position: absolute;
          top: -300px;
          transform: translateX(-50%) rotate(90deg);
          // width: 74px;
          color: #fff;
          font-size: 6px;
          font-weight: 100;
          content: "OUR BELIEF";
          left: 50%;
          @include tablet {
            top: -368px;
          }
        }
      }
    }
    &__title {
      padding-bottom: 68px;
      color: white;
      text-align: center;
      font-size: 90px;
      font-weight: bold;
    }
    &__whiteBox {
      .vision_text__content {
        // top: 845px;
        // height: 307px;
        padding: 35px 32px;
        text-align: center;

        .vision_text {
          font-size: 12px;
          line-height: 2.2;
          letter-spacing: 0.03em;
        }
      }
    }
  }
  .mission_sec {
    text-align: center;
    margin: 30px 0 30px;
    // position: absolute;
    // top: 1650px;

    .mission_title {
      font-size: 70px;
      font-weight: bold;
    }

    .mission_text__content {
      padding-top: 40px;
      padding-bottom: 42px;
      font-size: 12px;
      line-height: 1.5;
      letter-spacing: 0.03em;
    }
  }
  .credo_sec {
    text-align: center;
    margin: 30px 0 30px;
    // position: absolute;
    // top: 1650px;

    .credo_title {
      font-size: 70px;
      font-weight: bold;
    }

    .credo_text__content {
      padding-top: 40px;
      padding-bottom: 42px;
      font-size: 12px;
      line-height: 1.5;
      letter-spacing: 0.03em;
    }
  }
  .credo_button {
    display: inline-flex;
    position: relative;
    justify-content: space-around;
    font-size: 18px;
    bottom: 40px;
  }

  .founder_sec {
    margin: 100px 0 30px;
    text-align: center;

    .founder_title {
      padding-bottom: 39px;
      font-size: 50px;
      font-weight: bold;

      &::before {
        content: "";
        width: calc(50% - 145px - 20px);
        height: 1px;
        background-color: #242424;
        position: absolute;
        top: 6px;
        left: 0px;
      }
    }
    @media only screen and (min-width: 553px) {
      .founder_title {
        &::before {
          content: "";
          width: calc(50% - 268px - 30px);
          height: 1px;
          background-color: #242424;
          position: absolute;
          top: 6px;
          left: 0px;
        }
      }
    }

    .founder_img {
      position: relative;
    }

    .founder_text__content {
      text-align: left;
      margin-left: 30px;
      margin-right: 30px;
      padding-top: 28px;
      font-size: 12px;
      line-height: 1.5;
      letter-spacing: 0.03em;
    }
    .founder_text_quote {
      font-size: 12px;
      line-height: 1.5;
      font-weight: bold;
      letter-spacing: 0.03em;
      margin-right: 30px;
      position: relative;
      left: 28px;
    }

    .verticalLine {
      border-left: thick solid #e1e1e1;
    }
  }
}
.mobile_br {
  display: none;

  @media only screen and (max-width: 768px) {
    display: block;
  }
}
