.inhouseservices {

  // display: block;
  // background: url('../images/sp/about/top_bg@2x.jpg') no-repeat top;
  // background-size: contain;
  @include desktop {
    background: url("../images/pc/about/inhouseservices_bg.jpg") no-repeat;
    background-position-y: 1370px;
  }

  &__contents {
    @include desktop {
      overflow: visible;
    }
  }

  &__container {
    position: relative;
    margin: 0 20px;
    padding-bottom: 80px;

    @include desktop {
      margin: 0 160px;
      padding-bottom: 0;
    }
  }

  p {
    color: #333333;
    font-size: 12px;
    line-height: 22px;

    @include desktop {
      font-size: 15px;
      // line-height: 1.5;
    }
  }

  .inhouseservices_text {
    line-height: 2;
    max-width: 610px;
  }

  .concept_read_more {
    margin-top: 8px;
    text-align: left;
    font-size: 16px;

    input {
      opacity: 0;
      position: absolute;
      pointer-events: none;

      &:checked+.concept_see_more {
        -webkit-line-clamp: unset;
      }

      &:checked~label {
        display: none;
      }
    }
  }

  .concept_see_more {
    display: -webkit-box;
    -webkit-line-clamp: 18;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 8px;
    text-align: left;
    font-size: 16px;
    margin-right: 12px;

    p {
      display: inline;
    }

    @media only screen and (max-width: 768px) {
      -webkit-line-clamp: 10;
      font-size: 13px;
      margin-right: unset;
    }

    &:not(.truncated)~label {
      display: none;
    }
  }

  .read_more_text {
    color: black;
    text-align: center;
    margin-top: 14px;
    display: flex;
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.s-media {
  position: relative;
  border-bottom: 1px solid rgba(225, 225, 225, 0.5);
  padding-bottom: 56px;

  @include desktop {
    padding-top: 120px;
    padding-bottom: 0px;
  }

  &--hasLink {
    margin-bottom: 35px;

    @include desktop {
      margin-bottom: 168px;
    }
  }

  &__heading {
    color: #ffffff;
    font-size: 10px;
    line-height: 1.5;
    margin-bottom: 8px;

    @include desktop {
      position: absolute;
      right: 0;
      top: -456px;
      margin-bottom: 0;
      z-index: 10;
      color: #242424;
      font-size: 30px;
      line-height: 44px;
      font-weight: 600;
    }

    &--sp {
      @include desktop {
        display: none;
      }
    }
  }

  &__btn {
    right: 0;
    bottom: -1px;

    @include desktop {
      position: relative;
      right: inherit;
      bottom: inherit;
      margin-top: inherit;
      margin-left: auto;
      margin-right: 0;
      width: 200px;
      height: 67px;
    }

    .link {
      line-height: 67px;
    }
  }

  &__btn-vline {
    display: none;

    @include desktop {
      position: relative;
      margin-left: auto;
      margin-right: 200px;
      content: "";
      display: block;
      width: 1px;
      height: 102px;
      margin-top: 8px;
      background-color: rgba(225, 225, 225, 0.5);
    }
  }

  &__vibes {
    display: none;

    @include desktop {
      display: block;
    }

    &--sp {
      display: block;
      margin-bottom: 30px;

      @include desktop {
        display: none;
      }
    }
  }

  &__tells-img {
    @include desktop {
      position: absolute;
      right: 0;
      top: -32px;
      width: 350px;
      height: 575px;
      z-index: 4;
    }
  }

  &__vibes-img {
    @include desktop {
      position: absolute;
      right: 0;
      top: -208px;
      width: 350px;
      height: 575px;
      z-index: 4;
    }
  }

  &__vibes-bg {
    z-index: -5;

    @include desktop {
      position: absolute;
      right: -232px;
      top: -256px;
      width: 505px;
      height: 832px;
      z-index: 3;
    }
  }

  &__text-container {
    position: relative;

    // overflow: hidden;
    @include desktop {
      margin-right: 350px;
      padding: 50px 0;
    }
  }

  &__text {
    position: relative;
    // overflow: hidden;
    font-size: 15px;
    line-height: 32px;
  }
}

.s-service {
  position: relative;

  &--hasLink {
    margin-bottom: 88px;
    border-bottom: 1px solid rgba(225, 225, 225, 0.5);

    @include desktop {
      margin-bottom: 0;
      border-bottom: none;
    }
  }

  &__heading {
    display: none;

    @include desktop {
      position: relative;
      right: inherit;
      display: block;
      padding: 33px 160px 72px;
      background-color: rgba(255, 255, 255, 1);
      font-size: 40px;
      line-height: 59px;
      font-weight: 600;
    }
  }

  &__heading-sp {
    position: relative;
    font-size: 10px;
    line-height: 15px;

    @include desktop {
      display: none;
    }
  }

  &__heading-number {
    position: absolute;
    right: 0;
    width: 20px;
    color: rgba(225, 225, 225, 1);
    text-align: center;
  }

  &__heading-text {
    position: absolute;
    top: 128px;
    right: -10px;
    transform: rotate(90deg);
    color: rgba(225, 225, 225, 1);
  }

  &__img {
    display: none;

    @include desktop {
      position: absolute;
      display: block;
      left: 0;
      top: -32px;
      // box-shadow: 10px 10px 10px rgba(0,0,0,.1); looks weird with grow-right, 統一 with others
    }

    &--sp {
      position: relative;
      top: -14px;
      display: block;
      margin-bottom: 16px;

      @include desktop {
        display: none;
      }
    }
  }

  &__fwfs-img {
    @include desktop {
      width: 350px;
      height: 575px;
    }
  }

  &__text {
    @include desktop {
      min-height: 652px;
      padding: 50px 64px 90px 0;
      border-right: 1px solid rgba(225, 225, 225, 0.5);
    }
  }

  &__text-container--vibes {
    background-color: rgba(255, 255, 255, 1);
    font-size: 15px;
    line-height: 32px;

    @include desktop {
      border: 1px solid rgba(225, 225, 225, 0.5);
      margin-left: 190px;
      margin-right: -160px;
      padding: 0 159px 0 240px;

      &:after {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        content: "03";
        padding: 5px 10px;
        color: rgba(225, 225, 225, 1);
        font-size: 10px;
        line-height: 1.5;
      }
    }
  }

  &__text-container {
    background-color: rgba(255, 255, 255, 1);
    font-size: 15px;
    line-height: 32px;

    @include desktop {
      border: 1px solid rgba(225, 225, 225, 0.5);
      margin-left: 190px;
      margin-right: -160px;
      padding: 0 159px 0 240px;

      &:after {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        content: "AIによるキャリアプラン 06";
        padding: 5px 10px;
        color: rgba(225, 225, 225, 1);
        font-size: 10px;
        line-height: 1.5;
      }
    }
  }

  &__space-after {
    @include desktop {
      // height: 265px;
      margin-left: 350px;
      margin-right: 160px;
      border-left: 1px solid rgba(225, 225, 225, 0.5);
      border-right: 1px solid rgba(225, 225, 225, 0.5);
      // border-bottom: 1px solid rgba(225, 225, 225, 0.5);
    }
  }
}

.s-service {
  position: relative;

  &--hasLink {
    margin-bottom: 88px;
    border-bottom: 1px solid rgba(225, 225, 225, 0.5);

    @include desktop {
      margin-bottom: 0;
      border-bottom: none;
    }
  }

  &__heading {
    display: none;

    @include desktop {
      position: relative;
      right: inherit;
      display: block;
      padding: 33px 160px 72px;
      background-color: rgba(255, 255, 255, 1);
      font-size: 40px;
      line-height: 59px;
      font-weight: 600;
    }
  }

  &__heading-sp {
    position: relative;
    font-size: 10px;
    line-height: 15px;

    @include desktop {
      display: none;
    }
  }

  &__heading-number {
    position: absolute;
    right: 0;
    width: 20px;
    color: rgba(225, 225, 225, 1);
    text-align: center;
  }

  &__heading-text {
    position: absolute;
    top: 128px;
    right: -10px;
    transform: rotate(90deg);
    color: rgba(225, 225, 225, 1);
  }

  &__img {
    display: none;

    @include desktop {
      position: absolute;
      display: block;
      left: 0;
      top: -32px;
      // box-shadow: 10px 10px 10px rgba(0,0,0,.1); looks weird with grow-right, 統一 with others
    }

    &--sp {
      position: relative;
      top: -14px;
      display: block;
      margin-bottom: 16px;

      @include desktop {
        display: none;
      }
    }
  }

  &__tells-img {
    @include desktop {
      width: 350px;
      height: 575px;
    }

    @media screen and (max-width: 1024px) {
      width: 610px;
    }
  }

  &__text {
    @include desktop {
      min-height: 652px;
      padding: 50px 64px 90px 0;
      border-right: 1px solid rgba(225, 225, 225, 0.5);
    }

    .tells-title {
      font-size: 15px;
      font-weight: bold;
      margin-bottom: 20px;
    }
  }

  &__text-container-tells {
    background-color: rgba(255, 255, 255, 1);
    font-size: 15px;
    line-height: 32px;

    @include desktop {
      border-top: 1px solid rgba(225, 225, 225, 0.5);

      &:after {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        content: "01";
        padding: 5px 10px;
        color: rgba(225, 225, 225, 1);
        font-size: 10px;
        line-height: 1.5;
      }
    }

    @media screen and (max-width: 1024px) {
      line-height: 18px !important;
    }
  }

  &__text-container-susPay {
    background-color: rgba(255, 255, 255, 1);
    font-size: 15px;
    line-height: 32px;

    @include desktop {
      border: 1px solid rgba(225, 225, 225, 0.5);
      margin-left: 190px;
      margin-right: -160px;
      padding: 0 159px 0 240px;

      &:after {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        content: "02";
        padding: 5px 10px;
        color: rgba(225, 225, 225, 1);
        font-size: 10px;
        line-height: 1.5;
      }
    }

    @media screen and (max-width: 1024px) {
      line-height: 18px !important;
    }
  }

  &__space-after {
    @include desktop {
      // height: 265px;
      margin-left: 350px;
      margin-right: 160px;
      border-left: 1px solid rgba(225, 225, 225, 0.5);
      border-right: 1px solid rgba(225, 225, 225, 0.5);
      // border-bottom: 1px solid rgba(225, 225, 225, 0.5);
    }
  }
}

.s-spin {
  @include desktop {
    margin-bottom: 168px;
  }

  &__heading-sp {
    position: relative;
    font-size: 10px;
    line-height: 15px;

    @include desktop {
      display: none;
    }
  }

  &__heading-number {
    position: absolute;
    left: 0;
    width: 20px;
    color: rgba(225, 225, 225, 1);
    text-align: center;
  }

  &__heading-text {
    position: absolute;
    top: 128px;
    left: -10px;
    transform: rotate(90deg);
    color: rgba(225, 225, 225, 1);
  }

  &__img {
    display: none;

    @include desktop {
      position: absolute;
      right: 0;
      top: -40px;
      display: block;
      z-index: 5;
    }

    &--sp {
      position: relative;
      // top: -14px;
      margin-top: 26px;
      display: block;
      margin-bottom: 30px;

      @include desktop {
        display: none;
      }
    }
  }

  &__spin-img {
    @include desktop {
      width: 350px;
      height: 575px;
      z-index: 4;
    }
  }

  &__text-container {
    background-color: rgba(255, 255, 255, 1);
    font-size: 15px;
    line-height: 32px;

    @include desktop {
      margin-left: -160px;
      margin-right: 190px;
      padding: 0 200px 0 159px;
      border-top: 1px solid rgba(225, 225, 225, 0.5);

      &:before {
        position: absolute;
        top: 0;
        left: -65px;
        display: block;
        content: "03　才能開花・クラウドファンディング";
        padding: 14px 10px;
        color: rgba(225, 225, 225, 1);
        font-size: 10px;
        line-height: 1.5;
      }
    }
  }

  &__text {
    @include desktop {
      min-height: 575px;
      padding: 50px 64px 40px 70px;
    }
  }
}

.s-journey {
  @include desktop {
    margin-bottom: 168px;
  }

  &__heading-sp {
    position: relative;
    font-size: 10px;
    line-height: 15px;

    @include desktop {
      display: none;
    }
  }

  &__heading-number {
    position: absolute;
    left: 0;
    width: 20px;
    color: rgba(225, 225, 225, 1);
    text-align: center;
  }

  &__heading-text {
    position: absolute;
    top: 128px;
    left: -10px;
    transform: rotate(90deg);
    color: rgba(225, 225, 225, 1);
  }

  &__img {
    display: none;

    @include desktop {
      position: absolute;
      right: 0;
      top: -40px;
      display: block;
      z-index: 5;
    }

    &--sp {
      position: relative;
      top: -14px;
      display: block;
      margin-bottom: 30px;

      @include desktop {
        display: none;
      }
    }
  }

  &__journey-img {
    @include desktop {
      width: 350px;
      height: 575px;
      z-index: 4;
    }
  }

  &__text-container {
    background-color: rgba(255, 255, 255, 1);
    font-size: 15px;
    line-height: 32px;

    @include desktop {
      margin-left: -160px;
      margin-right: 190px;
      padding: 0 200px 0 159px;
      border-top: 1px solid rgba(225, 225, 225, 0.5);

      &:before {
        position: absolute;
        top: 0;
        left: -65px;
        display: block;
        content: "05　人材シェアリング";
        padding: 14px 10px;
        color: rgba(225, 225, 225, 1);
        font-size: 10px;
        line-height: 1.5;
      }
    }
  }

  &__text {
    @include desktop {
      min-height: 575px;
      padding: 50px 64px 40px 70px;
    }
  }
}

// .s-journey {
//   padding-top: 80px;
//   background-color: #ffffff;
//   &__heading-sp {
//     position: relative;
//     font-size: 10px;
//     line-height: 15px;
//     @include desktop {
//       display: none;
//     }
//   }
//   &__heading-number {
//     position: absolute;
//     right: 0;
//     width: 20px;
//     color: rgba(225,225,225,1);
//     text-align: center;
//   }
//   &__heading-text {
//     position: absolute;
//     top: 128px;
//     right: -10px;
//     transform: rotate(90deg);
//     color: rgba(225,225,225,1);
//   }
//   &__img {
//     display: none;
//     @include desktop {
//       position: absolute;
//       left: 0;
//       top: -34px;
//       display: block;
//     }
//     &--sp {
//       position: relative;
//       top: -14px;
//       display: block;
//       margin-bottom: 30px;
//       @include desktop {
//         display: none;
//       }
//     }
//   }
//   &__journey-img {
//     @include desktop {
//       width: 350px;
//       height: 575px;
//     }
//   }
//   &__text-container {
//     background-color: rgba(255,255,255,1);
//     font-size: 15px;
//     line-height: 32px;
//     @include desktop {
//       margin-left: 190px;
//       margin-right: -160px;
//       padding: 0 159px 0 240px;
//       border-top: 1px solid rgba(225, 225, 225, 0.5);
//       &:after {
//         position: absolute;
//         top: 0;
//         right: 0;
//         display: block;
//         content: '05　AIによるキャリアプラン';
//         padding: 14px 10px;
//         color: rgba(225,225,225,1);
//         font-size: 10px;
//         line-height: 1.5;
//       }
//     }
//   }
//   &__text {
//     @include desktop {
//       min-height: 575px;
//       padding: 50px 64px 40px 0;
//     }
//   }
// }

// .s-journey {
//   padding-top: 80px;
//   background-color: #ffffff;
//   &__heading-sp {
//     position: relative;
//     font-size: 10px;
//     line-height: 15px;
//     @include desktop {
//       display: none;
//     }
//   }
//   &__heading-number {
//     position: absolute;
//     right: 0;
//     width: 20px;
//     color: rgba(225,225,225,1);
//     text-align: center;
//   }
//   &__heading-text {
//     position: absolute;
//     top: 128px;
//     right: -10px;
//     transform: rotate(90deg);
//     color: rgba(225,225,225,1);
//   }
//   &__img {
//     display: none;
//     @include desktop {
//       position: absolute;
//       left: 0;
//       top: -34px;
//       display: block;
//     }
//     &--sp {
//       position: relative;
//       top: -14px;
//       display: block;
//       margin-bottom: 30px;
//       @include desktop {
//         display: none;
//       }
//     }
//   }
//   &__journey-img {
//     @include desktop {
//       width: 350px;
//       height: 575px;
//     }
//   }
//   &__text-container {
//     background-color: rgba(255,255,255,1);
//     font-size: 15px;
//     line-height: 32px;
//     @include desktop {
//       margin-left: 190px;
//       margin-right: -160px;
//       padding: 0 159px 0 240px;
//       border-top: 1px solid rgba(225, 225, 225, 0.5);
//       &:after {
//         position: absolute;
//         top: 0;
//         right: 0;
//         display: block;
//         content: '05　AIによるキャリアプラン';
//         padding: 14px 10px;
//         color: rgba(225,225,225,1);
//         font-size: 10px;
//         line-height: 1.5;
//       }
//     }
//   }
//   &__text {
//     @include desktop {
//       min-height: 575px;
//       padding: 50px 64px 40px 0;
//     }
//   }
// }

.service {

  &--first,
  &--second,
  &--last {
    border-top: 1px solid rgba(225, 225, 225, 0.5);

    @include desktop {
      margin-top: inherit;
      border: none;
    }
  }
}

.service {

  &--fith,
  &--second,
  &--last {
    border-top: 1px solid rgba(225, 225, 225, 0.5);

    @include desktop {
      // margin-top: inherit;
      margin-top: 168px;
      border: none;
    }
  }
}

@include desktop {
  /* Global engineering page */
  // #page_title_os {
  //     position: absolute;
  //     top: -638px;
  //     left: calc(50% - 565px);
  //     width: 100%;
  //     font-size: 70px;
  //     color: white;
  //     z-index: 3;
  //     font-weight: bold;

  //     &::before {
  //         content: '';
  //         width: 50px;
  //         height: 150px;
  //         background-color: #242424;
  //         display: block;
  //         position: absolute;
  //         top: -25px;
  //         left: -15px;
  //         opacity: .2;
  //         mix-blend-mode: overlay;
  //     }
  // }

  .os_header__whiteBox {
    width: calc(50% - 75px);
    height: 304px;
    background-color: #fff;
    position: absolute;
    top: 511px;
    right: 0;
    z-index: 0;
  }

  // .os_header_description {
  //     position: absolute;
  //     left: calc(50% - 598px);
  //     top: -540px;
  //     width: 615px;
  //     height: 307px;
  //     padding: 73px 32px 58px 65px;
  //     background: $red;
  //     z-index: 2;
  //     color: #fff;
  //     box-shadow: -2px 10px 4px 0px rgba(0, 0, 0, 0.1);
  // }

  .os_right__title {
    position: absolute;
    top: -230px;
    left: 770px;
    width: 550px;

    &-text {
      display: block;
      font-size: 30px;
      font-weight: bold;
      color: #000;
      transform-origin: top;
    }

    &--vline {
      width: 1px;
      height: 400px;
      background: #000;
      display: block;
      position: absolute;
      top: -520px;
      right: 464px;
    }
  }

  .os_right__btn {
    position: absolute;
    top: -165px;
    // left: 0;
    left: calc(-6% - 10px);
    width: 617px;
    color: #fff;
    display: flex;

    .os_btn__title {
      margin: 55px 0 0 40px;
      top: 0%;
      left: 0;
      transition: 0.6s ease-in-out;
      transition-delay: 0.2s;
      text-align: left;
      font-size: 12px;
      white-space: nowrap;

      &--link {
        color: #fff;
        position: relative;
        display: block;

        &::after {
          border-bottom: solid 1px #fff;
          bottom: -5px;
          content: "";
          display: block;
          position: absolute;
          right: 0;
          transition: all 0.3s ease;
          -webkit-transition: all 0.3s ease;
          width: 0;
        }

        &:hover::after {
          width: 100%;
          transform: rotateX(1);
        }
      }

      .os_btn__num {
        position: relative;
        left: 21px;
        top: -20px;
      }
    }
  }

  .os_text {
    font-size: 15px;
    line-height: 1.5;
    letter-spacing: 0.03em;
    max-width: 650px;
    max-height: 176px;
    color: #fff;
    font-family: "Noto Sans JP", sans-serif;
  }

  .os_header {
    position: relative;
    display: block;
    /* padding: 100px 0 154px; */
    z-index: 0;
    /* background-image: url(../images/pc/sises/ge_spiral.png); */
  }

  .os_header__background {
    position: relative;
    background: url(../images/pc/os/org_main.jpg),
      url(../images/pc/os/org_lines.png);
    background-size: cover, cover;
    background-blend-mode: hard-light;
    height: 815px;
    width: 60%;

    &::before {
      background: url(../images/pc/os/org_sideline.png);
      background-size: cover;
      top: 0;
      left: 100%;
      width: 67%;
      height: 100%;
      content: "";
      position: absolute;

      // left: 53%;
      // width: 55%;
    }
  }

  .os_line_background {
    position: relative;
    // background: linear-gradient(#3BB8FF, #0B4CB5);
  }

  .os_circle_img {
    position: relative;

    &::before {
      content: "";
      width: 50px;
      height: 150px;
      background-color: #242424;
      display: block;
      position: absolute;
      top: -320px;
      left: -139px;
      opacity: 0.5;
      mix-blend-mode: overlay;
      z-index: 2;
    }

    .os_circles {
      position: absolute;
      top: -300px;
      left: -130px;
    }
  }

  .bottom_spacer {
    margin-bottom: 200px;
  }
}

#page_title_os {
  @include desktop {
    position: absolute;
    top: -626px;
    left: calc(50% - 465px);
    width: 100%;
    font-size: 58px;
    color: white;
    z-index: 3;
    font-weight: bold;

    &::before {
      content: "";
      width: 50px;
      height: 150px;
      background-color: #242424;
      display: block;
      position: absolute;
      top: -25px;
      left: -15px;
      opacity: 0.2;
      mix-blend-mode: overlay;
    }
  }

  @include l-desktop {
    position: absolute;
    top: -638px;
    left: calc(50% - 565px);
    width: 100%;
    font-size: 70px;
    color: white;
    z-index: 3;
    font-weight: bold;

    &::before {
      content: "";
      width: 50px;
      height: 150px;
      background-color: #242424;
      display: block;
      position: absolute;
      top: -25px;
      left: -15px;
      opacity: 0.2;
      mix-blend-mode: overlay;
    }
  }
}

.page_title--os {
  position: relative;

  &::before {
    content: "";
    width: 1px;
    height: 150px;
    background-color: #fff;
    position: absolute;
    top: -140px;
    right: 30px;

    @media screen and (max-width: 350px) {
      top: -85px;
      height: 100px;
    }
  }

  &::after {
    position: absolute;
    top: 61px;
    right: -10px;
    transform: rotate(90deg);
    color: #fff;
    font-size: 6px;
    font-weight: 100;
    content: "FROM JAPAN TO THE WORLD";

    @media screen and (max-width: 350px) {
      top: 60px;
    }
  }
}

.os_header_description {
  @include desktop {
    position: absolute;
    left: calc(50% - 512px);
    top: -540px;
    width: 555px;
    height: 307px;
    padding: 73px 32px 58px 65px;
    background: $red;
    z-index: 2;
    color: #fff;
    box-shadow: -2px 10px 4px 0px rgba(0, 0, 0, 0.1);
  }

  @include l-desktop {
    position: absolute;
    left: calc(50% - 598px);
    top: -540px;
    width: 615px;
    height: 307px;
    padding: 73px 32px 58px 65px;
    background: $red;
    z-index: 2;
    color: #fff;
    box-shadow: -2px 10px 4px 0px rgba(0, 0, 0, 0.1);
  }
}

@media screen and (max-width: 1024px) {
  .os_header {
    // height: 750px;
    position: relative;
    padding-top: 60px;
    height: 100vh;
    display: block;

    //  &__background {
    //    position: absolute !important;
    //    top: 0;
    //    left: 0;
    //    right: 0;
    //    bottom: 0;
    //    background: url(../images/sp/os/os_mobile.jpg);
    //    background-repeat: no-repeat;
    //    background-size: cover;
    //  }
  }

  #page_title_os {
    position: relative;
    overflow: visible; // Use this when the before/after subtitle message looks weird
    margin-top: auto;
    width: 100%;
    height: initial;
    padding: 20px 20px 20px 10px;
    color: white;
    font-size: 40px;
    line-height: 1.15;
    font-weight: bold;
  }

  .os_header_description {
    display: flex;
    color: #fff;

    .os_text {
      font-size: 12px;
      line-height: 1.6;
      max-width: 650px;
      padding: 20px 10px 68px 10px;
    }
  }

  .os_header__background {
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url(../images/sp/os/os_mobile.jpg);
    background-repeat: no-repeat;
    background-size: cover;
  }

  .os_header__text-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: calc(100vh - 60px);
  }
}

.text__content {
  &--space-after {
    margin-bottom: 60px;
  }
}

.sp_vibes__btn {
  position: absolute;
  right: 0;
  bottom: -140px;

  @include l-desktop {
    z-index: 1;
    right: 0;
    bottom: 110px;
  }

  @include desktop {
    z-index: 1;
    right: 0;
    bottom: 80px;
  }
}

.sp_spin__btn {
  position: absolute;
  z-index: 1;
  right: auto;
  bottom: 40px;

  @media screen and (max-width: 1366px) {
    bottom: 0;
  }

  @media screen and (max-width: 1024px) {
    right: 0;
  }
}

.mobile_br {
  display: none;

  @media only screen and (max-width: 768px) {
    display: block;
  }
}
