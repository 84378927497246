#media-contact {
  .moff_hidden {
    display: none;
    @media screen and (max-width: 1024px) {
      display: block;
    }
  }
  .moff_block {
    display: block;
    @media screen and (max-width: 1024px) {
      display: none;
    }
  }
  .link {
    fill: #ffffff;
    &:hover {
      fill: $red;
    }
  }
  .filter {
    filter: invert(3%) sepia(0%) saturate(2605%) hue-rotate(134deg)
      brightness(0%) contrast(64%);
  }
  .media_list {
    background: #141414;
    padding: 0 7.29vw 190px 7.29vw;
    @media only screen and (max-width: 1025px) {
      padding: 0 11.5vw 118px 11.5vw;
    }
    @media only screen and (max-width: 575px) {
      padding: 0 0 190px 0;
    }
    .top_media_list {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 40px;
      @media only screen and (max-width: 575px) {
        padding: 0 6.4vw;
      }
      .title {
        font-size: 32px;
        font-weight: bold;
        color: #ffffff;
        @media only screen and (max-width: 1024px) {
          font-size: 18px;
        }
      }
      .see_all_btn {
        font-size: 16px;
        color: #ffffff;
        @media only screen and (max-width: 1024px) {
          font-size: 13px;
        }
      }
    }

    .media_articles_lists {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      // grid-template-rows: repeat(3, 1fr);
      grid-column-gap: 40px;
      grid-row-gap: 40px;
      align-items: start;
      justify-items: center;
      padding-left: 0;
      @media only screen and (max-width: 1025px) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 16px;
      }
      @media only screen and (max-width: 575px) {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(1, 1fr);
      }

      .no_articles {
        color: #fff;
        font-size: 18px;
      }

      .article_li {
        &:hover img {
          transform: scale(1.1);
          transition: 0.3s ease-out;
        }
        &:last-child {
          @media only screen and (max-width: 1025px) {
            display: none;
          }
          @media only screen and (max-width: 575px) {
            display: block;
          }
        }

        .media_article_title {
          position: relative;

          a {
            line-height: 1.5;
            font-size: 18px;
            font-weight: 500;
            color: #fff !important;
          }

          .article_img {
            position: relative;
            max-width: 520px;
            max-height: 310px;
            height: 14.14vw;
            margin: 0px auto;
            background: black;
            overflow: hidden;
            @media only screen and (max-width: 1025px) {
              max-width: 575px;
              height: 22.4vw;
            }
            @media only screen and (max-width: 575px) {
              width: 100%;
              height: calc(100vw / 1.75);
            }

            .overlay_before {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              display: block;
              background: transparent
                linear-gradient(180deg, #00000000 0%, #000000 100%) 0% 0%
                no-repeat padding-box;
              transition-duration: 0.3s;
            }

            .overlay_before + .article_img img {
              transform: scale(1.1);
              transition-duration: 0.3s;
              opacity: 0.5;
            }

            img {
              height: 100%;
              object-fit: cover;
              transition-duration: 0.3s;
            }
          }

          .media_article_body {
            position: absolute;
            width: 90%;
            height: 54px;
            bottom: 5%;
            margin: 0px 16px 0px 16px;

            &__overflow {
              width: 100%;
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              @media only screen and (max-width: 1025px) {
                font-size: 16px;
              }
            }
          }
        }

        .media_article_details {
          line-height: 1.5;
          margin: 8px 16px 5px 16px;
          color: #fff;
          letter-spacing: 0.025em;
          font-size: 14px;
          @media only screen and (max-width: 1025px) {
            font-size: 12px;
          }

          .article_media {
            float: left;
          }

          .article_date {
            width: auto;
            float: right;
          }
        }
      }
    }
  }
}
.media-contact {
  @media screen and (max-width: 1024px) {
    margin-top: 60px;
  }
  .tablet-only {
    display: none;
  }
  .sp-only {
    display: none;
  }
  @media screen and (max-width: 1024px) {
    .pc-only {
      display: none;
    }
    .tablet-only {
      display: block;
    }
  }

  @media screen and (max-width: 376px) {
    .pc-only {
      display: none;
    }
    .tablet-only {
      display: none;
    }
    .sp-only {
      display: block;
    }
  }

  .top-cover {
    position: relative;
    .top-img {
      height: 779px;
      object-fit: cover;
      width: 100%;
      @media screen and (max-width: 1024px) {
        height: 600px;
      }
      @media screen and (max-width: 575px) {
        height: 500px;
      }
    }
    .text {
      position: absolute;
      top: 57.12%;
      left: 14.48%;
      color: #ffffff;
      line-height: normal;
      @media screen and (max-width: 1024px) {
        top: 62.16%;
        left: 11.52%;
      }
      @media screen and (max-width: 576px) {
        top: 57.2%;
        left: 6.38%;
      }
      .subtitle {
        font-size: 16px;
        @media screen and (max-width: 1024px) {
          font-size: 12px;
        }
      }
      .title {
        font-size: 40px;
        font-weight: bold;
        margin: 16px 0 24px;
        @media screen and (max-width: 1024px) {
          font-size: 32px;
          margin: 8px 0 24px;
        }
        @media screen and (max-width: 576px) {
          font-size: 20px;
          margin: 8px 0 16px;
        }
      }
      .body {
        font-size: 16px;
        @media screen and (max-width: 1024px) {
          font-size: 14px;
        }
        @media screen and (max-width: 1024px) {
          font-size: 12px;
        }
      }
    }
  }
  .bottom {
    padding-top: 100px;
    padding-bottom: 260px;
    background: #141414;
    .form {
      width: fit-content;
      margin: 0 auto;
      color: #ffffff;
    }
    .contact-items {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 670px;
      @media screen and (max-width: 786px) {
        width: 86.93vw;
      }
      .label {
        font-weight: normal;
        margin-bottom: 8px;
      }
      .field {
        margin-bottom: 24px;
        input[type="text"],
        input[type="tel"],
        input[type="email"] {
          width: 100%;
          height: 60px;
          padding-left: 24px;
          border: none;
          border-radius: 0;
          outline: 0;
          background: #313238;
        }
        input:focus {
          background-color: #4b4d58;
        }
        textarea {
          width: 100%;
          height: 379px;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          resize: none;
          padding: 12px 24px 0 24px;
          border: 0;
          outline: 0;
          background: #313238;
          border-radius: 0;
          @media screen and (max-width: 786px) {
            height: 230px;
          }
        }
        .wpcf7c-conf {
          color: #ffffff;
        }
        .s-agree {
          .wpcf7-list-item {
            margin-left: unset !important;
          }
        }

        input:-webkit-autofill {
          -webkit-text-fill-color: #ffffff;
          -webkit-box-shadow: 0 0 0 1000px #313238 inset;
        }
      }
    }
    .wpcf7-response-output {
      left: 50%;
      transform: translateX(-50%);
      width: fit-content;
    }
    .step--1 {
      margin-top: 56px;
      bottom: unset;
      input[type="submit"] {
        width: 371px;
        height: 60px;
        font-size: 16px;
        font-weight: 500;
        line-height: unset;

        @media screen and (max-width: 576px) {
          width: 86.93%;
        }
      }
    }
    .step--2 {
      margin-top: 56px;
      bottom: unset;
      @include desktop {
        left: 50%;
        transform: translateX(-50%);
      }
      .ajax-loader {
        width: 0;
      }
      input[type="button"],
      input[type="submit"] {
        font-size: 16px;
        font-weight: 500;
        line-height: unset;
      }
    }

    input[type="button"]:hover,
    input[type="submit"]:hover {
      background-color: #000000;
      -webkit-transition-duration: 1s;
      transition-duration: 1s;
      -webkit-transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
      transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    }
  }
}
