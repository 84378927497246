.moff2023_images{
  width: 100%;
  height: 730px;
  display: flex;
  justify-content: center;
  align-items: center;

  overflow: hidden;

  // --card-width: 350px;
  // --card-height: 550px;
  --card-width: 24vw;
  --card-height: 39.19vw;

  --card-transition-duration: 800ms;
  --card-transition-easing: ease;
  @media screen and (max-width: 1024px) {
    --card-width: 28.8vw;
    --card-height: 47.03vw;
    height: 70vw

  }
  @media screen and (max-width: 576px) {
    margin-top: 60px;
    --card-width: 55vw;
    --card-height: 89.81vw;
    height: calc(100vw + 14.29vw)
    // --card-width: 250px;
    // --card-height: 350px;
  }


  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  button {
    border: none;
    background: none;
    cursor: pointer;
    &:focus {
      outline: none;
      border: none;
    }
  }

  .app {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cardList {
    position: absolute;
    width: calc(3 * var(--card-width));
    max-width: 1036px;
    height: auto;

    &__btn {
      --btn-size: 40px;
      width: var(--btn-size);
      height: var(--btn-size);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 100;

      &.btn--left {
        left: -4%;
        @media screen and (max-width: 576px){
          left: 20%;
        }
      }
      &.btn--right {
        right: -4%;
        @media screen and (max-width: 576px){
          right: 20%;
        }
      }

      .icon {
        width: 100%;
        height: 100%;
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }

    .cards__wrapper {
      position: relative;
      width: 100%;
      height: 100%;
      perspective: 1000px;
    }
  }

  .card {
    --card-translateY-offset: 0%;

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) translateX(var(--card-translateX-offset))
      translateY(var(--card-translateY-offset)) translateZ(var(--card-translateZ-offset)) rotateY(var(--card-rotation-offset))
      scale(var(--card-scale-offset));
    display: inline-block;
    width: var(--card-width);
    height: var(--card-height);
    max-width: var(--card-max-width);
    max-height: var(--card-max-height);
    transition: transform var(--card-transition-duration)
      var(--card-transition-easing);
    user-select: none;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: #000;
      z-index: 1;
      transition: opacity var(--card-transition-duration)
        var(--card-transition-easing);
      opacity: calc(1 - var(--opacity));
    }

    &__image {
      position: relative;
      width: 100%;
      height: 100%;

      .image {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        &__1{
          top:-4%;
          object-fit: contain;
        }
        &__2{
          top: 32%;
          object-fit: contain;
        }
      }
    }

    &.current--card {
      --current-card-rotation-offset: 0;
      --card-translateX-offset: 0;
      --card-translateZ-offset: 0;
      --card-rotation-offset: var(--current-card-rotation-offset);
      --card-scale-offset: 1.2;
      --card-max-width: 345.6px;
      --card-max-height: 564.3px;
      --opacity: 1;
    }

    &.previous--card {
      --card-translateX-offset: calc(-1 * var(--card-width) * 1.1);
      --card-translateZ-offset: 0;
      --card-rotation-offset: 25deg;
      @media screen and (min-width: 1440px) {
        --card-translateX-offset: calc(-1 * var(--card-max-width) * 1.1);
      }
    }
    &.previous--card_2 {
      --card-translateX-offset: 0;
      --card-rotation-offset: 0;
      --card-translateZ-offset: -100px;
      @media screen and (min-width: 1440px) {
        --card-translateX-offset: 0;
      }
    }

    &.next--card {
      --card-translateX-offset: calc(var(--card-width) * 1.1);
      --card-translateZ-offset: 0;
      --card-rotation-offset: -25deg;
      @media screen and (min-width: 1440px) {
        --card-translateX-offset: calc(var(--card-max-width) * 1.1);
      }
    }

    &.previous--card,
    &.next--card {
      --card-max-width: 345px;
      --card-max-height: 543px;
      --card-scale-offset: 0.9;
      --opacity: 0.7;
    }
    &.previous--card_2{
      --current-card-rotation-offset: 0;
      --card-translateX-offset: 0;
      --card-rotation-offset: var(--current-card-rotation-offset);
      --card-scale-offset: 0;
      --card-max-width: 345.6px;
      --card-max-height: 564.3px;
      --opacity: 1;
    }
  }

  .infoList {
    position: absolute;
    width: calc(3 * var(--card-width));
    height: var(--card-height);
    pointer-events: none;

    .info__wrapper {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      perspective: 1000px;
      transform-style: preserve-3d;
    }
  }

  .info {
    margin-bottom: calc(var(--card-height) / 8);
    margin-left: calc(var(--card-width) / 1.5);
    transform: translateZ(2rem);
    transition: transform var(--card-transition-duration)
      var(--card-transition-easing);
      @media screen and (max-width: 576px) {
        margin-left: calc(var(--card-width) / 1.3);
      }
    .text {
      position: relative;
      font-size: calc(var(--card-width) * var(--text-size-offset, 0.2));
      white-space: nowrap;
      color: #fff;
      width: fit-content;
      text-shadow: 1px 1px gray;
      @media screen and (max-width: 576px) {
        font-size: calc(var(--card-width) * var(--text-size-offset, 0.12));
      }
    }
    &--hidden{
      opacity: 0 !important;
    }

    .name,
    .location {
      text-transform: uppercase;
    }

    .name {
      font-weight: bold;
    }

    .location {
      --mg-left: 40px;
      --text-size-offset: 0.12;
      font-weight: 600;
      margin-left: var(--mg-left);
      margin-bottom: calc(var(--mg-left) / 2);
      padding-bottom: 0.8rem;
      @media screen and (max-width: 576px) {
        --text-size-offset: 0.08;
      }

      &::after {
        content: "";
        position: absolute;
        background: #fff;
        left: 0%;
        transform: translate(calc(-1 * var(--mg-left)), -50%);
      }
      // &::before {
      //   top: 50%;
      //   width: 20px;
      //   height: 5px;
      // }
      &::after {
        bottom: 0;
        width: 60px;
        height: 2px;
      }
    }

    .description {
      --text-size-offset: 0.065;
      font-weight: 500;
      @media screen and (max-width: 576px) {
        --text-size-offset: 0.065;
      }
    }

    &.current--info {
      opacity: 1;
      display: block;
    }
    &.previous--info,
    &.previous--info_2,
    &.next--info {
      opacity: 0;
      display: none;
    }
  }
}
.moff2023__btn{
  margin: 24px auto 80px;
  background-color: #699778;
}

