#archive_videos {

    .archive_block {
      display: block;
      @media only screen and (max-width: 1025px) {
        display: none;
      }
    }
  
    .archive_hidden {
      display: none;
      @media only screen and (max-width: 1025px) {
        display: block;
      }
    }
  
    .color_invert {
      filter: invert(88%) sepia(61%) saturate(0%) hue-rotate(229deg) brightness(107%) contrast(101%);
    }
  }
  
  .archive_videos_page {
    background-color: #171314;
  
    .page_header {
      padding-top: 60px;
      @include desktop {
        padding: unset;
      }
  
      &__background {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: fit-content;
        line-height: normal;
        @media only screen and (max-width: 768px) {
          margin-bottom: 80px;
        }
  
        &--image-pc {
          object-fit: cover;
          height: 779px;
          @media only screen and (max-width: 1025px) {
            display: none;
          }
        }
  
        &--image-tablet {
          display: none;
          @media only screen and (max-width: 1025px) {
            display: flex;
            object-fit: cover;
            width: 100%;
            height: 600px;
          }
          @media only screen and (max-width: 599px) {
            display: none;
          }
        }
  
        &--image-mobile {
          position: relative;
          display: none;
          @media only screen and (max-width: 599px) {
            display: flex;
            object-fit: cover;
            width: 100%;
            height: 500px;
          }
        }
      }
    }
  
    .page_content {
      max-width: 1640px;
      margin-top: 80px;
      margin-bottom: 150px;
      @media only screen and (max-width: 1025px) {
        max-width: 772px;
        padding: unset;
        margin-right: auto;
        margin-left: auto;
      }
      @media only screen and (max-width: 768px) {
        margin-top: 0;
      }
      @media only screen and (max-width: 575px) {
        padding: unset;
      }
  
      .archive_videos {
        margin-bottom: 8.33333%;
        @media only screen and (max-width: 575px) {
          margin-right: 0;
          margin-left: 0;
        }
  
        .archive_videos_top {
          position: relative;
          height: 80px;
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 10px;
  
          &--text {
            position: absolute;
            top: -350px;
            left: 140px;
            @media only screen and (max-width: 1025px) {
              top: -300px;
              left: unset;
            }
            @media only screen and (max-width: 768px) {
              left: 3%;
            }
            @media only screen and (max-width: 575px) {
              left: 3%;
            }
  
            .videos_text1 {
              font-size: 40px;
              font-weight: bold;
              color: #ffffff;
              margin-bottom: 24px;
              @media only screen and (max-width: 1025px) {
                font-size: 32px;
              }
              @media only screen and (max-width: 575px) {
                font-size: 18px;
              }
            }
  
            .videos_text2 {
              margin-bottom: 100px;
              line-height: 24px;
              max-width: 812px;
              color: #ffffff;
              @media only screen and (max-width: 1025px) {
                font-size: 14px;
                max-width: 788px;
              }
              @media only screen and (max-width: 768px) {
                max-width: 630px;
                font-size: 14px;
              }
              @media only screen and (max-width: 575px) {
                max-width: 95%;
                font-size: 13px;
              }
            }
          }
        }
  
        .selects {
          margin: 0 0 0 auto;
          display: flex;
          flex-wrap: wrap;
          height: 32px;
          @media only screen and (max-width: 575px) {
            margin: unset;
          }
  
          .filter_category {
            background-color: #141414;
            max-width: 250px;
            border-radius: 20px;
            padding: 8px 16px;
            margin: 0 10px;
            @media only screen and (max-width: 575px) {
              width: 200px;
              margin: 8px 0;
            }
            @media only screen and (max-width: 360px) {
              width: 160px;
            }
          }
  
          .filter_year {
            background-color: #141414;
            max-width: 200px;
            border-radius: 20px;
            padding: 8px 16px;
            margin: 0 10px;
            @media only screen and (max-width: 575px) {
              width: 140px;
              margin: 8px 2px;
            }
          }
  
          .selected-option {
            transition: 0.2s;
            &.open{
              color: #848484;
              font-size: 14px;
              transition: 0.2s;
            }
          }
  
          .faux-select-category {
            width: 150px;
          }
  
          .faux-select-tag {
            width: 120px;
          }
  
          .faux-select-category, .faux-select-tag {
            list-style-type: none;
            text-align: left;
            cursor: pointer;
            display: inline-block;
            position: relative;
            @media only screen and (max-width: 575px) {
              width: 100%;
            }
  
            svg {
              position: absolute;
              top: 0;
              right: -3%;
              transition: 0.5s;
            }
  
            li {
              padding: 0;
              text-indent:1em;
              position:relative;
              color: #fff;
              font-size: 16px;
  
              .options {
                display: block;
                list-style-type: none;
                padding: 0;
                margin: 0.4em 0 0 0;
                background: transparent;
                position: absolute;
                width: 110%;
                max-height: 0px;
                overflow: hidden;
                border: 3px solid transparent;
                border-radius: 20px;
                top: 1.2em;
                left: -0.2em;
                transition: all ease 0.25s;
                z-index: 1;
                li {
                  padding: 0.3em 0;
                  margin: 1em 0;
                  position: relative;
                  &:before {
                    background: #FF2222;
                    transition-duration: 0.2s;
                    transition-property: transform;
                    transition-delay: 0s;
                    transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
                    width: 3px;
                    height: 100%;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    transform: scaleY(0);
                    content: "";
                  }
                  &:hover:before{
                    transition-duration: 0.2s;
                    transition-property: transform;
                    transition-delay: 0s;
                    transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
                    transform: scaleY(1);
                    transform-origin: center center;
                  }
                  &:last-of-type{
                    border-bottom: 0;
                  }
                }
              }
              .options.open{
                font-size: 16px;
                background: #141414;
                max-height: fit-content;
              }
  
              .selected {
                content: "";
                position: absolute;
                background: #FF2222;
                width: 3px;
                height: 100%;
                bottom: 0;
                left: 0;
              }
            }
          }
  
          .faux-select-category.open, .faux-select-tag.open{
            svg {
              color: #B1B1B1;
              transition: 0.5s;
              transform: rotateX(180deg);
            }
          }
  
          svg {
            color: #B1B1B1;
            vertical-align: middle;
          }
        }
      }
  
      .archive_videos_lists {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-column-gap: 40px;
        grid-row-gap: 60px;
        align-items: start;
        justify-items: center;
        padding-left: 0;
        @media only screen and (max-width: 1025px) {
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(2, 1fr);
        }
        @media only screen and (max-width: 875px) {
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(2, 1fr);
          grid-column-gap: 16px;
        }
        @media only screen and (max-width: 575px) {
          grid-template-columns: repeat(1, 1fr);
          grid-template-rows: repeat(1, 1fr);
        }
        
        .no_videos{
          color: #fff;
          font-size: 18px;
        }
  
        .videos_li {
          .archive_videos_title {
            position: relative;
  
            a {
              line-height: 1.5;
              font-size: 18px;
              font-weight: 500;
              color: #fff !important;
            }
  
            .videos_img {
              position: relative;
              max-width: 520px;
              height: 310px;
              margin: 0px auto;
              background: black;
              overflow: hidden;
              @media only screen and (max-width: 1025px) {
                max-width: 575px;
                height: 230px;
              }
              @media only screen and (max-width: 575px) {
                width: 100%;
                height: calc(100vw / 1.75);
              }
  
              .overlay_before{
                position: absolute;
                top: 0;
                left: -1px;
                width: 101%;
                height: 100%;
                display: block;
                opacity: 0.3;
                background: transparent linear-gradient(180deg, #00000000 0%, #000000 100%) 0%
                0% no-repeat padding-box;
                transition-duration: 0.3s;
              }
  
              .overlay_before + .videos_img img {
                transform: scale(1.1);
                transition-duration: 0.3s;
                opacity: 0.5;
              }
  
              img {
                height: 99%;
                object-fit: cover;
                transition-duration: 0.3s;
              }
            }
  
            .archive_videos_body {
              position: absolute;
              width: 90%;
              height: 54px;
              bottom: 5%;
              margin: 0px 16px 0px 16px;
  
              &__overflow {
                width: 100%;
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                @media only screen and (max-width: 1025px) {
                  font-size: 16px;
                }
              }
            }
          }
  
          .archive_videos_details {
            line-height: 1.5;
            margin: 8px 16px 5px 16px;
            color: #fff;
            letter-spacing: 0.025em;
            font-size: 14px;
            @media only screen and (max-width: 1025px) {
              font-size: 12px;
            }
            
            .videos_media {
              float: left;
            }
  
            .videos_date {
              width: auto;
              float: right;
            }
          }
        }
      }
  
      .archive_page_number_wapper {
        display: flex;
        justify-content: center;
        margin-top: 120px;
  
        // provided paginate_links()
        .archive_page_numbers {
          a.page-numbers {
            display: inline-block;
            width: 40px;
            height: 40px;
            padding: 15px 0px;
            border-radius: 0px;
            color: white;
            font-weight: 0;
            font-size: 14px;
            text-align: center;
            svg {
              display: inline-block;
            }
          }
  
          span.page-numbers.dots {
            color: white;
          }
  
          span.current, a.page-numbers:hover {
            width: 40px;
            height: 40px;
            padding: 15px 0px;
            border-radius: 0px;
            background: #F21616;
            text-align: center;
          }
        }
      }
    }
  }
  