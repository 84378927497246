// 3D parallax effect
.effects-parallax-wrapper {
  transform-style: preserve-3d;
  perspective: var(--perspective-origin, 1px);
}

.effects-parallax-3d {
  transform-style: preserve-3d;
  transform: translateZ(var(--parallax-depth, -1px)) scale(var(--parallax-scale, 2));
}
