.history_title {
  color: #242424;
  font-weight: 700;
  font-size: 140px;
  line-height: 207px;
  text-transform: uppercase;
  padding: 200px 120px 100px 0;
  border-right: 1px solid #e1e1e1;
  display: inline-block;
  margin-left: 215px;
  position: relative;

  @media screen and (max-width: 1024px) {
      margin-left: 20px;
      font-size: 60px;
      line-height: 80px;
      padding: 50px 0 50px 0;
      border-right: 0;
  }

  &::after {
    @include desktop {
      content: "沿革";
      display: block;
      color: #E1E1E1;
      font-weight: 700;
      font-size: 15px;
      line-height: 22px;
      position: absolute;
      right: 0;
      top: 50%;
      bottom: 0;
      margin-top: -5px;
      margin-right: 15px;
    }
  }
}

.history_wrapper {
  position: relative;
}

.history_background {
  background-color: #F8F8F8;
  position: absolute;
  margin-top: 300px;
  top: 0;
  left: 0;
  height: calc(100% - 450px);
  width: calc(100% - 215px);
  z-index: -1;

  @media screen and (max-width: 1024px) {
    margin-top: 85px;
    width: 100%;
    height: calc(100% - 200px);
  }
}

.history_block {
  border-top: 1px solid #e1e1e1;
  border-left: 1px solid #e1e1e1;
  padding: 125px 170px;
  background: white;
  flex-grow: 1;

  @media screen and (max-width: 1024px) {
    margin-left: 20px;
    padding: 50px 25px;
  }
}

.history_block_wrapper {
  display: flex;
}

.history_block_left {
  width: 215px;
  height: 100%;
  border-top: 1px solid #e1e1e1;

  @media screen and (max-width: 1024px) {
    width: 0;
  }
}

.history_item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 70px;

  @media screen and (max-width: 1024px) {
    // flex-direction: column;
    margin-bottom: 20px;
    display: grid;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__sp {
    display: flex;
  }

  &_detail {
    width: 100%;
  
    &_row {
      width: 100%;
      display: flex;
    }
  }
}

.history_item__left {
  color: #242424;
  font-size: 40px;
  padding-right: 95px;

  @media screen and (max-width: 1024px) {
    padding-bottom: 15px;
    font-size: 20px;
    padding-right: 15px;
  }
}

.history_item__middle {
  color: #242424;
  font-weight: 300;
  font-size: 15px;
  line-height: 22px;
  display: flex;
  flex-direction: column;
  width: 200px;

  @media screen and (max-width: 1024px) {
    font-size: 12px;
    width: 125px;
  }

  span {
    padding-bottom: 30px;
  }

  &--month {
    @media screen and (max-width: 1024px) {
      margin-bottom: 32px;
    }
  }

  &--enmonth {
    @media screen and (max-width: 1024px) {
      margin-bottom: 32px;
      height: 35px;
    }
  }
}

.history_item__right {
  color: #242424;
  font-weight: 300;
  font-size: 15px;
  line-height: 22px;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media screen and (max-width: 1024px) {
    font-size: 10px;
    font-weight: bold;
  }

  span {
    padding-bottom: 30px;
  }

  &--content {
    @media screen and (max-width: 1024px) {
      margin-bottom: 32px;
    }
  }

  &--encontent {
    @media screen and (max-width: 1024px) {
      margin-bottom: 32px;
      height: 35px;
    }
  }

  &--content:last-child {
    @media screen and (max-width: 1024px) {
      margin-bottom: 0;
    }
  }
}