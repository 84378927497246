@include desktop {
  /* Global engineering page */
  #page_title_culture {
    position: absolute;
    top: -560px;
    left: calc(56% - 594px);
    // width: 32px;
    font-size: 90px;
    color: white;
    z-index: 3;
    font-weight: bold;

    &::before {
      content: "OUR WAY OF WORKING";
      width: 217px;
      height: 0px;
      background-color: #fff;
      position: absolute;
      top: -33px;
      left: 20px;
      font-size: 9px;
      font-weight: 100;
    }
  }

  .culture_header_description {
    position: absolute;
    left: calc(56% - 660px);
    top: -501px;
    width: 616px;
    height: 338px;
    padding: 62px 39px 51px 59px;
    background: #1167f2;
    z-index: 2;
    color: #fff;
    -webkit-box-shadow: -2px 10px 4px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: -2px 10px 4px 0px rgba(0, 0, 0, 0.1);
    box-shadow: -2px 10px 4px 0px rgba(0, 0, 0, 0.1);
  }

  .culture_right__title {
    position: absolute;
    top: -230px;
    left: 770px;
    width: 550px;

    &--vline {
      width: 1px;
      height: 522px;
      background: #242424;
      display: block;
      position: absolute;
      top: -580px;
      right: 500px;
    }
  }

  .culture_header__background {
    background: url(../images/pc/culture/cl_main.jpg);
    background-size: cover;
    background-blend-mode: hard-light;
    height: 852px;
    width: 56.7%;
  }

  .culture_circle_img {
    position: relative;

    &::before {
      content: "";
      width: 54px;
      height: 54px;
      background-color: #242424;
      display: block;
      position: absolute;
      top: -235px;
      left: -142px;
      opacity: 0.5;
      mix-blend-mode: overlay;
      z-index: 2;
    }

    .culture_circles {
      position: absolute;
      top: -315px;
      left: -190px;
    }
  }

  .culture_credo__sec {
    background: url(../images/pc/about/vs_spiral.png);
    background-repeat: no-repeat;
    background-position: top center;
  }

  .culture_after_origin_header {
    margin-top: 350px;
  }

  .culture_after_origin_enheader {
    margin-top: 250px;
  }

  .culture_credo__title {
    font-size: 100px;
    font-weight: bold;
    letter-spacing: 0;
    position: absolute;
    top: -150px;
    left: 55%;
    z-index: 2;

    &::before {
      content: "OUR VALUES";
      font-size: 9px;
      color: #e1e1e1;
      position: absolute;
      top: 12px;
      left: -65px;
    }
  }

  .culture_kaisyaseido__sec {
    background: url(../images/pc/about/vs_spiral.png);
    background-repeat: no-repeat;
    background-position: bottom center;
  }

  .culture_kaisyaseido__title {
    font-size: 80px;
    font-weight: bold;
    position: absolute;
    top: -160px;
    width: 400px;

    &::before {
      content: "WHAT WE HAVE";
      font-size: 9px;
      color: #e1e1e1;
      position: absolute;
      top: 0px;
      left: -100px;
      letter-spacing: 1px;
    }
  }

  .culture_kaisyaseido__entitle {
    font-size: 80px;
    font-weight: bold;
    position: absolute;
    top: -160px;
    width: 500px;

    &::before {
      content: "WHAT WE HAVE";
      font-size: 9px;
      color: #e1e1e1;
      position: absolute;
      top: 0px;
      left: -100px;
      letter-spacing: 1px;
    }
  }

  .culture_event__sec {
    height: 700px;

    .culture_event__border1 {
      width: 100%;
      height: 50px;
      border-bottom: 0.5px solid #e1e1e1;
      position: absolute;
      top: 2650px;
    }

    .culture_event__border2 {
      width: 100px;
      height: 550px;
      border-left: 0.5px solid #e1e1e1;
      position: absolute;
      top: 2700px;
      left: calc(50% - 100px);
    }

    .culture_event__border3 {
      width: calc(92% - -25px);
      height: 416px;
      border-left: 0.5px solid #e1e1e1;
      position: absolute;
      top: 2284px;
      right: 0;
    }
    .inovation {
      font-size: 18px;
    }
  }

  .culture_events__title {
    font-size: 100px;
    font-weight: bold;
    letter-spacing: 0;
    position: absolute;

    &::before {
      content: "WHAT WE DO TOGETHER";
      font-size: 9px;
      color: #e1e1e1;
      position: absolute;
      top: 12px;
      left: -168px;
    }
  }

  .bgGray1 {
    width: calc(50% - 25px);
    height: 385px;
    background-color: #f8f8f8;
    position: absolute;
    top: 853px;
    border-bottom: 0.5px solid #e1e1e1;
  }

  .enbgGray1 {
    width: calc(50% - 25px);
    height: 185px;
    background-color: #f8f8f8;
    position: absolute;
    top: 853px;
    border-bottom: 0.5px solid #e1e1e1;
  }

  .bgGray2 {
    width: calc(97% - 25px);
    height: 3815px;
    background-color: #f8f8f8;
    position: absolute;
    top: 0;
  }

  .enbgGray2 {
    width: calc(96% - 25px);
    height: 1385px;
    background-color: #f8f8f8;
    position: absolute;
    top: 0;
  }

  .bgWhite1 {
    width: calc(92% - -25px);
    height: 385px;
    background-color: #fff;
    position: absolute;
    top: 1050px;
    right: 0;
    border-bottom: 0.5px solid #e1e1e1;
  }

  .enbgWhite1 {
    width: calc(92% - -25px);
    height: 385px;
    background-color: #fff;
    position: absolute;
    top: 1095px;
    right: 0;
    border-bottom: 0.5px solid #e1e1e1;
  }

  .culture_kaisyaseido__border1 {
    width: 100%;
    height: 50px;
    border-bottom: 0.5px solid #e1e1e1;
    position: absolute;
    top: 240px;
  }

  .culture_kaisyaseido__border2 {
    width: calc(50% - -210px);
    height: 761px;
    border-right: 0.5px solid #e1e1e1;
    position: absolute;
    top: 289px;
  }

  .culture_kaisyaseido__border3 {
    width: calc(50% - -210px);
    height: 50px;
    border-bottom: 0.5px solid #e1e1e1;
    position: absolute;
    top: 650px;
  }

  .carousel-item__description--culture {
    margin-top: -100px;
  }
}

.culture_header_description {
  @include desktop {
    position: absolute;
    left: calc(56% - 572px);
    top: -501px;
    width: 535px;
    height: 338px;
    padding: 62px 39px 51px 59px;
    // background: #1167F2;
    z-index: 2;
    color: #fff;
    -webkit-box-shadow: -2px 10px 4px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: -2px 10px 4px 0px rgba(0, 0, 0, 0.1);
    box-shadow: -2px 10px 4px 0px rgba(0, 0, 0, 0.1);
  }
  @include l-desktop {
    position: absolute;
    left: calc(56% - 660px);
    top: -501px;
    width: 616px;
    height: 338px;
    padding: 62px 39px 51px 59px;
    background: #1167f2;
    z-index: 2;
    color: #fff;
    -webkit-box-shadow: -2px 10px 4px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: -2px 10px 4px 0px rgba(0, 0, 0, 0.1);
    box-shadow: -2px 10px 4px 0px rgba(0, 0, 0, 0.1);
  }
}
.culture_header_endescription {
  @include desktop {
    position: absolute;
    left: calc(56% - 572px);
    top: -501px;
    width: 535px;
    height: 338px;
    padding: 105px 39px 51px 59px;
    background: #1167f2;
    z-index: 2;
    color: #fff;
    -webkit-box-shadow: -2px 10px 4px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: -2px 10px 4px 0px rgba(0, 0, 0, 0.1);
    box-shadow: -2px 10px 4px 0px rgba(0, 0, 0, 0.1);
  }

  @include l-desktop {
    position: absolute;
    left: calc(56% - 660px);
    top: -501px;
    width: 616px;
    height: 338px;
    padding: 105px 39px 51px 59px;
    background: #1167f2;
    z-index: 2;
    color: #fff;
    -webkit-box-shadow: -2px 10px 4px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: -2px 10px 4px 0px rgba(0, 0, 0, 0.1);
    box-shadow: -2px 10px 4px 0px rgba(0, 0, 0, 0.1);
  }
}

.culture_text {
  @include desktop {
    font-size: 14px;
    line-height: 1.4;
    letter-spacing: 0.03em;
    max-width: 518px;
    max-height: 225px;
    font-family: "Noto Sans JP", sans-serif;
  }
  @include l-desktop {
    font-size: 15px;
    line-height: 1.6;
    letter-spacing: 0.03em;
    max-width: 518px;
    max-height: 225px;
    font-family: "Noto Sans JP", sans-serif;
  }
}

.culture-carousel__item--info {
  display: -webkit-flex;
  -webkit-justify-content: center;
  -webkit-flex-direction: column;
  right: 0;
  top: -800px;
  position: absolute;
  height: 636px;
  width: calc(50% - -636px);
  background-color: #fff;
  border-bottom: 0.5px solid #e1e1e1;

  .culture-carousel__item--container {
    @include desktop {
      padding: 0px 193px 53px 56px;

      &-description {
        width: 460px;
        height: 450px;
        font-size: 15px;
        line-height: 1.6;
      }
    }

    @include l-desktop {
      padding: 0 161px 46px 121px;

      &-description {
        width: 460px;
        height: 450px;
        font-size: 15px;
        line-height: 1.6;
      }
    }
  }
}

.culture-carousel__item--eninfo {
  display: -webkit-flex;
  -webkit-justify-content: center;
  -webkit-flex-direction: column;
  right: 0;
  top: -800px;
  position: absolute;
  height: 436px;
  width: calc(50% - -636px);
  background-color: #fff;
  border-bottom: 0.5px solid #e1e1e1;

  .culture-carousel__item--container {
    @include desktop {
      padding: 0px 193px 53px 56px;

      &-endescription {
        width: 460px;
        height: 230px;
        font-size: 15px;
        line-height: 1.6;
      }
    }

    @include l-desktop {
      padding: 0 161px 46px 121px;

      &-description {
        width: 460px;
        height: 450px;
        font-size: 15px;
        line-height: 1.6;
      }
    }
  }
}

.culture_header {
  position: relative;
  display: block;
  // background-image: url(../images/pc/culture/cl_lines.png);
  background: url(../images/pc/culture/cl_sideline.png);
  background-repeat: no-repeat;
  background-position: top right;
  background-size: 44%;
  z-index: 1;

  &__owow {
    width: calc(50% - 520px);
    height: 1px;
    background-color: #fff;
    position: absolute;
    top: 268px;
    left: 0;
  }

  .culture-carousel__item {
    .culture-carousel__title {
      @include desktop {
        position: absolute;
        top: -765px;
        left: 5%;
        text-align: center;
        z-index: 1;
        font-size: 30px;
        font-weight: bold;
      }
      @include l-desktop {
        position: absolute;
        top: -765px;
        left: 12%;
        text-align: center;
        z-index: 1;
        font-size: 30px;
        font-weight: bold;
      }
    }

    .culture_title__flatorg {
      @include desktop {
        left: -50px;
      }
      @include l-desktop {
        left: 0;
      }
    }
    .culture_title__cs {
      @include desktop {
        left: -50px;
      }

      @include l-desktop {
        left: 0;
      }
    }
  }

  .culture_carousel__nav {
    @include desktop {
      position: absolute;
      top: -430px;
      left: 50px;
      width: 650px;
    }
    position: absolute;
    top: -430px;
    left: -46px;
    width: 650px;

    &::before {
      content: "";
      width: 404px;
      height: 48px;
      background-color: #030694;
      display: block;
      position: absolute;
      top: 10px;
      left: 140px;
      opacity: 0.2;
      mix-blend-mode: overlay;
      z-index: -1;
    }

    .carousel__nav__title {
      margin: 10px 0 0 30px;
    }
  }

  .culture_carousel__ennav {
    @include desktop {
      position: absolute;
      top: -430px;
      left: 20px;
      width: 650px;
    }

    position: absolute;
    top: -430px;
    left: -46px;
    width: 650px;

    &::before {
      content: "";
      width: 404px;
      height: 48px;
      background-color: #030694;
      display: block;
      position: absolute;
      top: 10px;
      left: 140px;
      opacity: 0.2;
      mix-blend-mode: overlay;
      z-index: -1;
    }

    .carousel__nav__title {
      margin: 10px 0 0 30px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .culture_header {
    padding-top: 60px;
    height: 100vh;
    &__background {
      position: absolute !important;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: url(../images/sp/culture/cl_main_sp.png);
      background-repeat: no-repeat;
      background-size: cover;
    }
    &__text-container {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      // align-items: center;
      height: calc(100vh - 60px);
    }
  }

  #page_title_culture {
    position: relative;
    overflow: visible; // Use this when the before/after subtitle message looks weird
    margin-top: auto;
    width: 100%;
    height: initial;
    padding: 20px 20px 20px 40px;
    color: white;
    font-size: 50px;
    line-height: 1.5;
    font-weight: bold;
    // @media screen and (max-width: 350px) { // iPhone SE too small for the space
    //     padding-top: 0;
    // }
    &::before {
      content: "";
      height: 183px;
      width: 1px;
      background-color: #fff;
      position: absolute;
      top: -200px;
      left: 21px;
      @media screen and (max-width: 350px) {
        top: 40px;
      }
    }

    &::after {
      position: absolute;
      top: 39px;
      left: -12px;
      transform: rotate(90deg);
      //width: 74px;
      color: #fff;
      font-size: 6px;
      font-weight: 100;
      content: "OUR WAY OF WORKING";
      @media screen and (max-width: 350px) {
        top: 265px;
      }
    }
  }

  .culture_header_description {
    color: #fff;
    display: flex;
    .culture_text {
      padding: 20px 20px 68px 40px;
      font-size: 12px;
      line-height: 1.5;
      font-weight: 300;
      max-width: 650px;
    }
  }
}

.carousel_culture-item {
  width: 100%;
  height: 10px;
}

.credo_c {
  height: 832px;
  background: url(../images/pc/about/vs_spiral.png);
  background-position: 15% 90%;
  position: relative;
  .credos_title {
    position: absolute;
    bottom: -181px;
    left: 70px;
    font-size: 70px;
    font-weight: bold;
    z-index: 10;

    &::after {
      content: "";
      width: 455px;
      height: 1px;
      background-color: #242424;
      position: absolute;
      top: 7px;
      left: 368px;
    }

    &::before {
      content: "OUR VALUES";
      position: absolute;
      top: 5px;
      left: 838px;
      font-size: 9px;
      font-weight: 100;
      width: 100%;
    }
  }
}

.credo_titleEn {
  bottom: -1040px;
}

@media only screen and (max-width: 1180px) {
  .credos_title {
    bottom: -1400px;
  }
  .credos_titleEn {
    bottom: -1140px;
  }
}

.credos_text__content {
  position: absolute;
  bottom: -640px;
  left: 440px;
  width: 578px;
  z-index: 10;
  // @media only screen and (max-width: 1180px) {
  //   bottom: -1620px;
  // }

  .vision_text {
    font-size: 15px;
    line-height: 1.6;
    letter-spacing: 0.03em;
    max-width: 773px;
    max-height: 475px;
  }
}
.credos_text__contentEn {
  bottom: -1425px;

  @media only screen and (max-width: 1180px) {
    bottom: -1530px;
  }
}

.credo_button-culture {
  display: flex;
  position: relative;
  justify-content: space-around;
  font-size: 18px;
  bottom: 155px;
}

@media screen and (max-width: 1024px) {
  .credo_c {
    text-align: center;
    margin: 30px 0 30px;
    // position: absolute;
    // top: 1650px;

    .credos_title {
      font-size: 70px;
      font-weight: bold;
    }

    .credos_text__content {
      padding-top: 40px;
      padding-bottom: 42px;
      font-size: 12px;
      line-height: 1.5;
      letter-spacing: 0.03em;
    }
  }
  .credo_button-culture {
    display: grid;
    position: relative;
    justify-content: space-around;
    font-size: 18px;
    bottom: 0px;
    margin-bottom: 35px;
  }
}
