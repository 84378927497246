.localcurrency {
  .page_header {
    padding-top: 60px;
    @include desktop {
      padding: unset;
    }

    &__background {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      min-height: 908px;
      line-height: normal;
      @media only screen and (max-width: 1024px) {
        max-height: 1024px;
      }
      @media only screen and (max-width: 767px) {
        min-height: 829px;
      }

      &--image {
        position: relative;
        object-fit: cover;
        min-height: 908px;
        @media only screen and (max-width: 1024px) {
          position: absolute;
          min-height: 1024px;
          top: -12.8%;
        }
        @media only screen and (max-width: 767px) {
          position: absolute;
          max-height: 829px;
          top: -23.5%;
        }
      }
      &--overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(180deg, #00000000, #000000);
        opacity: 53%;
        @media only screen and (max-width: 1024px) {
          max-height: 1024px;
        }
        @media only screen and (max-width: 767px) {
          max-height: 829px;
        }
      }
      &--text {
        position: absolute;
        top: 45%;
        left: 10%;
        @media only screen and (max-width: 1024px) {
          left: unset;
          top: 35%;
        }
        @media only screen and (max-width: 767px) {
          left: 50%;
          transform: translateX(-50%);
          width: 74%;
          top: 35%;
        }
      }
      &--title {
        font-size: 40px;
        font-weight: bold;
        color: white;
        line-height: 55px;
        @media only screen and (max-width: 1024px) {
          font-size: 30px;
          line-height: 40px;
          text-align: center;
        }
        @media only screen and (max-width: 767px) {
          font-size: 20px;
          line-height: 25px;
          font-weight: bold;
          text-align: center;
        }
      }
      &--body {
        font-size: 24px;
        color: white;
        padding-top: 43px;
        letter-spacing: 2px;
        @media only screen and (min-width: 1025px) {
          br {
            display: none;
          }
        }
        @media only screen and (max-width: 1024px) {
          padding-top: 40px;
          font-size: 20px;
          line-height: normal;
          text-align: center;
        }
        @media only screen and (max-width: 767px) {
          padding-top: 20px;
          font-size: 16px;
          line-height: normal;
          text-align: center;
          letter-spacing: unset;
        }
      }
      &--area {
        padding-top: 118px;
        @media only screen and (max-width: 1024px) {
          padding-top: 110px;
        }
        @media only screen and (max-width: 767px) {
          padding-top: 160px;
        }
      }
      &--logo {
        position: absolute;
        bottom: 20px;
        right: 20px;
        width: 100px;
        @media only screen and (max-width: 1024px) {
          right: unset;
          text-align: center;
          width: 95px;
        }
        @media only screen and (max-width: 767px) {
          right: unset;
          text-align: center;
          width: 60px;
        }
      }
    }
    &__title {
      font-size: 48px;
      font-weight: 500;
      line-height: 58px;
      letter-spacing: -2.21px;
      margin-top: 80px;
    }

    &__sub_title {
      font-size: 26px;
      line-height: 31px;
      letter-spacing: 0px;
      margin-top: 18px;
    }

    .for_phone_title {
      display: none;
      @media only screen and (max-width: 767px) {
        display: block;
      }
    }

    .p-inner {
      max-width: 1366px;
      padding: 0 20px;
      @media only screen and (max-width: 1024px) {
        padding: 0 60px;
      }
    }
  }
  &__background--inner {
    padding-right: 29px;
    text-align: center;
  }
  .inquiry_btn {
    width: 329px;
    background-color: #d51b20;
    // margin-left: auto;
    // margin-right: auto;
    @media only screen and (max-width: 1024px) {
      margin-left: auto;
      margin-right: auto;
      width: 300px;
      float: none;
    }
    @media only screen and (max-width: 767px) {
      width: 200px;
    }
  }
  .inquiry_btn2 {
    width: 320px;
    background-color: #d51b20;
    margin-left: auto;
    margin-right: auto;
    @media only screen and (max-width: 1024px) {
      width: 300pxpx;
      float: none;
    }
    @media only screen and (max-width: 767px) {
      width: 200px;
      margin-bottom: 65px;
    }
  }
  .inquiry {
    font-family: Noto Sans CJK JP;
    text-align: center;
    font-weight: bold;
    color: white;
    font-size: 18px;
    line-height: 80px;
    @media only screen and (max-width: 1024px) {
      font-size: 18px;
      line-height: 70px;
    }
    @media only screen and (max-width: 767px) {
      font-size: 14px;
      line-height: 40px;
    }
  }
  .coin_logo {
    width: 71px;
    height: 79x;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 100px;
    @media only screen and (max-width: 767px) {
      width: 30px;
      height: 30px;
      margin-bottom: 80px;
    }
  }
  .introduction_box {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  .introduction_text {
    font-size: 130px;
    color: #f5f5f5;
    font-weight: bold;
    line-height: 50px;
    padding: 0 20px;
    @media only screen and (max-width: 1024px) {
      font-size: 80px;
      line-height: 25px;
    }
    @media only screen and (max-width: 767px) {
      font-size: 40px;
      line-height: 10px;
    }
  }
  .sustainable_coin_text {
    font-family: Noto Sans CJK JP;
    font-size: 40px;
    font-weight: bold;
    color: #32482e;
    @media only screen and (max-width: 1024px) {
      font-size: 32px;
    }
    @media only screen and (max-width: 767px) {
      font-size: 23px;
      line-height: 28px;
    }
  }
  .line_stick1 {
    border-right: 1px solid #c7c7c7;
    height: 200px;
    width: 10px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 90px;
    margin-bottom: 74px;
    @media only screen and (max-width: 1024px) {
      border-right: 1px solid #c7c7c7;
      height: 150px;
      width: 5px;
      margin-top: 50px;
      margin-bottom: 50px;
    }
    @media only screen and (max-width: 767px) {
      border-right: 1px solid #c7c7c7;
      height: 70px;
      margin-top: 35px;
      margin-bottom: 35px;
    }
  }
  .line_stick2 {
    border-bottom: 4px solid #32482e;
    width: 80px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 36px;
    margin-bottom: 100px;
    @media only screen and (max-width: 767px) {
      padding-top: 15px;
      width: 32px;
      margin-bottom: 50px;
    }
  }
  .three_service_content {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .service {
    border: 4px solid #306d3e;
    border-radius: 30px 30px 30px 30px;
    text-align: center;
    margin-left: 30px;
    margin-right: 30px;
    width: 230px;
    padding-top: 47px;
    padding-bottom: 46px;
    @media only screen and (max-width: 1024px) {
      border: 2px solid #306d3e;
      width: 160px;
      padding-top: 33px;
      padding-bottom: 32px;
      margin-left: 15px;
      margin-right: 15px;
      border-radius: 20px;
    }
    @media only screen and (max-width: 767px) {
      border: 1px solid #306d3e;
      width: 92px;
      padding-top: 18px;
      padding-bottom: 18px;
      margin-left: 5px;
      margin-right: 5px;
      border-radius: 15px;
    }
  }
  .service_text {
    font-family: Noto Sans CJK JP;
    font-size: 26px;
    font-weight: bold;
    color: #306d3e;
    @media only screen and (max-width: 1024px) {
      font-size: 18px;
    }
    @media only screen and (max-width: 767px) {
      font-size: 10px;
    }
  }
  .three_content_text {
    font-family: Noto Sans CJK JP;
    font-size: 36px;
    font-weight: bold;
    color: #32482e;
    text-align: center;
    padding-top: 100px;
    @media only screen and (max-width: 1024px) {
      font-size: 28px;
      padding-top: 70px;
    }
    @media only screen and (max-width: 767px) {
      font-size: 18px;
      text-align: center;
      width: 156px;
      height: auto;
      margin: auto;
      padding-top: 40px;
      line-height: 30px;
    }
  }
  .page_content {
    max-width: 1240px;
    padding-top: 200px;
    margin-bottom: 150px;
    @media only screen and (max-width: 1024px) {
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 150px;
    }
    @media only screen and (max-width: 767px) {
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 60px;
    }
    &__header {
      font-size: 36px;
      font-weight: bold;
      letter-spacing: 0px;
      line-height: 28px;
    }
    &__title {
      font-size: 25px;
      font-weight: bold;
      letter-spacing: 0px;
      line-height: 34px;
      margin-bottom: 40px;
      padding-top: 12px;
      @media only screen and (max-width: 1024px) {
        padding-left: 40px;
      }
      @media only screen and (max-width: 767px) {
        padding-left: 0px;
        padding-top: 40px;
        font-size: 20px;
        line-height: 30px;
      }
    }

    &__text {
      font-size: 17px;
      line-height: 28px;
      letter-spacing: 0px;
      margin-bottom: 28px;
      @media only screen and (max-width: 1024px) {
        padding-left: 40px;
        padding-right: 40px;
      }
      @media only screen and (max-width: 767px) {
        padding-left: 0px;
        padding-right: 0px;
        margin-bottom: 30px;
      }
    }

    &__img {
      width: 784px;
      height: 606px;
      margin-top: 50px;
      margin-left: auto;
      margin-right: auto;
      @media only screen and (max-width: 1024px) {
        width: 728px;
        height: 562px;
      }
      @media only screen and (max-width: 767px) {
        width: 374px;
        height: 289px;
      }
      &__text {
        padding-top: 50px;
        padding-bottom: 50px;
        text-align: center;
        @media only screen and (max-width: 767px) {
          font-size: 10px;
          padding-top: 30px;
          padding-bottom: 30px;
        }
      }
    }
    .ses__maintitle {
      // position: relative;
      font-size: 36px;
      font-weight: bold;
      letter-spacing: 0px;
      line-height: normal;
      padding: 128px 0px;
      @media only screen and (max-width: 1024px) {
        padding-top: 82px;
        padding-bottom: 85px;
        padding-left: 20px;
        padding-right: 85px;
      }
      @media only screen and (max-width: 767px) {
        padding-right: 0px;
        padding-top: 70px;
        padding-bottom: 40px;
        font-size: 22px;
      }
    }
    .page_content__for_phone {
      @include tablet {
        display: none;
      }
    }
    .page_content__for_phone_btn {
      text-align: center;
    }
    .txt-hide3 {
      display: none;
    }
    .read_more_btn3 {
      padding-top: 12px;
      padding-bottom: 12px;
      padding-left: 50px;
      padding-right: 50px;
      background-color: #498946;
      color: #ffffff;
      size: 18;
      border-radius: 10px 10px 10px 10px;
      transition: 0.5s;
      -erbkit-transition: 0.5s;
      .close3 {
        display: none;
        &-click {
          display: block !important;
        }
      }
      .open3 {
        display: block;
        &-click {
          display: none !important;
        }
      }
    }
    .for_tablet_phone {
      @include desktop {
        display: none;
      }
    }
    .for_first_local {
      @media only screen and (max-width: 767px) {
        display: none;
      }
    }
    .for_local {
      @media only screen and (max-width: 1024px) {
        display: none;
      }
    }
    .ses {
      position: relative;
      margin-bottom: 80px;
      @include desktop {
      }
      &__title {
        font-size: 25px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 28px;
        margin-bottom: 40px;
        @media only screen and (max-width: 767px) {
          font-size: 22px;
        }
      }
      &__subtitle {
        font-size: 17px;
        font-weight: bold;
        line-height: 28px;
        letter-spacing: 0px;
        margin-bottom: 12px;
      }
      &__paragraph1 {
        display: flex;
        @media only screen and (max-width: 1024px) {
          display: block;
        }
      }
      .read_more_btn_container {
        text-align: center;
      }
      .txt-hide {
        display: none;
      }
      .txt-hide2 {
        display: none;
      }
      .read_more_btn {
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 50px;
        padding-right: 50px;
        background-color: #498946;
        color: #ffffff;
        size: 18;
        border-radius: 10px 10px 10px 10px;
        transition: 0.5s;
        -erbkit-transition: 0.5s;
        .close {
          display: none;
          &-click {
            display: block !important;
          }
        }
        .open {
          display: block;
          &-click {
            display: none !important;
          }
        }
      }
      .read_more_btn2 {
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 50px;
        padding-right: 50px;
        background-color: #498946;
        color: #ffffff;
        size: 18;
        border-radius: 10px 10px 10px 10px;
        transition: 0.5s;
        -erbkit-transition: 0.5s;
        .close2 {
          display: none;
          &-click {
            display: block !important;
          }
        }
        .open2 {
          display: block;
          &-click {
            display: none !important;
          }
        }
      }
      &__paragraph2 {
        display: flex;
        flex-direction: row-reverse;
        @media only screen and (max-width: 1024px) {
          display: block;
        }
      }
      &__paragraph3 {
        display: flex;
      }
      &__paragraph1__text1 {
        font-size: 17px;
        line-height: 28px;
        letter-spacing: 0px;
        margin-bottom: 28px;
        @media only screen and (max-width: 1024px) {
          width: auto;
        }
      }
      &__paragraph1__container {
        padding-left: 66px;
        padding-right: 47px;
        width: 52%;
        @media only screen and (max-width: 1024px) {
          padding-left: 80px;
          padding-right: 60px;
          width: auto;
        }
      }
      &__paragraph2__text2 {
        font-size: 17px;
        line-height: 28px;
        letter-spacing: 0px;
        float: right;
        @media only screen and (max-width: 1024px) {
          float: none;
          width: auto;
          padding-left: 0px;
        }
      }
      &__paragraph2__container {
        padding-left: 66px;
        width: 52%;
        @media only screen and (max-width: 1024px) {
          padding: 0px;
          width: auto;
          padding-left: 80px;
          padding-right: 40px;
        }
        @media only screen and (max-width: 767px) {
          padding-left: 52px;
          padding-right: 20px;
        }
      }
      &__paragraph3__text3 {
        font-size: 17px;
        line-height: 28px;
        letter-spacing: 0px;
        margin-bottom: 28px;
        @media only screen and (max-width: 1024px) {
          width: auto;
        }
      }
      &__paragraph3__container {
        padding-top: 80px;
        padding-left: 66px;
        padding-right: 60px;
        width: 52%;
        @media only screen and (max-width: 1024px) {
          padding: 0px;
          width: 100%;
          padding-left: 60px;
          padding-right: 20px;
        }
        @media only screen and (max-width: 767px) {
          padding-left: 52px;
          padding-right: 20px;
        }
      }
      &__img1 {
        width: 560px;
        margin-left: auto;
        @media only screen and (max-width: 1024px) {
          margin-left: auto;
          margin-right: auto;
        }
        @media only screen and (max-width: 767px) {
          width: 100%;
        }
      }
      &__img2 {
        width: 560px;
        @media only screen and (max-width: 1024px) {
          margin-left: auto;
          margin-right: auto;
        }
        @media only screen and (max-width: 767px) {
          width: 100%;
          padding-left: 52px;
          padding-right: 20px;
          padding-top: 40px;
        }
      }
      &__img3 {
        width: 500px;
        margin-left: auto;
        @media only screen and (max-width: 1024px) {
          margin-left: auto;
          margin-right: auto;
        }
        @media only screen and (max-width: 767px) {
          width: 100%;
        }
      }
      .ses-one {
        &::before {
          content: "1";
          position: absolute;
          top: -20px;
          left: -20px;
          font-family: Noto Sans CJK JP;
          font-size: 150px;
          font-weight: bold;
          color: #efefef;
          z-index: -1;
          @media only screen and (max-width: 1024px) {
            left: 14px;
            top: -10px;
            font-size: 120px;
            z-index: index none;
          }
          @media only screen and (max-width: 767px) {
            top: 30px;
            left: 0px;
            font-size: 100px;
          }
        }
      }
      .ses-two {
        padding-top: 70px;
        @media only screen and (max-width: 1024px) {
          padding-top: 0px;
          padding-left: 0px;
        }
        &::before {
          content: "2";
          position: absolute;
          font-family: Noto Sans CJK JP;
          font-size: 150px;
          font-weight: bold;
          color: #efefef;
          z-index: -1;
          top: 50px;
          transform: translateX(-50%);
          @media only screen and (max-width: 1024px) {
            top: -10px;
            left: 14px;
            font-size: 120px;
            transform: none;
          }
          @media only screen and (max-width: 767px) {
            top: 30px;
            left: 0px;
            font-size: 100px;
          }
        }
      }
      .ses-three {
        &::before {
          content: "3";
          position: absolute;
          top: 60px;
          left: -5px;
          font-family: Noto Sans CJK JP;
          font-size: 150px;
          font-weight: bold;
          color: #efefef;
          z-index: -1;
          @media only screen and (max-width: 1024px) {
            top: -10px;
            left: 14px;
            font-size: 120px;
            @media only screen and (max-width: 767px) {
              top: 30px;
              left: 0px;
              font-size: 100px;
            }
          }
        }
      }
    }
    .line_stick3 {
      border-bottom: 1px solid #c7c7c7;
      width: 1022px;
      margin-left: auto;
      margin-right: auto;
      padding-top: 100px;
      margin-bottom: 100px;
      @media only screen and (max-width: 1024px) {
        width: auto;
      }
      @media only screen and (max-width: 767px) {
        border-bottom: 1px solid #c7c7c7;
        width: auto;
        padding-top: 0px;
        margin-bottom: 64px;
      }
    }
    .line_stick4 {
      border-bottom: 1px solid #c7c7c7;
      width: 1022px;
      margin-left: auto;
      margin-right: auto;
      padding-top: 100px;
      margin-bottom: 200px;
      @media only screen and (max-width: 1024px) {
        width: auto;
      }
      @media only screen and (max-width: 767px) {
        border-bottom: 1px solid #c7c7c7;
        width: auto;
        padding-top: 0px;
        margin-bottom: 64px;
      }
    }
    .sustainable_coin_title {
      font-family: Noto Sans CJK JP;
      font-size: 40px;
      font-weight: bold;
      color: #32482e;
      text-align: center;
      padding-bottom: 99px;
      line-height: normal;
      @media only screen and (max-width: 1024px) {
        font-size: 32px;
        padding-bottom: 60px;
      }
      @media only screen and (max-width: 767px) {
        font-size: 20px;
        padding-bottom: 42px;
      }
    }
    .coin_merit {
      // padding-left: 133px;
      padding-bottom: 200px;
      @media only screen and (max-width: 1024px) {
        padding-left: 0px;
      }
      @media only screen and (max-width: 767px) {
        padding-left: 0px;
        padding-bottom: 100px;
      }
      @media only screen and (max-width: 767px) {
        padding-bottom: 0px;
      }
      &__title {
        font-family: Noto Sans CJK JP;
        font-size: 25px;
        font-weight: bold;
        line-height: 32px;
        padding-bottom: 50px;
        padding-top: 50px;
        text-align: center;
        @media only screen and (max-width: 1024px) {
          font-size: 25px;
          padding-bottom: 50px;
        }
        @media only screen and (max-width: 767px) {
          font-size: 18px;
        }
      }
      &__container1 {
        display: flex;
        justify-content: center;
        padding-bottom: 100px;
        @media only screen and (max-width: 1024px) {
        }
        @media only screen and (max-width: 767px) {
          display: block;
          padding-bottom: 0px;
        }
      }
      &__container2 {
        display: flex;
        justify-content: center;
        padding-bottom: 80px;
        @media only screen and (max-width: 1024px) {
        }
        @media only screen and (max-width: 767px) {
          display: block;
          margin-left: 60px;
        }
      }
      &__forsmallphone {
        @media only screen and (max-width: 1024px) {
          margin-left: auto;
          margin-right: auto;
        }
        @media only screen and (max-width: 767px) {
          display: flex;
          justify-content: center;
        }
      }
      &__item {
        position: relative;
        max-width: 100px;
        margin-right: 10%;
        margin-left: 10%;
        @media only screen and (max-width: 1024px) {
        }
        @media only screen and (max-width: 767px) {
          text-align: center;
          padding-bottom: 100px;
          margin-left: 30px;
          margin-right: 30px;
        }
      }
      &__text {
        position: absolute;
        font-size: 17px;
        font-weight: 700;
        text-align: center;
        line-height: normal;
        width: 200px;
        left: 50%;
        margin-top: 20px;
        transform: translateX(-50%);
        @media only screen and (max-width: 767px) {
          font-size: 12px;
        }
      }
    }
    .step_page {
      padding-bottom: 240px;
      display: flex;
      justify-content: center;
      @media only screen and (max-width: 767px) {
        padding-bottom: 128px;
      }
    }
    .step_page__background {
      &--text {
        width: 60%;
        position: relative;
        top: 0;
        left: 0;
        margin: 0;
        @media only screen and (max-width: 1024px) {
          width: 83%;
        }
      }
      &--image {
        position: absolute;
        @media only screen and (max-width: 1024px) {
          width: 648px;
          padding-top: 20px;
        }
        @media only screen and (max-width: 767px) {
          display: none;
        }
      }
      &--foot_title {
        font-family: Noto Sans CJK JP;
        font-size: 25px;
        font-weight: bold;
        // padding-left: 133px;
        text-align: center;
        @media only screen and (max-width: 1024px) {
          // padding-left: 20px;
          padding-bottom: 90px;
        }
        @media only screen and (max-width: 767px) {
          padding-bottom: 20px;
          font-size: 18px;
        }
      }
      &--container {
        display: grid;
        grid-template-columns: 2fr 2fr;
        padding-top: 100px;
        @media only screen and (max-width: 1024px) {
          padding-top: 0px;
        }
        @media only screen and (max-width: 767px) {
          display: block;
        }
      }
      &--grid2 {
        border-left: 2px solid #c7c7c7;
        @media only screen and (max-width: 767px) {
          border-left: none;
        }
      }
      &--grid4 {
        border-left: 2px solid #c7c7c7;
        padding-bottom: 20px;
        @media only screen and (max-width: 767px) {
          border-left: none;
          padding-bottom: 0px;
        }
      }
      &--foot_subtitle1 {
        position: relative;
        font-family: Noto Sans CJK JP;
        font-size: 25px;
        font-weight: bold;
        color: #498946;
        padding-top: 81px;
        padding-bottom: 20px;
        border-bottom: 2px solid #c7c7c7;
        max-height: 140px;
        &::after {
          content: "";
          position: absolute;
          width: 10px;
          height: 10px;
          display: inline-block;
          -moz-border-radius: 7.5px;
          -webkit-border-radius: 7.5px;
          border-radius: 7.5px;
          background-color: #c7c7c7;
          bottom: -1px;
          right: -1px;
          transform: translate(50%, 50%);
          @media only screen and (max-width: 767px) {
            bottom: unset;
            left: -11px;
          }
        }
        @media only screen and (max-width: 1024px) {
          font-size: 22px;
          padding-top: 30px;
        }
        @media only screen and (max-width: 767px) {
          padding-top: 30px;
          padding-left: 18px;
          border-bottom: none;
          border-left: 2px solid #c7c7c7;
        }
      }
      &--foot_subtitle2 {
        position: relative;
        font-family: Noto Sans CJK JP;
        font-size: 25px;
        font-weight: bold;
        color: #498946;
        text-align: right;
        padding-top: 265px;
        padding-bottom: 20px;
        border-bottom: 2px solid #c7c7c7;
        max-height: 324px;
        &::after {
          content: "";
          position: absolute;
          width: 10px;
          height: 10px;
          display: inline-block;
          -moz-border-radius: 7.5px;
          -webkit-border-radius: 7.5px;
          border-radius: 7.5px;
          background-color: #c7c7c7;
          bottom: -1px;
          left: -11px;
          transform: translate(50%, 50%);
          @media only screen and (max-width: 767px) {
            bottom: unset;
          }
        }
        @media only screen and (max-width: 1024px) {
          padding-top: 124px;
          font-size: 22px;
        }
        @media only screen and (max-width: 767px) {
          font-size: 22px;
          padding-top: 40px;
          padding-left: 18px;
          border-bottom: none;
          text-align: left;
          border-left: 2px solid #c7c7c7;
        }
      }
      &--foot_subtitle3 {
        position: relative;
        font-family: Noto Sans CJK JP;
        font-size: 25px;
        font-weight: bold;
        color: #498946;
        padding-top: 100px;
        padding-bottom: 20px;
        border-bottom: 2px solid #c7c7c7;
        max-height: 160px;
        &::after {
          content: "";
          position: absolute;
          width: 10px;
          height: 10px;
          display: inline-block;
          -moz-border-radius: 7.5px;
          -webkit-border-radius: 7.5px;
          border-radius: 7.5px;
          background-color: #c7c7c7;
          bottom: -1px;
          right: -1px;
          transform: translate(50%, 50%);
          @media only screen and (max-width: 767px) {
            bottom: unset;
            left: -11px;
          }
        }
        @media only screen and (max-width: 1024px) {
          padding-top: 9px;
          font-size: 22px;
        }
        @media only screen and (max-width: 767px) {
          font-size: 22px;
          padding-top: 40px;
          padding-left: 18px;
          border-bottom: none;
          border-left: 2px solid #c7c7c7;
        }
      }
      &--foot_subtitle4 {
        position: relative;
        font-family: Noto Sans CJK JP;
        font-size: 25px;
        font-weight: bold;
        color: #498946;
        text-align: right;
        padding-top: 311px;
        padding-bottom: 20px;
        border-bottom: 2px solid #c7c7c7;
        max-height: 370px;
        &::before {
          content: "";
          position: absolute;
          width: 10px;
          height: 10px;
          display: inline-block;
          -moz-border-radius: 7.5px;
          -webkit-border-radius: 7.5px;
          border-radius: 7.5px;
          background-color: #c7c7c7;
          bottom: -1px;
          left: -11px;
          transform: translate(50%, 50%);
          @media only screen and (max-width: 767px) {
            bottom: unset;
            left: -11px;
          }
        }
        @media only screen and (max-width: 1024px) {
          padding-top: 183px;
          font-size: 22px;
        }
        @media only screen and (max-width: 767px) {
          font-size: 22px;
          padding-top: 40px;
          padding-left: 18px;
          border-bottom: none;
          border-left: 2px solid #c7c7c7;
          text-align: left;
        }
      }
      &--foot_text1 {
        position: relative;
        font-family: Noto Sans CJK JP;
        font-size: 22px;
        font-weight: bold;
        padding-top: 20px;
        padding-right: 20px;
        max-height: 118px;
        line-height: 28px;
        @media only screen and (max-width: 1024px) {
          font-size: 18px;
        }
        @media only screen and (max-width: 767px) {
          font-size: 17px;
          padding-left: 18px;
          border-left: 2px solid #c7c7c7;
          padding-top: 0;
          position: unset;
        }
      }
      &--foot_text2 {
        font-family: Noto Sans CJK JP;
        font-size: 22px;
        font-weight: bold;
        padding-top: 20px;
        padding-left: 20px;
        max-height: 118px;
        line-height: 28px;
        @media only screen and (max-width: 1024px) {
          font-size: 18px;
        }
        @media only screen and (max-width: 767px) {
          font-size: 17px;
          padding-left: 18px;
          border-left: 2px solid #c7c7c7;
          padding-top: 0;
        }
      }
      &--foot_text3 {
        position: relative;
        font-family: Noto Sans CJK JP;
        font-size: 22px;
        font-weight: bold;
        padding-top: 20px;
        padding-right: 20px;
        max-height: 118px;
        line-height: 28px;
        @media only screen and (max-width: 1024px) {
          font-size: 18px;
        }
        @media only screen and (max-width: 767px) {
          font-size: 17px;
          padding-left: 18px;
          border-left: 2px solid #c7c7c7;
          padding-top: 0;
          position: unset;
        }
      }
      &--foot_text4 {
        font-family: Noto Sans CJK JP;
        font-size: 22px;
        font-weight: bold;
        padding-top: 20px;
        padding-left: 20px;
        padding-bottom: 172px;
        max-height: 118px;
        line-height: 28px;
        @media only screen and (max-width: 1024px) {
          font-size: 18px;
          padding-bottom: 100px;
        }
        @media only screen and (max-width: 767px) {
          font-size: 17px;
          padding-left: 18px;
          border-left: 2px solid #c7c7c7;
          padding-top: 0;
          position: unset;
          padding-bottom: 30px;
        }
      }
    }
    .flow_content {
      display: flex;
      flex-direction: row;
      justify-content: center;
      @media only screen and (max-width: 1024px) {
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
      }
    }
    .flow {
      border: 4px solid #306d3e;
      border-radius: 30px 30px 30px 30px;
      text-align: center;
      width: 220px;
      padding-top: 47px;
      padding-bottom: 46px;
      @media only screen and (max-width: 1024px) {
        border: 4px solid #306d3e;
        border-radius: 20px 20px 20px 20px;
        text-align: center;
        width: 155px;
        padding-top: 28px;
        padding-bottom: 28px;
      }
      @media only screen and (max-width: 767px) {
        border: 3px solid #306d3e;
        border-radius: 20px 20px 20px 20px;
        text-align: center;
        width: 180px;
        padding-top: 28px;
        padding-bottom: 28px;
      }
    }
    .flow_text {
      font-family: Noto Sans CJK JP;
      font-size: 26px;
      font-weight: bold;
      color: #306d3e;
      @media only screen and (max-width: 1024px) {
        font-size: 14px;
      }
    }
    .flow_text_arrow {
      font-family: Noto Sans CJK JP;
      font-size: 28px;
      font-weight: bold;
      color: #1d3e1c;
      margin-top: auto;
      margin-bottom: auto;
      margin-left: 20px;
      margin-right: 20px;
      transform: scale(0.9, 1.7);
      @media only screen and (max-width: 1024px) {
        transform: rotate(90deg) scale(0.9, 1.7);
        padding-top: 28px;
        padding-bottom: 28px;
      }
    }
    .caution_container {
      padding-left: 253px;
      padding-top: 87px;
      padding-bottom: 220px;
      @media only screen and (max-width: 1024px) {
        padding-left: 60px;
        padding-right: 40px;
        padding-top: 70px;
        padding-bottom: 210px;
      }
      @media only screen and (max-width: 767px) {
        padding-left: 60px;
        padding-right: 40px;
        padding-top: 70px;
        padding-bottom: 100px;
      }
    }
    .caution_text {
      font-size: 25px;
      font-weight: bold;
    }
    .caution_smalltext {
      font-size: 17px;
      line-height: 28px;
      padding-top: 36px;
    }
    .foot_page_container {
      padding-left: 113px;
      padding-right: 113px;
      padding-bottom: 127px;
      @media only screen and (max-width: 1024px) {
        padding-right: 46px;
        padding-left: 40px;
      }
      @media only screen and (max-width: 767px) {
        padding-right: 20px;
        padding-left: 20px;
      }
      &__title {
        font-size: 25px;
        font-weight: bold;
        letter-spacing: 0px;
        line-height: 28px;
        margin-bottom: 40px;
        padding-top: 60px;
        @media only screen and (max-width: 1024px) {
          font-size: 20px;
        }
      }
      &__text {
        font-size: 17px;
        line-height: 28px;
        letter-spacing: 0px;
        margin-bottom: 28px;
      }
    }
  }
  .lc-link {
    color: #007bff;
  }
}
