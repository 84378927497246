.l-footer{
    .footer__holiday{
        display: block;
        font-size: 12px;
        text-align: center;
        @include desktop{
            text-align: left;
            white-space: nowrap;
        }
        .text{
            font-size: 12px;
            line-height: 1.6;
            margin-bottom: -3.6px;
            margin-top: 14.4px;
            letter-spacing: 0.05em;
            color: #f7f7f7;
        }
        .text-one {
            color: #999999;
            font-weight: 500;
            margin-bottom: 12px;
            margin-top: 40px ;
            line-height: unset;
        }
        .text-two {
            color: #FFFFFF ;
            margin-bottom: 8px;
            margin-top: unset;
        }
        .text-three {
            color: #FFFFFF;
            letter-spacing: 0px;
            margin-top: unset;
        }
    }
}
