.aligncenter{
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.alignright{
  float: right;
  margin-left: 1.5em;
}

.alignleft{
  float: left;
  margin-right: 1.5em;
}

@media (max-width: 575px){
  .alignright{
    float: none;
    margin-left: 0;
  }
  .alignleft{
    float: none;
    margin-right: 0;
  }
}
