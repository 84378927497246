.recruit-apply {
  .UIProperties {
    width: fit-content;
  }

  #bg-gra {
    display: flex;
    justify-content: center;
    column-gap: 150px;
    background: transparent linear-gradient(108deg, #FDF7D3 0%, #F7ECFA 49%, #E2E6F4 100%) 0% 0% no-repeat padding-box;
    width: 100%;
    padding-top: 110px;
    padding-bottom: 36px;

    @media screen and (max-width: 1215px) {
      column-gap: 50px;
    }

    @media screen and (max-width: 1024px) {
      padding-top: 80px;
      flex-direction: column-reverse;
      align-items: center;
    }

    #hand {
      position: relative;
      top: 78px;
      width: 542px;
      height: auto;
      object-fit: contain;

      @media screen and (max-width: 1115px) {
        width: 466px;
      }

      @media screen and (max-width: 1024px) {
        top: 10px;
      }

      @media screen and (max-width: 768px) {
        width: 274px;
      }
    }
  }

  .confirm__text {
    display: none;
    font-size: 14px;
    margin: 60px 6.4vw;
    color: #F21615;
  }

  .contact__body {
    max-width: 880px;
    margin: 0 auto;

    @media screen and (max-width: 992px) {
      padding: 0 6.4vw;
    }

    .DesiredIncome {
      @media screen and (min-width: 1025px) {
        display: inline-block;
        width: 392px;
      }
    }

    .PreIncome {
      @media screen and (min-width: 1025px) {
        display: inline-block;
        width: 392px;
      }
    }
  }

  .wpcf7-validation-errors {
    position: absolute;
    width: 100%;
    bottom: 175px;
    border: unset;
    max-width: 880px;
    margin: 0 auto;
    transform: translateX(-50%);
    left: 50%;
    color: red;

    @media screen and (max-width: 1024px) {
      bottom: 160px;
      padding: 0 6.4vw;
    }
  }

  .wpcf7-not-valid-tip {
    margin-top: 12px;
    font-size: 13px;
  }

  .step--1 {
    width: 180px;
    margin: 0 auto 100px;

    input[type="submit"] {
      width: 180px;
      height: 60px;
      font-size: 16px;
      font-weight: 500;
      line-height: unset;
      background-color: #F5594C;
      color: white;

      @media screen and (max-width: 576px) {
        width: 100%;
      }
    }
  }

  .step--2 {
    color: white;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 260px;
    margin: 0 auto 100px;

    input[type="submit"],
    input[type=button] {
      width: 110px;
      height: 60px;
      line-height: 40px;
      font-size: 14px;
      background-color: #F5594C;
    }

    .ajax-loader {
      width: 0;
    }
  }

  input[type="button"]:hover,
  input[type="submit"]:hover {
    background-color: #000000;
    -webkit-transition-duration: 1s;
    transition-duration: 1s;
    -webkit-transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  @include desktop {
    h3 {
      letter-spacing: 0.03em;
      font-size: 14px;
      line-height: 1.6666;
      margin-bottom: -2px;
      padding-bottom: 10px;
      margin-top: 13px;
      font-weight: 500;
    }

    p {
      letter-spacing: 0.03em;
      font-size: 13px;
      line-height: 20px;
      margin-bottom: -2px;
      margin-top: 6px;
      font-weight: 400;
    }

    .mobileBR {
      display: none;
    }

    .mobileBR1 {
      display: block;
    }

    .LayoutProperties {
      top: 0px;
      left: 0px;
      width: 100%;
      height: 444px;
    }

    .UIProperties {
      text-align: left;
      font: normal normal normal 14px/20px Noto Sans CJK JP;
      letter-spacing: 0px;
      color: #242424;
      opacity: 1;
    }

    #top-h2 {
      position: relative;
      margin-bottom: 20px;
      text-align: left;
      letter-spacing: 0px;
      color: #242424;
      opacity: 1;
      white-space: nowrap;
      font-size: 40px;
    }

    #top-p {
      position: relative;
      margin-bottom: 20px;
      text-align: left;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0px;
      color: #242424;
      opacity: 1;
    }

    #ast {
      color: #F21615;
    }

    label {
      font-weight: 100;
    }

    span.filebutton {
      position: relative;
      display: none;
      color: #fff;
      font-size: 13px;
      background: #303030;
      border: 1px solid #303030;
      margin: 4px 0 0 0;
      padding: 12px 20px;
      width: 266px;
      height: 50px;
      text-align: center;
      align-content: center;
      transition: .3s;
      cursor: pointer;
      z-index: 5;
    }

    span.filebutton:hover {
      border: 1px solid #303030;
      color: #535353;
      background: #303030;
    }

    span.filebutton:file-selector-button::after {
      background: #ffffff;
      opacity: 1;
    }

    input[type=file] {
      position: relative;
      margin: 0px 0 0 -14px;
      font-size: 13px;
      top: 0;
      left: 0;
    }

    input[type=file]:focus {
      outline: none;
    }

    input[type=file]::after {
      position: absolute;
      background: #fff;
      font-size: 13px;
      height: 26px;
      line-height: 1.8;
      text-align: right;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td input[type="text"] {
      width: 100%;
      background-color: #F5F5F5;
      line-height: 40px;
      height: 60px;
      padding: 0 20px;
      font-size: 14px;
      font-weight: 500;
      border-radius: 4px;
      border-style: none;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td input[type="tel"] {
      background-color: #F5F5F5;
      border-radius: 4px;
      border-style: none;
      width: 100%;
      height: 60px;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td input[type="email"] {
      background-color: #F5F5F5;
      border-radius: 4px;
      border-style: none;
      width: 100%;
      height: 60px;
    }

    .gender {
      padding-right: 198px;
      white-space: nowrap;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td textarea {
      background-color: #F5F5F5;
      border-radius: 4px;
      border-style: none;
    }

    .contact .s-contact .s-inner {
      position: relative;
      max-width: 780px;
      padding-top: 80px;
      margin-right: auto;
      margin-left: auto;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .privacy-inner {
      background-color: #F5F5F5;
      border-radius: 4px;
      border-style: none;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td {
      display: block;
      position: relative;
      width: calc(100% - 210px);
      margin-left: auto;
      padding: 0px 8px;

      &.birthday-container {
        letter-spacing: 0.03em;
        font-size: 18px;
        line-height: 1.6666;
        margin-bottom: -4px;
        margin-top: 0px;
        font-weight: 500;
        color: #303030;
      }

      &.gender-container {
        letter-spacing: 0.03em;
        font-size: 18px;
        line-height: 1.6666;
        margin-bottom: -4px;
        margin-top: 0px;
        font-weight: 500;
        color: #303030;
      }

      &.work-region-container {
        padding: 2px 2px 2px 2px;
        letter-spacing: 0.03em;
        font-size: 14px;
        line-height: 1.6666;
        margin-bottom: -4px;
        margin-top: -20px;
        font-weight: 500;
        color: #303030;
      }

      &.job-type-container {
        padding: 2px 2px 2px 2px;
        letter-spacing: 0.03em;
        font-size: 13px;
        line-height: 1.6666;
        margin-bottom: -4px;
        margin-top: 13px;
        font-weight: 500;
        color: #303030;
      }
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .wpcf7-file {
      margin-top: 20px;
      letter-spacing: 0.03em;
      font-size: 14px;
      line-height: 1.6666;
      margin-bottom: -4px;
      top: -12px;
      left: 12px;
      margin-bottom: 12px;
      gap: 10;
      margin-top: 13px;
      font-weight: 500;
      color: #303030;
      z-index: 2;
    }

    .wpcf7c-conf {
      background-color: #f00;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td label {
      margin-top: 20px;
      letter-spacing: 0.03em;
      font-size: 14px;
      line-height: 1.6666;
      margin-bottom: -2px;
      padding-bottom: 14px;
      margin-top: 13px;
      font-weight: 500;
      color: #303030;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: start;
      margin-top: 43px;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox .wpcf7-form-control .wpcf7-list-item label:before {
      position: absolute;
      top: 32%;
      left: 0;
      margin-top: -7px;
      content: "";
      width: 24px;
      height: 24px;
      display: inline-block;
      vertical-align: middle;
      border: 1px solid #ccc;
    }

    #single {
      text-align: left;
      width: 100%;
    }

    #single td {
      width: 100%;
    }

    #single.Region {
      position: relative;
      left: -14px;
    }

    #single.Job {
      display: grid;
      margin-top: unset !important;
      grid-template-columns: repeat(3, 1fr);
      /* 1行に3つの列を作成 */
      gap: 0px;
      width: 90%;
      position: relative;
      left: -14px;
    }

    .JobLabel {
      font-size: 14px;
      font-weight: 500;
      margin: 0px;
      white-space: nowrap;
      padding-left: 8px;
    }

    .RegionLabel {
      font-size: 14px;
      font-weight: 500;
      margin-top: 60px;
    }

    // .work-region-container{
    //   left: 20px;
    // }
    .job-type-container {
      left: 20px;

      .wpcf7-list-item {
        width: 270px;
      }
    }

    span.wpcf7-list-item {
      white-space: nowrap;
      padding-top: 8px;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table.is-lastCheck tbody tr td input[type="text"],
    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table.is-lastCheck tbody tr td input[type="tel"],
    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table.is-lastCheck tbody tr td input[type="email"],
    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table.is-lastCheck tbody tr td select,
    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table.is-lastCheck tbody tr td textarea {
      border: 0;
      background: #fff;
    }

    .contact .step--2 {
      position: absolute;
      bottom: -32px;
      left: 85px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      width: 556px;
    }

    .contact .contact__body+.wpcf7-response-output {
      position: absolute;
      left: 0;
      bottom: -210px;
    }

    .contact .step--1 {
      position: absolute;
      bottom: -63px;
      right: 0;
      left: 0;
      margin: 0 auto;
      display: block;
    }
  }

  @media screen and (max-width: 1024px) {
    h3 {
      letter-spacing: 0.03em;
      font-size: 14px;
      line-height: 1.6666;
      margin-bottom: -2px;
      padding-bottom: 10px;
      margin-top: 13px;
      font-weight: 500;
    }

    p {
      letter-spacing: 0.03em;
      font-size: 13px;
      line-height: 20px;
      margin-bottom: -2px;
      margin-top: 6px;
      font-weight: 400;
    }

    .mobileBR {
      display: none;
    }

    .mobileBR1 {
      display: block;
    }

    .LayoutProperties {
      top: 0px;
      left: 0px;
      width: 100%;
      height: 444px;
    }

    .UIProperties {
      text-align: left;
      font: normal normal normal 14px/20px Noto Sans CJK JP;
      letter-spacing: 0px;
      color: #242424;
      opacity: 1;
    }

    #top-h2 {
      position: relative;
      margin-bottom: 24px;
      text-align: left;
      letter-spacing: 0px;
      color: #242424;
      opacity: 1;
      font-size: 40px;
      white-space: nowrap;
    }

    #top-p {
      position: relative;
      text-align: left;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0px;
      color: #242424;
      opacity: 1;
      white-space: nowrap;
    }

    #ast {
      color: #F21615;
    }

    .contact .s-contact {
      padding: 0px 0px;
      position: relative;
      display: block;
      background: white;
    }

    .contact .s-contact .s-inner {
      position: relative;
      max-width: 780px;
      padding: 80px 24px 120px 24px;
      margin-right: auto;
      margin-left: auto;
    }

    .p-contact-form {
      position: relative;
      display: block;
      margin-top: 15px;
      background-color: #fff;
    }

    .p-contact-form .contact-form__inner,
    .p-contact-form .contact-form__inner .p-contact-form__wrap,
    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table {
      position: relative;
      display: block;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table.is-lastCheck tbody tr td input[type="text"],
    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table.is-lastCheck tbody tr td input[type="tel"],
    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table.is-lastCheck tbody tr td input[type="email"],
    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table.is-lastCheck tbody tr td select,
    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table.is-lastCheck tbody tr td textarea {
      border: 0;
      background-color: #fff;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table.is-lastCheck tbody tr td label:before {
      border: 0 !important;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody {
      position: relative;
      display: block;
      padding: 42px 16px 0px;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr {
      display: block;
      margin-top: 43px;
      text-align: left;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr:first-child {
      margin-top: 0;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr.is-error td input[type="text"],
    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr.is-error td input[type="tel"],
    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr.is-error td input[type="email"],
    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr.is-error td select,
    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr.is-error td textarea {
      border: 1px solid red;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr.is-error td label:before {
      border: 1px solid red !important;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr th {
      display: block;
      position: relative;
      font-size: 12px;
      letter-spacing: 0.03em;
      font-weight: 500;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td {
      margin-top: 14px;
      display: block;
      position: relative;
      width: 100%;
      font-size: 12px;
      letter-spacing: 0.03em;

      &.birthday-container {
        letter-spacing: 0.03em;
        font-size: 15px;
        line-height: 1.6666;
        margin-bottom: -4px;
        margin-top: 13px;
        font-weight: 500;
        color: #303030;
      }

      &.gender-container {
        letter-spacing: 0.03em;
        font-size: 15px;
        line-height: 1.6666;
        margin-bottom: -4px;
        margin-top: 13px;
        font-weight: 500;
        color: #303030;
      }

      &.work-region-container {
        padding: 2px 2px 2px 2px;
        letter-spacing: 0.03em;
        font-size: 12px;
        line-height: 1.6666;
        margin-bottom: -4px;
        margin-top: 13px;
        font-weight: 500;
        color: #303030;
      }

      &.job-type-container {
        padding: 2px 2px 2px 2px;
        letter-spacing: 0.03em;
        font-size: 12px;
        line-height: 1.6666;
        margin-bottom: -4px;
        margin-top: 13px;
        font-weight: 500;
        color: #303030;
      }
    }

    #single.Job {
      height: 388px;
      overflow-y: scroll;
      scroll-snap-type: y mandatory;
      display: grid;
      grid-template-columns: repeat(1, 0.1fr);
    }

    #single {
      text-align: left;
      width: 100%;
      margin: 0px;
    }

    #single td {
      width: 100%;
    }

    #single.Job label {
      white-space: nowrap;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .privacy-inner {
      width: 100%;
      height: 160px;
      overflow-y: scroll;
      background-color: #fff;
      border: 1px solid #ccc;
      line-height: 54px;
      padding: 16px;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .privacy-inner .privacy__text {
      font-size: 12px;
      line-height: 2;
      margin-bottom: -6px;
      margin-top: 10px;
      letter-spacing: 0.03em;
      color: #585858;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree {
      position: relative;
      margin: 8px auto 0;
      width: 216px;
      height: 36px;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label,
    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox,
    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox .wpcf7-form-control {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox .wpcf7-form-control .wpcf7-list-item {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      margin: 0;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox .wpcf7-form-control .wpcf7-list-item label {
      position: relative;
      display: block;
      line-height: 1;
      vertical-align: middle;
      cursor: pointer;
      padding-top: 10px;
      margin: 0 auto;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox .wpcf7-form-control .wpcf7-list-item label:before {
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -4px;
      content: "";
      width: 18px;
      height: 18px;
      display: inline-block;
      vertical-align: middle;
      border: 1px solid #ccc;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox .wpcf7-form-control .wpcf7-list-item label input[type="checkbox"] {
      display: none;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox .wpcf7-form-control .wpcf7-list-item label input[type="checkbox"]:checked+.wpcf7-list-item-label::before,
    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox .wpcf7-form-control .wpcf7-list-item label input[type="checkbox"]:checked+input[type="hidden"]+.wpcf7-list-item-label::before {
      opacity: 1;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox .wpcf7-form-control .wpcf7-list-item label .wpcf7-list-item-label {
      position: relative;
      padding-left: 26px;
      letter-spacing: 0.03em;
      font-size: 12px;
      font-weight: 600;
      white-space: nowrap;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox .wpcf7-form-control .wpcf7-list-item label .wpcf7-list-item-label::before {
      position: absolute;
      top: 0;
      left: 6px;
      display: block;
      content: "";
      width: 6px;
      height: 10px;
      border-right: 2px solid #303030;
      border-bottom: 2px solid #303030;
      opacity: 0;
      z-index: 1;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      -webkit-transition: opacity 0.15s cubic-bezier(0.25, 0.25, 0.75, 0.75);
      transition: opacity 0.15s cubic-bezier(0.25, 0.25, 0.75, 0.75);
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td input[type="text"] {
      width: 100%;
      background-color: #fff;
      border: 1px solid #ccc;
      line-height: 54px;
      height: 54px;
      padding: 0 16px;
      font-size: 12px;
      font-weight: 500;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td ::input-placeholder {
      font-size: 12px;
      letter-spacing: 0.03em;
      color: #999;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td ::-webkit-input-placeholder {
      font-size: 12px;
      letter-spacing: 0.03em;
      color: #999;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td :-ms-input-placeholder {
      font-size: 12px;
      letter-spacing: 0.03em;
      color: #999;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td ::-ms-input-placeholder {
      font-size: 12px;
      letter-spacing: 0.03em;
      color: #999;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td ::placeholder {
      font-size: 12px;
      letter-spacing: 0.03em;
      color: #999;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td input[type="tel"],
    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td input[type="email"] {
      ime-mode: disabled !important;
      width: 100%;
      background-color: #fff;
      border: 1px solid #ccc;
      line-height: 54px;
      height: 54px;
      padding: 0 16px;
      font-size: 12px;
      font-weight: 500;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td select {
      width: 100%;
      background-color: #fff;
      border: 1px solid #ccc;
      line-height: 54px;
      height: 54px;
      padding: 0 16px;
      font-size: 12px;
      letter-spacing: 0.03em;
      font-weight: 500;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td textarea {
      width: 100%;
      background-color: #fff;
      border: 1px solid #ccc;
      line-height: 18px;
      height: 180px;
      padding: 0 16px;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .wpcf7-file {
      margin-top: 20px;
      letter-spacing: 0.03em;
      font-size: 10px;
      line-height: 1.6666;
      margin-bottom: -4px;
      margin-top: 13px;
      font-weight: 500;
      color: #303030;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td label {
      margin-top: 20px;
      letter-spacing: 0.03em;
      font-size: 10px;
      line-height: 1.6666;
      margin-bottom: -4px;
      margin-top: 13px;
      font-weight: 500;
      color: #303030;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td input[type="text"] {
      width: 100%;
      background-color: #F5F5F5;
      line-height: 40px;
      height: 40px;
      padding: 0 20px;
      font-size: 14px;
      font-weight: 500;
    }

    .contact .step--1,
    .contact .step--2 {
      bottom: -52px;
      right: 0;
      margin: 0 auto;
      left: 0;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td input[type="text"] {
      width: 100%;
      background-color: #F5F5F5;
      line-height: 40px;
      height: 40px;
      padding: 0 20px;
      font-size: 14px;
      font-weight: 500;
      border-radius: 4px;
      border-style: none;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td input[type="tel"] {
      background-color: #F5F5F5;
      border-radius: 4px;
      border-style: none;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td input[type="email"] {
      background-color: #F5F5F5;
      border-radius: 4px;
      border-style: none;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td textarea {
      background-color: #F5F5F5;
      border-radius: 4px;
      border-style: none;
    }

    .contact .s-contact {
      padding: 0px 0px;
      position: relative;
      display: block;
      background: white;
    }

    .contact .s-contact .s-inner {
      position: relative;
      max-width: 780px;
      margin-right: auto;
      margin-left: auto;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .privacy-inner {
      background-color: #F5F5F5;
      border-radius: 4px;
      border-style: none;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td {
      display: block;
      position: relative;
      padding: 0px 8px;

      &.birthday-container {
        letter-spacing: 0.03em;
        font-size: 18px;
        line-height: 1.6666;
        margin-bottom: -4px;
        margin-top: 0px;
        font-weight: 500;
        color: #303030;
      }

      &.gender-container {
        letter-spacing: 0.03em;
        font-size: 18px;
        line-height: 1.6666;
        margin-bottom: -4px;
        margin-top: 0px;
        font-weight: 500;
        color: #303030;
      }

      &.work-region-container {
        padding: 2px 2px 2px 2px;
        letter-spacing: 0.03em;
        font-size: 14px;
        line-height: 1.6666;
        margin-bottom: -4px;
        margin-top: 13px;
        font-weight: 500;
        color: #303030;
      }

      &.job-type-container {
        padding: 2px 2px 2px 2px;
        letter-spacing: 0.03em;
        font-size: 13px;
        line-height: 1.6666;
        margin-bottom: -4px;
        margin-top: 13px;
        font-weight: 500;
        color: #303030;

        .wpcf7-list-item {
          width: 100%;
        }
      }
    }

    #single.Job {
      height: 388px;
      overflow-y: scroll;
      scroll-snap-type: y mandatory;
      background: #F5F5F5;
    }

    #single {
      text-align: left;
      width: 100%;
    }

    #single td {
      width: 100%;
    }

    #single.Job {
      display: grid;
    }

    .RegionLabel {
      padding-bottom: 16px;
    }

    .JobLabel {
      margin: 0px;
      display: block;
      position: relative;
      text-align: left;
      font-size: 14px;
      font-weight: 500;
      top: -12px;
      overflow: hidden;
    }

    .job-type-container {
      margin: 0px;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .wpcf7-file {
      position: relative;
      margin-top: 20px;
      letter-spacing: 0.03em;
      font-size: 14px;
      line-height: 1.6666;
      margin-bottom: -4px;
      gap: 10;
      top: -8px;
      margin-top: 13px;
      font-weight: 500;
      color: #303030;
      z-index: 2;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .privacy-inner {
      width: 100%;
      height: 160px;
      overflow-y: scroll;
      background-color: #fff;
      border: 1px solid #ccc;
      line-height: 54px;
      padding: 16px;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .privacy-inner .privacy__text {
      font-size: 12px;
      line-height: 2;
      margin-bottom: -6px;
      margin-top: 10px;
      letter-spacing: 0.03em;
      color: #585858;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree {
      position: relative;
      margin: 8px auto 0;
      width: 216px;
      height: 36px;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label,
    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox,
    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox .wpcf7-form-control {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox .wpcf7-form-control .wpcf7-list-item {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      margin: 0;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox .wpcf7-form-control .wpcf7-list-item label {
      position: relative;
      display: block;
      line-height: 1;
      vertical-align: middle;
      cursor: pointer;
      padding-top: 10px;
      margin: 0 auto;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox .wpcf7-form-control .wpcf7-list-item label:before {
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -4px;
      content: "";
      width: 18px;
      height: 18px;
      display: inline-block;
      vertical-align: middle;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox .wpcf7-form-control .wpcf7-list-item label input[type="checkbox"] {
      display: none;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox .wpcf7-form-control .wpcf7-list-item label input[type="checkbox"]:checked+.wpcf7-list-item-label::before,
    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox .wpcf7-form-control .wpcf7-list-item label input[type="checkbox"]:checked+input[type="hidden"]+.wpcf7-list-item-label::before {
      opacity: 1;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox .wpcf7-form-control .wpcf7-list-item label .wpcf7-list-item-label {
      position: relative;
      padding-left: 26px;
      letter-spacing: 0.03em;
      font-size: 12px;
      font-weight: 600;
      white-space: nowrap;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox .wpcf7-form-control .wpcf7-list-item label .wpcf7-list-item-label::before {
      position: absolute;
      top: 0;
      left: 6px;
      display: block;
      content: "";
      width: 6px;
      height: 10px;
      border-right: 2px solid #303030;
      border-bottom: 2px solid #303030;
      opacity: 0;
      z-index: 1;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      -webkit-transition: opacity 0.15s cubic-bezier(0.25, 0.25, 0.75, 0.75);
      transition: opacity 0.15s cubic-bezier(0.25, 0.25, 0.75, 0.75);
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td input[type="text"] {
      width: 100%;
      background-color: #fff;
      line-height: 54px;
      height: 54px;
      padding: 0 16px;
      font-size: 12px;
      font-weight: 500;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td ::input-placeholder {
      font-size: 12px;
      letter-spacing: 0.03em;
      color: #999;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td ::-webkit-input-placeholder {
      font-size: 12px;
      letter-spacing: 0.03em;
      color: #999;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td :-ms-input-placeholder {
      font-size: 12px;
      letter-spacing: 0.03em;
      color: #999;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td ::-ms-input-placeholder {
      font-size: 12px;
      letter-spacing: 0.03em;
      color: #999;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td ::placeholder {
      font-size: 12px;
      letter-spacing: 0.03em;
      color: #999;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td input[type="tel"],
    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td input[type="email"] {
      ime-mode: disabled !important;
      width: 100%;
      line-height: 54px;
      height: 54px;
      padding: 0 16px;
      font-size: 12px;
      font-weight: 500;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td select {
      width: 100%;
      background-color: #fff;
      line-height: 54px;
      height: 54px;
      padding: 0 16px;
      font-size: 12px;
      letter-spacing: 0.03em;
      font-weight: 500;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td textarea {
      width: 100%;
      background-color: #f5f5f5;
      line-height: 18px;
      height: 180px;
      padding: 0 16px;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .wpcf7-file {
      margin-top: 20px;
      letter-spacing: 0.03em;
      font-size: 10px;
      line-height: 1.6666;
      margin-bottom: -4px;
      margin-top: 13px;
      font-weight: 500;
      color: #303030;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td label {
      margin-top: 20px;
      letter-spacing: 0.03em;
      font-size: 14px;
      line-height: 1.6666;
      margin-bottom: -4px;
      margin-top: 13px;
      font-weight: 500;
      color: #303030;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td input[type="text"] {
      width: 100%;
      background-color: #F5F5F5;
      line-height: 40px;
      height: 40px;
      padding: 0 20px;
      font-size: 14px;
      font-weight: 500;
    }
  }

  @media screen and (max-width: 768px) {
    h3 {
      letter-spacing: 0.03em;
      font-size: 14px;
      line-height: 1.6666;
      margin-bottom: -2px;
      padding-bottom: 10px;
      margin-top: 13px;
      font-weight: 500;
    }

    p {
      letter-spacing: 0.03em;
      font-size: 13px;
      line-height: 20px;
      margin-bottom: -2px;
      margin-top: 6px;
      font-weight: 400;
    }

    .mobileBR {
      display: block;
    }

    .mobileBR1 {
      display: none;
    }

    .LayoutProperties {
      top: 0px;
      left: 0px;
      width: 100%;
      height: 512px;
    }

    .UIProperties {
      text-align: left;
      letter-spacing: 0px;
      color: #242424;
      opacity: 1;
      font-size: 14px;
      padding: 0 6.4vw;
    }

    #top-h2 {
      position: relative;
      text-align: left;
      font-size: 24px;
      letter-spacing: 0px;
      color: #242424;
      opacity: 1;
    }

    #top-p {
      position: relative;
      text-align: left;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: -0.2px;
      color: #242424;
      opacity: 1;
      white-space: normal;
    }

    #top-p::first-line {
      letter-spacing: -0.6px;
    }

    #ast {
      color: #F21615;
    }

    label {
      font-weight: 100;
    }

    .contact .s-contact {
      padding: 0px 0px;
      position: relative;
      display: block;
      background: white;
    }

    .contact .s-contact .s-inner {
      position: relative;
      max-width: 780px;
      padding: 80px 36px 0px 36px;
      margin-right: auto;
      margin-left: auto;
    }

    span.filebutton {
      position: relative;
      display: none;
      color: #fff;
      font-size: 13px;
      background: #303030;
      border: 1px solid #303030;
      margin: 4px 0 0 0;
      padding: 12px 20px;
      width: 183px;
      height: 50px;
      text-align: center;
      align-content: center;
      transition: .3s;
      cursor: pointer;
      z-index: 5;
    }

    span.filebutton:hover {
      border: 1px solid #303030;
      color: #535353;
      background: #303030;
    }

    span.filebutton:file-selector-button::after {
      background: #ffffff;
      opacity: 1;
    }

    input[type=file] {
      position: relative;
      margin: 0px 0 0 -14px;
      font-size: 13px;
      top: 0;
      left: 0;
    }

    input[type=file]:focus {
      outline: none;
    }

    input[type=file]::after {
      position: absolute;
      background: #fff;
      font-size: 13px;
      height: 26px;
      line-height: 1.8;
      text-align: right;
    }

    .Traffic {
      .wpcf7-list-item {
        display: block;
      }
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td input[type="text"] {
      width: 100%;
      background-color: #F5F5F5;
      line-height: 40px;
      height: 40px;
      padding: 0 20px;
      font-size: 14px;
      font-weight: 500;
      border-radius: 4px;
      border-style: none;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td input[type="tel"] {
      background-color: #F5F5F5;
      border-radius: 4px;
      border-style: none;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td input[type="email"] {
      background-color: #F5F5F5;
      border-radius: 4px;
      border-style: none;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td textarea {
      background-color: #F5F5F5;
      border-radius: 4px;
      border-style: none;
    }

    .contact .s-contact {
      padding: 0px 0px;
      position: relative;
      display: block;
      background: white;
    }

    .contact .s-contact .s-inner {
      position: relative;
      max-width: 780px;
      margin-right: auto;
      margin-left: auto;
      padding-bottom: 80px;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .privacy-inner {
      background-color: #F5F5F5;
      border-radius: 4px;
      border-style: none;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td {
      display: block;
      position: relative;
      padding: unset;

      &.birthday-container {
        letter-spacing: 0.03em;
        font-size: 18px;
        line-height: 1.6666;
        margin-bottom: -4px;
        margin-top: 0px;
        font-weight: 500;
        color: #303030;
      }

      &.gender-container {
        letter-spacing: 0.03em;
        font-size: 18px;
        line-height: 1.6666;
        margin-bottom: -4px;
        margin-top: 0px;
        font-weight: 500;
        color: #303030;
      }

      &.work-region-container {
        padding: 2px 2px 2px 2px;
        letter-spacing: 0.03em;
        font-size: 14px;
        line-height: 1.6666;
        margin-bottom: -4px;
        margin-top: 40px;
        font-weight: 500;
        color: #303030;
      }

      &.job-type-container {
        padding: 2px 2px 2px 2px;
        letter-spacing: 0.03em;
        font-size: 13px;
        line-height: 1.6666;
        margin-bottom: -4px;
        margin-top: 13px;
        font-weight: 500;
        color: #303030;
      }
    }

    #single.Job {
      height: 388px;
      overflow-y: scroll;
      scroll-snap-type: y mandatory;
      background: #F5F5F5;
    }

    #single {
      text-align: left;
      width: 100%;
    }

    #single td {
      width: 100%;
    }

    #single.Job {
      display: grid;
    }

    .JobLabel {
      display: block;
      position: relative;
      text-align: left;
      font-size: 14px;
      font-weight: 500;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .wpcf7-file {
      margin-top: 20px;
      letter-spacing: 0.03em;
      font-size: 14px;
      line-height: 1.6666;
      margin-bottom: -4px;
      left: 16px;
      top: -8px;
      gap: 10;
      margin-top: 13px;
      font-weight: 500;
      color: #303030;
      z-index: 2;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .privacy-inner {
      width: 100%;
      height: 160px;
      overflow-y: scroll;
      background-color: #fff;
      border: 1px solid #ccc;
      line-height: 54px;
      padding: 16px;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .privacy-inner .privacy__text {
      font-size: 12px;
      line-height: 2;
      margin-bottom: -6px;
      margin-top: 10px;
      letter-spacing: 0.03em;
      color: #585858;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree {
      position: relative;
      margin: 8px auto 0;
      width: 216px;
      height: 36px;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label,
    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox,
    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox .wpcf7-form-control {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox .wpcf7-form-control .wpcf7-list-item {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      margin: 0;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox .wpcf7-form-control .wpcf7-list-item label {
      position: relative;
      display: block;
      line-height: 1;
      vertical-align: middle;
      cursor: pointer;
      padding-top: 10px;
      margin: 0 auto;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox .wpcf7-form-control .wpcf7-list-item label:before {
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -4px;
      content: "";
      width: 18px;
      height: 18px;
      display: inline-block;
      vertical-align: middle;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox .wpcf7-form-control .wpcf7-list-item label input[type="checkbox"] {
      display: none;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox .wpcf7-form-control .wpcf7-list-item label input[type="checkbox"]:checked+.wpcf7-list-item-label::before,
    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox .wpcf7-form-control .wpcf7-list-item label input[type="checkbox"]:checked+input[type="hidden"]+.wpcf7-list-item-label::before {
      opacity: 1;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox .wpcf7-form-control .wpcf7-list-item label .wpcf7-list-item-label {
      position: relative;
      padding-left: 26px;
      letter-spacing: 0.03em;
      font-size: 12px;
      font-weight: 600;
      white-space: nowrap;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox .wpcf7-form-control .wpcf7-list-item label .wpcf7-list-item-label::before {
      position: absolute;
      top: 0;
      left: 6px;
      display: block;
      content: "";
      width: 6px;
      height: 10px;
      border-right: 2px solid #303030;
      border-bottom: 2px solid #303030;
      opacity: 0;
      z-index: 1;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      -webkit-transition: opacity 0.15s cubic-bezier(0.25, 0.25, 0.75, 0.75);
      transition: opacity 0.15s cubic-bezier(0.25, 0.25, 0.75, 0.75);
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td input[type="text"] {
      width: 100%;
      background-color: #fff;
      line-height: 54px;
      height: 54px;
      padding: 0 16px;
      font-size: 12px;
      font-weight: 500;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td ::input-placeholder {
      font-size: 12px;
      letter-spacing: 0.03em;
      color: #999;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td ::-webkit-input-placeholder {
      font-size: 12px;
      letter-spacing: 0.03em;
      color: #999;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td :-ms-input-placeholder {
      font-size: 12px;
      letter-spacing: 0.03em;
      color: #999;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td ::-ms-input-placeholder {
      font-size: 12px;
      letter-spacing: 0.03em;
      color: #999;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td ::placeholder {
      font-size: 12px;
      letter-spacing: 0.03em;
      color: #999;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td input[type="tel"],
    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td input[type="email"] {
      ime-mode: disabled !important;
      width: 100%;
      line-height: 54px;
      height: 54px;
      padding: 0 16px;
      font-size: 12px;
      font-weight: 500;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td select {
      width: 100%;
      background-color: #fff;
      line-height: 54px;
      height: 54px;
      padding: 0 16px;
      font-size: 12px;
      letter-spacing: 0.03em;
      font-weight: 500;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td textarea {
      width: 100%;
      background-color: #f5f5f5;
      line-height: 18px;
      height: 180px;
      padding: 0 16px;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .wpcf7-file {
      margin-top: 20px;
      letter-spacing: 0.03em;
      font-size: 10px;
      line-height: 1.6666;
      margin-bottom: -4px;
      margin-top: 13px;
      font-weight: 500;
      color: #303030;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td label {
      margin-top: 20px;
      letter-spacing: 0.03em;
      font-size: 14px;
      line-height: 1.6666;
      margin-bottom: -4px;
      margin-top: 13px;
      font-weight: 500;
      color: #303030;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td input[type="text"] {
      width: 100%;
      background-color: #F5F5F5;
      line-height: 40px;
      height: 40px;
      padding: 0 20px;
      font-size: 14px;
      font-weight: 500;
    }
  }

  @media screen and (max-width: 410px) {
    h3 {
      letter-spacing: 0.03em;
      font-size: 14px;
      line-height: 1.6666;
      margin-bottom: -2px;
      padding-bottom: 10px;
      margin-top: 13px;
      font-weight: 500;
    }

    p {
      letter-spacing: 0.03em;
      font-size: 13px;
      line-height: 20px;
      margin-bottom: -2px;
      margin-top: 6px;
      font-weight: 400;
    }

    .LayoutProperties {
      top: 0px;
      left: 0px;
      width: 768px;
      height: 512px;
    }

    .UIProperties {
      text-align: left;
      font: normal normal normal 14px/20px Noto Sans CJK JP;
      letter-spacing: 0px;
      color: #242424;
      opacity: 1;
    }

    #top-h2 {
      position: relative;
      text-align: left;
      letter-spacing: 0px;
      color: #242424;
      opacity: 1;
      white-space: nowrap;
      font-size: 24px;
    }

    #top-p {
      position: relative;
      text-align: left;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: -0.2px;
      color: #242424;
      opacity: 1;
      overflow-wrap: break-word;
    }

    #ast {
      color: #F21615;
    }

    label {
      font-weight: 100;
    }

    span.filebutton {
      position: relative;
      display: none;
      color: #fff;
      font-size: 13px;
      background: #303030;
      border: 1px solid #303030;
      margin: 4px 0 0 0;
      padding: 12px 14px;
      width: 151px;
      height: 50px;
      text-align: center;
      align-content: center;
      transition: .3s;
      cursor: pointer;
      z-index: 5;
    }

    span.filebutton:hover {
      border: 1px solid #303030;
      color: #535353;
      background: #303030;
    }

    span.filebutton:file-selector-button::after {
      background: #ffffff;
      opacity: 1;
    }

    input[type=file] {
      position: relative;
      margin: 0px 0 0 -14px;
      font-size: 13px;
      top: 0;
      left: 0;
    }

    input[type=file]:focus {
      outline: none;
    }

    input[type=file]::after {
      position: absolute;
      background: #fff;
      font-size: 13px;
      height: 26px;
      line-height: 1.8;
      text-align: right;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td input[type="text"] {
      width: 100%;
      background-color: #F5F5F5;
      line-height: 40px;
      height: 40px;
      padding: 0 20px;
      font-size: 14px;
      font-weight: 500;
      border-radius: 4px;
      border-style: none;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td input[type="tel"] {
      background-color: #F5F5F5;
      border-radius: 4px;
      border-style: none;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td input[type="email"] {
      background-color: #F5F5F5;
      border-radius: 4px;
      border-style: none;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td textarea {
      background-color: #F5F5F5;
      border-radius: 4px;
      border-style: none;
    }

    .contact .s-contact {
      padding: 0px 0px;
      position: relative;
      display: block;
      background: white;
    }

    .contact .s-contact .s-inner {
      position: relative;
      max-width: 780px;
      padding: 80px 24px 120px 24px;
      margin-right: auto;
      margin-left: auto;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .privacy-inner {
      background-color: #F5F5F5;
      border-radius: 4px;
      border-style: none;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td {
      display: block;
      position: relative;

      &.birthday-container {
        letter-spacing: 0.03em;
        font-size: 18px;
        line-height: 1.6666;
        margin-bottom: -4px;
        margin-top: 0px;
        font-weight: 500;
        color: #303030;
      }

      &.gender-container {
        letter-spacing: 0.03em;
        font-size: 18px;
        line-height: 1.6666;
        margin-bottom: -4px;
        margin-top: 0px;
        font-weight: 500;
        color: #303030;
      }

      &.work-region-container {
        padding: 2px 2px 2px 2px;
        letter-spacing: 0.03em;
        font-size: 14px;
        line-height: 1.6666;
        margin-bottom: -4px;
        margin-top: 13px;
        font-weight: 500;
        color: #303030;
      }

      &.job-type-container {
        padding: 2px 2px 2px 2px;
        letter-spacing: 0.03em;
        font-size: 13px;
        line-height: 1.6666;
        margin-bottom: -4px;
        margin-top: 13px;
        font-weight: 500;
        color: #303030;

        .wpcf7-list-item {
          width: 100%;
        }
      }
    }

    #single.Job {
      height: 388px;
      overflow-y: scroll;
      scroll-snap-type: y mandatory;
      background: #F5F5F5;
    }

    #single {
      text-align: left;
      width: 100%;
    }

    #single td {
      width: 100%;
    }

    #single.Job {
      display: grid;
    }

    .JobLabel {
      display: block;
      position: relative;
      top: 34px;
      left: 20px;
      text-align: left;
      font-size: 14px;
      font-weight: 500;
      width: 120%;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .wpcf7-file {
      margin-top: 20px;
      letter-spacing: 0.03em;
      font-size: 14px;
      line-height: 1.6666;
      margin-bottom: -4px;
      left: 16px;
      top: -10px;
      gap: 10;
      margin-top: 13px;
      font-weight: 500;
      color: #303030;
      z-index: 2;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .privacy-inner {
      width: 100%;
      height: 160px;
      overflow-y: scroll;
      background-color: #fff;
      border: 1px solid #ccc;
      line-height: 54px;
      padding: 16px;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .privacy-inner .privacy__text {
      font-size: 12px;
      line-height: 2;
      margin-bottom: -6px;
      margin-top: 10px;
      letter-spacing: 0.03em;
      color: #585858;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree {
      position: relative;
      margin: 8px auto 0;
      width: 216px;
      height: 36px;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label,
    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox,
    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox .wpcf7-form-control {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox .wpcf7-form-control .wpcf7-list-item {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      margin: 0;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox .wpcf7-form-control .wpcf7-list-item label {
      position: relative;
      display: block;
      line-height: 1;
      vertical-align: middle;
      cursor: pointer;
      padding-top: 10px;
      margin: 0 auto;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox .wpcf7-form-control .wpcf7-list-item label:before {
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -4px;
      content: "";
      width: 18px;
      height: 18px;
      display: inline-block;
      vertical-align: middle;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox .wpcf7-form-control .wpcf7-list-item label input[type="checkbox"] {
      display: none;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox .wpcf7-form-control .wpcf7-list-item label input[type="checkbox"]:checked+.wpcf7-list-item-label::before,
    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox .wpcf7-form-control .wpcf7-list-item label input[type="checkbox"]:checked+input[type="hidden"]+.wpcf7-list-item-label::before {
      opacity: 1;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox .wpcf7-form-control .wpcf7-list-item label .wpcf7-list-item-label {
      position: relative;
      padding-left: 26px;
      letter-spacing: 0.03em;
      font-size: 12px;
      font-weight: 600;
      white-space: nowrap;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .s-agree .agree-label .wpcf7-form-control-wrap.checkbox .wpcf7-form-control .wpcf7-list-item label .wpcf7-list-item-label::before {
      position: absolute;
      top: 0;
      left: 6px;
      display: block;
      content: "";
      width: 6px;
      height: 10px;
      border-right: 2px solid #303030;
      border-bottom: 2px solid #303030;
      opacity: 0;
      z-index: 1;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      -webkit-transition: opacity 0.15s cubic-bezier(0.25, 0.25, 0.75, 0.75);
      transition: opacity 0.15s cubic-bezier(0.25, 0.25, 0.75, 0.75);
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td input[type="text"] {
      width: 100%;
      background-color: #fff;
      line-height: 54px;
      height: 54px;
      padding: 0 16px;
      font-size: 12px;
      font-weight: 500;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td ::input-placeholder {
      font-size: 12px;
      letter-spacing: 0.03em;
      color: #999;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td ::-webkit-input-placeholder {
      font-size: 12px;
      letter-spacing: 0.03em;
      color: #999;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td :-ms-input-placeholder {
      font-size: 12px;
      letter-spacing: 0.03em;
      color: #999;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td ::-ms-input-placeholder {
      font-size: 12px;
      letter-spacing: 0.03em;
      color: #999;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td ::placeholder {
      font-size: 12px;
      letter-spacing: 0.03em;
      color: #999;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td input[type="tel"],
    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td input[type="email"] {
      ime-mode: disabled !important;
      width: 100%;
      line-height: 54px;
      height: 54px;
      padding: 0 16px;
      font-size: 12px;
      font-weight: 500;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td select {
      width: 100%;
      background-color: #fff;
      line-height: 54px;
      height: 54px;
      padding: 0 16px;
      font-size: 12px;
      letter-spacing: 0.03em;
      font-weight: 500;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td textarea {
      width: 100%;
      background-color: #f5f5f5;
      line-height: 18px;
      height: 180px;
      padding: 0 16px;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td .wpcf7-file {
      margin-top: 20px;
      letter-spacing: 0.03em;
      font-size: 10px;
      line-height: 1.6666;
      margin-bottom: -4px;
      margin-top: 13px;
      font-weight: 500;
      color: #303030;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td label {
      margin-top: 20px;
      letter-spacing: 0.03em;
      font-size: 14px;
      line-height: 1.6666;
      margin-bottom: -4px;
      margin-top: 13px;
      font-weight: 500;
      color: #303030;
    }

    .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody tr td input[type="text"] {
      width: 100%;
      background-color: #F5F5F5;
      line-height: 40px;
      height: 40px;
      padding: 0 20px;
      font-size: 14px;
      font-weight: 500;
    }

    .mobileBR {
      display: block;
    }
  }

  .contact .wpcf7-not-valid-tip,
  .contact .wpcf7-response-output,
  .contact div.wpcf7-acceptance-missing,
  .contact div.wpcf7-validation-errors {
    position: relative;
    display: inline-block;
    border: 0;
    margin: 22px 0;
    font-size: 13px;
    letter-spacing: 0.1em;
    color: red;
  }

  .p-contact-form .contact-form__inner .p-contact-form__wrap .p-contact-form__table tbody {
    position: relative;
    display: block;
    padding: 0px 0 52px;
  }
}