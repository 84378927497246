.recruit-complete {
  .complete {
    &--bg {
      width: 100%;
      background: transparent linear-gradient(108deg, #FDF7D3 0%, #F7ECFA 49%, #E2E6F4 100%) 0% 0% no-repeat padding-box;
      display: flex;
      height: 100vh;
      align-items: center;
      justify-content: center;
      column-gap: 10%;
      padding: 0 6.4vw;
      @media screen and (max-width: 1024px) {
        background: transparent linear-gradient(149deg, #FDF7D3 0%, #F7ECFA 49%, #E2E6F4 100%) 0% 0% no-repeat padding-box;
        flex-direction: column-reverse;
        padding: 0 5.79vw;

      }
    }
    &--text {
      height: fit-content;
    }
    &--title {
      font-size: 32px;
      margin-bottom: 19px;
      line-height: 1.5;
      @media screen and (max-width: 1024px) {
       font-size: 24px;
       margin-bottom: 24px;
      }
      @media screen and (max-width: 390px) {
        font-size: 22px;
        margin-bottom: 24px;
       }
    }
    &--description {
      font-size: 14px;
      margin-bottom: 32px;
      line-height: 1.5;
      @media screen and (max-width: 1024px) {
        font-size: 13px;
        margin-bottom: 24px;
       }
    }
    &--btn {
      width: 180px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #F5594C;
      &__text {
        color: white;
        font-size: 15px;
      }
    }
    &--hand {
      width: 428px;
      height: fit-content;
      object-fit: contain;
      @media screen and (max-width: 768px) {
        margin-bottom: 100px;
        width: 66.18%;
      }
    }
  }
}
