.moff-2023_parts {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;

  @media screen and (max-width: 1024px) {
    padding-top: 140px;
  }

  .image {
    &__wrapper {
      margin: 0 20px;
      max-width: 700px;
      height: auto;
      transition: 0.3s all ease-in-out;

      &:hover {
        transform: scale(1.03);
      }
    }

    &__desktop {
      display: block;

      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    &__mobile {
      display: none;

      @media screen and (max-width: 768px) {
        display: block;
      }
    }
  }

  .moff_button {
    border-style: solid;
    border-color: #347F6C;
    border-radius: 26px;
    height: 60px;
    width: 357px;
    border-width: 2px;
    margin-top: 32px;
    margin-bottom: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: #347F6C;
    }

    &:hover .button_text {
      color: #FFFFFF;
    }

    @media screen and (max-width: 576px) {
      height: 60px;
      width: 300px;
    }

    .button_text {
      text-align: center;
      color: #347F6C;
      font-size: 16px;
      font-weight: 600;

      @media screen and (max-width: 1024px) {
        font-size: 14px;
      }
    }
  }
}