// Animations variables
// $aos-distance: 100px !default;
$aos-distance: 80px !default;




/**
 * Fade animations:
 * fade
 * fade-up, fade-down, fade-left, fade-right
 * fade-up-right, fade-up-left, fade-down-right, fade-down-left
 */

[data-aos^='fade'][data-aos^='fade'] {
  opacity: 0;
  transition-property: opacity, transform;

  &.aos-animate {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

[data-aos='fade-up'] {
  transform: translate3d(0, $aos-distance, 0);
}

[data-aos='fade-down'] {
  transform: translate3d(0, -$aos-distance, 0);
}

[data-aos='fade-right'] {
  transform: translate3d(-$aos-distance, 0, 0);
}

[data-aos='fade-left'] {
  transform: translate3d($aos-distance, 0, 0);
}

[data-aos='fade-up-right'] {
  transform: translate3d(-$aos-distance, $aos-distance, 0);
}

[data-aos='fade-up-left'] {
  transform: translate3d($aos-distance, $aos-distance, 0);
}

[data-aos='fade-down-right'] {
  transform: translate3d(-$aos-distance, -$aos-distance, 0);
}

[data-aos='fade-down-left'] {
  transform: translate3d($aos-distance, -$aos-distance, 0);
}




/**
 * Zoom animations:
 * zoom-in, zoom-in-up, zoom-in-down, zoom-in-left, zoom-in-right
 * zoom-out, zoom-out-up, zoom-out-down, zoom-out-left, zoom-out-right
 */

[data-aos^='zoom'][data-aos^='zoom'] {
  opacity: 0;
  transition-property: opacity, transform;

  &.aos-animate {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1);
  }
}

[data-aos='zoom-in'] {
  transform: scale(.6);
}

[data-aos='zoom-in-up'] {
  transform: translate3d(0, $aos-distance, 0) scale(.6);
}

[data-aos='zoom-in-down'] {
  transform: translate3d(0, -$aos-distance, 0) scale(.6);
}

[data-aos='zoom-in-right'] {
  transform: translate3d(-$aos-distance, 0, 0) scale(.6);
}

[data-aos='zoom-in-left'] {
  transform: translate3d($aos-distance, 0, 0) scale(.6);
}

[data-aos='zoom-out'] {
  transform: scale(1.2);
}

[data-aos='zoom-out-up'] {
  transform: translate3d(0, $aos-distance, 0) scale(1.2);
}

[data-aos='zoom-out-down'] {
  transform: translate3d(0, -$aos-distance, 0) scale(1.2);
}

[data-aos='zoom-out-right'] {
  transform: translate3d(-$aos-distance, 0, 0) scale(1.2);
}

[data-aos='zoom-out-left'] {
  transform: translate3d($aos-distance, 0, 0) scale(1.2);
}




/**
 * Slide animations
 */

[data-aos^='slide'][data-aos^='slide'] {
  transition-property: transform;

  &.aos-animate {
    transform: translate3d(0, 0, 0);
  }
}

[data-aos='slide-up'] {
  transform: translate3d(0, 100%, 0);
}

[data-aos='slide-down'] {
  transform: translate3d(0, -100%, 0);
}

[data-aos='slide-right'] {
  transform: translate3d(-100%, 0, 0);
}

[data-aos='slide-left'] {
  transform: translate3d(100%, 0, 0);
}




/**
 * Flip animations:
 * flip-left, flip-right, flip-up, flip-down
 */

[data-aos^='flip'][data-aos^='flip'] {
  backface-visibility: hidden;
  transition-property: transform;
}

[data-aos='flip-left'] {
  transform: perspective(2500px) rotateY(-100deg);
  &.aos-animate {transform: perspective(2500px) rotateY(0);}
}

[data-aos='flip-right'] {
  transform: perspective(2500px) rotateY(100deg);
  &.aos-animate {transform: perspective(2500px) rotateY(0);}
}

[data-aos='flip-up'] {
  transform: perspective(2500px) rotateX(-100deg);
  &.aos-animate {transform: perspective(2500px) rotateX(0);}
}

[data-aos='flip-down'] {
  transform: perspective(2500px) rotateX(100deg);
  &.aos-animate {transform: perspective(2500px) rotateX(0);}
}

[data-aos='grow-right'] {
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #fff;
    transform-origin: right;
    transform: scaleX(1);
    content: "";
    transition-property: transform;
    // transition-duration: 800ms;
  }
  &.aos-animate {
    &::after {
      content: "";
      transform: scaleX(0);
      transform-origin: right;
      transition-property: transform;
      // transition-duration: 800ms;
    }
  }
}

[data-aos='grow-right-pc-only'] {
  @include desktop {
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: #fff;
      transform-origin: right;
      transform: scaleX(1);
      content: "";
      transition-property: transform;
      // transition-duration: 800ms;
    }
    &.aos-animate {
      &::after {
        content: "";
        transform: scaleX(0);
        transform-origin: right;
        transition-property: transform;
        // transition-duration: 800ms;
      }
    }
  }
}

[data-aos='grow-right2'] {
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #f8f8f8;
    transform-origin: right;
    transform: scaleX(1);
    content: "";
    transition-property: transform;
    // transition-duration: 800ms;
  }

  &.aos-animate {
    &::after {
      content: "";
      transform: scaleX(0);
      transform-origin: right;
      transition-property: transform;
      // transition-duration: 800ms;
    }
  }
}

[data-aos='grow-down'] {
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #fff;
    transform-origin: bottom;
    transform: scaleY(1);
    content: "";
    transition-property: transform;
    // transition-duration: 800ms;
  }
  &.aos-animate {
    &::after {
      content: "";
      transform: scaleY(0);
      transform-origin: bottom;
      transition-property: transform;
      // transition-duration: 800ms;
    }
  }
}

[data-aos='focus'] {
  filter: blur(5px);
  transform: scale(1.1) !important;
  &.aos-animate {
    filter: blur(0);
    transform: scale(1) !important;
  }
}

.page_title {
  overflow: hidden;
  height: 130px;
  @include desktop {
    &__text {
      position: relative;
      top: 40px;
      &--bisckets, &--culture, &--csr, &--company, &--about, &--recruit {
        top: 0;
      }
    }
    &--sises {
      height: 220px;
      .page_title__text {
        top: 0;
      }
    }
  }
}

.page_subtitle {
  @include tablet {
    position: absolute;
    left: calc(50% - 474px);
    top: -664px;
    display: block;
    color: #fff;
    font-size: 10px;
    line-height: 14px;

    &--bisckets {
      top: -550px;
      left: 7%;
    }

    &--sises {
      top: -735px;
      left: calc(50% - 502px);
    }

    &--explores {
       display: flex;
       align-items: center;
       left: calc(50% - 166px);
       top: 430px;
      //  transform: translateX(-50%) rotate(90deg);
    }

    &--csr {
      top: 318px;
      left: 624px;
    }

    &--culture {
      top: -600px;
    }

    &--company {
      display: flex;
      align-items: center;
      overflow: hidden;
      left: 93%;
      top: 234px;
      width: 290px;
      transform: translateX(-50%) rotate(90deg);
    }

    &--vision {
      display: flex;
      align-items: center;
      overflow: hidden;
      left: 50%;
      top: 350px;
      width: 292px;
      transform: translateX(-50%) rotate(90deg);
    }

    &--members {
      top: -664px;
      left: calc(50% - 502px);
    }

    &__text {
      display: block;
      color: white;
      font-size: 6px;
      line-height: 14px;
      font-weight: 400;
      transform-origin: top;
      letter-spacing: 0.1px;

      &--company {
        margin-left: 10px;
      }

      &--sises {
        margin-left: 10px;
      }

      &--members {
        display: none;
        // margin-left: 10px;
        // transform-origin: top;

      }
    }

    &__text--explores--sp {
      white-space: nowrap;
      font-size: 6px;
    }

    &__hline {
      position: absolute;
      right: 103%;
      top: 7px;
      display: block;
      width: 567px;
      height: 1px;
      background-color: #fff;
      transform-origin: left;

      &--explores--sp {
           position: absolute;
           right: 103%;
           top: 7px;
           display: block;
           width: 172px;
           height: 1px;
           background-color: #fff;
           transform-origin: left;
      }

      &--bisckets {
       display: none;
      }

      &--sises {
        position: absolute;
        right: 103%;
        top: 5px;
        display: block;
        width: 567px;
        height: 1px;
        background-color: #fff;
        transform-origin: left;
      }

      &--members {
        position: absolute;
        right: 103%;
        top: 5px;
        display: block;
        width: 567px;
        height: 1px;
        background-color: #fff;
        transform-origin: left;
      }

      &--culture {
        position: absolute;
        right: 103%;
        top: 5px;
        display: block;
        width: 567px;
        height: 1px;
        background-color: #fff;
        transform-origin: left;
      }
    }

    &__vline {
      display: block;
      width: 1px;
      height: 200px;
      background-color: #fff;

      &--company {
        // going to rotate
        width: 150px;
        height: 1px;
        margin-left: 28px;
        transform-origin: left;
      }

      &--vision {
        // going to rotate
        width: 200px;
        height: 1px;
        margin-left: 28px;
        transform-origin: left;
      }
    }
  }
  @include desktop {
    position: absolute;
    left: calc(50% - 474px);
    top: -664px;
    display: block;
    color: #fff;
    font-size: 10px;
    line-height: 14px;
    &--bisckets {
      top:-732px;
    }
    &--sises {
      top: -735px;
      left: calc(50% - 502px);
    }
    &--explores {
      top: 355px;
      left: calc(100% - 616px);
    }
    &--csr {
      top: 318px;
      left: 624px;
    }
    &--culture {
      top: -600px;
    }
    &--company {
      display: flex;
      align-items: center;
      overflow: hidden;
      left: 50%;
      top: 350px;
      width: 292px;
      transform: translateX(-50%) rotate(90deg);
    }
    &--vision {
      display: flex;
      align-items: center;
      overflow: hidden;
      left: 50%;
      top: 350px;
      width: 292px;
      transform: translateX(-50%) rotate(90deg);
    }

    &--members {
      top: -664px;
      left: calc(50% - 502px);
    }

    &__text {
      display: block;
      color: white;
      font-size: 9px;
      line-height: 14px;
      font-weight: 400;
      transform-origin: top;
      letter-spacing: 0.1px;
      &--company {
        
      }
      
      &--sises {
        margin-left: 10px;
      }

      &--members {
           display: block;
        margin-left: 10px;
        transform-origin: top;

      }
    }
    &__hline {
      position: absolute;
      right: 103%;
      top: 7px;
      display: block;
      width: 567px;
      height: 1px;
      background-color: #fff;
      transform-origin: left;
      &--explores {
        display: none;
      }
      &--bisckets {
        right: 103%;
      }

      &--sises {
        position: absolute;
        right: 103%;
        top: 5px;
        display: block;
        width: 567px;
        height: 1px;
        background-color: #fff;
        transform-origin: left;
      }

      &--members {
        position: absolute;
        right: 103%;
        top: 5px;
        display: block;
        width: 567px;
        height: 1px;
        background-color: #fff;
        transform-origin: left;
      }

      &--culture {
        position: absolute;
        right: 103%;
        top: 5px;
        display: block;
        width: 567px;
        height: 1px;
        background-color: #fff;
        transform-origin: left;
      }
    }
    &__vline {
      display: block;
      width: 1px;
      height: 200px;
      background-color: #fff;
      &--company {
        // going to rotate
        width: 150px;
        height: 1px;
        margin-left: 28px;
        transform-origin: left;
      }

      &--vision {
        // going to rotate
        width: 200px;
        height: 1px;
        margin-left: 28px;
        transform-origin: left;
      }
    }
  }
}

.animate {
  &_header {
    &__description {
      transform-origin: left;
      // transform: scaleX(100%); 
    }
    &__background {
      position: relative;
    }
  }
  // &_os_right__btn {
  //   transform-origin: left;
  // }

  &_top__hText {
    transform-origin: left;
  }

  &_carousel__info {
    transform-origin: left;
  }
}

.grow-right {
  position: relative;
  @include desktop {
    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: #fff;
      transition: transform 0.5s cubic-bezier(.64, .04, .35, 1);
    }
    &.is-shown {
      &:before {
        transform: scaleX(0);
        transform-origin: right;
        transition: transform 1s cubic-bezier(.64, .04, .35, 1);
      }
    }
  }
}

[data-aos='revealText'],
[data-aos='revealText']:before {
  @include desktop {
    animation-delay: 0;
    animation-iteration-count: 1;
    animation-duration: 1000ms;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(.4, 0, .2, 1);
  }
}

[data-aos='revealText'] {
  @include desktop {
    position: relative;
    display: inline-block;
    opacity: 0;
    &.aos-animate {
      animation-name: text;
      &:before {
        content: "";
        position: absolute;
        display: inline-block;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #000;
        transform: scaleX(0);
        transform-origin: 0 100%;
        pointer-events: none;
        animation-name: revealer;
      }
    }
  }
}

[data-aos='revealText--white'] {
  @extend [data-aos='revealText'];
  &.aos-animate {
    animation-name: textWhite;
    &:before {
      background-color: #fff;
    }
  }
}

@keyframes text {
	0% {
    clip-path: inset(0 100% 0 0);
	}
	50% {
    clip-path: inset(0 0 0 0);
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
 
@keyframes textWhite {
  0% {
    clip-path: inset(0 100% 0 0);
  }
  50% {
    clip-path: inset(0 0 0 0);
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes revealer {
	
	0%, 50% {
    transform-origin: 0 100%;
	}
	
	50%, 100% {
    transform-origin: 100% 100%;
	}
 
	
	50% {
    transform: scaleX(1);
  }
  
  0%, 50% {
    transform: scaleY(1);
  }
	
	100% {
    transform: scaleY(0);
	}
}

[data-aos='brightness'] {
  filter: brightness(200%);
  transform: scale(1.1);
  &.aos-animate {
    filter: brightness(100%);
    transform: scale(1);
  }
}