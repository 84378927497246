.global-nav {
  @include desktop {
    cursor: pointer;
    position: absolute;
    overflow: hidden;
    top: 32px;
    left: 36px;
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.l-header__sns {
  .sns-seperator {
    position: relative;
    padding-bottom: 28px;
    text-align: center;
    &::before {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      content: "";
      width: 20px;
      height: 1px;
      background-color: #aeaeae;
    }
  }
  .spin {
    margin-top: 14px;
    height: 20px;
  }
  .tells {
    width: auto;
    height: 23px;
  }
  .vibes {
    width: auto;
    height: 23px;
  }
}

.l-header {
  background-color: #fff;
  @include desktop {
    background: none;
  }

  .l-gnav-menu-icon {
    width: 24px;
    height: 24px;
    overflow: hidden;
    cursor: pointer;
    @include desktop {
      margin: 12px;
      width: 17px;
      height: 18px;
      overflow: hidden;
      pointer-events: auto;
    }
    > * {
      backface-visibility: hidden;
      will-change: transform;
    }
    .open {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      @include desktop {
        top: 12px;
        right: 12px;
        bottom: 12px;
        left: 12px;
        overflow: hidden;
      }
      .line {
        position: absolute;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #333;
        @include desktop {
          background-color: #fff;
        }
        &__cover {
          transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 2px;
          content: "";
          display: block;
          background-color: #fff;
          transition-property: transform;
          transition-duration: 1s;
          transform: translateX(-110%);
        }
        &--top {
          top: 0;
          transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
          transform: scaleX(1);
          transition-duration: 0.8s;
          transition-delay: 0.8s;
          transition-property: transform;
          transform-origin: left;
        }
        &--middle {
          transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
          top: 8px;
          transform: scaleX(1);
          transition-duration: 0.8s;
          transition-delay: 0.8s;
          transition-property: transform;
          transform-origin: left;
          .line__cover {
            transition-delay: 80ms;
          }
        }
        &--bottom {
          transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
          top: 16px;
          transform: scaleX(1);
          transition-duration: 0.8s;
          transition-delay: 0.95s;
          transition-property: transform;
          transform-origin: left;
          .line__cover {
            transition-delay: 0.1s;
          }
        }
      }
    }
    .close {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow: hidden;
      @include desktop {
        top: 13px;
        right: 13px;
        bottom: 13px;
        left: 11px;
      }
      .line {
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: #fff;
      }
      .line--right {
        transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
        display: block;
        right: 4px;
        top: 2px;
        background-color: #333;
        transform: rotate(-45deg) scaleX(0);
        transform-origin: right;
        transition-duration: 0.8s;
        transition-delay: 0.2s;
        @include desktop {
          right: 1px;
          top: 1px;
        }
        .line__cover {
          transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
          content: "";
          position: absolute;
          z-index: 1;
          left: 0;
          top: 50%;
          display: block;
          width: 100%;
          height: 2px;
          background-color: #fff;
          margin-top: -1px;
          margin-left: -1px;
          transform: translateX(-140%);
          transition-duration: 1.4s;
          transition-delay: 0.15s;
        }
      }
      .line--left {
        transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
        position: absolute;
        top: 19px;
        right: 4px;
        display: block;
        background-color: #333;
        transform: rotate(45deg) scaleX(0);
        transform-origin: right;
        transition-duration: 0.8s;
        transition-delay: 0.3s;
        @include desktop {
          top: 13px;
          right: 1px;
        }
        .line__cover {
          transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
          content: "";
          position: absolute;
          z-index: 1;
          left: 0;
          top: 50%;
          display: block;
          width: 17px;
          height: 2px;
          background-color: #fff;
          margin-top: -1px;
          margin-left: -1px;
          transform: translateX(-140%);
          transition-duration: 1.4s;
          transition-delay: 0.15s;
        }
      }
    }
  }
  .l-gnav-menu-icon.is-over {
    .open {
      .line {
        .line__cover {
          transform: translateX(110%);
        }
      }
    }
  }
  .l-gnav-menu-icon.is-open {
    .close {
      .line--right {
        transform: rotate(-45deg) scaleX(1);
        transition-delay: 0.6s;
        transition-property: transform;
      }
      .line--left {
        transform: rotate(45deg) scaleX(1);
        transition-delay: 0.8s;
        transition-property: transform;
      }
    }
    .open {
      .line--bottom {
        transition: 0.6s transform;
        transform: scaleX(0);
        transform-origin: right;
      }
      .line--middle {
        transition: 0.6s transform;
        transform: scaleX(0);
        transform-origin: right;
      }
      .line--top {
        transition: 0.6s transform;
        transform: scaleX(0);
        transform-origin: right;
      }
    }
  }
  .l-gnav-menu-icon.is-open.is-over {
    .close {
      .line--right {
        .line__cover {
          transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
          transform: translateX(150%);
          transition-duration: 1.4s;
          transition-delay: 0.2s;
          transition-property: transform;
        }
      }
      .line--left {
        .line__cover {
          transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
          transform: translateX(150%);
          transition-duration: 1.4s;
          transition-delay: 0.1s;
          transition-property: transform;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .l-header__gnav {
    &.is-open {
      pointer-events: auto;
      background-color: #fff;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      .header_gnav,
      .policy {
        opacity: 1;
      }
    }
    .header__gnav {
      position: absolute;
      top: -140px;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: fit-content;
      height: 235px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;

      .item {
        margin: 12px 0;
        text-align: center;
        opacity: 0;
        .link {
          position: relative;
          display: block;
          z-index: 999;
          .text {
            .bg {
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              background-color: #fff;
              content: "";
              -webkit-transform-origin: right;
              transform-origin: right;
              -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
            }
          }
        }
      }

      .second-level {
        &.second-level--event{
          .item{
            text-align: left;
            .text{
              font-weight: 500;
            }
          }
        }
      }

      .custom-margin{
        transform:translateY(10px);
      }
    }
  }
}

ul.second-level {
  display: none;
  font-size: 12px;
  line-height: 1.5;
  font-weight: 400;

  // padding: 0 0 0 2rem;
  &.second-level-open {
    // display: block;
    margin-bottom: -24px;
  }
  @include desktop {
    padding: 10px;
  }
  .second_li {
    white-space: nowrap;
    @include desktop {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
  .item .has_sub_a .text {
    font-size: 13px;
  }
}

.disable-click {
  pointer-events: none;
}
