.gdpr {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0px;
    border-radius: 4px;
    margin: 8px;
    padding: 16px;
    width: calc(100% - 16px);
    background-color: rgba(255,255,255,.96);
    z-index: 2000;
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
    // @media screen and (max-width: 1024px){
    //   bottom: 253px;

    // }
    // @media screen and (max-width: 500px){
    //   bottom: 158px;

    // }
    @include tablet {
        width: 360px;
    }
    &--hidden {
        display: none;
    }
    &__body {
        display: flex;
        margin-bottom: 16px;
    }
    &__img {
        width: 64px;
        height: 64px;
    }
    &__text {
        padding-right: 16px;
        width: 100%;
        font-size: 14px;
        line-height: 1.5;
    }
    &__menu {
        display: flex;
    }
    &__button {
        display: inline-flex;
        flex: 1 1;
        justify-content: center;
        align-items: center;
        min-width: 64px;
        min-height: 36px;
        border-radius: 4px;
        padding: 0 16px;
        background-color: $red;
        color: $white;
        font-size: .875rem;
        font-weight: 500;
        text-transform: uppercase;
        text-align: center;
        // box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12)
        &:not(:last-child) {
            margin-right: 16px;
        }
    }
    &__close {
        position: absolute;
        top: 0;
        right: 0;
        width: 48px;
        height: 48px;
        padding: 8px;
        border-radius: 100%;
        opacity: .3;
        &:hover {
            background-color: rgba(0,0,0,.1);
        }
    }
}
