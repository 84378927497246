@include desktop {
  .members_main {
    margin-top: 135px;
  }
  .background_image {
    background: url("../images/pc/top/background.jpg");
    background-repeat: no-repeat;
    background-position: center top;
    overflow: hidden;
    position: absolute;
    margin-top: -5px;
    height: 100%;
    width: calc(100% + 200px);
    left: -200px;
  }

  .members_right__title {
    position: absolute;
    top: -230px;
    left: 770px;
    width: 550px;

    &--vline {
      width: 1px;
      height: 260px;
      background: #242424;
      display: block;
      position: absolute;
      top: -420px;
      right: 401px;
    }
  }

  .members_header {
    position: relative;
    display: block;
    background: url(../images/pc/culture/cl_sideline.png);
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 50%;
    margin-bottom: 35px;
    z-index: 1;
  }
  .worldMap_text_wapper {
    position: relative;
    top: 100px;
    text-align: center;
    z-index: 1;
    .worldMap_text {
      font-size: 64px;
      font-weight: bold;
    }
  }
  .world_map_wapper {
    position: relative;
    top: 250px;
    margin: 0 115px;
    height: 46.7vw;
    // &:hover .animate-fading {
    //   animation-play-state: paused;
    //   opacity: 1;
    // }
    .world_map_inner {
      position: absolute;
      .image {
        width: 100%;
      }
      .country_text {
        position: absolute;
        top: -120px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 64px;
        font-weight: bold;
        white-space: nowrap;
      }
      .university_text {
        position: absolute;
        top: -120px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 64px;
        font-weight: bold;
        white-space: nowrap;
      }
      .myBtn {
        position: absolute;
        z-index: 1001;
        background-color: #205fed;
        width: 31px;
        height: 31px;
        border-radius: 50%;
        transition-duration: 0.4s;
        &_japan {
          top: 76%;
          left: 50.7%;
        }
        &_us {
          top: 43%;
          left: 88%;
        }

        &:hover {
          background-color: #242424;
        }
        &:focus {
          background-color: #242424;
        }
        &:focus .myBtn__seeall {
          color: #242424;
          text-decoration: underline;
        }

        &__seeall {
          position: absolute;
          left: -55px;
          top: 8.5px;
          color: #205fed;
          font-size: 14px;
          &:hover {
            color: #242424;
          }
        }
        &__number {
          position: absolute;
          left: 4px;
          top: 9px;
          color: white;
          font-size: 11px;
        }
      }

      .modal {
        display: none; /* Hidden by default */
        position: absolute; /* Stay in place */
        z-index: 1001; /* Sit on top */
        width: 276px;
        height: 272.96px;
        background: url("../images/common/dialog_point.svg");
        &__japan {
          top: 78%;
          left: 51%;
        }
        &__us {
          top: 47%;
          left: 84%;
        }

        .close {
          position: absolute;
          color: #aaaaaa;
          left: 240px;
          top: 34.5px;
          font-size: 24px;
          &:hover {
            color: #ffffff;
            text-decoration: none;
            cursor: pointer;
          }
        }
        .modal-content {
          color: #ffffff;
          margin-top: 17.5px;
          margin-left: 30px;
          margin-right: 5px;
          .modal-title-container {
            .modal-title {
              color: #ffffff;
              font-size: 16px;
              padding: 20px 0px;
            }
          }
          .modal-text-container {
            overflow: scroll;
            height: 175px;
            .modal-text {
              font-size: 14px;
              line-height: 30px;
            }
          }
        }
      }
    }

    .animate-fading {
      animation: fading 10s infinite;
    }
    @keyframes fading {
      0% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }

  .members_header__background {
    background: url(../images/pc/header/ms_main.jpg),
      url(../images/pc/member/ms_lines.png);
    background-size: cover, cover;
    background-blend-mode: hard-light;
    height: 900px;
    width: 60%;
  }

  .members_circle_img {
    position: relative;

    &::before {
      content: "";
      width: 54px;
      height: 54px;
      background-color: #242424;
      display: block;
      position: absolute;
      left: -122px;
      top: -258px;
      opacity: 0.5;
      mix-blend-mode: overlay;
      z-index: 2;
    }
  }

  .members_circles {
    position: absolute;
    top: -279px;
    left: -173px;
  }

  .members-carousel__item--info {
    display: flex;
    justify-content: center;
    flex-direction: column;
    display: -webkit-flex;
    -webkit-justify-content: center;
    -webkit-flex-direction: column;
    right: calc(40% - 3vw);
    top: -900px;
    position: absolute;
    width: 625px;
    height: 440px;
  }
}

.members_right__title {
  @include desktop {
    position: absolute;
    top: -230px;
    left: 690px;
    width: 550px;
  }

  @include l-desktop {
    position: absolute;
    top: -230px;
    left: 770px;
    width: 550px;

    &--vline {
      width: 1px;
      height: 260px;
      background: #242424;
      display: block;
      position: absolute;
      top: -420px;
      right: 401px;
    }
  }
}

#page_title_members {
  @include desktop {
    position: absolute;
    top: -660px;
    left: calc(60% - 522px);
    width: 100%;
    font-size: 70px;
    color: white;
    z-index: 3;
    font-weight: bold;

    &::before {
      content: "";
      width: 49px;
      height: 149px;
      background-color: #de2626;
      display: block;
      position: absolute;
      left: -14px;
      top: 0;
      opacity: 0.5;
      mix-blend-mode: overlay;
      z-index: 2;
    }
  }
  @include l-desktop {
    position: absolute;
    top: -659px;
    left: calc(60% - 657px);
    width: 100%;
    font-size: 70px;
    color: white;
    z-index: 3;
    font-weight: bold;

    &::before {
      content: "";
      width: 49px;
      height: 149px;
      background-color: #de2626;
      display: block;
      position: absolute;
      left: -14px;
      top: 0;
      opacity: 0.5;
      mix-blend-mode: overlay;
      z-index: 2;
    }
  }
}

.members_header_description {
  @include desktop {
    position: absolute;
    left: calc(60% - 606px);
    top: -571px;
    width: 560px;
    height: 342px;
    padding: 50px 32px 50px 65px;
    background: $red;
    z-index: 2;
    color: #fff;
    -webkit-box-shadow: -2px 10px 4px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: -2px 10px 4px 0px rgba(0, 0, 0, 0.1);
    box-shadow: -2px 10px 4px 0px rgba(0, 0, 0, 0.1);
  }
  @include l-desktop {
    position: absolute;
    left: calc(60% - 680px);
    top: -571px;
    width: 615px;
    height: 342px;
    padding: 50px 32px 50px 65px;
    background: $red;
    z-index: 2;
    color: #fff;
    -webkit-box-shadow: -2px 10px 4px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: -2px 10px 4px 0px rgba(0, 0, 0, 0.1);
    box-shadow: -2px 10px 4px 0px rgba(0, 0, 0, 0.1);
  }
}

.members_text {
  @include desktop {
    font-size: 14px;
    line-height: 1.6;
    letter-spacing: 0.03em;
    max-width: 518px;
    /* max-height: 176px; */
    font-family: "Noto Sans JP", sans-serif;
  }

  @include l-desktop {
    font-size: 15px;
    line-height: 1.6;
    letter-spacing: 0.03em;
    max-width: 518px;
    /* max-height: 176px; */
    font-family: "Noto Sans JP", sans-serif;
  }
}

.members_carousel__nav {
  @include desktop {
    position: absolute;
    top: -477px;
    left: 60px;
    width: 700px;
    display: inline-block;
    color: white;

    &--title {
      margin: 0 0 0 33px;
    }
  }

  @include l-desktop {
    position: absolute;
    top: -477px;
    width: 700px;
    display: inline-block;
    color: white;

    &--title {
      margin: 0 0 0 33px;
    }
  }
}

.members-carousel__item--info {
  .members-carousel__item--container {
    @include desktop {
      width: 100%;
      padding: 77px 128px 64px 40px;
      background-color: #fff;
      position: absolute;
      left: -25px;

      .num_data {
        font-weight: bold;
      }
    }

    @include l-desktop {
      padding: 77px 32px 64px 49px;
      background-color: #fff;

      .num_data {
        font-weight: bold;
      }
    }
  }
}

.members-carousel__title {
  @include desktop {
    position: absolute;
    top: -960px;
    left: 40px;
    text-align: center;
    z-index: 1;
    font-size: 30px;
    font-weight: bold;
  }

  @include l-desktop {
    position: absolute;
    top: -945px;
    left: 15%;
    text-align: center;
    z-index: 1;
    font-size: 30px;
    font-weight: bold;
  }
}

@media screen and (max-width: 1024px) {
  #page_title_members {
    position: relative;
    overflow: visible; // Use this when the before/after subtitle message looks weird
    margin-top: auto;
    width: 100%;
    height: initial;
    padding: 20px 20px 20px 50px;
    color: white;
    font-size: 50px;
    line-height: 1.5;
    font-weight: bold;

    &::before {
      content: "";
      height: 183px;
      width: 1px;
      background-color: #fff;
      position: absolute;
      top: -170px;
      left: 32px;

      @media screen and (max-width: 350px) {
        top: 40px;
        left: 22px;
      }
    }

    &::after {
      position: absolute;
      top: 44px;
      // left: -8px;
      left: -4px;
      transform: rotate(90deg) scale(0.6);
      // width: 88px;
      color: #fff;
      font-size: 9px;
      font-weight: 100;
      content: "OUR COMMUNITY";

      @media screen and (max-width: 350px) {
        top: 250px;
        left: -15px;
      }
    }
  }

  .members_header_description {
    color: #fff;
    display: flex;
    .members_text {
      padding: 20px 20px 68px 40px;
      font-size: 12px;
      line-height: 1.5;
      font-weight: 300;
      max-width: 650px;
      @include tablet {
        padding: 20px 20px 204px 40px;
      }
    }
  }

  .members_text {
    font-size: 12px;
    line-height: 1.6;
    letter-spacing: 0.03em;
    max-width: 650px;
  }

  .members_header {
    position: relative;
    display: block;
    padding-top: 60px;
    height: 100vh;
    &__background {
      position: absolute !important;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: url(../images/sp/members/ms_mobile.jpg);
      background-repeat: no-repeat;
      background-size: cover;
    }
    &__text-container {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      // align-items: center;
      height: calc(100vh - 60px);
    }
  }
  .worldMap_text_wapper {
    position: relative;
    top: 20px;
    left: 10px;
    .worldMap_text {
      font-size: 20px;
      font-weight: bold;
      @include tablet {
        font-size: 30px;
      }
    }
  }

  .world_map_wapper {
    position: relative;
    top: 90px;
    margin: 0 10px;
    height: calc(180vw - 140px);
    // height: 180vw;
    // &:hover .animate-fading {
    //   animation-play-state: paused;
    //   opacity: 1;
    // }
    @media screen and (min-width: 769px) {
      height: 55vw;
    }
    .world_map_inner {
      position: absolute;
      .image {
        width: 100%;
        @media screen and (max-width: 768px) {
          position: relative;
          top: -140px;
        }
      }
      .country_text {
        position: absolute;
        top: -70px;
        left: 50%;
        font-size: 20px;
        font-weight: bold;
        transform: translateX(-50%);
        white-space: nowrap;
        @media screen and (min-width: 769px) {
          font-size: 40px;
        }
      }
      .university_text {
        position: absolute;
        top: -70px;
        left: 50%;
        font-size: 20px;
        font-weight: bold;
        transform: translateX(-50%);
        white-space: nowrap;
        @media screen and (min-width: 769px) {
          font-size: 40px;
        }
      }
      .myBtn {
        position: absolute;
        z-index: 1001;
        background-color: #205fed;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        transition-duration: 0.4s;
        @media screen and (max-width: 768px) {
          display: none;
        }
        &_japan {
          top: 78%;
          left: 51%;
          // @media screen and (max-width: 768px) {
          //   top: calc(66.5% - 140px);
          //   left: 80.5%;
          // }
        }
        &_us {
          top: 47%;
          left: 84%;
          // @media screen and (max-width: 768px) {
          //   top: calc(29% - 140px);
          //   left: 88%;
          // }
        }

        &:hover {
          background-color: #242424;
        }
        &:focus {
          background-color: #242424;
        }
        &:focus .myBtn__seeall {
          color: #242424;
          text-decoration: underline;
        }

        &__seeall {
          position: absolute;
          left: -43px;
          top: 6px;
          color: #205fed;
          font-size: 12px;
          &:hover {
            color: #242424;
          }
        }
        &__number {
          position: absolute;
          left: 4px;
          top: 9px;
          color: white;
          font-size: 9px;
        }
      }

      .modal {
        display: none; /* Hidden by default */
        position: fixed; /* Stay in place */
        z-index: 1002; /* Sit on top */
        // padding-top: 100px;
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
        .close {
          position: fixed;
          color: #aaaaaa;
          right: 10px;
          top: 1%;
          font-size: 24px;
          cursor: pointer;
        }
        .modal-content {
          background-color: rgba(0, 0, 0, 0.9);
          border-radius: 26px;
          margin: auto;
          padding: 20px;
          border: 1px solid #888;
          width: 100%;
          height: 100%;
          color: #ffffff;
          .modal-title-container {
            text-align: center;
            margin-bottom: 20px;
            .modal-title {
              font-size: 16px;
            }
          }
          .modal-text-container {
            height: 95%;
            overflow: auto;
            .modal-text {
              font-size: 14px;
              line-height: 30px;
            }
          }
        }
      }
    }

    .animate-fading {
      animation: fading 10s infinite;
    }
    @keyframes fading {
      0% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }

  .certifications {
    background: #242424;
  }

  .women_will {
    background: $red;
  }

  .statistics_item {
    display: flex;
    min-height: 475px;
    padding-top: 50px;

    &__left {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 90px;
    }

    &__right {
      img {
        margin-left: -30px;
        max-width: calc(100% + 30px);
        padding-top: 40px;
        padding-bottom: 60px;
      }
    }

    &__number {
      color: #fff;
      font-size: 15px;
      line-height: 17px;
      padding-bottom: 5px;

      &--black {
        color: #242424;
      }
    }

    &__line {
      border-right: 1px solid white;
      height: 160px;

      &--black {
        border-color: #242424;
      }
    }

    &__title {
      color: #fff;
      font-size: 20px;
      line-height: 30px;
      transform: rotate(90deg);
      margin-top: 50%;
      text-transform: uppercase;
      white-space: nowrap;

      &--black {
        color: #242424;
      }

      &--certifications {
        padding-left: 70px;
      }

      &--nationalities {
        padding-left: 40px;
      }

      &--women_will {
        padding-left: 35px;
      }

      &--young_global {
        padding-left: 70px;
      }
    }

    &__content {
      color: #fff;
      font-size: 13px;
      font-weight: 700;
      line-height: 19px;
      padding-bottom: 35px;

      p {
        line-height: 21px;
      }

      &--black {
        color: #242424;
      }
    }

    &__content_number {
      font-size: 38px;
      line-height: 38px;
      padding-bottom: 3px;

      .percentage {
        font-size: 13px;
        font-size: 18px;
        padding-left: 1px;
      }
    }
  }
}

@media screen and (min-width: 769px) {
  .u-sp-only-map {
    display: none !important;
  }
}

@media screen and (max-width: 768px) {
  .u-pc-only-map {
    display: none !important;
  }
}
