.recruit {
  .recruit-btn {
   width: 170px;
   height: 60px;
   border-radius: 35px;
   background-color: #f21515;
   position: fixed;
   right: 25px;
   bottom: 55px;
   z-index: 3;
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
   box-shadow: 0 1px 15px #0c0c0c;
   &:hover {
    background-color: #9b2b2b;
   }
   @media screen and (max-width: 1024px) {
    width: 140px;
    height: 50px;
    right: 50%;
    transform: translateX(50%);
    bottom: 12px;
   }
   &__text {
    font-size: 18px;
    color: #ffffff;
    @media screen and (max-width: 768px) {
      font-size: 16px;
    }
   }
  }
  .recruit-top {
    position: relative;
    width: 100%;
    height: auto;
    background-color: black;
    &__video {
      &--desktop {
        width: 100%;
        object-fit: contain;
        @media screen and (max-width: 1024px) {
          padding-top: 60px;
        }
      }
      &--mobile {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        object-fit: cover;
        margin-top: 60px;
        height: 495px;
        width: auto;
      }
    }
    &__image {
      width: 100%;
      object-fit: contain;
      transform: translateY(-3px);
      &--desktop {
        @media screen and (max-width: 819px) {
          display: none;
        }
      }
      &--mobile {
        display: none;
        @media screen and (max-width: 819px) {
          display: block;
        }
      }
      &--whale {
        position: absolute;
        z-index: 1;
        width: 46%;
        left: -4%;
        top: -44%;
        @media screen and (max-width: 819px) {
          transform: rotate(-34deg) scaleY(-1);
          width: 319px;
          left: -20px;
          top: -126px;
        }
      }
      &--cloud {
        position: absolute;
        width: 43%;
        right: -3.5%;
        top: -28%;
        z-index: 1;
        @media screen and (max-width: 819px) {
          display: none;
        }
      }
    }
    &__text {
      position: absolute;
      top: 20%;
      left: 6.6%;
      color: #ffffff;
      width: 100%;

      @media screen and (max-width: 819px) {
        transform: translateX(-50%);
        left: 50%;
        text-align: center;
      }

      &--title {
        font-size: 67px;
        font-weight: bold;
        font-family: adobe-hebrew, sans-serif;
        @media screen and (max-width: 1440px) {
          font-size: 48px;
        }
        @media screen and (max-width: 1024px) {
          font-size: 50px;
        }
        @media screen and (max-width: 992px) {
          font-size: 40px;
        }
      }
      &--description {
        margin-top: 24px;
        font-size: 18px;
        line-height: 1.5;
        font-weight: normal;
        font-family: kozuka-gothic-pr6n, sans-serif;

        @media screen and (max-width: 992px) {
          font-size: 14px;
        }
      }
    }
  }
  .recruit-movies {
    width: 100%;
    height: 100%;
    max-height: 532px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 12px;
    background-color: black;
    padding: 32px 24px;
    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      row-gap: 12px;
    }
    &__container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &__bg {
        width: 100%;
      }
      &__btn {
        position: absolute;
        width: 40px;
        height: 40px;
        background-color: #1167F2;
        border-radius: 50%;
        display: grid;
        place-items: center;
        transition: all 0.3s ease-out;
        cursor: pointer;
        &:hover {
          transform: scale(1.05);
          transition: all 0.3s ease-out;
        }

        > svg {
          margin-left: 2.5px;
          margin-top: 2px;
        }
      }
    }
    .creative {
      @media screen and (max-width: 768px) {
        grid-row: 1;
        grid-column: 1 /3;
        padding: 0 20%;
      }
    }
    &__soon {
      width: 100%;
      height: 100%;
      background-color: #D5D4D3;
      display: flex;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: 768px) {
        height: unset;
        aspect-ratio: 16 / 9;
      }
      &__text{
        position: absolute;
        color: black;
      }
    }
    &__title {
      position: relative;
      color: white;
      margin-top: 8px;
      font-family: YuMincho, sans-serif;
      font-size: 16px;
      @media screen and (max-width: 768px) {
        font-size: 13px;
      }
    }
  }
  .recruit_video{
    display: none;
    &--bg{
      position: fixed;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.9);
      z-index: 101;
    }
    &--close {
      position: relative;
      top: -30px;
      align-self: flex-end;
      height: auto;
      margin-right: 3vw;
      font-size: 18px;
      color: white;
    }
    &--container {
      position: relative;
      // top: 50vh;
      transform: translateY(-21px);
      width: 100%;
      padding-bottom: 56.25%; /* 16:9 */
      &__video {
        position: absolute;
        // height: 582px;
        z-index: 3;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
      }
    }
  }

  .recruit-jobs {
    position: relative;
    height: 867px;
    display: flex;
    justify-content: center;
    @media screen and (max-width: 1190px) {
      height: 690px;
    }
    @media screen and (max-width: 819px) {
      height: fit-content;
    }
    &__title {
      position: absolute;
      text-align: center;
      margin-bottom: 24px;
      color: white;
      font-weight: bold;
      z-index: 1;
      margin-top: 40px;
      font-family: adobe-hebrew, sans-serif;
      font-size: 48px;
      @media screen and (max-width: 1190px) {
        font-size: 40px;
      }
      @media screen and (max-width: 819px) {
        font-size: 24px;
      }
    }
    &__bg {
      width: 100%;
      height: 100%;
    }
    &__list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 30px;
      position: absolute;
      top: 15%;
      z-index: 2;
      left: 50%;
      transform: translateX(-50%);
      @media screen and (max-width: 1190px) {
        column-gap: 20px;
      }
      &--mobile {
        width: 100%;
        position: relative;
        background-image: url(https://assets.free-will.co/images/recruit/job-bg-mobile.png);
        padding: 85px 8vw 60px
      }
      &--bg {
        width: 350px;
        height: 690px;
        background-color: black;
        padding-top: 30px;
        @media screen and (max-width: 1190px) {
          width: 252px;
          height: 540px;
        }
        @media screen and (max-width: 819px) {
          width: 100%;
          height: auto;
          background-color: unset;
        }
      }
      &--text {
        font-size: 20px;
        line-height: 2;
        color: white;
        text-align: center;
        font-family: kozuka-gothic-pr6n, sans-serif;
        @media screen and (max-width: 1190px) {
          font-size: 16px;
        }
      }
    }
    ol {
      padding: unset;
    }
  }

  .recruit-sim {
    position: relative;
    height: 600px;
    @media screen and (max-width: 819px) {
      height: 222px;
    }
    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: left;
    }
    &__text {
      position: absolute;
      top: 30%;
      left: 10%;
      &--title {
        font-size: 48px;
        font-family: YuMincho, sans-serif;
        color: white;
        margin-bottom: 24px;
        @media screen and (max-width: 819px) {
          font-size: 18px;
          line-height: 1.2;
        }
      }
      &--subtitle {
        font-size: 45px;
        font-family: YuMincho, sans-serif;
        color: white;
        @media screen and (max-width: 819px) {
          font-size: 13px;
        }
      }
    }
    &__btn {
      width: 200px;
      height: 48px;
      background-color: #f21515;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 24px;
      @media screen and (max-width: 819px) {
        width: 126px;
        height: 38px;
      }

      &--text {
        font-size: 18px;
        color: white;
        font-family: YuMincho, sans-serif;
        @media screen and (max-width: 819px) {
          font-size: 13px;
        }
      }
    }
  }

  .recruit-article {
    position: relative;
    background-color: #01100D;
    padding: 24px 60px 60px;
    @media screen and (max-width: 525px) {
      padding: 24px 0px 60px;
    }
    .slider {
      position: relative;
      max-width: 1635px;
      margin: auto;
      overflow: hidden;
      @media screen and (max-width: 525px) {
        overflow-x: auto;
        &::-webkit-scrollbar {
          display: none;
        }
      }

      .slides {
        display: flex;
        transition: transform 0.5s ease;
        column-gap: 30px;
        @media screen and (max-width: 525px) {
         column-gap: 10px;
        }
      }

      .slide {
        width: 380px;
        flex-shrink: 0;
        @media screen and (max-width: 525px) {
          width: calc(100vw - 35px)
         }
      }

      .slide-btn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-color: rgba(0,0,0,0.5);
        border: none;
        color: white;
        padding: 18px 21px;
        border-radius: 50%;
        cursor: pointer;
        z-index: 10;

        @media screen and (max-width: 525px) {
          display: none;
        }

        &:hover {
          background-color: rgba(0,0,0,0.8);
        }

        &.prev {
          left: 0;
          display: none;
        }

        &.next {
          right: 0;
        }
      }
    }

    &__title {
      font-size: 48px;
      font-weight: bold;
      color: white;
      text-align: center;
      margin-bottom: 30px;
      @media  screen and (max-width: 820px) {
        font-size: 24px;
      }
    }
    &__card {
      display: flex;
      flex-direction: column;
      width: fit-content;
      background-color: #E8E7E7;
      padding: 24px;
      height: 690px;
      justify-content: space-between;
      &--title {
        text-align: center;
        font-size: 18px;
        font-family: YuMincho, sans-serif;
      }
      &--img {
        height: 180px;
        width: 100%;
        object-fit: cover;
        margin: 24px 0;
      }
      &--description {
        font-size: 14px;
        line-height: 24px;
        @media screen and (max-width: 525px) {
          font-size: 13px;
          line-height: 22px;
        }
      }
      &--btn {
        width: 120px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #000000;
        margin: 0 auto;
        &__text {
          color: white;
        }
      }
    }
  }

  .recruit-seido {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 525px) {
      height: 235px;
    }
    &__bg {
      @media screen and (max-width: 525px) {
        object-fit: cover;
        height: 235px;
      }
      &--overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
    &__text {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: white;
      max-width: 770px;
      padding: 0 6.4vw;
    }
    &__title {
      text-align: center;
      font-family: YuMincho, sans-serif;
      font-size: 48px;
      font-weight: 800;
      margin-bottom: 32px;
      @media screen and (max-width: 768px) {
        font-size: 32px;
      }
      @media screen and (max-width: 525px) {
        font-size: 24px;
        margin-bottom: 24px;
      }
    }
    &__description {
      text-align: center;
      font-size: 24px;
      line-height: 1.4;
      @media screen and (max-width: 768px) {
        font-size: 14px;
      }
    }
  }

  .recruit-points {
    padding: 150px 9vw;
    background-image: url(https://assets.free-will.co/images/recruit/seido-bg-desktop-2.png);
    color: #ffffff;
    background-position: center;

    @media screen and (max-width: 768px) {
      background-image: url(https://assets.free-will.co/images/recruit/seido-bg-mobile-2.png);
      background-position: bottom;
    }
    &__title {
      font-family: YuMincho, sans-serif;
      font-size: 48px;
      font-weight: 800;
      margin-bottom: 32px;
      @media screen and (max-width: 1024px) {
        font-size: 40px;
      }
      @media screen and (max-width: 768px) {
        font-size: 20px;
        text-align: center;
        margin-top: 12px;
        margin-bottom: 24px;
      }
    }
    &__description {
      font-family: Kozuka Gothic Pr6N, sans-serif;
      font-size: 18px;
      line-height: 1.4;
      @media screen and (max-width: 1024px) {
        font-size: 16px;
      }
      @media screen and (max-width: 768px) {
        font-size: 14px;
      }
    }
    &__location {
      display: flex;
      justify-content: space-between;
      column-gap: 200px;
      margin-bottom: 80px;
      @media screen and (max-width: 1024px) {
        column-gap: 40px;
      }
      @media screen and (max-width: 768px) {
        flex-direction: column-reverse;
        align-items: center;
      }
      &--text {
        max-width: 900px;
      }
      &--img {
        width: 200px;
        object-fit: contain;
        @media screen and (max-width: 768px) {
          width: 125px;
        }
      }
    }
    &__division {
      display: flex;
      justify-content: space-between;
      column-gap: 150px;
      margin-bottom: 80px;
      @media screen and (max-width: 1024px) {
        column-gap: 40px;
      }
      @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: center;
      }
      &--text {
        max-width: 900px;
      }
      &--img {
        width: 250px;
        object-fit: contain;
        @media screen and (max-width: 768px) {
          width: 135px;
        }
      }
    }
    &__training {
      display: flex;
      justify-content: space-between;
      margin-bottom: 80px;
      column-gap: 50px;
      @media screen and (max-width: 1024px) {
        column-gap: 40px;
      }
      @media screen and (max-width: 768px) {
        flex-direction: column-reverse;
        align-items: center;
      }
      &--text {
        max-width: 900px;
      }
      &--img {
        width: 200px;
        object-fit: contain;
        @media screen and (max-width: 768px) {
          width: 118px;
        }
      }
    }
    &__person {
      display: flex;
      justify-content: space-between;
      margin-bottom: 80px;
      column-gap: 150px;
      @media screen and (max-width: 1024px) {
        column-gap: 40px;
      }
      @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: center;
      }

      &--text {
        max-width: 900px;
      }
      &--img {
        width: 150px;
        object-fit: contain;
        @media screen and (max-width: 768px) {
          width: 77px;
        }
      }
    }
  }



  .recruit-members {
    background-color: #01100D;
    padding-bottom: 100px;
    .members_member_headline {
      color: white !important;
    }
    .members_li {
      color: white !important;
    }
    .members_section {
      padding: 0 0 80px !important;
    }
    &__btn {
      width: 200px;
      height: 67px;
      background-color: #f21515;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      &--text {
        color: white;
        font-size: 18px;
      }
    }
  }

  .recruit-explain {
    position: relative;
    min-height: 470px;
    &__overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.1);
    }
    &__text {
      position: absolute;
      top: 8%;
      left: 10%;
      color: white;
      max-width: 600px;
      @media  screen and (max-width: 768px) {
        padding: 0 6.6vw;
        left: 0;
      }
    }
    &__img {
      min-height: 470px;
      object-fit: cover;
    }
    &__title {
      font-size: 48px;
      margin-bottom: 24px;
      font-family: YuMincho, sans-serif;
      @media  screen and (max-width: 768px) {
        margin-bottom: 18px;
        font-size: 24px;
      }
    }
    &__description {
      font-size: 16px;
      line-height: 1.4;
      @media  screen and (max-width: 768px) {
        font-size: 14px;
      }
    }
    &__btn {
      width: 120px;
      height: 40px;
      background-color: #f21515;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 24px;
      &--text {
        font-size: 16px;
        color: white;
      }
    }
  }

  #recruit_creative_video,#recruit_engineer_video  {
    position: absolute;
    z-index: 2;
    display: none;
  }

  #recruit_creative_video--mobile,#recruit_engineer_video--mobile  {
    display: none;
  }


  @media screen and (min-width: 820px) {
    .u-sp-only-recruit {
      display: none !important;
    }
  }

  @media screen and (max-width: 819px) {
    .u-pc-only-recruit {
      display: none !important;
    }
  }
}
