@include desktop {

    /* Global engineering page */
    #page_title_bisckets {
        position: absolute;
        top: -680px;
        left: calc(50% - 480px);
        width: 100%;
        font-size: 95px;
        color: white;
        z-index: 3;
        font-weight: bold;

          &::before {
              content: 'BUSINESS, IDENTITY, STRATEGY, CONSULTING, KNOWLEDGE, ENGINEERING, TECHNOLOGY,  SPECIALITY';
              width: 435px;
              height: 0px;
              background-color: #fff;
              position: absolute;
              top: -33px;
              left: 20px;
              font-size: 9px;
              font-weight: 100;

          }
    }

    .bisckets_header_description {
        position: absolute;
        left: calc(50% - 495px);
        top: -545px;
        max-width: 605px;
        height: 318px;
        padding: 50px 49px 48px 56px;
        background: $red;
        z-index: 2;
        color: #fff;
        box-shadow: -2px 10px 4px 0px rgba(0, 0, 0, 0.1);

        &::before {
            content: '';
            width: 84px;
            height: 268px;
            background-color: #242424;
            display: block;
            position: absolute;
            top: 88px;
            right: -45px;
            opacity: .5;
            mix-blend-mode: overlay;
        }
    }

     .bisckets_right__title {
            position: absolute;
            top: -430px;
            left: 770px;
            width: 550px;
            font-size: 30px;
            font-weight: bold;
            color: #fff;
            &--vline {
                position: absolute;
                top: -330px;
                right: 464px;
                display: block;
                width: 1px;
                height: 290px;
                background: linear-gradient(#ff5271, #951f17);
                transform-origin: top;
            }
            &-text {
                display: block;
                transform-origin: top;
            }
        //    &::before {
        //         content: '';
        //         width: 1px;
        //         height: 290px;
        //         background: linear-gradient(#ff5271, #951f17);
        //         display: block;
        //         position: absolute;
        //         top: -330px;
        //         right: 464px;
        //    }
     }

    .bisckets_right__btn {
        position: absolute;
        top: -370px;
        left: 640px;
        width: 550px;
        color: #fff;

        &--div1 {
            margin-top: 30px;
        }

        &--div2 {
            margin-top: 80px;
        }

        .bisckets_btn__title {
            margin: 55px 0 0 60px;
            top: 0%;
            left: 0;
            transition: 0.6s ease-in-out;
            transition-delay: 0.2s;
            text-align: left;
            font-size: 12px;
            color: #fff;

            .bisckets_btn__num {
                position: relative;
                left: 15px;
                top: -20px;
            }
        }
    }

    .bisckets_text {
        font-size: 15px;
        line-height: 1.5;
        letter-spacing: 0.03em;
        max-width: 500px;
        font-family: 'Noto Sans JP',
        sans-serif;
        font-weight: 400;
    }

    .bisckets_header {
        position: relative;
        display: block;
        z-index: 1;
        background: url(../images/pc/biskets/bsc_side.png) top right no-repeat #242424;
        background-size: 50%;
        // background: url(../images/pc/biskets/bsc_side.png);
        // background-size: cover;

        &__border {
            width: calc(50% - 460px);
            height: 1px;
            background-color: #fff;
            position: absolute;
            top: 175px;
            left: 0;
        }
    }

    .bisckets_header__background {
        position: relative;
        background: url(../images/pc/biskets/bsc_main.png);
        background-size: cover;
        width: 51.6%;
        height: 900px;

            // width: 100%;
            // height: 900px;
            // position: relative;

        &::before {
            // background: url(../images/pc/biskets/bsc_side.png);
            // background-size: cover;
            top: 0;
            left: 51%;
            width: 49%;
            height: 100%;
            content: '';
            position: absolute;

                // left: 53%;
                // width: 55%;
        }
    }

    .bisckets_header__whiteBox {
        width: calc(100% - 175px);
        height: 300px;
        background-color: #fff;
        position: absolute;
        top: 800px;
        left: 0;
        z-index: 10;
    }

    .bisckets_circle_img1 {
        position: relative;
    }

    .bisckets_circles1 {
        position: absolute;
        top: -285px;
        left: -64px;
    }

    .bisckets_circle_img2 {
        position: relative;

        &::before {
            content: '';
            width: 58px;
            height: 141px;
            background-color: #242424;
            display: block;
            position: absolute;
            top: -180px;
            right: -95px;
            opacity: .5;
            mix-blend-mode: overlay;
        }
    }

    .bisckets_circles2 {
        position: absolute;
        top: -166px;
        right: -119px;
    }

    .bisckets_circle_img {
        position: relative;

        .bisckets_circles {
            position: absolute;
            top: -358px;
            left: -155px;
        }
    }

    .bsk_phaze__sec {
        position: relative;
        z-index: 1;
        width: 996px;
        height: 1300px;

        &--title {
            font-size: 50px;
            font-weight: bold;
        }

        &--content {
            width: 967px;
            margin-top: 70px;

            .bsk_text {
                font-size: 15px;
                line-height: 1.5;
            }
        }

        .bsk_relation__sec--img {
            width: 600px;
            height: 100%;
            position: absolute;
            top: 400px;
            left: 25px;
        }
        .bsk_relation__sec--explain {
            position: relative;
            color: #a6a6a6;
            font-size: 12px;
            width: 101%;

            &::before {
                content: '01 TERMINOLOGY';
                width: 100%;
                position: absolute;
                top: 130px;
                left: 645px;
            }

            &_detail1 {
                position: absolute;
                top: 210px;
                left: 680px;
            }

            &_detail2 {
                position: absolute;
                top: 280px;
                left: 680px;
            }

            &_detail3 {
                position: absolute;
                top: 350px;
                left: 680px;
            }

            &_detail4 {
                position: absolute;
                top: 420px;
                left: 680px;
            }
        }
    }

    .bsk_relation__sec {
        position: relative;
        width: 890px;
        height: 689px;
        margin-top: 100px;

        &--title {
            font-size: 50px;
            font-weight: bold;
            position: absolute;
            top: 0;
            z-index: 1;
        }

        &--entitle {
            font-size: 50px;
            font-weight: bold;
            position: absolute;
            top: -45px;
            z-index: 1;
        }

        &--content {
            width: 900px;
            position: absolute;
            top: 100px;

            .bsk_text {
                font-size: 15px;
                line-height: 1.8;
            }
        }

        &--img {
            position: absolute;
            top: 400px;
        }
    }

    .bsk_solution__li {
        width: 960px;

        &--left {
            width: 600px;
        }
    }

    .bsk_service__sec {
        position: relative;
        width: 100%;
        height: 4300px;
        margin-top: 300px;

        &--title {
            font-size: 70px;
            font-weight: bold;
            position: relative;
            left: 20%;

            &::before {
                content: 'FROM JAPAN TO THE WORLD FROM THE WORLD TO JAPAN';
                position: absolute;
                top: 66px;
                left: 370px;
                color: #e1e1e1;
                font-size: 9px;
                font-weight: 100;
                transform: rotate(90deg);
                width: 140px;
            }
        }

        &--content1 {
            display: flex;
            width: 960px;
            height: 600px;
            margin-top: 150px;

            &--left {
                width: 640px;
                margin-top: 140px;

                &-sub {
                    font-size: 23px;
                    line-height: 1.5;
                    font-weight: bold;
                    width: 440px;
                    padding: 18px 13px 50px 40px;

                     &::before {
                         content: '01 SOCIAL LISTENNING';
                         color: #e1e1e1;
                         font-size: 9px;
                         position: absolute;
                         top: 285px;
                         left: 17px;
                     }
                }

                .bsk_text {
                    font-size: 13px;
                    line-height: 2.1;
                    width: 490px;
                    padding: 0px 13px 50px 40px;
                }

                .bsk_service__btn {
                    left: 50%;
                    font-weight: bold;
                    z-index: 100;
                }
            }

            &--right {
                width: 350px;
                -webkit-box-shadow: -2px 10px 4px 0px rgba(0, 0, 0, 0.1);
                -moz-box-shadow: -2px 10px 4px 0px rgba(0, 0, 0, 0.1);
                box-shadow: -2px 10px 4px 0px rgba(0, 0, 0, 0.1);

                .test_body {
                    -webkit-transition-timing-function: cubic-bezier(.19, 1, .22, 1);
                    transition-timing-function: cubic-bezier(.19, 1, .22, 1);
                    -webkit-transition-duration: 2.6s;
                    transition-duration: 2.6s;
                    -webkit-transition-property: -webkit-transform;
                    transition-property: -webkit-transform;
                    transition-property: transform;
                    transition-property: transform,
                    -webkit-transform;
                    overflow: hidden;
                    position: relative;
                    
                    .test_thumb {
                        position: relative;
                        display: block;
                        overflow: hidden;
                        width: 100%;
                        height: auto;
                        -webkit-backface-visibility: hidden;
                        backface-visibility: hidden;

                        .kyle {
                            .test_cover {
                                position: absolute;
                                top: -5px;
                                right: -5px;
                                bottom: -5px;
                                left: -5px;
                                background-color: #fff;
                                z-index: 1;
                                -webkit-transition-timing-function: cubic-bezier(.19, 1, .22, 1);
                                transition-timing-function: cubic-bezier(.19, 1, .22, 1);
                                -webkit-transition-property: -webkit-transform;
                                transition-property: -webkit-transform;
                                transition-property: transform;
                                transition-property: transform,
                                    -webkit-transform;
                                -webkit-transition-duration: 5s;
                                transition-duration: 5s;
                                -webkit-transform-origin: right;
                                transform-origin: right;
                                overflow: hidden;
                                -webkit-transform: scaleX(1);
                                transform: scaleX(1);
                            }

                            .is-shown {
                                -webkit-transform: scaleX(0);
                                transform: scaleX(0);
                            }
                        }

                        .kyle2 {
                            .test_img {
                                position: relative;
                                display: block;
                                width: 100%;
                                height: 100%;
                                -webkit-transition: 2.6s -webkit-transform,
                                    -webkit-filter 3s;
                                transition: 2.6s -webkit-transform,
                                    -webkit-filter 3s;
                                transition: 2.6s transform,
                                    filter 3s;
                                transition: 2.6s transform,
                                filter 3s,2.6s -webkit-transform,-webkit-filter 3s;
                                -webkit-transform: scale(1.1);
                                transform: scale(1.1);
                                -webkit-backface-visibility: hidden;
                                backface-visibility: hidden;
                                overflow: hidden;
                                -webkit-filter: brightness(200%);
                                filter: brightness(200%);
                                will-change: filter,transform;
                                // -webkit-transition-duration: 8s;
                                // transition-duration: 8s;
                            }

                            .is-shown {
                                -webkit-filter: brightness(100%);
                                filter: brightness(100%);
                                -webkit-transform: scale(1, 1);
                                transform: scale(1, 1);
                            }
                        }
                    }
                }
                .is-shown {
                    opacity: 1;
                }
            }
        }

        &--content2 {
            display: flex;
            width: 960px;
            height: 600px;
            margin-top: 153px;

            &--right {
                width: 640px;
                margin-top: 140px;
                position: absolute;
                left: 50%;

                &-sub {
                    font-size: 23px;
                    line-height: 1.5;
                    font-weight: bold;
                    width: 440px;
                    padding: 18px 13px 50px 40px;

                        &::before {
                            content: '02 RPA';
                            color: #e1e1e1;
                            font-size: 9px;
                            position: absolute;
                            top: -71px;
                            left: 17px;
                        }
                }

                .bsk_text {
                    font-size: 13px;
                    line-height: 2.1;
                    width: 490px;
                    padding: 0px 13px 50px 40px;
                }

                .bsk_service__btn {
                    left: 50%;
                    font-weight: bold;
                }
            }

            &--left {
                width: 350px;
                -webkit-box-shadow: -2px 10px 4px 0px rgba(0, 0, 0, 0.1);
                -moz-box-shadow: -2px 10px 4px 0px rgba(0, 0, 0, 0.1);
                box-shadow: -2px 10px 4px 0px rgba(0, 0, 0, 0.1);

                .test_body {
                    -webkit-transition-timing-function: cubic-bezier(.19, 1, .22, 1);
                    transition-timing-function: cubic-bezier(.19, 1, .22, 1);
                    -webkit-transition-duration: 2.6s;
                    transition-duration: 2.6s;
                    -webkit-transition-property: -webkit-transform;
                    transition-property: -webkit-transform;
                    transition-property: transform;
                    transition-property: transform,
                        -webkit-transform;
                    overflow: hidden;
                    position: relative;

                    .test_thumb {
                        position: relative;
                        display: block;
                        overflow: hidden;
                        width: 100%;
                        height: auto;
                        -webkit-backface-visibility: hidden;
                        backface-visibility: hidden;

                        .kyle {
                            .test_cover {
                                position: absolute;
                                top: -5px;
                                right: -5px;
                                bottom: -5px;
                                left: -5px;
                                background-color: #fff;
                                z-index: 1;
                                -webkit-transition-timing-function: cubic-bezier(.19, 1, .22, 1);
                                transition-timing-function: cubic-bezier(.19, 1, .22, 1);
                                -webkit-transition-property: -webkit-transform;
                                transition-property: -webkit-transform;
                                transition-property: transform;
                                transition-property: transform,
                                    -webkit-transform;
                                -webkit-transition-duration: 5s;
                                transition-duration: 5s;
                                -webkit-transform-origin: right;
                                transform-origin: right;
                                overflow: hidden;
                                -webkit-transform: scaleX(1);
                                transform: scaleX(1);
                            }

                            .is-shown {
                                -webkit-transform: scaleX(0);
                                transform: scaleX(0);
                            }
                        }

                        .kyle2 {
                            .test_img {
                                position: relative;
                                display: block;
                                width: 100%;
                                height: 100%;
                                -webkit-transition: 2.6s -webkit-transform,
                                    -webkit-filter 3s;
                                transition: 2.6s -webkit-transform,
                                    -webkit-filter 3s;
                                transition: 2.6s transform,
                                    filter 3s;
                                transition: 2.6s transform,
                                    filter 3s, 2.6s -webkit-transform, -webkit-filter 3s;
                                -webkit-transform: scale(1.1);
                                transform: scale(1.1);
                                -webkit-backface-visibility: hidden;
                                backface-visibility: hidden;
                                overflow: hidden;
                                -webkit-filter: brightness(200%);
                                filter: brightness(200%);
                                will-change: filter, transform;
                            }

                            .is-shown {
                                -webkit-filter: brightness(100%);
                                filter: brightness(100%);
                                -webkit-transform: scale(1, 1);
                                transform: scale(1, 1);
                            }
                        }
                    }
                }

                .is-shown {
                    opacity: 1;
                }
            }
        }

        &--content3 {
            display: flex;
            width: 960px;
            height: 600px;
            margin-top: 153px;

            &--left {
                width: 640px;
                margin-top: 140px;

                &-sub {
                    font-size: 23px;
                    line-height: 1.5;
                    font-weight: bold;
                    width: 440px;
                    padding: 18px 13px 50px 40px;

                        &::before {
                            content: '03 BARCODE SOLUTION';
                            color: #e1e1e1;
                            font-size: 9px;
                            position: absolute;
                            top: 1800px;
                            left: 17px;
                        }
                }

                .bsk_text {
                    font-size: 13px;
                    line-height: 2.1;
                    width: 490px;
                    padding: 0px 13px 50px 40px;
                }

                .bsk_service__btn {
                    left: 50%;
                    font-weight: bold;
                }
            }

            &--right {
                width: 350px;
                -webkit-box-shadow: -2px 10px 4px 0px rgba(0, 0, 0, 0.1);
                -moz-box-shadow: -2px 10px 4px 0px rgba(0, 0, 0, 0.1);
                box-shadow: -2px 10px 4px 0px rgba(0, 0, 0, 0.1);

                .test_body {
                    -webkit-transition-timing-function: cubic-bezier(.19, 1, .22, 1);
                    transition-timing-function: cubic-bezier(.19, 1, .22, 1);
                    -webkit-transition-duration: 2.6s;
                    transition-duration: 2.6s;
                    -webkit-transition-property: -webkit-transform;
                    transition-property: -webkit-transform;
                    transition-property: transform;
                    transition-property: transform,
                        -webkit-transform;
                    overflow: hidden;
                    position: relative;

                    .test_thumb {
                        position: relative;
                        display: block;
                        overflow: hidden;
                        width: 100%;
                        height: auto;
                        -webkit-backface-visibility: hidden;
                        backface-visibility: hidden;

                        .kyle {
                            .test_cover {
                                position: absolute;
                                top: -5px;
                                right: -5px;
                                bottom: -5px;
                                left: -5px;
                                background-color: #fff;
                                z-index: 1;
                                -webkit-transition-timing-function: cubic-bezier(.19, 1, .22, 1);
                                transition-timing-function: cubic-bezier(.19, 1, .22, 1);
                                -webkit-transition-property: -webkit-transform;
                                transition-property: -webkit-transform;
                                transition-property: transform;
                                transition-property: transform,
                                    -webkit-transform;
                                -webkit-transition-duration: 5s;
                                transition-duration: 5s;
                                -webkit-transform-origin: right;
                                transform-origin: right;
                                overflow: hidden;
                                -webkit-transform: scaleX(1);
                                transform: scaleX(1);
                            }

                            .is-shown {
                                -webkit-transform: scaleX(0);
                                transform: scaleX(0);
                            }
                        }

                        .kyle2 {
                            .test_img {
                                position: relative;
                                display: block;
                                width: 100%;
                                height: 100%;
                                -webkit-transition: 2.6s -webkit-transform,
                                    -webkit-filter 3s;
                                transition: 2.6s -webkit-transform,
                                    -webkit-filter 3s;
                                transition: 2.6s transform,
                                    filter 3s;
                                transition: 2.6s transform,
                                    filter 3s, 2.6s -webkit-transform, -webkit-filter 3s;
                                -webkit-transform: scale(1.1);
                                transform: scale(1.1);
                                -webkit-backface-visibility: hidden;
                                backface-visibility: hidden;
                                overflow: hidden;
                                -webkit-filter: brightness(200%);
                                filter: brightness(200%);
                                will-change: filter, transform;
                            }

                            .is-shown {
                                -webkit-filter: brightness(100%);
                                filter: brightness(100%);
                                -webkit-transform: scale(1, 1);
                                transform: scale(1, 1);
                            }
                        }
                    }
                }

                .is-shown {
                    opacity: 1;
                }
            }
        }

         &--content4 {
             display: flex;
             width: 960px;
             height: 600px;
            margin-top: 153px;

             &--right {
                width: 640px;
                margin-top: 140px;
                position: absolute;
                left: 50%;

                 &-sub {
                     font-size: 23px;
                     line-height: 1.5;
                     font-weight: bold;
                     width: 440px;
                     padding: 18px 13px 50px 40px;

                        &::before {
                            content: '04 AI TOOL';
                            color: #e1e1e1;
                            font-size: 9px;
                            position: absolute;
                            top: -61px;
                            left: 17px;
                        }
                 }

                 .bsk_text {
                     font-size: 13px;
                     line-height: 2.1;
                     width: 490px;
                     padding: 0px 13px 50px 40px;
                 }

                 .bsk_service__btn {
                     left: 50%;
                     font-weight: bold;
                 }
             }

             &--left {
                width: 350px;
                -webkit-box-shadow: -2px 10px 4px 0px rgba(0, 0, 0, 0.1);
                -moz-box-shadow: -2px 10px 4px 0px rgba(0, 0, 0, 0.1);
                box-shadow: -2px 10px 4px 0px rgba(0, 0, 0, 0.1);

                .test_body {
                    -webkit-transition-timing-function: cubic-bezier(.19, 1, .22, 1);
                    transition-timing-function: cubic-bezier(.19, 1, .22, 1);
                    -webkit-transition-duration: 2.6s;
                    transition-duration: 2.6s;
                    -webkit-transition-property: -webkit-transform;
                    transition-property: -webkit-transform;
                    transition-property: transform;
                    transition-property: transform,
                        -webkit-transform;
                    overflow: hidden;
                    position: relative;

                    .test_thumb {
                        position: relative;
                        display: block;
                        overflow: hidden;
                        width: 100%;
                        height: auto;
                        -webkit-backface-visibility: hidden;
                        backface-visibility: hidden;

                        .kyle {
                            .test_cover {
                                position: absolute;
                                top: -5px;
                                right: -5px;
                                bottom: -5px;
                                left: -5px;
                                background-color: #fff;
                                z-index: 1;
                                -webkit-transition-timing-function: cubic-bezier(.19, 1, .22, 1);
                                transition-timing-function: cubic-bezier(.19, 1, .22, 1);
                                -webkit-transition-property: -webkit-transform;
                                transition-property: -webkit-transform;
                                transition-property: transform;
                                transition-property: transform,
                                    -webkit-transform;
                                -webkit-transition-duration: 5s;
                                transition-duration: 5s;
                                -webkit-transform-origin: right;
                                transform-origin: right;
                                overflow: hidden;
                                -webkit-transform: scaleX(1);
                                transform: scaleX(1);
                            }

                            .is-shown {
                                -webkit-transform: scaleX(0);
                                transform: scaleX(0);
                            }
                        }

                        .kyle2 {
                            .test_img {
                                position: relative;
                                display: block;
                                width: 100%;
                                height: 100%;
                                -webkit-transition: 2.6s -webkit-transform,
                                    -webkit-filter 3s;
                                transition: 2.6s -webkit-transform,
                                    -webkit-filter 3s;
                                transition: 2.6s transform,
                                    filter 3s;
                                transition: 2.6s transform,
                                    filter 3s, 2.6s -webkit-transform, -webkit-filter 3s;
                                -webkit-transform: scale(1.1);
                                transform: scale(1.1);
                                -webkit-backface-visibility: hidden;
                                backface-visibility: hidden;
                                overflow: hidden;
                                -webkit-filter: brightness(200%);
                                filter: brightness(200%);
                                will-change: filter, transform;
                            }

                            .is-shown {
                                -webkit-filter: brightness(100%);
                                filter: brightness(100%);
                                -webkit-transform: scale(1, 1);
                                transform: scale(1, 1);
                            }
                        }
                    }
                }

                .is-shown {
                    opacity: 1;
                }
             }
         }

        &--content5 {
            display: flex;
            width: 960px;
            height: 600px;
            margin-top: 153px;

            &--left {
                width: 640px;
                margin-top: 140px;

                &-sub {
                    font-size: 23px;
                    line-height: 1.5;
                    font-weight: bold;
                    width: 440px;
                    padding: 18px 13px 50px 40px;

                    &::before {
                        content: '05 MARKETING AUTOMATION';
                        color: #e1e1e1;
                        font-size: 9px;
                        position: absolute;
                        top: 3298px;
                        left: 17px;
                    }
                }

                .bsk_text {
                    font-size: 13px;
                    line-height: 2.1;
                    width: 490px;
                    padding: 0px 13px 50px 40px;
                }

                .bsk_service__btn {
                    left: 50%;
                    font-weight: bold;
                }
            }

            &--right {
                width: 350px;
                -webkit-box-shadow: -2px 10px 4px 0px rgba(0, 0, 0, 0.1);
                -moz-box-shadow: -2px 10px 4px 0px rgba(0, 0, 0, 0.1);
                box-shadow: -2px 10px 4px 0px rgba(0, 0, 0, 0.1);

                .test_body {
                    -webkit-transition-timing-function: cubic-bezier(.19, 1, .22, 1);
                    transition-timing-function: cubic-bezier(.19, 1, .22, 1);
                    -webkit-transition-duration: 2.6s;
                    transition-duration: 2.6s;
                    -webkit-transition-property: -webkit-transform;
                    transition-property: -webkit-transform;
                    transition-property: transform;
                    transition-property: transform,
                        -webkit-transform;
                    overflow: hidden;
                    position: relative;

                    .test_thumb {
                        position: relative;
                        display: block;
                        overflow: hidden;
                        width: 100%;
                        height: auto;
                        -webkit-backface-visibility: hidden;
                        backface-visibility: hidden;

                        .kyle {
                            .test_cover {
                                position: absolute;
                                top: -5px;
                                right: -5px;
                                bottom: -5px;
                                left: -5px;
                                background-color: #fff;
                                z-index: 1;
                                -webkit-transition-timing-function: cubic-bezier(.19, 1, .22, 1);
                                transition-timing-function: cubic-bezier(.19, 1, .22, 1);
                                -webkit-transition-property: -webkit-transform;
                                transition-property: -webkit-transform;
                                transition-property: transform;
                                transition-property: transform,
                                    -webkit-transform;
                                -webkit-transition-duration: 5s;
                                transition-duration: 5s;
                                -webkit-transform-origin: right;
                                transform-origin: right;
                                overflow: hidden;
                                -webkit-transform: scaleX(1);
                                transform: scaleX(1);
                            }

                            .is-shown {
                                -webkit-transform: scaleX(0);
                                transform: scaleX(0);
                            }
                        }

                        .kyle2 {
                            .test_img {
                                position: relative;
                                display: block;
                                width: 100%;
                                height: 100%;
                                -webkit-transition: 2.6s -webkit-transform,
                                    -webkit-filter 3s;
                                transition: 2.6s -webkit-transform,
                                    -webkit-filter 3s;
                                transition: 2.6s transform,
                                    filter 3s;
                                transition: 2.6s transform,
                                    filter 3s, 2.6s -webkit-transform, -webkit-filter 3s;
                                -webkit-transform: scale(1.1);
                                transform: scale(1.1);
                                -webkit-backface-visibility: hidden;
                                backface-visibility: hidden;
                                overflow: hidden;
                                -webkit-filter: brightness(200%);
                                filter: brightness(200%);
                                will-change: filter, transform;
                            }

                            .is-shown {
                                -webkit-filter: brightness(100%);
                                filter: brightness(100%);
                                -webkit-transform: scale(1, 1);
                                transform: scale(1, 1);
                            }
                        }
                    }
                }

                .is-shown {
                    opacity: 1;
                }
            }
        }
    }

    .borders {
        position: relative;

        .bsk_border1 {
            height: 1390px;
            width: calc(50% - 497px);
            border-right: .5px solid #e1e1e1;
            // border-bottom: .5px solid #e1e1e1;
            background-color: transparent;
            position: absolute;
            top: -100px;
            left: 0;
            z-index: 1;
        }

        .bsk_border2 {
            height: 1px;
            width: 100%;
            border-bottom: .5px solid #e1e1e1;
            background-color: transparent;
            position: absolute;
            top: 1290px;
            left: 0;
            z-index: 1;
        }

        .bsk_border3 {
            height: 1385px;
            width: calc(50% - 497px);
            border-right: .5px solid #e1e1e1;
            border-bottom: .5px solid #e1e1e1;
            background-color: transparent;
            position: absolute;
            top: 1284px;
            left: 0;
            z-index: 1;
        }

        .bsk_border4 {
            height: 1px;
            width: 100%;
            border-bottom: .5px solid #e1e1e1;
            background-color: transparent;
            position: absolute;
            top: 2668px;
            left: 0;
            z-index: 1;
        }

        .bsk_border5 {
               height: 544px;
               width: calc(50% - 497px);
               border-right: .5px solid #e1e1e1;
               background-color: transparent;
               position: absolute;
               top: 2669px;
               left: 0;
               z-index: 1;
        }

        .bsk_border6 {
            height: 545px;
            width: 100%;
            border-right: .5px solid #e1e1e1;
            border-bottom: .5px solid #e1e1e1;
            background-color: transparent;
            position: absolute;
            top: 2670px;
            left: 0;
            z-index: 1;
        }

        .bsk_border7 {
            height: 1px;
            width: calc(50% - -133px);
            border-bottom: .5px solid #e1e1e1;
            background-color: transparent;
            position: absolute;
            top: 3410px;
            right: 0;
            z-index: 1;
        }

        .bsk_border8 {
            height: 1px;
            width: calc(50% - -138px);
            border-bottom: .5px solid #e1e1e1;
            background-color: transparent;
            position: absolute;
            top: 4180px;
            left: 0;
            z-index: 1;
        }

        .bsk_border9 {
           height: 760px;
           width: calc(50% - 496px);
           border-right: .5px solid #e1e1e1;
           background-color: transparent;
           position: absolute;
           top: 4180px;
           left: 0;
           z-index: 1;
        }

         .bsk_border10 {
            height: 1px;
            width: 100%;
            border-bottom: .5px solid #e1e1e1;
            position: absolute;
            top: 4940px;
            z-index: 1;
         }

        .bsk_border11 {
            height: 1px;
            width: calc(50% - -138px);
            border-bottom: .5px solid #e1e1e1;
            position: absolute;
            top: 5680px;
            left: 0;
            z-index: 1;
        }

        .bsk_border12 {
           height: 211px;
           width: calc(50% - 496px);
           border-right: .5px solid #e1e1e1;
           position: absolute;
           top: 5169px;
           left: 14px;
           z-index: 1;
        }

        .bsk_border13 {
            height: 10px;
            width: calc(50% - -496px);
            border-bottom: .5px solid #e1e1e1;
            position: absolute;
            top: 425px;
            right: 0;
            z-index: 1;
        }

        .bsk_border14 {
            height: 855px;
            width: 100px;
            border-left: .5px solid #e1e1e1;
            position: absolute;
            top: 435px;
            left: calc(50% - -153px);
            z-index: 1;
        }

        .bsk_border15 {
            width: 650px;
            border-bottom: .5px solid #e1e1e1;
            position: absolute;
            top: 600px;
            left: calc(50% - 497px);
            z-index: 1;
        }

        .bsk_border16 {
            width: 650px;
            border-bottom: .5px solid #e1e1e1;
            position: absolute;
            top: 1107px;
            left: calc(50% - 497px);
            z-index: 1;
        }
    }
}

@media screen and (max-width: 1024px) {

    /* Global engineering page */
    #page_title_bisckets {
        position: relative;
        overflow: visible;
        margin-top: auto;
        width: 100%;
        height: initial;
        padding: 20px 12px;
        color: white;
        font-size: 62px;
        line-height: 1.5;
        font-weight: bold;

        &::before {
            content: 'BUSINESS, IDENTITY, STRATEGY, CONSULTING, KNOWLEDGE, ENGINEERING, TECHNOLOGY,  SPECIALITY';
            // width: 505px;
            font-size: 10px;
            font-weight: 300;
            position: absolute;
            top: 0;
            left: 17px;
            transform: scale(0.6);
            transform-origin: left;
            color: #fff;
            letter-spacing: 0;
            white-space: nowrap;
            @media screen and (max-width: 350px) {
                // top: 28px;
                // left: 37px;
            }

        }
    }

    .bisckets_header_description {
        color: #fff;
        display: flex;
    
    }

    .bisckets_text {
        font-size: 12px;
        line-height: 1.5;
        letter-spacing: 0.03em;
        max-width: 600px;
        font-family: 'Noto Sans JP',
        sans-serif;
        // width: 90%;
        // font-family: 'Noto Sans Japanese',
        // sans-serif;
        padding: 20px 12px 68px 12px;

    }

    .bisckets_header {
        position: relative;
        display: block;
        z-index: 1;
        padding-top: 60px;
        height: 100vh;
    }

    .bisckets_header__background {
        position: absolute !important;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: url(../images/sp/bisckets/bs_mobile.jpg);
        background-size: cover;
        background-repeat: no-repeat;
    }

    .bisckets_header__text-container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: calc(100vh - 60px);
    }

     .bsk_phaze__sec {
         margin-top: 70px;
         margin-bottom: 50px;

         &--title {
            font-size: 25px;
            font-weight: bold;
            padding-left: 5%;

            // &::before {
            //     content: '01';
            //     color: #242424;
            //     font-size: 9px;
            //     position: absolute;
            //     top: -13px;
            //     left: 39px;
            // }
         }

         &--content {
            display: flex;
            justify-content: center;
            margin-top: 30px;

             .bsk_text {
                font-size: 12px;
                line-height: 1.6;
                max-width: 700px;
                width: 90%;
             }
         }
     }

     .bsk_relation__sec {
         margin-top: 100px;

         &--title {
            font-size: 25px;
            font-weight: bold;
            padding-left: 5%;

            // &::before {
            //     content: '02';
            //     color: #242424;
            //     font-size: 9px;
            //     position: absolute;
            //     top: -13px;
            //     left: 39px;
            // }
         }

         &--content {
            display: flex;
            justify-content: center;
            margin-top: 30px;

             .bsk_text {
                font-size: 12px;
                line-height: 1.6;
                max-width: 700px;
                width: 90%;
             }
         }
    }

    .bsk_relation__sp {
        position: relative;
        margin-top: 100px;

        &--content1 {
            height: 545px;

            &-head {
                width: 100%;
                height: 142px;
                background-color: #1167f2;

                &--title {
                    font-size: 25px;
                    color: #fff;
                    line-height: 1.5;
                    padding-top: 15px;
                    display: flex;
                    justify-content: center;
                }
            }

            &-item1 {
                max-width: 650px;
                width: 90%;
                height: 156px;
                background-color: #fff;
                position: absolute;
                z-index: 1;
                top: 110px;
                left: 20px;
                box-shadow: -2px 10px 4px 0px rgba(0, 0, 0, 0.1);
                display: flex;

                &--num {
                    font-size: 10px;
                    border-right: .5px solid #ccc;
                    padding-left: 16%;
                    padding-top: 26px;
                    width: 80px;
                }

                &--detail {
                    font-size: 15px;
                    line-height: 1.4;
                    padding: 25px 12px 10px 18px;
                }
            }

            &-item2 {
                max-width: 650px;
                width: 90%;
                height: 108px;
                background-color: #fff;
                position: absolute;
                z-index: 1;
                top: 280px;
                left: 20px;
                box-shadow: -2px 10px 4px 0px rgba(0, 0, 0, 0.1);
                display: flex;

                &--num {
                    font-size: 10px;
                    border-right: .5px solid #ccc;
                    padding-left: 16%;
                    padding-top: 26px;
                    width: 80px;
                }

                &--detail {
                    font-size: 15px;
                    line-height: 1.4;
                    padding: 25px 12px 10px 18px;
                }
            }

            &-item3 {
                max-width: 650px;
                width: 90%;
                height: 110px;
                background-color: #fff;
                position: absolute;
                z-index: 1;
                top: 400px;
                left: 20px;
                box-shadow: -2px 10px 4px 0px rgba(0, 0, 0, 0.1);
                display: flex;

                &--num {
                    font-size: 10px;
                    border-right: .5px solid #ccc;
                    padding-left: 16%;
                    padding-top: 26px;
                    width: 80px;
                }

                &--detail {
                    font-size: 15px;
                    line-height: 1.4;
                    padding: 25px 12px 10px 18px;
                }
            }
        }

        &--content2 {
            height: 380px;

            &-head {
                width: 100%;
                height: 142px;
                background-color: $red;

                &--title {
                    font-size: 25px;
                    color: #fff;
                    // padding: 16px 133px 62px 18px;
                    line-height: 1.5;
                    padding-top: 15px;
                    display: flex;
                    justify-content: center;
                }
            }

            &-item1 {
                max-width: 650px;
                width: 90%;
                height: 110px;
                background-color: #fff;
                position: absolute;
                z-index: 1;
                top: 660px;
                left: 20px;
                box-shadow: -2px 10px 4px 0px rgba(0, 0, 0, 0.1);
                display: flex;

                &--num {
                    font-size: 10px;
                    border-right: .5px solid #ccc;
                    padding-left: 16%;
                    padding-top: 26px;
                    width: 80px;
                }

                &--detail {
                    font-size: 15px;
                    line-height: 1.4;
                    padding: 25px 12px 10px 18px;
                }
            }

            &-item2 {
                max-width: 650px;
                width: 90%;
                height: 108px;
                background-color: #fff;
                position: absolute;
                z-index: 1;
                top: 785px;
                left: 20px;
                box-shadow: -2px 10px 4px 0px rgba(0, 0, 0, 0.1);
                display: flex;

                &--num {
                    font-size: 10px;
                    border-right: .5px solid #ccc;
                    padding-left: 16%;
                    padding-top: 26px;
                    width: 80px;
                }

                &--detail {
                    font-size: 15px;
                    line-height: 1.4;
                    padding: 25px 12px 10px 18px;
                }
            }
        }

        &--content3 {
            height: 545px;

            &-head {
                width: 100%;
                height: 142px;
                background-color: #242424;

                &--title {
                    font-size: 25px;
                    color: #fff;
                    // padding: 16px 133px 62px 18px;
                    line-height: 1.5;
                    padding-top: 15px;
                    display: flex;
                    justify-content: center;
                }
            }

            &-item1 {
                max-width: 650px;
                width: 90%;
                height: 156px;
                background-color: #fff;
                position: absolute;
                z-index: 1;
                top: 1040px;
                left: 20px;
                box-shadow: -2px 10px 4px 0px rgba(0, 0, 0, 0.1);
                display: flex;

                &--num {
                    font-size: 10px;
                    border-right: .5px solid #ccc;
                    padding-left: 16%;
                    padding-top: 26px;
                    width: 80px;
                }

                &--detail {
                    font-size: 15px;
                    line-height: 1.4;
                    padding: 25px 12px 10px 18px;
                }
            }

            &-item2 {
                max-width: 650px;
                width: 90%;
                height: 108px;
                background-color: #fff;
                position: absolute;
                z-index: 1;
                top: 1210px;
                left: 20px;
                box-shadow: -2px 10px 4px 0px rgba(0, 0, 0, 0.1);
                display: flex;

                &--num {
                    font-size: 10px;
                    border-right: .5px solid #ccc;
                    padding-left: 16%;
                    padding-top: 26px;
                    width: 80px;
                }

                &--detail {
                    font-size: 15px;
                    line-height: 1.4;
                    padding: 25px 12px 10px 18px;
                }
            }
        }
    }

    .bsk_bottom__spacer {
        margin-bottom: 100px;
    }

    .bsk_img__phase {
        width: 110px;
    }
}