.credo-parallax {
  margin: 100px 0;
  @media screen and (max-width: 768px) {
    margin: 10vh 10px 0;
  }
  .credo-parallax-image-pc {
    position: relative;
    display: flex;
    justify-content: center;
    background-image: url(../images/pc/credo/credo-background.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    padding-top: 80px;
    padding-bottom: 210px;
    @media screen and (max-width: 768px) {
      display: none;
    }
    &-left {
      position: relative;
      left: 174px;
      width: 500px;
      mix-blend-mode: color-dodge;
    }
    &-right {
      position: relative;
      right: 174px;
      width: 500px;
      mix-blend-mode: color-dodge;
    }
    &-text {
      position: absolute;
      bottom: 148px;
      width: 413px;
    }
  }
  .credo-parallax-image-sp {
    background-image: url(../images/pc/credo/credo-background.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    padding-top: 36vw;
    @media screen and (min-width: 769px) {
      display: none;
    }
    &-text {
      transform: translateY(-75px);
    }
  }
}

.credo-slides {
  margin-bottom: 250px;
  @media screen and (max-width: 1024px) {
    margin-bottom: 100px;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: initial;
  }
  .slideshow-container {
    position: relative;
    margin: 0 auto;
    width: max-content;
    background: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    line-height: normal;
    align-items: center;
    height: 571px;
    width: 100vw;
    padding: 0 60px;
    // width: 72.35vw;
    text-align: center;
    @media screen and (max-width: 768px) {
      padding: 40px 3vw 0 3vw;
      top: 0px;
      width: 100%;
      height: initial;
    }

    .slidebar-vertical-wrapper {
      position: relative;
      width: 4px;
      height: 180px;
      background-color: #f4f4f4;
      z-index: 3;
      .slidebar-vertical {
        background-color: #242424;
        width: 100%;
        transition: all 0.3s linear;
      }
    }
  }

  .slide-contents {
    position: relative;
    text-align: center;
    transition: all 0.2s;
    top: 8px;
    width: 100%;
    height: 400px; // adjust for mobile
    overflow: hidden;
    cursor: pointer;
    @media screen and (max-width: 768px) {
      padding-top: 28px;
      height: 515px;
    }

    .slide-item {
      position: absolute;
      width: 100%;
      height: 100%;
      font-size: 15px;
      text-align: center;
      display: grid;
      place-items: center;
      overflow: hidden;
      transition: transform 0.3s ease-out;
      @media screen and (max-width: 768px) {
        place-items: initial;
      }

      &[slide="before"] {
        transform: translateY(100%);
        opacity: 0;
      }

      &[slide="center"] {
        transform: translateY(0);
      }

      &[slide="after"] {
        transform: translateY(-100%);
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .culture_section .container {
    margin: 0px 1px 14px;
    width: 100%;
    background: #000;
    z-index: 2;
    position: relative;
  }
}

.culture .s-about {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 797px;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
  margin-top: 174px;
  .about-button {
    display: flex;
    position: absolute;
    justify-content: space-around;
    font-size: 18px;
    bottom: 99px;
    .btn_black {
      background-color: #242424;
    }
  }
}
@media screen and (max-width: 1024px) {
  .about-button {
    display: flex;
    position: relative;
    justify-content: center;
    margin-bottom: 150px;
    .btn_black {
      background-color: #242424;
    }
  }
}
