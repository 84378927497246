.members_section {
  @include desktop {
    position: relative;
    display: block;
    padding: 150px 0 154px;
    z-index: 1;
  }

  .csr__intoro {
    text-align: center;
    padding: 0;

    .members_member_headline {
      font-size: 60px;
      font-weight: 700;
      letter-spacing: 1.5px;
      line-height: 74px;
      text-align: center;
      color: #242424;
      padding: 100px 0 100px;

      @media screen and (max-width: 1024px) {
        font-size: 40px;
        font-weight: 600;
        letter-spacing: 0.75px;
        line-height: 37px;
        padding: 20px 0 60px;
      }
    }
  }
  .members_articles {
    @include desktop {
      margin-right: 30px;
      margin-left: 30px;
    }
    .members_articles_list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
      column-gap: 0px;
      row-gap: 70px;
      align-items: start;
      justify-items: center;
      @media only screen and (max-width: 1200px) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
      }
      @media only screen and (max-width: 720px) {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(1, 1fr);
      }
      .disable {
        pointer-events: none;
      }
      .members_li {
        width: 100%;
        .members_img {
          position: relative;
          width: 100%;
          height: auto;
          object-fit: cover;
          transition: 0.3s ease-out;
          background: black;
          overflow: hidden;
          img {
            transition: 0.3s ease-in-out;
          }
          &:hover img {
            transform: scale(1.1);
            transition: 0.3s ease-out;
            opacity: 0.3;
          }
          &:hover .vibes_article {
            display: block;
          }
          &:hover .vibes_article img {
            opacity: 1;
          }
          .vibes_article {
            transition: 0.3s ease;
            display: none;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            text-align: center;
            .icon {
              height: 24px;
              width: 24px;
            }
            .text {
              font-size: 16px;
              font-family: Noto Sans CJK JP;
              color: #ffffff;
              margin-left: 3.37px;
            }
          }
        }
        .members_title {
          margin-top: 30px;
          margin-bottom: 8px;
          font-size: 24px;
          font-weight: bold;
          text-align: center;
          @media only screen and (max-width: 1024px) {
            font-size: 17px;
            margin-bottom: 5px;
          }
        }
        .members_body {
          font-size: 16px;
          font-weight: 500;
          text-align: center;
          @media only screen and (max-width: 1024px) {
            font-size: 12px;
            font-weight: 450;
            line-height: 26px;
          }
        }
        .members_caption {
          font-size: 14px;
          font-weight: 400;
          text-align: center;
          line-height: 28px;
          margin-top: 12px;
          @media only screen and (max-width: 1024px) {
            font-size: 12px;
            font-weight: 300;
            line-height: 16px;
          }
        }
        .fw_vibes_wantedly_icons {
          position: relative;
          top: 30px;
          display: flex;
          justify-content: center;
          .hide {
            display: none;
          }

          .icon_wapper {
            display: flex;
            align-items: center;
            background-color: #303030;
            width: 40px;
            height: 40px;
            border-radius: 50px;
            margin: 0 10.5px;
            @media (hover: hover) and (pointer: fine) {
              &:hover {
                background-color: #f21515;
              }
            }
          }
          .icon {
            height: 24px;
          }
          .icon_wantedly {
            height: 29px;
          }
          .filter_svg {
            filter: brightness(0) saturate(100%) invert(100%) sepia(0%)
              saturate(7500%) hue-rotate(30deg) brightness(96%) contrast(114%);
          }
        }
      }
    }
  }
}
.member_viewall_btn {
  position: relative;
  top: -54px;
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
  margin-right: 24px;
}
