.article-list {
  margin-bottom: 1rem;
  padding-right: 20rem;
  padding-left: 20rem;

  a {
    display: flex;
    text-decoration: none;
    color: #333;

    @media only screen and (max-width: 768px) {
      display: block;
    }

    &::after {
      display: block;
      clear: both;
      content: '';
    }
  }
  
  a:hover {
    background-color: #eee;
  }
}

@media only screen and (max-width: 1200px) {
  .article-list {
    padding-right: 13rem;
    padding-left: 13rem;
  }
}

@media only screen and (max-width: 980px) {
  .article-list {
    padding-right: 5rem;
    padding-left: 5rem;
  }
}
 


.article-list .img-wrap img{
  min-width: 240px;
  width: 240px;
  height: 160px;
  object-fit: cover;
}

.img-wrap {
  @media only screen and (max-width: 768px) {
    display: flex;
    justify-content: center;
  }
}
 
.news_text {
  margin-left: 30px;
  font-family: 'Noto Sans JP', sans-serif;

  .article-date {
    color: #969696;
    font-size: .75rem;
    margin-top: 15px;
    margin-bottom: 10px;
  }

  .news_title {
    font-size: 1.125rem;
    margin-bottom: 20px;
  }

  p {
    font-size: 13px;
    line-height: 1.5;
  }
}

.article-list .text .article-date,
.article-list .text .article-author {
  font-size: .75rem;
  font-weight: bold;
  display: inline-block;
  margin-bottom: .5rem;
  color: #888;
}

