.marketing-pr{

  .intro{
    position: relative;
    height: 409px;
    background-color: #000000;
    @media screen and (max-width:991px) {
      height: 700px;
    }
    &--bg {
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 0.4;
    }
    &--texts {
      position: absolute;
      top: 43.77%;
      margin: 0 9.72vw;
      color: #FFFFFF;
      @media  screen and (max-width:576px) {
        margin: 0 4vw;
      }
      &__title {
        font-size: 32px;
        font-weight: bold;
        margin-bottom: 6px;
        @media  screen and (max-width:576px) {
          line-height: 36px;
        }
      }
      &__subtitle {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 16px;
      }
      &__description {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .business {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    align-items: start;
    width: 78.1%;
    column-gap: 100px;
    row-gap: 32px;
    // background: url("../images/pc/top/background.jpg");
    // background-repeat: no-repeat;
    // background-position: center top;
    margin: 34px auto 160px auto;
    @media screen and (max-width: 991px) {
      width: 94%;
      grid-template-columns: repeat(1,1fr);
    }

    &--content {
      display: flex;
      align-items: flex-start;
      column-gap: 14px;

      &__number {
        font-size: 60px;
        color: #E6E6E6;
        padding-top: 24px;
      }

      &__texts {
        .title {
          font-size: 20px;
          font-weight: bold;
          margin-bottom: 6px;
          line-height: 24px;
          @media  screen and (max-width:576px) {
            font-size: 18px;
          }
        }
        .description {
          font-size: 14px;
          line-height: 20px;
          padding-left: 18px;
          &__item{
            list-style: decimal;
          }
        }
      }
    }
  }
}
