.members_body.no_scroll{
  height: 100%;
  overflow: hidden;
}
.members{
  /*========= ナビゲーションのためのCSS ===============*/
  #profile-box {
    position:fixed;
    z-index: 999;
    top:0;
    right: -120%;
    width:760px;
    height: 100vh;/*ナビの高さ*/
    background:#ffffff;
    transition: all 0.6s;
    @media screen and(max-width: 1024px) {
      top: 120%;
      bottom: -120%;
      left: 0;
      width: 100%;
      height: 100vh;
      background: transparent;
    }
    .freewill-logo-back {
      display: block;
      margin-bottom: 70px;
      visibility: hidden;
      @media screen and(max-width: 1024px) {
        background-color: transparent;
        margin-bottom: 0;
      }
    }
    .carousel {
      width: 100%;
      height: 300px;
      position: relative;
      margin: 0 auto;
      overflow: hidden;
      @media screen and(max-width: 1024px) {
        height: 40%;
      }
      &-area {
        height: 100%;
        position: absolute;
        display: flex;
        margin: 0;
        padding-left: 0;
      }
      ul {
        list-style: none;
      }
      &-list {
        width: 100%;
        height: 300px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        @media screen and(max-width: 1024px) {
          height: auto;
        }
      }
    }
    /*********** スライド送りボタン ***********/
    /* 共有パーツ */
    .arrow-btn button {
      border: none;
    }
    .arrow{
      &-wrap {
        width: 90%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      &-btn1 {
        width: 48px;
        height: 48px;
        background-color: #000000;
        border-radius: 50%;
        transition: 0.2s;
      }

      &-btn2 {
        width: 48px;
        height: 48px;
        background-color: #000000;
        border-radius: 50%;
        transition: 0.2s;
      }
      &-btn1:active {
        background-color: #808080;
      }
      &-btn2:active {
        background-color: #808080;
      }
      /* 左 */
      &-left {
        position: relative;
      }
      &-left:before {
        content: '';
        width: 10px;
        height: 10px;
        border-top: 2px solid #fefefe;
        border-left: 2px solid #fefefe;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-30%, -50%) rotate(-45deg);
      }
      /* 右 */
      &-right {
        position: relative;
      }
      &-right:before {
        content: '';
        width: 10px;
        height: 10px;
        border-top: 2px solid #fefefe;
        border-left: 2px solid #fefefe;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-70%, -50%) rotate(135deg);
      }
      &-right-btn{
        position: absolute;
        top: 50%;
        right: 2.5%;
        transform: translate(-50%, -50%);
      }
    }
    /*********** ページネーション ***********/
    .pagination {
      margin: 16px 0;
      display: flex;
      justify-content: center;
      &-circle {
        margin-left: 4px;
        margin-right: 4px;
        width: 10px;
        height: 10px;
        //border: 1px solid #333;
        border-radius: 50%;
        background-color: #e1e1e1;
      }
      &-circle.target {
        background-color: #000000;
      }
      ul.pagination {
        list-style: none;
      }
    }
    /*閉じるボタン*/
    .profile-box-close-btn {
      display: block;
      padding: 4px;
      margin: 4px;
      margin-bottom: 35px;
      visibility: hidden;
      @media screen and(max-width: 1024px) {
        background-color: transparent;
        margin-bottom: 0;
      }
    }
    .profile-box-close-btn p {
      visibility: hidden;
      @media screen and(max-width: 1024px) {
        visibility: visible;
        text-align: right;
        color: #000000;
        font-size: 20px;
        padding: 20px 20px 100px 20px;
        @media screen and(max-width: 720px) {
          font-size: 16px;
        }
      }
    }
    .members-detail {
      padding: 20px 0 20px 50px;
      //border-bottom: 1.5px solid #cccccc ;
      @media screen and(max-width: 1024px) {
        padding: 20px 0 20px 20px;
      }
      .members-name {
        text-align: left;
        font-weight: 700;
        font-size: 24px;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
        margin-bottom: 15px;
      }
      .members-position {
        text-align: left;
        font-size: 16px;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
      }
    }
    .sns-links {
      padding: 20px 0 20px 50px;
      //border-bottom: 1.5px solid #cccccc ;
      @media screen and(max-width: 1024px) {
        padding: 20px 0 20px 20px;
      }
      .sns-wrapper {
        display: flex;
        text-align: center;
        padding: 10px 0;
      }
      .sns-icon_wapper {
        display: inline-block;
        margin: auto 0;
        align-items: center;
        //background-color: #303030;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        .filter_svg {
          filter: brightness(0) saturate(100%) invert(100%) sepia(0%)
            saturate(7500%) hue-rotate(30deg) brightness(0%) contrast(114%);
        }
        .fw_icon {
          width: 50%;
        }
        .vibes_icon {
          width: 65%;
          margin-top: 2px;
        }
        .fb_icon {
          width: 85%;
          margin-top: 2px;
        }
        .url_icon {
          width: 75%;
          margin-top: 3px;
        }
      }
      .sns_text {
        display: inline-block;
        margin: auto 0;
        padding-left: 14px;
      }
    }
    .members-discription {
      padding: 20px 70px 100px 50px;
      p{
        line-height: 28px;
        font-size: 14px;
        overflow-wrap: break-word;
      }
      @media screen and(max-width: 1024px) {
        padding: 20px 20px 40px 20px;
      }
      .profile-title {
        font-size: 16px;
        padding-bottom: 20px;
        font-weight: 700;
      }
    }
  }

  /*アクティブクラスがついたら位置を0に*/
  #profile-box.panelactive {
    right: 0;
    @media screen and(max-width: 1024px) {
      top: 200px;
      bottom: 0;
    }
  }
  .profile-box-cover.panelactive {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 990;
    background: rgba(3,3,3,0.5);
    display: block;
  }
  #profile-box.panelactive .profile-box-list {
    position: fixed;
    z-index: 999;
    width: 760px;
    height: calc(100vh - 35px);
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;
    @media screen and(max-width: 1024px) {
      width: 100%;
      height: calc(100vh - 200px);
      background: #ffffff;
    }
  }
  #profile-box.panelactive .profile-box-list::-webkit-scrollbar {
    display: none;
  }

  .members_section {
    @include desktop {
      position: relative;
      display: block;
      padding: 150px 0 154px;
      z-index: 1;
    }

    .csr__intoro {
      text-align: center;
      padding: 0;

      .members_member_headline_2 {
        font-size: 64px;
        font-weight: 700;
        letter-spacing: 1.5px;
        line-height: 74px;
        text-align: center;
        color: #242424;
        padding: 100px 0 0px;
        white-space: nowrap;
        &__handwriting {
          transform: translate(133px, -30px);
          width: 391.04px;
        }
        @media screen and (max-width: 1024px) {
          font-size: 30px;
          font-weight: 600;
          letter-spacing: 0.75px;
          line-height: 37px;
          padding: 20px 0 60px;
          &__handwriting {
            transform: translate(53px, -73px);
            width: 195.52px;
          }
        }
      }
    }
    .members_li_articles {
      @include desktop {
        margin-right: 6.98vw;
        margin-left: 6.98vw;
      }
      .members_li_articles_list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        column-gap: 0px;
        row-gap: 24px;
        align-items: start;
        justify-items: center;
        .member_text1 {
          width: 600px;
          text-align: center;
          grid-column: 1 / span 3;
          grid-row: 3;
          margin: 20px 0px;
        }
        .member_text2 {
          width: 600px;
          text-align: center;
          grid-column: 1 / span 3;
          grid-row: 6;
          margin: 20px 0px;
        }
        @media only screen and (max-width: 1200px) {
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(1, 1fr);
          .member_text1 {
            width: 600px;
            text-align: center;
            grid-column: 1 / span 2;
            grid-row: 4;
          }
          .member_text2 {
            width: 600px;
            text-align: center;
            line-height: 32px;
            grid-column: 1 / span 2;
            grid-row: 8;
          }
        }

        @media only screen and (max-width: 720px) {
          grid-template-columns: repeat(1, 1fr);
          grid-template-rows: repeat(1, 1fr);
          row-gap: 0px;
          .member_text1 {
            width: 90%;
            text-align: center;
            grid-column: 1;
            grid-row: 7;
          }
          .member_text2 {
            width: 90%;
            margin: 40px 0;
            text-align: center;
            grid-column: 1;
            grid-row: 12;
          }
        }

        .members_li_li {
          width: 100%;
          overflow: hidden;
          .disable {
            pointer-events: none;
          }

          .members_li_img {
            position: relative;
            width: 100%;
            height: auto;
            object-fit: cover;
            img {
              transition: 0.3s ease-in-out;
            }

            &:hover img {
              transition: 0.3s ease-in-out;
              transform: scale(1.1);
            }

            .overlay_before {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              display: block;
              transition: 0.3s ease;
              background: transparent
                linear-gradient(180deg, #00000000 0%, #000000 100%) 0% 0%
                no-repeat padding-box;
              opacity: 0.4;
            }
            .overlay_after {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              z-index: 2;
              transition: 0.3s ease;
              cursor: pointer;
              @media (hover: hover) and (pointer: fine) {
                &:hover {
                  display: block;
                  background: rgba(0, 0, 0, 0.7);
                }
                &:hover .icon_wapper {
                  display: block;
                }
              }
              .hide {
                display: none;
              }
              .icon_wapper {
                position: absolute;
                top: 50%;
                left: 50%;
                // width: 240.03px;
                white-space: nowrap;
                transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                transition: 0.3s ease;
                // line-height: 50px;
                display: none;
                .icon_wapper_circle {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background-color: rgba(0, 0, 0, 0.7);
                  width: 35px;
                  height: 35px;
                  border-radius: 50px;
                }
                .filter_svg {
                  filter: brightness(0) saturate(100%) invert(100%) sepia(0%)
                    saturate(7500%) hue-rotate(30deg) brightness(96%)
                    contrast(114%);
                }
                .jump_icon {
                  position: relative;
                  width: 15px;
                  height: 15px;
                  top: 7px;
                  opacity: 1;
                }
                .icon {
                  position: relative;
                  width: 20px;
                  height: 20px;
                  //top: 7px;
                  opacity: 1;
                }
                .text {
                  position: relative;
                  top: 10px;
                  font-family: Noto Sans CJK JP;
                  font-size: 14px;
                  margin-left: 24px;
                  color: #ffffff;
                }
                .executive {
                  font-family: Noto Sans CJK JP;
                  font-size: 18px;
                  margin-left: auto;
                }
                .fw_li_link {
                  display: flex;
                  margin-bottom: 15px;
                }
                .vibes_li_link {
                  display: flex;
                  margin-top: 15px;
                  margin-bottom: 15px;
                }
                .wantedly_li_link {
                  display: flex;
                  margin-top: 15px;
                  margin-bottom: 15px;
                }
                .facebook_li_link {
                  display: flex;
                }
                @media (hover: none) {
                  display: block;
                  left: 92%;
                  width: auto;
                  // .icon_wapper_circle {
                  //   width: 35px;
                  //   height: 35px;
                  // }
                  // .icon {
                  //   left: 22%;
                  //   width: 19px;
                  //   height: 19px;
                  // }
                  .text {
                    display: none;
                  }
                  .fw_li_link {
                    margin-bottom: 10px;
                  }
                  .vibes_li_link {
                    margin-top: 10px;
                    margin-bottom: 10px;
                  }
                  .wantedly_li_link {
                    margin-top: 10px;
                  }
                  @media only screen and (max-width: 320px) {
                    .fw_li_link {
                      margin-bottom: 8px;
                    }
                    .vibes_li_link {
                      margin-top: 8px;
                      margin-bottom: 8px;
                    }
                    .wantedly_li_link {
                      margin-top: 8px;
                    }
                  }
                }
              }
            }
            .members_li_title {
              position: absolute;
              top: 55.7%;
              left: 1.66vw;
              font-size: 18px;
              // font-size: 0.937vw;
              font-weight: bold;
              color: #ffffff;
              z-index: 1;
              @media only screen and (max-width: 375px) {
                font-size: 16px;
              }
            }
            .members_li_body {
              position: absolute;
              top: 70%;
              left: 1.66vw;
              font-size: 16px;
              // font-size: 0.83vw;
              // font-weight: 100;
              color: #ffffff;
              z-index: 1;
              @media only screen and (max-width: 375px) {
                font-size: 14px;
              }
            }
            .members_li_caption {
              position: absolute;
              top: 81%;
              left: 1.66vw;
              font-size: 12px;
              max-width: 90%;
              font-weight: 100;
              line-height: 22px;
              color: #ffffff;
              opacity: 0.7;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1; /* number of lines to show */
              -webkit-box-orient: vertical;
              z-index: 1;
            }
          }
        }
      }
    }
    .overlay_all {
      display: none;
      // display: block;
      z-index: 100;
      background: rgba(0, 0, 0, 0.7);
      position: fixed;
      width: 100%;
      height: 100vh;
      top: 0;
      left: 0;
    }
  }
}
