@include desktop {
  .explores_header {
    &__background {
      // background: url(../images/pc/explorers/ex_line.png);
      background-size: cover;
      background-repeat: no-repeat;
      background-blend-mode: hard-light;
      // height: 1040px;
      min-height: 840px;
      width: 100%;
      position: relative;

      &--image {
        @include desktop {
          position: absolute;
          background: url(../images/pc/explorers/explores_header.png);
          background-size: cover;
          width: 100%;
          height: 1040px;
          z-index: -1;
        }
      }
    }
    &__title {
      font-size: 80px;
      font-weight: bold;
      color: #fff;
      position: absolute;
      top: 440px;
      right: 0;
      transform: translateY(-50%) translateX(-50%);
    }

    &__sub {
      font-size: 30px;
      color: #fff;
      position: absolute;
      top: 565px;
      right: 185px;
      transform: translateY(-50%) translateX(-50%);
    }

    &__content {
      position: absolute;
      top: 665px;
      right: -100px;
      transform: translateY(-50%) translateX(-50%);
      width: 474px;

      .explores_text {
        color: #fff;
        font-size: 15px;
        line-height: 1.6;
        letter-spacing: 0.03em;
        font-family: "Noto Sans JP", sans-serif;
      }
    }

    &__whiteBox {
      width: calc(100% - 262px);
      height: 1200px;
      background-color: #fff;
      position: absolute;
      top: 800px;
      left: 0;
      z-index: 10;

      .explores_extra__border {
        width: calc(50% - -180px);
        height: 1px;
        background: transparent;
        border-top: 0.5px solid #e1e1e1;
        position: absolute;
        top: 80px;
        left: calc(50% - 180px);

        &::before {
          content: "OUR EXTRAORDINARY";
          position: absolute;
          top: -4px;
          left: -125px;
          color: #e1e1e1;
          font-size: 9px;
        }
      }

      .explores_text__content {
        padding-top: 80px;
        padding-left: 200px;

        // .vision_hr1 {
        //     width: 710px;
        //     height: .5px;
        //     background-color: #242424;
        //     position: absolute;
        //     top: 55px;
        //     right: 25px;
        // }

        .explores_text__large {
          font-size: 20px;
          padding: 30px;
        }

        .explores_text {
          font-size: 15px;
          line-height: 1.6;
          letter-spacing: 0.03em;
          max-width: 711px;
          max-height: 475px;
          font-family: "Noto Sans JP", sans-serif;

          &:first-child {
            // border-top: .5px solid #242424;
            padding-top: 50px;
          }
        }
      }
    }

    .explores_circle_img {
      position: relative;

      &::before {
        content: "";
        width: 52px;
        height: 148px;
        background-color: #c30000;
        display: block;
        position: absolute;
        top: -360px;
        left: 204px;
        opacity: 0.5;
        mix-blend-mode: overlay;
      }
    }

    .explores_circles {
      position: absolute;
      top: -412px;
      left: 151px;
    }
  }

  .company__content-small {
    padding: 40px 110px 5px 94px;
  }

  .company__content-large {
    padding: 151px 110px 5px 94px;
  }

  .explores_num {
    color: #e1e1e1 !important;
  }

  .explores_line {
    background-color: #e1e1e1 !important;
  }

  .explores_readmore__btn {
    float: right;
  }

  .explores-csr_section {
    position: relative;
    left: calc(50% - -100px);
    height: 500px;

    &__box {
      width: 352px;
      height: 331px;
      background: #f8f8f8;

      &--next {
        font-size: 9px;
        padding: 54px 48px 15px 59px;
      }

      &--title {
        font-size: 40px;
        font-weight: bold;
        padding-right: 97px;
        padding-bottom: 24px;
        padding-left: 58px;
      }

      &--content {
        font-size: 10px;
        line-height: 1.5;
        padding-right: 59px;
        padding-bottom: 60px;
        padding-left: 59px;
      }
    }

    &__btn {
      position: absolute;
      top: 296px;
      left: 153px;
    }

    .btn-open {
      display: inline-block;
      width: 199px;
      height: 67px;
      text-align: center;
      background-color: #f21515;
      font-size: 20px;
      line-height: 66px;
      color: #fff;
      // text-decoration: none;
      // font-weight: bold;
      // border: 2px solid #9ec34b;
      // position: relative;
      // overflow: hidden;
      // z-index: 1;
    }

    // .btn-open:after {
    //     width: 100%;
    //     height: 0;
    //     content: "";
    //     position: absolute;
    //     top: 50%;
    //     left: 50%;
    //     background: #FFF;
    //     opacity: 0;
    //     transform: translateX(-50%) translateY(-50%) rotate(45deg);
    //     transition: .2s;
    //     z-index: -1;
    // }

    // .btn-open:hover {
    //     color: #9ec34b;
    // }
  }
}

@media screen and (max-width: 1024px) {
  .explores_header {
    // height: 2500px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 60px;
    &__background {
      position: relative;
      display: flex;
      flex-direction: column;
      // align-items: center;
      justify-content: flex-end;
      padding-bottom: 68px;
      background: url(../images/sp/explores/ex_mobile.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      // height: 800px;
      height: calc(100vh - 60px);
      @include tablet {
        padding-bottom: 204px;
      }

      .explores_header__sub {
        padding: 0 20px 50px;
        color: #fff;
        font-size: 20px;
        text-align: right;
      }

      .explores_header__content {
        padding: 0 20px;
        color: #fff;
        font-size: 12px;
        line-height: 1.5;
      }
    }
    &__title {
      position: relative;
      overflow: visible !important;
      height: initial !important;
      padding: 0 20px 100px;
      font-size: 60px;
      color: white;
      font-weight: bold;
      text-align: right;
      @media screen and (max-width: 350px) {
        font-size: 50px;
      }
      &::before {
        content: "";
        height: 183px;
        width: 1px;
        background-color: #fff;
        position: absolute;
        top: -250px;
        left: 20px;
        @media screen and (max-width: 350px) {
          top: -115px;
        }
      }

      &::after {
        position: absolute;
        top: 4px;
        left: -57px;
        transform: rotate(90deg) scale(0.6);
        //width: 74px;
        color: #fff;
        font-size: 9px;
        font-weight: 100;
        content: "EXPERIENCING THE EXTRAORDINARY";
        @media screen and (max-width: 350px) {
          top: 120px;
        }
      }
      // &::before {
      //     content: '';
      //     width: 1px;
      //     height: 190px;
      //     background-color: #fff;
      //     position: absolute;
      //     top: -250px;
      //     left: -15px;
      // }

      // &::after {
      //     content: 'EXPERIENCING THE EXTRAORDINARY';
      //     width: 160px;
      //     /* height: 54px; */
      //     color: #FFF;
      //     font-size: 9px;
      //     position: absolute;
      //     top: 25;
      //     left: -94px;
      //     font-weight: 100;
      //     transform: rotate(90deg);
      // }
    }
    &__whiteBox {
      .explores_text__content {
        padding: 20px;
        text-align: center;
        .explores_text {
          font-size: 12px;
          line-height: 2.2;
          letter-spacing: 0.03em;
        }
      }
    }
  }

  .explores_sp__blkBox {
    position: relative;
    width: 100%;
    background-color: #242424;
    &--title {
      color: #fff;
      font-size: 40px;
      font-weight: bold;
      padding: 65px 118px 16px 74px;
      &::before {
        content: "";
        width: 33px;
        height: 3px;
        background-color: #fff;
        position: absolute;
        top: 70px;
        left: 33px;
      }
    }
  }

  .explores_sp__whtBox {
    .text {
      padding: 0 20px;
      margin-top: 14px;
      margin-bottom: 10px;
      font-size: 12px;
      line-height: 2;
      letter-spacing: 0.03em;
    }
  }

  .explores_text__large {
    font-size: 21px;
    line-height: 1.5;
    font-weight: 500;
    margin-bottom: 30px;
  }

  .explorers {
    &__list {
      .thumb {
        position: absolute;
      }
    }
  }
}

.mobile_br {
  display: none;

  @media only screen and (max-width: 768px) {
    display: block;
  }
}
