.news_header {
  margin-bottom: 7.77778%;
  margin-right: 6.66667%;
  margin-left: 6.66667%;
  padding-top: 100px;

  &_wrap {
    position: relative;
    display: block;
    color: #000;

    &__title {
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      line-height: 2.5;
      position: relative;
      z-index: 10;
      margin: 0;
      font-size: 60px;
      font-weight: 600;
    }

    &__bg {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      &::before {
        width: 100%;
        height: 100%;
        content: "";
        display: block;
        background-color: #f5f3f0;
      }
    }
  }
}
// Youtube popup

.Modal-overlay {
  display: none;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.85);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
  padding-top: 10%;
  z-index: 99999;
}
.Modal-box {
  width: 87%;
  max-width: 860px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}
.video-container {
  position: relative;
  display: block;
  text-align: center;
  height: 0;
  padding: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  > iframe,
  > object,
  > embed {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    max-width: 860px;
  }
}
.Modal-close {
  position: absolute;
  right: 20px;
  top: 10px;
  font-size: 50px;
  z-index: 100000;
  color: #fff;
}
​
    // this gets attached to Body
    .Modal-cancel-overflow {
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .news_header_wrap__title {
    font-size: 40px;
  }
}

.news_content {
  margin-right: 23.33333%;
  margin-left: 23.33333%;

  .article_header {
    margin-bottom: 40px;
    .article_date {
      line-height: 1.1;
      font-weight: 500;
      letter-spacing: 0.1em;
      margin-bottom: 20px;
    }

    .news_title {
      line-height: 1.53846;
      margin-top: 0;
      margin-bottom: 0;
      font-weight: 400;
      font-size: 25px;
    }
  }

  .article_top_img {
    // display: flex;
    justify-content: center;
  }

  .news_paragraph {
    font-family: "Noto Sans JP", sans-serif;
    font-size: 15px;
    line-height: 2;
    color: #242424;
    margin-top: 50px;
    margin-bottom: 100px;

    .alignnone {
      height: 160px;
      width: 300px;
      margin-top: 50px;
      margin-bottom: 50px;
    }
  }
}

@media only screen and (max-width: 991px) {
  .news_content {
    margin-right: 13.33333%;
    margin-left: 13.33333%;
  }
}

.new_articles_header {
  margin-bottom: 7.77778%;
  margin-right: 6.66667%;
  margin-left: 6.66667%;
  padding-top: 100px;
  .new_articles_wrap {
    position: relative;
    display: block;
    color: #000;

    &__title {
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      line-height: 2.5;
      position: relative;
      z-index: 10;
      margin: 0;
      font-size: 30px;
      font-weight: 500;
    }
  }
}

.new_articles {
  margin-bottom: 8.33333%;
  margin-right: 10%;
  margin-left: 10%;

  .new_articles_list {
    .news_li {
      width: 300px;
    }

    .news_body {
      padding: 25px 0px;

      .article_date {
        line-height: 1.5;
        margin-bottom: 5px;
        color: rgb(150, 150, 150);
        letter-spacing: 0.025em;
        font-size: 12px;
      }

      .other_news_title {
        line-height: 1.5;
        color: #333 !important;
      }
    }
  }
}

.explore_articles {
  display: flex;
  justify-content: center;
  // margin-bottom: 8.33333%;
  margin-right: 6.9%;
  margin-left: 6.9%;

  @media only screen and (max-width: 767px){
    margin-right: 0%;
    margin-left: 0%;
  }


  .explore_articles_list {
    .explore_li {
      width: 100%;
    }
  }
}
